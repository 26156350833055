// @ts-nocheck
import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-users-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  @Output()
  private userSelected: EventEmitter<object | null> = new EventEmitter();

  onUserSelected(user: object | null) {
    this.userSelected.emit(user);
  }
}
