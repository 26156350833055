<section class="detail-appointment" *ngIf="message">
  <h2 i18n="@@eiSopivatVastaanottoajatOtsikko">Ei-sopivat ajat</h2>

  <div
    *ngIf="
      message.unsuitable_times.length === 0 &&
      !message.data.appointment_request.excluded_times_description
    "
    i18n="@@eiSopivatVastaanottoajatKaikki">
    Kaikki ajat sopivat.
  </div>

  <div *ngIf="message.unsuitable_times.length > 0">
    <div *ngFor="let time of message.unsuitable_times">
      <h6>{{ time.day | amDateFormat: date_formats['date'] }}</h6>
      <span *ngFor="let hours of time.hours">{{ hours }}</span>
    </div>
  </div>

  <div
    class="freetext"
    [class.with-times]="message.unsuitable_times.length > 0"
    *ngIf="message.data.appointment_request.excluded_times_description">
    {{ message.data.appointment_request.excluded_times_description }}
  </div>

  <div class="footer"></div>
</section>
