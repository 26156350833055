<body>
  <section class="gdpr">
    <h1 style="margin-bottom: 1%">Tietosuojaohjeistus</h1>

    <p>
      EU:n tietosuoja-asetus tulee voimaan 25.5.2018. Asetus on otettava
      huomioon henkilötietojen käsittelyssä, ja tässä dokumentissa on ohjeet
      miten toimia, mikäli potilaat käyttävät asetukseen perustuvia oikeuksiaan.
    </p>

    <p>
      Asetukseen mukaan potilaalla on oikeus tarkastaa häntä koskevat tiedot,
      vaatia niihin oikaisuja tai niiden poistamista.
    </p>

    <p>
      Rekisterinpitäjänä teillä on vastuu siitä, että nämä potilaan oikeudet
      toteutuvat. Käytännössä tämä tarkoittaa sitä, että mikäli potilas haluaa
      tarkastaa tietonsa, oikaista niitä tai vaatii tietojen poistamista, nämä
      pyynnöt esitetään teille.
    </p>

    <p>
      Näissä tilanteissa teidän tulisi muistaa muiden
      potilastietojärjestelmienne sisältämien tietojen läpikäynnin lisäksi
      lähettää tämä pyyntö myös Klinikille.
    </p>

    <p>
      Toivomme, että lisäisitte nämä ohjeet tietosuojaa käsitteleviin
      ohjeistuksiinne mahdollisimman pian.
    </p>

    <p>Pyynnön lähettäminen tapahtuu seuraavasti:</p>
    <ol>
      <li>
        ProUI -käyttöliittymässä klikkaa oikeasta yläkulmasta nimeäsi ja valitse
        valitse "Lähetä palautetta".
      </li>
      <li>Valitse palautteen tyypiksi "Henkilötietojen käsittely".</li>
      <li>
        Kirjaa pyynnön tiedot, haluttu toiminto ja pyynnön tekijän nimi sekä
        henkilötunnus.
      </li>
      <li>Paina "Lähetä".</li>
    </ol>

    <p>
      <strong
        >Pyyntöjä ei missään tapauksessa tule lähettää sähköpostilla!</strong
      >
    </p>

    <p>Pyyntö toteutetaan seuraavasti:</p>
    <ul>
      <li>
        Tietojen pyyntö: Ilmoitamme pääkäyttäjälle, kuinka tiedot ovat
        ladattavissa.
      </li>
      <li>Tietojen poisto: Poistetaan 30 päivän kuluessa.</li>
      <li>
        Tietojen oikaisu: korjattu tieto kirjataan ylös 30 päivän kuluessa.
      </li>
    </ul>

    <p>
      Kaikki tiedot, kuten tietojen käsittelyyn liittyvät lokitiedot, eivät ole
      suoraan nähtävillä ProUI-käyttöliittymässä. Tietojen poistaminen ei
      myöskään ole mahdollista käyttöliittymästä käsin. Näistä syistä on tärkeää
      toimia näiden ohjeiden mukaisesti.
    </p>

    <p>Lisätietoja antaa:</p>
    <p><strong>Jukka Happonen</strong></p>
    <p>Head of product development</p>
    <p>Klinik Healthcare Solutions Oy</p>
    <p><a href="mailto:tietosuoja@klinik.fi"> tietosuoja@klinik.fi </a></p>
  </section>
</body>
