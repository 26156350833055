// @ts-nocheck
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  @Input()
  private chart: Chart;

  public hideHead = false;
  public hideTotal = false;
  public percentage = null;
  public data: tableObject[] = [];
  public translations = {
    top: $localize`:@@topTableTop:`,
    total: $localize`:@@topTableTotal:`,
    percentage: $localize`:@@topTablePercentage:`,
  };

  private showNumbers = false;
  private hidePercentage = false;
  private maxItems: number = null;
  constructor(private utilsSerevice: UtilsService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chart'] && this.chart.data.datasets.length > 0) {
      this.initializeData();
      this.updateConfig();
      this.updateTable();
      this.updateHeaders();
    }
  }

  initializeData(): void {
    this.data = [];
    this.chart.data.datasets.forEach(() =>
      this.data.push({
        header: '',
        total: 0,
        showing: 0,
        headers: <string[]>[],
        values: <(string | number)[][]>[],
      })
    );
  }

  updateConfig(): void {
    this.showNumbers = this.utilsSerevice.getProperty(
      this.chart,
      'top_items.show_numbers',
      false
    );
    this.hidePercentage = this.utilsSerevice.getProperty(
      this.chart,
      'top_items.hide_percentage',
      false
    );
    this.maxItems = this.utilsSerevice.getProperty(
      this.chart,
      'top_items.max_items',
      false
    );
    this.hideHead = this.utilsSerevice.getProperty(
      this.chart,
      'top_items.hide_table_head',
      false
    );
    this.hideTotal = this.utilsSerevice.getProperty(
      this.chart,
      'top_items.hide_total',
      false
    );
  }

  updateHeaders(): void {
    this.chart.data.datasets.forEach(
      (dataset: ChartDataSets, index: number) => {
        const headers: string[] = [];
        if (this.showNumbers) {
          headers.push(`${this.translations.top} ${this.data[index].showing}`);
        }
        if (this.chart.data.datasets.length > 1) {
          headers.push(dataset.label, this.translations.total);
        } else {
          headers.push(this.chart.legend, this.translations.total);
        }
        if (!this.hidePercentage) {
          headers.push(this.translations.percentage);
        }
        this.data[index].headers = headers;
      }
    );
  }

  _safeDivision(a: number, b: number): number {
    if (b === 0) {
      return 0;
    }
    return a / b;
  }

  updateTable(): void {
    this.chart.data.datasets.forEach(
      (dataset: ChartDataSets, index: number) => {
        const data = this.maxItems
          ? [...dataset.data].slice(0, this.maxItems)
          : [...dataset.data];
        const labels = this.maxItems
          ? [...this.chart.data.labels.slice(0, this.maxItems)]
          : [...this.chart.data.labels];

        this.data[index].showing = data.length;

        // If the list includes a total, avoid computing it and remove it from the list to avoid showing wrong numbers (percentage).
        const indexTotal = labels.indexOf($localize`:@@total:`);
        if (indexTotal !== -1) {
          labels.splice(indexTotal, 1);
          this.data[index].total = data.splice(indexTotal, 1)[0] as number;
        } else {
          this.data[index].total = data.reduce(
            (acc: number, val: any) => acc + val,
            0
          );
        }

        this.data[index].values = data.map((value: any, i: number) => {
          const cells: (string | number)[] = [];
          if (this.showNumbers) {
            cells.push(`${i + 1}.`);
          }
          cells.push(<string>labels[i]);
          cells.push(value);
          cells.push(
            `${Math.round(
              this._safeDivision(value, this.data[index].total) * 100
            )}%`
          );
          if (!this.hidePercentage) {
            this.percentage = `${Math.round(
              this._safeDivision(
                this.data[index].total,
                data.reduce((acc: number, val: any) => acc + val, 0)
              ) * 100
            )}%`;
          }
          return cells;
        });
      }
    );
  }
}
