// @ts-nocheck
import { Component, Input, OnInit } from '@angular/core';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { MessageService } from 'services/message.service';
import {
  EnquiryRecentUpdateMessageEvent,
  AuditLogCaseType,
} from 'app/shared/methods/enquiry/enquiry';
import { ToasterService } from 'angular2-toaster';
import { ErrorTypes } from 'app/utils/http-response.enums';

@Component({
  selector: 'app-messages-detail-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent implements OnInit {
  _messageId: number | undefined;
  @Input() set messageId(value: number | undefined) {
    this._messageId = value;
    this.reset();
  }
  get messageId(): number {
    return this._messageId;
  }

  public auditEvents: Array<EnquiryRecentUpdateMessageEvent> = undefined;

  permissions = Permissions;

  constructor(
    private messageService: MessageService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {}

  async updateAuditEvents() {
    this.reset();

    try {
      this.auditEvents = await this.messageService.getAuditEvents(
        this.messageId
      );
    } catch (error) {
      // Recognized errors
      if (error?.status === ErrorTypes.tooManyRequests)
        return this.toasterService.pop(
          'error',
          $localize`:@@errorTooManyRequests:`
        );

      // Unrecognized errors
      this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`);
    }
  }

  getActorName(event: EnquiryRecentUpdateMessageEvent) {
    if (event.actor) {
      return `${event.actor.first_name} ${event.actor.last_name}`;
    } else if (event.type == AuditLogCaseType.UPLOADED_DOCUMENTS) {
      return $localize`:@@patient:Patient`;
    } else {
      return $localize`:@@system:System`;
    }
  }
  getLogMessage(event: EnquiryRecentUpdateMessageEvent): string {
    let returnMessage: string = '';
    switch (event.type) {
      case AuditLogCaseType.OPENED_MESSAGE:
        returnMessage = $localize`:@@tapahtumaYhteydenotonAvaus:`;
        break;
      case AuditLogCaseType.CHANGED_STATE:
        returnMessage = this.getStateChangeMessage(event);
        break;
      case AuditLogCaseType.SENT_SMS:
        returnMessage = $localize`:@@tapahtumaSMSlahetys:`;
        break;
      case AuditLogCaseType.READ_SMS:
        returnMessage = $localize`:@@tapahtumaSMSluku:`;
        break;
      case AuditLogCaseType.UPLOADED_DOCUMENTS:
        returnMessage = $localize`:@@patientUploadedAttachments:`;
        break;
      case AuditLogCaseType.REMOVED_ATTACHMENT:
        returnMessage = $localize`:@@userHidAttachment:`;
        break;
      case AuditLogCaseType.MEDICAL_DATA_MOVED_TO_RECORD:
        returnMessage = $localize`:@@medicalDataMovedToRecord:`;
        break;
      case AuditLogCaseType.SMS_MESSAGE_MOVED_TO_RECORD:
        returnMessage = $localize`:@@smsDataMovedToRecord:`;
        break;
      case AuditLogCaseType.ATTACHMENT_MOVED_TO_RECORD:
        returnMessage = $localize`:@@attachmentsMovedToRecord:`;
        break;
      case AuditLogCaseType.CONNECTED_ENQUIRY_TO_PRS:
        returnMessage = $localize`:@@connectedToPrs:`;
        break;
      case AuditLogCaseType.MOVED_TO_PATIENT_RECORD:
        returnMessage = $localize`:@@sendDocumentMovedToPatientRecord:`;
        break;
      case AuditLogCaseType.MOVED_AS_AN_ACTION:
        returnMessage = $localize`:@@sendDocumentMovedAsAnAction:`;
        break;
      case AuditLogCaseType.MESSAGE_TRANSFER_EVENT:
        returnMessage = this.getTransferEventMessage(event);
        break;
      case AuditLogCaseType.VIEWED_AUDIT_LOGS:
        returnMessage = $localize`:@@viewedAuditLog:`;
        break;
      case AuditLogCaseType.SERVICE_UPDATE:
        // todo: add translations if/when needed. These are not related to a Message.
        returnMessage = 'Service updated';
        break;
      case AuditLogCaseType.CREATE_COMMENT:
        returnMessage = $localize`:@@commentWasAdded:`;
        break;
      case AuditLogCaseType.EDIT_COMMENT:
        returnMessage = $localize`:@@commentWasEdited:`;
        break;
      case AuditLogCaseType.BLACK_PEAR_TPP_ATTEMPT:
        returnMessage = $localize`:@@requestedTPPForward:`;
        break;
    }

    return returnMessage;
  }

  private getStateChangeMessage(
    event: EnquiryRecentUpdateMessageEvent
  ): string {
    if (!Object.keys(event.data).includes('state'))
      return this.getTransferEventMessage(event);

    let translateMessage: string = '';

    switch (event.data.state) {
      case 0:
        translateMessage = $localize`:@@setCaseToOpen:`;
        break;
      case 1:
        translateMessage = $localize`:@@setCaseToHandling:`;
        break;
      case 2:
        translateMessage = $localize`:@@setCaseToClosed:`;
        break;
      default:
        translateMessage = $localize`:@@tapahtumaYhteydenotonTilanMuokkaus:`;
        break;
    }

    return translateMessage;
  }

  private getTransferEventMessage(
    event: EnquiryRecentUpdateMessageEvent
  ): string {
    // Priority:
    // 1. service_to_name - use name if available
    // 2. service_to - use id if name is not available
    // 3. 'noUnit' - last resort
    const toUnit =
      event.data.service_to_name ??
      event.data.service_to ??
      $localize`:@@noUnit:No unit`;

    let transferMsg = $localize`:@@transferredCaseToUnit:Transferred case to unit ${toUnit}`;

    if (!event.data.service_group_to) return transferMsg;

    const serviceGroup = $localize`:@@serviceGroup:`;
    return `${transferMsg}, ${serviceGroup}: (${event.data.service_group_to})`;
  }

  private reset(): void {
    this.auditEvents = undefined;
  }
}
