<section class="followup" *ngIf="showFollowupSection">
  <section [class.status-ready]="message.state === 2">
    <section class="followup-actions">
      <button
        class="btn action toggle"
        [ngClass]="{
          'new-messages': newFollowupMessageCount > 0,
          open: !followupOpen
        }"
        [disabled]="
          !userPermissions.has(permissions.MESSAGE_VIEW_SMS) || isMessageLoading
        "
        (click)="toggleOpener()">
        <span>
          <ng-container *ngIf="newFollowupMessageCount === 0">
            <span class="count" *ngIf="followupMessages.length > 0">{{
              followupMessages.length
            }}</span>
            <i *ngIf="followupMessages.length === 0" class="fa fa-envelope"></i>
          </ng-container>

          <ng-container *ngIf="newFollowupMessageCount > 0">
            <span class="count count-new">{{ newFollowupMessageCount }}</span>
          </ng-container>

          <ng-container *ngIf="!followupOpen">
            <ng-container *ngIf="newFollowupMessageCount === 0">
              <span
                *ngIf="followupMessages.length > 0"
                i18n="@@vastausviestitAvaa"
                >Avaa viestiketju</span
              >
              <span
                i18n="@@vastausviestitLähetä"
                *ngIf="followupMessages.length === 0"
                >Lähetä viesti</span
              >
            </ng-container>

            <ng-container *ngIf="newFollowupMessageCount > 0">
              <span
                i18n="@@vastausviestitLueUusi"
                *ngIf="newFollowupMessageCount === 1"
                >Lue uusi viesti</span
              >
              <span
                i18n="@@vastausviestitLueUuudet"
                *ngIf="newFollowupMessageCount > 1"
                >Lue uudet viestit</span
              >
            </ng-container>
          </ng-container>

          <span *ngIf="followupOpen" i18n="@@vastausviestitSulje"
            >Sulje viestiketju</span
          >
        </span>
      </button>
    </section>

    <section class="followup-body">
      <section class="body-header">
        <h1 i18n="@@vastausviestitOtsikko">Viestit</h1>
      </section>

      <section #scrolling class="body-content">
        <section class="followup-messages">
          <div
            *ngFor="let fm of followupMessages"
            class="message"
            [class.error]="fm.delivery_status === 3 || fm.delivery_status === 6"
            [class.outbound]="fm.direction === 2"
            [class.inbound]="fm.direction === 1">
            <div class="message-content">
              <div class="message-content-body">
                <div class="fm-status" *ngIf="message.state !== 2">
                  <div
                    class="error-header"
                    *ngIf="
                      fm.delivery_status === 3 ||
                      fm.delivery_status === 6 ||
                      fm.delivery_status === 7 ||
                      (fm.delivery_status === 1 &&
                        dateCheck(fm.created_at) &&
                        fm.direction === 2)
                    "
                    i18n="@@vastausviestitLahetysEpaonnistui">
                    Viestin välitys epäonnistui. Ole puhelimitse yhteydessä
                    asiakkaaseen.
                  </div>

                  <div
                    class="pending-header"
                    *ngIf="
                      fm.delivery_status === 1 &&
                      !dateCheck(fm.created_at) &&
                      fm.direction === 2
                    "
                    i18n="@@vastausviestitLahetysKesken">
                    Viestiä välitetään...
                  </div>

                  <div
                    class="success-header"
                    *ngIf="fm.delivery_status === 2 && fm.direction === 2"
                    i18n="@@vastausviestitLahetysOnnistui">
                    Viesti välitetty
                  </div>

                  <h5 *ngIf="fm.title">{{ fm.title }}</h5>

                  <i
                    *ngIf="fm.delivery_status === 4"
                    i18n="@@vastausviestitUusi">
                    Uusi
                  </i>
                </div>
                {{ fm.body }}

                <span class="meta">
                  <span *ngIf="fm.type === 1" i18n="@@vastausviestitSMS">
                    SMS
                  </span>
                  <span *ngIf="fm.type === 2" i18n="@@vastausviestitSahkoposti">
                    Sähköposti
                  </span>
                  <span *ngIf="fm.sent_today">
                    <ng-container i18n="@@vastausviestitKellonaika">
                      Kello
                    </ng-container>
                    {{ fm.created_at | amDateFormat: date_formats['time'] }}
                  </span>
                  <span *ngIf="!fm.sent_today">{{
                    fm.created_at | amDateFormat: date_formats['date']
                  }}</span>
                </span>

                <button
                  class="btn"
                  *ngIf="fm.delivery_status === 4"
                  (click)="setFollowupMessageStatus(fm, 5)"
                  i18n="@@vastausviestitMerkitseLuetuksi">
                  Merkitse luetuksi
                </button>

                <button
                  class="btn"
                  *ngIf="fm.delivery_status === 3"
                  (click)="setFollowupMessageStatus(fm, 6)"
                  i18n="@@vastausviestitMerkitseLuetuksi2">
                  Merkitse luetuksi
                </button>
              </div>
            </div>

            <div class="message-sender">
              <div *ngIf="!fm.actor">
                <span
                  *ngIf="
                    fm.sender_address ===
                    message?.data?.personal_info?.telephone
                  ">
                  <ng-container
                    *ngIf="
                      message?.data?.personal_info?.firstname &&
                      message?.data?.personal_info?.lastname
                    ">
                    {{ message.data.personal_info.firstname }}
                    {{ message.data.personal_info.lastname }}
                  </ng-container>
                  <ng-container *ngIf="message?.data?.personal_info?.full_name">
                    {{ message.data.personal_info.full_name }}
                  </ng-container>
                </span>
                <span
                  *ngIf="
                    fm.sender_address !==
                    message?.data?.personal_info?.telephone
                  ">
                  {{ fm.sender_address }}
                </span>
              </div>
              <div *ngIf="fm.actor">
                <span>{{ fm.actor.first_name }} {{ fm.actor.last_name }}</span>
              </div>
            </div>
          </div>

          <h5 i18n="@@vastausviestitUusiViestiOtsikko">Uusi viesti</h5>

          <div
            class="message closed"
            *ngIf="!numberCanReceiveFollowups()"
            i18n="@@vastausviestitNumeroonEiVoidaLahettaa">
            Asiakkaan syöttämään numeroon ei valitettavasti voida lähettää
            tekstiviestejä.
          </div>

          <div
            class="message closed"
            *ngIf="message.state !== 1 && numberCanReceiveFollowups()"
            i18n="@@vastausviestitEiKasittelyssaEiVoidaLahettaa">
            Vain käsittelyssä oleviin yhteydenottoihin voi lähettää viestejä.
          </div>

          <div
            class="message outbound form"
            *ngIf="message.state === 1 && numberCanReceiveFollowups()">
            <div class="btn-group" dropdown *ngIf="templates.length">
              <button dropdownToggle type="button" class="btn dropdown-toggle">
                <ng-container i18n="@@vastausviestitValitseViestipohja">
                  Valitse viestipohja
                </ng-container>
                <span class="caret"></span>
              </button>
              <div *dropdownMenu class="dropdown-menu" role="menu">
                <div *ngFor="let tmpl of templates">
                  <div class="dropdown-item" role="menuitem">
                    <div (click)="selectSMSTemplate(tmpl.id)">
                      {{ tmpl.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="message-content message-form">
              <textarea
                #messageContent
                id="followup-textarea"
                placeholder="Kirjoita viesti..."
                i18n-placeholder="@@vastausviestitViestikentanAputeksti"
                [(ngModel)]="newFollowupMessageContent"
                [disabled]="!hasSmsSendPermission">
              </textarea>
              <span
                *ngIf="requestType === requestTypes.IMAGE_UPLOAD"
                i18n="@@attachmentRequestLink"
                >Attachment upload link included.</span
              >
              <span
                *ngIf="requestType === requestTypes.VIDEO_CONSULTATION"
                i18n="@@videoConsultationRequestLink"
                >Video consultation link included.</span
              >
            </div>

            <section id="attachment-selectors">
              <app-toggle-switch
                *ngIf="isRequestAttachmentsEnabled"
                [label]="imageUploadLabel"
                [disabled]="requestType === requestTypes.VIDEO_CONSULTATION"
                [value]="requestType === requestTypes.IMAGE_UPLOAD"
                (updatedEvent)="
                  toggleRequestSelection($event, requestTypes.IMAGE_UPLOAD)
                ">
              </app-toggle-switch>

              <app-toggle-switch
                *ngIf="isVideoConsultationEnabled"
                [label]="videoConsultationLabel"
                [disabled]="requestType === requestTypes.IMAGE_UPLOAD"
                [value]="requestType === requestTypes.VIDEO_CONSULTATION"
                (updatedEvent)="
                  toggleRequestSelection(
                    $event,
                    requestTypes.VIDEO_CONSULTATION
                  )
                ">
              </app-toggle-switch>
            </section>

            <div class="send-sms-button">
              <button
                [disabled]="isSendMessageButtonDisabled"
                class="btn action"
                (click)="sendMessage()">
                <span i18n="@@sendSMSMessage"
                  >Send message to {{ message.contact_phone_number }}</span
                >
              </button>
            </div>
          </div>
        </section>
      </section>
    </section>
  </section>
</section>
