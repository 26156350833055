// @ts-nocheck
import { Injectable } from '@angular/core';
import { UtilsService } from 'services/utils.service';
import { getNestedValue } from 'app/shared/methods/getNestedValue';
import { Enquiry } from '../methods/enquiry/enquiry';

@Injectable()
export class SymptomsService {
  constructor(protected utilsService: UtilsService) {}

  public addSymptomDisplayNames(message: Enquiry): {} {
    const organizedSymptoms = {
      ...getNestedValue(message, 'organized_symptoms'),
    };
    if (!organizedSymptoms) {
      return;
    }

    const VASPain = getNestedValue(message, 'data', 'data_v2', 'pain');
    const painSymptomID = '32';

    Object.keys(organizedSymptoms).forEach((symptomID) => {
      const currentSymptom = organizedSymptoms[symptomID];
      currentSymptom['displayName'] =
        this.utilsService.getNameTranslation(currentSymptom);

      if (VASPain && symptomID === painSymptomID) {
        currentSymptom['displayName'] += `, VAS ${VASPain}`;
      }

      const children = getNestedValue(currentSymptom, 'children');
      if (children) {
        const namesArray = children.map((child: {}) =>
          this.utilsService.getNameTranslation(child)
        );
        currentSymptom.displayName += ` (${namesArray.join(', ')})`;
      }
    });

    return organizedSymptoms;
  }

  /**
   * Returns true if the message has a symptom duration present.
   * @param message
   * @returns boolean
   */
  public symptomDurationPresent(message: Enquiry): boolean {
    const symptomDuration = message?.data?.data_v2?.symptom_duration;

    return symptomDuration !== null && symptomDuration !== undefined;
  }

  public getSymptomDurationNumber(message: Enquiry): number {
    return message.data.data_v2?.symptom_duration?.duration_number;
  }
}
