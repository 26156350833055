// @ts-nocheck
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { getNestedValue } from 'app/shared/methods/getNestedValue';
import { Subscription } from 'rxjs';
import { StateService, StateKeys } from 'services/state.service';
import { UtilsService } from 'services/utils.service';
import { SMS_TEMPLATE_KEYS } from '../../sms-messaging.component';
import { SMSTemplatesService } from 'app/smstemplates.service';

@Component({
  selector: 'app-edit-templates',
  templateUrl: './edit-templates.component.html',
  styleUrls: ['./edit-templates.component.scss'],
})
export class EditTemplatesComponent implements OnInit, OnDestroy {
  public readonly SMSTemplateKeys = SMS_TEMPLATE_KEYS;

  @Input() hasEditPermission: boolean;

  public readonly editTemplateFormGroup: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    body: new FormControl('', Validators.required),
  });

  private editedTemplates: EditedTemplate[] = [];
  private editedTemplatesValidity: object = {};

  public existingTemplates: SMSTemplate[] = [];
  public deletingTemplate: SMSTemplate = null;
  public smsTemplateDeleteItem: DeleteItem[] = [];

  private stateChangeSubscription: Subscription =
    this.stateService.state$.subscribe((state) => {
      switch (state['key']) {
        case StateKeys.updateSMSTemplates:
          this.getTemplates();
          break;
        case StateKeys.SMSTemplateDeleted:
          const smsTemplateID = state['value'];

          for (let i = 0; i < this.existingTemplates.length; i++) {
            const smsTemplate = this.existingTemplates[i];

            if (smsTemplate.id === smsTemplateID) {
              // Remove the deleted template from local state
              this.existingTemplates.splice(i, 1);
            }
          }
          break;
      }
    });

  constructor(
    private stateService: StateService,
    private smstemplatesService: SMSTemplatesService,
    private utilsService: UtilsService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.getTemplates();
  }

  ngOnDestroy(): void {
    this.stateChangeSubscription.unsubscribe();
  }

  getTemplates(): void {
    this.smstemplatesService
      .getSMSTemplates()
      .then((response: SMSTemplate[]) => {
        // Map each sms template with changes = false to identify when to display save changes button.
        this.existingTemplates = response.map((smsTemplate) => {
          smsTemplate.changes = false;
          return smsTemplate;
        });
      })
      .catch(() => {
        this.existingTemplates = null;
      });
  }

  checkChange(index: number, key: string, event: InputUpdateEvent) {
    const existingtemplate = this.existingTemplates[index];

    // No changes if newValue is same as the existing one
    if (existingtemplate[key] === event.value) {
      existingtemplate.changes = false;
      return;
    }

    // If template has not been edited yet initiate it.
    if (getNestedValue(this.editedTemplates, `${index}`) === undefined) {
      this.editedTemplates[index] = {};
    }
    if (
      getNestedValue(this.editedTemplatesValidity, `${index}`) === undefined
    ) {
      this.editedTemplatesValidity[index] = {};
    }

    // casting to any is a hack around the typing. ES6 might make this more simple.
    this.editedTemplates[index][key] = event.value as any;
    this.editedTemplatesValidity[index][key] = event.isValid;
    existingtemplate.changes = true;
  }

  isSaveChangesDisabled(index: number) {
    if (!this.hasEditPermission) return true;

    if (!this.isTemplateEdited(index)) return true;

    if (!this.isEditedTemplateValid(index)) return true;

    return false;
  }

  isTemplateEdited(index: number) {
    // No changes detected, should not be updated
    if (this.existingTemplates[index].changes === false) {
      return false;
    }

    // No changes stored, should not be updated
    if (!this.utilsService.ObjectHasKey(this.editedTemplates, `${index}`)) {
      return false;
    }

    return true;
  }

  isEditedTemplateValid(index: number) {
    const validity: object = this.editedTemplatesValidity[index];
    for (const key of Object.keys(validity)) {
      if (!validity[key]) {
        return false;
      }
    }

    return true;
  }

  putTemplate(index: number): void {
    if (this.isSaveChangesDisabled(index)) {
      // If this happens, then there's a bug in the template. Hence not translating the message.
      this.toasterService.pop(
        'error',
        'Edited SMS template is not valid for saving'
      );
      return;
    }

    // Set template with the new values to update it with
    const template = this.existingTemplates[index];
    const editedTemplate = this.editedTemplates[index];
    Object.keys(editedTemplate).forEach((templateKey) => {
      template[templateKey] = editedTemplate[templateKey];
    });

    // Update template
    this.smstemplatesService
      .putSMSTemplate(template)
      .then((smsTemplate: SMSTemplate) => {
        delete this.editedTemplates[index];

        this.toasterService.pop(
          'success',
          $localize`:@@smsTemplateSaveSuccess:`
        );
        template.changes = false;
        template.body = smsTemplate.body;
        template.name = smsTemplate.name;
        this.existingTemplates[index] = template;
        this.existingTemplates = [...this.existingTemplates];
      })
      .catch(() =>
        this.toasterService.pop('error', $localize`:@@smsTemplateSaveError:`)
      );
  }

  promptDelete(template: SMSTemplate) {
    this.smsTemplateDeleteItem = [
      { label: $localize`:@@smsTemplateNameLabel:`, content: template.name },
      { label: $localize`:@@smsTemplateBodyLabel:`, content: template.body },
    ];
    this.deletingTemplate = template;
  }

  async deleteTemplate(deleteTemplate: boolean): Promise<void> {
    if (!deleteTemplate) {
      this.deletingTemplate = null;
      return;
    }

    await this.smstemplatesService
      .deleteSMSTemplate(this.deletingTemplate.id)
      .then(() => {
        this.toasterService.pop(
          'success',
          $localize`:@@smsTemplateDeleteSuccess:`
        );
      })
      .catch(() =>
        this.toasterService.pop('error', $localize`:@@smsTemplateDeleteError:`)
      );

    this.deletingTemplate = null;
  }
}
