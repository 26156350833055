<section class="detail-changelog">
  <h2 i18n="@@viestinMuutoksetOtsikko">Event log</h2>

  <div *ngIf="auditEvents?.length == 0" i18n="@@viestinMuutoksetEiMuutoksia">
    Ei tapahtumia.
  </div>

  <div *ngIf="auditEvents?.length > 0">
    <table class="table">
      <tbody>
        <tr *ngFor="let event of auditEvents">
          <td class="datetime">
            {{ event.created_at | date: 'medium' }}
          </td>
          <td>
            {{ getActorName(event) }}
          </td>
          <td>
            <ng-container
              *hasPermissions="permissions.MESSAGE_VIEW_FULL_AUDIT_LOG">
              {{ getLogMessage(event) }}
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="footer"></div>

  <button
    *ngIf="auditEvents === undefined"
    class="btn"
    (click)="updateAuditEvents()"
    i18n="@@loadEventLog">
    Load event log
  </button>
</section>
