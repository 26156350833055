<form
  id="user-authentication-view"
  [formGroup]="formGroup"
  (ngSubmit)="toggleMfaVerificationModal(true)">
  <p i18n="@@userMfaInfoText1" class="info-text">
    Multifactor authentication is a means to better secure your Klinik account,
    requiring a second verification method in addition to your password.
  </p>

  <p i18n="@@userMfaInfoText2" class="info-text">
    Below you can choose the authentication method you would like to use to
    secure your account.
  </p>

  <div class="inputs">
    <app-simple-dropdown
      [items]="authenticationMethods"
      [dropDownName]="'userMFAMethod'"
      [label]="
        mfaEnabled
          ? translations.selectMFAMethod
          : translations.selectedMFAMethod
      "
      [isDisabled]="mfaEnabled"
      [preSetValue]="formGroup.controls['mfaMethod'].value"
      (onChangeEmitter)="updateValue('mfaMethod', $event)">
    </app-simple-dropdown>
  </div>

  <button
    type="submit"
    [disabled]="!formGroup.controls['mfaMethod'].value"
    [ngClass]="{ action: !mfaEnabled, delete: mfaEnabled }">
    <span *ngIf="!mfaEnabled" i18n="@@enableMFA"
      >Enable multifactor authentication</span
    >
    <span *ngIf="mfaEnabled" i18n="@@disableMFA"
      >Disable multifactor authentication</span
    >
  </button>
</form>

<div
  #mfaVerificationModal="bs-modal"
  class="modal fade mfa-verification-modal"
  bsModal
  tabindex="-1"
  role="dialog"
  [config]="{ backdrop: true, keyboard: false }">
  <div
    class="modal-close-wrapper"
    (click)="toggleMfaVerificationModal(false)"></div>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <mfa-verification
        *ngIf="mfaVerificationModalVisible"
        [maskedUsername]="userDetails.username"
        [sessionId]="sessionService.getSessionId()"
        [backButtonText]="translations.backButtonText"
        (backToPreviousPageEmitter)="toggleMfaVerificationModal(false)"
        (onVerified)="saveChanges()"></mfa-verification>
    </div>
  </div>
</div>
