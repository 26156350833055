// @ts-nocheck
import { Injectable } from '@angular/core';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { environment } from 'environments/environment';

import { HttpWrapperService } from './http-wrapper.service';
import { StateService, StateKeys } from './state.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToasterService } from 'angular2-toaster';
import { HttpTools } from 'tools/httptools';

export enum INTEGRATION_RESPONSE {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export enum EXTERNAL_FORWARD_TYPES {
  FI = 'FOR_INFORMATION',
  FOR_INFORMATION = 'FI',
  FA = 'FOR_ACTION',
  FOR_ACTION = 'FA',
}

export enum EXTERNAL_FORWARD_STATUSES {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
}

enum EMIS_POST_TYPES {
  SEND,
  FIND_PATIENTS,
}

@Injectable()
export class IntegrationService {
  private readonly permissions = Permissions;

  public kipInstallerDownloadUrl = '';
  public latestVersion: {} = null;

  constructor(
    private http: HttpClient,
    private httpWrapper: HttpWrapperService,
    protected stateService: StateService,
    protected httpTools: HttpTools,
    private toaster: ToasterService
  ) {}

  catchError(error) {
    if (environment.production !== true) {
      this.errorToaster();
    }
  }

  async getEMISSearchRequestXML(messageId) {
    try {
      const kipStatus = await this.getKIPStatus();
      this.stateService.setState(StateKeys.emisIntegrationAvailable, kipStatus);

      if (kipStatus) {
        this.stateService.setState(StateKeys.findingPatientsFromPrs, true);
        const searchRequest = await this.httpWrapper.get(
          '/integrations/get-emis-search-request-xml/' + messageId
        );
        await this.postToEMIS(searchRequest, 1);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getEMISFileRecordXML(messageId, selections) {
    try {
      const kipStatus = await this.getKIPStatus();
      this.stateService.setState(StateKeys.emisIntegrationAvailable, kipStatus);

      if (kipStatus) {
        const fileRecordRequest = await this.httpWrapper.deprecated_post(
          '/integrations/get-emis-file-record-xml/',
          {
            message_id: messageId,
            selections: Array.from(selections.values()),
          }
        );

        // const response = await this.http.post(environment.kip_url + '/emis-server-side', fileRecordRequest['xml_data']).toPromise();
        // const response = await this.http.post(environment.emis_server_side, fileRecordRequest['xml_data']).toPromise();
        const response = await this.postToEMIS(
          fileRecordRequest['xml_data'],
          1
        );

        if (response['data'] === 'Ok') {
          await this.httpWrapper.deprecated_post(
            '/integrations/mark-message-data-as-sent-to-emis',
            fileRecordRequest['audit_event_data']
          );
          this.stateService.setState(
            StateKeys.emisFileRecordPostStatus,
            INTEGRATION_RESPONSE.SUCCESS
          );
        } else {
          this.stateService.setState(
            StateKeys.emisFileRecordPostStatus,
            INTEGRATION_RESPONSE.FAIL
          );
        }
      } else {
        this.stateService.setState(
          StateKeys.emisFileRecordPostStatus,
          INTEGRATION_RESPONSE.FAIL
        );
      }
    } catch (error) {
      console.log(error);
      this.stateService.setState(
        StateKeys.emisFileRecordPostStatus,
        INTEGRATION_RESPONSE.FAIL
      );
    }
  }

  async getEMISFileAttachmentXML(messageId, attachmentIds) {
    try {
      const kipStatus = await this.getKIPStatus();
      this.stateService.setState(StateKeys.emisIntegrationAvailable, kipStatus);

      if (kipStatus) {
        for (const attachmentId of Array.from(attachmentIds.values())) {
          const fileAttachmentRequest = await this.httpWrapper.get(
            `/integrations/get-emis-file-attachment-xml/${messageId}/${attachmentId}`
          );

          // const response = await this.http.post(environment.kip_url + '/emis-server-side', fileAttachmentRequest['xml_data']).toPromise();
          // const response = await this.http.post(environment.emis_server_side, fileAttachmentRequest['xml_data']).toPromise();
          const response = await this.postToEMIS(
            fileAttachmentRequest['xml_data'],
            1
          );

          if (response['data'] === 'Ok') {
            await this.httpWrapper.deprecated_post(
              '/integrations/mark-attachments-as-sent-to-emis',
              { attachment_id: attachmentId, message_id: messageId }
            );
            this.stateService.setState(
              StateKeys.emisAttachmentsPostStatus,
              INTEGRATION_RESPONSE.SUCCESS
            );
          } else {
            this.stateService.setState(
              StateKeys.emisAttachmentsPostStatus,
              INTEGRATION_RESPONSE.FAIL
            );
          }
        }
      } else {
        this.stateService.setState(
          StateKeys.emisAttachmentsPostStatus,
          INTEGRATION_RESPONSE.FAIL
        );
      }
    } catch (error) {
      console.log(error);
      this.stateService.setState(
        StateKeys.emisAttachmentsPostStatus,
        INTEGRATION_RESPONSE.FAIL
      );
    }
  }

  async getEMISSMSFollowupMessagesXML(followupIds) {
    try {
      const kipStatus = await this.getKIPStatus();
      this.stateService.setState(StateKeys.emisIntegrationAvailable, kipStatus);

      if (kipStatus) {
        const followupRequest = await this.httpWrapper.deprecated_post(
          '/integrations/get-emis-sms-messages-xml',
          followupIds
        );

        // const response = await this.http.post(environment.kip_url + '/emis-server-side', followupRequest['xml_data']).toPromise();
        // const response = await this.http.post(environment.emis_server_side, followupRequest['xml_data']).toPromise();
        const response = await this.postToEMIS(followupRequest['xml_data'], 1);

        if (response['data'] === 'Ok') {
          await this.httpWrapper.deprecated_post(
            '/integrations/mark-followups-as-sent-to-emis',
            {
              followup_ids: followupIds,
              message_id: followupRequest['message_id'],
            }
          );
          this.toaster.pop(
            'success',
            'OK',
            $localize`:@@smsDataMovedToRecord:`
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  /***
   * Post constructed XML to EMIS.
   * @param body
   * @param send_type
   */
  async postToEMIS(body, send_type: EMIS_POST_TYPES = EMIS_POST_TYPES.SEND) {
    try {
      const headers = this.httpTools.addAuthenticationHeaders(
        new HttpHeaders()
      );

      const response = await this.http
        .post(environment.kip_url + '/emis-server-side', body, {
          headers: headers,
        })
        .toPromise();

      if (response) {
        switch (send_type) {
          case EMIS_POST_TYPES.SEND:
            this.toaster.pop(
              'OK',
              'OK',
              'Contact request data sent to EMIS Web'
            );
            break;
          case EMIS_POST_TYPES.FIND_PATIENTS:
            if (response['data'] === 'Ok') {
              response['data'] = '{"Patient": []}';
            }

            let data = JSON.parse(response['data']);
            data = {
              source: 'emis',
              items: Array.isArray(data['Patient'])
                ? data['Patient']
                : [data['Patient']],
            };

            this.stateService.setState(StateKeys.emisPatientSearchResult, data);
            this.stateService.setState(StateKeys.findingPatientsFromPrs, false);
            break;
        }
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }

  errorToaster(translation_key: string = 'prsErrorUnableToMoveData') {
    this.toaster.pop('error', 'ERROR', $localize`:@@prsErrorUnableToMoveData:`);
  }

  async getLatestKIPVersion(): Promise<boolean> {
    try {
      this.latestVersion = await this.httpWrapper.get(
        '/integrations/get-latest-version-info'
      );
      this.kipInstallerDownloadUrl = this.latestVersion['download_url'];
      return true;
    } catch (error) {
      return false;
    }
  }

  async getKIPStatus() {
    const httpOptions = { headers: new HttpHeaders({}) };
    httpOptions.headers.set('access-control-allow-origin', environment.kip_url);
    httpOptions.headers.set('access-control-allow-headers', 'content-type');

    const url = environment.kip_url + '/status';

    try {
      const status = await this.http
        .get(url, { headers: httpOptions.headers, responseType: 'json' })
        .toPromise();
      await this.getLatestKIPVersion();
      const kipUpdateAvailable = this.isKIPUpdateAvailable(
        status,
        this.latestVersion
      );
      this.stateService.setState(
        StateKeys.newKIPVersionAvailable,
        kipUpdateAvailable
      );
      return status;
    } catch (error) {
      return false;
    }
  }

  isKIPUpdateAvailable(installed, latest): boolean {
    const installedMainAppVersion = this.getSemanticVersionObject(
      installed['main_app_version']
    );
    const installedProxyVersion = this.getSemanticVersionObject(
      installed['proxy_version']
    );

    const latestMainAppVersion = this.getSemanticVersionObject(
      latest['main_app_version']
    );
    const latestProxyVersion = this.getSemanticVersionObject(
      latest['proxy_version']
    );

    if (installedMainAppVersion.major !== latestMainAppVersion.major) {
      return true;
    }
    if (installedMainAppVersion.minor !== latestMainAppVersion.minor) {
      return true;
    }
    if (installedMainAppVersion.patch !== latestMainAppVersion.patch) {
      return true;
    }

    if (installedProxyVersion.major !== latestProxyVersion.major) {
      return true;
    }
    if (installedProxyVersion.minor !== latestProxyVersion.minor) {
      return true;
    }
    if (installedProxyVersion.patch !== latestProxyVersion.patch) {
      return true;
    }
    return false;
  }

  async checkIntegrationAvailable() {
    try {
      const kipStatus = await this.getKIPStatus();
      this.stateService.setState(StateKeys.emisIntegrationAvailable, kipStatus);
    } catch (error) {
      this.stateService.setState(StateKeys.emisIntegrationAvailable, false);
    }
  }

  getSemanticVersionObject(versionString: string) {
    const arr: string[] = versionString.split('.');
    return {
      major: parseInt(arr[0]),
      minor: parseInt(arr[1]),
      patch: parseInt(arr[2]),
    };
  }

  setEmisPatientId(message: object, emis_patient_id: string, nhsNumber): void {
    this.httpWrapper
      .post('/integrations/' + message['id'] + '/set-emis-patient-id/', {
        emis_patient_id: emis_patient_id,
        nhs_number: nhsNumber,
      })
      .subscribe(
        (result) => {
          this.toaster.pop(
            'success',
            'OK',
            $localize`:@@patientConnectedToRecord:`
          );
          this.stateService.setState(StateKeys.selectedMessage, message);
          this.stateService.setState(
            StateKeys.emisPatientConnectedToRecordStatus,
            INTEGRATION_RESPONSE.SUCCESS
          );
        },
        (error) => {
          this.toaster.pop(
            'error',
            'ERROR',
            $localize`:@@prsErrorUnableConnectToPrs:`
          );
          this.stateService.setState(
            StateKeys.emisPatientConnectedToRecordStatus,
            INTEGRATION_RESPONSE.FAIL
          );
        }
      );
  }

  async postGPconnectData(
    messageUuid: string,
    selections: Set<string>,
    attachmentIds: Set<number>,
    practiceCode: {},
    purpose: string
  ): Promise<void> {
    try {
      const requestBody = {
        message: messageUuid,
        selections: Array.from(selections.values()),
        attachments: Array.from(attachmentIds.values()),
        practice_code: practiceCode,
        purpose: purpose,
      };

      await this.httpWrapper
        .post('/gpconnect/message', requestBody)
        .toPromise();
      this.stateService.setState(
        StateKeys.gpConnectSendDocumentStatus,
        INTEGRATION_RESPONSE.SUCCESS
      );
    } catch (errors) {
      if (environment.production === false) {
        console.error(errors);
      }
      this.stateService.setState(
        StateKeys.gpConnectSendDocumentStatus,
        INTEGRATION_RESPONSE.FAIL
      );
    }
  }

  async getExternalForwards(messageUuid: string) {
    if (!messageUuid) {
      console.log(
        'Unable to get external forwards. Message UUID is not available.'
      );
      return;
    }

    await this.httpWrapper
      .get(`/gpconnect/${messageUuid}/external_forwards`)
      .then((result: any) => {
        const data = { externalForwards: result, messageUuid: messageUuid };
        this.stateService.setState(
          StateKeys.loadedExternalForwardStatuses,
          data
        );
      })
      .catch((error) => this.catchError(error));
  }
}
