//* Enums that you aim to use during application runtime should be defined here.
//* While declaring enums in the enums.d.ts file is convinient because you don't need to import them in every file, it's not a good practise for enums that you aim to use during application runtime.

export enum LoadingStatus {
  NONE = 'NONE',
  LOADING = 'LOADING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  POLLING = 'POLLING',
}

export enum AccessGroupName {
  SERVICE_PERSONNEL_ROLE = 'service_personnel',
  SERVICE_ADMIN_ROLE = 'service_admin',
  SERVICE_GROUP_ADMIN_ROLE = 'service_group_admin',
  SYSTEM_ADMIN_ROLE = 'system_admin',
  RESTRICTED_USER_ROLE = 'restricted_user',
}
