<div class="dialog-wrapper">
  <!-- Dialog body content -->
  <div>
    <h2 i18n="@@confirmPatientIdentity">Verify patient identity</h2>
    <p>
      {{ modalDescription }}
    </p>

    <div [ngSwitch]="confirmApiStatus">
      <!-- The confirmation is done -->
      <div *ngSwitchCase="LoadingStatus.SUCCESS" class="status">
        <i class="fa fa-check-circle-o"></i>
        <p i18n="@@identityConfirmed">Identity verified</p>
      </div>
      <!-- The confimation failed -->
      <div *ngSwitchCase="LoadingStatus.FAILED" class="status">
        <i class="fa fa-exclamation-circle"></i>
        <p>{{ errorMessage }}</p>
      </div>
      <!-- Suggestion details provided by PDS (Before user confirms) -->
      <div class="suggestion-details" *ngSwitchDefault>
        <p *ngFor="let s of suggestionDetails | keyvalue: noSort">
          {{ s.key }}: <span>{{ s.value }}</span>
        </p>
      </div>
    </div>
  </div>

  <!-- Dialog footer -->
  <mat-card-footer>
    <mat-divider></mat-divider>
    <mat-card-actions>
      <button
        mat-stroked-button
        (click)="dialogRef.close()"
        i18n="@@closeWindow">
        Close window
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="
          confirmApiStatus == LoadingStatus.SUCCESS
            ? continue()
            : confirmIdentity()
        "
        [disabled]="actionButtonDisabled">
        {{ actionButtonText }}
      </button>
    </mat-card-actions>
  </mat-card-footer>
</div>
