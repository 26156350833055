// @ts-nocheck
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { Subscription } from 'rxjs';
import { MessageService } from 'services/message.service';
import { SessionService } from 'services/session.service';
import {
  StateService,
  StateKeys,
  singleMessageEvent,
} from 'services/state.service';
import { UtilsService } from 'services/utils.service';
import { Enquiry } from 'app/shared/methods/enquiry/enquiry';
import { LoadingStatus } from 'enums';

@Component({
  selector: 'app-messages-detail-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public message: Enquiry;

  public commentForm: FormGroup;
  public commentEditForm: FormGroup;
  public isCommentingEnabled = this.sessionService.isCommentingEnabled;
  public showCommentArea = false;
  public editing_comment: number;
  public userPermissions: Set<string> = new Set();

  public readonly permissions = Permissions;

  private submittingComment = false;
  private editingComment = false;
  private stateChangeSubscription: Subscription;

  private readonly session = this.sessionService.getSession();

  constructor(
    private utilsService: UtilsService,
    protected stateService: StateService,
    protected messageService: MessageService,
    fb: FormBuilder,
    private toasterService: ToasterService,
    private sessionService: SessionService
  ) {
    this.commentForm = fb.group({
      content: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.maxLength(600),
      ]),
    });

    this.commentEditForm = fb.group({
      id: new FormControl({ value: 0, disabled: false }, [Validators.required]),
      content: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.maxLength(600),
      ]),
    });
  }

  ngOnInit() {
    this.userPermissions = this.utilsService.filterUserPemsissions([
      this.permissions.MESSAGE_CREATE_COMMENT,
    ]);

    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.loadedServiceGroup:
            this.isCommentingEnabled = this.sessionService.isCommentingEnabled;
            break;
          case StateKeys.singleMessage:
            const event = state['value'] as singleMessageEvent;
            if (event.status !== LoadingStatus.SUCCESS) return;
            this.showCommentArea = false;
            break;
          case StateKeys.messageCommented:
            this.messageService.getMessage(this.message.id);
            this.commentForm.get('content').setValue('');
            this.toasterService.pop(
              'success',
              $localize`:@@commentSuccessMessage:`
            );
            break;
          case StateKeys.messageCommentEdited:
            this.messageService.getMessage(this.message.id);
            this.clearEditForm();
            this.toasterService.pop(
              'success',
              $localize`:@@commentEditSuccessMessage:`
            );
            break;
          case StateKeys.messageCommentEditFailed:
            this.messageService.getMessage(this.message.id);
            this.clearEditForm();
            this.toasterService.pop(
              'error',
              $localize`:@@commentEditFailMessage:`
            );
            break;
        }
      }
    );
  }

  ngOnChanges(): void {
    this.getEditFlags();
  }

  ngOnDestroy(): void {
    this.stateChangeSubscription.unsubscribe();
  }

  toggleCommentArea(): void {
    this.showCommentArea = !this.showCommentArea;
  }

  clearEditForm(): void {
    this.commentEditForm.get('id').setValue('');
    this.commentEditForm.get('content').setValue('');
    this.editing_comment = null;
  }

  getEditFlags(): void | boolean {
    if (!this.message) {
      return false;
    }

    for (const comment of this.message.comments) {
      if (
        comment.editable_by_date &&
        comment.user_id === this.session['id'] &&
        comment.service_to == null
      ) {
        comment['canEdit'] = true;
      }
    }
  }

  OnSubmit(formValue): void {
    if (
      (this.commentForm.invalid && !this.isCommentingEnabled) ||
      this.submittingComment
    ) {
      this.submittingComment = false;
      return;
    }
    this.submittingComment = true;
    this.messageService.commentMessage(this.message.id, formValue.content);
    this.submittingComment = false;
  }

  OnEditSubmit(formValue): void {
    if (
      (this.commentEditForm.invalid && !this.isCommentingEnabled) ||
      this.editingComment
    ) {
      this.editingComment = false;
      return;
    }
    this.editingComment = true;
    this.messageService.editMessageComment(formValue.id, formValue.content);
    this.editingComment = false;
  }

  changeEditComment(comment: { id: number; content: string }): void {
    if (comment && this.editing_comment !== comment.id) {
      this.commentEditForm.get('id').setValue(comment.id);
      this.commentEditForm.get('content').setValue(comment.content);
      this.editing_comment = comment.id;
      return;
    }
    this.clearEditForm();
  }
}
