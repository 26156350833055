<section class="prompt">
  <i class="close fa fa-times-circle" (click)="emitClick(false)"></i>

  <div class="info">
    <i class="fa fa-info"></i>
  </div>

  <div class="prompt-content">
    <div>
      <h2>{{ translations['header'] }}</h2>
      <p>{{ translations['prompt'] }}</p>
    </div>

    <div class="buttons">
      <button type="button" (click)="emitClick(false)">
        <i class="warning fa fa-times"></i>{{ translations['no'] }}
      </button>
      <button type="button" (click)="emitClick(true)">
        <i class="success fa fa-check"></i>{{ translations['yes'] }}
      </button>
    </div>
  </div>
</section>
