<form
  [formGroup]="formGroup"
  (ngSubmit)="saveChanges()"
  id="authentication-form">
  <h1 i18n="@@authSettingsPageTitle">Authentication</h1>

  <p class="work-in-progress">
    This feature is a work in progress and no actual functionality is included
    yet.
  </p>

  <app-toggle-switch
    [label]="translations.mfaEnabled"
    [value]="formGroup.get('mfaEnabled')?.value"
    (updatedEvent)="updateValue('mfaEnabled', $event)">
  </app-toggle-switch>

  <app-toggle-switch
    [label]="translations.mfaRequired"
    [value]="formGroup.get('mfaRequired')?.value"
    (updatedEvent)="updateValue('mfaRequired', $event)">
  </app-toggle-switch>

  <app-simple-dropdown
    [items]="authenticationMethods"
    [dropDownName]="'mfaMethod'"
    [label]="translations.mfaMethod"
    (onChangeEmitter)="updateValue('mfaMethod', $event)">
  </app-simple-dropdown>

  <div class="button-group">
    <button type="submit" class="btn save" i18n="@@authSettingsSave">
      Save changes
    </button>
  </div>
</form>
