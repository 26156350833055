// @ts-nocheck
/**
 * Returns true if a value exists in a enum and false if not.
 */
export function valueInEnum(value: any, _enum: any): boolean {
  let valueInEnum = false;
  // Loop through .keys instead of .values because of TS version
  const enumKeys = Object.keys(_enum);

  // IE11 does not support .includes(), thus loop and exact matching
  for (let i = 0; i < enumKeys.length; i++) {
    const key = enumKeys[i];
    if (_enum[key] === value) {
      valueInEnum = true;
      break;
    }
  }

  return valueInEnum;
}
