// @ts-nocheck
import {
  customOptionsBarNoAutoskip,
  customOptionsBarPlugin,
  defaultOptions,
  defaultOptionsPie,
} from '../configs/chartJSDefaultConfigs';

const translations = [
  $localize`:@@sidebar_outcomes:`,
  $localize`:@@sidebar_enquiry-outcome:`,
  $localize`:@@sidebar_resource-outcome:`,
];

export const outcomes: View = {
  'enquiry-outcome': {
    id: 'enquiry-outcome',
    charts: [
      {
        chart_config: {
          id: 'enquiry-outcome',
          legend: $localize`:@@enquiryOutcome:`,
          type: ChartType.BAR,
          options: customOptionsBarNoAutoskip,
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.NONE,
        datasets: ['procedures'],
        translations: {
          labels: {
            services: $localize`:@@contactsByUnit:`,
          },
        },
      },
    ],
  },

  'resource-outcome': {
    id: 'resource-outcome',
    charts: [
      {
        chart_config: {
          id: 'resource-outcome',
          legend: $localize`:@@resourceOutcome:`,
          type: ChartType.BAR,
          options: customOptionsBarNoAutoskip,
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.NONE,
        datasets: ['resources'],
        translations: {
          labels: {
            services: $localize`:@@contactsByUnit:`,
          },
        },
      },
    ],
  },
};
