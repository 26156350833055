// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { Subscription } from 'rxjs';
import { AppointmentType, MessageService } from 'services/message.service';
import { SessionService } from 'services/session.service';
import {
  StateService,
  StateKeys,
  appointmentTypesEvent,
} from 'services/state.service';
import { UserService } from 'services/user.service';
import { UtilsService } from 'services/utils.service';
import { ServiceGroupService } from 'services/service-group.service';
import { ToasterService } from 'angular2-toaster';
import { DropdownTranslations } from 'app/utils/dropdowns/dropdown/dropdown.component';
import { environment } from 'environments/environment';
import { addInactiveToServiceName } from 'app/utils/serviceUtils';
import { LoadingStatus } from 'enums';

@Component({
  selector: 'app-messages-list-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  private stateChangeSubscription: Subscription;
  public appointmentTypeId = -1;
  public appointmentTypes: { id: number; name: string }[] = [];
  public appointmentType: object = null;
  public priorityIds: Array<number> = [];
  public drawerOpen = false;
  public searchTerm: string = null;
  public ssnSearch: string = null;
  public searchAppointmentId: string = null;
  public localAppointmentNamesTranslationStore = {};
  public lastFiltered = {
    enteredSsnSearch: null,
    enteredAppointmentIdSearch: null,
    enteredSearchTerm: null,
  };
  public user: object;
  public ssnSearchVisible: boolean;
  public priorities = [
    {
      value: [-1, 0],
      name: $localize`:@@non_urgent:`,
      checked: true,
    },
    {
      value: [1],
      name: $localize`:@@urgent:`,
      checked: true,
    },
    {
      value: [2],
      name: $localize`:@@emergency:`,
      checked: true,
    },
  ];
  public serviceFilterOptions: DropdownItem[] = [];
  public appointmentTypeFilterOptions: DropdownItem[] = [];

  get serviceDropdownTranslations(): DropdownTranslations {
    return {
      deselectAll: $localize`:@@dropdownDeselectAll:`,
      selectAll: $localize`:@@dropdownSelectAll:`,
      noUnitsAvailable: $localize`:@@servicesDropdownNoAvailable:`,
      allUnitsSelected: $localize`:@@servicesDropdownAllSelected:`,
      noUnitsSelected: $localize`:@@servicesDropdownNoSelected:`,
      nUnitsSelected: $localize`:@@servicesDropdownNSelected:`,
    };
  }

  get appointmentTypeDropdownTranslations(): DropdownTranslations {
    return {
      deselectAll: $localize`:@@dropdownDeselectAll:`,
      selectAll: $localize`:@@dropdownSelectAll:`,
      noUnitsAvailable: $localize`:@@contact_typesDropdownNoAvailable:`,
      allUnitsSelected: $localize`:@@contact_typesDropdownAllSelected:`,
      noUnitsSelected: $localize`:@@contact_typesDropdownNoSelected:`,
      nUnitsSelected: $localize`:@@contact_typesDropdownNSelected:`,
    };
  }
  get priorityDropdownTranslations(): DropdownTranslations {
    return {
      deselectAll: $localize`:@@dropdownDeselectAll:`,
      selectAll: $localize`:@@dropdownSelectAll:`,
      noUnitsAvailable: $localize`:@@filter_prioritiesDropdownNoAvailable:`,
      allUnitsSelected: $localize`:@@filter_prioritiesDropdownAllSelected:`,
      noUnitsSelected: $localize`:@@filter_prioritiesDropdownNoSelected:`,
      nUnitsSelected: $localize`:@@filter_prioritiesDropdownNSelected:`,
    };
  }
  get priorityFilterOptions(): DropdownItem[] {
    return this.priorities;
  }

  constructor(
    protected messageService: MessageService,
    protected sessionService: SessionService,
    protected stateService: StateService,
    protected userService: UserService,
    protected serviceGroupService: ServiceGroupService,
    protected configuration: Configuration,
    private utilsService: UtilsService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.user = this.sessionService.getSession();
    this.ssnSearchVisible = this.getSsnSearchVisible();
    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.selectedPriorities:
            this.priorityIds = state['value'];
            break;
          case StateKeys.loadedUserServices:
            this.serviceFilterOptions = this.createServiceFilterOptions(
              state['value'] as Service[]
            );
            break;
          case StateKeys.resetFilter:
            switch (state['value']['filter']) {
              case 'searchTerm':
                this.searchTerm = null;
                break;
              case 'appointmentType':
                this.appointmentType = null;
                this.appointmentTypeId = -1;
                break;
              case 'priorityIds':
                this.priorityIds = [];
                break;
            }
            break;
          case StateKeys.appointmentTypes:
            const event = state['value'] as appointmentTypesEvent;
            if (event.status !== LoadingStatus.SUCCESS) return;
            this.appointmentTypeFilterOptions =
              this.getAppointmentTypeFilterOptions();
        }
      }
    );

    this.serviceGroupService.getActiveServiceGroup().catch((reason) => {
      this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`);
    });
    this.userService.getUserServices();
    this.messageService.fetchAppointmentTypes();
  }

  ngOnDestroy() {
    this.stateChangeSubscription.unsubscribe();
  }

  toggleDrawer() {
    this.drawerOpen = !this.drawerOpen;
    this.stateService.setState(StateKeys.toggledDrawer, this.drawerOpen);
  }

  filterByPriority(priorities: Array<number>) {
    this.priorityIds = priorities;
    this.stateService.setState(StateKeys.selectedPriorities, this.priorityIds);
  }

  getAppointmentTypeFilterOptions(): DropdownItem[] {
    const dropDownItems: DropdownItem[] = [];

    // Sort parent types alphabetically
    const sortedParentTypes = this.messageService.appointmentTypes.sort(
      (a, b) => {
        const aName = this.getTranslatedAppointmentTypeName(a);
        const bName = this.getTranslatedAppointmentTypeName(b);
        return aName.localeCompare(bName);
      }
    );

    sortedParentTypes.forEach((type) => {
      // Add parent type to dropdown...
      dropDownItems.push({
        name: this.getTranslatedAppointmentTypeName(type),
        value: type.id,
        checked: true,
      });

      //... Followed by its sorted subtypes
      const sortedSubTypes = type.sub_appointment_types.sort((a, b) => {
        const aName = this.getTranslatedAppointmentTypeName(
          a as AppointmentType
        );
        const bName = this.getTranslatedAppointmentTypeName(
          b as AppointmentType
        );
        return aName.localeCompare(bName);
      });
      sortedSubTypes.forEach((subType) => {
        dropDownItems.push({
          name: this.getTranslatedAppointmentTypeName(
            subType as AppointmentType
          ),
          value: subType.id,
          checked: true,
          // We need to distinguish between the items to later use them as separate filter params
          isSecondary: true,
        });
      });
    });

    return dropDownItems;
  }

  getTranslatedAppointmentTypeName(appointmentType: AppointmentType) {
    const locale = this.utilsService.currentLocale;

    const shortNameTranslation = appointmentType?.[`short_name_${locale}`];
    if (shortNameTranslation) return shortNameTranslation;

    const nameTranslation = appointmentType?.[`name_${locale}`];
    if (nameTranslation) return nameTranslation;

    const fallbackValue = appointmentType?.name;
    if (fallbackValue) return fallbackValue;

    return environment.translationMissingMessage[locale];
  }

  getAppointmentTypeShortName(appointmentType) {
    // when appointment name fetched for the first time, store value locally to avoid unnecessary method calls
    let translation =
      this.localAppointmentNamesTranslationStore[appointmentType.id];
    if (translation === undefined) {
      if (
        'translations' in appointmentType === false &&
        'name' in appointmentType
      ) {
        translation = appointmentType.name;
      } else {
        translation = this.utilsService.getFieldTranslation(
          appointmentType,
          'short_name'
        );
      }
    }

    this.localAppointmentNamesTranslationStore[appointmentType.id] =
      translation;

    return translation;
  }

  getSsnSearchVisible(): boolean {
    let serviceGroup = this.stateService.getState(StateKeys.loadedServiceGroup);
    return serviceGroup && serviceGroup.country_code === 'fi';
  }

  filterMessageList(key: string, value: string | number | object): void {
    if (this.lastFiltered[key] !== value) {
      // Filter only if not yet filtered with selected value
      this.lastFiltered[key] = value;
      this.stateService.setState(StateKeys[key], value);
    }
  }
  handlePriorityChange(selectedPriorities: DropdownItem[]) {
    // TODO: get rid of this wrapper function once filterByPriority is refactored
    const values = selectedPriorities.map(
      (priority) => priority.value
    ) as number[];
    const priorityIds = values.reduce((acc, val) => acc.concat(val), []);
    this.filterByPriority(priorityIds);
  }

  handleServiceChange(selectedServices: DropdownItem[]) {
    this.stateService.setState(StateKeys.filteredByservices, selectedServices);
  }

  handleAppointmentTypeChange(selectedAppointmentTypes: DropdownItem[]) {
    this.stateService.setState(
      StateKeys.filteredByAppointmentTypes,
      selectedAppointmentTypes
    );
  }

  createServiceFilterOptions(services: Service[]): DropdownItem[] {
    return services.map((service) => {
      const serviceName = addInactiveToServiceName(service);

      return {
        name: serviceName,
        value: service.id,
        checked: true,
      };
    });
  }
}
