<div class="messages-list-tabs-titles">
  <div
    class="tab tab-messages"
    (click)="activateTab('messages')"
    [class.has-items]="newMessageCount > 0"
    [class.new-items]="highlightedTabs.indexOf('messages') > -1"
    [class.active]="activeTab === 'messages'">
    <div class="counts">
      <span
        class="new-message-count"
        *ngIf="newMessageCount > 0"
        title="{{ newHover }}">
        {{ newMessageCount }}
      </span>
      <span
        class="emergency-message-count"
        *ngIf="emergencyMessageCount > 0"
        title="{{ newEmergencyHover }}">
        {{ emergencyMessageCount }}
      </span>
      <span
        class="urgent-message-count"
        *ngIf="urgentMessageCount > 0"
        title="{{ newUrgentHover }}">
        {{ urgentMessageCount }}
      </span>
    </div>
    <ng-container i18n="@@viestilistaValilehtiUudetYhteydenotot"
      >New contact requests</ng-container
    >
  </div>
  <div
    class="tab tab-followup"
    (click)="activateTab('followups')"
    [class.has-items]="newFollowupsCount > 0"
    [class.new-items]="highlightedTabs.indexOf('followups') > -1"
    [class.active]="activeTab === 'followups'">
    <span class="count" *ngIf="newFollowupsCount > 0">
      {{ newFollowupsCount }}
    </span>
    <ng-container i18n="@@viestilistaValilehtiUudetViestit"
      >New messages</ng-container
    >
  </div>
  <div
    class="tab tab-own-messages"
    (click)="activateTab('own-messages')"
    [class.new-items]="highlightedTabs.indexOf('own-messages') > -1"
    [class.active]="activeTab === 'own-messages'">
    <span i18n="@@viestilistaValilehtiOmatAsioinnit">My cases</span>
  </div>
  <div
    class="tab tab-pending-messages"
    (click)="activateTab('pending-messages')"
    [class.new-items]="highlightedTabs.indexOf('pending-messages') > -1"
    [class.active]="activeTab === 'pending-messages'"
    *ngIf="showPendingTab">
    <span i18n="@@pending">Pending</span>
  </div>
  <div
    class="tab tab-ready"
    (click)="activateTab('ready')"
    [class.new-items]="highlightedTabs.indexOf('ready') > -1"
    [class.active]="activeTab === 'ready'">
    <span i18n="@@viestilistaValilehtiArkisto"> Archive </span>
  </div>
</div>

<div
  class="messages-list-tabs-content"
  [class.followups]="activeTab === 'followups'">
  <div class="table" [class.paged]="totalMessageCount > pageSize">
    <div
      class="table-filters"
      *ngIf="searchTerm || searchSsn || stateIds.toString() === '0'">
      <span *ngIf="stateIds.toString() === '0'">
        <ng-container i18n="@@viestilistaVainKasittelemattomat"
          >Status: only unhandled</ng-container
        >
        <i class="fa fa-times" (click)="resetFilter('stateIds', true)"></i>
      </span>
      <span *ngIf="searchTerm">
        <ng-container i18n="@@viestilistaLahettajaaHaettu"
          >Sender:</ng-container
        >
        {{ searchTerm }}
        <i class="fa fa-times" (click)="resetFilter('searchTerm', true)"></i>
      </span>
      <span *ngIf="searchSsn">
        <ng-container i18n="@@viestlistanSuodatusHakuHetu">SSN</ng-container>:
        {{ searchSsn }}
      </span>
    </div>

    <div class="table-header">
      <!-- STATE HEADER -->
      <div class="state">
        <div
          *ngIf="orderBy.includes(orderTypes.in_progress_first)"
          class="order-toggle"
          (click)="setOrdering(orderTypes.new_state_first)">
          <span>
            <i
              class="icon-circle"
              aria-hidden="true"
              title="{{ newHover }}"></i>
            <i class="fa fa-caret-up" aria-hidden="true"></i>
          </span>
        </div>

        <div
          *ngIf="orderBy.includes(orderTypes.new_state_first)"
          class="order-toggle"
          (click)="setOrdering(orderTypes.in_progress_first)">
          <span>
            <i
              class="icon-circle"
              aria-hidden="true"
              title="{{ newHover }}"></i>
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </span>
        </div>
        <div
          *ngIf="
            !orderBy.includes(orderTypes.in_progress_first) &&
            !orderBy.includes(orderTypes.new_state_first)
          "
          class="order-toggle"
          (click)="setOrdering(orderTypes.new_state_first)">
          <span>
            <i
              class="icon-circle"
              aria-hidden="true"
              title="{{ newHover }}"></i>
          </span>
        </div>
      </div>

      <!-- PRIORITY HEADER -->
      <div class="priority" *ngIf="showPriority">
        <div class="order-toggle" (click)="togglePriorityOrder()">
          <span>
            <i
              class="fa fa-exclamation-circle"
              title="{{ urgentHover }}"
              aria-hidden="true"></i>
            <!-- Carrot indicating  wether the list is ordered by priority -->
            <i
              class="fa fa-caret-up"
              aria-hidden="true"
              *ngIf="orderBy.includes(orderTypes.most_urgent_first)"></i>
            <i
              class="fa fa-caret-down"
              aria-hidden="true"
              *ngIf="orderBy.includes(orderTypes.least_urgent_first)"></i>
          </span>
        </div>
      </div>

      <!-- FLAGGED_BY_PROFESSIONAL HEADER -->
      <div class="flagged_by_professional"></div>

      <!-- DATE HEADER -->
      <div class="date">
        <div
          *ngIf="orderBy.includes(orderTypes.oldest_first)"
          class="order-toggle"
          (click)="setOrdering(orderTypes.most_recent_first)">
          <ng-container i18n="@@viestilistaOtsikkoSaapunut"
            >Arrived</ng-container
          >
          <i class="fa fa-caret-up" aria-hidden="true"></i>
        </div>
        <div
          *ngIf="orderBy.includes(orderTypes.most_recent_first)"
          class="order-toggle"
          (click)="setOrdering(orderTypes.oldest_first)">
          <ng-container i18n="@@viestilistaOtsikkoSaapunut2"
            >Arrived</ng-container
          >
          <i class="fa fa-caret-down" aria-hidden="true"></i>
        </div>
        <div
          *ngIf="
            !orderBy.includes(orderTypes.most_recent_first) &&
            !orderBy.includes(orderTypes.oldest_first)
          "
          class="order-toggle"
          (click)="setOrdering(orderTypes.most_recent_first)"
          i18n="@@viestilistaOtsikkoSaapunut3">
          Arrived
        </div>
      </div>

      <!-- SENDER HEADER -->
      <div class="sender">
        <div
          *ngIf="orderBy.includes(orderTypes.lastname_a_to_z)"
          class="order-toggle"
          (click)="setOrdering(orderTypes.lastname_z_to_a)">
          <ng-container i18n="@@viestilistaOtsikkoLahettaja"
            >Sender</ng-container
          >
          <i class="fa fa-caret-up" aria-hidden="true"></i>
        </div>
        <div
          *ngIf="orderBy.includes(orderTypes.lastname_z_to_a)"
          class="order-toggle"
          (click)="setOrdering(orderTypes.lastname_a_to_z)">
          <ng-container i18n="@@viestilistaOtsikkoLahettaja2"
            >Sender</ng-container
          >
          <i class="fa fa-caret-down" aria-hidden="true"></i>
        </div>
        <div
          *ngIf="
            !orderBy.includes(orderTypes.lastname_z_to_a) &&
            !orderBy.includes(orderTypes.lastname_a_to_z)
          "
          class="order-toggle"
          (click)="setOrdering(orderTypes.lastname_a_to_z)"
          i18n="@@viestilistaOtsikkoLahettaja3">
          Sender
        </div>
      </div>

      <!-- TYPE HEADER -->
      <div class="type" i18n="@@viestilistaOtsikkoAihe">Aihe</div>

      <!-- SERVICE HEADER -->
      <div class="service">
        <div
          *ngIf="orderBy.includes(orderTypes.services_a_to_z)"
          class="order-toggle"
          (click)="setOrdering(orderTypes.services_z_to_a)">
          <ng-container i18n="@@viestilistaOtsikkoYksikko">Unit</ng-container>
          <i class="fa fa-caret-up" aria-hidden="true"></i>
        </div>
        <div
          *ngIf="orderBy.includes(orderTypes.services_z_to_a)"
          class="order-toggle"
          (click)="setOrdering(orderTypes.services_a_to_z)">
          <ng-container i18n="@@viestilistaOtsikkoYksikko2">Unit</ng-container>
          <i class="fa fa-caret-down" aria-hidden="true"></i>
        </div>
        <div
          *ngIf="
            !orderBy.includes(orderTypes.services_z_to_a) &&
            !orderBy.includes(orderTypes.services_a_to_z)
          "
          class="order-toggle"
          (click)="setOrdering(orderTypes.services_a_to_z)"
          i18n="@@viestilistaOtsikkoYksikko3">
          Unit
        </div>
      </div>
    </div>

    <div class="table-body">
      <div *ngIf="messageList?.length === 0 && !isMessageListUpdating">
        <div class="message disabled" *ngIf="!searchTerm">
          <span *ngIf="stateIds.join() === '0,1'">
            <span
              class="empty-list"
              *ngIf="activeTab === 'messages' || activeTab === 'ready'"
              i18n="@@viestilistaEiUusiaYhteydenottoja">
              No new contact requests.
            </span>
            <span
              class="empty-list"
              *ngIf="activeTab === 'followups'"
              i18n="@@viestilistaEiUusiaViesteja">
              No new messages.
            </span>
          </span>
          <span *ngIf="stateIds.join() === '2'">
            <span class="empty-list" i18n="@@viestilistaEiKasiteltyja">
              No contact requests have not been handled.
            </span>
          </span>
        </div>
        <div
          class="message disabled"
          *ngIf="searchTerm"
          i18n="@@viestilistaEiTuloksiaSuodatuksilla">
          No contact requests with selected restrictions.
        </div>
      </div>
      <div class="message-wrapper" *ngFor="let message of messageList">
        <div
          (click)="selectMessage(message)"
          class="message"
          [class.active]="message.id === messageId"
          [class.state-new]="message.state === 0"
          [class.state-open]="message.state === 1"
          [class.state-closed]="message.state === 2"
          [class.priority-normal]="message.priority === 0"
          [class.priority-high]="message.priority === 1"
          [class.priority-emergency]="message.priority === 2">
          <!-- STATE CELL -->
          <div class="state">
            <span>
              <i
                *ngIf="
                  (message.state === 0 && message.forwarded === false) ||
                  (activeTab === 'followups' && message.forwarded === false)
                "
                class="icon-circle"
                title="{{ newHover }}"
                aria-hidden="true"></i>
              <i
                *ngIf="message.state === 0 && message.forwarded === true"
                class="fa fa-arrow-right"
                title="{{ transferHover }}"
                aria-hidden="true"></i>
              <i
                *ngIf="
                  message.state !== 0 &&
                  activeTab === 'followups' &&
                  message.has_new_attachments === true
                "
                class="fa fa-paperclip fa-flip-vertical"
                title="{{ attachmentHover }}"
                aria-hidden="true"></i>
            </span>
          </div>

          <!-- PRIORITY CELL -->
          <div class="priority" *ngIf="showPriority">
            <span *ngIf="message.priority > 0">
              <i
                *ngIf="message.priority === 1"
                class="fa fa-exclamation-circle"
                title="{{ urgentHover }}"
                aria-hidden="true"></i>
              <i
                *ngIf="message.priority > 1"
                class="fa fa-exclamation-triangle"
                title="{{ emergencyHover }}"
                aria-hidden="true"></i>
            </span>
          </div>

          <!-- FLAGGED BY PROFESSIONAL -->
          <div class="flagged_by_professional">
            <i
              class="fa fa-flag"
              aria-hidden="true"
              title="{{ priorityHover }}"
              *ngIf="message?.flagged_by_professional"></i>
          </div>

          <!-- DATE CELL -->
          <div id="enquiry-list-arrival-date" class="date">
            <span *ngIf="message.sent_today">
              <ng-container i18n="@@viestilistaKellonaika">Kello</ng-container>
              {{ message.created_at | amDateFormat: date_formats['time'] }}
            </span>
            <span *ngIf="!message.sent_today">{{
              message.created_at | amDateFormat: date_formats['date']
            }}</span>
          </div>

          <!-- SENDER CELL -->
          <div
            id="enquiry-list-sender-name-{{ message.firstname }}"
            class="sender">
            <ng-container
              *ngIf="message.firstname && message.lastname; else fullName">
              {{ message.firstname }} {{ message.lastname }}
            </ng-container>
            <ng-template #fullName>
              <ng-container *ngIf="message.full_name; else noNameAvailable">
                {{ message.full_name }}
              </ng-container>
            </ng-template>
            <ng-template #noNameAvailable>
              <span *ngIf="message.appointment_request_uuid">{{
                shortUuid(message.appointment_request_uuid)
              }}</span>
            </ng-template>
            <ng-container>
              <i
                *ngIf="message.enquiry_submitted_by === 'child'"
                class="fa fa-child"
                title="child"
                i18n-title="@@description_esitiedotLapsi">
              </i>
              <i
                *ngIf="message.enquiry_submitted_by === 'proxy'"
                class="fa fa-shield"
                title="Submitted by proxy"
                i18n-title="@@submitted_by_proxy">
              </i>
            </ng-container>
          </div>

          <!-- TYPE CELL -->
          <div id="enquiry-list-enquiry-type" class="type">
            {{ getAppointmentTypeShortName(message) | async }}
          </div>

          <!-- SERVICE CELL -->
          <div class="service">
            {{ message.servicename }}
          </div>
        </div>
      </div>
    </div>
    <div class="table-footer">
      <div class="updated-info">
        <i class="fa fa-refresh spin" *ngIf="isMessageListUpdating"></i>

        <p
          title="{{ getLastUpdatedLocalizedPipe('medium') }}"
          class="last-updated">
          <span i18n="@@listUpdated">List updated</span>:
          {{ getLastUpdatedLocalizedPipe('shortTime') }}
        </p>
      </div>
    </div>
  </div>

  <div class="pagination-wrapper" *ngIf="totalMessageCount > pageSize">
    <pagination
      [itemsPerPage]="pageSize"
      [totalItems]="totalMessageCount"
      [(ngModel)]="page"
      [maxSize]="maxSize"
      class="pagination-sm"
      [boundaryLinks]="false"
      [previousText]="previousText"
      [nextText]="nextText"
      (pageChanged)="pageChanged($event)">
    </pagination>
  </div>
</div>
