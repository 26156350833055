<div class="users-list-content" [class.paged]="totalUsers > pageSize">
  <div class="content-table">
    <table>
      <thead>
        <tr>
          <th class="first-name-header">
            <div
              *ngIf="orderBy === 'first_name'"
              class="order-toggle"
              (click)="setOrdering('-first_name')">
              <label i18n="@@kayttajalistaEtunimi"> Etunimi </label>
              <i class="fa fa-caret-up" aria-hidden="true"></i>
            </div>
            <div
              *ngIf="orderBy === '-first_name'"
              class="order-toggle"
              (click)="setOrdering('first_name')">
              <label i18n="@@kayttajalistaEtunimi2"> Etunimi </label>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </div>
            <div
              *ngIf="orderBy !== '-first_name' && orderBy !== 'first_name'"
              class="order-toggle"
              (click)="setOrdering('-first_name')">
              <label i18n="@@kayttajalistaEtunimi3"> Etunimi </label>
            </div>
          </th>

          <th class="last-name-header">
            <div
              *ngIf="orderBy === 'last_name'"
              class="order-toggle"
              (click)="setOrdering('-last_name')">
              <label i18n="@@kayttajalistaSukunimi"> Sukunimi </label>
              <i class="fa fa-caret-up" aria-hidden="true"></i>
            </div>
            <div
              *ngIf="orderBy === '-last_name'"
              class="order-toggle"
              (click)="setOrdering('last_name')">
              <label i18n="@@kayttajalistaSukunimi2"> Sukunimi </label>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </div>
            <div
              *ngIf="orderBy !== '-last_name' && orderBy !== 'last_name'"
              class="order-toggle"
              (click)="setOrdering('-last_name')">
              <label i18n="@@kayttajalistaSukunimi3"> Sukunimi </label>
            </div>
          </th>

          <th class="email-header">
            <div
              *ngIf="orderBy === 'username'"
              class="order-toggle"
              (click)="setOrdering('-username')">
              <label i18n="@@kayttajalistaTunnus"> Käyttäjätunnus </label>
              <i class="fa fa-caret-up" aria-hidden="true"></i>
            </div>
            <div
              *ngIf="orderBy === '-username'"
              class="order-toggle"
              (click)="setOrdering('username')">
              <label i18n="@@kayttajalistaTunnus2"> Käyttäjätunnus </label>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </div>
            <div
              *ngIf="orderBy !== '-username' && orderBy !== 'username'"
              class="order-toggle"
              (click)="setOrdering('-username')">
              <label i18n="@@kayttajalistaTunnus3"> Käyttäjätunnus </label>
            </div>
          </th>

          <th class="status-header">
            <div
              *ngIf="orderBy === 'user_status'"
              class="order-toggle"
              (click)="setOrdering('-user_status')">
              <label i18n="@@kayttajalistaTila"> Tila </label>
              <i class="fa fa-caret-up" aria-hidden="true"></i>
            </div>
            <div
              *ngIf="orderBy === '-user_status'"
              class="order-toggle"
              (click)="setOrdering('user_status')">
              <label i18n="@@kayttajalistaTila2"> Tila </label>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </div>
            <div
              *ngIf="orderBy !== '-user_status' && orderBy !== 'user_status'"
              class="order-toggle"
              (click)="setOrdering('-user_status')">
              <label i18n="@@kayttajalistaTila3"> Tila </label>
            </div>
          </th>

          <th class="last-login-header">
            <div
              *ngIf="orderBy === 'last_login'"
              class="order-toggle"
              (click)="setOrdering('-last_login')">
              <label i18n="@@kayttajalistaKirjautunut"> Kirjautunut </label>
              <i class="fa fa-caret-up" aria-hidden="true"></i>
            </div>
            <div
              *ngIf="orderBy === '-last_login'"
              class="order-toggle"
              (click)="setOrdering('last_login')">
              <label i18n="@@kayttajalistaKirjautunut2"> Kirjautunut </label>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </div>
            <div
              *ngIf="orderBy !== '-last_login' && orderBy !== 'last_login'"
              class="order-toggle"
              (click)="setOrdering('-last_login')">
              <label i18n="@@kayttajalistaKirjautunut3"> Kirjautunut </label>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="let user of userList"
          (click)="selectUser(user)"
          [ngClass]="{
            'not-active-user':
              user.user_status === userstatus.DISABLED ||
              user.user_status === userstatus.EXPIRED
          }">
          <!--FIRST NAME CELL-->
          <td class="first-name">
            <div>{{ user.first_name }}</div>
          </td>

          <!--LAST NAME CELL-->
          <td class="last-name">
            <div>{{ user.last_name }}</div>
          </td>

          <!--EMAIL CELL-->
          <td class="email">
            <div>{{ user.username }}</div>
          </td>

          <!--ACTIVE CELL-->
          <td class="status">
            <div
              *ngIf="user.user_status === userstatus.ACTIVE"
              i18n="@@userStatusActive">
              Aktiivinen
            </div>
            <div
              *ngIf="user.user_status === userstatus.DISABLED"
              i18n="@@UserStatusNotActive">
              Ei aktiivinen
            </div>
            <div
              *ngIf="user.user_status === userstatus.EXPIRED"
              i18n="@@UserStatusExpired">
              Vanhentunut
            </div>
          </td>

          <!--LAST LOGIN CELL-->
          <td class="last-login">
            <div *ngIf="user.last_login">
              <span>{{ user.last_login | date: 'medium' }}</span>
            </div>

            <div *ngIf="!user.last_login">
              <span i18n="@@kayttajalistaSisaankirjautuminenEiTiedossa">
                Ei tiedossa
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="pagination-wrapper" *ngIf="totalUsers > pageSize">
    <pagination
      [itemsPerPage]="pageSize"
      [totalItems]="totalUsers"
      [(ngModel)]="page"
      [maxSize]="maxSize"
      class="pagination-sm"
      [boundaryLinks]="false"
      [previousText]="previousText"
      [nextText]="nextText"
      (pageChanged)="pageChanged($event)"></pagination>
  </div>
</div>
