<h1 i18n="@@asiakkaanTiedotYksikotListaOtsikko">Units</h1>

<p i18n="@@thisPageIsOnlyVisibleToStaffUsers">
  This page is only visible to staff members
</p>

<p style="color: red">
  It is <b>critical</b> to deactivate the units from Django Admin <b>first</b>.
</p>

<p style="color: red">
  Otherwise, any patient cases still being sent to the unit will get stuck and
  will not appear on Pro UI.
</p>

<table class="table">
  <thead>
    <th>Unit</th>
    <th>Active</th>
    <th>Deleted</th>
    <th>Deleted at</th>
  </thead>
  <tbody>
    <tr *ngFor="let service of services">
      <td>{{ service.name }} ({{ service.slug }})</td>
      <td>
        <app-toggle-switch
          [value]="service.active"
          [disabled]="!!service.deleted"
          [askConfirm]="true"
          [askConfirmText]="askConfirmText(service)"
          (updatedEvent)="updateActive(service, $event)">
        </app-toggle-switch>
      </td>
      <td>
        <app-toggle-switch
          [value]="service.deleted"
          [disabled]="!!service.active"
          [askConfirm]="true"
          [askConfirmText]="askConfirmDeleteText(service)"
          (updatedEvent)="updateDeleted(service, $event)">
        </app-toggle-switch>
      </td>
      <td>
        {{ service.deleted | date: 'medium' }}
      </td>
    </tr>
  </tbody>
</table>
