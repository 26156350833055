<h1>Feature Summary</h1>

<p i18n="@@thisPageIsOnlyVisibleToStaffUsers">
  This page is only visible to staff members
</p>

<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Dynamic Columns -->
    <ng-container
      [matColumnDef]="column"
      *ngFor="let column of displayedColumns">
      <th mat-header-cell *matHeaderCellDef>{{ COLUMN_CONFIG[column] }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="column === 'service_group'; else otherColumns">
          {{ element[column] }}
        </ng-container>
        <ng-template #otherColumns>
          <mat-icon *ngIf="element[column] == true" ngClass="green-icon"
            >check_circle</mat-icon
          >
          <mat-icon *ngIf="element[column] != true" color="warn"
            >cancel</mat-icon
          >
        </ng-template>
      </td></ng-container
    >

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[50, 100, 150]"
    showFirstLastButtons
    aria-label="Select page of Feature Overview">
  </mat-paginator>
</div>
