// @ts-nocheck
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { HttpTools } from 'tools/httptools';
import { HttpMethod } from 'tools/httptools'; // Add this import
import { CacheService } from './cache.service';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UtilsService } from './utils.service';
import { Enquiry } from '../methods/enquiry/enquiry';

export type AppointmentTypesResponse = {
  // 'key' is the appointment type's id
  [key: number]: AppointmentType;
};

export enum AppointmentTypeFieldName {
  NAME = 'name',
  SHORT_NAME = 'short_name',
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentTypesService {
  constructor(
    private http: HttpClient,
    private httpTools: HttpTools,
    private cacheService: CacheService,
    private utilsService: UtilsService
  ) {}

  /**
   * Returns all available appointment types.
   */
  getAppointmentTypes(): Observable<AppointmentTypesResponse> {
    const cache_key = 'appointment_types';
    const headers = this.httpTools.prepareHeaders(HttpMethod.GET);

    const cached = this.cacheService.get(cache_key);
    if (cached) return cached;

    const response = this.http
      .get<Array<AppointmentType>>(
        `${environment.backendURL}/appointment-types`,
        { headers: headers }
      )
      .pipe(shareReplay(1));

    this.cacheService.set(cache_key, response);

    return response;
  }

  /**
   * Returns a specific appointment type.
   */
  getAppointmentTypeById(
    appointmentTypeId: number
  ): Observable<AppointmentType> {
    const allAppointmentTypes$ = this.getAppointmentTypes();
    return allAppointmentTypes$.pipe(
      map((appointmentTypes) => {
        const appType = appointmentTypes[appointmentTypeId];
        return appType;
      })
    );
  }

  /**
   * Returns the appointment type's translated name.
   * @param appointmentTypeId The appointment type's id.
   * @param fieldName The field name of the translation (Optional)
   * @param fallbackValue The fallback value if the translation is missing (Optional)
   */
  getTranslatedNameById(
    appointmentTypeId: number,
    fieldName: AppointmentTypeFieldName = AppointmentTypeFieldName.NAME,
    fallBack?: string
  ): Observable<string> {
    return this.getAppointmentTypeById(appointmentTypeId).pipe(
      map((appointmentType) => {
        const locale = this.utilsService.getCurrentLocale();
        const fallbackValue =
          fallBack ?? environment.translationMissingMessage[locale];

        const translations = appointmentType?.translations;
        const localeTranslations = translations?.[locale];
        const name = localeTranslations?.[fieldName] ?? fallbackValue;

        return name;
      })
    );
  }

  getTranslatedSubtype(message: Enquiry): string | null {
    const subType =
      message?.data?.data_v2?.appointment_request?.appointment_sub_type;
    if (!subType) {
      return null;
    }

    return this.utilsService.getFieldTranslation(subType, 'name');
  }
}
