import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Enquiry } from 'app/shared/methods/enquiry/enquiry';
import { MatDialog } from '@angular/material/dialog';
import { SelectCaseDetailsComponent } from '../select-case-details/select-case-details.component';
import { MessageService } from 'services/message.service';
import { LoadingStatus } from 'enums';
import { formatLocaleShortDate } from 'app/shared/pipes/klinik-short-date.pipe';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-identity-link',
  templateUrl: './identity-link.component.html',
  styleUrls: ['./identity-link.component.scss'],
})
export class IdentityLinkComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { enquiry: Enquiry },
    public dialogRef: MatDialogRef<IdentityLinkComponent>,
    public dialog: MatDialog,
    private messageService: MessageService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {}

  public confirmApiStatus: LoadingStatus = LoadingStatus.NONE;
  LoadingStatus = LoadingStatus; // Expose the enum to the template
  public errorMessage: string | null = null;

  private openForwardPreview(): void {
    const dialogRef = this.dialog.open(SelectCaseDetailsComponent, {
      data: {
        enquiry: this.data.enquiry,
      },
      panelClass: 'dialog-no-padding',
      height: '70%',
      width: '70%',
    });
  }

  continue() {
    this.dialogRef.close(); // Close current dialog
    this.openForwardPreview(); // Open the integration dialog
  }

  async confirmIdentity() {
    this.confirmApiStatus = LoadingStatus.LOADING;
    try {
      await this.messageService.confirmIdentity(this.data.enquiry.id); // Confirm the identity on the backend
      await this.messageService.getMessage(this.data.enquiry.id); // Refresh the case details on the frontend
      this.confirmApiStatus = LoadingStatus.SUCCESS;
    } catch (error) {
      this.confirmApiStatus = LoadingStatus.FAILED;
      this.errorMessage = $localize`:@@somethingWentWrong:Something went wrong. Please try again later.`; // TODO: Consider more specific error messages
    }
  }

  get modalDescription(): string {
    if (this.confirmApiStatus === LoadingStatus.SUCCESS) {
      return $localize`:@@confirmPatientIdentitySuccess:You have successfully verified the patient's identity. You can now proceed to save to patient record.`;
    }
    return $localize`:@@confirmPatientIdentityDesc:Our system was unable to verify this patient’s identity, but we found a likely match in the Personal Demographics service provided by NHS. Please check the details below and verify the suggestion.`;
  }

  get actionButtonDisabled(): boolean {
    return (
      this.confirmApiStatus === LoadingStatus.LOADING ||
      this.confirmApiStatus === LoadingStatus.FAILED
    );
  }

  get actionButtonText(): string {
    if (this.confirmApiStatus === LoadingStatus.LOADING)
      return $localize`:@@loading:Loading...`;
    if (this.confirmApiStatus === LoadingStatus.SUCCESS)
      return $localize`:@@proceed:Proceed`;
    return $localize`:@@confirmPatientIdentity:Verify patient identity`;
  }

  get suggestionDetails(): Record<string, string | undefined> {
    const suggestion = this.data.enquiry.data.data_v2?.pds_suggestions?.[0]!; // It should be safe to assume that the first suggestion is always present. Otherwise the user should not see this screen.

    const nhsNumberKey = $localize`:@@nhsNumber:NHS Number`;
    const firstNameKey = $localize`:@@kayttajalistaEtunimi:First Name`;
    const lastNameKey = $localize`:@@kayttajalistaSukunimi:Last Name`;
    const dateOfBirthKey = $localize`:@@dateOfBirth:Date of birth`;
    const practiceCode = $localize`:@@practice_code:Practice code`;
    const postalCode = $localize`:@@customer_postal_code:Postal code`;

    return {
      [firstNameKey]: suggestion.first_name,
      [lastNameKey]: suggestion.last_name,
      [postalCode]: suggestion.postal_code,
      [dateOfBirthKey]: formatLocaleShortDate(
        suggestion.date_of_birth,
        this.utilsService.currentLocale
      ),
      [nhsNumberKey]: suggestion.nhs_number,
      [practiceCode]: suggestion.registered_practice_ods,
    };
  }

  /**
   * The keyvalue pipe used in the template sorts the keys alphabetically by default.
   * Providing a custom sorting function like this allows us to keep the order of the keys as they are defined in the object.
   */
  noSort = (a: string, b: string) => {
    return a;
  };
}
