<section class="service-group-detail-content">
  <div class="content-section">
    <!--here the if will prevent attempts to populate the template before we have data-->
    <ng-container *ngIf="this.serviceGroupDetails">
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <div class="users-form-group">
          <h2 i18n="@@asiakkaanTiedotYksikotListaOtsikko">Yksiköt</h2>
          <label *ngFor="let service of services.controls; let i = index">
            <input type="checkbox" [formControl]="service" />
            <span>{{ serviceGroupDetails.services[i].name }}</span>
          </label>
          <div class="button-wrapper">
            <button
              type="button"
              class="btn"
              (click)="selectAllServiceGroups(true)"
              i18n="@@asiakkaanTiedotYksikotListaValitse">
              Valitse kaikki
            </button>
            <button
              type="button"
              class="btn"
              (click)="selectAllServiceGroups(false)"
              i18n="@@asiakkaanTiedotYksikotListaTyhjenna">
              Tyhjennä valinnat
            </button>
          </div>
        </div>
        <button class="btn action" type="submit">
          <span i18n="@@asiakkaanTiedotTallenna"> Tallenna </span>
        </button>
      </form>
    </ng-container>
  </div>
</section>
