// @ts-nocheck
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListModule } from './list/list.module';
import { DetailModule } from './detail/detail.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LinkServiceGroupsComponent } from './link-service-groups/link-service-groups.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ListModule,
    RouterModule,
    DetailModule,
    ModalModule.forRoot(),
  ],
  declarations: [UsersComponent, LinkServiceGroupsComponent],
})
export class UsersModule {}
