// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';

import { SessionService } from 'services/session.service';
import { ServiceGroupService } from 'services/service-group.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit {
  /**
   * Features page under Settings.
   */

  public serviceGroup: ServiceGroup = {} as ServiceGroup;
  public showIntegrationFeatures: boolean = false;
  public showDescription = false;

  public patientIdentityLookupToggleDescription = $localize`:@@patientIdentityLookupToggleDescription:`;

  public feedbackTranslations = {
    feedback_procedures: {
      name: $localize`:@@feedbackProcedure:`,
      description: $localize`:@@feedbackProcedureDescription:`,
    },
    feedback_resources: {
      name: $localize`:@@feedbackResources:`,
      description: $localize`:@@feedbackResourcesDescription:`,
    },
  };

  constructor(
    private sessionService: SessionService,
    private serviceGroupService: ServiceGroupService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    if (!this.sessionService.userIsStaff) {
      return;
    }

    this.serviceGroupService
      .getActiveServiceGroup()
      .then((serviceGroup) => {
        this.serviceGroup = serviceGroup;

        // Show integration settings only for UK and development environments
        this.showIntegrationFeatures =
          this.sessionService.isUKProduction ||
          this.sessionService.isDevelopment;
      })

      .catch(() => {
        this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`);
      });
  }

  public updateLocalValue(key: string, value: string) {
    console.debug(`Updating service group '${key}' with value '${value}'`);
    this._nullOtherIntegrations(key);
    this.serviceGroup[key] = value;
  }

  public getIntegrationSetting(key: string) {
    if (this.serviceGroup.integration_settings) {
      return this.serviceGroup.integration_settings[key];
    } else null;
  }

  public setIntegrationSetting(key: string, value: string) {
    console.debug(
      `Updating service group integration setting '${key}' with value '${value}'`
    );

    this._nullOtherIntegrations(key);
    this._initIntegrationSettings();
    this.serviceGroup.integration_settings[key] = value;
  }

  private _nullOtherIntegrations(key: string) {
    switch (key) {
      case 'gp_connect_enabled':
        this.serviceGroup.integration_settings.emis_enabled = false;
        this.serviceGroup.black_pear_emis_enabled = false;
        this.serviceGroup.black_pear_tpp_enabled = false;
        break;
      case 'emis_enabled':
        this.serviceGroup.integration_settings.gp_connect_enabled = false;
        this.serviceGroup.black_pear_emis_enabled = false;
        this.serviceGroup.black_pear_tpp_enabled = false;
        break;
      case 'black_pear_emis_enabled':
        this.serviceGroup.integration_settings.gp_connect_enabled = false;
        this.serviceGroup.integration_settings.emis_enabled = false;
        this.serviceGroup.black_pear_tpp_enabled = false;
        break;
      case 'black_pear_tpp_enabled':
        this.serviceGroup.integration_settings.gp_connect_enabled = false;
        this.serviceGroup.integration_settings.emis_enabled = false;
        this.serviceGroup.black_pear_emis_enabled = false;
        break;
    }
  }

  public _initIntegrationSettings() {
    // Initialize integration settings
    if (!this.serviceGroup.integration_settings) {
      this.serviceGroup.integration_settings =
        {} as ServiceGroupIntegrationSettings;
    }
  }

  public saveFeatures(): void {
    this._initIntegrationSettings();

    this.serviceGroupService
      .updateServiceGroup({
        pro_form_active: this.serviceGroup.pro_form_active,
        dashboard_enabled: this.serviceGroup.dashboard_enabled,
        snooze_enabled: this.serviceGroup.snooze_enabled,
        request_attachments_enabled:
          this.serviceGroup.request_attachments_enabled,
        allow_commenting: this.serviceGroup.allow_commenting,
        video_consultation_enabled:
          this.serviceGroup.video_consultation_enabled,
        feedback_resources_enabled:
          this.serviceGroup.feedback_resources_enabled,
        feedback_procedures_enabled:
          this.serviceGroup.feedback_procedures_enabled,
        feedback_loop_enabled: this.serviceGroup.feedback_loop_enabled,
        sms_notification_email_enabled:
          this.serviceGroup.sms_notification_email_enabled,
        patient_identity_lookup_enabled:
          this.serviceGroup.patient_identity_lookup_enabled,
        black_pear_tpp_enabled: this.serviceGroup.black_pear_tpp_enabled,
        black_pear_emis_enabled: this.serviceGroup.black_pear_emis_enabled,
        reduced_copy_content_enabled:
          this.serviceGroup.reduced_copy_content_enabled,
        data_reports_enabled: this.serviceGroup.data_reports_enabled,
        integration_settings: {
          gp_connect_enabled:
            this.serviceGroup.integration_settings.gp_connect_enabled,
          emis_enabled: this.serviceGroup.integration_settings.emis_enabled,
        },
      })
      .then((serviceGroup) => {
        this.serviceGroup = serviceGroup;
        this.toasterService.pop('success', $localize`:@@savedSuccesfully:`);
      })
      .catch((e) => {
        this.handleSaveError(e);
      });
  }

  handleSaveError(err: any) {
    if (err?.error?.[0]?.code === 'overlapping_integrations') {
      this.toasterService.pop(
        'error',
        $localize`:@@errorOverlappingIntegrations:Service group cannot have multiple integrations enabled at once!`
      );
      return;
    }
    this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`);
  }

  displayDescription(event: MouseEvent, descriptionTranslationKey: string) {
    this.showDescription = true;
    // request animation frame in order to make sure element has been rendered before attempting to update it's contents
    requestAnimationFrame(() => {
      const descriptionElement = document.getElementById('description');
      if (!descriptionElement) {
        // Sometimes the descriptionElement is null, and an unnecessary
        // error would be thrown.
        return;
      }

      const hoverElement = <HTMLElement>event.target;
      descriptionElement.style.top = `${
        hoverElement.offsetTop + hoverElement.offsetHeight
      }px`;
      descriptionElement.style.left = `${
        hoverElement.offsetLeft + hoverElement.offsetWidth
      }px`;
      descriptionElement.getElementsByTagName('h3')[0].innerHTML =
        this.feedbackTranslations[descriptionTranslationKey].name;
      descriptionElement.getElementsByTagName('p')[0].innerHTML =
        this.feedbackTranslations[descriptionTranslationKey].description;
    });
  }
}
