// @ts-nocheck
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { PublicService } from 'services/public.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit {
  public fileForm: FormControl;
  public files: object[];
  public checkingToken = true;
  public validToken = false;
  public caseHandled = false;
  public appointment_request_uuid: string;
  public practice: string;
  public loading = false;
  public uploadSuccess = false;
  public uploadError = false;
  public error = '';

  private token: string;
  private fileTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  private errorTranslations = {
    max_files_error: $localize`:@@maxFilesError:`,
    file_size_error: $localize`:@@fileSizeError:`,
    file_type_error: $localize`:@@fileTypeError:`,
  };

  providers: [
    Location,
    { provide: LocationStrategy; useClass: PathLocationStrategy }
  ];

  constructor(
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private location: Location,
    private route: ActivatedRoute,
    private http: HttpClient,
    private publicService: PublicService
  ) {
    this.fileForm = new FormControl({ value: '', disabled: false });
  }

  ngOnInit() {
    this.files = [];
    this.token = this.route.snapshot.queryParams.auth;
    this.location.replaceState('patient');

    if (this.token) {
      this.publicService.getPublicData(this.token).then((result) => {
        this.validToken = result.valid;

        if (this.validToken) {
          this.practice = result.response['service_group_name'];
          this.appointment_request_uuid =
            result.response['appointment_request_uuid'];
        } else {
          this.caseHandled = result.case_handled;
        }

        this.checkingToken = false;
      });
    } else {
      this.validToken = false;
      this.checkingToken = false;
    }
  }

  prepareHeaders(method: string = null) {
    let headers = new HttpHeaders();
    headers = headers.set('document-token', this.token);

    if (method === 'post') {
      headers = headers.set('Content-Type', 'application/json');
    }

    return headers;
  }

  onFileChange(event) {
    this.error = '';

    if (event.target.files && event.target.files.length) {
      const inputFiles = event.target.files;
      if (inputFiles.length + this.files.length > 5) {
        this.error = this.errorTranslations.max_files_error;
        this.fileForm.reset('');
        return;
      }
      for (const file of inputFiles) {
        if (file.size > 15000000) {
          this.error = this.errorTranslations.file_size_error;
        } else if (!this.fileTypes.includes(file.type)) {
          this.error = this.errorTranslations.file_type_error;
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => {
            this.files.push({ file: reader.result });
            this.cd.markForCheck();
          };
        }
      }
      this.fileForm.reset('');
    }
  }

  onSubmit() {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.backendURL + '/documents', this.files, {
          headers: this.prepareHeaders('post'),
        })
        .subscribe(
          (result) => {
            this.uploadSuccess = true;
          },
          (error) => {
            this.loading = false;
            this.uploadError = true;
            this.uploadSuccess = false;
          }
        );
    });
  }

  remove(index) {
    this.files.splice(index, 1);
  }
}
