<section class="auth-container">
  <h1 i18n="@@setPasswordPageTitle">Aseta uusi salasana</h1>

  <div *ngIf="tokenInvalid">
    <h4 i18n="@@setPasswordTokenInvalidTitle">
      Salasanan vaihtolinkki on vanhentunut
    </h4>
    <div i18n="@@setPasswordTokenInvalidMessage">
      Käyttämäsi linkki salasanan asettamiseksi on jo käytetty tai vanhentunut.
      Linkit ovat voimassa 14 vuorokautta lähettämisestä ja ne voidaan käyttää
      vain kertaalleen.
    </div>
    <a
      routerLink="/auth/resetpassword"
      i18n="@@setPasswordRequestPasswordReset">
      Tee uusi salasanan vaihtamispyyntö
    </a>
  </div>

  <div *ngIf="state === 'form-success'">
    <h4 i18n="@@setPasswordChangedSuccesfully">Salasanan vaihto onnistui!</h4>
    <a routerLink="/auth/login" i18n="@@setPasswordLoginLink">
      Kirjaudu sisään
    </a>
  </div>

  <form
    *ngIf="!tokenInvalid && state !== 'form-success'"
    id="passwordsetForm"
    [formGroup]="form"
    name="form"
    class="form-horizontal"
    #f="ngForm">
    <div>
      <input
        [formControl]="username"
        id="username"
        type="text"
        class="form-control"
        name="username"
        required
        placeholder="Käyttäjätunnus"
        i18n-placeholder="@@setPasswordUsernamePlaceholder"
        disabled="disabled" />
    </div>

    <div>
      <input
        [formControl]="password"
        id="password1"
        type="password"
        class="form-control"
        name="password"
        required
        placeholder="Salasana"
        i18n-placeholder="@@setPasswordPasswordPlaceholder" />
    </div>

    <div>
      <input
        [formControl]="repeatPassword"
        id="password2"
        type="password"
        class="form-control"
        name="repeatPassword"
        required
        placeholder="Salasana uudestaan"
        i18n-placeholder="@@setPasswordRepeatPasswordPlaceholder" />
    </div>

    <div *ngIf="state == 'form-error' && submitted" class="error">
      <div
        class="error-item"
        *ngIf="repeatPassword.value === '' || password.value === ''">
        <i class="fa fa-exclamation-circle"></i>
        <ng-container i18n="@@setPasswordErrorNoPasswords">
          Syötä salasanat.
        </ng-container>
      </div>
      <div class="error-item" *ngIf="repeatPassword.value !== password.value">
        <i class="fa fa-exclamation-circle"></i>
        <ng-container i18n="@@setPasswordErrorPasswordDontMatch">
          Salasanat eivät täsmää.
        </ng-container>
      </div>
      <div *ngFor="let error of errors">
        <div class="error-item" *ngIf="error.code === 'password_too_short'">
          <i class="fa fa-exclamation-circle"></i>
          <ng-container i18n="@@setPasswordErrorPasswordTooShort">
            Valitsemasi salasana on liian lyhyt.
          </ng-container>
        </div>

        <div class="error-item" *ngIf="error.code === 'password_too_similar'">
          <i class="fa fa-exclamation-circle"></i>
          <ng-container i18n="@@setPasswordErrorTooEasyToGuess">
            Valitsemasi salasana on liian helppo arvata.
          </ng-container>
        </div>

        <div class="error-item" *ngIf="error.code === 'password_too_common'">
          <i class="fa fa-exclamation-circle"></i>
          <ng-container i18n="@@setPasswordErrorTooPopular">
            Valitsemasi salasana on liian yleinen.
          </ng-container>
        </div>

        <div class="error-item" *ngIf="error.code === 'password_too_simple'">
          <i class="fa fa-exclamation-circle"></i>
          <ng-container i18n="@@setPasswordErrorWrongCharacters">
            Salasanassa pitää olla isoja kirjaimia, pieniä kirjaimia ja
            numeroita.
          </ng-container>
        </div>
        <div class="error-item" *ngIf="error.code === 'invalid_token'">
          <i class="fa fa-exclamation-circle"></i>
          <span i18n="@@setPasswordErrorInvalidToken">
            Salasanan vaihtolinkki on vanhentunut, tilaa uusi linkki</span
          >
          <a
            routerLink="/auth/resetpassword"
            i18n="@@setPasswordErrorForgotPassword">
            here</a
          >
        </div>
      </div>
    </div>

    <div class="flexbox">
      <div>
        <button
          (click)="setNewPassword(form.value)"
          class="btn btn-auth"
          i18n="@@setPasswordFormSubmitButton">
          Lähetä
        </button>
      </div>
      <div>
        <i
          class="fa fa-question-circle"
          i18n-title="@@passwordRequirementsTitle"
          title="Salasanan vaatimukset"
          (click)="showHint = !showHint"></i>
      </div>

      <div id="hint" *ngIf="showHint === true">
        <ng-container i18n="@@passwordRequirements">
          Password must be at least 8 characters long and contain both lower and
          uppercase letters and numbers. It cannot be your name nor be too
          common.
        </ng-container>
      </div>
    </div>
  </form>
</section>
