<section id="redirection-component">
  <div class="content-wrapper">
    <div class="deny-interaction" *ngIf="submitting"></div>

    <p *ngIf="validatingToken" class="validating">
      <i class="fa fa-spinner spin"></i> Validating request
    </p>

    <ng-container *ngIf="!validatingToken && !validToken">
      <section *ngIf="!caseHandled" class="expired-link">
        <i class="fa fa-history fa-5x"></i>
        <h1 i18n="@@expiredLink">The link has expired</h1>
        <p>
          If you refreshed the page before submitting, you can still access the
          page from the original link sent to you.
        </p>
      </section>

      <section *ngIf="caseHandled" class="upload-success">
        <i class="fa fa-check fa-5x"></i>
        <h1 i18n="@@closedCase">
          Your Healthcare professional has closed your enquiry
        </h1>
      </section>
    </ng-container>

    <ng-container *ngIf="validToken && !uploadSuccess">
      <h1>
        This is a message from <span class="green">{{ practice }}</span>
      </h1>

      <p>
        We have reviewed your request and would like to refer you to a
        pharmacist of your choice.
      </p>
      <p>
        Pharmacists are highly trained and able to advise and resolve many
        medical issues in the same day, such as coughs, colds, sore throats,
        tummy trouble and aches and pains.
      </p>
      <p>
        The pharmacist will also be able to refer you back to the surgery if
        your request needs to be seen by a GP.
      </p>

      <form
        [formGroup]="redirectionForm"
        (ngSubmit)="onSubmit(redirectionForm)">
        <div class="form-input-container">
          <label for="referral_decision">Do you accept this referral?</label>

          <input
            type="radio"
            name="referral_decision"
            id="accpet"
            value="1"
            formControlName="referral_decision" />
          <label
            class="radio-button"
            for="accpet"
            (click)="referralDecision(true)"
            [ngClass]="{
              selected: redirectionForm.value.referral_decision === '1'
            }"
            >Accept</label
          >

          <input
            type="radio"
            name="referral_decision"
            id="decline"
            value="0"
            formControlName="referral_decision" />
          <label
            class="radio-button"
            for="decline"
            (click)="referralDecision(false)"
            [ngClass]="{
              selected: redirectionForm.value.referral_decision === '0'
            }"
            >Decline</label
          >
        </div>

        <div
          class="form-input-container"
          *ngIf="redirectionForm.value.referral_decision === '1'">
          <label for="preferred_pharmacy"
            >Select which pharmacy you would prefer to be redirected to.</label
          >
          <ng-container *ngIf="pharmacies.length === 0; else selectPharmacy">
            <p>
              <i class="fa fa-spinner spin"></i> Fetching list of pharmacies...
            </p>
          </ng-container>

          <ng-template #selectPharmacy>
            <ng-container *ngFor="let pharmacy of pharmacies">
              <div
                class="card"
                (click)="
                  redirectionAnswer('preferred_pharmacy', pharmacy.name)
                ">
                <input
                  type="radio"
                  name="preferred_pharmacy"
                  id="pharmacy_{{pharmacy.id}"
                  value="{{ pharmacy.name }}"
                  formControlName="preferred_pharmacy" />
                <label
                  class="radio-button"
                  for="pharmacy_{{ pharmacy.id }}"
                  [ngClass]="{
                    selected:
                      redirectionForm.value.preferred_pharmacy === pharmacy.name
                  }">
                  {{ pharmacy.name }}<br />
                  <span class="light-font">{{ pharmacy.address }}</span>
                </label>
                <div class="opening-hours">
                  <span
                    class="small-font"
                    *ngFor="let time of pharmacy.opening_hours"
                    >{{ time }}</span
                  >
                </div>
              </div>
            </ng-container>
          </ng-template>
        </div>

        <div
          class="form-input-container"
          *ngIf="redirectionForm.value.referral_decision === '0'">
          <label for="reason_for_decline"
            >Please tell us why you would prefer to wait for a consultation with
            a GP.</label
          >

          <ng-container
            *ngFor="let reason of declinationReasons; let i = index">
            <input
              type="radio"
              name="reason_for_decline"
              id="decline_{{ i }}"
              value="{{ reason }}"
              formControlName="reason_for_decline" />
            <label
              class="radio-button"
              for="decline_{{ i }}"
              (click)="redirectionAnswer('reason_for_decline', reason)"
              [ngClass]="{
                selected: redirectionForm.value.reason_for_decline === reason
              }"
              >{{ reason }}</label
            >
          </ng-container>

          <input
            type="radio"
            name="reason_for_decline"
            id="decline_other"
            value=""
            formControlName="reason_for_decline" />
          <label
            class="radio-button"
            for="decline_other"
            (click)="redirectionAnswer('reason_for_decline', '')"
            [ngClass]="{ selected: otherReason === true }"
            >Other (Please describe)</label
          >

          <ng-container *ngIf="otherReason">
            <textarea
              name="reason_for_decline"
              id="reason_for_decline"
              formControlName="reason_for_decline"></textarea>
            <p>
              {{ redirectionForm.value.reason_for_decline.length }}/{{
                max_decline_len
              }}
            </p>
          </ng-container>
        </div>

        <button
          type="submit"
          [disabled]="!redirectionForm.valid"
          [ngClass]="{ disabled: !redirectionForm.valid }">
          <p>
            <span *ngIf="submitting"><i class="fa fa-spinner spin"></i></span>
            Submit
          </p>
        </button>
      </form>
    </ng-container>

    <section *ngIf="uploadSuccess" class="upload-success">
      <i class="fa fa-check-circle-o fa-5x green"></i>
      <h1>Thank-you.</h1>
      <p *ngIf="redirectionForm.value.referral_decision === '0'">
        Your feedback has been sent. We will contact you within
        <span class="green">[xxx]</span> time.
      </p>
      <p *ngIf="redirectionForm.value.referral_decision === '1'">
        Your response has been forwarded. You will be contacted by
        <span class="green">{{ redirectionForm.value.preferred_pharmacy }}</span
        >.
      </p>
    </section>
  </div>
</section>
