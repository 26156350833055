// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DashboardService } from 'services/dashboard.service';
import { DatepickerService } from 'services/datepicker.service';
import { UtilsService } from 'services/utils.service';

interface QuickFilter {
  id: string;
  name: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface CalendarItem {
  value: number;
  enabled: boolean;
  date: moment.Moment;
}

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, OnDestroy {
  public open = false;
  public loading = false;
  public startDate: moment.Moment;
  public endDate: moment.Moment;
  public month1: moment.Moment;
  public month2: moment.Moment;
  public month1Days: CalendarItem[] = [];
  public month2Days: CalendarItem[] = [];
  public canAdvanceMonth = true;
  public canRetreatMonth = true;
  public dateInvalid: any = {
    start: false,
    end: false,
  };

  // The default is defined in
  // src/app/dashboard/dashboard.component.ts:67
  private selectedQuickFilter = 0;
  private selectStartDate = true;
  private datePickerSubscription: Subscription;
  private loadingStateSubscription: Subscription;

  private readonly baseDate = moment('2016-07-24');
  private readonly today = moment();

  public readonly quickFilters = [
    {
      id: 'today',
      name: $localize`:@@datepickerToday:`,
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
    },
    {
      id: 'yesterday',
      name: $localize`:@@datepickerYesterday:`,
      startDate: moment().subtract(1, 'days').startOf('day'),
      endDate: moment().subtract(1, 'days').endOf('day'),
    },
    {
      id: 'lastSevenDays',
      name: $localize`:@@datepickerLastSevenDays:`,
      startDate: moment().subtract(6, 'days').endOf('day'),
      endDate: moment().startOf('day'),
    },
    {
      id: 'lastFourteenDays',
      name: $localize`:@@datepickerLastFourteenDays:`,
      startDate: moment().subtract(13, 'days').endOf('day'),
      endDate: moment().startOf('day'),
    },
    {
      id: 'thisWeek',
      name: $localize`:@@datepickerThisWeek:`,
      startDate: moment().startOf('isoWeek'),
      endDate: moment().endOf('day'),
    },
    {
      id: 'lastWeek',
      name: $localize`:@@datepickerLastWeek:`,
      startDate: moment().subtract(1, 'weeks').startOf('isoWeek'),
      endDate: moment().subtract(1, 'weeks').endOf('isoWeek'),
    },
    {
      id: 'thisMonth',
      name: $localize`:@@datepickerThisMonth:`,
      startDate: moment().startOf('month'),
      endDate: moment().endOf('day'),
    },
    {
      id: 'lastMonth',
      name: $localize`:@@datepickerLastMonth:`,
      startDate: moment().subtract(1, 'months').startOf('month'),
      endDate: moment().subtract(1, 'months').endOf('month'),
    },
    {
      id: 'thisYear',
      name: $localize`:@@datepickerThisYear:`,
      startDate: moment().startOf('year'),
      endDate: moment().endOf('day'),
    },
    {
      id: 'lastYear',
      name: $localize`:@@datepickerLastYear:`,
      startDate: moment().subtract(1, 'years').startOf('year'),
      endDate: moment().subtract(1, 'years').endOf('year'),
    },
  ];

  public readonly weekDays = [
    ...moment.weekdaysShort().slice(1),
    moment.weekdaysShort(0),
  ];

  constructor(
    private datepickerService: DatepickerService,
    private utilsService: UtilsService,
    private dashboardService: DashboardService
  ) {
    // Default en locale to en-UK.
    // moment doesn't apply locale settings to weekdays etc. so we specify those here.
    if (this.utilsService.currentLocale === 'en') {
      moment.locale('en-gb', {
        week: {
          dow: 1, // First day of the week is monday
          doy: 4, // First week of the year is the one with majority of it's days (>=4) in January
        },
      });
    } else {
      moment().locale(this.utilsService.currentLocale);
    }

    this.loadingStateSubscription =
      this.dashboardService.loadingState$.subscribe(
        (state) => (this.loading = state)
      );
  }

  ngOnInit() {
    this.datePickerSubscription = this.datepickerService.state$.subscribe(
      (datepickerstate) => {
        this.startDate = moment(datepickerstate.startDate);
        this.endDate = moment(datepickerstate.endDate);
        this.selectedQuickFilter = datepickerstate.selectedQuickFilter;
      }
    );
    this.month2 = moment(this.endDate).startOf('month');
    this.month1 = moment(this.month2).subtract(1, 'months').startOf('month');
    this.updateMonthDays();
  }

  ngOnDestroy(): void {
    this.datePickerSubscription.unsubscribe();
    this.loadingStateSubscription.unsubscribe();
  }

  toggleDatepicker(): void {
    this.open = !this.open;
  }

  applyChanges(): void {
    this.updateDatePickerState();
    this.toggleDatepicker();
  }

  updateDatePickerState(): void {
    this.datepickerService.update({
      startDate: this.startDate.format('YYYY-MM-DD'),
      endDate: this.endDate.format('YYYY-MM-DD'),
      selectedQuickFilter: this.selectedQuickFilter,
    });
  }

  selectQuickFilter(index: number, filter: QuickFilter): void {
    this.startDate = filter.startDate;
    this.endDate = filter.endDate;
    this.selectedQuickFilter = index;
  }

  isDateEnabled(date: moment.Moment): boolean {
    return date.isBetween(this.baseDate, this.today, 'day', '[]');
  }

  getMonthDays(month: moment.Moment): any[] {
    const start = month.clone().startOf('month');
    const initalEmpty = start.isoWeekday() - 1;
    const end = month.clone().endOf('month');
    const finalEmpty = 7 - end.isoWeekday();
    const daysInMonth = month.daysInMonth();

    const values = [];
    for (let i = 0; i < initalEmpty; i++) {
      values.push({ value: 0, enable: false, date: null });
    }
    const day = moment(start);
    for (let i = 1; i <= daysInMonth; i++) {
      values.push({
        value: i,
        enabled: this.isDateEnabled(day),
        date: moment(day),
      });
      day.add(1, 'day');
    }
    for (let i = 0; i < finalEmpty; i++) {
      values.push({ value: 0, enable: false, date: null });
    }
    return values;
  }

  get headline() {
    if (this.selectedQuickFilter > -1) {
      return this.quickFilters[this.selectedQuickFilter].name;
    }
    if (this.startDate && this.endDate) {
      return `${this.startDate.format('L')} - ${this.endDate.format('L')}`;
    }
    return '';
  }

  getMonth1Name(): string {
    return this.month1.format('MMMM YYYY');
  }

  getMonth2Name(): string {
    return this.month2.format('MMMM YYYY');
  }

  updateMonthDays(): void {
    this.month1Days = this.getMonthDays(this.month1);
    this.month2Days = this.getMonthDays(this.month2);
    this.canAdvanceMonth = this.month2.isBefore(this.today, 'month');
    this.canRetreatMonth = this.month2.isAfter(this.baseDate, 'month');
  }

  nextMonth(): void {
    this.month1.add(1, 'month');
    this.month2.add(1, 'month');
    this.updateMonthDays();
  }

  previousMonth(): void {
    this.month1.subtract(1, 'month');
    this.month2.subtract(1, 'month');
    this.updateMonthDays();
  }

  selectDate(date: moment.Moment) {
    this.selectedQuickFilter = -1;
    if (this.selectStartDate) {
      this.startDate = date;
      this.selectStartDate = false;
    } else {
      this.endDate = date;
      this.selectStartDate = true;
    }
    if (this.startDate.isAfter(this.endDate)) {
      const swap = this.startDate;
      this.startDate = this.endDate;
      this.endDate = swap;
      this.selectStartDate = true;
    }
  }

  manualInputDate(event: any, input: string): void {
    const date = moment(event.target.value, 'L');
    if (date.isValid()) {
      if (this.isDateEnabled(date)) {
        this.selectedQuickFilter = -1;
        this.dateInvalid[input] = false;
        switch (input) {
          case 'start':
            this.startDate = date;
            break;
          case 'end':
            this.endDate = date;
            break;
        }
      }
    } else {
      this.dateInvalid[input] = true;
    }
  }
}
