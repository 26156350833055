<form>
  <h2 i18n="@@smsTemplateAddNewTemplateHeader">Add new template</h2>

  <div class="input-row">
    <div class="input-label-wrapper name">
      <app-textarea-input
        [translations]="SMSTemplateKeys.NAME"
        [smallTextarea]="true"
        [prefill]="newTemplate[SMSTemplateKeys.NAME]"
        [disabled]="!hasEditPermission"
        (controlEmitter)="updateControl(SMSTemplateKeys.NAME, $event)">
      </app-textarea-input>
    </div>

    <div class="input-label-wrapper body">
      <app-textarea-input
        [translations]="SMSTemplateKeys.BODY"
        [maxlength]="900"
        [largeTextarea]="true"
        [rows]="4"
        [prefill]="newTemplate[SMSTemplateKeys.BODY]"
        [disabled]="!hasEditPermission"
        (controlEmitter)="updateControl(SMSTemplateKeys.BODY, $event)">
      </app-textarea-input>
    </div>

    <div id="add-new-template-button" class="vertically-stacked-buttons">
      <button
        class="btn action"
        (click)="addNewTemplate()"
        [disabled]="isNewTemplateButtonDisabled">
        <span>
          <span *ngIf="!sending" i18n="@@smsTemplateAddNew"
            >Add new template +</span
          >
          <i class="fa fa-spinner spin" *ngIf="sending"></i>
        </span>
      </button>
    </div>
  </div>
</form>
