// @ts-nocheck
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from 'services/session.service';
import { getNestedValue } from './shared/methods/getNestedValue';
import { ErrorTypes } from './utils/http-response.enums';

export function sessionInterceptorFactory(injector: Injector) {
  return new SessionInterceptor(injector);
}

class SessionInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  private _errorStatus: number = null;
  private get isUnathorizedStatus(): boolean {
    return this._errorStatus === ErrorTypes.unauthorized;
  }

  private _errorCode: string = null;
  private get isUnathorizedCode(): boolean {
    return this._errorCode === ErrorTypes.unauthorized_session_code;
  }

  private isUnauthorizedSession(error: HttpErrorResponse): boolean {
    this._errorStatus = getNestedValue(error, 'status');
    this._errorCode = getNestedValue(error, 'error', 'code');
    return this.isUnathorizedStatus && this.isUnathorizedCode;
  }

  /**
   * Logs user out if error has unauthorized status and error code.
   */
  private sessionErrorHandler(error: HttpErrorResponse): Observable<any> {
    if (this.isUnauthorizedSession(error)) {
      this.injector.get<SessionService>(SessionService).logout();
    }

    return throwError(error);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(catchError((error) => this.sessionErrorHandler(error)));
  }
}
