import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { EXTERNAL_FORWARD_TYPES } from 'services/integration.service';
import { MessageService, MESSAGE_STATES } from 'services/message.service';
import { SessionService } from 'services/session.service';
import {
  StateService,
  StateKeys,
  singleMessageEvent,
} from 'services/state.service';
import { UtilsService } from 'services/utils.service';
import { trigger, style, animate, transition } from '@angular/animations';
import {
  AppointmentTypeFieldName,
  AppointmentTypesService,
} from 'services/appointment-types.service';
import { Enquiry } from 'app/shared/methods/enquiry/enquiry';
import { LoadingStatus } from 'enums';

@Component({
  selector: 'app-messages-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ 'max-height': 0, height: 0, opacity: 0 }),
        animate(
          '0.6s ease-out',
          style({ 'max-height': '100vh', height: '40px', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        style({ 'max-height': '100vh', height: '40px', opacity: 1 }),
        animate(
          '0.6s 200ms ease-out',
          style({ 'max-height': 0, height: 0, opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class DetailComponent implements OnInit, OnDestroy {
  @Output()
  private integrationActionEmitter: EventEmitter<EXTERNAL_FORWARD_TYPES> = new EventEmitter();

  private stateChangeSubscription: Subscription | null = null;

  public message: Enquiry | null = null;
  public session: object | null = this.sessionService.getSession();
  public appointmentSubTypeName: String | null = null;
  public appointmentTypeName$: Observable<string> | null = null;

  public emisIntegrationAvailable = false;
  public integrationDrawerVisible: boolean = false;
  public integrationErrorsList: Set<String> = new Set();
  public integrationAction = (action: EXTERNAL_FORWARD_TYPES): void =>
    this.integrationActionEmitter.emit(action);

  public readonly integrationActions = EXTERNAL_FORWARD_TYPES;
  public readonly messageStates = MESSAGE_STATES;

  public get showIntegrationContainer() {
    return (
      this.sessionService.isEmisIntegrationEnabled ||
      this.sessionService.isGPConnectIntegrationEnabled
    );
  }

  public get isRestricted() {
    return this.sessionService.isRestricted;
  }

  public get isMessageLoading() {
    return this.stateService.isLoading(StateKeys.singleMessage);
  }

  constructor(
    protected messageService: MessageService,
    protected stateService: StateService,
    protected utilsService: UtilsService,
    private sessionService: SessionService,
    private appointmentTypesService: AppointmentTypesService
  ) {}

  ngOnInit() {
    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        // A bit of a hack to get around problems trying to add typing to 'state' itself.
        const typedState = state as { key: StateKeys; value: any };

        switch (typedState.key) {
          case StateKeys.selectedMessage:
            if (typedState.value) {
              this.messageService.getMessage(typedState.value['id']);
              this.scrollToTop();
            }
            break;
          case StateKeys.singleMessage:
            const event = typedState.value as singleMessageEvent;
            if (event.status === LoadingStatus.FAILED) {
              // TODO: provide a more helpful feedback to the user
              this.message = null;
              return;
            }
            if (event.status === LoadingStatus.LOADING) return;
            this.message = event.data;
            this.appointmentSubTypeName = this.getAppointmentSubTypeName();
            this.appointmentTypeName$ = this.getAppointmentTypeNameTranslated();
            break;
          case StateKeys.closedMessage:
          case StateKeys.selectedService:
          case StateKeys.redirectedMessage:
            this.message = null;
            this.appointmentSubTypeName = null;
            this.appointmentTypeName$ = null;
            break;
          case StateKeys.emisPatientSearchResult:
            break;
          case StateKeys.emisIntegrationAvailable:
            this.emisIntegrationAvailable = typedState.value;
            break;
        }
      }
    );
  }

  /**
   *
   * @deprecated use appointmentTypesService.getTranslatedSubtype instead
   */
  getAppointmentSubTypeName(): string | null {
    const appointmentSubType =
      this.message?.data?.data_v2?.appointment_request?.appointment_sub_type;
    if (!appointmentSubType) return null;
    return this.utilsService.getFieldTranslation(appointmentSubType, 'name');
  }

  scrollToTop() {
    window.requestAnimationFrame(() => {
      const bodyContent = document.getElementsByClassName('body-content')[0];
      if (!bodyContent) {
        return;
      }

      if (!bodyContent.scroll) {
        // IE11 does not support scroll methods. https://caniuse.com/element-scroll-methods
        console.info('Browser used does not support scroll() method.');
        return;
      }

      bodyContent.scroll({ top: 0 });
    });
  }
  ngOnDestroy() {
    this.stateChangeSubscription?.unsubscribe();
  }

  messageIsReady() {
    if (this.message && this.message['state'] === 2) {
      return true;
    }
    return false;
  }

  getAppointmentTypeNameTranslated(): Observable<string> {
    if (!this.message) {
      return of('');
    }

    return this.appointmentTypesService.getTranslatedNameById(
      this.message.appointment_type,
      AppointmentTypeFieldName.NAME,
      this.message.data.appointment_request.appointment_duration_type
    );
  }

  /**
   * Checks all possible reasons why user would not be allowed to use GP Connect and gathers valid errors into integrationErrorsList
   */
  checkIntegrationErrors(): void {
    this.integrationErrorsList.clear();

    // If the message has timedout external forwards
    if (this.message?.has_timed_out_external_forwards == true) {
      this.integrationErrorsList.add(
        $localize`:@@CaseHasTimedoutExternalForwardsShort:Case has timed out transfers, please proceed manually`
      );
    }

    // GP Connect requries validation of patient information with NHS or PDS
    const patientValidation = this.message?.patient_validation;
    if (!patientValidation) {
      this.integrationErrorsList.add(
        $localize`:@@PatientValidationMissing:Case has not been validated with NHS Login or PDS`
      );
    } else if (!patientValidation.is_valid) {
      // If patientValidation has been done, but was invalid.
      this.integrationErrorsList.add(
        $localize`:@@PatientInformationInvalid:Patient information has not been validated`
      );
    }

    // If Practice code is not found
    const practiceCodes = this.sessionService.serviceGroup?.practice_codes;
    if (!practiceCodes || practiceCodes.length === 0) {
      this.integrationErrorsList.add(
        $localize`:@@CouldNotFindOds:Could not find practice ODS codes`
      );
    }

    // If message is not in progress
    if (this.message?.state !== this.messageStates.IN_PROGRESS) {
      this.integrationErrorsList.add(
        $localize`:@@CaseNotInProgress:Integration actions are disabled until case is in progress`
      );
    }

    // If message has pending external forwards
    if (this.message?.has_pending_external_forwards === true) {
      this.integrationErrorsList.add(
        $localize`:@@CaseHasPendingExternalForwards:Case has transfers to patient record in progress`
      );
    }
  }
}
