<section
  [class.feature-followups]="followupsEnabled"
  [class.feature-usermanagement]="userManagementEnabled">
  <user-satisfaction></user-satisfaction>
  <header>
    <app-header></app-header>
  </header>

  <section
    class="app-body"
    [class.welcome-open]="openWelcome"
    [class.message-open]="openMessage"
    [class.followup-open]="openFollowup && openMessage">
    <toaster-container [toasterconfig]="toasterconfig"></toaster-container>
    <router-outlet></router-outlet>
  </section>

  <div
    *ngIf="envLabel"
    id="dev-ribbon-gibbon"
    style="
      background-color: #ffa;
      border: 1px solid black;
      overflow: hidden;
      text-transform: uppercase;
      white-space: nowrap;
      position: absolute;
      left: -50px;
      top: 30px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      z-index: 9000;
    ">
    <a
      href="#"
      onclick="document.getElementById('dev-ribbon-gibbon').style.display='none'; return false;"
      style="display: block; color: #000; font-weight: 600; padding: 3px 70px"
      >{{ envLabel }}</a
    >
  </div>

  <!-- 
    Disabled until a solution is found for DEVEL-2879
    <app-sleep-modal></app-sleep-modal>
  -->
</section>
