// @ts-nocheck
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FollowupComponent } from './followup.component';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { BsDropdownModule } from 'ngx-bootstrap';
import { UtilsModule } from 'app/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MomentModule,
    BsDropdownModule.forRoot(),
    UtilsModule,
  ],
  exports: [FollowupComponent],
  declarations: [FollowupComponent],
})
export class FollowupModule {}
