<label [ngClass]="{ disabled: disabled }">
  {{ label }}
  <input
    type="checkbox"
    [checked]="value"
    (click)="toggle($event)"
    [disabled]="disabled" />
  <span
    class="slider"
    [ngClass]="{ disabled: disabled }"
    [title]="title"></span>
</label>
