<div class="delete-confirmation-popup">
  <h2>{{ title }}</h2>

  <ng-container *ngFor="let item of items">
    <p class="label">{{ item?.label }}</p>
    <p>{{ item?.content }}</p>
  </ng-container>

  <div class="button-group">
    <button type="button" class="btn cancel" (click)="emit(false)">
      {{ cancel }}
    </button>
    <button
      type="button"
      class="btn delete"
      (click)="emit(true)"
      [disabled]="disabled">
      {{ delete }}
    </button>
  </div>
</div>
