<header>
  <h1 i18n="@@dataReports">Data exports</h1>
  <p i18n="@@dataReportsDesc">
    Export data from your organisations data within Klinik. Get insights into
    casecounts, team productivity and more.
  </p>
</header>

<p
  i18n="@@dateRangeError"
  class="date-range-error"
  *ngIf="dateRangeOverTwelveMonths">
  The date range may not exceed 12 months
</p>

<div class="report-generation-row">
  <!-- Report type -->
  <mat-form-field>
    <mat-label i18n="@@chooseReportType">Choose export type</mat-label>
    <mat-select [disabled]="selectorsDisabled" [(ngModel)]="selectedReportType">
      <mat-option
        *ngFor="let o of reportOptions"
        [value]="o.value"
        [disabled]="o.disabled">
        {{ o.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Start date -->
  <div class="date-picker">
    <label i18n="@@startDateLabel">Start date</label>
    <input type="date" [(ngModel)]="startDate" [disabled]="selectorsDisabled" />
  </div>

  <!-- End date -->
  <div class="date-picker">
    <label i18n="@@endDateLabel">End date</label>
    <input type="date" [(ngModel)]="endDate" [disabled]="selectorsDisabled" />
  </div>

  <!-- Trigger -->
  <button
    class="btn action"
    (click)="queueReport(selectedReportType)"
    [disabled]="triggerDisabled">
    <span i18n="@@exportData">Generate report</span>
    <app-loading
      *ngIf="generationRequestPending"
      class="button-spinner"></app-loading>
  </button>
</div>

<!-- Polling indicator -->
<div class="polling-loader" *ngIf="pollingInProgress">
  <h2 i18n="@@generatingReport">Exporting your data...</h2>
  <p i18n="generatingReportDesc">
    We are generating the data report for you. Depending on the report type and
    time frame selected this may take anywhere from 15 seconds to a few minutes.
    Please wait patiently. A save prompt will appear here once everything is
    ready.
  </p>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<!-- Generic error message -->
<div class="error-container" *ngIf="hasErrored">
  <p i18n="somethingWentWrong">Something went wrong. Please try again later.</p>
</div>
