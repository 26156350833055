// @ts-nocheck
import { Component, Input } from '@angular/core';

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

/**
 * Represents a notification component that displays messages with optional icons and styling.
 *
 * To use this component, include it in your template and provide content between its opening and closing tags.
 * You can also customize the notification type and icon by using the `type` and `icon` input properties.
 *
 * @example
 * ```
 * <app-notification type="NotificationType.SUCCESS" icon="check-circle">
 *   This is a success notification.
 * </app-notification>
 * ```
 */
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  /**
   * The icon name to be displayed in the notification. All FontAwesome icons should be valid.
   *
   * @type {string}
   * @default 'null'
   */
  @Input()
  public readonly icon: string = null;

  /**
   * The type of notification to be displayed. This will determine the styling of the notification.
   * The available types are `NotificationType.SUCCESS`, `NotificationType.ERROR`, `NotificationType.INFO`, and `NotificationType.WARNING`.
   * If no type is provided, the default type is `NotificationType.INFO`.
   * @type {NotificationType}
   * @default NotificationType.INFO
   */
  @Input()
  public readonly type: NotificationType = NotificationType.INFO; // default
}
