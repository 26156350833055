// @ts-nocheck
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-superuser',
  templateUrl: './superuser.component.html',
  styleUrls: ['./superuser.component.scss'],
})
export class SuperuserComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
