// @ts-nocheck
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-top-three',
  templateUrl: './top-three.component.html',
  styleUrls: ['./top-three.component.scss'],
})
export class TopThreeComponent implements OnChanges {
  @Input()
  private chart: Chart;

  public items: object[] = null;

  ngOnChanges(): void {
    const labels = this.chart.data.labels;
    this.items = [];

    for (let i = 0; i < labels.length; i++) {
      if (i === 3) {
        break;
      }
      this.items.push({
        title: labels[i],
        value: this.chart.data.datasets[0].data[i],
      });
    }
  }
}
