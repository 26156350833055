// @ts-nocheck
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export class DropdownItem {
  name: String;
  value: any;

  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}

@Component({
  selector: 'app-simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.scss'],
})
export class SimpleDropdownComponent implements OnInit {
  @Input()
  public items: Array<DropdownItem> = [];

  @Input()
  public dropDownName: string;

  @Input()
  public label: string;

  private isRequiredField: boolean = false;

  public get isRequired() {
    return this.isRequiredField;
  }

  @Input()
  public set isRequired(required: boolean) {
    if (required) {
      this.internalFormControl.setValidators([Validators.required]);
    } else {
      this.internalFormControl.clearValidators();
    }
    this.isRequiredField = required;
  }

  @Input()
  public set isDisabled(disabled: boolean) {
    if (disabled) {
      this.internalFormControl.disable();
    } else {
      this.internalFormControl.enable();
    }
  }

  @Input()
  public set isTouched(touched: boolean) {
    touched
      ? this.internalFormControl.markAsTouched()
      : this.internalFormControl.markAsUntouched();
  }

  @Input()
  public errorText: string;

  @Input()
  public set preSetValue(value: any) {
    this.internalFormControl.setValue(value);
  }

  @Output()
  private onChangeEmitter: EventEmitter<DropdownItem> = new EventEmitter();

  public internalFormGroup = new FormGroup({});

  public internalFormControl = new FormControl({ updateOn: 'blur' });

  constructor() {}

  ngOnInit() {
    this.internalFormGroup.addControl(
      'internalFormControl',
      this.internalFormControl
    );
    this.internalFormControl.valueChanges.subscribe(() => {
      this.onChangeEmitter.emit(this.internalFormControl.value);
    });
  }

  get showErrors(): boolean {
    return (
      this.internalFormControl.errors !== null &&
      this.internalFormControl.touched
    );
  }
}
