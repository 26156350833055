// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { environment } from 'environments/environment';
import { Permissions } from 'permissions/permissions.module';
import { Subscription } from 'rxjs';
import { DashboardService } from 'services/dashboard.service';
import { DatepickerService } from 'services/datepicker.service';
import { SessionService } from 'services/session.service';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public sidebarOpen = true;
  public dailyData: any = {};
  public activeView: string = null;
  public activeChartgroup: string = null;
  public activeChart: Chart = null;
  public dailyView = Views.DAILY;
  public startDate: string = null;
  public endDate: string = null;
  public apiStatus: string = null;

  private selectedServices = [];
  private selectedContactTypes = [];
  private charts = {};
  private views: object = null;
  private data_sets: object = {};
  private datePickerSubscription: Subscription;
  public readonly permissions = Permissions;

  constructor(
    private dashboardService: DashboardService,
    private sessionService: SessionService,
    private utilsService: UtilsService,
    private toaster: ToasterService,
    private datepickerService: DatepickerService
  ) {}

  ngOnInit(): void {
    if (
      this.getConfig() &&
      this.sessionService.checkPermissions(Permissions.DASHBOARD_VIEW)
    ) {
      this.initSubscriptions();

      if (window.innerWidth < 1324) {
        setTimeout(() => this.toggleSidebar(), 200);
      }
    } else {
      this.apiStatus = 'restricted';
    }
  }

  ngOnDestroy(): void {
    if (this.datePickerSubscription) {
      this.datePickerSubscription.unsubscribe();
    }
  }

  initSubscriptions(): void {
    this.datePickerSubscription = this.datepickerService.state$.subscribe(
      (datePickerObject: DatePickerObject) => {
        this.startDate = datePickerObject.startDate;
        this.endDate = datePickerObject.endDate;
        this.getData();
      }
    );
  }

  toggleSidebar(closeSideBar = false): void {
    if (closeSideBar === true) {
      this.sidebarOpen = false;
    } else {
      this.sidebarOpen = !this.sidebarOpen;
    }
  }

  updateSelectedServices(selectedServices = []): void {
    this.selectedServices = selectedServices;
    this.getData();
  }

  async getConfig() {
    try {
      const config = this.dashboardService.fetchViews();
      this.views = config['views'];
      this.data_sets = config['data_sets'];
      this.activeView = Object.keys(this.views)[0];
      this.activeChartgroup = Object.keys(this.views[this.activeView])[0];
      return true;
    } catch (error) {
      this.toaster.pop('error', $localize`:@@DashboardDataError:`);
      if (!environment.production) {
        console.error(error);
      }
      return false;
    }
  }

  async getData(): Promise<void> {
    if (!this.startDate || !this.endDate || !this.data_sets) {
      return;
    }

    const data = {
      start_date: this.startDate,
      end_date: this.endDate,
      services: this.selectedServices,
      contact_types: this.selectedContactTypes,
      data_sets: Array.from(this.data_sets[this.activeView]),
    };

    try {
      this.charts = await this.dashboardService.getData(data, this.activeView);
      this.toaster.pop('success', $localize`:@@DashboardDataSuccess:`);
      this.switchChartgroup(this.activeChartgroup);
    } catch (error) {
      if (!environment.production) {
        console.log(error);
      }

      this.toaster.pop('error', $localize`:@@DashboardDataError:`);
    }
  }

  async switchChart(event: object): Promise<void> {
    if (event['view'] === this.dailyView) {
      this.views[this.dailyView]['dashboard'].forEach(
        (chart: object) =>
          (this.dailyData[chart['key']] = this.charts[chart['key']])
      );
      this.dailyData = { ...this.dailyData };
    }

    if (event['view'] !== null) {
      this.activeView = event['view'];
    }

    if (event['key'] !== null) {
      this.activeChart = { ...this.charts[event['key']] };
    }

    if (event['view'] === this.dailyView) {
      this.activeChart =
        this.startDate === this.endDate
          ? this.charts['contacts-by-hour']
          : this.charts['contacts-by-day'];
    }
  }

  switchChartgroup(chartGroup: string): void {
    this.activeChartgroup = chartGroup;

    for (const view in this.views) {
      if (Object.keys(this.views[view]).indexOf(this.activeChartgroup) !== -1) {
        this.switchChart({
          key: this.views[view][chartGroup][0]['key'],
          view: view,
        });
        break;
      }
    }
  }

  switchView(view: string, chartGroup: string = null) {
    this.activeView = view;
    this.getData();
    chartGroup =
      chartGroup === null ? Object.keys(this.views[view])[0] : chartGroup;
    this.switchChartgroup(chartGroup);
  }

  switchToLinkedChart(linkedChart: LinkedChart) {
    if (
      this.utilsService.ObjectHasKey(
        this.views,
        `${linkedChart.view}.${linkedChart.chartgroup}`
      )
    ) {
      this.switchView(linkedChart.view, linkedChart.chartgroup);
    }
  }

  handleContactTypeUpdate(contactTypes: DropdownItem[]): void {
    this.selectedContactTypes = contactTypes.map(
      (contactType: DropdownItem) => contactType.value
    );
    this.getData();
  }

  handleServiceFilterUpdate(services: DropdownItem[]): void {
    this.selectedServices = services.map(
      (service: DropdownItem) => service.value
    );
    this.getData();
  }
}
