<div class="create-user btn-group">
  <button
    class="btn btn-submit"
    *hasPermissions="[permissions.USERS_CREATE]"
    (click)="createUser()"
    i18n="@@kayttajalistaSuodatusLuoUusi">
    Create new user
  </button>
</div>

<div class="search-term btn-group">
  <div class="form-group">
    <input
      class="form-control"
      type="text"
      i18n-placeholder="@@kayttajalistaSuodatusHakuNimella"
      placeholder="Hae nimellä..."
      name="searchTerm"
      [(ngModel)]="searchTerm"
      (keyup.enter)="filterBySearchTerm()" />
    <button type="button" (click)="filterBySearchTerm()" i18n="@@filterSearch">
      Hae
    </button>
  </div>
</div>

<div
  class="service-select btn-group"
  [class.active]="isServiceSelected()"
  [class.single]="serviceList.length === 1"
  dropdown>
  <button dropdownToggle type="button" class="btn dropdown-toggle">
    <span
      *ngIf="!isServiceSelected()"
      i18n="@@kayttajalistaSuodatusYksikotKaikki"
      >All units</span
    >
    <span *ngIf="isServiceSelected()">{{ service.name }}</span>
    <span class="caret"></span>
  </button>

  <div *dropdownMenu class="dropdown-menu" role="menu">
    <div
      *ngIf="isServiceSelected()"
      class="dropdown-item"
      (click)="filterByService(null)"
      i18n="@@kayttajalistaSuodatusYksikotValitseKaikki">
      KaikkAll units
    </div>
    <div *ngFor="let s of serviceList">
      <div *ngIf="s !== service" role="menuitem">
        <div class="dropdown-item" (click)="filterByService(s)">
          {{ s.name }}
        </div>
      </div>
    </div>
  </div>
</div>
