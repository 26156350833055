// @ts-nocheck
export interface ValidSessionResponse {
  session: SuccessTypes.valid | ErrorTypes.invalid;
}

export interface ValidMFACodeResponse {
  id: number;
  sessionid: string;
  pro_submit_token: string;
}

export enum SuccessTypes {
  valid = 'valid',
}

export enum ErrorTypes {
  unauthorized = 401,
  unauthorized_session_code = 'unauthorized_session',
  forbidden = 403,
  notFound = 404,
  tooManyRequests = 429,
  invalidEmail = 'invalidEmail',
  unknown = 'unknown',
  internalServerError = 500,
  invalid = 'invalid',
}
