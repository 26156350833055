import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private sessionService: SessionService) {}

  /** Returns true if the service group has the Black Pear TPP feature flag enabled */
  get isBlackPearTPPEnabled(): boolean {
    return !!this.sessionService?.serviceGroup?.black_pear_tpp_enabled;
  }

  /** Returns true if the Black Pear Emis integration is enabled. */
  get isBlackPearEmisIntegrationEnabled(): boolean {
    return !!this.sessionService?.serviceGroup?.black_pear_emis_enabled;
  }

  /** Returns true if the GP Connect integration is enabled. */
  get isGPConnectIntegrationEnabled(): boolean {
    return !!this.sessionService?.serviceGroup?.integration_settings
      ?.gp_connect_enabled;
  }

  /** Returns true if the patient identity feature is enabled*/
  get isIdentityLinkEnabled(): boolean {
    return !!this.sessionService.serviceGroup?.patient_identity_lookup_enabled;
  }

  /** Returns true if the Copy button content should be reduced (DEVEL-3543) */
  get isCopyContentReduced(): boolean {
    return !!this.sessionService?.serviceGroup?.reduced_copy_content_enabled;
  }

  /** Returns true if the Data Reports feature is enabled */
  get isDataReportsEnabled(): boolean {
    return !!this.sessionService?.serviceGroup?.data_reports_enabled;
  }
}
