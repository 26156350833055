// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'services/message.service';
import { StateService, StateKeys } from 'services/state.service';

@Component({
  selector: 'app-messages-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnDestroy {
  private stateChangeSubscription: Subscription;
  public openDrawer = false;

  constructor(
    protected messageService: MessageService,
    protected stateService: StateService
  ) {}

  ngOnInit() {
    const self = this;
    this.stateChangeSubscription = this.stateService.state$.subscribe(function (
      state
    ) {
      switch (state['key']) {
        case StateKeys.toggledDrawer:
          self.openDrawer = state['value'];
          break;
      }
    });
  }

  ngOnDestroy() {
    this.stateChangeSubscription.unsubscribe();
  }
}
