// @ts-nocheck
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PublicService } from 'services/public.service';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { RedirectionComponent } from './redirection/redirection.component';

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  declarations: [DocumentUploadComponent, RedirectionComponent],
  providers: [PublicService],
})
export class PublicModule {}
