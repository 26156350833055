<section class="file-upload-body">
  <section class="file-upload flex-container">
    <section *ngIf="checkingToken" class="page-load">
      <div class="loading-icon flex-item"></div>
    </section>
    <section
      *ngIf="validToken && !uploadSuccess"
      class="upload-section flex-container">
      <h1 i18n="@@attachmentUploadHeader">Attachment upload requested</h1>
      <p i18n="@@attachmentUploadDetail">
        With regards to your recent health enquiry to {{ practice }} linked to
        case ID <b>{{ appointment_request_uuid }}</b> please select '+ click to
        add attachment/photo' to send your GP surgery photos of the
        condition/problem. This will help the clinician who will be triaging
        your concern to offer you further advice or treatment.
      </p>
      <p i18n="@@attachmentUploadDisclaimerMedicalRecord">
        Photos may be saved as part of your medical record. Please do not send
        photos if you do not consent for this to occur.
      </p>
      <p i18n="@@attachmentUploadDisclaimerFrontCamera">
        Avoid using the front-facing camera of your phone due to automatic image
        smoothing.
      </p>
      <p i18n="@@attachmentUploadDisclaimerSeveralPhotos">
        Please take several photos at different angles.
      </p>
      <p i18n="@@attachmentUploadDisclaimerPhotos">
        Please note: Do NOT submit photos of intimate areas. In the case of
        children in particular.
      </p>
      <form class="file-upload-form">
        <div class="input-container flex-container">
          <p i18n="@@fileUploadType">
            File type must be <b>.jpg, .jpeg</b> or <b>.png</b>
          </p>
          <p i18n="@@fileUploadSize">
            Max file size for <b>one</b> attachment/photo is <b>15 MB</b>
          </p>
          <div>
            <label
              for="file-upload"
              class="custom-file-upload"
              [ngClass]="{ disabled: files.length >= 5 }">
              <i class="fa fa-plus-square-o fa-5x"></i>
              <p i18n="@@clickAddFile">Click to add attachment/photo</p>
            </label>
            <input
              id="file-upload"
              class="file-input-static"
              type="file"
              multiple
              [formControl]="fileForm"
              (change)="onFileChange($event)"
              accept="image/png, image/jpeg, image/jpg"
              [attr.disabled]="files.length >= 5 ? '' : null" />
          </div>
          <span *ngIf="error" class="file-error"
            ><i class="fa fa-exclamation-circle" aria-hidden="true"></i
            >{{ error }}</span
          >
          <div class="file-list">
            <p *ngIf="files.length === 0" i18n="@@noFilesAdded">
              No attachment/photo uploaded yet...
            </p>
            <div *ngFor="let image of files; let i = index" class="file-item">
              <img [src]="image.file" alt="" />
              <button type="button" class="btn" (click)="remove(i)">
                <i class="fa fa-trash"></i>
                <span i18n="@@delete">Delete</span>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="loading" class="upload-popup loading-animation">
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          <p i18n="@@uploadingFiles">Sending attachments/photos</p>
        </div>
        <div *ngIf="uploadError" class="upload-popup loading-failed">
          <p i18n="@@fileUploadFailed">
            Sending attachments/photos failed. Please try again
          </p>
          <button class="btn" type="button" (click)="uploadError = false">
            OK
          </button>
        </div>
      </form>
      <button
        class="btn submit-button"
        type="button"
        (click)="files.length ? onSubmit() : null"
        [disabled]="files.length === 0 || loading || uploadError">
        <i class="fa fa-upload"></i>
        <span i18n="@@sendFiles">Send attachments/photos</span>
      </button>
    </section>
    <section
      *ngIf="!checkingToken && !validToken && !caseHandled"
      class="expired-link">
      <i class="fa fa-history fa-5x"></i>
      <h1 i18n="@@expiredLink">The link has expired</h1>
      <p i18n="@@expiredLinkExplanation">
        If you refreshed the page before uploading your attachments/photos, you
        can still access the upload page from the original link sent to you.
      </p>
    </section>
    <section *ngIf="!validToken && caseHandled" class="upload-success">
      <i class="fa fa-check fa-5x"></i>
      <h1 i18n="@@closedCase">
        Your Healthcare professional has closed your enquiry
      </h1>
    </section>
    <section *ngIf="uploadSuccess" class="upload-success">
      <i class="fa fa-check-circle-o fa-5x"></i>
      <h1 i18n="@@successfulUpload">
        File(s) have been uploaded to your case
        <b>{{ appointment_request_uuid }}</b>
      </h1>
    </section>
  </section>
</section>
