<section class="detail-status" *ngIf="message">
  <div class="message-status">
    <div class="status-priority" *ngIf="message.priority > 0 && !isRestricted">
      <ng-container *ngIf="message.priority === 1">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <ng-container i18n="@@viestinStatusKiireellinen"
          >Kiireellinen</ng-container
        >
      </ng-container>

      <ng-container *ngIf="message.priority > 1">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <ng-container i18n="@@kiireellisyysarvioKiireellisyysPaivystys"
          >Päivystys</ng-container
        >
      </ng-container>
    </div>

    <div class="status-assignee">
      <strong i18n="@@viestinStatusKasittelija">Käsittelijä:</strong>
      <ng-container
        i18n="@@viestinStatusEiKasittelijaa"
        *ngIf="!message?.assigned_to"
        >Ei käsittelijää</ng-container
      >
      <ng-container *ngIf="message?.assigned_to"
        >{{ message?.assigned_to?.first_name }}
        {{ message?.assigned_to?.last_name }}</ng-container
      >
    </div>

    <div class="pro-form-filler" *ngIf="message.pro_form_filler">
      <strong i18n="@@proFormFiller">Täyttäjä:</strong>
      {{ message.pro_form_filler }}
    </div>
  </div>

  <div class="loading-container" *ngIf="fetchinExternalForwards">
    <i class="fa fa-spinner spin"></i>
  </div>

  <div class="external-forward-statuses" *ngIf="externalForwardStatuses">
    <app-notification
      *ngIf="message?.has_timed_out_external_forwards"
      [icon]="'info-circle'">
      {{ externalForwardStatusTranslations['externalforwardtimedout'] }}
    </app-notification>

    <div
      class="external-forward {{ getDisplayStatus(ef) }}"
      *ngFor="let ef of externalForwardStatuses">
      <div class="status">
        <i
          class="fa"
          [ngClass]="{
            'fa-history':
              getDisplayStatus(ef) === EXTERNAL_FORWARD_STATUSES.PENDING,
            'fa-check':
              getDisplayStatus(ef) === EXTERNAL_FORWARD_STATUSES.SUCCESS,
            'fa-times':
              getDisplayStatus(ef) === EXTERNAL_FORWARD_STATUSES.FAILURE
          }"></i>
        <p>{{ ef.translated_status }}</p>
        <p>{{ ef.latest_created_at }}</p>
      </div>

      <ul>
        <li *ngIf="ef.latest_status_code" i18n="@@externalForwardStatusCode">
          Code: {{ ef.latest_status_code }}
        </li>
        <li
          *ngIf="ef.latest_status_message"
          i18n="@@externalForwardStatusMessage">
          Message: {{ ef.latest_status_message }}
        </li>
      </ul>
    </div>
  </div>
</section>
