// @ts-nocheck
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { CardChartComponent } from './card/card-chart/card-chart.component';
import { CardComponent } from './card/card.component';
import { SideBySideComponent } from './card/side-by-side/side-by-side.component';
import { TableAndChartComponent } from './card/table-and-chart/table-and-chart.component';
import { TableComponent } from './card/table/table.component';
import { TopThreeComponent } from './card/top-three/top-three.component';
import { TotalComponent } from './card/total/total.component';
import { ChartComponent } from './chart/chart.component';
import { DailyComponent } from './daily/daily.component';
import { DashboardComponent } from './dashboard.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UtilsModule } from 'app/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ClickOutsideModule,
    UtilsModule,
  ],
  declarations: [
    SidebarComponent,
    DashboardComponent,
    TotalComponent,
    ChartComponent,
    DailyComponent,
    DatepickerComponent,
    TableComponent,
    CardComponent,
    TopThreeComponent,
    SideBySideComponent,
    CardChartComponent,
    TableAndChartComponent,
  ],
})
export class DashboardModule {}
