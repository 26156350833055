// @ts-nocheck
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UtilsService } from 'services/utils.service';
import {
  Enquiry,
  IdentifierAndTranslation,
} from 'app/shared/methods/enquiry/enquiry';
import { SessionService } from 'services/session.service';

@Component({
  selector: 'app-patient-identifier-display',
  templateUrl: './patient-identifier-display.component.html',
  styleUrls: ['./patient-identifier-display.component.scss'],
})
export class PatientIdentifierDisplayComponent implements OnInit, OnChanges {
  @Input()
  public message: object;

  public identifier: String;
  public enquiry: Enquiry;

  private identifierTranslations = {
    viestinAsiakasHenkilotunnus: $localize`:@@viestinAsiakasHenkilotunnus:Personal identification number`,
    patientBSN: $localize`:@@patientBSN: BSN`,
    nhsNumber: $localize`:@@nhsNumber:NHS number`,
  };

  constructor(
    protected utilsService: UtilsService,
    protected sessionService: SessionService
  ) {}

  ngOnInit() {
    this.updateIdentifierDisplay();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateIdentifierDisplay();
  }

  getIdentifierTranslation(identifier: string): string {
    try {
      return this.identifierTranslations[identifier];
    } catch (error) {
      return 'Translation not found';
    }
  }

  updateIdentifierDisplay(): void {
    this.enquiry = new Enquiry(this.message, this.utilsService.currentLocale);

    // Set identifier
    const identifier: IdentifierAndTranslation | null =
      this.enquiry.getDefaultIdentifier(this.sessionService.market);

    if (!identifier?.value) {
      this.identifier = '';
      return;
    }

    this.identifier = `${this.getIdentifierTranslation(
      identifier.translationKey
    )}: ${identifier.value}`;
  }
}
