// @ts-nocheck
import { daily } from './views/daily';
import { feedback } from './views/feedback';
import { medical } from './views/medical';
import { outcomes } from './views/outcomes';
import { technical } from './views/technical';
import { usage } from './views/usage';
import { user } from './views/user';

export const DashboardViews: { [id: string]: View } = {
  daily,
  user,
  usage,
  outcomes,
  medical,
  technical,
  feedback,
};
