// @ts-nocheck
import { Component, Input, OnInit } from '@angular/core';

/**
 * @deprecated
 * @use NotificationComponent instead
 */
@Component({
  selector: 'app-error-notification',
  templateUrl: './error-notification.component.html',
  styleUrls: ['./error-notification.component.scss'],
})
export class ErrorNotificationComponent {
  @Input()
  public readonly text: string = null;
}
