// @ts-nocheck
import { Injectable } from '@angular/core';

@Injectable()
export class Configuration {
  public sessionName = 'KlinikProSession';
  public updateInterval = 90; // in seconds
  public pageSize = 20;
  public usersPageSize = 20;
  public defaultState: object = {
    selectedService: null,
    selectedMessage: null,
    selectedPriorities: [],
    selectedStates: [0, 1],
  };
  public customSettings = {
    'p5-digital': {
      logo: 'p5-logo.png',
      alt: 'P5 digital',
      header: '#0045e4',
    },
  };
}
