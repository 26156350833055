// @ts-nocheck
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, isObservable, of } from 'rxjs';
import { map, catchError, startWith } from 'rxjs/operators';

type returnType<T> =
  | Observable<{
      loading: boolean;
      data?: T;
      error?: unknown;
    }>
  | T;

/**
 * This pipe is used to wrap an observable value with a loading state.
 * Helps to avoid having to write a bunch of boilerplate code in the template.
 */
@Pipe({
  name: 'loading',
})
export class LoadingPipe implements PipeTransform {
  transform<T>(val: Observable<T>): returnType<T | {}> {
    if (!isObservable(val)) {
      // This might be the cae e.g. post request where we don't have an observable before user takes action.
      // Yet, the html template might already check for the loading state.
      return val;
    }

    return val.pipe(
      map((data) => ({ loading: false, data: data == undefined ? {} : data })),
      catchError((error) => of({ loading: false, error })),
      startWith({ loading: true })
    );
  }
}
