// @ts-nocheck
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpTools } from 'tools/httptools';

export interface AuthenticationData {
  /** Date in ISO format, YYYY-MM-DD */
  date: string;

  /** The number of patient cases in total for the day. */
  total_patient_cases: number;

  /** The number of patient cases that were successfully authenticated with NHS Login for the day. */
  nhs_login_success: number;

  /** The number of patient cases that were successfully authenticated with PDS for the day. */
  pds_success: number;

  /** The number of patient cases that failed to authenticate with PDS for the day. */
  pds_failure: number;

  /** The number of patient cases for which the NHS number was bypassed for the day. */
  nhs_number_bypassed: number;

  /** The number of patient cases for which a potential patient identity was suggested for the day. */
  identity_suggested: number;

  /** The number of patient cases for which a potential patient identity was confirmed for the day. */
  identity_suggestion_confirmed: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationDataService {
  constructor(private http: HttpClient, private httpTools: HttpTools) {}

  /**
   * Calls the backend to get the authentication data for a given service group.
   * @param serviceGroupId - The service group id.
   * @param startDate - The start date in ISO format (YYYY-MM-DD).
   * @param endDate - The end date in ISO format (YYYY-MM-DD).
   * @param includeAccessCases - Whether to include Access cases.
   * @param includeAccessCases - Whether to include Connect cases.
   * @returns The response from the backend.
   */
  async getAuthenticationData(
    serviceGroupId: number,
    startDate: string = null,
    endDate: string = null,
    includeAccessCases: boolean = true,
    includeConnectCases: boolean = true
  ): Promise<Array<AuthenticationData>> {
    const params = {};
    if (startDate) {
      params['start_date'] = startDate;
    }
    if (endDate) {
      params['end_date'] = endDate;
    }
    params['include_access'] = includeAccessCases;
    params['include_connect'] = includeConnectCases;

    return (await this.http
      .get(
        `${environment.backendURL}/service_group/${serviceGroupId}/messages/authentication-data`,
        {
          headers: this.httpTools.prepareHeaders(),
          params: params,
        }
      )
      .toPromise()) as Array<AuthenticationData>;
  }
}
