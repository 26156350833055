// @ts-nocheck
export enum DELIVERY_STATUS {
  SENT = 1,
  DELIVERED = 2,
  FAILED = 3,
  NEW = 4,
  READ = 5,
  FAILURE_ACKNOWLEDGED = 6,
  REJECTED = 7,
}

export enum MESSAGE_TYPES {
  SMS = 1,
  EMAIL = 2,
}

export enum MESSAGE_DIRECTIONS {
  INBOUND = 1,
  OUTBOUND = 2,
}

export interface FollowupMessage {
  id: number;
  external_id: number | null;
  message_id: number | null;
  actor: { first_name: string; last_name: string; full_name: string } | null;
  type: MESSAGE_TYPES;
  direction: MESSAGE_DIRECTIONS;
  title: string | null;
  body: string | null;
  sender_address: string;
  receiver_address: string;
  delivery_status: DELIVERY_STATUS;
  failure_reason: string | null;
  created_at: string;
  sent_to_emis: boolean | null;
  sent_today: boolean;
}
