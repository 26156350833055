// @ts-nocheck
import { Injectable } from '@angular/core';
import { HttpWrapperService } from './http-wrapper.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SessionService } from './session.service';
import { StateService, StateKeys } from './state.service';

/**
 * Service to manage snooze timers for a service group.
 */
@Injectable({
  providedIn: 'root',
})
export class SnoozeTimerService {
  constructor(
    private httpWrapper: HttpWrapperService,
    private http: HttpClient,
    private sessionService: SessionService,
    private stateService: StateService
  ) {}

  /**
   * Returns all snooze timers for the current service group.
   */
  getTimers() {
    return this.httpWrapper
      .get(`/service_group/${this.sessionService.serviceGroupId}/snooze_timer/`)
      .then((res: HttpResponse<object>) =>
        this.stateService.setState(StateKeys.snoozeTimers, res)
      )
      .catch(() => this.stateService.setState(StateKeys.snoozeTimerFailed, ''));
  }

  getActiveTimer() {
    return this.httpWrapper
      .get(
        `/service_group/${this.sessionService.serviceGroupId}/snooze_timer/snooze_active/`
      )
      .then((res: HttpResponse<object>) =>
        this.stateService.setState(StateKeys.snoozeActiveTimer, res)
      )
      .catch(() => this.stateService.setState(StateKeys.snoozeTimerFailed, ''));
  }

  /**
   * Creates a new snooze timer for the current service group.
   * @param timerData
   */
  createTimer(timerData): void {
    this.httpWrapper
      .post(
        `/service_group/${this.sessionService.serviceGroupId}/snooze_timer/`,
        timerData
      )
      .subscribe(
        (data) => {
          this.getTimers();
        },
        (error) => {
          this.stateService.setState(StateKeys.snoozeTimerFailed, error);
          console.log(error);
        }
      );
  }

  /**
   * Deletes a snooze timer for the current service group.
   */
  deleteTimer(timerId) {
    return this.httpWrapper
      .delete(
        `/service_group/${this.sessionService.serviceGroupId}/snooze_timer/${timerId}/`
      )
      .then(() => this.getTimers())
      .catch(() => this.stateService.setState(StateKeys.snoozeTimerFailed, ''));
  }

  /**
   * Modifies a snooze timer for the current service group.
   * @param timerData
   * @param timerId
   */
  modifyTimer(timerData, timerId) {
    this.httpWrapper
      .patch(
        `/service_group/${this.sessionService.serviceGroupId}/snooze_timer/${timerId}/`,
        timerData
      )
      .subscribe(
        (data) => {
          this.getTimers();
        },
        (error) => {
          this.stateService.setState(StateKeys.snoozeTimerFailed, error);
          console.log(error);
        }
      );
  }
}
