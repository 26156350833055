<div class="daily-container" [ngClass]="{ 'sidebar-open': sidebarOpen }">
  <app-card
    class="status"
    [type]="typeTableAndChart"
    [chart]="dailyData['status']"
    [activeView]="activeView">
  </app-card>
  <app-card
    class="time"
    [type]="typeSideBySide"
    [chart]="dailyData['avg_time']"
    [activeView]="activeView">
  </app-card>
  <app-card
    class="appointments"
    [type]="typeTopThree"
    [chart]="dailyData['appointment_types']"
    [activeView]="activeView"
    (linkedChartEmitter)="openLinkedChart($event)">
  </app-card>
  <app-card
    class="priorities"
    [type]="typeChart"
    [chart]="dailyData['priorities']"
    [activeView]="activeView">
  </app-card>
  <app-card
    class="conditions"
    [type]="typeTopThree"
    [chart]="dailyData['diagnoses']"
    [activeView]="activeView"
    (linkedChartEmitter)="openLinkedChart($event)">
  </app-card>
  <app-card
    class="contacts"
    [type]="typeChart"
    [chart]="activeChart"
    [activeView]="activeView">
  </app-card>
</div>
