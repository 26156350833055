// @ts-nocheck
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
import { SetPasswordComponent } from './setpassword/setpassword.component';

export const authRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent,
  },
  {
    path: 'setpassword',
    component: SetPasswordComponent,
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(authRoutes);
