// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { ServiceGroupService } from 'services/service-group.service';
import { HttpTools } from 'tools/httptools';

import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-superuser-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  public serviceGroupList: Array<object> = [];

  constructor(
    private serviceGroupService: ServiceGroupService,
    private toasterService: ToasterService,
    private httpTools: HttpTools,
    protected configuration: Configuration
  ) {}

  ngOnInit() {
    this.serviceGroupService
      .getAllServiceGroups()
      .then((serviceGroups) => {
        this.serviceGroupList = serviceGroups;
      })
      .catch((reason) => {
        this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`);
      });
  }
}
