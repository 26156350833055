<div
  class="datepicker"
  [ngClass]="{ unclickable: loading }"
  [tabindex]="1"
  [attachOutsideOnClick]="true"
  [clickOutsideEnabled]="open"
  (keyup.enter)="applyChanges()"
  (keyup.esc)="toggleDatepicker()"
  (clickOutside)="toggleDatepicker()">
  <div
    class="datepicker-headline"
    [ngClass]="{ 'datepicker-headline--open': open }"
    (click)="toggleDatepicker()">
    <i class="icon fa fa-calendar"></i>
    <p>{{ headline }}</p>
    <i class="arrow fa fa-angle-down" [ngClass]="{ 'arrow--open': open }"></i>
  </div>
  <div class="datepicker-body" *ngIf="open">
    <div class="quick-filters">
      <p
        class="filter"
        *ngFor="let filter of quickFilters; let i = index"
        [ngClass]="{ 'filter--selected': i === selectedQuickFilter }"
        (click)="selectQuickFilter(i, filter)">
        {{ filter.name }}
      </p>
    </div>
    <div class="month1">
      <div class="month">
        <div class="month-header">
          <i
            class="calendar-arrow fa fa-chevron-left"
            [ngClass]="{ 'calendar-arrow--disabled': !canRetreatMonth }"
            (click)="canRetreatMonth && previousMonth()"></i>
          <p class="header-text">
            <strong>{{ getMonth1Name() }}</strong>
          </p>
        </div>
        <div class="month-days">
          <div class="month-days-header" *ngFor="let day of weekDays">
            <p>{{ day }}</p>
          </div>
          <div
            [ngClass]="{
              'month-days-item': item.value > 0,
              'month-days-item--disabled': !item.enabled
            }"
            *ngFor="let item of month1Days">
            <p
              *ngIf="item.value > 0"
              [ngClass]="{
                'month-days-item--selected':
                  (startDate && item.date.isSame(startDate, 'day')) ||
                  (endDate && item.date.isSame(endDate, 'day')),
                'month-days-item--in-range':
                  startDate &&
                  endDate &&
                  item.date.isBetween(startDate, endDate, 'day')
              }"
              (click)="item.enabled && selectDate(item.date)">
              {{ item.value }}
            </p>
            <p *ngIf="item.value === 0"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="month2">
      <div class="month">
        <div class="month-header">
          <p class="header-text">
            <strong>{{ getMonth2Name() }}</strong>
          </p>
          <i
            class="calendar-arrow fa fa-chevron-right"
            [ngClass]="{ 'calendar-arrow--disabled': !canAdvanceMonth }"
            (click)="canAdvanceMonth && nextMonth()"></i>
        </div>
        <div class="month-days">
          <div class="month-days-header" *ngFor="let day of weekDays">
            <p>{{ day }}</p>
          </div>
          <div
            [ngClass]="{
              'month-days-item': item.value > 0,
              'month-days-item--disabled': !item.enabled
            }"
            *ngFor="let item of month2Days">
            <p
              *ngIf="item.value > 0"
              [ngClass]="{
                'month-days-item--selected':
                  (startDate && item.date.isSame(startDate, 'day')) ||
                  (endDate && item.date.isSame(endDate, 'day')),
                'month-days-item--in-range':
                  startDate &&
                  endDate &&
                  item.date.isBetween(startDate, endDate, 'day')
              }"
              (click)="item.enabled && selectDate(item.date)">
              {{ item.value }}
            </p>
            <p *ngIf="item.value === 0"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-input">
        <input
          type="text"
          [ngClass]="{ 'footer-input--invalid': dateInvalid['start'] }"
          (blur)="manualInputDate($event, 'start')"
          [ngModel]="startDate.format('L')" />
        -
        <input
          type="text"
          [ngClass]="{ 'footer-input--invalid': dateInvalid['end'] }"
          (blur)="manualInputDate($event, 'end')"
          [ngModel]="endDate.format('L')" />
      </div>
      <div class="footer-apply">
        <button
          class="btn btn-apply"
          (click)="applyChanges()"
          i18n="@@DatepickerApply">
          Apply
        </button>
      </div>
    </div>
  </div>
</div>
