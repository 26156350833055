// @ts-nocheck
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { Permissions } from 'permissions/permissions.module';
import { Subscription } from 'rxjs';
import { SessionService } from 'services/session.service';
import { ServiceGroupService } from 'services/service-group.service';
import { StateService, StateKeys } from 'services/state.service';
import { DatepickerComponent } from '../components/datepicker/datepicker.component';
import { AuditLogCaseType } from 'app/shared/methods/enquiry/enquiry';
import { SnoozeTimerService } from 'services/snooze-timer.service';

@Component({
  selector: 'app-snooze-timers',
  templateUrl: './snooze-timers.component.html',
  styleUrls: ['./snooze-timers.component.scss'],
})
export class SnoozeTimersComponent implements OnInit, OnDestroy {
  @ViewChild(DatepickerComponent)
  datePickerComponent: DatepickerComponent;

  public snoozeTimerDeleteItem: DeleteItem[] = [];
  public activeTab = 'snooze-events';

  /** All the snooze timers for the service group.
   * This contains both timed snooze events and opening hours.
   */
  public timers: Array<SnoozeTimer> = null;
  public auditEvents: object[] = null;
  public totalAuditEventCount: number;
  public addNew = false;
  public timedEvent = false;
  public showSummary = false;
  public showDeletePopup = false;
  public startDate: moment.Moment = null;
  public endDate: moment.Moment = null;
  public recurrentDays: number[] = null;
  public selectedId: number = null;
  public hasEditPermission: boolean = null;
  public timeInvalid: any = {
    start: false,
    end: false,
  };

  private stateChangeSubscription: Subscription = null;
  private today: moment.Moment = null;
  private sgTimezone: string = null;

  // Snooze history view pagination settings
  public page: number = 1;
  public pageSize: number = 30;
  public maxSize: number = 30;
  public previousIcon: string =
    '<i class="fa fa-arrow-left" aria-hidden="true">';
  public nextIcon: string = '<i class="fa fa-arrow-right" aria-hidden="true">';
  public isLoadingSnoozeEvents: boolean = false;

  public readonly weekDays = [
    ...moment.weekdaysShort().slice(1),
    moment.weekdaysShort(0),
  ];
  public readonly permissions = Permissions;

  constructor(
    protected serviceGroupService: ServiceGroupService,
    private snoozeTimersService: SnoozeTimerService,
    private stateService: StateService,
    protected toasterService: ToasterService,
    protected sessionService: SessionService
  ) {}

  get snoozeDisclaimer() {
    const noPastSnoozeWarning = $localize`:@@noPastSnoozeWarning:A snooze event must start in the future. We recommend that you allow your patients 10 minutes to complete the form, so that they will receive a warning of your closure time.`;
    const snoozeBankHolidayInfo = $localize`:@@snoozeBankHolidayInfo:For bank holidays, set the snooze to start on the day/time you close, and set to resume on the day/time you open. E.g. Fri 4 pm - Mon 8 am.`;

    // It would be better to show these in two separate paragraphs, but the UI component being used doesn't quite support that.
    return `${noPastSnoozeWarning} ${snoozeBankHolidayInfo}`;
  }

  ngOnInit() {
    this.sgTimezone = this.sessionService.timeZone;
    this.today = moment_tz().tz(this.sgTimezone);
    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.snoozeTimers:
            this.timers = <Array<SnoozeTimer>>state['value'];
            break;
          case StateKeys.snoozeTimerFailed:
            this.toasterService.pop(
              'danger',
              $localize`:@@snoozeActionFailed:`
            );
            break;
        }
      }
    );
    this.hasEditPermission = this.sessionService.checkPermissions(
      this.permissions.SETTINGS_PATIENT_FORM_EDIT
    );

    this.startDate = this.today;
    this.endDate = moment(this.today).add(1, 'hour');
    this.recurrentDays = [];
    this.snoozeTimersService.getTimers();
  }

  snoozeHistoryPageChange(e: { page: number; itemsPerPage: number }) {
    this.page = e.page;
    this.getSnoozeTimerHistory();
  }

  async getSnoozeTimerHistory() {
    try {
      this.isLoadingSnoozeEvents = true;
      const response: QuerySetResponse =
        await this.serviceGroupService.getAuditEvents({
          page: this.page,
          type: [
            AuditLogCaseType.NEW_TIMED_SNOOZE_EVENT,
            AuditLogCaseType.NEW_RECURRING_OPENING_HOURS,
            AuditLogCaseType.DEACTIVATE_OPENING_HOURS,
            AuditLogCaseType.ACTIVATE_OPENING_HOURS,
            AuditLogCaseType.DELETE_SNOOZE_EVENT,
          ].join(','),
        });

      this.auditEvents = response.results;
      this.totalAuditEventCount = response.count;
    } catch (e) {
      this.toasterService.pop(
        'danger',
        $localize`:@@auditEventFetchingFailed:`
      );
    } finally {
      this.isLoadingSnoozeEvents = false;
    }
  }

  ngOnDestroy() {
    this.stateChangeSubscription.unsubscribe();
  }

  promptDelete(timer: SnoozeTimer): void {
    if (timer.recurring_event && timer.recurring_event.length === 0) {
      this.snoozeTimerDeleteItem = [
        {
          label: $localize`:@@snoozeTimerStart:`,
          content: this.formatDate(timer, 'start'),
        },
        {
          label: $localize`:@@snoozeTimerEnd:`,
          content: this.formatDate(timer, 'end'),
        },
      ];
    } else {
      this.snoozeTimerDeleteItem = [
        {
          label: $localize`:@@snoozeTimerStart:`,
          content: this.formatTime(timer, 'start'),
        },
        {
          label: $localize`:@@snoozeTimerEnd:`,
          content: this.formatTime(timer, 'end'),
        },
        {
          label: $localize`:@@recurrentSnoozeRepeat:`,
          content: `${this.weekDays.filter(
            (weekDay, index) => timer['recurring_event'].indexOf(index) !== -1
          )}`,
        },
      ];
    }

    this.selectedId = timer.id;
    this.showDeletePopup = true;
  }

  /**
   * Returns all opening hours that are active.
   */
  recurringTimersActive(): Array<SnoozeTimer> {
    return this.timers.filter((t) => t.recurring_event.length > 0 && t.active);
  }

  /** Returns all opening hours that are inactive */
  recurringTimersInactive(): Array<SnoozeTimer> {
    return this.timers.filter((t) => t.recurring_event.length > 0 && !t.active);
  }

  /** Returns all timed ie. temporary snooze events. */
  programmedTimers(): Array<SnoozeTimer> {
    return this.timers
      .filter((t) => t.recurring_event.length === 0)
      .sort((a, b) => {
        return (
          moment_tz(a.timed_event.lower).tz(this.sgTimezone).unix() -
          moment_tz(b.timed_event.lower).tz(this.sgTimezone).unix()
        );
      });
  }

  /**
   * Check if the timer dates are valid. Returns true if valid, false otherwise. Also shows a toaster message if invalid.
   * @param lower - The lower bound / start date.
   * @param upper - The upper bound / end date.
   */
  isValidTimerDates(lower, upper, timePeriod: TimePeriod = TimePeriod.SNOOZE) {
    const lowerMoment = moment(lower);
    const upperMoment = moment(upper);

    const now = moment().tz(this.sgTimezone);
    // Set "now" to the end of the current minute
    now.seconds(59).milliseconds(999);

    if (timePeriod == TimePeriod.SNOOZE && lowerMoment.isBefore(now)) {
      this.toasterService.pop(
        'warning',
        $localize`:@@startTimeMustBeInTheFuture:Start time must be in the future`
      );
      return false;
    }

    if (!upperMoment.isAfter(lowerMoment)) {
      this.toasterService.pop('warning', $localize`:@@invalidDates:`);
      return false;
    }

    return true;
  }

  formatMomentDate(date, format) {
    return moment_tz.tz(date.format(format), this.sgTimezone).format();
  }

  /**
   * Checks that the timer configured by the user is valid.
   * @param timedEvent - If timedEvent is true, then it is a timed event (ie. snooze), otherwise it is a recurring event (ie. opening hours).
   */
  validateTimer(timedEvent: boolean) {
    const timePeriod = timedEvent
      ? TimePeriod.SNOOZE
      : TimePeriod.OPENING_HOURS;

    if (timePeriod == TimePeriod.SNOOZE) {
      this.startDate = this.datePickerComponent.startDate;
      this.endDate = this.datePickerComponent.endDate;
      this.recurrentDays = [];
    }
    const lower = this.formatMomentDate(this.startDate, 'YYYY-MM-DD HH:mm');
    const upper = this.formatMomentDate(this.endDate, 'YYYY-MM-DD HH:mm');

    if (!this.isValidTimerDates(lower, upper, timePeriod)) return;

    this.showSummary = true;
  }

  /**
   * Creates a new timer and sends it to the backend to be persisted.
   */
  sendTimer(recurrent) {
    const timerData = {};
    if (recurrent) {
      timerData['recurring_event'] = this.recurrentDays;
      this.checkEndOfDay(this.endDate);
    }
    timerData['timed_event'] = {
      lower: moment_tz
        .tz(this.startDate.format('YYYY-MM-DD HH:mm'), this.sgTimezone)
        .format(),
      upper: moment_tz
        .tz(this.endDate.format('YYYY-MM-DD HH:mm'), this.sgTimezone)
        .format(),
    };
    timerData['original_timezone'] = this.sgTimezone;
    this.snoozeTimersService.createTimer(timerData);

    this.clearSelections();
  }

  switchTab(target) {
    this.clearSelections();
    this.activeTab = target;

    if (this.activeTab === 'snooze-history') {
      this.getSnoozeTimerHistory();
    }
  }

  clearSelections() {
    this.addNew = false;
    this.timedEvent = false;
    this.startDate = this.today;
    this.endDate = moment(this.today).add(1, 'hour');
    this.recurrentDays = [];
    this.showSummary = false;
  }

  deleteTimer(deleteTimer: boolean) {
    this.showDeletePopup = false;

    if (!deleteTimer) {
      return;
    }

    this.snoozeTimersService.deleteTimer(this.selectedId);
    this.selectedId = null;
  }

  toggleTimer(timer) {
    const data = {
      active: !timer['active'],
    };
    this.snoozeTimersService.modifyTimer(data, timer['id']);
  }

  formatDate(timer, input) {
    let date: string;

    switch (input) {
      case 'start':
        date = timer.timed_event.lower;
        break;
      case 'end':
        date = timer.timed_event.upper;
        break;
    }
    return moment_tz(date).tz(this.sgTimezone).format('LLL');
  }

  formatTime(timer, input) {
    switch (input) {
      case 'start':
        const strTime = timer.timed_event.lower;
        return moment_tz(strTime).tz(this.sgTimezone).format('HH:mm');
      case 'end':
        const time = moment_tz(timer.timed_event.upper).tz(this.sgTimezone);
        this.reverseCheckEndOfDay(time);
        return time.format('HH:mm');
    }
  }

  formatDays() {
    this.recurrentDays.sort();
    return this.recurrentDays.map((dow) => this.weekDays[dow]).join(', ');
  }

  toggleDay(dow) {
    const index = this.recurrentDays.indexOf(dow);
    if (index > -1) {
      this.recurrentDays.splice(index, 1);
    } else {
      this.recurrentDays.push(dow);
    }
  }

  manualInputTime(event: any, input: string): void {
    const date = moment(event.target.value, 'HH:mm');
    if (date.isValid()) {
      switch (input) {
        case 'start':
          // Verify range is ok
          this.startDate = date;
          break;
        case 'end':
          // Verify range is ok
          this.endDate = date;
          break;
      }
    } else {
      this.timeInvalid[input] = true;
    }
  }

  auditEventCreation(createdAt) {
    return moment_tz(createdAt).tz(this.sgTimezone).format('llll');
  }

  auditEventAction(eventType) {
    let type;
    switch (eventType) {
      case AuditLogCaseType.NEW_TIMED_SNOOZE_EVENT:
        type = $localize`:@@timedSnoozeEventCreated:`;
        break;
      case AuditLogCaseType.NEW_RECURRING_OPENING_HOURS:
        type = $localize`:@@recurringOpeningHoursCreated:`;
        break;
      case AuditLogCaseType.DEACTIVATE_OPENING_HOURS:
        type = $localize`:@@deactivatedOpeningHours:`;
        break;
      case AuditLogCaseType.ACTIVATE_OPENING_HOURS:
        type = $localize`:@@activatedOpeningHours:`;
        break;
      case AuditLogCaseType.DELETE_SNOOZE_EVENT:
        if (event['actor']) {
          type = $localize`:@@deletedSnoozeEvent:`;
        } else {
          type = $localize`:@@pastSnoozeEventCleanUp:`;
        }
        break;
    }
    return type;
  }

  checkEndOfDay(data) {
    const dayEnd = data.clone();
    dayEnd.hour(0);
    dayEnd.minute(0);
    if (data.isSame(dayEnd)) {
      data.endOf('day');
    }
  }

  reverseCheckEndOfDay(data) {
    const dayEnd = data.clone();
    dayEnd.endOf('day');
    if (data.isSame(dayEnd, 'second')) {
      data.hour(0);
      data.minute(0);
    }
  }

  auditEventActor(actor) {
    return actor
      ? `${actor['first_name']} ${actor['last_name']}`
      : $localize`:@@automatic:`;
  }

  auditEventTimer(data: {}): string {
    let auditEventTimeSpan: string = null;
    if (data['recurring_event'] && data['recurring_event'].length > 0) {
      const timeFormat = 'HH:mm';
      const days = `${data['recurring_event']
        .map((dow) => this.weekDays[dow])
        .join(', ')}`;
      const timeSpan = `${moment_tz(data['timed_event']['lower'])
        .tz(this.sgTimezone)
        .format(timeFormat)} - ${moment_tz(data['timed_event']['upper'])
        .tz(this.sgTimezone)
        .format(timeFormat)}`;
      auditEventTimeSpan = `${days} ${timeSpan}`;
    } else {
      const timeFormat = 'DD-MM-YYYY HH:mm';
      auditEventTimeSpan = `${moment_tz(data['timed_event']['lower'])
        .tz(this.sgTimezone)
        .format(timeFormat)} - ${moment_tz(data['timed_event']['upper'])
        .tz(this.sgTimezone)
        .format(timeFormat)}`;
    }
    return auditEventTimeSpan;
  }
}
