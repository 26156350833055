<app-practice-code-selection
  *ngIf="showPracticeCodeSelection"
  [currentIntegrationAction]="currentIntegrationAction"
  (integrationActionEmitter)="handleIntegrationAction($event)"
  (cancelEmitter)="showPracticeCodeSelection = $event">
</app-practice-code-selection>

<app-moving-to-record
  *ngIf="movingToRecord"
  (moveToRecordEmitter)="movingToRecord = !movingToRecord"
  [currentIntegrationAction]="currentIntegrationAction"
  [message]="message">
</app-moving-to-record>

<app-connect-with-prs
  *ngIf="connectingWithPRS"
  (connectWithPRSEmitter)="connectingWithPRS = !connectingWithPRS"
  [message]="message">
</app-connect-with-prs>

<app-notification [icon]="'bell'" *ngIf="activeTimer && activeTimer['active']">
  {{ snoozeNotification }}
</app-notification>

<section
  id="enquiry-list-page"
  class="messages"
  [ngClass]="{
    'snooze-notification-visible': activeTimer && activeTimer['active']
  }">
  <section class="messages-welcome">
    <div class="content">
      <app-welcome></app-welcome>
    </div>
  </section>

  <section class="messages-list">
    <div class="close-mask" (click)="closeFollowup()"></div>
    <div class="content">
      <app-messages-list></app-messages-list>
    </div>
  </section>

  <section class="messages-detail">
    <div class="content">
      <app-messages-detail
        (integrationActionEmitter)="handleIntegrationAction($event)">
      </app-messages-detail>
    </div>
  </section>

  <section class="messages-followup">
    <div class="content">
      <app-messages-followup></app-messages-followup>
    </div>
  </section>
</section>
