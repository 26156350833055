// @ts-nocheck
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Enquiry, PDSSuggestion } from 'app/shared/methods/enquiry/enquiry';
import {
  EnquiryParser,
  ParsedItem,
} from 'app/shared/methods/enquiry/enquiryParser';
import { NotificationType } from 'components/atoms/notification/notification.component';
import { FeatureFlagService } from 'services/feature-flag.service';
import { IntegrationTransactionService } from 'services/integration-transaction.service';
import { StateService, StateKeys } from 'services/state.service';
import { Observable, Subscription } from 'rxjs';
import { FollowupMessage } from '../followup/followup';
import { UtilsService } from 'services/utils.service';
import { SessionService } from 'services/session.service';
import { finalize, map } from 'rxjs/operators';
import {
  AttachmentImage,
  AttachmentService,
} from 'services/attachment.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MESSAGE_STATES, MessageService } from 'services/message.service';
import { formatLocaleShortDate } from 'app/shared/pipes/klinik-short-date.pipe';
import {
  AppointmentTypeFieldName,
  AppointmentTypesService,
} from 'services/appointment-types.service';

@Component({
  selector: 'app-select-case-details',
  templateUrl: './select-case-details.component.html',
  styleUrls: ['./select-case-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCaseDetailsComponent implements OnInit {
  /**
   * A bucket to store all subscriptions.
   * All subscriptions from the bucket will be unsubscribed when the component is destroyed.
   */
  private _subBucket = Array<Subscription>();

  public currentTab = 0;

  //#region static copy
  successHeading = $localize`:@@ehrSaveSuccess:Case data successfully saved to the patient record`;
  failureHeading = $localize`:@@ehrSaveFail:Failed to save case data to the patient record`;
  //#endregion

  //#region DATA
  transactions$: Observable<IntegrationTransaction[]> =
    this.integrationTransactionService.fetch(this.data.enquiry.id); // Async transactions for this message
  createResponse$: Observable<any> | null = null; // Async response from the create API call, will be null if no create call has been made
  followupMessages: Array<FollowupMessage> | undefined = undefined; // Synced follow-ups for this message
  images$: Observable<AttachmentImage[]> | undefined; // Async images for this message
  images: Array<AttachmentImage> | undefined = undefined; // Synced images for this message
  translatedAppointmentType: string | undefined = undefined; // Synced appointment type for this message
  private _enquiryParser: EnquiryParser = new EnquiryParser(
    this.data.enquiry,
    this.utilsService,
    this.sessionService.timeZone
  );
  //#endregion

  //#region SELECTION STATE - TOGGLE SWITCHES
  includePersonalInfo: boolean = false; // default false
  includeCaseSummary: boolean = true; // default true
  includeComments: boolean = this.hasComments; // default true if data
  includeImages: boolean = false; // starts as false, switched default to true when data
  includeSMS: boolean = false; // starts as false, switched default to true when data
  //#endregion

  //#region TOGGLES
  get personalInfoToggleTitle(): string {
    return 'Include personal information in the forwarded patient case';
  }
  get caseSummaryToggleTitle(): string {
    return 'Include case summary in the forwarded patient case';
  }
  get commentsToggleTitle(): string {
    return !this.hasComments
      ? $localize`:@@noComments:No comments to be selected`
      : $localize`:@@commentsAndTransfers:Comments and transfer comments`;
  }
  get imagesToggleTitle(): string {
    return this.isImagesToggleDisabled
      ? $localize`:@@noImages:No images to be selected`
      : $localize`:@@includeImages:Include images in the forwarded patient case`;
  }
  get smsToggleTitle(): string {
    return this.isSmsToggleDisabled
      ? $localize`:@@noSms:No SMS messages to be selected`
      : $localize`:@@includeSms:Include SMS messages in the forwarded patient case`;
  }

  get hasComments(): boolean {
    return this.commentsItems.length > 0;
  }
  get isImagesToggleDisabled(): boolean {
    if (!this.images) return true;
    return this.images?.length === 0;
  }
  get isSmsToggleDisabled(): boolean {
    if (!this.followupMessages) return true;
    return this.followupMessages?.length === 0;
  }
  //#endregion

  constructor(
    public dialogRef: MatDialogRef<SelectCaseDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { enquiry: Enquiry },
    readonly attachmentService: AttachmentService,
    private integrationTransactionService: IntegrationTransactionService,
    private stateService: StateService,
    private featureFlagService: FeatureFlagService,
    private utilsService: UtilsService,
    private sessionService: SessionService,
    private messageService: MessageService,
    private appointmentTypesService: AppointmentTypesService
  ) {}

  ngOnInit(): void {
    this._subBucket.push(
      this.stateService.state$.subscribe((state) => {
        const key = state['key'] as StateKeys;
        let value = state['value'] as object;

        switch (key) {
          case StateKeys.loadedFollowupMessages:
            this.handleReceivedFollowupMessages(
              value as Array<FollowupMessage>
            );
            break;
          default:
            break;
        }
      })
    );

    // Fetch attachments for this message
    this.loadImages(this.data.enquiry.id);
    // Fetch follow-ups for this message
    this.messageService.getFollowupMessages(this.data.enquiry.id);
    // Fetch appointment type translation
    this.loadAppointmentTypeTranslation();
  }

  private handleReceivedFollowupMessages(value: FollowupMessage[]) {
    this.followupMessages = value;
    if (value.length > 0) this.includeSMS = true; // If there are followups, default the toggle to true
  }

  ngOnDestroy(): void {
    this._subBucket.forEach((sub) => sub?.unsubscribe());
  }

  private loadImages(messageId: number): void {
    this.images$ = this.attachmentService.getImages(messageId);
    // We need the images synchronously as well. To be used as the payload for the integration transaction.
    this._subBucket.push(
      this.images$.subscribe((images) => {
        this.images = images;
        // If there are images, default the toggle to true
        if (images?.length > 0) this.includeImages = true;
      })
    );
  }

  get personalInformationItems(): Array<ParsedItem> {
    return [...this._enquiryParser?.personalInfoItems];
  }

  get caseSummaryItems() {
    const items = [];

    if (this._enquiryParser?.symptomDetailItems)
      items.push(...this._enquiryParser.symptomDetailItems);
    if (this._enquiryParser?.symptomItems)
      items.push(...this._enquiryParser.symptomItems);
    if (this._enquiryParser?.severitySymptomsItem !== undefined)
      items.push(this._enquiryParser.severitySymptomsItem);
    if (this._enquiryParser?.notSelectedSeveritySymptomsItem)
      items.push(this._enquiryParser.notSelectedSeveritySymptomsItem);
    if (this._enquiryParser?.previousTreatmentItems)
      items.push(...this._enquiryParser.previousTreatmentItems);
    if (this._enquiryParser?.medicalQuestionItems)
      items.push(...this._enquiryParser.medicalQuestionItems);

    return items;
  }

  get commentsItems(): Array<ParsedItem> {
    return this._enquiryParser?.commentItems;
  }

  get statusNotificationIcon(): Observable<string> {
    return this.integrationTransactionService
      .getLatestCreated(this.data.enquiry.id)
      .pipe(
        map((transaction) => {
          switch (transaction?.status) {
            case IntegrationTransactionStatus.SUCCESS:
              return 'check';
            case IntegrationTransactionStatus.FAILURE:
              return 'times';
            case IntegrationTransactionStatus.PENDING:
              return 'spinner spin';
            default:
              return '';
          }
        })
      );
  }

  get statusnotificationText(): Observable<string> {
    return this.integrationTransactionService
      .getLatestCreated(this.data.enquiry.id)
      .pipe(
        map((transaction) => {
          switch (transaction?.status) {
            case IntegrationTransactionStatus.SUCCESS:
              return $localize`:@@caseAlreadySaved:Case data has already been saved to the patient record. You have the option to save again with additional data if required.`;
            case IntegrationTransactionStatus.FAILURE:
              return $localize`:@@failedEhrSave:Failed to save case data. You have the option to try again.`;
            case IntegrationTransactionStatus.PENDING:
              return $localize`:@@ehrSaveLoading:Case data is being saved. Please wait.`;
            default:
              return '';
          }
        })
      );
  }

  get statusNotificationType(): Observable<string> {
    return this.integrationTransactionService
      .getLatestCreated(this.data.enquiry.id)
      .pipe(
        map((transaction) => {
          switch (transaction?.status) {
            case IntegrationTransactionStatus.SUCCESS:
              return NotificationType.SUCCESS;
            case IntegrationTransactionStatus.FAILURE:
              return NotificationType.ERROR;
            case IntegrationTransactionStatus.PENDING:
              return NotificationType.WARNING;
            default:
              return '';
          }
        })
      );
  }

  get hasSelections(): boolean {
    return (
      this.includePersonalInfo ||
      this.includeCaseSummary ||
      this.includeComments ||
      this.includeSMS ||
      this.includeImages
    );
  }

  get isSendButtonDisabled(): boolean {
    const inHandling = this.data.enquiry.state === MESSAGE_STATES.IN_PROGRESS;
    return !this.hasSelections || this.createResponse$ !== null || !inHandling;
  }

  get sendButtonTitle(): string | undefined {
    const inHandling = this.data.enquiry.state === MESSAGE_STATES.IN_PROGRESS;
    if (!inHandling)
      return $localize`:@@saveDisabledCaseNotInProgress:Save to patient record is disabled until case is in progress`;
    else {
      return '';
    }
  }

  getSelectedSmsMessageIds(): Array<number> {
    if (!this.includeSMS) return [];
    return this.followupMessages?.map((f) => f.id);
  }

  getSelectedImageAttachmentIds(): Array<number> {
    if (!this.includeImages) return [];
    return this.images?.map((a) => a.id);
  }

  saveCaseToEhr(): void {
    const messageId = this._enquiryParser.id;
    const practiceCode = this.data.enquiry?.patient_validation?.practice_code;
    const completeCaseSummary = this.buildCaseSummary();
    const comments = this.includeComments;
    const followupMessageIds = this.getSelectedSmsMessageIds();
    const imageAttachmentIds = this.getSelectedImageAttachmentIds();
    const integrationDestination = this.getIntegrationDestination();

    this.createResponse$ = this.integrationTransactionService
      .create(
        messageId,
        practiceCode,
        completeCaseSummary,
        comments,
        followupMessageIds,
        imageAttachmentIds,
        integrationDestination
      )
      .pipe(
        finalize(() => {
          // Refresh the transactions list
          this.transactions$ = this.integrationTransactionService.fetch(
            messageId,
            true
          );
        })
      );
  }
  private getIntegrationDestination(): IntegrationDestination | null {
    if (this.featureFlagService.isBlackPearTPPEnabled)
      return IntegrationDestination.TPP;
    if (this.featureFlagService.isBlackPearEmisIntegrationEnabled)
      return IntegrationDestination.EMIS;
    return null;
  }

  get patientName(): string {
    return this._enquiryParser?.name;
  }

  get patientDateOfBirth(): string {
    return this._enquiryParser?.dateOfBirth;
  }

  get identitySuggestion(): PDSSuggestion | null {
    return this.data.enquiry.data.data_v2?.pds_suggestions?.[0] ?? null;
  }

  get nhsNumber(): number {
    return this._enquiryParser?.nhs_number;
  }

  get patientSignature(): string {
    const DobLocalised = $localize`:@@dateOfBirth:Date of birth`;
    const NhsNumberLocalised = $localize`:@@nhsNumber:NHS number`;

    let signature = '';
    if (this.patientName) signature += this.patientName;
    if (this.patientDateOfBirth)
      signature += ` | ${DobLocalised}: ${formatLocaleShortDate(
        this.patientDateOfBirth,
        this.utilsService.currentLocale
      )}`;
    if (this.nhsNumber)
      signature += ` | ${NhsNumberLocalised}: ${this.nhsNumber}`;

    return signature;
  }

  get messageId(): number {
    return this.data.enquiry.id;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  /**
   * Builds the case summary from the selected items.
   * @returns A string containing the case summary and the signature. Or null if no items are selected.
   */
  buildCaseSummary(): string | null {
    if (!this.includeCaseSummary && !this.includePersonalInfo) return null;

    let items: ParsedItem[] = [];
    this.includePersonalInfo && items.push(...this.personalInformationItems);
    this.includeCaseSummary && items.push(...this.caseSummaryItems);

    return (
      this.translatedAppointmentType +
      '\n' +
      this.combineInfoItems(items) +
      '\n' +
      this._enquiryParser.caseIdentifierItem.toString() +
      '\n' +
      $localize`:@@copiedFromKlinikProBy:Copied from Klinik Access by [username]`
    );
  }

  /**
   * Combines multiple parsed items into a string.
   */
  combineInfoItems(items: Array<ParsedItem>): string {
    return items.join('\n');
  }

  get hasAnyTransactions(): Observable<boolean> {
    return this.integrationTransactionService.hasAny(this.data.enquiry.id);
  }

  acknowledgeStatus(): void {
    this.createResponse$ = null;
  }

  openLogs(): void {
    this.createResponse$ = null;
    this.currentTab = 1;
  }

  getErrorDescription(error: any): string {
    if (error?.status === 404)
      return $localize`:@@ehrPatientNotfound:There is an issue with the patient's registered practice or NHS number.  We suggest the case is copied manually into the patient record and patient details are verified to be correct in the patient record and NHS patient demographics.`;
    else if (error?.status == 413)
      return $localize`:@@ehrPayloadSizeTooLarge:There is an issue with the message size when saving into patient record. Please retry sending the data in smaller parts (ie. send in images separately).  If issue remains we suggest the case is copied manually into the patient record.`;
    // Handle other errors here...

    // Default error message
    return $localize`:@@defaultEhrErrorDescription:Something went wrong when saving the case data. You can review the details of the request from the logs or try saving again. If the issue persists please copy the case manually.`;
  }

  loadAppointmentTypeTranslation() {
    this._subBucket.push(
      this.appointmentTypesService
        .getTranslatedNameById(
          this.data.enquiry.appointment_type,
          AppointmentTypeFieldName.NAME,
          this.data?.enquiry?.data?.appointment_request
            ?.appointment_duration_type
        )
        .subscribe((result) => {
          const translatedSubType =
            this.appointmentTypesService.getTranslatedSubtype(
              this.data.enquiry
            );
          this.translatedAppointmentType = translatedSubType
            ? `${result} (${translatedSubType})`
            : result;
        })
    );
  }

  getSMSActorName(sms: FollowupMessage): string {
    if (sms?.actor?.full_name) return sms.actor.full_name;
    return $localize`:@@patient:Patient`; // Patient responses don't have an actor
  }
}
