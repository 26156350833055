// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { SessionService } from 'services/session.service';
import { Permissions } from 'permissions/permissions.module';

@Component({
  selector: 'app-sms-templates',
  templateUrl: './sms-templates.component.html',
  styleUrls: ['./sms-templates.component.scss'],
})
export class SmsTemplatesComponent implements OnInit {
  public readonly hasEditPermission = this.sessionService.checkPermissions(
    Permissions.SETTINGS_SMS_TEMPLATES_EDIT
  );

  constructor(private sessionService: SessionService) {}

  ngOnInit() {}
}
