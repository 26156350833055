<div
  class="kip-instruction-card"
  [ngClass]="{
    'kip-instruction-card-done': config.state === 2,
    'kip-instruction-card-locked': config.state === 0
  }">
  <div class="kip-instruction-card-header-container">
    <div
      class="kip-instruction-card-check"
      [ngClass]="{ 'kip-instruction-card-check-done': config.state === 2 }">
      <i class="fa fa-check" *ngIf="config.state === 2"></i>
    </div>
    <h2>
      {{ config.translations.header }}
      <ng-container *ngIf="config.state === 2" i18n="@@kipInstallerCompleted">
        (Completed)</ng-container
      >
    </h2>
  </div>

  <p
    class="kip-instruction-card-text"
    *ngIf="config.state === 0"
    i18n="@@kipInstallerPreviousStep">
    Finish previous step
  </p>

  <ng-container *ngIf="config.state === 1">
    <p class="kip-instruction-card-text">{{ config.translations.text }}</p>

    <div class="errors" *ngIf="responseStatus === 'failed' && touched === true">
      <div class="error" *ngIf="config.action === 'addPhrName'">
        <i class="fa fa-refresh"></i>
        <p i18n="@@somethingWentWrong">Something went wrong, please retry.</p>
      </div>

      <div class="error" *ngIf="config.action === 'testKipConnection'">
        <i class="fa fa-refresh"></i>
        <p i18n="@@kipConnectionError">
          Something went wrong, please retry. Make sure Klinik Integration Proxy
          is running
        </p>
      </div>
    </div>

    <div class="flex-container">
      <form [formGroup]="phrUserForm" *ngIf="config.action === 'addPhrName'">
        <input
          formControlName="emis_username"
          i18n-placeholder="@@kipInstallerPHRUsernamePlaceholder" />
      </form>

      <button
        (click)="handleAction(config.action); touched = true"
        [ngClass]="{
          'input-button': config.action === 'addPhrName',
          disabled: !phrUserForm.valid || responseStatus === 'sending'
        }"
        [disabled]="
          (config.action === 'addPhrName' && !phrUserForm.valid) ||
          responseStatus === 'sending'
        ">
        <p>{{ config.translations.button }}</p>
      </button>
    </div>
  </ng-container>
</div>
