<section class="detail-description" *ngIf="message">
  <img
    id="body-part-image"
    *ngIf="message.data.symptom_information?.location_id != null"
    src="assets/images/body/{{
      message.data.symptom_information?.location_id
    }}.jpg" />

  <div id="header-container">
    <h2 *ngIf="!message.data.short" i18n="@@esitiedotOtsikko">Esitiedot</h2>
    <button (click)="tryCopyingEnquiryDetails()" class="btn small action">
      <span>
        <i class="fa fa-clipboard" aria-hidden="true"></i>
        <ng-container i18n="@@esitiedotKopioi">Kopioi</ng-container>
      </span>
    </button>

    <button
      id="black-pear-integration-trigger"
      *ngIf="showIntegrationTrigger"
      class="btn small action"
      [disabled]="disableIntegrationButton"
      [title]="blackPearIntegrationButtonTitle"
      (click)="startBlackPearIntegration()">
      <i class="fa fa-{{ integrationButtonIcon }}" aria-hidden="true"></i>
      <span i18n="@@saveToPatientRecord">Save to patient record</span>
    </button>
  </div>

  <div id="description-items" class="items">
    <!-- Patient age -->
    <div class="item" *ngIf="patientAge">
      <h5 i18n="@@esitiedotIka">Ikä</h5>
      <p>{{ patientAge }}</p>
    </div>
    <!-- Patient sex -->
    <div class="item" *ngIf="patientSex">
      <h5 i18n="@@esitiedotSukupuoli">Sukupuoli</h5>
      <p>{{ patientSex }}</p>
    </div>
    <!-- Location of symptoms -->
    <div class="item" *ngIf="message.data.symptom_information?.location">
      <h5 i18n="@@esitiedotVaivanSijainti">Vaivan sijainti</h5>
      <!-- Data_v2 -->
      <p *ngIf="message.data.data_v2?.body_parts?.translations">
        {{ utilsService.getNameTranslation(message.data.data_v2?.body_parts) }}
      </p>
      <p *ngIf="!message.data.data_v2?.body_parts?.translations">
        {{ message.data.symptom_information?.location }}
      </p>
    </div>
    <!-- Symptom duration -->
    <div class="item" *ngIf="symptom_duration_present">
      <h5 i18n="@@description_symptomDuration">Oireiden kesto</h5>
      <!-- Data_v2 -->
      <p>{{ symptom_duration_number }} {{ symptom_duration_unit }}</p>
    </div>
    <!-- Selected symptoms -->
    <div
      class="item"
      *ngIf="message.data.symptom_information?.symptoms.length > 0">
      <h5 i18n="@@esitiedotOireet">Oireet</h5>
      <!-- Data_v2 -->
      <ng-container
        *ngIf="
          message.data.data_v2?.symptoms &&
          message.data.data_v2.symptom_descriptions
        ">
        <ng-container *ngFor="let key of objectKeys(organizedSymptoms)">
          <p>
            <strong>{{ organizedSymptoms[key].displayName }}</strong
            ><span *ngIf="message.data.data_v2.symptom_descriptions[key]"
              >:</span
            >
            {{ message.data.data_v2.symptom_descriptions[key] }}
          </p>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!message.data.data_v2?.symptoms">
        <ng-container
          *ngFor="let symptom of message.data.symptom_information?.symptoms">
          <ng-container
            *ngIf="
              symptom.symptom_name === 'Kipu' &&
                message.data.symptom_information?.pain;
              else elseBlock
            ">
            <p>
              <strong
                >{{ symptom.symptom_name }}, VAS
                {{ message.data.symptom_information?.pain }}</strong
              ><span *ngIf="symptom['symptom_description']">:</span>
              {{ symptom.symptom_description }}
            </p>
          </ng-container>
          <ng-template #elseBlock>
            <p>
              <strong>{{ symptom.symptom_name }}</strong
              ><span *ngIf="symptom['symptom_description']">:</span>
              {{ symptom.symptom_description }}
            </p>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
    <!-- Selected severity symptoms -->
    <div class="item" *ngIf="showSeveritySymptomsItem">
      <h5 i18n="@@esitiedotKiireellisyysoireet">Severity symptoms</h5>
      <div
        class="symptom-chips-wrapper"
        *ngIf="translatedSeveritySymptoms.length > 0">
        <mat-chip-list>
          <mat-chip
            disabled
            *ngFor="let symptom of translatedSeveritySymptoms"
            class="symptom-chip"
            >{{ symptom }}</mat-chip
          >
        </mat-chip-list>
      </div>
      <p *ngIf="translatedSeveritySymptoms.length <= 0">
        <strong i18n="@@esitiedotEiKiireellisyysoireita"
          >No severity symptoms</strong
        >
      </p>
    </div>
    <!-- Not selected severity symptoms -->
    <div
      class="item"
      *ngIf="
        showSeveritySymptomsItem &&
        translatedNotselectedSeveritySymptoms.length > 0
      ">
      <h5 i18n="@@notSelectedSeveritySymptoms">No signs of</h5>
      <div class="symptom-chips-wrapper">
        <mat-chip-list class="symptom-chips">
          <mat-chip
            *ngFor="let symptom of translatedNotselectedSeveritySymptoms"
            disabled
            class="symptom-chip not-selected"
            >{{ symptom }}</mat-chip
          >
        </mat-chip-list>
      </div>
    </div>
    <!-- Earlier treatment and medication -->
    <div *ngIf="!message.data.data_v2; else dataV2PreviousTreatment">
      <div
        class="item"
        *ngIf="message.data.symptom_information?.earlier_medication">
        <h5 i18n="@@esitiedotAikaisempiHoito">
          Aikaisemmin käytettyjä lääkkeitä tai hoitokeinoja
        </h5>
        <p>{{ message.data.symptom_information?.earlier_medication }}</p>
      </div>

      <div
        class="item"
        *ngIf="message.data.symptom_information?.earlier_treatment">
        <h5 i18n="@@esitiedotAikaisempiTutkimus">
          Vaivan aikaisempi tutkiminen tai hoito
        </h5>
        <p>{{ message.data.symptom_information?.earlier_treatment }}</p>
      </div>
    </div>
    <ng-template #dataV2PreviousTreatment>
      <div class="item" *ngIf="message.data.data_v2?.earlier_self_treatment">
        <h5 i18n="@@esitiedotAikaisempiHoito">
          Aikaisemmin käytettyjä lääkkeitä tai hoitokeinoja
        </h5>
        <p>
          <strong
            >"{{
              message.data.data_v2.earlier_self_treatment.exact_question
            }}"</strong
          ><br />
          <span
            *ngIf="
              message.data.data_v2.earlier_self_treatment
                .previous_self_treatment_description
            "
            >{{
              message.data.data_v2.earlier_self_treatment
                .previous_self_treatment_description
            }}</span
          >
          <span
            *ngIf="
              !message.data.data_v2.earlier_self_treatment
                .previous_self_treatment_boolean &&
              !message.data.data_v2.earlier_self_treatment
                .previous_self_treatment_description
            "
            i18n="@@messagesDetailDescriptionNo"
            >No</span
          >
        </p>
      </div>

      <div class="item" *ngIf="message.data.data_v2?.earlier_other_treatment">
        <h5 i18n="@@esitiedotAikaisempiTutkimus">
          Vaivan aikaisempi tutkiminen tai hoito
        </h5>

        <p>
          <strong
            >"{{
              message.data.data_v2.earlier_other_treatment.exact_question
            }}"</strong
          ><br />
          <span
            *ngIf="
              message.data.data_v2.earlier_other_treatment
                ?.previous_other_treatment_description
            "
            >{{
              message.data.data_v2.earlier_other_treatment
                .previous_other_treatment_description
            }}</span
          >
          <span
            *ngIf="
              !message.data.data_v2?.earlier_other_treatment
                ?.previous_other_treatment_boolean &&
              !message.data.data_v2?.earlier_other_treatment
                ?.previous_other_treatment_description
            "
            i18n="@@messagesDetailDescriptionNo"
            >No</span
          >
        </p>
      </div>
    </ng-template>
    <!-- Other current issues -->
    <div
      class="item"
      *ngIf="message.data.symptom_information?.other_current_issues">
      <h5 i18n="@@esitiedotMuutVaivat">Muita ajankohtaisia vaivoja</h5>
      <p>{{ message.data.symptom_information?.other_current_issues }}</p>
    </div>
    <!-- Additional questions related to appointment type-->
    <div
      class="item"
      *ngFor="let question of message.data.appointment_type_questions">
      <h5>{{ formatQuestion(question.question) }}</h5>
      <p>{{ question.answer }}</p>
    </div>
    <!-- Additional information provided by the patient -->
    <div class="item" *ngIf="message.data.additional_info">
      <h5 i18n="@@esitiedotLisatiedot">Lisätietoja hoitohenkilökunnalle</h5>
      <p>{{ message.data.additional_info }}</p>
    </div>
    <!-- General medication and general health -->
    <div
      class="item"
      *ngIf="
        message.data.data_v2?.symptoms &&
        message.data.data_v2.general_medication?.general_medication_description
      ">
      <h5 i18n="@@description_general_medication">
        Peruslääkitys ja mahdolliset yleissairaudet
      </h5>
      <p>
        <strong
          >"{{
            message.data.data_v2.general_medication.exact_question
          }}"</strong
        ><br />
        {{
          message.data.data_v2.general_medication.general_medication_description
        }}
      </p>
    </div>
    <!-- Additional questions added by service group -->
    <div class="item" *ngFor="let item of descriptionItems">
      <h5 class="item-title" *ngIf="item['title']">{{ item.title }}</h5>
      <div class="question-item">
        <h5 class="item-question" *ngIf="item['question']">
          {{ item.question }}
        </h5>
        <p class="item-answer long-text" *ngIf="item['answer']">
          {{ item.answer }}
        </p>
      </div>
    </div>
    <ng-container *ngIf="message.data.data_v2?.open_questions">
      <div
        class="item"
        *ngFor="let item of message.data.data_v2.open_questions">
        <h5>{{ getOpenQuestionTranslation(item, 'question') }}</h5>
        <p>{{ item.value }}</p>
      </div>
    </ng-container>
  </div>
</section>
