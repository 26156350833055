// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageCounts } from 'services/message.service';
import {
  messageCountEvent,
  StateService,
  StateKeys,
} from 'services/state.service';
import { LoadingStatus } from 'enums';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  private stateChangeSubscription: Subscription;

  public newMessages = 0;
  public urgentMessages = 0;
  public followupMessages = 0;
  public loadedStats = false;

  constructor(protected stateService: StateService) {}

  requestNavigation(request: string): void {
    this.stateService.setState(StateKeys.requestedNavigation, {
      request: request,
    });
  }

  ngOnInit(): void {
    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.messageCounts:
            const event = state['value'] as messageCountEvent;
            if (event.status !== LoadingStatus.SUCCESS) return;

            const messageCounts: MessageCounts = event?.data;
            this.newMessages = messageCounts.newMessages.count;
            this.urgentMessages = messageCounts.urgentMessages.count;
            this.followupMessages = messageCounts.newFollowups.count;
            this.loadedStats = true;
            break;
          default:
            break;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.stateChangeSubscription.unsubscribe();
  }
}
