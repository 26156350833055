<div class="sidebar-container">
  <div class="sidebar-section">
    <div class="sidebar-section-item">
      <app-datepicker></app-datepicker>
    </div>
    <div class="sidebar-section-item filters">
      <app-dropdown
        [translations]="serviceDropdownTranslations"
        [options]="serviceFilterOptions"
        (selectedOptions)="handleServiceFilterUpdate($event)"></app-dropdown>
      <app-dropdown
        [translations]="contactTypeDropdownTranslations"
        [options]="contactTypeOptions"
        (selectedOptions)="handleContactTypeUpdate($event)"></app-dropdown>
    </div>
  </div>
  <div class="sidebar-section" *ngIf="tabItems['views'].length > 0">
    <div class="sidebar-section-item">
      <div class="tab" *ngFor="let view of tabItems['views']">
        <h3
          class="tab-header"
          [ngClass]="{
            active: view['key'] === activeView,
            unclickable: loading
          }"
          (click)="changeView(view['key'])">
          {{ view['name'] }}
        </h3>
        <div
          class="tab-content"
          [ngClass]="{ active: view['key'] === activeView }"
          *ngIf="onlyTopLevelNavigation.indexOf(view.key) === -1">
          <p
            *ngFor="let chart of view['charts']"
            (click)="changeChartgroup(chart['key'])"
            class="tab-chart"
            [ngClass]="{ active: chart['key'] === activeChartgroup }">
            {{ chart['name'] }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar-section">
    <button class="tab" (click)="toggleFullScreen()">
      <span *ngIf="isFullScreen" i18n="@@exitfullScreen">Exit full screen</span>
      <span *ngIf="!isFullScreen" i18n="@@fullScreen">Full screen</span>
    </button>
  </div>
</div>
