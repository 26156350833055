// @ts-nocheck
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { Enquiry } from 'app/shared/methods/enquiry/enquiry';
import { getNestedValue } from 'app/shared/methods/getNestedValue';
import * as moment from 'moment';
import 'moment/min/locales';
import { SessionService } from 'services/session.service';
import { UtilsService, shortUuid } from 'services/utils.service';
import { AuthenticationInfoItem } from './item-classes/AuthenticationInfoItem';
import { PersonalInfoItem } from './item-classes/PersonalInfoItem';

@Component({
  selector: 'app-messages-detail-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnChanges, OnInit {
  @Input() message: Enquiry;

  public personalInfoItems: Array<PersonalInfoItem> = [];
  public formfillerItems: Array<PersonalInfoItem> = [];
  public array = Array;
  public serviceGroupPracticeCodes: Array<string> = [];

  constructor(
    protected toasterService: ToasterService,
    protected utilsService: UtilsService,
    private sessionService: SessionService
  ) {}

  ngOnInit() {
    let locale = this.utilsService.currentLocale;
    if (locale === 'en') {
      locale = 'en-gb';
    }
    moment.locale(locale);
  }

  identificationNrCopySuccess() {
    this.showCopySuccess(
      $localize`:@@personalIdentityNumberCopiedTitle:`,
      $localize`:@@personalIdentityNumberCopiedMessage:`
    );
  }

  showCopySuccess(title: string, message: string) {
    this.toasterService.pop('success', title, message);
  }

  ngOnChanges() {
    this.updateServiceGroupPracticeCodes();

    if (this.message) {
      this.personalInfoItems = [];
      this.personalInfoItems.push(...this.generatePersonalInfoItems());
      this.personalInfoItems.push(...this.generateDynamicFields());
      this.personalInfoItems.push(...this.generateOtherFields());
      this.formfillerItems = this.generateFormFillerItems();
    }
  }

  updateServiceGroupPracticeCodes(): void {
    // Get service group's practice codes as a list so we can check if the patient's ods matches the service group's ones
    const practiceCodes = this.sessionService.serviceGroup.practice_codes;

    if (practiceCodes) {
      this.serviceGroupPracticeCodes = practiceCodes.map((c: {}) =>
        getNestedValue(c, 'code')
      );
    }
  }

  generatePersonalInfoItems(): Array<PersonalInfoItem> {
    let items = new Array<PersonalInfoItem>();
    const enquiry = new Enquiry(this.message, this.utilsService.currentLocale);

    items.push(
      new PersonalInfoItem(
        'sent-date',
        $localize`:@@viestinAsiakasLahetysaika:`,
        this.formattedSentTime()
      )
    );
    items.push(
      new PersonalInfoItem(
        'id',
        $localize`:@@viestinAsiakasAsiointitunnus:`,
        this.getAppointmentRequestId()
      )
    );
    items.push(
      new PersonalInfoItem(
        'name',
        $localize`:@@viestinAsiakasNimi:`,
        this.getName()
      )
    );
    items.push(new AuthenticationInfoItem(this.message));
    items.push(
      new PersonalInfoItem(
        'acute-id',
        $localize`:@@acuteId:`,
        this.getAcuteId()
      )
    );
    items.push(
      new PersonalInfoItem(
        'phone',
        $localize`:@@viestinAsiakasPuhelinnumero:`,
        this.getPersonalInfoItem('telephone')
      )
    );
    items.push(
      new PersonalInfoItem(
        'ssn',
        $localize`:@@viestinAsiakasHenkilotunnus:`,
        this.getPersonalInfoItem('ssn'),
        true
      )
    );
    items.push(
      new PersonalInfoItem(
        'bsn',
        $localize`:@@patientBSN:`,
        this.getPersonalInfoItem('bsn'),
        true
      )
    );
    items.push(
      new PersonalInfoItem(
        'date-of-birth',
        $localize`:@@dateOfBirth:`,
        this.getPersonalInfoItem('date_of_birth')
      )
    );
    items.push(
      new PersonalInfoItem(
        'email',
        $localize`:@@viestinAsiakasSahkoposti:`,
        this.getPersonalInfoItem('email')
      )
    );
    items.push(
      new PersonalInfoItem(
        'street_address',
        $localize`:@@customer_street_address:`,
        enquiry.streetAddress
      )
    ),
      items.push(
        new PersonalInfoItem(
          'postal_code',
          $localize`:@@customer_postal_code:`,
          enquiry.postalCode
        )
      ),
      items.push(
        new PersonalInfoItem(
          'municipality',
          $localize`:@@viestinAsiakasKotikunta:`,
          this.getPersonalInfoItem('municipality')
        )
      );
    items.push(
      new PersonalInfoItem(
        'contact-methods',
        $localize`:@@Yhteydenottotavat:`,
        this.getPersonalInfoItem('contact_methods')
      )
    );
    items.push(
      new PersonalInfoItem(
        'occupational-health-enabled',
        $localize`:@@occupationalHealthCustomer:`,
        this.getOccupationalHealthEnabled()
      )
    );
    items.push(
      new PersonalInfoItem(
        'employer',
        $localize`:@@viestinAsiakasTyonantaja:`,
        this.getPersonalInfoItem('optional_information_identifiable.employer')
      )
    );
    items.push(
      new PersonalInfoItem(
        'contact_route',
        $localize`:@@contactRoute:`,
        this.getContactRoute()
      )
    );
    items.push(
      new PersonalInfoItem(
        'practice-code',
        $localize`:@@practice_code:`,
        this.getPracticeCode(),
        false,
        !this.message?.patient_validation?.practice_code_match
      )
    );
    items.push(
      new PersonalInfoItem(
        'nhs-number',
        $localize`:@@nhsNumber:`,
        this.getNHSNumber(),
        !this.getNHSNumberBypassed(),
        this.getNHSNumberBypassed()
      )
    );

    return items;
  }

  generateDynamicFields(): Array<PersonalInfoItem> {
    let items = new Array<PersonalInfoItem>();

    const dfields = this.getPersonalInfoItem('dynamic_fields');
    if (dfields) {
      Object.keys(dfields).forEach((key) => {
        if (
          ['translation_keys', 'nhs_number'].indexOf(key) === -1 &&
          this.utilsService.ObjectHasKey(dfields, `${key}.value`)
        ) {
          const value = dfields[key].value;
          const header = this.utilsService.ObjectHasKey(
            dfields,
            `${key}.translations.${this.utilsService.currentLocale}.name`
          )
            ? dfields[key]['translations'][this.utilsService.currentLocale].name
            : key;
          const showCopy = this.utilsService.ObjectHasKey(
            dfields,
            `${key}.show_copy_in_proui`
          )
            ? dfields[key]['show_copy_in_proui']
            : false;

          items.push(new PersonalInfoItem(key, header, value, showCopy));
        }
      });
    }

    return items;
  }

  generateOtherFields(): Array<PersonalInfoItem> {
    let items = new Array<PersonalInfoItem>();

    if (
      this.utilsService.ObjectHasKey(
        this.message,
        'data.data_v2.personal_info.other_fields'
      )
    ) {
      const other_fields =
        this.message['data']['data_v2']['personal_info']['other_fields'];

      Object.keys(other_fields).forEach((key) => {
        const value = other_fields[key]['value'];
        const header = this.utilsService.ObjectHasKey(
          other_fields,
          `${key}.translations.${this.utilsService.currentLocale}.title`
        )
          ? other_fields[key]['translations'][this.utilsService.currentLocale]
              .title
          : other_fields[key]['title'];
        const showCopy = this.utilsService.ObjectHasKey(
          other_fields,
          `${key}.show_copy_in_proui`
        )
          ? other_fields[key]['show_copy_in_proui']
          : false;

        items.push(new PersonalInfoItem(key, header, value, showCopy));
      });
    }

    return items;
  }

  generateFormFillerItems(): Array<PersonalInfoItem> {
    const items: Array<PersonalInfoItem> = [];
    const formFillerPresent = this.utilsService.ObjectHasKey(
      this.message,
      'data.data_v2.personal_info.optional_information_anonymous.form_filler_information_present'
    );

    if (formFillerPresent) {
      const formFillerDetails = this.getPersonalInfoItem(
        'optional_information_identifiable.form_filler_details'
      );
      const formFillerFullname = `${formFillerDetails['form_filler_last_name']} ${formFillerDetails['form_filler_first_name']}`;

      items.push(
        new PersonalInfoItem(
          'caretaker-name',
          $localize`:@@viestinAsiakasTayttajanNimi:`,
          formFillerFullname
        )
      );
      items.push(
        new PersonalInfoItem(
          'caretaker-phone',
          $localize`:@@viestinAsiakasTayttajanPuhelinnumero:`,
          formFillerDetails['form_filler_phone_number']
        )
      );
      items.push(
        new PersonalInfoItem(
          'caretaker-role',
          $localize`:@@caretaker_role:`,
          formFillerDetails['form_filler_role']
        )
      );
    } else if (
      this.utilsService.ObjectHasKey(
        this.message,
        'data.data_v2.personal_info.form_filler'
      )
    ) {
      items.push(
        new PersonalInfoItem(
          'caretaker-name',
          $localize`:@@viestinAsiakasTayttajanNimi:`,
          this.getPersonalInfoItem('form_filler.full_name')
        )
      );
      items.push(
        new PersonalInfoItem(
          'caretaker-phone',
          $localize`:@@viestinAsiakasTayttajanPuhelinnumero:`,
          this.getPersonalInfoItem('form_filler.phone_number')
        )
      );
    }

    return items;
  }

  getPersonalInfoItem(items: string): any {
    const keys = items.split('.');
    let item: any = false;
    let data: object;

    if (this.utilsService.ObjectHasKey(this.message, 'data.data_v2')) {
      data = this.message['data']['data_v2']['personal_info'];
    } else {
      data = this.message['data']['personal_info'];
    }

    if (this.utilsService.ObjectHasKey(data, items)) {
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          item = data[keys[i]];
        } else {
          item = item[keys[i]];
        }
      }
    }

    // If item doesn't have value or is hidden: no need for further actions
    if (item === '******' || item === false) {
      return item;
    }

    if (Array.isArray(item) && item.length > 0) {
      return item.map((arrayItem: any) =>
        this.utilsService.getNameTranslation(arrayItem)
      );
    }

    if (
      typeof item === 'object' &&
      this.utilsService.ObjectHasKey(item, 'translations')
    ) {
      return this.utilsService.getNameTranslation(item);
    }

    if (keys[keys.length - 1] === 'date_of_birth') {
      return moment(item).format('L');
    }

    return item;
  }

  /**
   * @deprecated - The same information should be available in the Enquiry/EnquiryParser.
   * @returns - The formatted sent time.
   */
  formattedSentTime() {
    let formSubmittedAt = getNestedValue(this.message, 'form_submitted_at');
    if (formSubmittedAt === undefined || formSubmittedAt === null) {
      formSubmittedAt = getNestedValue(this.message, 'created_at');
    }
    return moment(formSubmittedAt).format('L LT');
  }

  /**
   * @deprecated - The same information should be available in the Enquiry/EnquiryParser.
   * @returns {string} - The appointment request UUID.
   */
  getAppointmentRequestId(): string {
    if (this.message.data.appointment_request_uuid) {
      return shortUuid(this.message.data.appointment_request_uuid);
    }

    // This is a fallback for old cases that do not have an UUID.
    return this.message.id.toString();
  }

  getContactRoute(): string {
    if (
      this.utilsService.ObjectHasKey(this.message, 'data.data_v2.contact_route')
    ) {
      const contact_route = this.message['data']['data_v2']['contact_route'];

      if (
        !this.utilsService.ObjectHasKey(
          contact_route,
          `name_${this.utilsService.currentLocale}`
        )
      ) {
        return this.message['data']['data_v2']['contact_route']['name'];
      }

      return this.message['data']['data_v2']['contact_route'][
        `name_${this.utilsService.currentLocale}`
      ];
    }

    return null;
  }

  getAcuteId() {
    const samlUserData = this.utilsService.getProperty(
      this.message,
      'data.data_v2.personal_info.optional_information_identifiable.samlUserdata'
    );

    if (samlUserData) {
      return samlUserData['urn:fujitsu:SAML:2.0:attributes:Clientid'] || false;
    }

    return false;
  }

  getName(): string {
    const firstname = this.getPersonalInfoItem('firstname');
    const lastname = this.getPersonalInfoItem('lastname');
    if (firstname && lastname) {
      return `${firstname} ${lastname}`;
    }

    return this.getPersonalInfoItem('full_name');
  }

  getPracticeCode(): string | null {
    const practice_code = this.message.patient_validation?.practice_code;
    if (practice_code) {
      return practice_code;
    }

    return null;
  }

  getOccupationalHealthEnabled(): string {
    const enabled = this.getPersonalInfoItem(
      'optional_information_anonymous.occupational_health_patient'
    );
    if (enabled) {
      return ' '; // Return a space to preserve the legacy behaviour of showing just the header but no value.
    } else {
      return null;
    }
  }

  getNHSNumber() {
    const nhs_number = getNestedValue(this.message, 'nhs_number');
    if (nhs_number) {
      return nhs_number;
    }

    if (this.getNHSNumberBypassed()) {
      return $localize`:@@nhsNumberNotProvided:`;
    }

    return undefined;
  }

  getNHSNumberBypassed(): boolean {
    return this.message['nhs_number_bypass'];
  }
}
