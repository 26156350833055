// @ts-nocheck
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  EXTERNAL_FORWARD_TYPES,
  IntegrationService,
  INTEGRATION_RESPONSE,
} from 'services/integration.service';
import { MessageService } from 'services/message.service';
import { UtilsService } from 'services/utils.service';
import { StateService, StateKeys } from 'services/state.service';
import { Subscription } from 'rxjs';
import { SessionService } from 'services/session.service';
import { Permissions } from 'permissions/permissions.module';
import * as moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { getNestedValue } from 'app/shared/methods/getNestedValue';
import { EnquiryParser } from 'app/shared/methods/enquiry/enquiryParser';
import { FollowupMessage } from '../followup/followup';
import { ToasterService } from 'angular2-toaster';
import { AttachmentImage } from 'services/attachment.service';

class ParsedItem {
  key: any;
  value: string;

  constructor(key, value) {
    this.key = key;
    this.value = value;
  }
}

class ParsedFollowUpItem extends ParsedItem {
  number: string;
  sender: string;
  body: string;
  createdAt: string;

  constructor(key, value, number, sender, body, createdAt) {
    super(key, value);
    this.number = number;
    this.sender = sender;
    this.body = body;
    this.createdAt = createdAt;
  }
}

class ParsedAttachmentItem extends ParsedItem {
  id: number;
  sentToEmis: boolean;

  constructor(key, value, id, sentToEmis) {
    super(key, value);
    this.id = id;
    this.sentToEmis = sentToEmis;
  }
}

class SelectionItem {
  key: String;
  header: string;
  content: string;
  preset: boolean;

  constructor(key, header, content) {
    this.key = key;
    this.header = header;
    this.content = content;
    this.preset = false;
  }
}

@Component({
  selector: 'app-moving-to-record',
  templateUrl: './moving-to-record.component.html',
  styleUrls: ['./moving-to-record.component.scss'],
})
export class MovingToRecordComponent implements OnInit, OnDestroy {
  public enquiry: EnquiryParser;
  @Input() set message(message: {}) {
    // Initiate enquiry
    this.enquiry = new EnquiryParser(
      message,
      this.utilsService,
      this.sessionService.timeZone
    );
  }

  @Input()
  public readonly currentIntegrationAction: string = null;

  @Output()
  private moveToRecordEmitter: EventEmitter<boolean> = new EventEmitter();

  private stateChangeSubscription: Subscription;
  private changeSendingState = (type: string, status: string): void =>
    this.sending.forEach((data) => {
      data.status = data.type === type ? status : data.status;
    });

  private user: object;
  public hasPicturePermission: boolean;
  public attachmentsLoaded: boolean = false;
  public followupMessagesLoaded: boolean = false;

  // null = not asked
  // [] = asked answered no
  // [items] = whaterver answer patient gave
  public followupMessagesParsed: Array<ParsedFollowUpItem> = [];
  public systemRecommendationsParsed: Array<ParsedItem> = [];
  public processedPictures: Array<ParsedAttachmentItem> = [];
  public selections: Set<string> = new Set();
  public visibleSelectionItems: Set<SelectionItem> = new Set();
  public pictures: AttachmentImage[] = [];
  public selectedAttachmentIds: Set<number> = new Set();
  public selectedFollowupMessageIds: Set<number> = new Set();
  public followupMessages: Array<FollowupMessage> = [];
  public sending: Set<{ status: string; type: string }> = new Set();
  public firstOfSending: { status: string; type: string } = null;

  public readonly permissions = Permissions;
  public readonly integrationResponse = INTEGRATION_RESPONSE;
  public readonly integrationAction = EXTERNAL_FORWARD_TYPES;

  public selectionItems: Array<SelectionItem> = [
    new SelectionItem(
      'personal_info',
      $localize`:@@personalInformation:`,
      $localize`:@@movingToRecordPersonalInformationContent:`
    ),
    new SelectionItem(
      'medical_information',
      $localize`:@@movingToRecordMedicalInformationHeader:`,
      $localize`:@@movingToRecordMedicalInformationContent:`
    ),
    new SelectionItem(
      'system_recommendations',
      $localize`:@@movingToRecordSystemRecommendationsHeader:`,
      $localize`:@@movingToRecordSystemRecommendationsContent:`
    ),
    new SelectionItem(
      'followups',
      $localize`:@@smsMessages:`,
      $localize`:@@movingToRecordFollowupContent:`
    ),
    new SelectionItem(
      'comments',
      $localize`:@@comments:`,
      $localize`:@@movingToRecordCommentsContent:`
    ),
    new SelectionItem(
      'attachments',
      $localize`:@@movingToRecordAttachmentsHeader:`,
      $localize`:@@movingToRecordAttachmentsContent:`
    ),
  ];

  private readonly translations = {
    messagesDetailDescriptionNo: $localize`:@@messagesDetailDescriptionNo:`,
    urgencyEmergency: $localize`:@@arvioKiireellisyysPaivystysLabel:`,
    urgencyUrgent: $localize`:@@arvioKiireellisyysKiireellinenLabel:`,
    urgencyNonUrgent: $localize`:@@kiireellisyysarvioKiireellisyysNormaali:`,
    urgencySelfCare: $localize`:@@viestinAnalysointiKiireellisyysItsehoidollinen:`,
    urgency: $localize`:@@viestinAnalysointiKiireellisyys:`,
  };

  constructor(
    private integrationService: IntegrationService,
    private messageService: MessageService,
    private utilsService: UtilsService,
    protected stateService: StateService,
    private toaster: ToasterService,
    protected sessionService: SessionService
  ) {}

  ngOnDestroy(): void {
    this.stateChangeSubscription.unsubscribe();

    // Show feedback modal when case was sent succesfully
    if (this.firstOfSending) {
      const sendingSuccessful =
        this.firstOfSending.status === INTEGRATION_RESPONSE.SUCCESS;
      this.stateService.setState(
        StateKeys.professionalEnquiryAssessmentModalVisible,
        sendingSuccessful
      );
    }
  }

  async ngOnInit() {
    this.user = this.sessionService.getSession();
    this.hasPicturePermission = this.sessionService.checkPermissions(
      this.permissions.MESSAGE_VIEW_ATTACHMENTS
    );

    if (!this.hasPicturePermission) {
      this.selectionItems.pop();
      this.selections.delete('attachments');
    }

    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.gpConnectSendDocumentStatus:
            this.changeSendingState('senddocument', state['value']);
            break;

          case StateKeys.emisFileRecordPostStatus:
            this.changeSendingState(
              $localize`:@@moveToRecordText:`,
              state['value']
            );
            break;

          case StateKeys.emisAttachmentsPostStatus:
            this.changeSendingState(
              $localize`:@@moveToRecordAttachments:`,
              state['value']
            );
            break;

          case StateKeys.attachmentsFetchSuccessful:
            this.pictures = this.utilsService.ObjectHasKey(
              state['value'],
              'images'
            )
              ? state['value'].images
              : null;

            // This is checked to eliminate a bug when the another message's modal, which is closed, is still
            // fetching attachments and after completing the request they would be displayed here.
            // So we check also that the given set of attachments matches to the message currently opened.
            const messageIdInResponse: number = getNestedValue(
              state,
              'value',
              'id'
            );

            if (this.pictures && messageIdInResponse === this.enquiry.id) {
              this.showSelection('attachments');
              this.selections.add('attachments');

              this.processedPictures = this.pictures
                .filter((picture) => picture.file)
                .map((picture) => {
                  if (!picture.sent_to_emis) {
                    this.selectedAttachmentIds.add(picture.id);
                  }
                  const file = this.utilsService.createImgDataURL(picture.file);
                  const parsedAttachmentItem = new ParsedAttachmentItem(
                    null,
                    file,
                    picture.id,
                    picture.sent_to_emis
                  );
                  return parsedAttachmentItem;
                });
            }
            break;
          case StateKeys.loadedFollowupMessages:
            this.followupMessagesLoaded = true;
            this.followupMessages = state['value'] as Array<FollowupMessage>;
            this.selectedFollowupMessageIds.clear();
            const patientName = this.utilsService.getPatientNameFromMessage(
              this.enquiry,
              '-'
            );

            if (this.followupMessages.length > 0) {
              this.showSelection('followups');
              this.selections.add('followups');

              this.followupMessagesParsed = [];
              for (const followup of this.followupMessages) {
                const actorName = followup.actor?.full_name;
                const senderName =
                  followup.direction === 1 ? patientName : actorName;
                const dateParsed = this.parseCreatedAt(followup.created_at);

                this.followupMessagesParsed.push(
                  new ParsedFollowUpItem(
                    null,
                    null,
                    followup.sender_address,
                    senderName,
                    followup.body,
                    dateParsed
                  )
                );

                if (followup.sent_to_emis === false) {
                  this.selectedFollowupMessageIds.add(followup['id']);
                }
              }
            }
            break;
        }
      }
    );

    this.loadAttachments();
    this.loadFollowupMessages();

    this.preselectAvailableSelections();

    this.parseSystemRecommendations();
    this.setPresetSelections();
  }

  /**
   * Check which selections are available.
   * Show toggles in HTML template for each available selection.
   * Pre-select each available selection.
   */
  preselectAvailableSelections(): void {
    if (this.enquiry.personalInfoItems.length !== 0) {
      this.showSelection('personal_info');
      this.selections.add('personal_info');
    }

    if (
      this.enquiry.symptomItems.length !== 0 ||
      this.enquiry.severitySymptomsItem !== undefined ||
      this.enquiry.symptomDetailItems.length !== 0 ||
      this.enquiry.previousTreatmentItems.length !== 0 ||
      this.enquiry.medicalQuestionItems.length !== 0
    ) {
      this.showSelection('medical_information');
      this.selections.add('medical_information');
    }

    if (this.enquiry.commentItems.length !== 0) {
      this.showSelection('comments');
      this.selections.add('comments');
    }
  }

  setPresetSelections() {
    const presetSelections: Array<String> = getNestedValue(
      this.user,
      'service_group',
      'integration_settings',
      'preset_modal_selections'
    );
    if (presetSelections === null || presetSelections === undefined) {
      return;
    }

    presetSelections.forEach((selection) => {
      const i = this.selectionItems.map((x) => x.key).indexOf(selection);
      let selectionItem: SelectionItem = this.selectionItems[i];
      selectionItem.preset = true;
    });
  }

  parseCreatedAt(createdAt, showTime = true): string {
    const timezone: string = this.user['service_group']['time_zone'];
    if (showTime) {
      return moment_tz(moment(createdAt)).tz(timezone).format('LLL');
    } else {
      return moment_tz(moment(createdAt)).tz(timezone).format('L');
    }
  }

  async loadAttachments() {
    const attachments: object = await this.messageService.getAttachments(
      this.enquiry.id
    );
    if (attachments) {
      this.stateService.setState(
        StateKeys.attachmentsFetchSuccessful,
        attachments
      );
    } else {
      this.stateService.setState(StateKeys.attachmentFetchFailed, attachments);
    }
    this.attachmentsLoaded = true;
  }

  async loadFollowupMessages() {
    try {
      await this.messageService.getFollowupMessages(this.enquiry.id);
    } catch (error) {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
      // TODO: Implement visual UI feedback for all errors here instead of the messages service
    }
  }

  showSelection(key) {
    for (let i = 0; i < this.selectionItems.length; i++) {
      if (this.selectionItems[i].key === key) {
        this.visibleSelectionItems.add(this.selectionItems[i]);
        break;
      }
    }
  }

  parseSystemRecommendations() {
    const urgency: number = this.enquiry.priority;
    if (Number.isInteger(urgency)) {
      this.systemRecommendationsParsed.push(
        new ParsedItem(
          this.translations.urgency,
          this.getUrgencyTranslation(urgency)
        )
      );
    }

    if (this.systemRecommendationsParsed.length > 0) {
      this.showSelection('system_recommendations');
      this.selections.add('system_recommendations');
    }
  }

  getUrgencyTranslation(urgency) {
    switch (urgency) {
      case -1:
        return this.translations.urgencySelfCare;
      case 0:
        return this.translations.urgencyNonUrgent;
      case 1:
        return this.translations.urgencyUrgent;
      case 2:
        return this.translations.urgencyEmergency;
      default:
        return '-';
    }
  }

  toggleSelections(selection: string): void {
    if (this.isPresetSelection(selection)) {
      return;
    }

    this.selections.has(selection)
      ? this.selections.delete(selection)
      : this.selections.add(selection);

    if (selection === 'attachments' && this.selectedAttachmentIds.size <= 0) {
      this.pictures.forEach((picture) => {
        if (!picture.sent_to_emis) {
          this.selectedAttachmentIds.add(picture.id);
        }
      });
    }
  }

  toggleAttachmentSelections(selection: number) {
    if (this.isPresetSelection('attachments')) {
      return;
    }

    this.selectedAttachmentIds.has(selection)
      ? this.selectedAttachmentIds.delete(selection)
      : this.selectedAttachmentIds.add(selection);

    if (
      this.selectedAttachmentIds.size <= 0 &&
      this.selections.has('attachments')
    ) {
      this.selections.delete('attachments');
    } else if (
      this.selectedAttachmentIds.size > 0 &&
      !this.selections.has('attachments')
    ) {
      this.selections.add('attachments');
    }
  }

  isPresetSelection(selection: string): boolean {
    let selectionItem: SelectionItem = this.selectionItems.find(
      (x) => x.key === selection
    );
    if (selectionItem == null || selectionItem == undefined) {
      return false;
    }
    return selectionItem.preset;
  }

  moveToRecord(retry = false, data: { type: string } = null) {
    const moveAttachments: boolean = this.selections.has('attachments');
    const moveRecord: boolean =
      this.selections.size > 1 ||
      (this.selections.size === 1 && !moveAttachments);

    if (this.sessionService.isGPConnectIntegrationEnabled) {
      const selectedPracticeCode: {} = this.sessionService.getData(
        'selectedPracticeCode'
      );
      const uuid: string = getNestedValue(
        this.enquiry,
        'data',
        'appointment_request_uuid'
      );
      const purpose: string = this.sessionService.getData('forPurpose');
      this.sending.add({ type: 'senddocument', status: 'sending' });
      this.integrationService.postGPconnectData(
        uuid,
        this.selections,
        this.selectedAttachmentIds,
        selectedPracticeCode,
        purpose
      );
    }

    if (this.sessionService.isEmisIntegrationEnabled) {
      if (
        (moveRecord && !retry) ||
        (retry && data.type === $localize`:@@moveToRecordText:`)
      ) {
        if (retry === false) {
          this.sending.add({
            type: $localize`:@@moveToRecordText:`,
            status: 'sending',
          });
        } else {
          this.changeSendingState(data.type, 'sending');
        }

        this.integrationService.getEMISFileRecordXML(
          this.enquiry.id,
          this.selections
        );
      }

      if (
        (moveAttachments && !retry) ||
        (retry && data.type === $localize`:@@moveToRecordAttachments:`)
      ) {
        if (retry === false) {
          this.sending.add({
            type: $localize`:@@moveToRecordAttachments:`,
            status: 'sending',
          });
        } else {
          this.changeSendingState(data.type, 'sending');
        }

        this.integrationService.getEMISFileAttachmentXML(
          this.enquiry.id,
          this.selectedAttachmentIds
        );
      }
    }

    // Used in HTML template to determine which sending animation to display
    this.firstOfSending = this.sending.values().next().value;
  }

  closeModal(): void {
    // Do not allow closing of modal while request is underway
    if (this.isBeingSent()) {
      return;
    }

    this.moveToRecordEmitter.emit(true);
  }

  textPreviewVisible() {
    if (this.selections.size <= 0) {
      return false;
    }

    if (this.selections.size == 1 && this.selections.has('attachments')) {
      return false;
    }

    return true;
  }

  isBeingSent(): boolean {
    return this.firstOfSending && this.firstOfSending.status === 'sending';
  }
}
