<div class="card" [ngClass]="{ loading: loading }">
  <div class="title" *ngIf="!hideTitle">
    <h1
      [ngClass]="{ clickable: relatedCharts }"
      (click)="toggleRelatedCharts()">
      {{ title }}
      <i
        class="fa fa-angle-down"
        [ngClass]="{ open: open }"
        *ngIf="relatedCharts"></i>
    </h1>

    <div
      id="related-charts"
      (clickOutside)="toggleRelatedCharts()"
      [ngClass]="{ open: open }"
      [clickOutsideEnabled]="open"
      [exclude]="'.clickable'"
      *ngIf="relatedCharts">
      <button
        [ngClass]="{ selected: relatedChart.id === chart.id }"
        (click)="emitNewChartId(relatedChart.id)"
        *ngFor="let relatedChart of chart.related_charts">
        {{ relatedChart.translation }}
      </button>
    </div>

    <a (click)="openLinkedChart()" *ngIf="LinkedChart"
      ><i class="fa fa-list-ul"></i
    ></a>
  </div>

  <div class="content" [ngSwitch]="type">
    <app-card-chart
      [chart]="data"
      *ngSwitchCase="typeChart"
      (newChartIdEmitter)="switchChart($event)"></app-card-chart>
    <app-table-and-chart
      [chart]="data"
      *ngSwitchCase="typeTableAndChart"></app-table-and-chart>
    <app-side-by-side
      [chart]="data"
      *ngSwitchCase="typeSideBySide"></app-side-by-side>
    <app-top-three [chart]="data" *ngSwitchCase="typeTopThree"></app-top-three>
    <app-total [total]="total" *ngSwitchCase="typeTotal"></app-total>
    <app-table [chart]="data" *ngSwitchCase="typeTop"></app-table>
  </div>
</div>
