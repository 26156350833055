// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'services/session.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FeedbackService } from 'services/feedback.service';
import { StateService, StateKeys } from 'services/state.service';
import { Subscription } from 'rxjs';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit, OnDestroy {
  private stateChangeSubscription: Subscription;
  private user: object;
  sensitive: any;
  feedbackForm: FormGroup;
  disableButton = false;
  constructor(
    protected sessionService: SessionService,
    private fb: FormBuilder,
    protected router: Router,
    private feedbackService: FeedbackService,
    private stateService: StateService,
    protected toasterService: ToasterService
  ) {
    this.user = this.sessionService.getSession();

    this.feedbackForm = fb.group({
      full_name: new FormControl(
        {
          value: this.user['first_name'] + ' ' + this.user['last_name'],
          disabled: true,
        },
        Validators.required
      ),
      service_group: new FormControl(
        { value: this.user['service_group']['name'], disabled: true },
        Validators.required
      ),
      subject: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      content: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.maxLength(2000),
      ]),
      type: new FormControl({ value: '1', disabled: false }, [
        Validators.required,
      ]),
    });
  }

  ngOnInit() {
    const self = this;
    this.stateChangeSubscription = this.stateService.state$.subscribe(function (
      state
    ) {
      switch (state['key']) {
        case StateKeys.sendingFeedbackComplete:
          self.toasterService.pop(
            'success',
            $localize`:@@FeedbackSentSuccess:`
          );
          self.backToMessages();
          break;
        case StateKeys.sendingFeedbackFailed:
          self.toasterService.pop('error', $localize`:@@FeedbackSentFailure:`);
          self.disableButton = false;
          break;
      }
    });
  }

  OnSubmit(form: any) {
    if (this.feedbackForm.valid && this.disableButton === false) {
      this.disableButton = true;
      this.feedbackService.sendFeedBack(this.feedbackForm.value);
    }
  }

  backToMessages() {
    this.router.navigate(['messages']);
  }

  ngOnDestroy() {
    this.stateChangeSubscription.unsubscribe();
  }

  currentType() {
    return this.feedbackForm.controls['type'].value;
  }
}
