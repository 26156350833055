// @ts-nocheck
import { Injectable } from '@angular/core';

export type KeyValueStorage = {
  [key: string]: any;
};

/**
 * @class CacheService
 * @description
 * A service for managing a simple key-value cache in an Angular application.
 * This service allows you to store, retrieve, and remove key-value pairs in the cache.
 */
@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private _storage: KeyValueStorage = {};

  constructor() {}

  private _parseString(key: string | number): string {
    return key.toString();
  }
  private _ttlExpired(key: string | number): boolean {
    const ttl = this._storage[key]?.ttl;
    const created = this._storage[key]?.created;
    if (!ttl) return false;
    return Date.now() > created + ttl;
  }

  /**
   * @param {string | number} key - The key under which to store the value.
   * @param {any} value - The value to be stored.
   * @param {number} ttl - The time-to-live (in milliseconds) for the key-value pair.
   * @returns {void}
   * @description
   * Stores a key-value pair in the cache.
   */
  public set(key: string | number, value: any, ttl?: number): void {
    const cache_key = this._parseString(key);

    this._storage[cache_key] = {
      value,
      ttl,
      created: Date.now(),
    };
  }

  /**
   * @param {string | number} key - The key used to retrieve the value from the cache.
   * @returns {any} - The value associated with the given key, or undefined if not found.
   * @description
   * Retrieves a value from the cache based on the provided key.
   */
  public get(key: string | number): any {
    const cache_key = this._parseString(key);
    if (this._ttlExpired(cache_key)) return undefined;
    return this._storage[this._parseString(key)]?.value;
  }

  /**
   * @param {string | number} key - The key for the value to be removed from the cache.
   * @returns {void}
   * @description
   * Removes a key-value pair from the cache based on the provided key.
   */
  public remove(key: string | number): void {
    delete this._storage[this._parseString(key)];
  }
}
