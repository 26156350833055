<h1 i18n="@@featuresSettingsTitle">Features</h1>

<p i18n="@@thisPageIsOnlyVisibleToStaffUsers">
  This page is only visible to staff members
</p>

<app-toggle-switch
  [label]="'Connect enabled'"
  [value]="serviceGroup.pro_form_active"
  (updatedEvent)="updateLocalValue('pro_form_active', $event)">
</app-toggle-switch>

<app-toggle-switch
  [label]="'Dashboard enabled'"
  [value]="serviceGroup.dashboard_enabled"
  (updatedEvent)="updateLocalValue('dashboard_enabled', $event)">
</app-toggle-switch>

<app-toggle-switch
  [label]="'Snooze enabled'"
  [value]="serviceGroup.snooze_enabled"
  (updatedEvent)="updateLocalValue('snooze_enabled', $event)">
</app-toggle-switch>

<app-toggle-switch
  [label]="'Request attachments enabled'"
  [value]="serviceGroup.request_attachments_enabled"
  (updatedEvent)="updateLocalValue('request_attachments_enabled', $event)">
</app-toggle-switch>

<app-toggle-switch
  [label]="'Allow commenting'"
  [value]="serviceGroup.allow_commenting"
  (updatedEvent)="updateLocalValue('allow_commenting', $event)">
</app-toggle-switch>

<app-toggle-switch
  [label]="'Video Consultation'"
  [value]="serviceGroup.video_consultation_enabled"
  (updatedEvent)="updateLocalValue('video_consultation_enabled', $event)">
</app-toggle-switch>

<app-toggle-switch
  [label]="'Feedback loop enabled'"
  [value]="serviceGroup.feedback_loop_enabled"
  (updatedEvent)="updateLocalValue('feedback_loop_enabled', $event)">
</app-toggle-switch>

<app-toggle-switch
  [label]="
    'SMS notification email enabled (Should only be used in the Dutch market)'
  "
  [value]="serviceGroup.sms_notification_email_enabled"
  (updatedEvent)="updateLocalValue('sms_notification_email_enabled', $event)">
</app-toggle-switch>

<app-toggle-switch
  [label]="'Reduced copy content'"
  [value]="serviceGroup.reduced_copy_content_enabled"
  (updatedEvent)="updateLocalValue('reduced_copy_content_enabled', $event)">
</app-toggle-switch>

<app-toggle-switch
  [label]="'Patient identity lookup'"
  [title]="patientIdentityLookupToggleDescription"
  [value]="serviceGroup.patient_identity_lookup_enabled"
  (updatedEvent)="updateLocalValue('patient_identity_lookup_enabled', $event)">
</app-toggle-switch>

<app-toggle-switch
  [label]="'Data exports enabled'"
  [value]="serviceGroup.data_reports_enabled"
  (updatedEvent)="updateLocalValue('data_reports_enabled', $event)">
</app-toggle-switch>

<hr />
<h2>Outcome feature</h2>
<div class="feedback-resources">
  <app-toggle-switch
    [label]="'Feedback Resources'"
    [value]="serviceGroup.feedback_resources_enabled"
    (updatedEvent)="updateLocalValue('feedback_resources_enabled', $event)">
  </app-toggle-switch>
  <i
    class="fa fa-info-circle"
    (mouseover)="displayDescription($event, 'feedback_resources')"
    (mouseout)="showDescription = false"></i>
</div>

<div class="feedback-procedures">
  <app-toggle-switch
    [label]="'Feedback Procedures'"
    [value]="serviceGroup.feedback_procedures_enabled"
    (updatedEvent)="updateLocalValue('feedback_procedures_enabled', $event)">
  </app-toggle-switch>
  <i
    class="fa fa-info-circle"
    (mouseover)="displayDescription($event, 'feedback_procedures')"
    (mouseout)="showDescription = false"></i>
</div>

<div id="description" *ngIf="showDescription">
  <h3></h3>
  <p></p>
</div>

<ng-container *ngIf="showIntegrationFeatures">
  <hr />
  <h2>Integrations</h2>

  <app-toggle-switch
    [label]="'Patient record Integration (GP Connect)'"
    [value]="getIntegrationSetting('gp_connect_enabled')"
    (updatedEvent)="setIntegrationSetting('gp_connect_enabled', $event)">
  </app-toggle-switch>
  <app-toggle-switch
    [label]="'Patient record Integration (Black Pear TPP)'"
    [value]="serviceGroup.black_pear_tpp_enabled"
    (updatedEvent)="updateLocalValue('black_pear_tpp_enabled', $event)">
  </app-toggle-switch>
  <app-toggle-switch
    [label]="'Patient record Integration (Black Pear EMIS)'"
    [value]="serviceGroup.black_pear_emis_enabled"
    (updatedEvent)="updateLocalValue('black_pear_emis_enabled', $event)">
  </app-toggle-switch>

  <!--
      This is disabled because we don't actually want our CS team(s) to enable the EMIS integration.
  -->
  <app-toggle-switch
    [label]="'Patient record Integration (EMIS)'"
    [value]="getIntegrationSetting('emis_enabled')"
    [disabled]="true"
    (updatedEvent)="setIntegrationSetting('emis_enabled', $event)">
  </app-toggle-switch>
</ng-container>

<hr />

<button class="btn btn-submit" (click)="saveFeatures()">Save</button>
