<section class="intro">
  <img
    src="assets/images/followup-sms-view.png"
    alt="Picture of the SMS followup view" />
  <div>
    <h2 class="intro-title" i18n="@@smsTemplatesSettingsTitle">
      SMS templates
    </h2>

    <p i18n="@@smsTemplatesSettingsDescription">
      Add, edit and delete SMS templates.
    </p>
  </div>
</section>

<app-new-template [hasEditPermission]="hasEditPermission"></app-new-template>

<app-edit-templates
  [hasEditPermission]="hasEditPermission"></app-edit-templates>
