<section class="detail-customer" *ngIf="message">
  <div *ngIf="message?.data?.personal_info?.caretaker_info">
    <h4 i18n="@@viestinAsiakasOtsikko">Asiakkaan tiedot</h4>
  </div>

  <div class="items">
    <ng-container *ngFor="let item of personalInfoItems">
      <div class="item {{ 'item-' + item.key }}" *ngIf="item.value">
        <h5>{{ item.header }}</h5>

        <p class="value">
          <!-- show exclamation icon -->
          <ng-container *ngIf="item.show_exclamation">
            <span><i class="fa fa-exclamation"></i></span>
          </ng-container>

          <!-- Show valid icon -->
          <ng-container *ngIf="item.show_valid_icon">
            <span
              ><img
                src="assets/images/icons/custom_certificate.svg"
                alt="Valid"
            /></span>
          </ng-container>

          <!-- Showing of simple (string) value -->
          <ng-container *ngIf="!array.isArray(item.value)">
            <span class="value">{{ item.value }}</span>
          </ng-container>

          <!-- Special handling for items whose value is an array -->
          <ng-container *ngIf="array.isArray(item.value)">
            <p class="value" *ngFor="let value of item.value">
              {{ value }}
            </p>
          </ng-container>

          <!-- Copy link -->
          <span
            class="copy-link"
            ngxClipboard
            [cbContent]="item.value"
            (cbOnSuccess)="identificationNrCopySuccess()"
            *ngIf="item.show_copy">
            <i class="fa fa-clipboard" aria-hidden="true"></i>&nbsp;<span
              i18n="@@esitiedotKopioi"
              >&nbsp;</span
            >
          </span>
        </p>
      </div>
    </ng-container>
  </div>

  <div class="form-filler-information" *ngIf="formfillerItems.length > 0">
    <h4 i18n="@@viestinAsiakasTayttajanTiedot">Lomakkeen täyttäjän tiedot</h4>
    <div class="items">
      <ng-container *ngFor="let item of formfillerItems">
        <div class="item {{ 'item-' + item.key }}" *ngIf="item.value">
          <h5>{{ item.header }}</h5>
          <p class="value">
            <span class="value">{{ item.value }}</span>
          </p>
        </div>
      </ng-container>
    </div>
  </div>
</section>
