<section class="flex-container-wrapper">
  <section class="auth-wrapper flex-container">
    <section class="auth flex-item">
      <section class="auth-content">
        <router-outlet></router-outlet>
        <app-language-dropdown></app-language-dropdown>
      </section>
    </section>
  </section>
</section>
