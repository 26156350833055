<div class="welcome-body" *ngIf="loadedStats">
  <div class="stats">
    <section class="welcome-new-messages" [class.all-clear]="newMessages === 0">
      <h2 i18n="@@tervetuloaYhteydenototOtsikko">
        Käsittelemättömät yhteydenotot
      </h2>

      <div class="stat new-messages">
        <div class="number">
          <span *ngIf="newMessages === 0" class="empty">
            <i class="fa fa-check"></i>
          </span>
          <span *ngIf="newMessages > 0">{{ newMessages }}</span>
        </div>

        <div class="label">
          <div *ngIf="newMessages === 0">
            <span i18n="@@tervetuloaYhteydenototEiYhtään">
              Ei käsittelemättömiä yhteydenottoja
            </span>
          </div>

          <div *ngIf="newMessages === 1">
            <div class="actions">
              <button
                class="btn"
                (click)="requestNavigation('latest-new-message')"
                i18n="@@tervetuloaYhteydenototAvaaYksi">
                Avaa käsittelemätön yhteydenotto
              </button>
            </div>
          </div>

          <div *ngIf="newMessages > 1">
            <div class="actions">
              <button
                class="btn"
                (click)="requestNavigation('new-messages')"
                i18n="@@tervetuloaYhteydenototAvaa">
                Näytä vain käsittelemättömät yhteydenotot
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="welcome-urgent-messages"
      [class.all-clear]="urgentMessages === 0">
      <h2 i18n="@@tervetuloaKiireellisetOtsikko">Kiireelliset yhteydenotot</h2>

      <div class="stat urgent-messages">
        <div class="number">
          <span *ngIf="urgentMessages === 0" class="empty">
            <i class="fa fa-check"></i>
          </span>

          <span *ngIf="urgentMessages > 0">
            {{ urgentMessages }}
          </span>
        </div>

        <div class="label">
          <div *ngIf="urgentMessages === 0">
            <span i18n="@@tervetuloaKiireellisetEiYhtaan">
              Ei kiireellisiä yhteydenottoja
            </span>
          </div>

          <div *ngIf="urgentMessages === 1">
            <div class="actions">
              <button
                class="btn"
                (click)="requestNavigation('latest-priority-message')"
                i18n="@@tervetuloaKiireellisetAvaaYksi">
                Avaa kiireellinen yhteydenotto
              </button>
            </div>
          </div>

          <div *ngIf="urgentMessages > 1">
            <div class="actions">
              <button
                class="btn"
                (click)="requestNavigation('priority-messages')"
                i18n="@@tervetuloaKiireellisetAvaa">
                Näytä vain kiireelliset yhteydenotot
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="welcome-followup-messages"
      [class.all-clear]="followupMessages === 0">
      <h2 i18n="@@tervetuloaKasittelemattomatOtsikko">
        Käsittelemättömät viestit
      </h2>

      <div class="stat followup-messages">
        <div class="number">
          <span *ngIf="followupMessages === 0" class="empty">
            <i class="fa fa-check"></i>
          </span>

          <span *ngIf="followupMessages > 0">
            {{ followupMessages }}
          </span>
        </div>

        <div class="label">
          <div *ngIf="followupMessages === 0">
            <span i18n="@@tervetuloaKasittelemattomatEiYhtaan">
              Ei käsittelemättömiä viestejä
            </span>
          </div>

          <div *ngIf="followupMessages === 1">
            <div class="actions">
              <button
                class="btn"
                (click)="requestNavigation('latest-followupmessage')"
                i18n="@@tervetuloaKasittelemattomatAvaaYksi">
                Avaa käsittelemätön viesti
              </button>
            </div>
          </div>

          <div *ngIf="followupMessages > 1">
            <div class="actions">
              <button
                class="btn"
                (click)="requestNavigation('followupmessages')"
                i18n="@@tervetuloaKasittelemattomatAvaa">
                Näytä uudet viestit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="welcome-footer">
    <button
      class="btn"
      (click)="requestNavigation('all-messages')"
      i18n="@@tervetuloaYhteydenototNaytaKaikki">
      Näytä kaikki yhteydenotot
    </button>
  </div>
</div>
