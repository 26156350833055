import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import {
  AuthenticationData,
  AuthenticationDataService,
} from 'services/authentication-data.service';
import { SessionService } from 'services/session.service';

@Component({
  selector: 'app-authentication-data',
  templateUrl: './authentication-data.component.html',
  styleUrls: ['./authentication-data.component.scss'],
})
export class AuthenticationDataComponent implements OnInit {
  public downloadingAuthenticationData: boolean = false;
  public authenticationData: any = null;
  public authenticationDataSummaryRow: AuthenticationData | undefined;

  public startDate: string | undefined;
  public endDate: string | undefined;

  /** Whether to include Klinik Access (ie. patient form) cases. */
  public includeAccessCases: boolean = true;

  /** Whether to include Klinik Connect (ie. pro form) cases. */
  public includeConnectCases: boolean = true;

  constructor(
    protected sessionservice: SessionService,
    protected authenticationDataService: AuthenticationDataService,
    protected toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    // Initialize the date range to the last 7 days.
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - 7);
    this.startDate = startDate.toISOString().split('T')[0];
    this.endDate = today.toISOString().split('T')[0];

    this.downloadAuthenticationData();
  }

  get serviceGroupName(): string | undefined {
    return this.sessionservice.serviceGroup?.name;
  }

  /**
   * Returns true if the selections are valid.
   */
  get validSelections(): boolean {
    return this.includeAccessCases || this.includeConnectCases;
  }

  async downloadAuthenticationData() {
    try {
      this.downloadingAuthenticationData = true;

      const serviceGroupId: number | undefined =
        this.sessionservice.serviceGroup?.id;
      if (!serviceGroupId) {
        return;
      }

      this.authenticationData =
        await this.authenticationDataService.getAuthenticationData(
          serviceGroupId,
          this.startDate,
          this.endDate,
          this.includeAccessCases,
          this.includeConnectCases
        );

      this.authenticationDataSummaryRow = this.calculateSummaryRow(
        this.authenticationData
      );
    } catch (error) {
      this.toasterService.pop(
        'error',
        'Failed to download authentication data'
      );
      this.authenticationData = [];
    } finally {
      this.downloadingAuthenticationData = false;
    }
  }

  /**
   * A pure function that calculates the summary row for the authentication data.
   * @param authenticationData - All the authentication data.
   * @returns - The summary row.
   */
  private calculateSummaryRow(
    authenticationData: Array<AuthenticationData>
  ): AuthenticationData {
    const summaryRow = {
      date: 'Total',
      total_patient_cases: 0,
      nhs_login_success: 0,
      pds_success: 0,
      pds_failure: 0,
      nhs_number_bypassed: 0,
      identity_suggested: 0,
      identity_suggestion_confirmed: 0,
    };

    for (const row of authenticationData) {
      summaryRow.total_patient_cases += row.total_patient_cases;
      summaryRow.nhs_login_success += row.nhs_login_success;
      summaryRow.pds_success += row.pds_success;
      summaryRow.pds_failure += row.pds_failure;
      summaryRow.nhs_number_bypassed += row.nhs_number_bypassed;
      summaryRow.identity_suggested += row.identity_suggested;
      summaryRow.identity_suggestion_confirmed +=
        row.identity_suggestion_confirmed;
    }

    return summaryRow;
  }
}
