// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterConfig } from 'angular2-toaster';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { MessageService } from 'services/message.service';
import { SessionService } from 'services/session.service';
import {
  StateService,
  StateKeys,
  singleMessageEvent,
} from 'services/state.service';
import { UserService } from 'services/user.service';
import { Configuration } from './app.constants';
import { minutesToMS } from './shared/methods/mintuesToMS';
import { Meta } from '@angular/platform-browser';
import { LoadingStatus } from 'enums';
import * as Raven from 'raven-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private stateChangeSubscription: Subscription;
  private sessionValidityInterval: NodeJS.Timer;

  public envLabel = null;
  public followupsEnabled = false;
  public userManagementEnabled = false;
  public openMessage = false;
  public openWelcome = true;
  public openFollowup = false;
  public newReleaseNotes = false;
  public toasterconfig: ToasterConfig = new ToasterConfig({
    showCloseButton: false,
    tapToDismiss: true,
    timeout: 4000,
    limit: 5, // Maximum number of toasts. If the limit is reached, oldest toasts will start to be removed.
    animation: 'fade',
  });

  constructor(
    protected userService: UserService,
    protected stateService: StateService,
    protected sessionService: SessionService,
    protected router: Router,
    protected messageService: MessageService,
    protected configuration: Configuration,
    private meta: Meta
  ) {
    this.envLabel = environment.label;
    this.userManagementEnabled = environment.features.userManagement;
    this.meta.addTags([{ name: 'robots', content: 'noindex, nofollow' }]);
  }

  ngOnInit() {
    const session = this.sessionService.getSession();

    if (session) {
      this.messageService.getServiceGroup();
      this.messageService.getUserServices();
    }

    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.singleMessage:
            const event = state['value'] as singleMessageEvent;
            if (event.status !== LoadingStatus.SUCCESS) return;
            this.openMessage = true;
            break;
          case StateKeys.loadedServiceGroup:
            this.followupsEnabled = state['value']['sms_enabled'];
            break;
          case StateKeys.closedMessage:
            this.openFollowup = false;
            this.openMessage = false;
            break;
          case StateKeys.selectedService:
            this.openMessage = false;
            break;
          case StateKeys.toggledFollowup:
            this.openFollowup = state['value'];
            break;

          case StateKeys.refreshUserData:
            const stateValue = state['value'];
            this.messageService.getServiceGroup();
            this.messageService.getUserServices();

            // grab all the data we need to initialize succesfully
            const serviceNames = [];

            // cache service_group to session
            this.sessionService.setServiceGroup(stateValue.service_group);

            this.stateService.setState(
              StateKeys.loadedServiceGroup,
              stateValue.service_group
            );
            for (const service of stateValue.services) {
              serviceNames[service.id] = service.name;
            }

            // cache services to session
            this.sessionService.setData('serviceNames', serviceNames);
            this.sessionService.setData('userServices', stateValue.services);
            this.stateService.setState(
              StateKeys.loadedUserServices,
              stateValue.services
            );
            break;

          case StateKeys.loggedIn:
            this.router.navigate(['/landing/']);
            this.setIsSessionValidInterval();
            break;

          case StateKeys.deleteSession:
            clearInterval(this.sessionValidityInterval);
            break;

          case StateKeys.requestedNavigation:
            switch (state['value']['request']) {
              case 'welcome':
                this.openWelcome = true;
                this.stateService.setState(StateKeys.closedMessage, null);
                break;
              default:
                this.openWelcome = false;
                break;
            }
            break;
        }
      }
    );
  }

  /**
        
   * Start interval that checks if session is still valid.
   * Logs user out if session has expired.
   */
  private setIsSessionValidInterval(): void {
    // How often session validity is checked in minutes
    const interval = minutesToMS(30);

    this.sessionValidityInterval = setInterval(async () => {
      await this.sessionService.isSessionValid().catch((err) => {
        // If user does not have a session, SessionInterceptor will logout the user
      });
    }, interval);
  }

  ngOnDestroy() {
    if (this.sessionValidityInterval) {
      clearInterval(this.sessionValidityInterval);
    }

    if (this.stateChangeSubscription) {
      this.stateChangeSubscription.unsubscribe();
    }
  }
}
