// @ts-nocheck
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {
  @Input() public label: string;
  // Autocomplete attribute tells browser/password managers how to fill an input with relevant information
  // I.e. 'current-password' will offer existing passwords while 'password' will offer to generate a new password. etc.
  @Input() public autocomplete: string = '';
  // HTML input type. See more from: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types
  @Input() public type: string = 'text';
  // Elements should have unique IDs to help our E2E tests etc.
  @Input() public inputId: string;

  private isRequiredField: boolean = false;

  public get isRequired() {
    return this.isRequiredField;
  }

  @Input()
  public set isRequired(required: boolean) {
    if (required) {
      this.internalFormControl.setValidators([Validators.required]);
    } else {
      this.internalFormControl.clearValidators();
    }
    this.isRequiredField = required;
  }

  @Input()
  public set isDisabled(disabled: boolean) {
    if (disabled) {
      this.internalFormControl.disable();
    } else {
      this.internalFormControl.enable();
    }
  }

  @Input()
  public set isTouched(touched: boolean) {
    touched
      ? this.internalFormControl.markAsTouched()
      : this.internalFormControl.markAsUntouched();
  }

  @Input()
  public errorText: string;

  @Input() public set value(value: string) {
    this.internalFormControl.setValue(value);
  }

  @Output()
  public readonly updatedEvent = new EventEmitter<string>();

  public internalFormGroup = new FormGroup({});

  public internalFormControl = new FormControl('', { updateOn: 'blur' });

  constructor() {
    this.internalFormGroup.addControl(
      'internalFormControl',
      this.internalFormControl
    );
  }

  ngOnInit() {
    this.internalFormControl.valueChanges.subscribe(() =>
      this.updatedEvent.emit(this.internalFormControl.value)
    );
  }

  get showErrors(): boolean {
    return (
      this.internalFormControl.errors !== null &&
      this.internalFormControl.touched
    );
  }
}
