<div id="landing-page-landing-container" class="landing-container">
  <nav>
    <h1>
      <span i18n="@@tervetuloaOtsikko">Loistavaa päivää</span>&nbsp;<span
        class="name"
        *ngIf="session"
        >{{ session.first_name }}</span
      >
    </h1>
    <div class="navigation-group">
      <button
        id="landing-nav-button-enquiry"
        class="nav-button"
        [disabled]="!userPermissions.has(permissions.MESSAGE_VIEW)"
        routerLink="/messages">
        <div class="icon">
          <i class="fa fa-envelope"></i>
        </div>
        <span i18n="@@navigationMessages">Contact requests</span>
      </button>
      <button
        id="landing-nav-button-connect"
        class="nav-button"
        [disabled]="!userPermissions.has(permissions.PRO_FORM_VIEW)"
        (click)="openProForm()"
        *ngIf="sessionService.isConnectEnabled">
        <div class="icon">
          <i class="fa fa-wpforms"></i>
        </div>
        <span i18n="@@proForm">Connect</span>
      </button>
      <button
        id="landing-nav-button-dashboard"
        class="nav-button"
        routerLink="/dashboard"
        [disabled]="!userPermissions.has(permissions.DASHBOARD_VIEW)"
        *ngIf="sessionService.isDashboardEnabled">
        <div class="icon">
          <i class="fa fa-bar-chart"></i>
        </div>
        <span i18n="@@dashboardMenuTitle">Dashboard</span>
      </button>
      <button
        id="landing-nav-button-users"
        class="nav-button"
        [disabled]="!userPermissions.has(permissions.USERS_VIEW)"
        routerLink="/users">
        <div class="icon">
          <i class="fa fa-users"></i>
        </div>
        <span i18n="@@navigationUserManagement">User administration</span>
      </button>
      <a
        id="landing-nav-button-instrcutions"
        class="nav-button"
        href="{{ instructionsUrl }}"
        target="_blank"
        rel="noopener">
        <div class="icon">
          <i class="fa fa-question"></i>
        </div>
        <span i18n="@@header_gdpr_instructions">Instrcutions</span>
      </a>
    </div>
    <div class="info-text" *ngIf="enableInfotext">
      <span>
        Access to this computer/Solution and any information it contains is
        limited to authorised users only. Legal action can be taken against
        unauthorised use of, or unauthorised access to, this computer/Solution
        and/or any information it contains, including pursuant to the Computer
        Misuse Act 1990. If you are an authorised user, by proceeding to access
        and use this computer/Solution and/or the information it contains, you
        are accepting any terms of use, notices and policies which are contained
        or referenced within it or which have otherwise been drawn to your
        attention as an authorised user.
      </span>
    </div>
  </nav>

  <div class="picture"></div>

  <img
    id="logo"
    src="https://storage.googleapis.com/klinik-shared-media/Klinik%20Healthcare%20Solutions.png"
    alt="Klinik Healthcare Solutions" />
</div>

<user-satisfaction
  [ngClass]="{ 'survey-closed': !activatedSurvey }"
  (surveyEmitter)="toggleSurvey()"></user-satisfaction>

<app-kip-integration
  *ngIf="kipOnboarding"
  (popupVisible)="toggleKIPOnboarding($event)"></app-kip-integration>
