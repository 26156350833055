// @ts-nocheck
import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Configuration } from 'app/app.constants';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { Subscription } from 'rxjs';
import {
  MessageService,
  MESSAGE_REQUEST_TYPES,
} from 'services/message.service';
import { SessionService } from 'services/session.service';
import {
  StateService,
  StateKeys,
  singleMessageEvent,
} from 'services/state.service';
import { UtilsService } from 'services/utils.service';
import { SMSTemplatesService } from 'app/smstemplates.service';
import { ToasterService } from 'angular2-toaster';
import { DELIVERY_STATUS, FollowupMessage } from './followup';
import { Enquiry } from 'app/shared/methods/enquiry/enquiry';
import { LoadingStatus } from 'enums';

@Component({
  selector: 'app-messages-followup',
  templateUrl: './followup.component.html',
  styleUrls: ['./followup.component.scss'],
})
export class FollowupComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('scrolling')
  private followupScrolling: ElementRef;

  private stateChangeSubscription: Subscription;
  private isSendingFollowupMessage = false;
  private scrolled = false;
  private intervalId: any;
  private errorCheckDate: Date = new Date();
  private REFRESH_RATE_MS = 10000;

  public followupOpen = false;
  public newFollowupMessageContent: string = null;
  public followupMessages: Array<FollowupMessage> = [];
  public hasAttachmentRequestPermission = false;

  public readonly requestTypes = MESSAGE_REQUEST_TYPES;
  public requestType: MESSAGE_REQUEST_TYPES = null;
  public imageUploadLabel: string = $localize`:@@requestAttachments:`;
  public videoConsultationLabel: string = $localize`:@@requestVideoConsultation:`;

  public date_formats: any;
  public newFollowupMessageCount = 0;
  public session: object = null;
  public message: Enquiry = null;
  public userPermissions: Set<string> = new Set();
  public readonly permissions = Permissions;

  public isSMSEnabled: boolean = this.sessionService.isSMSEnabled;
  public isRequestAttachmentsEnabled: boolean =
    this.sessionService.isRequestAttachmentsEnabled;
  public isVideoConsultationEnabled: boolean =
    this.sessionService.isVideoConsultationEnabled;

  public templates: Array<SMSTemplate> = [];

  public get showFollowupSection(): boolean {
    return (
      this.isSMSEnabled && this.message && !this.sessionService.isRestricted
    );
  }
  public get isMessageLoading() {
    return this.stateService.isLoading(StateKeys.singleMessage);
  }

  constructor(
    protected messageService: MessageService,
    protected sessionService: SessionService,
    protected stateService: StateService,
    protected configuration: Configuration,
    private utilsService: UtilsService,
    private smstemplatesService: SMSTemplatesService,
    private toasterService: ToasterService
  ) {
    this.date_formats = this.utilsService.getDateFormats();
  }

  async ngOnInit() {
    try {
      this.templates = await this.smstemplatesService.getSMSTemplates();
    } catch (error) {
      // Don't do anything. Probably a bunch of other things failed as well,
      // and toasters are already being thrown left-and-right.
    }

    this.userPermissions = this.utilsService.filterUserPemsissions([
      this.permissions.MESSAGE_VIEW_SMS,
    ]);

    if (
      this.sessionService.checkPermissions(
        this.permissions.MESSAGE_REQUEST_ATTACHMENTS
      )
    ) {
      this.hasAttachmentRequestPermission = true;
    }

    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.loadedServiceGroup:
            this.isSMSEnabled = this.sessionService.isSMSEnabled;
            this.isRequestAttachmentsEnabled =
              this.sessionService.isRequestAttachmentsEnabled;
            this.isVideoConsultationEnabled =
              this.sessionService.isVideoConsultationEnabled;
            break;

          case StateKeys.singleMessage:
            const event = state['value'] as singleMessageEvent;
            if (event.status !== LoadingStatus.SUCCESS) return;
            this.message = event.data;
            this.updateFollowupMessages();
            break;
          case StateKeys.toggledFollowup:
            this.followupOpen = state['value'];
            this.toggleInterval();
            this.scrolled = false;
            this.scrollToBottom();
            break;
          case StateKeys.selectedMessage:
            this.resetSMSMessageAndRequestSelections();
            break;
          case StateKeys.closedMessage:
            this.followupMessages = [];
            this.message = null;
            this.followupOpen = false;
            break;
          case StateKeys.loadedFollowupMessages:
            this.followupMessages = state['value'];
            this.updateCount();
            break;
          case StateKeys.updatedFollowupMessage:
            this.updateCount();
            break;
          case StateKeys.receivedNewFollowups:
            this.updateFollowupMessages();
            this.updateCount();
            break;
        }
      }
    );
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  ngOnDestroy() {
    console.debug('Clearing interval to update followup messages');
    clearInterval(this.intervalId);

    if (this.stateChangeSubscription) {
      this.stateChangeSubscription.unsubscribe();
    }
  }

  toggleOpener() {
    this.followupOpen = !this.followupOpen;
    this.stateService.setState(StateKeys.toggledFollowup, this.followupOpen);
  }

  /**
   * Toggle followup message polling interval based on whether the followup
   * section is open or not.
   */
  toggleInterval() {
    if (this.followupOpen) {
      this.updateFollowupMessages(); // Update followup messages immediately, the interval will kick in after
      // Set a polling interval to update the followup messages
      console.debug('Setting interval to update followup messages');
      this.intervalId = setInterval(
        () => this.updateFollowupMessages(),
        this.REFRESH_RATE_MS
      );
    } else {
      // Stop polling
      console.debug('Clearing interval to update followup messages');
      clearInterval(this.intervalId);
    }
  }

  selectSMSTemplate(id: number) {
    if (this.templates.length) {
      for (let i = 0; i < this.templates.length; i++) {
        if (this.templates[i].id === id) {
          this.newFollowupMessageContent = this.templates[i]['body'];
        }
      }
    }
  }

  numberCanReceiveFollowups() {
    if (!this.message.contact_phone_number) {
      return false;
    }

    return true;
  }

  dateCheck(fm_date) {
    const date = new Date(fm_date);
    return date < this.errorCheckDate;
  }

  updateFollowupMessages() {
    if (!this.message || this.isSMSEnabled === false) {
      return;
    }

    this.messageService.getFollowupMessages(this.message.id);
    this.errorCheckDate = new Date();
    this.errorCheckDate.setHours(this.errorCheckDate.getHours() - 1);
  }

  updateCount() {
    this.newFollowupMessageCount = this.followupMessages.filter(function (
      followupMessage
    ) {
      return followupMessage.delivery_status === DELIVERY_STATUS.NEW;
    }).length;
  }

  setFollowupMessageStatus(followupMessage: FollowupMessage, status: number) {
    followupMessage.delivery_status = status;
    this.messageService.updateFollowupMessage(followupMessage.id, status);
  }

  async sendMessage() {
    if (this.isSendingFollowupMessage) {
      return;
    }

    try {
      // Try sending SMS message
      this.isSendingFollowupMessage = true;
      await this.messageService.sendFollowupMessage(
        this.message.id,
        this.newFollowupMessageContent,
        this.requestType
      );

      // Reset written message and request selections only if sending SMS message was successful
      this.resetSMSMessageAndRequestSelections();
    } catch (error) {
      this.toasterService.pop('error', $localize`:@@temporaryErrorSendingSms:`);
    } finally {
      // Succeed or not, set isSending to False and update followup messages
      this.isSendingFollowupMessage = false;
      this.updateFollowupMessages();
    }
  }

  get isSendMessageButtonDisabled() {
    const hasContent = this.newFollowupMessageContent
      ? this.newFollowupMessageContent.length > 0
      : false;
    return !hasContent || this.isSendingFollowupMessage;
  }

  scrollToBottom() {
    if (typeof this.followupScrolling !== 'undefined' && !this.scrolled) {
      this.followupScrolling.nativeElement.scrollTop =
        this.followupScrolling.nativeElement.scrollHeight;
      this.scrolled = true; // this is needed to prevent unnecessary scrolling
    }
  }

  resetSMSMessageAndRequestSelections() {
    // Reset SMS message content
    this.newFollowupMessageContent = null;

    // Reset request selections
    this.requestType = null;
  }

  toggleRequestSelection(selected: boolean, type: MESSAGE_REQUEST_TYPES) {
    if (!selected) {
      this.requestType = null;
      return;
    }

    this.requestType = type;
  }

  get hasSmsSendPermission(): Boolean {
    return this.sessionService.checkPermissions(
      this.permissions.MESSAGE_SEND_SMS
    );
  }
}
