// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { Subscription } from 'rxjs';
import { MessageService } from 'services/message.service';
import { SnoozeTimerService } from 'services/snooze-timer.service';
import {
  StateService,
  StateKeys,
  singleMessageEvent,
} from 'services/state.service';
import * as moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { SessionService } from 'services/session.service';
import {
  EXTERNAL_FORWARD_TYPES,
  INTEGRATION_RESPONSE,
} from 'services/integration.service';
import { EnquiryParser } from 'app/shared/methods/enquiry/enquiryParser';
import { UtilsService } from 'services/utils.service';
import { Enquiry } from 'app/shared/methods/enquiry/enquiry';
import { LoadingStatus } from 'enums';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit, OnDestroy {
  private stateChangeSubscription: Subscription;
  private refreshActiveTimer;
  private snoozeTimerDisabled = $localize`:@@snoozeDiscalimerDisabled:`;
  private snoozeDisclaimerIfError = $localize`:@@snoozeDisclaimerIfError:`;

  public message: object = null;
  public user = this.sessionService.getSession();
  public activeTimer: any;
  public serviceId: number = null;
  public openMessage = false;
  public movingToRecord = false;
  public showPracticeCodeSelection = false;
  public connectingWithPRS = false;
  public currentIntegrationAction: EXTERNAL_FORWARD_TYPES = null;
  public snoozeNotification: string = null;
  public enquiryParser: EnquiryParser;
  public enquiry: Enquiry;

  public readonly permissions = Permissions;
  public readonly integrationReponse = INTEGRATION_RESPONSE;

  constructor(
    private snoozeTimerService: SnoozeTimerService,
    private utilsService: UtilsService,
    protected messageService: MessageService,
    protected stateService: StateService,
    protected sessionService: SessionService
  ) {}

  ngOnInit() {
    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.gpConnectSendDocumentStatus:
            // Update message details after successful send document. Message state is no longer editable by user.
            if (state['value'] === this.integrationReponse.SUCCESS) {
              this.messageService.getMessage(this.message['id']);
            }
            break;

          case StateKeys.singleMessage:
            const event = state['value'] as singleMessageEvent;
            this.handleSingleMessageEvent(event);
            break;

          case StateKeys.closedMessage:
            this.message = null;
            this.openMessage = false;
            break;

          case StateKeys.selectedService:
            this.message = null;
            this.openMessage = false;
            setTimeout(() => {
              this.serviceId = state['value'] ? state['value']['id'] : null;
            });
            break;

          case StateKeys.snoozeActiveTimer:
            if (state['value'] && state['value']['open_time']) {
              this.activeTimer = state['value'];
              const time1 = moment(this.activeTimer['open_time'])
                .locale('en')
                .format('LL');
              const time2 = moment_tz(this.activeTimer['open_time'])
                .tz(this.user['service_group']['time_zone'])
                .format('LT');
              this.activeTimer['open_time'] = time1 + ' ' + time2;
              this.snoozeNotification = `${this.snoozeTimerDisabled} ${this.activeTimer['open_time']}. ${this.snoozeDisclaimerIfError}`;
            }

            break;
        }
      }
    );

    this.snoozeTimerService.getActiveTimer();
    this.refreshActiveTimer = setInterval(() => {
      this.snoozeTimerService.getActiveTimer();
    }, 1800000);
  }

  ngOnDestroy(): void {
    this.stateChangeSubscription.unsubscribe();
    clearInterval(this.refreshActiveTimer);
  }

  closeFollowup() {
    this.stateService.setState(StateKeys.toggledFollowup, false);
  }

  private handleSingleMessageEvent(event: singleMessageEvent): void {
    if (event.status !== LoadingStatus.SUCCESS) return;

    this.message = event.data;
    this.openMessage = true;

    this.enquiryParser = new EnquiryParser(
      this.message,
      this.utilsService,
      this.sessionService.timeZone
    );

    this.enquiry = this.message as Enquiry;
  }

  handleIntegrationAction(action: EXTERNAL_FORWARD_TYPES): void {
    this.currentIntegrationAction = action;

    if (!this.checkGpConnectRequirements()) {
      return;
    }

    switch (action) {
      case EXTERNAL_FORWARD_TYPES.FOR_ACTION:
        this.sessionService.setData('forPurpose', EXTERNAL_FORWARD_TYPES.FA);
        this.movingToRecord = true;
        break;

      case EXTERNAL_FORWARD_TYPES.FOR_INFORMATION:
        this.sessionService.setData('forPurpose', EXTERNAL_FORWARD_TYPES.FI);
        this.movingToRecord = true;
        break;
    }
  }

  checkGpConnectRequirements(): boolean {
    // NHS number is requirement for GpConnect
    if (!this.message['nhs_number']) {
      return false;
    }

    // Practice code is requirement for GpConnect
    const practiceCodeSelected = this.selectPracticeCodeIfOnlyOne();
    if (!practiceCodeSelected && !this.showPracticeCodeSelection) {
      this.showPracticeCodeSelection = true;
      return false;
    } else {
      this.showPracticeCodeSelection = false;
    }

    // All requirements for GpConnect met
    return true;
  }

  selectPracticeCodeIfOnlyOne(): boolean {
    /**
     * Selects practice code if there is only one to choose from and returns true in that case.
     * Returns false if there are multiple practice codes and one needs to be selected by the user.
     */
    const practiceCodes = this.sessionService.serviceGroup.practice_codes;
    if (practiceCodes && practiceCodes.length == 1) {
      this.sessionService.setData('selectedPracticeCode', practiceCodes[0]);
      return true;
    }

    return false;
  }
}
