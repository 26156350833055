// @ts-nocheck
import { AbstractControl } from '@angular/forms';

export class PhoneNumberValidator {
  public static validate(c: AbstractControl) {
    if (c.value === null || c.value.length < 1) {
      return null;
    }

    const PHONENUMBER_REGEX =
      /^(((\+|00)358(([\-\s])?))?([0-9]{2,5})?([\-\s])?([0-9]{7}))?$/;

    return PHONENUMBER_REGEX.test(c.value.replace(/ /g, ''))
      ? null
      : {
          validatePhoneNumber: {
            valid: false,
          },
        };
  }
}
