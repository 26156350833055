// @ts-nocheck
import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { SessionService } from 'services/session.service';

@Directive({
  selector: '[hasPermissions]',
})
export class HasPermissionsDirective implements OnInit {
  private permissions: string | string[];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sessionService: SessionService
  ) {}

  ngOnInit() {}

  @Input()
  set hasPermissions(val) {
    this.permissions = val;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;

    if (this.sessionService.getSession()) {
      hasPermission = this.sessionService.checkPermissions(this.permissions);
    }

    return hasPermission;
  }
}
