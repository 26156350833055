// @ts-nocheck
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { Subscription } from 'rxjs';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';
import { UserService } from 'services/user.service';
import { UserDetailsModalType } from './detail/detail.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  public user = null;
  public currentUrl = '';
  public usersCreate = false;
  public usersEdit = false;
  public existingUser = false;
  public readonly permissions = Permissions;

  public selectedUser: object = null;

  public modalType: UserDetailsModalType = UserDetailsModalType.MY_ACCOUNT;

  @ViewChild('userModal', { static: true }) userModal;

  constructor(
    protected sessionService: SessionService,
    protected stateService: StateService,
    protected userService: UserService,
    protected router: Router,
    protected toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.user = this.sessionService.getSession();
    this.subscriptions.add(
      this.router.events.subscribe(
        () => (this.currentUrl = this.router.routerState.snapshot.url)
      )
    );
    this.usersCreate = this.sessionService.checkPermissions(
      this.permissions.USERS_CREATE
    );
    this.usersEdit = this.sessionService.checkPermissions(
      this.permissions.USERS_EDIT
    );

    this.subscriptions.add(
      this.stateService.state$.subscribe((state) => {
        switch (state['key']) {
          case StateKeys.cancelledUserEditing:
            this.closeUserModal();
            break;
          case StateKeys.closeUserModal:
            this.closeUserModal();
            break;
          case StateKeys.userListLoadingFailed:
            this.toasterService.pop(
              'danger',
              $localize`:@@userListLoadingFailed:`
            );
            break;
          case StateKeys.userDetailsLoadFailed:
            this.toasterService.pop(
              'danger',
              $localize`:@@userDetailsLoadFailed:`
            );
            break;
          case StateKeys.userDetailsUpdateFailed:
            this.toasterService.pop(
              'danger',
              $localize`:@@userDetailsUpdateFailed:`
            );
            break;
          case StateKeys.createFailed:
            if (state['value'].status === 409) {
              this.existingUser = state['value'].value.username;
            }
            break;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  closeUserModal(refresh = false) {
    this.selectedUser = null;
    this.userModal.hide();

    if (refresh) {
      this.userService.getUsers();
    }
  }

  userSelected(user: object | null) {
    this.setModalType(user);
    this.selectedUser = user ? user : {};
    this.userModal.show();
  }

  setModalType(user: object | null): void {
    if (!user) {
      this.modalType = UserDetailsModalType.CREATE_USER;
    } else {
      this.modalType = UserDetailsModalType.EDIT_USER;
    }
  }
}
