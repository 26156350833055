// @ts-nocheck
import { throwError as observableThrowError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { StateService, StateKeys } from 'services/state.service';
import { HttpTools } from 'tools/httptools';

/**
 * @deprecated - Use the HttpClient directly.
 */
@Injectable()
export class HttpWrapperService {
  constructor(
    private http: HttpClient,
    private httpTools: HttpTools,
    private stateService: StateService
  ) {}

  /**
   * @deprecated Use the HttpTools.prepareHeaders() method instead.
   * @param method
   */
  prepareHeaders(method: string = null) {
    let headers = new HttpHeaders();
    headers = this.httpTools.addAuthenticationHeaders(headers);

    if (environment.production) {
      headers = this.httpTools.addCachePreventionHeaders(headers);
    }

    if (method === 'post' || method === 'patch') {
      headers = this.httpTools.addContentTypeJsonHeader(headers);
    }

    return headers;
  }

  /**
   * @deprecated - Use the HttpClient directly.
   */
  async get(relativeUrl: string) {
    try {
      return await this.http
        .get(`${environment.backendURL}${relativeUrl}`, {
          headers: this.prepareHeaders(),
        })
        .toPromise();
    } catch (error) {
      this.handleErrors(error);
    }
  }

  /**
   * @deprecated - Use the HttpClient directly.
   */
  async delete(relativeUrl: string) {
    try {
      return await this.http
        .delete(`${environment.backendURL}${relativeUrl}`, {
          headers: this.prepareHeaders(),
        })
        .toPromise();
    } catch (error) {
      this.handleErrors(error);
    }
  }

  /**
   * @deprecated - Use the HttpClient directly.
   */
  async deprecated_post(relativeUrl: string, data: any): Promise<object> {
    try {
      return await this.http
        .post(`${environment.backendURL}${relativeUrl}`, JSON.stringify(data), {
          headers: this.prepareHeaders('post'),
        })
        .toPromise();
    } catch (error) {
      this.handleErrors(error);
    }
  }

  /**
   * @deprecated - Use the HttpClient directly.
   */
  post(relativeUrl: string, data: any): Observable<Object> {
    try {
      return this.http.post(
        `${environment.backendURL}${relativeUrl}`,
        JSON.stringify(data),
        { headers: this.prepareHeaders('post') }
      );
    } catch (error) {
      this.handleErrors(error);
    }
  }

  /**
   * @deprecated - Use the HttpClient directly.
   */
  patch(relativeUrl: string, data: any): Observable<Object> {
    try {
      return this.http.patch(
        `${environment.backendURL}${relativeUrl}`,
        JSON.stringify(data),
        { headers: this.prepareHeaders('patch') }
      );
    } catch (error) {
      this.handleErrors(error);
    }
  }

  /**
   * @deprecated
   */
  private handleErrors(e) {
    // catch all general errors, then pass through
    let error = null;
    if (e.hasOwnProperty('error')) {
      error = e['error'];
    }
    this.stateService.setState(StateKeys.requestErrors, error);
    return observableThrowError(e);
  }
}
