import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ServiceGroupService } from 'services/service-group.service';

export interface ServiceGroupFeatures {
  service_group: string;
  connect: boolean;
  dashboard: boolean;
  snooze: boolean;
  attachments: boolean;
  comments: boolean;
  video: boolean;
  feedback: boolean;
  sms_notification: boolean;
  reduced_copy: boolean;
  outcome_resources: boolean;
  outcome_procedures: boolean;
  gp_connect: boolean;
  blackpear_tpp: boolean;
  blackpear_emis: boolean;
  patient_identity_lookup: boolean;
}

type ColumnConfig = Record<keyof ServiceGroupFeatures, string>;

@Component({
  selector: 'app-feature-overview',
  templateUrl: './feature-overview.component.html',
  styleUrls: ['./feature-overview.component.scss'],
})
export class FeatureOverviewComponent implements OnInit {
  public dataSource: MatTableDataSource<ServiceGroupFeatures>;
  public serviceGroupSettings: ServiceGroup | undefined;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public COLUMN_CONFIG: ColumnConfig = {
    service_group: 'Service Group',
    connect: 'Connect',
    dashboard: 'Dashboard',
    snooze: 'Snooze',
    attachments: 'Attachments',
    comments: 'Comments',
    video: 'Video',
    feedback: 'Feedback',
    sms_notification: 'SMS Notification',
    reduced_copy: 'Reduced Copy',
    outcome_resources: 'Outcome - Resources',
    outcome_procedures: 'Outcome - Procedures',
    gp_connect: 'GP Connect',
    blackpear_tpp: 'Blackpear TPP',
    blackpear_emis: 'Blackpear EMIS',
    patient_identity_lookup: 'Patient Identity Lookup',
  };
  public displayedColumns = Object.keys(
    this.COLUMN_CONFIG
  ) as (keyof ServiceGroupFeatures)[];

  constructor(private serviceGroupService: ServiceGroupService) {
    this.dataSource = new MatTableDataSource();
  }

  public serviceGroups: ServiceGroupListSerializerData[] = [];

  ngOnInit() {
    this.fetchServiceGroups();
  }

  async fetchServiceGroups() {
    try {
      const serviceGroups =
        await this.serviceGroupService.getAllServiceGroups();
      this.populateFeatureData(serviceGroups);
    } catch (error) {
      console.error('Error fetching service groups:', error);
    }
  }

  populateFeatureData(serviceGroups: ServiceGroupListSerializerData[]) {
    this.dataSource.data = serviceGroups.map((group) => ({
      service_group: group.name,
      connect: group.pro_form_active,
      dashboard: group.dashboard_enabled,
      snooze: group.snooze_enabled,
      attachments: group.request_attachments_enabled,
      comments: group.allow_commenting,
      video: group.video_consultation_enabled,
      feedback: group.feedback_loop_enabled,
      sms_notification: group.sms_enabled,
      reduced_copy: group.reduced_copy_content_enabled,
      outcome_resources: group.feedback_resources_enabled,
      outcome_procedures: group.feedback_procedures_enabled,
      gp_connect: group.integration_settings?.gp_connect_enabled ?? false,
      blackpear_tpp: group.black_pear_tpp_enabled,
      blackpear_emis: group.black_pear_emis_enabled,
      patient_identity_lookup: group.patient_identity_lookup_enabled,
    }));
  }
}
