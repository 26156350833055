// @ts-nocheck
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SessionService } from 'services/session.service';
import {
  EXTERNAL_FORWARD_STATUSES,
  EXTERNAL_FORWARD_TYPES,
} from 'services/integration.service';
import { IntegrationService } from 'services/integration.service';
import { StateService, StateKeys } from 'services/state.service';
import { Subscription } from 'rxjs';
import { getNestedValue } from 'app/shared/methods/getNestedValue';
import { formatDateToLocale } from 'app/utils/dateUtils';
import { FeatureFlagService } from 'services/feature-flag.service';

@Component({
  selector: 'app-messages-detail-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public message: object = null;

  public externalForwardStatuses: ExternalForwardStatus[] = null;
  public fetchinExternalForwardStatuses: boolean = false;

  public readonly EXTERNAL_FORWARD_TYPES = EXTERNAL_FORWARD_TYPES;
  public readonly EXTERNAL_FORWARD_STATUSES = EXTERNAL_FORWARD_STATUSES;

  private stateChangeSubscription: Subscription;

  private readonly externalForwardStatusTranslations = {
    externalforwardpendingfi: $localize`:@@externalForwardPendingFI:`,
    externalforwardpendingfa: $localize`:@@externalForwardPendingFA:`,
    externalforwardfailurefi: $localize`:@@externalForwardFailureFI:`,
    externalforwardfailurefa: $localize`:@@externalForwardFailureFA:`,
    externalforwardsuccessfi: $localize`:@@externalForwardSuccessFI:`,
    externalforwardsuccessfa: $localize`:@@externalForwardSuccesssFA:`,
    externalforwardtimedout: $localize`:@@CaseHasTimedoutExternalForwards:`,
  };

  get isRestricted(): boolean {
    return this.sessionService.isRestricted;
  }

  constructor(
    private sessionService: SessionService,
    private integrationService: IntegrationService,
    private stateService: StateService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.loadedExternalForwardStatuses:
            this.fetchinExternalForwardStatuses = false;

            if (
              state['value']['messageUuid'] !==
              getNestedValue(this.message, 'data', 'appointment_request_uuid')
            ) {
              return;
            }

            this.externalForwardStatuses = state['value']['externalForwards'];
            this.localize_and_filter(this.externalForwardStatuses);
            break;
          case StateKeys.gpConnectSendDocumentStatus:
            this.fetchExternalForwards();
            break;
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (getNestedValue(changes, 'message') === undefined) {
      return;
    }

    let messageChanged: boolean = false;
    if (changes.message.firstChange === false) {
      messageChanged =
        changes.message.currentValue.id !== changes.message.previousValue.id;
    }

    if (changes.message.firstChange || messageChanged) {
      this.fetchExternalForwards();
    }
  }

  ngOnDestroy(): void {
    if (this.stateChangeSubscription) {
      this.stateChangeSubscription.unsubscribe();
    }
  }

  fetchExternalForwards(): void {
    if (!this.featureFlagService.isGPConnectIntegrationEnabled) return;
    this.externalForwardStatuses = null;
    this.fetchinExternalForwardStatuses = true;
    this.integrationService.getExternalForwards(
      getNestedValue(this.message, 'data', 'appointment_request_uuid')
    );
  }

  localize_and_filter(externalForwardStatuses: ExternalForwardStatus[]): void {
    if (!externalForwardStatuses) {
      return;
    }

    this.externalForwardStatuses = externalForwardStatuses.filter(
      (externalForwardStatus) => {
        // If external forward does not have status, filter it out
        if (externalForwardStatus.latest_status === null) {
          return false;
        }

        // Translation for status
        const status = this.getDisplayStatus(
          externalForwardStatus
        ).toLowerCase();
        const type = externalForwardStatus.type.toLowerCase();
        externalForwardStatus.translated_status =
          this.externalForwardStatusTranslations[
            `externalforward${status}${type}`
          ];

        // Format created_at to local timezone
        const created_at = externalForwardStatus.latest_created_at;
        externalForwardStatus.latest_created_at = formatDateToLocale(
          created_at,
          'LLL',
          this.sessionService.timeZone
        );

        // Return customized object
        return externalForwardStatus;
      }
    );
  }

  getDisplayStatus(externalForward: ExternalForwardStatus) {
    if (externalForward.latest_status == EXTERNAL_FORWARD_STATUSES.FAILURE) {
      return EXTERNAL_FORWARD_STATUSES.FAILURE;
    }

    if (
      externalForward.technical_ack_received &&
      externalForward.business_ack_received
    ) {
      return EXTERNAL_FORWARD_STATUSES.SUCCESS;
    }

    return EXTERNAL_FORWARD_STATUSES.PENDING;
  }
}
