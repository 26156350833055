// @ts-nocheck
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-details-notification-slot',
  templateUrl: './patient-details-notification-slot.component.html',
  styleUrls: ['./patient-details-notification-slot.component.scss'],
})
export class PatientDetailsNotificationSlotComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
