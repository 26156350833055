<h1 i18n="@@staffSettingsTitle">Staff</h1>

<p i18n="@@thisPageIsOnlyVisibleToStaffUsers">
  This page is only visible to staff members.
</p>

<hr />

<h2 i18n="@@staffSettingsAllActiveUsers">All active users</h2>

<p i18n="@@staffSettingsAllActiveUsersDescription">
  Contains all users that are not deleted, are not staff users and are not in a
  demo service group.
</p>

<button
  class="btn action"
  (click)="downloadActiveUsersList()"
  [disabled]="downloadingUsersList"
  i18n="@@staffSettingsDownloadActiveUsersList">
  Download active users list
</button>

<hr />

<app-authentication-data></app-authentication-data>
