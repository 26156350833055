<app-delete-confirmation-popup
  *ngIf="deletingTemplate"
  [translations]="'smsTemplateDelete'"
  [items]="smsTemplateDeleteItem"
  [disabled]="hasEditPermission === false"
  (deleteConfirmationEmitter)="deleteTemplate($event)">
</app-delete-confirmation-popup>

<form [formGroup]="editTemplateFormGroup">
  <h2 i18n="@@smsTemplateEditTemplates">Edit existing templates</h2>

  <div class="input-row">
    <div class="input-label-wrapper name">
      <label for="name" i18n="@@smsTemplateNameLabel">Title</label>
    </div>
    <div class="input-label-wrapper body">
      <label for="body" i18n="@@smsTemplateBodyLabel">Message</label>
    </div>
  </div>

  <p *ngIf="!existingTemplates" class="error" i18n="@@smsTemplateLoadError">
    Error occured, could not load existing SMS templates
  </p>

  <div
    class="input-row"
    *ngFor="let template of existingTemplates; let i = index">
    <div class="input-label-wrapper name">
      <app-textarea-input
        [smallTextarea]="true"
        [prefill]="template.name"
        [disabled]="!hasEditPermission"
        (controlEmitter)="checkChange(i, SMSTemplateKeys.NAME, $event)">
      </app-textarea-input>
    </div>

    <div class="input-label-wrapper body">
      <app-textarea-input
        [prefill]="template.body"
        [maxlength]="900"
        [largeTextarea]="true"
        [rows]="4"
        [disabled]="!hasEditPermission"
        (controlEmitter)="checkChange(i, SMSTemplateKeys.BODY, $event)">
      </app-textarea-input>
    </div>

    <div class="vertically-stacked-buttons">
      <button
        type="button"
        class="btn action"
        *ngIf="template.changes"
        (click)="putTemplate(i)"
        [disabled]="isSaveChangesDisabled(i)">
        <span i18n="@@smsTemplateSave">Save changes</span>
      </button>

      <button
        type="button"
        class="btn delete"
        (click)="promptDelete(template)"
        [disabled]="!hasEditPermission">
        <span>
          <i class="fa fa-trash" aria-hidden="true"></i>
          <span i18n="@@smsTemplateDelete">Delete template</span>
        </span>
      </button>
    </div>
  </div>
</form>
