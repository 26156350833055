// @ts-nocheck
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface BooleanSwitchInput {
  label?: string;
  title?: string;
  disabled?: boolean;
  value?: boolean;
}

/**
 * A reusable toggle switch component that emits an on/off (boolean) value when toggled.
 */
@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent implements OnInit {
  /**
   * The label text to display next to the toggle switch.
   */
  @Input() public label: string = '';

  /**
   * The value of the toggle switch. This can be used to set the initial value, for example.
   */
  @Input() public value: boolean = false;

  /**
   * Whether the toggle switch is disabled or not.
   */
  @Input() public disabled: boolean = false;

  /**
   * The title text to display when hovering over the toggle switch.
   */
  @Input() public title: string = '';

  /**
   * Set this to true if you want a basic confirmation dialog to show the user is toggling the switch.
   */
  @Input() public askConfirm: boolean = false;

  /**
   * Set this to customize the confirmation dialog text. "Are you sure?" will be used by default.
   * */
  @Input() public askConfirmText: string = 'Are you sure?';

  /**
   * The event that is emitted when the toggle switch is toggled. The value emitted is a boolean
   * and indicates whether the toggle switch is on or off.
   */
  @Output() public readonly updatedEvent = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  public toggle(event: Event) {
    const checkedValue = (event.currentTarget as any).checked;

    if (this.askConfirm) {
      if (!confirm(this.askConfirmText)) {
        // toggle the input back to the previous value
        this.value = !checkedValue;
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }

    this.value = checkedValue;
    this.updatedEvent.emit(this.value);
  }
}
