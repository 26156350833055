// @ts-nocheck
import { Component, Input, OnInit } from '@angular/core';
import { Enquiry } from 'app/shared/methods/enquiry/enquiry';
@Component({
  selector: 'app-caretaker-info-display',
  templateUrl: './caretaker-info-display.component.html',
  styleUrls: ['./caretaker-info-display.component.scss'],
})
export class CaretakerInfoDisplayComponent implements OnInit {
  @Input()
  public message: Enquiry;
  utilsService: any;
  constructor() {}
  ngOnInit(): void {}
}
