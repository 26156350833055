// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { SessionService } from 'services/session.service';
import { ServiceGroupService } from 'services/service-group.service';
import { SMSTemplatesService } from 'app/smstemplates.service';

export enum SMS_TEMPLATE_KEYS {
  'NAME' = 'name',
  'BODY' = 'body',
}

@Component({
  selector: 'app-sms-messaging',
  templateUrl: './sms-messaging.component.html',
  styleUrls: ['./sms-messaging.component.scss'],
})
export class SmsMessagingComponent implements OnInit {
  public readonly userIsStaff: boolean;

  public serviceGroup: ServiceGroup = {} as ServiceGroup;

  public deletingAllSMSTemplates: boolean = false;

  public smsTemplateMarkets = [
    // todo: these should be feched from the source (ie. Messages)
    // so there's no need to update multiple places when new markets are added.
    { name: $localize`:@@GreatBritain:`, value: 'gb' },
    { name: $localize`:@@Netherlands:`, value: 'nl' },
  ];

  translations = {
    smsTemplateMarkets:
      $localize`:@@smsTemplatesSettingsTitle:` + ' - ' + $localize`:@@Country:`,
  };

  selectedSMSTemplateMarket: string | null = null;

  public readonly checkKeys = SMS_TEMPLATE_KEYS;

  constructor(
    private serviceGroupService: ServiceGroupService,
    private smsTemplatesService: SMSTemplatesService,
    private toasterService: ToasterService,
    private sessionService: SessionService
  ) {
    this.userIsStaff = this.sessionService.userIsStaff;
  }

  ngOnInit(): void {
    this.getSmsSettings();
  }

  private getSmsSettings() {
    if (!this.userIsStaff) {
      // No need to load service group SMS settings, nor risk an error happening.
      return;
    }

    this.serviceGroupService
      .getActiveServiceGroup()
      .then((serviceGroup) => {
        this.serviceGroup = serviceGroup;
      })
      .catch(() => {
        this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`);
      });
  }

  protected updateLocalValue(key: string, value: string) {
    console.debug(`Updating service group '${key}' with value '${value}'`);
    this.serviceGroup[key] = value;
  }

  getLocalValue(key: string) {
    return this.serviceGroup[key] || '';
  }

  protected updateSMSTemplateMarket(market: string) {
    this.selectedSMSTemplateMarket = market;
  }

  public saveSMSSettings() {
    this.serviceGroupService
      .updateServiceGroup({
        // Explicitly add fields that should be saved to prevent mistakes.
        sms_enabled: this.serviceGroup.sms_enabled,
        sms_phone_number: this.serviceGroup.sms_phone_number,
        sms_rejection_message: this.serviceGroup.sms_rejection_message,
      })
      .then((serviceGroup) => {
        this.serviceGroup = serviceGroup;
        this.toasterService.pop('success', $localize`:@@savedSuccesfully:`);
      })
      .catch(() => {
        this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`);
      });
  }

  public addDefaultSMSTemplates() {
    this.smsTemplatesService
      .addDefaultSMSTemplates(
        this.serviceGroup.id,
        this.selectedSMSTemplateMarket
      )
      .then((value) => {
        this.toasterService.pop(
          'success',
          $localize`:@@smsTemplateAddNewSuccess:`
        );
      })
      .catch((reason) => {
        this.toasterService.pop('error', $localize`:@@smsTemplateAddNewError:`);
      });
  }

  public async deleteAllSMSTemplates(deleteConfirmation: boolean) {
    if (!deleteConfirmation) {
      this.deletingAllSMSTemplates = false;
      return;
    }

    this.smsTemplatesService
      .deleteAllSMSTemplates()
      .then(() => {
        this.toasterService.pop(
          'success',
          $localize`:@@smsTemplateDeleteSuccess:`
        );
      })
      .catch(() =>
        this.toasterService.pop('error', $localize`:@@smsTemplateDeleteError:`)
      );

    this.deletingAllSMSTemplates = false;
  }
}
