// @ts-nocheck
import * as ChartJS from 'chart.js';
import { ChartData, ChartOptions, ChartTooltipItem } from 'chart.js';
import { environment } from 'environments/environment';

class ChartJSDefaultOptions implements ChartJS.ChartOptions {
  responsive = true;
  maintainAspectRatio = false;
  legend = {
    display: false,
    labels: {
      fontFamily: "'quicksand', 'sans-serif'",
    },
  };
  scales = {
    xAxes: [
      {
        ticks: {
          display: true,
          fontSize: 16,
          min: 0,
          beginAtZero: true,
          // - 3 characters from value.length to avoid replacing last 3 characters with '...'
          callback: (value: string) =>
            value === null
              ? ''
              : value.length - 3 > 12
              ? `${value.substr(0, 12)}...`
              : value,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          beginAtZero: true,
        },
      },
    ],
  };
  plugins = {
    datalabels: {
      display: false,
    },
  };
  tooltips = {
    enabled: true,
    mode: <ChartJS.InteractionMode>'nearest',
    intersect: true,
    titleFontSize: 16,
    bodyFontSize: 16,
    callbacks: {
      title: (item: ChartTooltipItem[], data: ChartData) => {
        const title = `${data.labels[item[0].index]}`;
        const regex = /^\d{4}-\d{2}-\d{2}$/i;
        if (regex.test(title)) {
          return new Date(title).toLocaleString(getLocale(), {
            weekday: 'long',
          });
        }
        return title;
      },
      label: (item: ChartTooltipItem, data: ChartData) => {
        const amount = <number>(
          data['datasets'][item.datasetIndex]['data'][item.index]
        );
        const total = (<number[]>data.datasets[0].data).reduce(
          (a: number, b: number) => a + b,
          0
        );
        return `${$localize`:@@total:`}: ${amount} (${(
          (amount / total) *
          100
        ).toFixed(0)}%)`;
      },
    },
  };
  hover = {
    mode: <ChartJS.InteractionMode>'nearest',
    intersect: true,
  };

  constructor(customOptions?: ChartJS.ChartOptions) {
    if (customOptions) {
      Object.keys(customOptions).forEach((option: string) => {
        this[option] = customOptions[option];
      });
    }
  }
}
function getLocale(): string {
  const href = window.location.href.toLowerCase().split('/');
  const localeIndex = href.indexOf('#') > 0 ? href.indexOf('#') - 1 : 0;
  if (environment.availableLanguages.indexOf(href[localeIndex]) !== -1) {
    return href[localeIndex];
  }
  return environment.defaultLocale;
}

const pieLegend: ChartOptions = {
  legend: {
    display: true,
    labels: {
      fontFamily: "'quicksand', 'sans-serif'",
      fontSize: 16,
    },
  },
};

const datalabelsPlugin: ChartOptions = {
  plugins: {
    datalabels: {
      display: true,
      anchor: 'end',
      align: 'bottom',
      color: 'white',
      formatter: function (value: number, context: object) {
        const total = (<Array<number>>context['dataset'].data).reduce(
          (acc: number, val: number) => acc + val,
          0
        );
        return value > 0
          ? `${value} (${((value / total) * 100).toFixed(0)}%)`
          : '';
      },
    },
  },
};

const hideTooltips: ChartOptions = {
  tooltips: {
    enabled: false,
  },
  hover: {
    mode: null,
  },
};

const groupedBarTooltip: ChartOptions = {
  tooltips: {
    enabled: true,
    mode: 'single',
    titleFontSize: 16,
    bodyFontSize: 16,
    callbacks: {
      title: (item: ChartTooltipItem[], data: ChartData) => {
        const title = `${data.datasets[item[0].datasetIndex].label}`;
        if (new Date(title).getDate()) {
          return new Date(title).toLocaleString(getLocale(), {
            weekday: 'long',
          });
        }
        return title;
      },
      label: (item: ChartTooltipItem, data: ChartData) => {
        let total = 0;
        data.datasets.forEach(
          (dataset) =>
            (total += (<number[]>dataset.data).reduce(
              (a: number, b: number) => a + b,
              0
            ))
        );
        return `${$localize`:@@total:`}: ${item.value} (${(
          (parseInt(item.value) / total) *
          100
        ).toFixed(0)}%)`;
      },
    },
  },
};

const autoSkipXLabelsFalse: ChartOptions = {
  scales: {
    xAxes: [
      {
        ticks: {
          autoSkip: false,
          display: true,
          fontSize: 16,
          // - 3 characters from value.length to avoid replacing last 3 characters with '...'
          callback: (value: string) =>
            value === null
              ? ''
              : value.length - 3 > 12
              ? `${value.substr(0, 12)}...`
              : value,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          beginAtZero: true,
        },
      },
    ],
  },
};

const hideScales: ChartOptions = {
  scales: {
    xAxes: [{ display: false }],
    yAxes: [{ display: false }],
  },
};

const hideXLabel: ChartOptions = {
  scales: {
    xAxes: [{ display: false }],
    yAxes: [
      {
        ticks: {
          min: 0,
          beginAtZero: true,
        },
      },
    ],
  },
};

export const defaultOptions: ChartOptions = new ChartJSDefaultOptions();

export const customOptionsBarPlugin: ChartOptions = new ChartJSDefaultOptions({
  ...datalabelsPlugin,
});

export const customOptionsBarNoAutoskip: ChartOptions =
  new ChartJSDefaultOptions({
    ...autoSkipXLabelsFalse,
  });

export const defaultOptionsPie: ChartOptions = new ChartJSDefaultOptions({
  ...hideScales,
  ...pieLegend,
});

export const customOptionsPie: ChartOptions = new ChartJSDefaultOptions({
  ...hideScales,
  ...hideTooltips,
});

export const optionsGroupedBar: ChartOptions = new ChartJSDefaultOptions({
  ...groupedBarTooltip,
  ...autoSkipXLabelsFalse,
});
