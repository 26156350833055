// @ts-nocheck
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SessionService } from 'services/session.service';
import { FeedbackService } from 'services/feedback.service';

@Component({
  selector: 'app-kip-integration',
  templateUrl: './kip-integration-popup.component.html',
  styleUrls: ['./kip-integration-popup.component.scss'],
})
export class KIPIntegrationPopupComponent implements OnInit {
  @Output()
  private popupVisible: EventEmitter<boolean> = new EventEmitter();

  public additionalFeedback = false;
  private session = this.sessionService.getSession();
  public navigationCheck = null;

  constructor(
    private sessionService: SessionService,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit() {
    this.activateKipPopup();
  }

  activateKipPopup() {
    if (
      !this.session ||
      !this.sessionService.isEmisIntegrationEnabled ||
      this.session['kip_onboarded'] !== null
    ) {
      return;
    } else {
      this.navigationCheck = 0;
    }
  }

  nextView() {
    this.navigationCheck++;
    this.togglePopup();
  }

  backView() {
    this.navigationCheck--;
    this.togglePopup();
  }

  togglePopup() {
    const click = document.getElementById('header-dropdown');
    const disableDropdown = document.getElementById('lock-dropdown');
    if (
      (this.navigationCheck !== 2 && click.classList.contains('show')) ||
      this.navigationCheck === 2
    ) {
      click.classList.toggle('show');
      disableDropdown.classList.toggle('lock-dropdown');
    }
  }

  async patchUserOnboarded() {
    await this.feedbackService
      .patchUserOnboarded()
      .then(() => (this.navigationCheck = null));
    this.closePopup();
  }

  closePopup() {
    this.popupVisible.emit(false);
  }
}
