// @ts-nocheck
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { DropdownTranslations } from 'app/utils/dropdowns/dropdown/dropdown.component';
import { ChartDataSets } from 'chart.js';
import { Subscription } from 'rxjs';
import { DashboardService } from 'services/dashboard.service';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnChanges, OnDestroy {
  @Input()
  public activeView: string;

  @Input()
  public type: string = null;

  @Input()
  private chart: Chart = null;

  @Input()
  public hideFilters = true;

  @Input()
  public hideTitle = false;

  @Output()
  private linkedChartEmitter: EventEmitter<LinkedChart> = new EventEmitter();

  @Output()
  private newChartIdEmitter: EventEmitter<object> = new EventEmitter();

  public relatedCharts = false;
  public LinkedChart = false;
  public open = false;
  public loading = false;
  public title: string = null;
  public data: Chart = null;
  public total: number = null;
  public readonly typeTableAndChart = CardTypes.TABLE_AND_CHART;
  public readonly typeSideBySide = CardTypes.SIDE_BY_SIDE;
  public readonly typeTopThree = CardTypes.TOP_THREE;
  public readonly typeChart = CardTypes.CHART;
  public readonly typeTotal = CardTypes.TOTAL;
  public readonly typeTop = CardTypes.TOP;
  public readonly dailyView = Views.DAILY;

  // @ts-ignore
  private readonly placeholderChart: Chart = {
    id: 'placeholder',
    legend: '',
    type: ChartType.BAR,
    options: {},
    data: { labels: ['', '', ''], datasets: [{ data: [0, 0, 0] }] },
  };
  private loadingStateSubscription: Subscription;

  constructor(
    private utilsService: UtilsService,
    private dashboardService: DashboardService
  ) {
    this.loadingStateSubscription =
      this.dashboardService.loadingState$.subscribe(
        (state: boolean) => (this.loading = state)
      );
  }

  ngOnChanges(): void {
    this.total = 0;
    this.data =
      this.utilsService.ObjectHasKey(this.chart, 'data.datasets') &&
      this.chart.data.datasets.length > 0
        ? { ...this.chart }
        : this.placeholderChart;
    this.title = this.utilsService.ObjectHasKey(this.chart, 'legend')
      ? this.chart.legend
      : '';
    this.LinkedChart = this.utilsService.ObjectHasKey(
      this.chart,
      'linked_chart'
    );
    this.relatedCharts =
      this.utilsService.ObjectHasKey(
        this.chart,
        'card_config.show_related_charts'
      ) && this.activeView !== Views.DAILY;

    if (this.utilsService.ObjectHasKey(this.chart, 'data.datasets')) {
      this.chart.data.datasets.forEach((dataset: ChartDataSets) => {
        this.total += (<number[]>dataset.data).reduce(
          (acc: number, curr: number) => acc + curr,
          0
        );
      });
    }
  }

  ngOnDestroy(): void {
    this.loadingStateSubscription.unsubscribe();
  }

  openLinkedChart(): void {
    this.linkedChartEmitter.emit(this.chart.linked_chart);
  }

  toggleRelatedCharts(): void {
    if (this.relatedCharts) {
      this.open = !this.open;
    }
  }

  emitNewChartId(newChartId: object): void {
    this.toggleRelatedCharts();
    this.newChartIdEmitter.emit({ view: null, key: newChartId });
  }
}
