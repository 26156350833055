// @ts-nocheck
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IntegrationService } from 'services/integration.service';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';
import { UserService } from 'services/user.service';

@Component({
  selector: 'app-kip-card',
  templateUrl: './kip-card.component.html',
  styleUrls: ['./kip-card.component.scss'],
})
export class KipCardComponent implements OnInit, OnDestroy {
  @Input()
  public config: {
    state: number;
    action: string;
    translations: { header: string; text: string; button: string };
  } = null;

  @Input()
  public index: number = null;

  @Output()
  private cardEmitter: EventEmitter<number> = new EventEmitter();

  private stateChangeSubscription: Subscription;
  private touched = false;

  public responseStatus: string = null;
  public phrUserForm: FormGroup = new FormGroup({
    emis_username: new FormControl('', Validators.required),
  });

  constructor(
    private integrationService: IntegrationService,
    private userService: UserService,
    private sessionService: SessionService,
    private stateService: StateService
  ) {}

  ngOnDestroy(): void {
    this.stateChangeSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.integrationService.getLatestKIPVersion();

    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.userDetailsUpdateFailed:
            if (
              this.config.action === 'addPhrName' &&
              this.responseStatus === 'sending'
            ) {
              this.responseStatus = 'failed';
            }
            break;

          case StateKeys.updateSuccessful:
            if (
              this.config.action === 'addPhrName' &&
              (this.responseStatus === 'sending' ||
                this.responseStatus === 'failed')
            ) {
              this.responseStatus = 'success';
              this.nextStep();
            }
            break;

          case StateKeys.emisIntegrationAvailable:
            // This state is also updated elsewhere so we should only change the response status here
            // if the update is done by the status check in testKipConnection()
            if (
              this.config.action === 'testKipConnection' &&
              this.responseStatus === 'sending'
            ) {
              const status = state['value'];
              if (status === false) {
                this.responseStatus = 'failed';
              } else {
                this.responseStatus = 'success';
                this.nextStep();
              }
            }
            break;
          default:
            break;
        }
      }
    );
  }

  handleAction(action: string): void {
    // This component handles actions dynamically.
    // action = function name
    let nextStep = false;
    const componentAction = this[action]; // Bind function from "this scope" to const componentAction

    if (componentAction) {
      // Call function dynamically here.
      // Dynamically called functions lose this binding, so it needs to be passsed separately.
      const self = this;

      nextStep = componentAction(self);
    } else {
      console.log(`${action} does not exist`);
    }

    nextStep
      ? this.nextStep()
      : console.log(`${action} failed. Cannot move to next step.`);
  }

  downloadKip(self: this): boolean {
    let next = false;

    const downloadUrl = self.integrationService.kipInstallerDownloadUrl;
    if (downloadUrl !== '' && downloadUrl !== null) {
      window.open(downloadUrl, '_blank');
      next = true;
    } else {
      // Try to update kipInstallerDownloadUrl
      self.integrationService.getLatestKIPVersion();
    }

    return next;
  }

  continue(self: this): boolean {
    return true;
  }

  testKipConnection(self: this): false {
    // Response from the update is caught in the sessionService listener inside onIniti()
    // Thus this function will never invoke nextStep()
    self.responseStatus = 'sending';
    self.integrationService.checkIntegrationAvailable();
    return false;
  }

  addPhrName(self: this): false {
    // Response from the update is caught in the sessionService listener inside onIniti()
    // Thus this function will never invoke nextStep()
    self.responseStatus = 'sending';
    const userID = self.sessionService.getSession()['id'];
    self.userService
      .updateUserDetails(userID, self.phrUserForm.value, false)
      .then((res) => {
        if (res) {
          this.stateService.setState(StateKeys.updateSuccessful, '');
        }
      })
      .catch((error) => {
        this.stateService.setState(StateKeys.userDetailsUpdateFailed, null);
      });
    return false;
  }

  testPhrConnection(self: this): boolean {
    return true;
  }

  nextStep() {
    this.config['state'] = 2;
    this.cardEmitter.emit(this.index);
  }
}
