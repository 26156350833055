<div id="link-service-groups-container">
  <div class="link-service-groups-shadow"></div>
  <section class="link-service-groups-content">
    <h2 i18n="@@DoYouWantToContinueToAddUser">
      Do you want to continue to add user {{ username }} to Klinik practice
      platform {{ user['service_group']['name'] }}
    </h2>
    <h4 i18n="@@WeHaveNoticed">
      We have noticed user {{ username }} already has a user account for another
      Klinik practice
    </h4>
    <div class="link-service-groups-button-container">
      <button class="no-opinion" i18n="@@NoOpinion" (click)="closePopUp()">
        No
      </button>
      <button class="yes-button" i18n="@@YesOpinion" (click)="linkUser()">
        Yes
      </button>
    </div>
  </section>
</div>
