<section class="user-satisfaction-container" *ngIf="askSurvey === true">
  <div class="user-satisfaction-header">
    <h2 i18n="@@askingForFeedbackSurvey">We would like to hear your opinion</h2>
  </div>
  <button class="user-satisfaction-quit" (click)="postSurveyData()">
    <i class="fa fa-times"></i>
  </button>

  <div class="user-satisfaction-body" *ngIf="surveyGrade === null">
    <div class="smileys">
      <button
        *ngFor="let smiley of smileys"
        (click)="selectGrade(smiley)"
        attr.aria-selected="{{ smiley.visible }}">
        <img
          src="assets/customer_satisfaction/{{ smiley.label }}.png"
          alt="{{ smiley.label }}" />
      </button>
    </div>
    <div class="user-satisfaction-footer">
      <p i18n="@@askingMonthyFeedbackSurvey">We highly value your opinion</p>
    </div>
  </div>

  <div
    class="user-satisfaction-body-two"
    *ngIf="surveyGrade != null && additionalFeedback === false">
    <div class="user-satisfaction-body-container">
      <div class="user-satisfaction-body-text">
        <p i18n="@@askingMonthyFeedbackSurveyThank">Thank you!</p>
        <p i18n="@@askingMonthyFeedbackSurveyMore">
          Would you like to provide us more feedback?
        </p>
      </div>
    </div>
  </div>
  <div
    class="user-satisfaction-button-container"
    *ngIf="surveyGrade != null && additionalFeedback === false">
    <button
      class="yes-opinion"
      (click)="additionalFeedback = true"
      i18n="@@askingMonthyFeedbackSurveyYes">
      Yes
    </button>
    <button
      class="no-opinion"
      (click)="postSurveyData()"
      i18n="@@askingMonthyFeedbackSurveyNo">
      No
    </button>
  </div>

  <div class="user-satisfaction-body-three" *ngIf="additionalFeedback === true">
    <div class="survey-wrapper">
      <textarea [(ngModel)]="feedbackValue"></textarea>
    </div>
    <div class="user-satisfaction-button-container-two">
      <button
        class="send-button"
        (click)="postSurveyData()"
        i18n="@@sendFeedbackSurvey">
        Send feedback
      </button>
    </div>
  </div>
</section>
