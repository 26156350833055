// @ts-nocheck
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { Configuration } from 'app/app.constants';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { environment } from 'environments/environment';

import { HttpWrapperService } from 'services/http-wrapper.service';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';
import { HttpTools } from 'tools/httptools';

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  EXPIRED = 'EXPIRED',
}

@Injectable()
export class UserService {
  public selectedUser = null;

  /**
   * @deprecated - use getUser instead
   */
  public selectedDetailedUser = null;

  private serviceNames: object = {};
  private selectedService = null;
  private order_by = 'first_name';
  private page = 1;
  private userSearchTerm: string = null;
  private readonly page_size: number;
  private readonly permissions = Permissions;

  constructor(
    private sessionService: SessionService,
    private stateService: StateService,
    private router: Router,
    protected configuration: Configuration,
    private httpWrapper: HttpWrapperService,
    private toaster: ToasterService,
    private http: HttpClient,
    private httpTools: HttpTools
  ) {
    this.page_size = this.configuration.usersPageSize;

    this.stateService.state$.subscribe((state) => {
      switch (state['key']) {
        case StateKeys.selectedService:
          this.selectedService = state['value'];
          this.page = 1;
          this.getUserCount();
          this.getUsers();
          break;
        case StateKeys.user_order_by:
          this.order_by = state['value'];
          this.getUsers();
          break;
        case StateKeys.userSearchTerm:
          this.userSearchTerm = state['value'];
          this.page = 1;
          this.getUserCount();
          this.getUsers();
          break;
        case StateKeys.userPageChanged:
          this.page = state['value'];
          this.getUsers();
          break;
        case StateKeys.selectedUser:
          if (!state['value']) {
            return;
          }

          this.selectedUser = state['value']['user'];
          this.getUserDetails(this.selectedUser, state['value']['target']);
          break;
      }
    });
  }

  async updateUserDetails(
    userId: number,
    data: object,
    navigateToUserDetails = true
  ): Promise<object> {
    if (
      this.sessionService.checkPermissions(this.permissions.USERS_EDIT) ||
      userId === this.sessionService.getSession()['id']
    ) {
      try {
        return this.http
          .post(`${environment.backendURL}/users/${userId}`, data, {
            headers: this.httpTools.prepareHeaders(),
          })
          .toPromise();
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
    }
  }

  /**
   * Makes the user a staff user, which gives them access and permissions
   * to everything in the system.
   * @param userId - Id of the user.
   * @returns Promise<any> - Promise that resolves when the user is successfully made a staff user.
   */
  async makeStaffUser(userId: number): Promise<any> {
    try {
      return this.http
        .put(`${environment.backendURL}/users/${userId}/is-staff`, null, {
          headers: this.httpTools.prepareHeaders(),
        })
        .toPromise();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Removes the staff user status from a user.
   * @param userId - Id of the user.
   * @returns Promise<any> - Promise that resolves when the user is successfully removed from staff.
   */
  async removeStaffUserStatus(userId: number): Promise<any> {
    try {
      return this.http
        .delete(`${environment.backendURL}/users/${userId}/is-staff`, {
          headers: this.httpTools.prepareHeaders(),
        })
        .toPromise();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteUser(id: number): Promise<Object> {
    if (
      this.sessionService.checkPermissions(this.permissions.USERS_DELETE) ===
      false
    ) {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
      return;
    }

    try {
      return this.http
        .delete(`${environment.backendURL}/users/${id}`, {
          headers: this.httpTools.prepareHeaders(),
        })
        .toPromise();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createUser(user: object): Promise<void> {
    if (this.sessionService.checkPermissions(this.permissions.USERS_CREATE)) {
      this.httpWrapper.post('/users', user).subscribe(
        (data) => {
          this.selectedUser = data;
          this.stateService.setState(StateKeys.userDetailLoaded, data);
          this.stateService.setState(StateKeys.createSuccessful, '');
          this.stateService.setState(StateKeys.closeUserModal, null);
          this.getUsers();
        },
        (error) => {
          this.stateService.setState(StateKeys.createFailed, {
            formErrors: { username: error['error'].code },
            status: error.status,
            value: user,
          });
        }
      );
    } else {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
    }
  }

  linkUser(user: object): void {
    if (this.sessionService.checkPermissions(this.permissions.USERS_CREATE)) {
      this.httpWrapper.post('/users/add_user_to_service_group', user).subscribe(
        (data) => {
          this.stateService.setState(
            StateKeys.linkSuccessful,
            user['username']
          );
          this.stateService.setState(StateKeys.closeUserModal, null);
          this.getUsers();
        },
        (error) => {
          this.stateService.setState(StateKeys.createFailed, {
            formErrors: { username: error['error'].code },
            status: error.status,
          });
        }
      );
    } else {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
    }
  }

  // Get service units that are available for the given user
  getUserServices() {
    // Check session cache
    const userServices = this.sessionService.getData('userServices');
    const serviceNames = this.sessionService.getData('serviceNames');

    if (userServices && serviceNames) {
      this.stateService.setState(StateKeys.loadedUserServices, userServices);
      return;
    }

    //  Handle the lack of data
    const defaultServices = [];
    this.stateService.setState(StateKeys.loadedUserServices, defaultServices);
  }

  getUsers() {
    const parameters: Array<string> = [];

    // only admin has rights to see user list, exit so don't even try
    if (!this.sessionService.checkPermissions(this.permissions.USERS_VIEW)) {
      return;
    }

    if (this.selectedService !== null) {
      parameters.push('service_id=' + this.selectedService['id'].toString());
    }

    if (this.userSearchTerm !== null) {
      parameters.push('search_term=' + this.userSearchTerm);
    }

    parameters.push('order_by=' + this.order_by);
    parameters.push('page=' + this.page);
    parameters.push('page_size=' + this.page_size);

    return this.httpWrapper
      .get(`/users?${parameters.join('&')}`)
      .then((res: HttpResponse<object>) =>
        this.stateService.setState(StateKeys.userListLoaded, res)
      )
      .catch(() =>
        this.stateService.setState(StateKeys.userListLoadingFailed, '')
      );
  }

  getUserCount() {
    const parameters: Array<string> = [];

    if (this.selectedService !== null) {
      parameters.push('service_id=' + this.selectedService['id'].toString());
    }

    if (this.userSearchTerm !== null) {
      parameters.push('search_term=' + this.userSearchTerm);
    }

    return this.httpWrapper
      .get('/users/count?' + parameters.join('&'))
      .then((result: HttpResponse<object>) => {
        this.stateService.setState(StateKeys.userCountUpdated, result);
      })
      .catch(() => {
        this.stateService.setState(StateKeys.userListLoadingFailed, '');
      });
  }

  /**
   * Get a user by Id.
   * @param id
   */
  async getUser(id: number): Promise<User> {
    try {
      return this.http
        .get(`${environment.backendURL}/users/${id}`, {
          headers: this.httpTools.prepareHeaders(),
        })
        .toPromise() as Promise<User>;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Gets user details
   * @deprecated use getUser instead
   *
   * @param user
   * @param target
   */
  getUserDetails(user: object, target: string) {
    const relativeUrl = '/users/' + user['id'].toString();
    return this.httpWrapper
      .get(relativeUrl)
      .then((res: HttpResponse<object>) => {
        this.selectedDetailedUser = res;

        if (target === 'details') {
          this.router.navigate(['/users/details']);
        }

        if (target === 'profile') {
          this.router.navigate(['profile']);
        }
      })
      .catch(() =>
        this.stateService.setState(StateKeys.userDetailsLoadFailed, '')
      );
  }

  async updateUserMfaSettings(mfaMethod: string, userId: number) {
    const data = {
      session_id: this.sessionService.getSessionId(),
      mfa_method_name: mfaMethod,
    };

    try {
      return this.http
        .put(`${environment.backendURL}/users/${userId}/mfa`, data, {
          headers: this.httpTools.prepareHeaders(),
        })
        .toPromise();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async downloadUsersList(): Promise<Blob> {
    const response = await this.http
      .get(`${environment.backendURL}/users/active/csv`, {
        headers: this.httpTools.prepareHeaders(),
        responseType: 'blob',
      })
      .toPromise();
    return response;
  }
}
