// @ts-nocheck
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpTools } from 'tools/httptools';
import { CacheService } from './cache.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UtilsService } from './utils.service';

const ATTACHMENTS_CACHE_TTL = 1000 * 30; // 30 seconds

export interface AttachmentImage {
  id: number;

  /**
   * Base64 encoded image data. If this is an empty string, the image has been removed and hidden.
   */
  file: string;
  sent_to_emis: boolean;

  /**
   * The file extension of the image. (e.g. '.png', '.jpg', etc.)
   */
  file_extension: string;

  /**
   * The mime type of the image. (e.g. 'image/png', 'image/jpg', etc.)
   */
  mime_type: string;

  url?: string;
}

export interface AttachmentApiResponse {
  id: number;
  images: AttachmentImage[];
}

/**
 * Provides methods for fetching creating, and updating attachments.
 * Attachments refer to files that are attached to messages. (e.g. PDFs, images, etc.)
 */
@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  constructor(
    private http: HttpClient,
    private httpTools: HttpTools,
    private cache: CacheService,
    private utilsService: UtilsService
  ) {}

  private _getURL(messageId: number): string {
    return `${environment.backendURL}/messages/${messageId}/attachments`;
  }

  /**
   * Fetches attachments for a message.
   * Results will be cached based on the messageId.
   * @param messageId The ID of the message to fetch attachments for.
   * @returns An observable that will emit the response from the backend.
   */
  public getAttachments(messageId: number): Observable<AttachmentApiResponse> {
    const cache_key = `attachments_${messageId}`;
    const url = this._getURL(messageId) + '/get';
    const headers = this.httpTools.prepareHeaders();

    // Cached by messageId
    const cached = this.cache.get(cache_key);
    if (cached) return cached;

    const response = this.http
      .get<AttachmentApiResponse>(url, { headers })
      .pipe(
        map((response) => {
          return response;
        }),
        shareReplay(1) // This allows multiple subscriptions to the same observable to share the same response
      );

    // Cache the response
    this.cache.set(cache_key, response, ATTACHMENTS_CACHE_TTL);
    return response;
  }

  /**
   * Marks the attachment as viewed for the given messageId.
   * @param messageId The ID of the message to mark the attachment as viewed for.
   * @returns An observable that will emit the response from the backend.
   */
  public markOpened(messageId: number): Observable<any> {
    const url = this._getURL(messageId);
    const headers = this.httpTools.prepareHeaders();

    return this.http.get(url, { headers });
  }

  /**
   * Gets and formats the images for a given messageId.
   * @param messageId The ID of the message to get images for.
   * @returns An observable that will emit the response from the backend.
   * The response will be formatted to include the image url and file type.
   */
  public getImages(messageId: number): Observable<AttachmentImage[]> {
    return this.getAttachments(messageId).pipe(
      map(
        (response) =>
          response?.images
            .map((image) => {
              return {
                ...image,
                url: this.utilsService.createImgDataURL(image.file),
              } as AttachmentImage;
            })
            .filter((image) => image.file) // Filter out 'hidden' images
      )
    );
  }
}
