<div class="service-group-list-content">
  <div class="content-table">
    <div class="item-wrapper urgency-override">
      <div class="item">
        <i class="fa fa-info-circle"></i>
        <p i18n="@@toSwitchToAnotherPractice">To switch to another practice:</p>
        <ol>
          <li i18n="@@selectThePracticeFrom">
            Select the practice from the left hand column list
          </li>
          <li i18n="@@selectTheUnitsYouWish">
            Select the units you wish to view
          </li>
          <li i18n="@@pressTheSaveButton">Press the ‘Save’ button</li>
          <li i18n="@@confirmThePracticeNameHas">
            *IMPORTANT* Confirm the practice name has changed to the one you
            wish to view on the upper green bar before navigating to the patient
            list. NB- the practice you selected when last logged into ProUI will
            automatically appear when you next log in so it’s important that you
            check the correct practice name is displayed before accessing the
            patient list.
          </li>
        </ol>
      </div>
    </div>
    <table>
      <tbody>
        <tr
          *ngFor="let serviceGroup of serviceGroupList"
          class="service-group-row">
          <!--SERVICE GROUP CELL-->
          <td>
            <div>
              <a [routerLink]="['service-group', serviceGroup.id]">{{
                serviceGroup.name
              }}</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
