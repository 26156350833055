import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from 'guards/permission.guard';
import { SessionGuard } from 'guards/session.guard';
import { Permissions } from 'permissions/permissions.module';
import { AuthenticationComponent } from './authentication/authentication.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DataComponent } from './data/data.component';
import { ReportsComponent } from './reports/reports.component';
import { FeaturesComponent } from './features/features.component';
import { FeatureOverviewComponent } from './feature-overview/feature-overview.component';

import { MyAccountComponent } from './my-account/my-account.component';

import { SettingsComponent } from './settings.component';
import { SmsMessagingComponent } from './sms-messaging/sms-messaging.component';
import { SnoozeTimersComponent } from './snooze-timers/snooze-timers.component';
import { UnitsComponent } from './units/units.component';
import { StaffComponent } from './staff/staff.component';
import { TilesComponent } from './tiles/tiles.component';

export const settingRoutes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    children: [
      {
        path: 'my-account',
        component: MyAccountComponent,
        canActivate: [SessionGuard],
      },
      {
        path: 'patient-form',
        component: SnoozeTimersComponent,
        canActivate: [SessionGuard, PermissionGuard],
        data: {
          permissions: [Permissions.SETTINGS_PATIENT_FORM_VIEW],
        },
      },
      {
        path: 'sms-messaging',
        component: SmsMessagingComponent,
        canActivate: [SessionGuard, PermissionGuard],
        data: {
          permissions: [Permissions.SETTINGS_SMS_TEMPLATES_VIEW],
        },
      },
      {
        path: 'authentication',
        component: AuthenticationComponent,
        canActivate: [SessionGuard],
        // TODO: Add permissions for view
        // data: {
        //   permissions: []
        // }
      },
      {
        path: 'units',
        component: UnitsComponent,
        canActivate: [SessionGuard],
        // TODO: Add permissions for view
        // data: {
        //   permissions: []
        // }
      },
      {
        path: 'features',
        component: FeaturesComponent,
        canActivate: [SessionGuard],
        // TODO: Add permissions for view
        // data: {
        //   permissions: []
        // }
      },
      {
        path: 'features-overview',
        component: FeatureOverviewComponent,
        canActivate: [SessionGuard],
        // TODO: Add permissions for view
        // data: {
        //   permissions: []
        // }
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [SessionGuard],
        // TODO: Add permissions for view
        // data: {
        //   permissions: []
        // }
      },
      {
        path: 'data',
        component: DataComponent,
        canActivate: [SessionGuard, PermissionGuard],
        data: {
          permissions: [Permissions.SETTINGS_DATA],
        },
      },
      {
        path: 'tiles',
        component: TilesComponent,
        canActivate: [SessionGuard],
        // TODO: Add permissions for view
        // data: {
        //   permissions: []
        // }
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [SessionGuard],
        // TODO: Add permissions for view
        // data: {
        //   permissions: []
        // }
      },
      {
        path: 'staff',
        component: StaffComponent,
        canActivate: [SessionGuard],
      },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(settingRoutes);
