import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { environment } from 'environments/environment';
import { StateService } from 'services/state.service';
import { UtilsService } from 'services/utils.service';
import { SymptomsService } from 'services/symptoms.service';
import { ClipboardService } from 'ngx-clipboard';
import { EnquiryParser } from 'app/shared/methods/enquiry/enquiryParser';
import { SessionService } from 'services/session.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import {
  Enquiry,
  EnquiryData,
  EnquiryDataV2,
} from 'app/shared/methods/enquiry/enquiry';
import { MatDialog } from '@angular/material/dialog';
import { SelectCaseDetailsComponent } from 'app/messages/select-case-details/select-case-details.component';
import { IdentityLinkComponent } from 'app/messages/identity-link/identity-link.component';
import { MESSAGE_STATES } from 'services/message.service';
import { CopyContentService } from '../../../shared/services/copy-content.service';

@Component({
  selector: 'app-messages-detail-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent implements OnChanges {
  @Input()
  public message: Enquiry | undefined;

  public enquiryParser: EnquiryParser | undefined;

  public organizedSymptoms = {};
  public descriptionItems: any[] = []; // TODO: type this
  public patientAge: string | null = null;
  public patientSex: string | null = null;
  public objectKeys = Object.keys;
  public symptom_duration_number: number | null = null;
  public symptom_duration_unit: string | null = null;
  public symptom_duration_present = false;

  private readonly translations: Record<string, string> = {
    symptom_hours: $localize`:@@description_symptomDurationHours:`,
    symptom_days: $localize`:@@description_symptomDurationDays:`,
    symptom_weeks: $localize`:@@description_symptomDurationWeeks:`,
    symptom_months: $localize`:@@description_symptomDurationMonths:`,
    male: $localize`:@@esitiedotMies:`,
    female: $localize`:@@esitiedotNainen:`,
    child: $localize`:@@description_esitiedotLapsi:`,
    months: $localize`:@@esitiedotKuukautta:`,
    weeks: $localize`:@@esitiedotViikkoa:`,
    years: $localize`:@@esitiedotVuotias:`,
  };

  constructor(
    public toasterService: ToasterService,
    protected stateService: StateService,
    protected utilsService: UtilsService,
    protected sessionservice: SessionService,
    private symptomsService: SymptomsService,
    public featureFlagService: FeatureFlagService,
    public clipboardService: ClipboardService,
    public dialog: MatDialog,
    public copyContentService: CopyContentService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { message } = changes;

    if (message) {
      this.messageUpdated(message.currentValue);
    }
  }

  /**
   * Handling for when the message input is updated.
   * @param updatedMessage - The updated message from SimpleChanges.
   */
  private messageUpdated(updatedMessage: Enquiry) {
    // Update the enquiryParser based on the message
    this.enquiryParser = new EnquiryParser(
      updatedMessage,
      this.utilsService,
      this.timezone
    );

    this.organizedSymptoms =
      this.symptomsService.addSymptomDisplayNames(updatedMessage);
    if (this.message) {
      this.getAgeAndSex();
      this.generateDescriptionItems();
    }
    this.symptom_duration_number = null;
    this.symptom_duration_unit = null;
    this.symptom_duration_present =
      this.symptomsService.symptomDurationPresent(updatedMessage);
    if (this.symptom_duration_present) {
      this.symptom_duration_number =
        this.symptomsService.getSymptomDurationNumber(updatedMessage);
      this.symptom_duration_unit = this.getSymptomDurationUnit();
    }
  }

  private get timezone(): string {
    return this.sessionservice.timeZone;
  }

  getMessageData(): EnquiryData | EnquiryDataV2 | undefined {
    if (!this.message) return undefined;
    return this.utilsService.ObjectHasKey(this.message, 'data.data_v2')
      ? this.message['data']['data_v2']
      : this.message['data'];
  }

  getAgeAndSex(): void {
    this.patientAge = null;
    this.patientSex = null;
    const data = this.getMessageData();
    const age = this.utilsService.getProperty(data ?? {}, 'personal_info.age');
    const age_number = this.utilsService.getProperty(
      data ?? {},
      'personal_info.age_number'
    );
    const age_unit = this.utilsService.getProperty(
      data ?? {},
      'personal_info.age_unit'
    );
    const sex = this.utilsService.getProperty(data ?? {}, 'personal_info.sex');

    if (age_number && age_unit) {
      this.patientAge = `${age_number} ${this.translations[age_unit]}`;
    } else if (age) {
      this.patientAge = `${age} ` + $localize`:@@esitiedotVuotias:`;
    }

    if (sex) {
      this.patientSex = this.translations[sex];
    }
  }

  generateDescriptionItems(): void {
    this.descriptionItems = [];
    const data = this.getMessageData();
    if (!data) return;

    const items: Record<string, string> = {
      free_text: $localize`:@@description_free_text:`,
      free_text_medication_and_treatment: $localize`:@@description_free_text_medication_and_treatment:`,
      free_text_patient_history_and_status: $localize`:@@description_free_text_patient_history_and_status:`,
    };

    const multiQuestions = this.utilsService.getProperty(
      data ?? {},
      'multi_question'
    );
    if (multiQuestions) {
      multiQuestions.forEach((question: object, index: number) =>
        this.descriptionItems.push({
          question: this.utilsService.getProperty(question, 'question'),
          answer: this.utilsService.getProperty(question, 'answer'),
        })
      );
    }

    Object.keys(items).forEach((key: string) => {
      // todo: this should be refactored to use the Enquiry class
      // and it's freeText getter.
      if (
        this.utilsService.ObjectHasKey(data, `${key}.free_text`) &&
        this.utilsService.ObjectHasKey(data, `${key}.free_text_questions`)
      ) {
        this.descriptionItems.push({
          title: items[key],
          question: this.utilsService.getProperty(
            data,
            `${key}.free_text_questions`
          ),
          answer: this.utilsService.getProperty(data, `${key}.free_text`),
        });
      }
    });
  }

  /**
   * Try copying enquiry details to users clipboard.
   * Show error toaster if copying fails for any reason.
   */
  async tryCopyingEnquiryDetails(): Promise<void> {
    try {
      // todo: This could be refactored to use this.enquiryParser, but
      // in that case the component's tests become problematic.
      const enquiry = new EnquiryParser(
        this.message as Enquiry,
        this.utilsService,
        this.timezone,
        this.featureFlagService.isCopyContentReduced
      );
      // Generate copyData
      const copyData = await this.copyContentService.generateCopyContent(
        enquiry,
        this.featureFlagService.isCopyContentReduced
      );
      // Add copied content to users clipboard
      this.clipboardService.copyFromContent(copyData);
      this.toasterService.pop(
        'success',
        $localize`:@@prerequisitesCopyTitle:`,
        enquiry.name
      );
    } catch (error) {
      console.debug(error);
      this.toasterService.pop(
        'error',
        $localize`:@@errorPleaseTryAgain:An error occurred. Please try again.`
      );
    }
  }

  getSymptomDurationUnit() {
    const durationUnit = this.utilsService.getProperty(
      this.message ?? {},
      'data.data_v2.symptom_duration.duration_unit'
    );

    if (durationUnit) {
      return this.translations[`symptom_${durationUnit}`];
    }

    return '';
  }

  getOpenQuestionTranslation(object: Object, key: string): string {
    const translation = this.utilsService.getFieldTranslation(object, key);
    if (
      translation ===
      environment.translationMissingMessage[this.utilsService.currentLocale]
    ) {
      return this.utilsService.getProperty(object, 'question');
    }
    return translation;
  }

  formatQuestion(question: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = question;
    return textArea.value.replace(/<\/?[^>]+>/gi, '');
  }

  get showIntegrationTrigger(): boolean {
    return (
      this.featureFlagService.isBlackPearTPPEnabled ||
      this.featureFlagService.isBlackPearEmisIntegrationEnabled
    );
  }
  get patientValidationIsValid(): boolean {
    return (
      !!this.message?.patient_validation?.is_valid &&
      this.message?.patient_validation?.practice_code_match
    );
  }
  get hasIdentitySuggestions(): boolean {
    return !!this.message?.data.data_v2?.pds_suggestions;
  }

  get hasConfirmedSuggestion(): boolean {
    return !!this.message?.pds_suggestion_confirmed;
  }

  get canUseIdentitySuggestions(): boolean {
    return (
      this.featureFlagService.isIdentityLinkEnabled &&
      this.hasIdentitySuggestions &&
      !!this.message?.patient_validation?.practice_code_match
    );
  }

  /**
   * Integration button is disabled if the patient validation is not valid and if the user has not confirmed the identity suggestion
   * or if the message is in a new state (there should be  no reason to open the integration modal if the message is new)
   */
  get disableIntegrationButton(): boolean {
    return (
      (!this.patientValidationIsValid && !this.canUseIdentitySuggestions) ||
      this.message?.state === MESSAGE_STATES.NEW
    );
  }

  get blackPearIntegrationButtonTitle(): string {
    const practiceCodeMismatch = $localize`:@@practiceCodeMismatch:Patient registered to unidentified practice please continue with manual process`;
    const noPdsMatch = $localize`:@@noPdsMatch:Patient identity has not been confirmed by PDS check, please continue with manual process`;
    const defaultTitle = $localize`:@@saveToPatientRecord:Save to patient record`;
    const identitySuggestionsTitle = $localize`:@@identitySuggestionsAvailable:Patient identity has not been verified, but we've found a potential match in the patient record.`;
    const caseNotHandledTitle = $localize`:@@saveDisabledCaseNotInProgress:Save to patient record is disabled until case is in progress`;

    if (this.message?.state === MESSAGE_STATES.NEW) {
      return caseNotHandledTitle;
    }

    if (this.patientValidationIsValid || this.hasConfirmedSuggestion) {
      return defaultTitle;
    }

    if (this.canUseIdentitySuggestions) {
      return identitySuggestionsTitle;
    }

    // This message should appear when validation is otherwise valid just the practice code does not match
    if (
      !this.message?.patient_validation?.practice_code_match &&
      this.message?.patient_validation?.is_valid
    ) {
      return practiceCodeMismatch;
    }

    // If no PDS match is found return default value
    return noPdsMatch;
  }

  get integrationButtonText(): string {
    return 'Send details to TPP';
  }
  get integrationButtonIcon(): string {
    return 'share';
  }

  startBlackPearIntegration(): void {
    // Integration can be used if patient validation is valid or if the user has confirmed the identity suggestion
    if (this.patientValidationIsValid || this.hasConfirmedSuggestion)
      return this.openForwardPreview();

    // In some cases we can use the identity suggestions provided by PDS if the patient validation is not valid
    if (!this.canUseIdentitySuggestions) return;
    return this.openIdentityLink();
  }

  openForwardPreview(): void {
    const dialogRef = this.dialog.open(SelectCaseDetailsComponent, {
      data: {
        enquiry: this.message,
      },
      panelClass: 'dialog-no-padding',
      height: '70%',
      width: '70%',
    });
  }

  openIdentityLink(): void {
    const dialogRef = this.dialog.open(IdentityLinkComponent, {
      data: {
        enquiry: this.message,
      },
      width: '70%',
    });
  }

  public get showSeveritySymptomsItem(): boolean {
    return !!this.message?.is_long_appointment;
  }

  public get translatedSeveritySymptoms(): Array<string> {
    return (
      this.enquiryParser?.severitySymptoms?.map((s) =>
        this.utilsService.getNameTranslation(s.symptom)
      ) ?? []
    );
  }
  public get translatedNotselectedSeveritySymptoms(): Array<string> {
    return (
      this.enquiryParser?.notSelectedSeveritySymptoms.map((s) =>
        this.utilsService.getNameTranslation(s.symptom)
      ) ?? []
    );
  }
}
