<section class="settings">
  <div class="settings-container">
    <section class="settings-menu">
      <div class="content">
        <h1 i18n="@@settingsHeader">Settings</h1>

        <nav>
          <a
            [routerLink]="['my-account']"
            routerLinkActive="active"
            i18n="@@userDropdownMyAccount">
            My account
          </a>
        </nav>

        <hr />

        <nav>
          <!-- ng-container is necessary so we can use more than one template binding (starts with *) -->
          <ng-container *ngIf="snoozeEnabled">
            <a
              *hasPermissions="[permissions.SETTINGS_PATIENT_FORM_VIEW]"
              [routerLink]="['patient-form']"
              routerLinkActive="active"
              i18n="@@patientFormSettingsTitle">
              Patient form
            </a>
          </ng-container>

          <a
            *hasPermissions="[permissions.SETTINGS_SMS_TEMPLATES_VIEW]"
            [routerLink]="['sms-messaging']"
            routerLinkActive="active"
            i18n="@@smsMessaging">
            SMS messaging
          </a>

          <!-- Disabled this Autentication from confusing MFA users  -->
          <a
            *ngIf="false"
            [routerLink]="['authentication']"
            routerLinkActive="active"
            i18n="@@authSettingsNavTitle">
            Authentication
          </a>

          <a
            id="tiles-link"
            [routerLink]="['tiles']"
            routerLinkActive="active"
            i18n="@@tiles">
            Tiles
          </a>

          <a
            id="data-reports-link"
            *ngIf="isDataReportsEnabled"
            [routerLink]="['reports']"
            routerLinkActive="active"
            i18n="@@dataReports">
            Data exports
          </a>

          <a
            id="data-requests-link"
            *ngIf="isDataRequestViewEnabled"
            [routerLink]="['data']"
            routerLinkActive="active"
            i18n="@@dataRequests">
            Data
          </a>
        </nav>

        <hr />

        <nav>
          <a
            *ngIf="userIsStaff"
            [routerLink]="['change-password']"
            routerLinkActive="active"
            i18n="@@changePassword">
            Change password
          </a>

          <a
            *ngIf="userIsStaff"
            [routerLink]="['units']"
            routerLinkActive="active"
            i18n="@@asiakkaanTiedotYksikotListaOtsikko">
            Units
          </a>

          <a
            *ngIf="userIsStaff"
            [routerLink]="['features']"
            routerLinkActive="active"
            i18n="@@featuresSettingsTitle">
            Features
          </a>

          <a
            *ngIf="userIsStaff"
            [routerLink]="['features-overview']"
            routerLinkActive="active"
            i18n="@@featureOverviewSettingsTitle">
            Feature Overview
          </a>

          <a
            *ngIf="userIsStaff"
            [routerLink]="['staff']"
            routerLinkActive="active"
            i18n="@@staffSettingsTitle">
            Staff
          </a>
        </nav>
      </div>
    </section>

    <section class="settings-body">
      <ng-container *ngIf="showSettingsIntro">
        <h1 i18n="@@settingsHeader">Settings</h1>
        <p i18n="@@settingsIntro">
          Choose from the navigation bar on the left which settings you would
          like to edit.
        </p>
      </ng-container>

      <router-outlet
        (activate)="showSettingsIntro = false"
        (deactivate)="showSettingsIntro = true"></router-outlet>
    </section>
  </div>
</section>
