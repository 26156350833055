<section class="snooze-timers">
  <h1 i18n="@@patientFormSettingsTitle">Patient form</h1>
  <div class="patient-form-tabs">
    <button
      class="settings-page-nav-button"
      [ngClass]="{ active: activeTab === 'snooze-events' }"
      (click)="switchTab('snooze-events')"
      i18n="@@snoozeEventSettingsTab">
      Timed Snooze events
    </button>
    <button
      class="settings-page-nav-button"
      [ngClass]="{ active: activeTab === 'opening-hours' }"
      (click)="switchTab('opening-hours')"
      i18n="@@openingHoursSettingsTab">
      Opening hours
    </button>
    <button
      class="settings-page-nav-button"
      [ngClass]="{ active: activeTab === 'snooze-history' }"
      (click)="switchTab('snooze-history')"
      i18n="@@snoozeHistorySettingsTab">
      Snooze history
    </button>
  </div>
  <section *ngIf="activeTab === 'snooze-events'">
    <section class="snooze-intro">
      <div class="intro">
        <img
          src="assets/images/out-of-service-dynaform.png"
          alt="Picture of patient form out of service" />
        <div>
          <h2 i18n="@@snoozeEventSettingsTitle">Snooze patient form</h2>
          <p i18n="@@snoozeEventIntroText">
            The Timed Snooze event is intended to be used for one off periods
            when you wish to close access online for patient enquiries e.g for a
            practice learning & training event. So the online platform
            <b>WILL NOT</b> be available for any <b>Timed snooze Events</b> set.
          </p>
          <p i18n="@@snoozeEventIntroText2">
            Snoozing the patient form using a <b>Timed Snooze Event</b> will
            prevent patient enquiries from being submitted online. Patients will
            be greeted with a page explaining that your practice does not allow
            online enquiries to be sent before the end of the currently active
            snooze period. Active snooze periods are visible below, the patient
            form will open automatically at the end of snooze period or at the
            time a snooze event is deleted.
          </p>
          <p i18n="@@snoozeEventIntroText3">
            Please note <b>Timed Snooze Events</b> will <b>over-ride</b> any
            opening hours set in the <b>Opening hours</b> tab.
          </p>
        </div>
      </div>
      <button
        type="button"
        class="btn action"
        (click)="addNew = !addNew"
        [disabled]="!hasEditPermission">
        <span i18n="@@addNewSnoozeEvent">Add new Snooze event +</span>
      </button>
    </section>

    <section class="snooze-creation" *ngIf="addNew">
      <section *ngIf="!showSummary">
        <h3 id="event-time" i18n="@@timedSnoozeEventTitle">
          Select snooze event time
        </h3>
        <app-notification [icon]="'exclamation-circle'">
          {{ snoozeDisclaimer }}
        </app-notification>

        <app-common-datepicker></app-common-datepicker>
      </section>

      <section *ngIf="showSummary">
        <h3 i18n="@@snoozeTimerOverview">Overview</h3>
        <p i18n="@@timedSnoozeEventType">Type: Timed snooze event</p>
        <span class="start-time">
          <i class="fa fa-eye-slash fa-flip-horizontal" aria-hidden="true"></i>
          <span i18n="@@snoozeTimerStart">Start</span>:
          {{ startDate.format('LLL') }} </span
        ><br />
        <span class="end-time">
          <i class="fa fa-eye" aria-hidden="true"></i>
          <span i18n="@@snoozeTimerEnd">End</span>: {{ endDate.format('LLL') }}
        </span>
      </section>

      <div class="button-container button-right">
        <button
          class="btn cancel"
          (click)="clearSelections()"
          i18n="@@snoozeTimerCancelButton">
          Cancel
        </button>
        <button
          *ngIf="!showSummary"
          class="btn action"
          (click)="validateTimer(true)"
          i18n="@@continue">
          Continue
        </button>
        <button
          *ngIf="showSummary"
          class="btn action"
          (click)="sendTimer(false)"
          i18n="@@snoozeTimerCreateButton">
          Create event
        </button>
      </div>
    </section>

    <section class="snooze-list" *ngIf="timers">
      <div *ngFor="let timer of programmedTimers()" class="single-timer">
        <div class="timer-info">
          <h4 i18n="@@timedSnoozeEvent">Timed snooze event</h4>
          <span class="start-time">
            <i
              class="fa fa-eye-slash fa-flip-horizontal"
              aria-hidden="true"></i>
            <span i18n="@@snoozeTimerStart">Start</span>:
            {{ formatDate(timer, 'start') }} </span
          ><br />
          <span class="end-time">
            <i class="fa fa-eye" aria-hidden="true"></i>
            <span i18n="@@snoozeTimerEnd">End</span>:
            {{ formatDate(timer, 'end') }}
          </span>
        </div>
        <button
          class="btn delete"
          (click)="promptDelete(timer)"
          [disabled]="!hasEditPermission">
          <span>
            <i class="fa fa-trash" aria-hidden="true"></i>
            <span i18n="@@snoozeTimerDelete">Delete event</span>
          </span>
        </button>
      </div>
    </section>
  </section>
  <section *ngIf="activeTab === 'opening-hours'">
    <section class="snooze-intro">
      <div class="intro">
        <div>
          <h2 i18n="@@openingHoursSettingsTab">Opening hours</h2>
          <p i18n="@@openingHoursIntroText">
            Whilst the online service is intended to be accessible by patients
            24/7, you can set specific times when the online patient form
            <b>WILL BE</b> available. e.g you may wish to close off access over
            weekends. Therefore you could select e.g. Mon-Fri and 00:00- 00:00.
          </p>
          <p i18n="@@openingHoursIntroText2">
            The times set are repeated weekly, and the form will not be
            available to users outside the hours defined. If there are no
            <b>Opening Hours</b> defined, the form will always be open 24/7. The
            <b>Opening Hours</b> will continue to allow access online inside of
            the defined hours until the event is deleted or a
            <b>Timed Event</b> is set which will over-ride the
            <b>Opening Hours</b>.
          </p>
          <p i18n="@@openingHoursIntroText3">
            Active <b>Opening Hours</b> are visible below. Access to the online
            patient form will close automatically at the end of the set time on
            any given day.
          </p>
          <p i18n="@@openingHoursIntroText4">
            Please note that <b>Timed Snooze Events</b> will over-ride any
            opening hours you set here.
          </p>
        </div>
      </div>
      <button
        class="btn action"
        (click)="addNew = !addNew"
        [disabled]="!hasEditPermission"
        i18n="@@addNewOpeningHoursEvent">
        Add new Opening hours +
      </button>
    </section>

    <section class="snooze-creation" *ngIf="addNew">
      <section *ngIf="!showSummary">
        <h3 i18n="@@openingHoursEventTitle">Recurrent opening hours</h3>
        <h4 i18n="@@recurrentSnoozeRepeat">Repeat every</h4>
        <div class="weekdays">
          <span
            *ngFor="let day of weekDays; let i = index"
            (click)="toggleDay(i)"
            [ngClass]="{ 'weekday-selected': this.recurrentDays.includes(i) }">
            {{ day[0] }}
          </span>
        </div>
        <h4 i18n="@@recurrentSnoozeTime">Start & end time</h4>
        <div class="time-input">
          <span i18n="@@snoozeTimerStart">Start</span>
          <input
            type="text"
            class="time-input"
            [ngClass]="{ 'footer-input--invalid': timeInvalid['start'] }"
            (blur)="manualInputTime($event, 'start')"
            [ngModel]="startDate.format('HH:mm')" />
          <span i18n="@@snoozeTimerEnd">End</span>
          <input
            type="text"
            class="time-input"
            [ngClass]="{ 'footer-input--invalid': timeInvalid['end'] }"
            (blur)="manualInputTime($event, 'end')"
            [ngModel]="endDate.format('HH:mm')" />
        </div>
      </section>

      <section *ngIf="showSummary">
        <h3 i18n="@@snoozeTimerOverview">Overview</h3>
        <!-- todo: openingHoursEventType localisation does not exist. Split "Type" and "Opening hours" to their own keys? -->
        <p i18n="@@openingHoursEventType">Type: Opening hours</p>
        <p>
          <span i18n="@@recurrentSnoozeRepeat">Repeat every</span>:
          {{ formatDays() }}
        </p>
        <span class="start-time">
          <i class="fa fa-eye-slash fa-flip-horizontal" aria-hidden="true"></i>
          <span i18n="@@snoozeTimerStart">Start</span>:
          {{ startDate.format('HH:mm') }} </span
        ><br />
        <span class="end-time">
          <i class="fa fa-eye" aria-hidden="true"></i>
          <span i18n="@@snoozeTimerEnd">End</span>:
          {{ endDate.format('HH:mm') }}
        </span>
      </section>

      <div class="button-container button-right">
        <button
          class="btn cancel"
          (click)="clearSelections()"
          i18n="@@snoozeTimerCancelButton">
          Cancel
        </button>
        <button
          *ngIf="!showSummary"
          class="btn action"
          (click)="validateTimer(false)"
          i18n="@@continue"
          [disabled]="recurrentDays.length == 0">
          Continue
        </button>
        <button
          *ngIf="showSummary"
          class="btn action"
          (click)="sendTimer(true)"
          i18n="@@snoozeTimerCreateButton">
          Create event
        </button>
      </div>
    </section>

    <section class="snooze-list" *ngIf="timers">
      <div
        *ngFor="let timer of recurringTimersActive()"
        class="recurrent-timer">
        <div class="timer-info">
          <h4 i18n="@@openingHoursEvent">Opening hours</h4>
          <div class="body">
            <div class="time">
              <span class="start-time">
                <i
                  class="fa fa-eye-slash fa-flip-horizontal"
                  aria-hidden="true"></i>
                <span i18n="@@snoozeTimerStart">Start</span>:
                {{ formatTime(timer, 'start') }} </span
              ><br />
              <span class="end-time">
                <i class="fa fa-eye" aria-hidden="true"></i>
                <span i18n="@@snoozeTimerEnd">End</span>:
                {{ formatTime(timer, 'end') }}
              </span>
            </div>
            <div class="info-weekdays">
              <span
                *ngFor="let day of weekDays; let i = index"
                [ngClass]="{
                  'weekday-selected': timer['recurring_event'].indexOf(i) !== -1
                }">
                *
              </span>
              <span
                *ngFor="let day of weekDays; let i = index"
                [ngClass]="{
                  'weekday-selected': timer['recurring_event'].indexOf(i) !== -1
                }">
                {{ day[0] }}
              </span>
            </div>
            <div class="toggle">
              <label class="switch">
                <input
                  type="checkbox"
                  (click)="toggleTimer(timer)"
                  [disabled]="!hasEditPermission"
                  checked
                  id="deactivate-timer" />
                <span class="slider"></span>
              </label>
            </div>
          </div>
        </div>
        <button
          class="btn delete"
          (click)="promptDelete(timer)"
          [disabled]="!hasEditPermission">
          <span>
            <i class="fa fa-trash" aria-hidden="true"></i>
            <span i18n="@@delete">Delete</span>
          </span>
        </button>
      </div>
      <h4
        *ngIf="recurringTimersInactive().length > 0"
        i18n="@@openingHoursInactive">
        Inactive opening hours
      </h4>
      <div
        *ngFor="let timer of recurringTimersInactive()"
        class="recurrent-timer inactive">
        <div class="timer-info">
          <h4 i18n="@@openingHoursEvent">Opening hours</h4>
          <div class="body">
            <div class="time">
              <span class="start-time">
                <i
                  class="fa fa-eye-slash fa-flip-horizontal"
                  aria-hidden="true"></i
                ><span i18n="@@snoozeTimerStart">Start</span>:
                {{ formatTime(timer, 'start') }} </span
              ><br />
              <span class="end-time">
                <i class="fa fa-eye" aria-hidden="true"></i>
                <span i18n="@@snoozeTimerEnd">End</span>:
                {{ formatTime(timer, 'end') }}
              </span>
            </div>
            <div class="info-weekdays">
              <span *ngFor="let day of weekDays; let i = index">
                <ng-container *ngIf="timer['recurring_event'].indexOf(i) !== -1"
                  >*</ng-container
                >
              </span>
              <span *ngFor="let day of weekDays; let i = index">
                {{ day[0] }}
              </span>
            </div>
            <div class="toggle">
              <label class="switch">
                <input
                  type="checkbox"
                  (click)="toggleTimer(timer)"
                  [disabled]="!hasEditPermission"
                  id="activate-timer" />
                <span class="slider"></span>
              </label>
            </div>
          </div>
        </div>
        <button
          class="btn delete"
          (click)="promptDelete(timer)"
          [disabled]="!hasEditPermission">
          <span>
            <i class="fa fa-trash" aria-hidden="true"></i>
            <span i18n="@@delete">Delete</span>
          </span>
        </button>
      </div>
    </section>
  </section>
  <section *ngIf="activeTab === 'snooze-history'" class="audit-logs">
    <h2 i18n="@@snoozeHistorySettingsTitle">
      Event history
      <app-loading *ngIf="isLoadingSnoozeEvents"></app-loading>
    </h2>
    <table class="snooze-history-table">
      <tr>
        <th i18n="@@snoozeHistoryTableCreatedAtHeader">Created at</th>
        <th i18n="@@snoozeHistoryTableEventTypeHeader">Event type</th>
        <th i18n="@@snoozeHistoryTableActorHeader">Actor</th>
        <th i18n="@@snoozeHistoryTableDataHeader">Time</th>
      </tr>
      <ng-container *ngFor="let event of auditEvents">
        <tr *ngIf="event['actor']" id="auditEventRows">
          <td>{{ auditEventCreation(event['created_at']) }}</td>
          <td>{{ auditEventAction(event['type']) }}</td>
          <td>{{ auditEventActor(event['actor']) }}</td>
          <td>{{ auditEventTimer(event['data']) }}</td>
        </tr>
      </ng-container>
    </table>

    <div class="pagination-wrapper" *ngIf="totalAuditEventCount > pageSize">
      <pagination
        [itemsPerPage]="pageSize"
        [totalItems]="totalAuditEventCount"
        [(ngModel)]="page"
        [maxSize]="maxSize"
        class="pagination-sm"
        [boundaryLinks]="false"
        [previousText]="previousIcon"
        [nextText]="nextIcon"
        (pageChanged)="snoozeHistoryPageChange($event)">
      </pagination>
    </div>
  </section>

  <app-delete-confirmation-popup
    *ngIf="showDeletePopup"
    [items]="snoozeTimerDeleteItem"
    [translations]="activeTab"
    [disabled]="hasEditPermission === false"
    (deleteConfirmationEmitter)="deleteTimer($event)">
  </app-delete-confirmation-popup>
</section>
