// @ts-nocheck
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SessionService } from 'services/session.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { FeedbackService } from 'services/feedback.service';
import { Subscription } from 'rxjs';
import { StateService, StateKeys } from 'services/state.service';

@Component({
  selector: 'user-satisfaction',
  templateUrl: './user-satisfaction.component.html',
  styleUrls: ['./user-satisfaction.component.scss'],
  animations: [
    trigger('fade-in-feedback', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('0.7s ease-out', style({ opacity: 1, height: '5em' })),
      ]),
    ]),
    trigger('fade-in-feedback-buttons', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('0.7s ease-out', style({ opacity: 1, height: '50px' })),
      ]),
    ]),
  ],
})
export class UserSatisfactionComponent implements OnInit, OnDestroy {
  @Output()
  private surveyEmitter: EventEmitter<void> = new EventEmitter();

  public askSurvey = false;
  public feedbackValue: string = null;
  public surveyGrade: number = null;
  public additionalFeedback = false;
  private session = this.sessionService.getSession();
  private stateChangeSubscription: Subscription;

  public smileys = [
    { visible: true, hidden: false, grade: 5, label: 'amazing' },
    { visible: true, hidden: false, grade: 4, label: 'good' },
    { visible: true, hidden: false, grade: 2, label: 'bad' },
    { visible: true, hidden: false, grade: 1, label: 'terrible' },
  ];

  constructor(
    private sessionService: SessionService,
    private feedbackService: FeedbackService,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.surveyGrade = null;
    this.feedbackValue = null;
    this.checkDate();

    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.deleteSession:
            this.askSurvey = false;
            this.session = null;
        }
      }
    );
  }
  ngOnDestroy(): void {
    if (this.stateChangeSubscription) {
      this.stateChangeSubscription.unsubscribe();
    }
  }

  selectGrade(selectedSmiley: any) {
    this.surveyGrade = selectedSmiley['grade'];

    this.smileys.map((smiley: any) => {
      if (smiley === selectedSmiley) {
        smiley.visible = true;
      } else {
        smiley.visible = false;
      }
    });
  }

  checkDate() {
    if (
      !this.session ||
      (this.session['kip_onboarded'] === null &&
        this.sessionService.isEmisIntegrationEnabled)
    ) {
      return;
    }
    const surveyString = this.session['last_survey_date'];
    const surveyDate = new Date(surveyString);
    const dayCounter = Math.floor(
      (new Date().getTime() - surveyDate.getTime()) / 1000 / 60 / 60 / 24
    );

    if (
      this.session['service_group'].feedback_survey_enabled === true &&
      (dayCounter >= 30 || surveyDate === null)
    ) {
      this.askSurvey = true;
    }
  }

  async postSurveyData() {
    const data = {
      grade: this.surveyGrade,
      feedback: this.feedbackValue ? this.feedbackValue : '',
    };

    await this.feedbackService
      .postFeedbackSurvey(data)
      .then(() => (this.askSurvey = false));
    this.toggleSurvey();
  }

  toggleSurvey(): void {
    this.surveyEmitter.emit();
  }
}
