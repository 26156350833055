import { formatDateToLocale } from 'app/utils/dateUtils';
import { UtilsService } from 'services/utils.service';
import { getNestedValue } from '../getNestedValue';
import { Enquiry, EnquiryDynamicField } from './enquiry';

/**
 * This class is used for visualization purposes.
 * The key is expected to be a localized string such as a "Date of birth" and a value such as "Feb 21st 2024".
 */
export class ParsedItem {
  key: any;
  value: string | null;

  constructor(key: any, value: string | null) {
    this.key = key;
    this.value = value;
  }

  /**
   * @returns A string representation of the parsed item in the format "key: value".
   */
  toString(): string {
    return `${this.key}: ${this.value}`;
  }

  /**
   * @returns A string representation of the parsed item in the format "KEY: value, or, in the case of
   *  reduced copy, return strings as they come, without formatting to upper case\n".
   */
  toCopyItem(reducedCopyContent: boolean): string {
    return reducedCopyContent
      ? `${this.key}: ${this.value}\n`
      : `${this.key.toUpperCase()}: ${this.value}\n`;
  }
}

/**
 * EnquiryParser extends Enquiry and provides additional functions for parsing sets of information from an Enquiry.
 * */
export class EnquiryParser extends Enquiry {
  // Cached values of the data sets
  public personalInfoItems: Array<ParsedItem>;
  public symptomDetailItems: Array<ParsedItem>;
  public symptomItems: Array<ParsedItem>;

  /**
   * Parsed item with the severity symptoms as comma-separated string.
   */
  public severitySymptomsItem: ParsedItem | undefined;
  public previousTreatmentItems: Array<ParsedItem>;
  public medicalQuestionItems: Array<ParsedItem>;
  public dynamicFieldItems: Array<ParsedItem>;
  public commentItems: Array<ParsedItem>;
  public notSelectedSeveritySymptomsItem: ParsedItem | undefined;
  enquiry: Enquiry;

  /**
   *
   * @param enquiry - Enquiry object
   * @param utilsService
   * @param timezone
   * @param reducedCopyContent - If true, the copy data will be reduced to only contain the most important information.
   */
  constructor(
    enquiry: Enquiry,
    private utilsService: UtilsService,
    private timezone: string,
    private reducedCopyContent: boolean = false
  ) {
    // Initiate Enquiry constructor
    super(enquiry, utilsService.getCurrentLocale());
    this.enquiry = enquiry;

    // Generate data sets and cache results
    this.personalInfoItems = this._personalInfoItems();
    this.symptomDetailItems = this._symptomDetailItems();
    this.symptomItems = this._symptomItems();
    this.severitySymptomsItem = this._severitySymptomsItem();
    this.previousTreatmentItems = this._previousTreatmentItems();
    this.medicalQuestionItems = this._medicalQuestionItems();
    this.dynamicFieldItems = this._dynamicFieldItems();
    this.commentItems = this._commentItems();
    this.notSelectedSeveritySymptomsItem =
      this._notSelectedSeveritySymptomsItem();
  }

  /**
   * Generates a parsed item that contains the not selected severity symptoms as a string.
   */
  private _notSelectedSeveritySymptomsItem(): ParsedItem | undefined {
    if (
      this.notSelectedSeveritySymptoms === undefined ||
      this.notSelectedSeveritySymptoms === null ||
      this.notSelectedSeveritySymptoms?.length === 0
    )
      return undefined;

    const translatedSymptoms = this.notSelectedSeveritySymptoms.map((symptom) =>
      this.utilsService.getNameTranslation(symptom.symptom)
    );

    const translatedSymptomsString = translatedSymptoms.join(', ');
    return new ParsedItem(
      $localize`:@@notSelectedSeveritySymptoms:No signs of`,
      translatedSymptomsString
    );
  }

  /**
   * Returns an array of parsed items containing the personal information of the enquiry.
   * Personal information contains fields such as the patient's name, telephone, email, nhs_number, date of birth, ...
   */
  private _personalInfoItems(): Array<ParsedItem> {
    const items = new Array<ParsedItem>();

    if (this.name) {
      items.push(new ParsedItem($localize`:@@name:Name`, this.name));
    }

    if (this.telephone) {
      items.push(
        new ParsedItem(
          $localize`:@@moveToRecordPreviewPhone:Phone`,
          this.telephone
        )
      );
    }

    if (this.email) {
      items.push(
        new ParsedItem($localize`:@@moveToRecordPreviewEmail:Email`, this.email)
      );
    }

    items.push(this.nhsNumberItem);

    if (this.created_at) {
      items.push(
        new ParsedItem(
          $localize`:@@moveToRecordPreviewSentTime:Sent time`,
          formatDateToLocale(this.created_at, 'LLL', this.timezone)
        )
      );
    }

    items.push(this.caseIdentifierItem, this.dateOfBirthItem);

    return items.filter((item) => item !== null && item.value !== null);
  }

  private _symptomDetailItems(): Array<ParsedItem> {
    const arr = new Array<ParsedItem>();

    if (Object.keys(this.bodyParts || {}).length > 0) {
      arr.push(
        new ParsedItem(
          $localize`:@@esitiedotVaivanSijainti:Location of the ailment`,
          this.utilsService.getNameTranslation(this.bodyParts)
        )
      );
    }

    if (this.symptomDurationNumber && this.symptomDurationUnit) {
      arr.push(
        new ParsedItem(
          $localize`:@@description_symptomDuration:Symptom Duration`,
          `${this.symptomDurationNumber} ${this.symptomDurationUnit}`
        )
      );
    }

    return arr;
  }

  private _symptomItems(): Array<ParsedItem> {
    const arr = new Array<ParsedItem>();

    for (const symptomKey in this.organized_symptoms) {
      const symptom = this.organized_symptoms[symptomKey];
      const symptomDescription = getNestedValue(
        this.symptomDescriptions,
        `${symptom.id}`
      );

      arr.push(
        new ParsedItem(
          symptom.displayName,
          symptomDescription ? symptomDescription : '-'
        )
      );
    }

    return arr;
  }

  /**
   * Returns a parsed item with the severity symptoms as comma-separated string.
   */
  private _severitySymptomsItem(): ParsedItem | undefined {
    const severitySymptoms = this.dataV2?.severity_symptoms?.severity_symptoms;

    // Severity symptoms not asked
    if (severitySymptoms == null || severitySymptoms == undefined) {
      return undefined;
    }

    // Severity symptoms asked, but not selected.
    if (severitySymptoms.length === 0) {
      return new ParsedItem(
        `${$localize`:@@esitiedotKiireellisyysoireet:Severity symptoms`}`,
        $localize`:@@esitiedotEiKiireellisyysoireita:No severity symptoms`
      );
    }

    // Get translations for all the severity symptoms
    const translatedSeveritySymptoms = severitySymptoms.map((severitySymptom) =>
      this.utilsService.getNameTranslation(severitySymptom.symptom)
    );

    // Finally, return the parsed item with the severity symptoms joined by a comma.
    return new ParsedItem(
      `${$localize`:@@esitiedotKiireellisyysoireet:Severity symptoms`}`,
      translatedSeveritySymptoms.join(', ')
    );
  }

  private _previousTreatmentItems(): Array<ParsedItem> {
    const arr = new Array<ParsedItem>();

    if (this.hasPreviousOtherTreatment) {
      arr.push(
        new ParsedItem(
          this.reducedCopyContent
            ? this.previousOtherTreatmentQuestionKeyword
            : this.previousOtherTreatmentQuestion,
          this.previousOtherTreatmentDescription
        )
      );
    }

    if (this.hasPreviousSelfTreatment) {
      arr.push(
        new ParsedItem(
          this.reducedCopyContent
            ? this.previousSelfTreatmentQuestionKeyword
            : this.previousSelfTreatmentQuestion,
          this.previousSelfTreatmentDescription
        )
      );
    }

    return arr;
  }

  private _medicalQuestionItems(): Array<ParsedItem> {
    const arr = new Array<ParsedItem>();

    if (this.hasMultiQuestions) {
      this.multiQuestions.forEach((question) => {
        arr.push(new ParsedItem(question.question, question.answer));
      });
    }

    if (this.openQuestions) {
      this.openQuestions.forEach((question) => {
        arr.push(new ParsedItem(question.question, question.value));
      });
    }

    if (this.data?.appointment_type_questions?.length > 0) {
      if (this.reducedCopyContent && this.enquiry.is_short_appointment) {
        // only for short appointment type
        arr.push(
          new ParsedItem(
            $localize`:@@freeTextShortAppointmentTypeQuestionKeyword:Request`,
            this.data?.appointment_type_questions[0]?.answer
          )
        );
      } else {
        this.data?.appointment_type_questions.forEach((question) => {
          arr.push(new ParsedItem(question.question, question.answer));
        });
      }
    }

    return arr;
  }

  private _dynamicFieldItems(): Array<ParsedItem> {
    const arr = new Array<ParsedItem>();

    const dynamicFieldKeys = Object.keys(this.dynamicFields || {});
    for (const key of dynamicFieldKeys) {
      if (key === 'translation_keys') {
        continue;
      }

      const dynamicField = this.dynamicFields?.[key] as EnquiryDynamicField;
      arr.push(
        new ParsedItem(
          this.utilsService.getNameTranslation(dynamicField),
          dynamicField.value
        )
      );
    }
    return arr;
  }

  private _commentItems(): Array<ParsedItem> {
    const arr: Array<ParsedItem> = new Array();

    this.comments?.forEach((comment) => {
      let commentTitle = formatDateToLocale(
        comment.created_at,
        'LL',
        this.timezone
      );
      commentTitle += ` ${comment.user_name ? comment.user_name : '-'}`;

      let commentContent = comment.content;
      if (comment.service_from && comment.service_to) {
        // service_from and service_to can have additional spaces returned from backend
        commentContent += ` (${comment.service_from} -> ${comment.service_to})`;
      }

      arr.push(new ParsedItem(commentTitle, commentContent));
    });

    return arr;
  }

  /**
   * Returns the free_text component's question and answer as a ParsedItem,
   * or null if the the data is not available.
   */
  public get freeTextItem(): ParsedItem | null {
    if (!this.freeText) {
      return null;
    }

    return new ParsedItem(
      this.reducedCopyContent
        ? this.freeTextQuestionKeyword
        : this.freeText.free_text_questions,
      this.freeText.free_text
    );
  }

  /**
   * Returns a ParsedItem containing the date of birth of the patient.
   */
  public get dateOfBirthItem(): ParsedItem {
    const value = this.dateOfBirth
      ? formatDateToLocale(this.dateOfBirth, 'L', this.timezone, true)
      : null; // Do no attempt to format missing date of birth

    return new ParsedItem($localize`:@@dateOfBirth:Date of birth`, value);
  }

  /**
   * Returns a ParsedItem containing the NHS number of the patient.
   */
  public get nhsNumberItem(): ParsedItem {
    return new ParsedItem($localize`:@@nhsNumber:NHS number`, this.nhs_number);
  }

  /**
   * Returns a ParsedItem containing the case identifier (short UUID) of the enquiry.
   */
  public get caseIdentifierItem(): ParsedItem {
    return new ParsedItem(
      $localize`:@@viestinAsiakasAsiointitunnus:Case identifier`,
      this.shortUuid
    );
  }

  public get formSubmittedAtItem(): ParsedItem {
    const value = this.form_submitted_at
      ? formatDateToLocale(this.form_submitted_at, 'LLLL', this.timezone)
      : null; // Do no attempt to format missing form submitted at

    return new ParsedItem(
      $localize`:@@viestinAsiakasLahetysaika:Sent time`,
      value
    );
  }

  generateEnquiryDetailsCopyData(): string {
    // Gather items to be added to the copy data
    const basicItems = new Array<ParsedItem>();

    basicItems.push(this.nhsNumberItem);

    if (!this.reducedCopyContent) {
      basicItems.push(
        this.dateOfBirthItem,
        this.caseIdentifierItem,
        this.formSubmittedAtItem
      );
    }

    // Gather different sets of data that might or might not be present
    const enquiryDataSets: Array<Array<ParsedItem>> = [
      basicItems,
      !this.reducedCopyContent ? this.dynamicFieldItems : [],
      this.symptomDetailItems,
      this.symptomItems,
      this.severitySymptomsItem ? [this.severitySymptomsItem] : [],
      !this.reducedCopyContent && this.notSelectedSeveritySymptomsItem
        ? [this.notSelectedSeveritySymptomsItem]
        : [],
      this.previousTreatmentItems,
      this.medicalQuestionItems,
      this.freeTextItem ? [this.freeTextItem] : [],
    ];

    let copyData = '';

    for (const dataSet of enquiryDataSets) {
      // If no data, skip it.
      if (!dataSet) {
        continue;
      }

      // Loop through each item and append to copyData
      for (const item of dataSet) {
        if (item.key && item.value !== null) {
          copyData += item.toCopyItem(this.reducedCopyContent);
        }
      }
    }

    // Finally append "copied from..." to end of copied content
    if (!this.reducedCopyContent) {
      copyData += `${$localize`:@@copiedFromKlinikPro:Copied from Klinik Access`}`;
    }

    return copyData;
  }
}
