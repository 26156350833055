// @ts-nocheck
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-confirmation-popup',
  templateUrl: './delete-confirmation-popup.component.html',
  styleUrls: ['./delete-confirmation-popup.component.scss'],
})
export class DeleteConfirmationPopupComponent {
  @Input() public items: DeleteItem[] = [];
  @Input() public disabled: boolean = true;
  @Input() set translations(prefix: string) {
    this.title = this.translationStore[`${prefix}`].title;
    this.cancel = this.translationStore[`${prefix}`].cancel;
    this.delete = this.translationStore[`${prefix}`].delete;
  }

  @Output() private deleteConfirmationEmitter: EventEmitter<boolean> =
    new EventEmitter();

  public title: string;
  public cancel: string;
  public delete: string;

  private readonly translationStore = {
    smsTemplateDelete: {
      title: $localize`:@@smsTemplateDeleteTitle:`,
      cancel: $localize`:@@smsTemplateDeleteCancel:`,
      delete: $localize`:@@smsTemplateDelete:`,
    },
    smsTemplateDeleteAll: {
      title: $localize`:@@smsTemplateDeleteAllTitle:`,
      cancel: $localize`:@@smsTemplateDeleteCancel:`,
      delete: $localize`:@@confirmButton:`,
    },
    'snooze-events': {
      title: $localize`:@@snoozeTimerDeleteConfirmation:`,
      cancel: $localize`:@@snoozeTimerDeleteCancel:`,
      delete: $localize`:@@snoozeTimerDelete:`,
    },
    'opening-hours': {
      title: $localize`:@@openingHoursDeleteConfirmation:`,
      cancel: $localize`:@@snoozeTimerDeleteCancel:`,
      delete: $localize`:@@snoozeTimerDelete:`,
    },
  };

  emit(value: boolean): void {
    this.deleteConfirmationEmitter.emit(value);
  }
}
