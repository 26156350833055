<section *ngIf="message">
  <section
    class="detail-analysis"
    *ngIf="message.data.diagnosis_suggestions?.length > 0">
    <h2 i18n="@@viestinAnalysointiOtsikko">Järjestelmän suositukset</h2>
    <div class="item-wrapper urgency-override">
      <div class="item">
        <h4 i18n="@@viestinAnalysointiKiireellisyys">
          Vastaanottoajan kiireellisyys
        </h4>
        <span
          id="body-content-urgency-self-care"
          *ngIf="message.priority === -1"
          i18n="@@viestinAnalysointiKiireellisyysItsehoidollinen">
          Kiireetön tai soveltuu itsehoitoon
        </span>
        <span
          id="body-content-urgency-non-urgent"
          *ngIf="message.priority === 0"
          i18n="@@viestinAnalysointiKiireellisyysNormaali">
          Kiireetön
        </span>
        <span id="body-content-urgency-urgent" *ngIf="message.priority === 1">
          <i
            class="fa fa-exclamation-circle"
            style="color: #f89a00"
            aria-hidden="true"></i>
          <ng-container i18n="@@viestinAnalysointiKiireellisyysKiireellinen">
            Kiireellinen
          </ng-container>
        </span>
        <span
          id="body-content-urgency-emergency"
          *ngIf="message.priority === 2">
          <i
            class="fa fa-exclamation-triangle"
            style="color: red"
            aria-hidden="true"></i>
          <ng-container i18n="@@viestinAnalysointiKiireellisyysPäivystys">
            Päivystys
          </ng-container>
        </span>
      </div>
    </div>

    <div class="group-wrapper">
      <h5 class="suggestion_sub_heading" i18n="@@viestinAnalysointiSelitys">
        Valittuun sijaintiin ja oireisiin liittyviä todennäköisiä vaivoja:
      </h5>

      <div class="items" *ngIf="diagnoses">
        <div class="item-wrapper" *ngFor="let diag of diagnoses">
          <div class="item">
            <div class="name-and-score">
              <h4 title="{{ utilsService.getNameTranslation(diag) }}">
                {{ utilsService.getNameTranslation(diag) }}
              </h4>
              <p class="item-score" *ngIf="showScores">
                {{ diag.score | number: '2.0-0' }} %
              </p>
            </div>

            <a
              *ngIf="
                diag.visible_klinik_fi && utilsService.currentLocale == 'fi'
              "
              class="klinikfi_article"
              target="_blank"
              href="https://klinik.fi/terveysinfo/{{
                utilsService.getProperty(
                  diag,
                  'translations.' + utilsService.currentLocale + '.slug'
                )
              }}"
              i18n="@@viestinAnalysointiLinkkiArtikkeliin">
              Lue lisätietoa
            </a>
            <div>
              <h5
                *ngIf="diag.hasOwnProperty('duration')"
                i18n="@@viestinAnalysointiVastaanotto">
                Vastaanottoajan pituus
              </h5>
              <span
                *ngIf="diag.duration == 0"
                i18n="@@viestinAnalysointiVastaanottoLyhyt">
                Lyhyt
              </span>
              <span
                *ngIf="diag.duration == 1"
                i18n="@@viestinAnalysointiVastaanottoNormaali">
                Normaali
              </span>
              <span
                *ngIf="diag.duration == 2"
                i18n="@@viestinAnalysointiVastaanottoPitkä">
                Pitkä
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="group-wrapper">
        <h5
          *ngIf="other_suggestions.length > 0"
          class="suggestion_sub_heading"
          i18n="@@viestinAnalysointiMuutVaivat">
          Muita mahdollisia vaivoja:
        </h5>
        <div class="items">
          <div class="item-wrapper" *ngFor="let diag of other_suggestions">
            <div class="item">
              <div class="name-and-score">
                <h4 title="{{ utilsService.getNameTranslation(diag) }}">
                  {{ utilsService.getNameTranslation(diag) }}
                </h4>
                <p class="item-score" *ngIf="showScores">
                  {{ diag.score | number: '2.0-0' }} %
                </p>
              </div>
              <a
                *ngIf="
                  diag.visible_klinik_fi && utilsService.currentLocale == 'fi'
                "
                class="klinikfi_article"
                target="_blank"
                href="https://klinik.fi/terveysinfo/{{
                  utilsService.getProperty(
                    diag,
                    'translations.' + utilsService.currentLocale + '.slug'
                  )
                }}"
                i18n="@@viestinAnalysointiLinkkiArtikkeliin">
                Lue lisätietoa
              </a>
              <div>
                <h5
                  *ngIf="diag.hasOwnProperty('duration')"
                  i18n="@@viestinAnalysointiVastaanotto">
                  Vastaanottoajan pituus
                </h5>
                <span
                  *ngIf="diag.duration == 0"
                  i18n="@@viestinAnalysointiVastaanottoLyhyt">
                  Lyhyt
                </span>
                <span
                  *ngIf="diag.duration == 1"
                  i18n="@@viestinAnalysointiVastaanottoNormaali">
                  Normaali
                </span>
                <span
                  *ngIf="diag.duration == 2"
                  i18n="@@viestinAnalysointiVastaanottoPitkä">
                  Pitkä
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="group-wrapper">
        <h5
          class="suggestion_sub_heading"
          *ngIf="symptom_articles.length > 0"
          i18n="@@viestinAnalysointiTietoaOireista">
          Tietoa oireista ja ohjeita potilaalle:
        </h5>
        <div class="items">
          <div class="item-wrapper" *ngFor="let diag of symptom_articles">
            <div class="item">
              <h4 title="{{ utilsService.getNameTranslation(diag) }}">
                {{ utilsService.getNameTranslation(diag) }}
              </h4>
              <a
                *ngIf="
                  diag.visible_klinik_fi && utilsService.currentLocale == 'fi'
                "
                class="klinikfi_article"
                target="_blank"
                href="https://klinik.fi/terveysinfo/{{
                  utilsService.getProperty(
                    diag,
                    'translations.' + utilsService.currentLocale + '.slug'
                  )
                }}"
                i18n="@@viestinAnalysointiLinkkiArtikkeliin"
                >Lue lisätietoa</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="items" *ngIf="!message.data.data_v2?.diagnosis">
      <div
        class="item-wrapper"
        *ngFor="let suggestion of message.data.diagnosis_suggestions">
        <div class="item">
          <h4 title="{{ suggestion.name }}">{{ suggestion.name }}</h4>
          <div>
            <h5 i18n="@@viestinAnalysointiVastaanotto">
              Vastaanottoajan pituus
            </h5>
            <span
              *ngIf="suggestion.appointment_duration === 0"
              i18n="@@viestinAnalysointiVastaanottoLyhyt">
              Lyhyt
            </span>
            <span
              *ngIf="suggestion.appointment_duration === 1"
              i18n="@@viestinAnalysointiVastaanottoNormaali">
              Normaali
            </span>
            <span
              *ngIf="suggestion.appointment_duration === 2"
              i18n="@@viestinAnalysointiVastaanottoPitkä">
              Pitkä
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="disclaimer-wrapper" *ngIf="diagnoses.length > 0">
      <p class="disclaimer" i18n="@@viestinAnalysointiHuomautus">
        Järjestelmän suositukset ovat suuntaa-antavia. Lopullinen arvio
        vastaanottoajan kiirellisyydestä ja pituudesta tulee perustua
        hoitohenkilökunnan arvioon.
      </p>
    </div>
    <div class="disclaimer-wrapper" *ngIf="diagnoses.length < 1">
      <p class="disclaimer" i18n="@@noDiagnosis">None found.</p>
    </div>
  </section>
</section>
