// @ts-nocheck
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpWrapperService } from 'services/http-wrapper.service';
import { HttpClient } from '@angular/common/http';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';

/**
 * Service for managing SMS templates for a service group.
 */
@Injectable({
  providedIn: 'root',
})
export class SMSTemplatesService {
  constructor(
    private httpWrapper: HttpWrapperService,
    private http: HttpClient,
    private sessionService: SessionService,
    private stateService: StateService
  ) {}

  /**
   * Returns all SMS templates for the active service group.
   */
  async getSMSTemplates(): Promise<SMSTemplate[]> {
    try {
      const headers = this.httpWrapper.prepareHeaders();
      const url = `${environment.backendURL}/service_group/${this.sessionService.serviceGroupId}/sms-templates/`;
      const response = (await this.http
        .get(url, { headers: headers })
        .toPromise()) as SMSTemplate[];
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Creates a new SMS template for the active service group.
   * @param SMSTemplate
   */
  async newSMSTemplate(SMSTemplate: SMSTemplate): Promise<{}> {
    try {
      const headers = this.httpWrapper.prepareHeaders('post');
      const url = `${environment.backendURL}/service_group/${this.sessionService.serviceGroupId}/sms-templates/`;
      const response = await this.http
        .post(url, JSON.stringify(SMSTemplate), { headers: headers })
        .toPromise();

      // Inform other services and components about the need to update their SMS templates.
      this.stateService.setState(StateKeys.updateSMSTemplates, response);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Updates an existing SMS template for the active service group.
   * @param SMSTemplate - The entire SMS template to update.
   */
  async putSMSTemplate(SMSTemplate: SMSTemplate): Promise<{}> {
    try {
      const headers = this.httpWrapper.prepareHeaders('post');
      const url = `${environment.backendURL}/service_group/${this.sessionService.serviceGroupId}/sms-templates/${SMSTemplate.id}/`;
      const response = await this.http
        .put(url, JSON.stringify(SMSTemplate), { headers: headers })
        .toPromise();

      // Inform other services and components about the need to update their SMS templates.
      this.stateService.setState(StateKeys.updateSMSTemplates, response);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Sends an HTTP request to add the default SMS templates for a service group.
   */
  async addDefaultSMSTemplates(
    serviceGroupId: number,
    market: string
  ): Promise<{}> {
    try {
      const headers = this.httpWrapper.prepareHeaders('post');
      const url = `${environment.backendURL}/service_group/${serviceGroupId}/sms-templates/default`;

      const response = await this.http
        .post(url, { market: market }, { headers: headers })
        .toPromise();

      // Inform other services and components about the need to update their SMS templates.
      this.stateService.setState(StateKeys.updateSMSTemplates, response);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteSMSTemplate(SMSTemplateID: number): Promise<{}> {
    try {
      const headers = this.httpWrapper.prepareHeaders();
      const url = `${environment.backendURL}/service_group/${this.sessionService.serviceGroupId}/sms-templates/${SMSTemplateID}/`;
      const response = await this.http
        .delete(url, { headers: headers })
        .toPromise();

      // Inform other services and components about the need to update their SMS templates.
      this.stateService.setState(StateKeys.SMSTemplateDeleted, SMSTemplateID);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Delets all SMS templates for the active service group.
   */
  async deleteAllSMSTemplates(): Promise<void> {
    try {
      // Fetch all SMS templates and delete them one by one. Yes, quite clumsy and inefficient.
      const smsTemplates = await this.getSMSTemplates();

      for (const smsTemplate of smsTemplates) {
        await this.deleteSMSTemplate(smsTemplate.id);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
