// @ts-nocheck
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { environment } from 'environments/environment';
import { getNestedValue } from '../methods/getNestedValue';
import { valueInEnum } from '../methods/valueInEnum';

import { HttpWrapperService } from './http-wrapper.service';
import { IntegrationService } from './integration.service';
import { SessionService } from './session.service';
import { EnquiryRecentUpdateMessageEvent } from 'app/shared/methods/enquiry/enquiry';
import {
  StateService,
  StateKeys,
  updatedMessageStateEvent,
} from './state.service';
import { FollowupMessage } from 'app/messages/followup/followup';
import { AttachmentApiResponse } from './attachment.service';
import { UtilsService } from './utils.service';
import { HttpTools, HttpMethod } from 'tools/httptools';

export enum MESSAGE_STATES {
  NEW = 0,
  IN_PROGRESS = 1,
  ARCHIVED = 2,
  PENDING = 3,
}

export enum MESSAGE_REQUEST_TYPES {
  IMAGE_UPLOAD = 'IMAGE_UPLOAD',
  VIDEO_CONSULTATION = 'VIDEO_CONSULTATION',
}

export interface MessageCounts {
  totalMessages: { type: string; count: number };
  emergencyMessages: { type: string; count: number };
  urgentMessages: { type: string; count: number };
  newMessages: { type: string; count: number };
  newFollowups: { type: string; count: number };
}

export interface AppointmentType {
  id: number;
  name: string;
  sub_appointment_types: Array<Pick<AppointmentType, 'id' | 'name'>>;
}

/**
 * List of parameters that can be used to search and filter messages (ie. patient cases).
 */
export interface MessageListParameters {
  /** Search by specific service Ids. This must be a comma-separated string of ids. */
  service_id: string | undefined;

  search_term: string | undefined;

  /** Search by the patient's SSN (Finnish social security number). */
  search_ssn: string | undefined;

  /** Search by the patient case's id. */
  appointment_id: string | undefined;

  /** Search by a specific appointment type id's This must be a comma-separated string of ids.. */
  appointment_type_id: string | undefined;

  /** Search by a specific appointment sub type id's This must be a comma-separated string of ids.. */
  sub_appointment_type_id: string | undefined;

  /** Search by specific message states. This must be a comma-separated string of state ids. */
  state_id: string | undefined;

  /** Search by specific priorities (urgencies). This must be a comma-separated string of state ids. */
  priority_id: string | undefined;

  /** Specify how the returned list should be ordered. */
  order_by: string[];

  /** Specify the page number. */
  page: number;

  /** Specify the page size, ie. number of results to return. */
  page_size: number;

  /** Search for messages with new followups (ie. SMS). Use the number 1 for "true". */
  has_new_followups: number | undefined;

  /** Search for messages that have new attachments (ie. pictures). Use the number 1 for "true". */
  has_new_attachments: number | undefined;

  /** Filter for messages that are assigned for given users */
  assigned_to_user_id: string | undefined;
}

@Injectable()
export class MessageService {
  private serviceNames: object = {};
  private fetchedAppointmentTypes: AppointmentType[] = [];
  private readonly permissions = Permissions;

  constructor(
    private httpWrapper: HttpWrapperService,
    private httpTools: HttpTools,
    private http: HttpClient,
    private sessionService: SessionService,
    private stateService: StateService,
    private toaster: ToasterService,
    private utilsService: UtilsService,
    protected integrationService: IntegrationService
  ) {}

  catchError(error) {
    if (environment.production !== true && error !== null) {
      console.error(error);
    }
  }

  // Get service group information by id
  getServiceGroup() {
    // check session cache
    const serviceGroup = this.sessionService.serviceGroup as any;

    if (serviceGroup) {
      this.fetchedAppointmentTypes = serviceGroup.appointment_types;
      this.stateService.setState(StateKeys.loadedServiceGroup, serviceGroup);
      return;
    }
  }

  get appointmentTypes(): AppointmentType[] {
    return this.fetchedAppointmentTypes;
  }

  async fetchAppointmentTypes() {
    this.stateService.setLoading(StateKeys.appointmentTypes);

    const slug = this.sessionService.serviceGroup.slug;
    const url = `${environment.klinikBackendUrl}/service_group/${slug}`;

    try {
      const response = await this.http.get(url, { headers: {} }).toPromise();

      this.fetchedAppointmentTypes = response[
        'appointment_types'
      ] as AppointmentType[];

      this.stateService.setSuccess(
        StateKeys.appointmentTypes,
        this.fetchedAppointmentTypes
      );
      return response['appointment_types'];
    } catch (error) {
      this.stateService.setFailed(StateKeys.appointmentTypes);
    }
  }

  populateServiceNames(services) {
    for (const service of services) {
      this.serviceNames[service.id] = service.name;
    }
  }

  // Get service units that are available for the given user
  getUserServices() {
    // check session cache
    const userServices = this.sessionService.getData('userServices');

    if (userServices) {
      this.stateService.setState(StateKeys.loadedUserServices, userServices);
      this.populateServiceNames(userServices);
      return;
    }
  }

  // Compile parameter string for message list and count requests
  getParameterString(params) {
    const parameters: Array<any> = [];
    for (const key in params) {
      if (
        params.hasOwnProperty(key) &&
        typeof params[key] !== 'undefined' &&
        params[key] !== null
      ) {
        parameters.push(key + '=' + params[key]);
      }
    }
    return parameters.join('&');
  }

  async getBundledMessageCounts(params: MessageListParameters) {
    // GUARD: permissions check
    if (
      !this.sessionService.checkPermissions([this.permissions.MESSAGE_VIEW])
    ) {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
      return;
    }

    // GUARD: message counts are already loading
    if (this.stateService.isLoading(StateKeys.messageCounts)) {
      return;
    }

    // GUARD: service group is not set
    if (!this.sessionService.serviceGroupId) {
      return;
    }

    this.stateService.setLoading(StateKeys.messageCounts);

    const headers = this.httpWrapper.prepareHeaders();
    const requestUrl = `${environment.backendURL}/service_group/${
      this.sessionService.serviceGroupId
    }/bundled_counts?${this.getParameterString(params)}`;
    try {
      const res = await this.http
        .get(requestUrl, { headers: headers })
        .toPromise();

      const messageCounts: MessageCounts = {
        totalMessages: res['total-messages'],
        emergencyMessages: res['emergency-messages'],
        urgentMessages: res['urgent-messages'],
        newMessages: res['new-messages'],
        newFollowups: res['new-followups'],
      };
      this.stateService.setSuccess(StateKeys.messageCounts, messageCounts);

      return res;
    } catch (error) {
      this.stateService.setFailed(StateKeys.messageCounts);
    }
  }

  // Get all service units that user can redirect the message to
  getMessageCounts(countType: string, params: object) {
    if (this.sessionService.checkPermissions([this.permissions.MESSAGE_VIEW])) {
      return this.httpWrapper
        .get('/messages/counts?' + this.getParameterString(params))
        .then((res: any) => {
          const result = {
            type: countType,
            count: res ? res : 0,
          };

          return result;
        })
        .catch((error) => this.catchError(error));
    } else {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
    }
  }

  // Get all messages

  getMessages(params: MessageListParameters): void {
    // GUARD: permissions check
    if (
      !this.sessionService.checkPermissions([this.permissions.MESSAGE_VIEW])
    ) {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
      return;
    }
    // GUARD: messages are already loading
    if (this.stateService.isLoading(this.stateService.keys.loadingMessages)) {
      return;
    }

    this.stateService.setLoading(this.stateService.keys.loadingMessages);

    this.httpWrapper
      .get('/messages?' + this.getParameterString(params))
      .then((result) => {
        const items: Array<object> = [];
        const dateNow: Date = new Date();
        for (const item of result as object[]) {
          // check if message was sent today
          const dateSent = new Date(item['created_at']);
          item['sent_today'] =
            dateSent.setHours(0, 0, 0, 0) === dateNow.setHours(0, 0, 0, 0);
          // combine service name with service id
          item['servicename'] = this.serviceNames[item['service']];

          // combine appointment type information with the id
          if (item['appointment_duration_type_id']) {
            item['appointment_type'] = this.appointmentTypes.find(function (
              at
            ): boolean {
              return at['id'] === item['appointment_duration_type_id'];
            });
          }

          // appointment type id wasn't in the list provided by service group
          if (typeof item['appointment_type'] === 'undefined') {
            item['appointment_type'] = { name: '', short_name: '' };
          }

          items.push(item);
        }
        this.stateService.setSuccess(StateKeys.loadingMessages, items);
      })
      .catch((error) => {
        this.stateService.setFailed(StateKeys.loadingMessages);
        this.catchError(error);
      });
  }

  // Get details of a single message
  async getMessage(messageId: number): Promise<object> {
    if (
      !this.sessionService.checkPermissions([this.permissions.MESSAGE_VIEW])
    ) {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
      return;
    }

    this.stateService.setLoading(this.stateService.keys.singleMessage);

    const headers = this.httpWrapper.prepareHeaders();
    const requestUrl = `${environment.backendURL}/messages/${messageId}`;

    try {
      const result = await this.http
        .get(requestUrl, { headers: headers })
        .toPromise();

      // check if message was sent today
      const dateNow: Date = new Date();
      const dateSent = new Date(result['created_at']);
      result['sent_today'] =
        dateSent.setHours(0, 0, 0, 0) === dateNow.setHours(0, 0, 0, 0);

      // bundle unsuitable appointment times
      result['unsuitable_times'] = [];
      if (typeof result['data'] !== 'undefined') {
        const timingRequests =
          result['data']['appointment_request']['timing_requests'];
        if (timingRequests != null && timingRequests.length > 0) {
          for (const tr of timingRequests) {
            const time = { day: new Date(tr.excluded_time[0]), hours: [] };
            const hourList = tr.excluded_time[1];

            const startEndTimes = [];
            hourList.forEach((h) => {
              startEndTimes.push({
                date: time['day'],
                start: h,
                end: h + 1, // If the selectable hour range changes this will become a bug, but there is no information about it on ProUI at the moment..
              });
            });
            const hoursGroupped = this.groupHours(startEndTimes);
            time['hours'] = hoursGroupped[time['day'].toString()];
            result['unsuitable_times'].push(time);
          }
        }
      }

      this.stateService.setSuccess(StateKeys.singleMessage, result);

      return result;
    } catch (error) {
      this.stateService.setFailed(StateKeys.singleMessage);
      this.toaster.pop('error', $localize`:@@somethingWentWrong:`);
    }
  }

  groupHours(startEndTimes) {
    let groupStart: String = null;
    let groupEnd: String = null;
    const groups = {};

    for (let i = 0; i < startEndTimes.length; i++) {
      groups[startEndTimes[i]['date']] = [];
    }

    for (let i = 0; i < startEndTimes.length; i++) {
      if (groupStart === null) {
        groupStart = startEndTimes[i]['start'];
      }

      if (i + 1 < startEndTimes.length) {
        // if current end time is same as next's start time -> set group end to next's end
        // else create group from groupStart + groupEnd and set groupStart to next's start
        if (startEndTimes[i]['end'] === startEndTimes[i + 1]['start']) {
          groupEnd = startEndTimes[i]['end'];
        } else {
          groupEnd = startEndTimes[i]['end'];
          groups[startEndTimes[i]['date']].push(`${groupStart} - ${groupEnd}`);
          groupStart = startEndTimes[i + 1]['start'];
        }
      } else {
        groupEnd = startEndTimes[i]['end'];
        groups[startEndTimes[i]['date']].push(`${groupStart} - ${groupEnd}`);
      }
    }
    return groups;
  }

  // Redirect a message to another service unit
  async redirectMessage(
    messageId: number,
    serviceId: number,
    messageComment: string,
    isFlaggedByProfessional?: boolean,
    forceUpdate: boolean = false
  ) {
    const hasPermissions = this.sessionService.checkPermissions([
      this.permissions.MESSAGE_VIEW,
      this.permissions.MESSAGE_TRANSFER,
    ]);
    if (!hasPermissions) {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
      return null;
    }

    const url = `${environment.backendURL}/messages/${messageId}/service`;
    const headers = this.httpWrapper.prepareHeaders();
    const body = {
      service_id: serviceId,
      message_comment: messageComment,
      is_flagged_by_professional: isFlaggedByProfessional,
      force_update: forceUpdate,
    };
    return await this.http.post(url, body, { headers: headers }).toPromise();
  }

  /**
   * @deprecated Use `AttachmentService.fetch` instead.
   */
  async getAttachments(
    messageId: number
  ): Promise<AttachmentApiResponse | undefined> {
    // GUARD: permissions check
    if (
      !this.sessionService.checkPermissions([
        this.permissions.MESSAGE_VIEW,
        this.permissions.MESSAGE_VIEW_ATTACHMENTS,
      ])
    ) {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
      return;
    }

    this.stateService.setLoading(StateKeys.attachments);

    const headers = this.httpWrapper.prepareHeaders();
    const url = `${environment.backendURL}/messages/${messageId}/attachments/get`;
    try {
      const response = (await this.http
        .get(url, { headers: headers })
        .toPromise()) as AttachmentApiResponse;

      // Add file type to here so that users can have better experience when downloading images
      response.images.forEach((image) => {
        // fileType is not part of the AttachmentImage interface, but it is used here
        // to preserve backwards compatibility with any old code still relying on this.
        (image as any).fileType = image.file_extension;
      });

      this.stateService.setSuccess(StateKeys.attachments, response);
      return response as AttachmentApiResponse;
    } catch (error) {
      this.stateService.setFailed(StateKeys.attachments);
    }
  }
  /**
   * @deprecated Use `AttachmentService.markOpened` instead.
   */
  openAttachments(messageId: number): object {
    if (
      this.sessionService.checkPermissions([
        this.permissions.MESSAGE_VIEW,
        this.permissions.MESSAGE_VIEW_ATTACHMENTS,
      ])
    ) {
      return this.httpWrapper
        .get('/messages/' + messageId + '/attachments')
        .then()
        .catch((error) => this.catchError(error));
    } else {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
    }
  }

  // Post message comment
  commentMessage(messageId: number, messageComment: string): object {
    if (
      this.sessionService.checkPermissions([
        this.permissions.MESSAGE_VIEW,
        this.permissions.MESSAGE_CREATE_COMMENT,
      ])
    ) {
      return this.httpWrapper
        .deprecated_post('/messages/' + messageId + '/comment', {
          message_comment: messageComment,
        })
        .then(() =>
          this.stateService.setState(StateKeys.messageCommented, messageId)
        )
        .catch((error) => this.catchError(error));
    } else {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
    }
  }

  // Post message comment
  editMessageComment(commentId: number, messageComment: string): object {
    if (
      this.sessionService.checkPermissions([
        this.permissions.MESSAGE_VIEW,
        this.permissions.MESSAGE_CREATE_COMMENT,
      ])
    ) {
      return this.httpWrapper
        .deprecated_post('/messages/comment/' + commentId + '/edit', {
          message_comment: messageComment,
        })
        .then(() =>
          this.stateService.setState(StateKeys.messageCommentEdited, commentId)
        )
        .catch((error) => {
          this.stateService.setState(
            StateKeys.messageCommentEditFailed,
            commentId
          );
          this.catchError(error);
        });
    } else {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
    }
  }

  // Update message status
  async updateMessage(
    messageId: number,
    state: number,
    forceUpdate = false
  ): Promise<void> {
    // Do not post with invalid value
    if (!valueInEnum(state, MESSAGE_STATES)) {
      return;
    }

    let response = null;
    const headers = this.httpWrapper.prepareHeaders();
    const url = `${environment.backendURL}/messages/${messageId}/state`;
    const body = {
      state: state,
      force_update: !!forceUpdate,
    };

    try {
      response = await this.http
        .post(url, body, { headers: headers })
        .toPromise();
    } catch (error) {
      if (error.error.code === 'SKIP_MESSAGE_STATE') {
        this.toaster.pop('error', $localize`:@@invalidStatusChange:`);
      }
      if (error.error.code === 'MESSAGE_STATE_CONFLICT') {
        this.stateService.setState(StateKeys.enquiryAlreadyInHandling, {
          inHandlingBy: error.error.message,
          state: state,
        });
      }
      this.catchError(error);
      return;
    }

    this.getMessage(messageId);
    this.stateService.setState(StateKeys.updatedMessageState, <
      updatedMessageStateEvent
    >{
      messageId: messageId,
      currentState: state,
    });

    // If enquiry was moved to archive, professional feedback should be asked
    if (state === MESSAGE_STATES.ARCHIVED) {
      this.stateService.setState(
        StateKeys.professionalEnquiryAssessmentModalVisible,
        true
      );
    }
  }

  /**
   * Get all follow-up messages (SMS) for a message.
   * @param messageId
   */
  getFollowupMessages(messageId: number): Promise<void> {
    // Permission check
    const hasPermissions = this.sessionService.checkPermissions([
      this.permissions.MESSAGE_VIEW,
      this.permissions.MESSAGE_VIEW_SMS,
    ]);
    if (!hasPermissions) {
      throw new Error(
        "User doesn't have permissions to view followup messages."
      );
    }

    const headers = this.httpWrapper.prepareHeaders();
    const url = `${environment.backendURL}/messages/${messageId}/followups`;

    return this.http
      .get(url, { headers: headers })
      .toPromise()
      .then((result: Array<FollowupMessage>) => {
        result = result.map((followup) => this.enrichFollowupMessage(followup));

        this.stateService.setState(StateKeys.loadedFollowupMessages, result);
      })
      .catch((error) => {
        // Todo: should have some proper error handling that makes the
        // problem visible to the user.
        this.catchError(error);
      });
  }

  /**
   * Enrich a FollowupMessage with some additional information that is not returned by the API.
   * @param followup - The FollowupMessage to enrich.
   */
  private enrichFollowupMessage(followup: FollowupMessage): FollowupMessage {
    // check if message was sent today
    const dateNow: Date = new Date();
    const dateSent = new Date(followup.created_at);
    followup.sent_today =
      dateSent.setHours(0, 0, 0, 0) === dateNow.setHours(0, 0, 0, 0);

    if (followup.actor) {
      // Add full name for the actor
      followup.actor.full_name =
        followup.actor.first_name + ' ' + followup.actor.last_name;
    }

    // todo: we could also convert the date strings to Date objects here etc.
    return followup;
  }

  /**
   * Fetches audit events related to a specific Message.
   *
   * @param messageId - The integer Id of the Message.
   */
  async getAuditEvents(
    messageId: number
  ): Promise<Array<EnquiryRecentUpdateMessageEvent>> {
    const hasPermission = this.sessionService.checkPermissions([
      this.permissions.MESSAGE_VIEW_FULL_AUDIT_LOG,
    ]);

    if (!hasPermission) {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
      return Promise.reject();
    }

    try {
      const url = `${environment.backendURL}/messages/${messageId}/audit-events`;
      const options = {
        headers: this.httpWrapper.prepareHeaders(),
      };

      return (await this.http
        .get(url, options)
        .toPromise()) as Array<EnquiryRecentUpdateMessageEvent>;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // Update followupmessage (SMS) status
  updateFollowupMessage(
    followupMessageId: number,
    deliveryStatus: number
  ): object {
    if (
      this.sessionService.checkPermissions([
        this.permissions.MESSAGE_VIEW,
        this.permissions.MESSAGE_VIEW_SMS,
      ])
    ) {
      return this.httpWrapper
        .deprecated_post(
          `/followup_message/${followupMessageId}/delivery_status`,
          deliveryStatus
        )
        .then(() => {
          this.stateService.setState(StateKeys.updatedFollowupMessage, {
            id: followupMessageId,
            state: deliveryStatus,
          });
        })
        .catch((error) => this.catchError(error));
    } else {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
    }
  }

  // Send followupmessage (SMS)
  async sendFollowupMessage(
    messageId: number,
    content: string,
    requestType: MESSAGE_REQUEST_TYPES = null
  ): Promise<object> {
    const SMSPermissions = [
      this.permissions.MESSAGE_VIEW_SMS,
      this.permissions.MESSAGE_SEND_SMS,
    ];
    if (!this.sessionService.checkPermissions(SMSPermissions)) {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
      return;
    }

    try {
      const url = `${environment.backendURL}/messages/${messageId}/followups`;
      const data = { text: content, request_link: requestType };
      const options = { headers: this.httpWrapper.prepareHeaders('post') };

      const response = await this.http.post(url, data, options).toPromise();

      // TODO: Should EMIS integration be dismantled?
      if (this.sessionService.isEmisIntegrationEnabled) {
        this.integrationService.getEMISSMSFollowupMessagesXML([response['id']]);
      }
      // TODO: Is this needed?
      this.updateMessage(
        messageId,
        getNestedValue(response, 'updated_message_state'),
        true
      );

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Send diagnosis feedback loop data to backend
   * @param data
   */
  sendFeedbackLoopData(data) {
    if (this.sessionService.checkPermissions([this.permissions.MESSAGE_VIEW])) {
      this.httpWrapper
        .deprecated_post('/diagnosis_feedback/send', data)
        .then(() =>
          this.stateService.setState(StateKeys.feedbackLoopDataSent, null)
        )
        .catch((error) => {
          this.stateService.setState(
            StateKeys.feedbackLoopDataSendingFailed,
            null
          );
          this.catchError(error);
        });
    } else {
      this.toaster.pop('error', $localize`:@@NoPermissionsError:`);
    }
  }

  /**
   * Send closed case feedback data to backend
   * @param data
   */
  sendClosedCaseFeedbackData(data) {
    this.httpWrapper
      .deprecated_post('/case_feedback/send', data)
      .then(() =>
        this.stateService.setState(StateKeys.caseFeedbackDataSent, null)
      )
      .catch((error) => {
        this.stateService.setState(
          StateKeys.caseFeedbackDataSendingFailed,
          null
        );
        this.catchError(error);
      });
  }

  hideAttachment(documentId: number, data: object) {
    this.httpWrapper
      .deprecated_post(`/messages/document/${documentId}/hide`, data)
      .then(() => this.stateService.setState(StateKeys.documentHidden, null))
      .catch((error) => {
        this.stateService.setState(StateKeys.documentHiddenFailed, null);
        this.catchError(error);
      });
  }

  async confirmIdentity(messageId: number) {
    const url = `${environment.backendURL}/messages/${messageId}/pds-suggestions/confirmed`;
    const headers = this.httpTools.prepareHeaders(HttpMethod.PUT);
    return await this.http.put(url, {}, { headers }).toPromise();
  }
}
