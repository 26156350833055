<ng-container *ngIf="showModal">
  <div
    class="modal fade"
    bsModal
    #feedbackLoopModal="bs-modal"
    [config]="{ backdrop: false, show: true }"
    tabindex="-1"
    role="dialog"
    aria-hidden="false">
    <div class="modal-wrapper"></div>
    <div id="outcome-description" *ngIf="showOutcomeDescription">
      <h3></h3>
      <p></p>
    </div>
    <div class="modal-dialog modal-md">
      <div class="modal-content" id="outcome-modal">
        <form [formGroup]="feedbackLoopForm" (ngSubmit)="OnSubmit()">
          <section *ngIf="assesmentStatuses.need_for_care_accuracy_assesment">
            <div class="modal-header">
              <h4
                class="modal-title pull-left"
                i18n="@@viestiKiireellisyysOtsikko">
                Anna oma arviosi
              </h4>
            </div>

            <div class="modal-body">
              <p i18n="@@kiireellisyysarvioTeksti">
                Anna oma arviosi hoidon tarpeen kiireellisyydestä
              </p>
              <p>
                <span i18n="@@moodaliJarjestelmanKiireellisyysSuositus"
                  >Järjestelmän arvio hoidon tarpeen kiireellisyydestä:</span
                >
                <ng-container [ngSwitch]="message['priority']">
                  <b
                    class="emergency"
                    i18n="@@viestinAnalysointiKiireellisyysPäivystys"
                    *ngSwitchCase="2">
                    Emergency
                  </b>
                  <b
                    class="urgent"
                    i18n="@@viestinAnalysointiKiireellisyysKiireellinen"
                    *ngSwitchCase="1">
                    Urgent
                  </b>
                  <b
                    class="normal"
                    i18n="@@kiireellisyysarvioKiireellisyysNormaali"
                    *ngSwitchCase="0">
                    Non-urgent need for care
                  </b>
                  <b
                    class="selfcare"
                    i18n="@@viestinAnalysointiKiireellisyysItsehoidollinen"
                    *ngSwitchCase="-1">
                    Non-urgent or suitable for self-care
                  </b>
                </ng-container>
              </p>
              <div
                class="btn-group-vertical feedback-choice-group"
                role="group">
                <button
                  type="button"
                  class="feedback-choice btn er"
                  (click)="setFeedback(2)"
                  [ngClass]="{ 'btn-danger': feedbackValue === 2 }">
                  <i class="fa fa-circle red-ball"></i>
                  <p i18n="@@kiireellisyysarvioKiireellisyysPaivystys">
                    Päivystys
                  </p>
                  <span i18n="@@arvioKiireellisyysPaivystysLabel"
                    >(Välitön hoidontarve)</span
                  >
                </button>
                <button
                  type="button"
                  class="feedback-choice btn u"
                  (click)="setFeedback(1)"
                  [ngClass]="{ 'btn-warning': feedbackValue === 1 }">
                  <i class="fa fa-circle yellow-ball"></i>
                  <p i18n="@@kiireellisyysarvioKiireellisyysKiireellinen">
                    Kiireellinen
                  </p>
                  <span i18n="@@arvioKiireellisyysKiireellinenLabel"
                    >(Hoidon tarve 1-2pv sisällä)</span
                  >
                </button>
                <button
                  type="button"
                  class="feedback-choice btn nu"
                  (click)="setFeedback(0)"
                  [ngClass]="{ 'btn-success': feedbackValue === 0 }">
                  <i class="fa fa-circle green-ball"></i>
                  <p i18n="@@kiireellisyysarvioKiireellisyysNormaali">
                    Kiireetön hoidontarve
                  </p>
                </button>
                <button
                  type="button"
                  class="feedback-choice btn sc"
                  (click)="setFeedback(-1)"
                  *ngIf="message.data?.data_v2?.appointment_request?.v3 == true"
                  [ngClass]="{ 'btn-outline-info': feedbackValue === -1 }">
                  <i class="fa fa-circle blue-ball"></i>
                  <p i18n="@@viestinAnalysointiKiireellisyysItsehoidollinen">
                    Soveltuu itsehoitoon
                  </p>
                </button>
              </div>
              <ng-container
                *ngIf="!feedbackLoopForm.controls.feedback.valid && showErrors">
                <p class="error" i18n="@@selectOption">Choose an option</p>
              </ng-container>
            </div>
          </section>

          <section
            *ngIf="
              assesmentStatuses.method_assesment ||
              assesmentStatuses.resource_assesment
            ">
            <div class="modal-header">
              <h4 class="modal-title pull-left" i18n="@@selectCaseOutcome">
                Select outcome for the patient case
              </h4>
            </div>

            <div class="modal-body row">
              <div
                *ngIf="assesmentStatuses.method_assesment"
                class="col form-group">
                <label for="app-type">
                  <ng-container i18n="@@selectCaseProcedure"
                    >Enquiry outcome</ng-container
                  >
                  <i
                    class="fa fa-info-circle"
                    (mouseover)="
                      displayOutcomeDescriptions($event, 'enquiry_outcome')
                    "
                    (mouseout)="showOutcomeDescription = false"></i>
                </label>
                <select
                  name="app-type"
                  class="custom-select form-control"
                  id="app-type"
                  formControlName="procedure">
                  <option value="" disabled selected i18n="@@selectOption">
                    Choose an option
                  </option>
                  <option
                    *ngFor="let procedure of assesmentStatuses.methods"
                    [value]="procedure.id">
                    {{ getTranslation(procedure) }}
                  </option>
                </select>
                <ng-container
                  *ngIf="
                    !feedbackLoopForm.controls.procedure.valid && showErrors
                  ">
                  <p class="error" i18n="@@selectOption">Choose an option</p>
                </ng-container>
              </div>

              <div
                *ngIf="assesmentStatuses.resource_assesment"
                class="col form-group">
                <label for="resource">
                  <ng-container i18n="@@selectCaseResource"
                    >Resource / Professional</ng-container
                  >
                  <i
                    class="fa fa-info-circle"
                    (mouseover)="
                      displayOutcomeDescriptions($event, 'resource_outcome')
                    "
                    (mouseout)="showOutcomeDescription = false"></i>
                </label>
                <select
                  name="resource"
                  class="custom-select form-control"
                  id="resource"
                  formControlName="resource">
                  <option value="" disabled selected i18n="@@selectOption">
                    Choose an option
                  </option>
                  <option
                    *ngFor="let resource of assesmentStatuses.resources"
                    [value]="resource.id">
                    {{ getTranslation(resource) }}
                  </option>
                </select>
                <ng-container
                  *ngIf="
                    !feedbackLoopForm.controls.resource.valid && showErrors
                  ">
                  <p class="error" i18n="@@selectOption">Choose an option</p>
                </ng-container>
              </div>
            </div>
          </section>

          <div class="modal-buttons btn-group">
            <button
              class="btn btn-submit btn-single"
              type="submit"
              [class.disabled]="!feedbackLoopForm.valid"
              i18n="@@viestitoiminnotKiireellisyysarvioVahvista">
              Lähetä arvio
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
