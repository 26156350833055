// @ts-nocheck
import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { Subscription } from 'rxjs';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';
import { UserService } from 'services/user.service';

@Component({
  selector: 'app-users-list-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  private stateChangeSubscription: Subscription;

  public readonly permissions = Permissions;
  public serviceList: Array<object> = [];
  public service: object = null;
  public drawerOpen = false;
  public searchTerm: string = '';

  @Output()
  private openCreateUserModal: EventEmitter<object> = new EventEmitter();

  constructor(
    protected sessionService: SessionService,
    protected stateService: StateService,
    protected userService: UserService,
    protected router: Router
  ) {}

  toggleDrawer() {
    this.drawerOpen = !this.drawerOpen;
    this.stateService.setState(StateKeys.toggledDrawer, this.drawerOpen);
  }

  filterByService(service: object) {
    this.service = service;
    this.saveState();
    this.stateService.setState(StateKeys.selectedService, this.service);
  }

  isServiceSelected() {
    return this.service && (this.service as any).id !== -1;
  }

  filterBySearchTerm() {
    this.saveState();
    this.stateService.setState(
      StateKeys.userSearchTerm,
      this.searchTerm.trim()
    );
  }

  initServiceSelect(serviceList: Array<object>) {
    this.serviceList = serviceList;

    // if only one service is available, select it automatically
    if (this.serviceList.length === 1) {
      this.filterByService(this.serviceList[0]);
    }
  }

  createUser() {
    this.openCreateUserModal.emit();
  }

  saveState() {
    this.sessionService.setData('app-users-list-filter', {
      service: this.service,
    });
  }

  ngOnInit() {
    const self = this;

    this.stateChangeSubscription = this.stateService.state$.subscribe(function (
      state
    ) {
      switch (state['key']) {
        case StateKeys.loadedUserServices:
          self.initServiceSelect(state['value']);
          break;
      }
    });

    this.userService.getUserServices();
    this.stateService.setState(StateKeys.selectedService, null);
  }

  ngOnDestroy() {
    this.stateChangeSubscription.unsubscribe();
  }
}
