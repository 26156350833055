// @ts-nocheck
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpWrapperService } from 'services/http-wrapper.service';
import { environment } from '../../../environments/environment';
import { StateKeys, StateService } from './state.service';
import { ToasterService } from 'angular2-toaster';
import { ErrorTypes } from 'app/utils/http-response.enums';
import { LoadingStatus } from 'enums';

export interface DataCounts {
  audit_events: number;
  enquiries: number;
  followups: number;
  users: number;
}

export interface DataSetRequest {
  created_at: string;
  data_set_content: Array<string>;
  data_set_created: string;
  data_set_download_url: string;
  deleted: string | null;
  id: number;
  uuid: string;
  service_group: number;
  updated_at: string;
  user: number;
  status: DataSetRequestStatus;
  downloadState?: LoadingStatus;
}

export interface DataCountsEvent {
  status: LoadingStatus;
  data: DataCounts;
}

export interface DataSetRequestsEvent {
  status: LoadingStatus;
  data: Array<DataSetRequest> | null;
  error?: ErrorResponse | null;
}

/**
 * Service for providing data set requests for a service group.
 */
@Injectable()
export class DatasetRequestService {
  constructor(
    private httpWrapper: HttpWrapperService,
    private http: HttpClient,
    private stateService: StateService,
    private toasterService: ToasterService
  ) {}

  async getServiceGroupDataCounts(serviceGroupId: number) {
    this.stateService.setState(this.stateService.keys.dataCounts, {
      status: LoadingStatus.LOADING,
      data: null,
    });

    try {
      const headers = this.httpWrapper.prepareHeaders();
      const url = `${environment.backendURL}/service_group/${serviceGroupId}/data-set-requests/data-counts`;
      const response = (await this.http
        .get(url, { headers: headers })
        .toPromise()) as DataCounts;

      this.stateService.setSuccess(StateKeys.dataCounts, response);

      return response;
    } catch (error) {
      this.stateService.setFailed(StateKeys.dataCounts);
      return Promise.reject(error);
    }
  }

  async createNewDataSetRequest(serviceGroupId: number) {
    this.stateService.setState(this.stateService.keys.createDataSetRequest, {
      status: LoadingStatus.LOADING,
      data: null,
    });

    const headers = this.httpWrapper.prepareHeaders();
    const url = `${environment.backendURL}/service_group/${serviceGroupId}/data-set-requests/`;

    try {
      const response = await this.http
        .post(url, null, { headers: headers })
        .toPromise();

      this.stateService.setSuccess(StateKeys.createDataSetRequest, null);

      return response;
    } catch (error) {
      const errorRespone = {
        status: error.status,
        error: error.error,
      };
      this.stateService.setState(this.stateService.keys.createDataSetRequest, {
        status: LoadingStatus.FAILED,
        data: null,
        error: errorRespone as ErrorResponse,
      });
    }
  }

  async getDataSetRequests(
    serviceGroupId: number
  ): Promise<Array<DataSetRequest>> {
    this.stateService.setState(this.stateService.keys.dataSetRequests, {
      status: LoadingStatus.LOADING,
      data: null,
    });

    const headers = this.httpWrapper.prepareHeaders();
    const url = `${environment.backendURL}/service_group/${serviceGroupId}/data-set-requests/`;
    try {
      const response = (await this.http
        .get(url, { headers: headers })
        .toPromise()) as Array<DataSetRequest>;

      this.stateService.setSuccess(StateKeys.dataSetRequests, response);

      return response;
    } catch (error) {
      this.stateService.setFailed(StateKeys.dataSetRequests);
      return Promise.reject(error);
    }
  }

  async getSignedDatasetUrl(
    dataSetRequestId: number
  ): Promise<getSignedUrlResponse> {
    const headers = this.httpWrapper.prepareHeaders();
    const url = `${environment.backendURL}/service_group/data-set-requests/download/${dataSetRequestId}`;

    try {
      return (await this.http
        .get(url, { headers: headers })
        .toPromise()) as getSignedUrlResponse;
    } catch (error) {
      if (error?.status) this.handleErrorStatus(error);
      return Promise.reject(error);
    }
  }

  handleErrorStatus(error) {
    switch (error.status) {
      case ErrorTypes.notFound:
        // todo: translate this one
        this.toasterService.pop('error', 'Dataset not found');
        break;
      case ErrorTypes.forbidden:
        this.toasterService.pop('error', $localize`:@@NoPermissionsError:`);
        break;
      default:
        this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`);
        break;
    }
  }
}

export type getSignedUrlResponse = {
  download_url: string;
};
