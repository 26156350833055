// @ts-nocheck
import { PersonalInfoItem } from './PersonalInfoItem';
import { Enquiry } from 'app/shared/methods/enquiry/enquiry';

export class AuthenticationInfoItem extends PersonalInfoItem {
  /**
   * Show authentication details.
   * @param message - The message ie. patient case object.
   */

  private NHSNumberSupersededTranslation = $localize`:@@NHSNumberSuperseded:`;
  private InvalidPatientInformationTranslation = $localize`:@@InvalidPatientInformation:`;

  constructor(private message: Enquiry) {
    // Clumsy way to call the constructor first and update the values later
    super('authentication', '', '');

    this.header = $localize`:@@patientValidation:`;
    this.updateValueAndIcons();
  }

  updateValueAndIcons(): string {
    const patientValidation = this.message.patient_validation;
    if (!patientValidation) return null;

    let value = patientValidation.provider;

    if (patientValidation.is_valid) {
      this.show_valid_icon = true;

      if (patientValidation.nhs_number_superseded) {
        value += ' (' + this.NHSNumberSupersededTranslation + ')';
      }
    } else {
      this.show_exclamation = true;
      value += ' ' + this.InvalidPatientInformationTranslation;
      if (patientValidation.error_description) {
        value += ' (' + patientValidation.error_description + ')';
      }
    }

    this.value = value;
  }
}
