// @ts-nocheck
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { SuperuserComponent } from './superuser.component';
import { detailRoutes } from './detail/detail.routing';
import { DetailComponent } from './detail/detail.component';

export const superuserRoutes: Routes = [
  {
    path: '',
    component: SuperuserComponent,
    children: detailRoutes,
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(superuserRoutes);
