<div
  class="transparent"
  *ngIf="navigationCheck === 0"
  [ngClass]="{ shadow: navigationCheck === 2 }"></div>
<section class="kip-popup-container" *ngIf="navigationCheck === 0">
  <div class="kip-popup-header">
    <div i18n="@@askingForFeedbackSurvey">Integration is here!</div>
  </div>
  <div class="kip-popup-body">
    <div class="klinik-access-container">
      <i class="fa fa-user-md"></i>
      <p class="klinik-access-text">Klinik Access</p>
    </div>
    <div class="sync-container">
      <i class="fa fa-retweet"></i>
    </div>
    <div class="kip-container">
      <i class="fa fa-bolt"></i>
      <p class="kip-text">KIP</p>
    </div>
    <div class="sync-container">
      <i class="fa fa-retweet"></i>
    </div>
    <div class="phr-container">
      <i class="fa fa-laptop"></i>
      <p class="phr-text">PHR</p>
    </div>
  </div>
  <div class="kip-popup-container-body-text">
    <p class="kip-popup-body-text">
      Klinik integration pack or <span style="color: #00ac61">KIP </span> for
      short is the connection between Klinik Access and patient record systems.
      We are excited to release the first version of
      <span style="color: #00ac61">KIP </span> with integration capability to
      EMIS.
    </p>
  </div>
  <div class="kip-popup-page-checker-container">
    <div class="kip-popup-page-checker-ellipse-selected-one"></div>
    <div class="kip-popup-page-checker-ellipse"></div>
    <div class="kip-popup-page-checker-ellipse"></div>
    <div class="kip-popup-page-checker-ellipse"></div>
  </div>
  <div class="kip-popup-page-next-container">
    <div class="kip-popup-page-next-container-two">
      <button class="kip-popup-page-next-button" (click)="nextView()">
        <p class="kip-popup-page-next-text">Next</p>
      </button>
    </div>
  </div>
</section>
<section class="kip-popup-container" *ngIf="navigationCheck === 1">
  <div class="kip-popup-header">
    <div i18n="@@askingForFeedbackSurvey">
      KIP will streamline your work flow
    </div>
  </div>
  <div class="kip-popup-body-two">
    <div class="kip-popup-patient-enquiry-easily-move">
      <div class="kip-popup-patient-enquiry-easily-move-logo">
        <i class="fa fa-file"></i>
      </div>
      <p class="kip-popup-patient-enquiry-easily-move-text">
        Easily move patient enquiry details and attachments to EMIS.
      </p>
    </div>
    <div class="kip-popup-patient-enquiry-sent-messages">
      <div class="kip-popup-patient-enquiry-sent-messages-logo">
        <i class="fa fa-comment"></i>
      </div>
      <p class="kip-popup-patient-enquiry-sent-messages-text">
        Sent messages are moved to EMIS automatically and patients responses can
        be easily moved as well.
      </p>
    </div>
    <div class="kip-popup-patient-enquiry-add-new-task">
      <div class="kip-popup-patient-enquiry-add-new-task-logo">
        <i class="fa fa-plus"></i>
      </div>
      <p class="kip-popup-patient-enquiry-add-new-task-text">
        Add new tasks to EMIS.
      </p>
    </div>
  </div>
  <div class="kip-popup-page-checker-container">
    <div class="kip-popup-page-checker-ellipse"></div>
    <div class="kip-popup-page-checker-ellipse-selected-two"></div>
    <div class="kip-popup-page-checker-ellipse"></div>
    <div class="kip-popup-page-checker-ellipse"></div>
  </div>
  <div class="kip-popup-page-navigation-container">
    <div class="kip-popup-page-navigation-container-back">
      <div
        class="kip-popup-page-navigation-container-back-two"
        (click)="backView()">
        <p class="kip-popup-page-navigation-container-back-text">Back</p>
      </div>
    </div>
    <div class="kip-popup-page-navigation-container-next">
      <div
        class="kip-popup-page-navigation-container-next-two"
        (click)="nextView()">
        <p class="kip-popup-page-navigation-container-next-text">Next</p>
      </div>
    </div>
  </div>
</section>
<section class="kip-popup-container" *ngIf="navigationCheck === 2">
  <div class="kip-popup-header">
    <div i18n="@@askingForFeedbackSurvey">You can find KIP here</div>
  </div>
  <div class="kip-popup-body-three">
    <div class="kip-popup-body-three-container">
      <p class="kip-popup-body-three-text">
        We have added a new tab to Klinik Access dedicated for
        <span style="color: #00ac61">KIP</span>. From there you will be able to
        find installation guide, instructions for use and all different use
        cases.
      </p>
    </div>
  </div>
  <div class="kip-popup-page-checker-container">
    <div class="kip-popup-page-checker-ellipse"></div>
    <div class="kip-popup-page-checker-ellipse"></div>
    <div class="kip-popup-page-checker-ellipse-selected-three"></div>
    <div class="kip-popup-page-checker-ellipse"></div>
  </div>
  <div class="kip-popup-page-navigation-container">
    <div class="kip-popup-page-navigation-container-back">
      <div
        class="kip-popup-page-navigation-container-back-two"
        (click)="backView()">
        <p class="kip-popup-page-navigation-container-back-text">Back</p>
      </div>
    </div>
    <div class="kip-popup-page-navigation-container-next">
      <div
        class="kip-popup-page-navigation-container-next-two"
        (click)="nextView()">
        <p class="kip-popup-page-navigation-container-next-text">Next</p>
      </div>
    </div>
  </div>
</section>
<section class="kip-popup-container" *ngIf="navigationCheck === 3">
  <div class="kip-popup-header">
    <div i18n="@@askingForFeedbackSurvey">Get started</div>
  </div>
  <div class="kip-popup-body-four">
    <div class="kip-popup-download-kip-container">
      <div class="kip-popup-download-kip-logo">
        <i class="fa fa-arrow-circle-down"></i>
      </div>
      <p class="kip-popup-download-kip-text">
        Download <span style="color: #00ac61">KIP</span>
      </p>
    </div>
    <div class="kip-popup-follow-installer-container">
      <div class="kip-popup-follow-installer-logo">
        <i class="fa fa-bolt"></i>
      </div>
      <p class="kip-popup-follow-installer-text">
        Follow installer guide and connect
        <span style="color: #00ac61">KIP</span> with EMIS.
      </p>
    </div>
    <div class="kip-popup-verify-kip-container">
      <div class="kip-popup-verify-kip-logo">
        <i class="fa fa-check-circle"></i>
      </div>
      <p class="kip-popup-verify-kip-text">
        Verify <span style="color: #00ac61">KIP</span> connection from Klinik
        Access
      </p>
    </div>
  </div>
  <div class="kip-popup-kip-download-container">
    <div class="kip-popup-take-me-to-kip-download-container">
      <button
        class="kip-popup-take-me-to-kip-download-button"
        (click)="patchUserOnboarded()">
        <p class="kip-popup-take-me-to-kip-download-text">
          Take me to KIP download
        </p>
      </button>
      <div class="kip-popup-kip-later-container" (click)="patchUserOnboarded()">
        <p class="kip-popup-kip-later-text">I will start using KIP later</p>
      </div>
    </div>
  </div>
  <div class="kip-popup-page-checker-container">
    <div class="kip-popup-page-checker-ellipse"></div>
    <div class="kip-popup-page-checker-ellipse"></div>
    <div class="kip-popup-page-checker-ellipse"></div>
    <div class="kip-popup-page-checker-ellipse-selected-four"></div>
  </div>
  <div class="kip-popup-page-navigation-container">
    <div class="kip-popup-page-navigation-container-back">
      <div
        class="kip-popup-page-navigation-container-back-two"
        (click)="backView()">
        <p class="kip-popup-page-navigation-container-back-text">Back</p>
      </div>
    </div>
  </div>
</section>
