// @ts-nocheck
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { UsersComponent } from './users.component';
import { detailRoutes } from './detail/detail.routing';

export const userRoutes: Routes = [
  {
    path: '',
    component: UsersComponent,
    children: detailRoutes,
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(userRoutes);
