// @ts-nocheck
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { getNestedValue } from 'app/shared/methods/getNestedValue';
import { Subscription } from 'rxjs';
import { MessageService } from 'services/message.service';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';
import { UtilsService } from 'services/utils.service';
@Component({
  selector: 'app-professional-enquiry-assessment-modal',
  templateUrl: './professional-enquiry-assessment-modal.component.html',
  styleUrls: ['./professional-enquiry-assessment-modal.component.scss'],
})
export class ProfessionalEnquiryAssessmentModalComponent
  implements OnInit, OnDestroy
{
  @Input()
  public message: object;

  public feedbackLoopForm: FormGroup;
  public feedbackValue: number = null;
  public showModal = false;
  public showOutcomeDescription = false;
  public showErrors = false;
  public assesmentStatuses: ProfessionalEnquiryAssesmentStatus = null;
  public assesmentEnabled = false;

  private stateChangeSubscription: Subscription;
  private outcomeTranslations = {
    enquiry_outcome: {
      name: $localize`:@@selectCaseProcedure:`,
      description: $localize`:@@EnquiryOutcomeDescription:`,
    },
    resource_outcome: {
      name: $localize`:@@selectCaseResource:`,
      description: $localize`:@@ResourceOutcomeDescription:`,
    },
  };

  constructor(
    private messageService: MessageService,
    private fb: FormBuilder,
    private sessionService: SessionService,
    private stateService: StateService,
    private toasterService: ToasterService,
    private utilsService: UtilsService
  ) {
    this.feedbackLoopForm = this.fb.group({
      feedback: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      procedure: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      resource: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
    });
  }

  ngOnInit() {
    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.feedbackLoopDataSent:
            this.toasterService.pop(
              'success',
              $localize`:@@EstimateSentSuccessfully:`
            );
            this.clearModal();
            break;
          case StateKeys.caseFeedbackDataSent:
            this.toasterService.pop(
              'success',
              $localize`:@@EstimateSentSuccessfully:`
            );
            this.clearModal();
            break;
          case StateKeys.caseFeedbackDataSendingFailed:
          case StateKeys.feedbackLoopDataSendingFailed:
            this.toasterService.pop(
              'error',
              $localize`:@@EstimateSentUnsuccessfully:`
            );
            break;
          case StateKeys.professionalEnquiryAssessmentModalVisible:
            this.assesmentStatuses =
              this.sessionService.getProfessionalEnquiryAssesmentStatuses(
                this.message
              );
            this.assesmentEnabled =
              this.assesmentStatuses.need_for_care_accuracy_assesment ||
              this.assesmentStatuses.method_assesment ||
              this.assesmentStatuses.resource_assesment;
            this.decideModalVisibility(state['value']);
            break;
        }
      }
    );
  }

  decideModalVisibility(visible: boolean) {
    // Hide modal
    if (!visible || !this.assesmentEnabled) {
      this.clearModal();
      return;
    }

    // Restricted users not allowed to give feedback
    if (this.sessionService.isRestricted) {
      this.clearModal();
      return;
    }

    // Ask for feedback only if it hasn't been given yet
    if (
      getNestedValue(this.message, 'has_diagnosis_feedback') ||
      getNestedValue(this.message, 'has_case_feedback')
    ) {
      this.clearModal();
      return;
    }

    if (this.assesmentStatuses.need_for_care_accuracy_assesment) {
      this.feedbackLoopForm.controls.feedback.enable();
    }

    if (this.assesmentStatuses.method_assesment) {
      this.feedbackLoopForm.controls.procedure.enable();
    }

    if (this.assesmentStatuses.resource_assesment) {
      this.feedbackLoopForm.controls.resource.enable();
    }

    this.showModal = true;
  }

  clearModal(): void {
    this.feedbackValue = null;
    this.feedbackLoopForm.get('feedback').setValue(this.feedbackValue);
    this.feedbackLoopForm.get('procedure').setValue('');
    this.feedbackLoopForm.get('resource').setValue('');
    this.feedbackLoopForm.controls.feedback.disable();
    this.feedbackLoopForm.controls.procedure.disable();
    this.feedbackLoopForm.controls.resource.disable();
    this.showModal = false;
  }

  setFeedback(value: number): void {
    if (this.feedbackValue === value) {
      this.feedbackValue = null;
      this.feedbackLoopForm.get('feedback').setValue(this.feedbackValue);
      return;
    }

    this.feedbackValue = value;
    this.feedbackLoopForm.get('feedback').setValue(this.feedbackValue);
  }

  getFeedbackLoopData(): object {
    const severity_symptoms = getNestedValue(
      this.message,
      'data',
      'data_v2',
      'severity_symptoms'
    );
    const feedback_severity_symptoms =
      typeof severity_symptoms !== 'undefined' ? severity_symptoms : '';
    const age = getNestedValue(this.message, 'age_in_years');
    const feedback_age = Math.floor(age);

    const feedback_loop_data = {
      appointment_priority: getNestedValue(this.message, 'priority'),
      suggested_priority: this.feedbackValue,
      body_parts: getNestedValue(this.message, 'data', 'data_v2', 'body_parts'),
      diagnosis: getNestedValue(this.message, 'data', 'data_v2', 'diagnosis'),
      symptoms: getNestedValue(this.message, 'data', 'data_v2', 'symptoms'),
      pain: getNestedValue(this.message, 'data', 'data_v2', 'pain'),
      v3: !!getNestedValue(
        this.message,
        'data',
        'data_v2',
        'appointment_request',
        'v3'
      ),
      severity_symptoms: feedback_severity_symptoms,
      age: feedback_age,
      sex: getNestedValue(
        this.message,
        'data',
        'data_v2',
        'personal_info',
        'sex'
      ),
      appointment_request_id: getNestedValue(
        this.message,
        'data',
        'appointment_request_uuid'
      ),
      service_group: this.sessionService.serviceGroupId,

      symptom_duration: getNestedValue(
        this.message,
        'data',
        'data_v2',
        'symptom_duration'
      ),
    };
    return feedback_loop_data;
  }

  getCaseFeedbackData(): object {
    const data = {
      appointment_request_id: this.message['data']['appointment_request_uuid'],
      message: this.message['id'],
      procedure: this.feedbackLoopForm.get('procedure').value,
      resource: this.feedbackLoopForm.get('resource').value,
    };
    return data;
  }

  async OnSubmit(): Promise<void> {
    if (!this.feedbackLoopForm.valid) {
      this.showErrors = true;
      return;
    }

    if (this.assesmentStatuses.need_for_care_accuracy_assesment) {
      const caseFeedbackLoopData = this.getFeedbackLoopData();
      this.messageService.sendFeedbackLoopData(caseFeedbackLoopData);
    }

    const caseData = this.getCaseFeedbackData();
    this.messageService.sendClosedCaseFeedbackData(caseData);
  }

  getTranslation(obj: object): string {
    if (obj['translations'][this.utilsService.currentLocale]['name']) {
      return obj['translations'][this.utilsService.currentLocale]['name'];
    }
    return obj['name'];
  }

  displayOutcomeDescriptions(event: MouseEvent, outcome: string): void {
    this.showOutcomeDescription = true;
    // Make sure element has been rendered before attempting to update it's contents
    requestAnimationFrame(() => {
      const descriptionElement = document.getElementById('outcome-description');
      if (!descriptionElement) {
        return;
      }

      const hoverElement = <HTMLElement>event.target;
      descriptionElement.style.top = `${
        hoverElement.getBoundingClientRect().top + 15
      }px`;
      descriptionElement.style.left = `${
        hoverElement.getBoundingClientRect().left + 15
      }px`;
      descriptionElement.getElementsByTagName('h3')[0].innerHTML =
        this.outcomeTranslations[outcome].name;
      descriptionElement.getElementsByTagName('p')[0].innerHTML =
        this.outcomeTranslations[outcome].description;
    });
  }

  ngOnDestroy() {
    this.stateChangeSubscription.unsubscribe();
  }
}
