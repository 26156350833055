// @ts-nocheck
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart as ChartJS } from 'chart.js';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-card-chart',
  templateUrl: './card-chart.component.html',
  styleUrls: ['./card-chart.component.scss'],
})
export class CardChartComponent implements OnChanges {
  @Input()
  public chart: Chart;

  constructor(private utilsService: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.chart &&
      this.compareChanges(
        changes.chart.previousValue,
        changes.chart.currentValue
      )
    ) {
      requestAnimationFrame(() => this.initChart());
    }
  }

  compareChanges(prev: object, curr: object) {
    return (
      JSON.stringify(prev, this.utilsService.getCircularReplacer()) !==
      JSON.stringify(curr, this.utilsService.getCircularReplacer())
    );
  }

  initChart(): void {
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>(
      document.createElement('canvas')
    );
    const container: HTMLElement = <HTMLElement>(
      document.getElementById(`${this.chart.id}-container`)
    );
    const canvasContainer: HTMLElement = <HTMLElement>(
      document.getElementById(`${this.chart.id}-container`)
    );

    canvasContainer.innerHTML = '';
    canvas.setAttribute('id', `${this.chart.id}-chart`);
    canvas.height = container.clientHeight;
    canvas.width = container.clientWidth;
    canvasContainer.appendChild(canvas);

    const chart = new ChartJS(`${this.chart.id}-chart`, {
      type: this.chart.type,
      data: this.chart.data,
      options: this.chart.options,
    });
  }
}
