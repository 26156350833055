// @ts-nocheck
import { Component, Input, OnInit } from '@angular/core';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { MessageService, MESSAGE_STATES } from 'services/message.service';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-messages-detail-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit {
  @Input()
  public message: object;

  public showPromptEnquiryAlreadyInHandling = false;
  public userPermissions: Set<string> = new Set();
  public promptTranslations = { header: '', prompt: '', yes: '', no: '' };

  public readonly permissions = Permissions;
  public readonly messageStates = MESSAGE_STATES;

  private state: number = null;
  private promptState: number = null;

  constructor(
    protected messageService: MessageService,
    protected sessionService: SessionService,
    protected stateService: StateService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.userPermissions = this.utilsService.filterUserPemsissions([
      this.permissions.MESSAGE_CHANGE_STATE,
    ]);

    // Add state service listener for prompt
    this.stateService.state$.subscribe((state) => {
      switch (state['key']) {
        case StateKeys.enquiryAlreadyInHandling:
          // Prompt user if they are sure they want to move enquiry to archive.
          this.promptState = state['value'].state;

          const inHandlingBy: string = state['value'].inHandlingBy;
          const viestinStatusKasittelija = $localize`:@@viestinStatusKasittelija:`;

          this.promptTranslations = {
            header: $localize`:@@promptUpdateMessageStatusHeader:`,
            prompt: `${viestinStatusKasittelija}: ${inHandlingBy}`,
            yes:
              this.state === 0
                ? $localize`:@@viestinVaiheetPalautaUudeksi:`
                : $localize`:@@viestinVaiheetOtaKasittelyyn:`,
            no: $localize`:@@promptUpdatemesageSatustNo:`,
          };
          this.showPromptEnquiryAlreadyInHandling = true;
          break;
      }
    });
  }

  updateMessageState(forceUpdate = false, state: number = null) {
    // If state would not be changed, no need to update it
    if (state === this.message['state']) {
      return;
    }

    this.messageService.updateMessage(this.message['id'], state, forceUpdate);
  }

  handlePrompt(value: boolean) {
    this.showPromptEnquiryAlreadyInHandling = false;

    // User wants to update enquiry status even though enquiry was already assigned to someone else
    if (value) {
      this.updateMessageState(true, this.promptState);
    }
  }
}
