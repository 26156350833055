<ng-container *ngIf="message">
  <section class="detail-actions">
    <app-messages-detail-actions
      [message]="message"></app-messages-detail-actions>
  </section>

  <section class="detail-body">
    <section class="detail-body-content" [class.visible]="message">
      <section class="body-header">
        <app-messages-detail-progress
          [message]="message"></app-messages-detail-progress>
      </section>

      <!-- Info box hovering on top of the message details -->
      <app-patient-details-notification-slot>
        <app-patient-identifier-display [message]="message">
        </app-patient-identifier-display>

        <mat-divider></mat-divider>

        <app-caretaker-info-display
          *ngIf="message?.data?.personal_info?.caretaker_info"
          [message]="message">
        </app-caretaker-info-display>
      </app-patient-details-notification-slot>

      <section class="body-content" [class.status-ready]="messageIsReady()">
        <section class="content-comment-case">
          <app-messages-detail-status
            [message]="message"></app-messages-detail-status>
        </section>

        <section class="body-content-inner">
          <app-messages-detail-comment
            [message]="message"></app-messages-detail-comment>
          <h1 id="appointment-type" *ngIf="message">
            {{ appointmentTypeName$ | async }}
            <span *ngIf="appointmentSubTypeName !== null"
              >({{ appointmentSubTypeName }})</span
            >
          </h1>

          <section class="content-print-customer" id="content-print-customer">
            <app-messages-detail-customer
              [message]="message"></app-messages-detail-customer>
          </section>

          <section class="content-print-case">
            <app-messages-detail-description
              [message]="message"
              *ngIf="!sessionService.isRestricted">
            </app-messages-detail-description>
            <app-messages-detail-attachments
              [message]="message"
              *ngIf="!sessionService.isRestricted">
            </app-messages-detail-attachments>
            <app-messages-detail-analysis
              [message]="message"></app-messages-detail-analysis>
            <app-messages-detail-appointment
              [message]="message"></app-messages-detail-appointment>
            <app-messages-detail-changelog
              [messageId]="message?.id"></app-messages-detail-changelog>
          </section>
        </section>
      </section>

      <div class="loading-indicator" *ngIf="isMessageLoading">
        <app-loading class="loading-indicator-spinner"> </app-loading>
      </div>
    </section>
    <app-professional-enquiry-assessment-modal
      [message]="message"></app-professional-enquiry-assessment-modal>
    <div
      class="mouse-out-integration-container"
      (mouseover)="integrationDrawerVisible = false"
      *ngIf="integrationDrawerVisible"></div>

    <div
      id="integration-container"
      (mouseover)="checkIntegrationErrors(); integrationDrawerVisible = true"
      *ngIf="showIntegrationContainer">
      <div class="pill"></div>
      <div
        class="integration-actions"
        [ngClass]="{ 'integration-actions-visible': integrationDrawerVisible }">
        <ng-container *ngIf="integrationDrawerVisible">
          <p i18n="@@integrationDrawerHeader">
            Close case and transfer information to the patient record
          </p>

          <div class="error">
            <app-notification
              *ngFor="let error of integrationErrorsList"
              [icon]="'exclamation-circle'">
              {{ error }}
            </app-notification>
          </div>

          <button
            class="btn action"
            (click)="integrationAction(integrationActions.FOR_INFORMATION)"
            [disabled]="integrationErrorsList.size > 0">
            <ng-container i18n="@@moveCaseAsInformation"
              >To patient record for information</ng-container
            >
          </button>
          <button
            class="btn action"
            (click)="integrationAction(integrationActions.FOR_ACTION)"
            [disabled]="integrationErrorsList.size > 0">
            <ng-container i18n="@@moveCaseAsAction"
              >To patient record as a task</ng-container
            >
          </button>
        </ng-container>
      </div>
    </div>
  </section>
</ng-container>
