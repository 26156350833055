// @ts-nocheck
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'app/shared/services/session.service';
import { Permissions } from 'permissions/permissions.module';
import { Subscription } from 'rxjs';
import { StateService, StateKeys } from 'services/state.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-auth-currentuser',
  templateUrl: './currentuser.component.html',
  styleUrls: ['./currentuser.component.scss'],
})
export class CurrentUserComponent implements OnInit, OnDestroy {
  private stateChangeSubscription: Subscription;
  public hasSettingsPermission: boolean;
  public hasKipPermission: boolean;

  public session = this.sessionService.getSession();
  public readonly permissions = Permissions;

  public get version(): string | null {
    const versionPlaceholder: string = '{BUILD_VERSION}';
    return environment.version != versionPlaceholder
      ? environment.version
      : null;
  }

  constructor(
    protected sessionService: SessionService,
    protected stateService: StateService,
    private router: Router,
    private http: HttpClient
  ) {}

  logout() {
    this.session = null;
    this.sessionService.logout();
    this.hasSettingsPermission = null;
    this.hasKipPermission = null;
  }

  ngOnInit() {
    this.hasSettingsPermission = this.checkSettingsPermission();
    this.hasKipPermission = this.sessionService.isEmisIntegrationEnabled;

    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.refreshUserData:
            this.session = state['value'];
            break;
          case StateKeys.deleteSession:
            this.session = null;
            break;
        }
      }
    );
  }

  public checkSettingsPermission(): boolean {
    if (this.sessionService.userIsStaff) {
      return true;
    }

    if (this.sessionService.checkPermissions(this.permissions.SETTINGS_VIEW)) {
      return true;
    }

    return false;
  }

  ngOnDestroy() {
    this.stateChangeSubscription.unsubscribe();
  }
}
