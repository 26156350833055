<h2 i18n="@@authenticationDataHeader">
  Authentication data for {{ serviceGroupName }} (Beta)
</h2>

<p>
  This tool can be used to see data related to authenticated patient cases for
  the service group. The data is available for the UK-specific PDS and NHS Login
  authentication methods.
</p>

<label i18n="@@startDateLabel">Start date</label>
<input type="date" [(ngModel)]="startDate" />
<br />

<label i18n="@@endDateLabel">End date</label>
<input type="date" [(ngModel)]="endDate" />

<mat-slide-toggle [color]="'primary'" [(ngModel)]="includeAccessCases">
  Access cases
</mat-slide-toggle>

<mat-slide-toggle [color]="'primary'" [(ngModel)]="includeConnectCases">
  Connect cases
</mat-slide-toggle>

<button
  class="btn action"
  (click)="downloadAuthenticationData()"
  [disabled]="downloadingAuthenticationData || !validSelections"
  i18n="@@staffSettingsDownloadAuthenticationData">
  Load authentication data
</button>

<table class="table">
  <thead>
    <th>Date</th>
    <th>Total patient cases</th>
    <th>Total Success Rate</th>
    <th>NHS Login Success</th>
    <th>PDS Success</th>
    <th>PDS Failure</th>
    <th>PDS Success Rate</th>
    <th>NHS Number Bypassed</th>
    <th>NHS Number Bypassed Rate</th>
    <th>Identity Suggested</th>
    <th>Identity Suggestion Confirmed</th>
  </thead>
  <tbody *ngIf="!downloadingAuthenticationData">
    <tr *ngFor="let date of authenticationData">
      <td>{{ date.date | date }}</td>
      <td>{{ date.total_patient_cases }}</td>
      <td>
        {{
          (date.nhs_login_success +
            date.pds_success +
            date.identity_suggestion_confirmed) /
            date.total_patient_cases | percent
        }}
      </td>
      <td>{{ date.nhs_login_success }}</td>
      <td>{{ date.pds_success }}</td>
      <td>{{ date.pds_failure }}</td>
      <td>
        {{ date.pds_success / date.total_patient_cases | percent }}
      </td>
      <td>{{ date.nhs_number_bypassed }}</td>
      <td>
        {{ date.nhs_number_bypassed / date.total_patient_cases | percent }}
      </td>
      <td>{{ date.identity_suggested }}</td>
      <td>{{ date.identity_suggestion_confirmed }}</td>
    </tr>
    <tr class="summary-row" *ngIf="authenticationDataSummaryRow">
      <td>
        {{ authenticationDataSummaryRow.date }}
      </td>
      <td>
        {{ authenticationDataSummaryRow.total_patient_cases }}
      </td>
      <td>
        {{
          (authenticationDataSummaryRow.nhs_login_success +
            authenticationDataSummaryRow.pds_success +
            authenticationDataSummaryRow.identity_suggestion_confirmed) /
            authenticationDataSummaryRow.total_patient_cases | percent
        }}
      </td>
      <td>
        {{ authenticationDataSummaryRow.nhs_login_success }}
      </td>
      <td>
        {{ authenticationDataSummaryRow.pds_success }}
      </td>
      <td>
        {{ authenticationDataSummaryRow.pds_failure }}
      </td>
      <td>
        {{
          authenticationDataSummaryRow.pds_success /
            authenticationDataSummaryRow.total_patient_cases | percent
        }}
      </td>
      <td>
        {{ authenticationDataSummaryRow.nhs_number_bypassed }}
      </td>
      <td>
        {{
          authenticationDataSummaryRow.nhs_number_bypassed /
            authenticationDataSummaryRow.total_patient_cases | percent
        }}
      </td>
      <td>{{ authenticationDataSummaryRow.identity_suggested }}</td>
      <td>{{ authenticationDataSummaryRow.identity_suggestion_confirmed }}</td>
    </tr>
  </tbody>
  <tbody *ngIf="downloadingAuthenticationData">
    <tr>
      <td colspan="7" class="text-center">
        <app-loading></app-loading>
      </td>
    </tr>
  </tbody>
</table>
