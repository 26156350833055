// @ts-nocheck
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Configuration } from 'app/app.constants';
import { SessionService } from 'services/session.service';
import { HttpWrapperService } from 'services/http-wrapper.service';

import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { UtilsService } from 'services/utils.service';
import { ServiceGroupService } from 'services/service-group.service';
import { addInactiveToServiceName } from 'app/utils/serviceUtils';

@Component({
  selector: 'app-superuser-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent {
  id: number;
  serviceGroupDetails: ServiceGroup;
  user: any = {};
  public selectedDetailedUser: any = {};
  public selectedUserServices = [];
  userForm: FormGroup;
  public services: FormArray;
  public delete_user: Boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private httpWrapper: HttpWrapperService,
    private sessionService: SessionService,
    private serviceGroupService: ServiceGroupService,
    private utilsService: UtilsService,
    protected toasterService: ToasterService,
    protected configuration: Configuration
  ) {
    route.params.subscribe((params) => {
      // subscribe to the result of the call completed by the router
      this.id = params['id'];
      this.user = this.sessionService.getSession();
      this.userForm = this.fb.group({
        service_group: new FormControl({ value: this.id }),
      });
      this.getServiceGroupDetails(this.id);
    });
  }

  buildServicesArray() {
    if (this.serviceGroupDetails) {
      // map the service ids to an array
      this.selectedUserServices = this.user.services.map(
        (service) => service.id
      );
    }
    const availableUserServices = this.serviceGroupDetails.services.map(
      (service) => {
        // mark the control as true if the selected user has the specific role
        if (this.selectedUserServices.indexOf(service.id) !== -1) {
          return this.fb.control(true);
        } else {
          return this.fb.control(false);
        }
      }
    );

    // Enrich services with inactive status
    this.serviceGroupDetails.services = this.serviceGroupDetails.services.map(
      (service) => {
        return {
          ...service,
          name: addInactiveToServiceName(service),
        };
      }
    );

    return this.fb.array(availableUserServices);
  }

  onSubmit(): void {
    const formValue = this.userForm.value;

    // get the ticked service ids
    const services = [];
    for (let i = 0; i < this.services.length; i++) {
      const serviceSelected = this.services.value[i];
      if (serviceSelected) {
        services.push(this.serviceGroupDetails.services[i].id);
      }
    }

    formValue.services = services;
    formValue.service_group = parseInt(formValue.service_group['value']);
    formValue.emisIntegrationEnabled =
      this.sessionService.isEmisIntegrationEnabled;
    this.changeUserDetails(this.user.id, formValue);
  }

  selectAllServiceGroups(value: boolean) {
    for (let i = 0; i < this.services.length; i++) {
      this.services.controls[i].setValue(value);
    }
  }

  getServiceGroupDetails(id: number) {
    return this.serviceGroupService
      .getServiceGroup(id)
      .then((serviceGroup: ServiceGroup) => {
        this.serviceGroupDetails = serviceGroup;
        this.services = this.buildServicesArray();
      })
      .catch(() =>
        this.toasterService.pop('danger', $localize`:@@LoadingInfoFailed:`)
      );
  }

  changeUserDetails(id: number, payload: object) {
    const language = this.utilsService.getCurrentLocale();
    return this.httpWrapper
      .deprecated_post('/users/' + id.toString() + '/transfer', payload)
      .then(() => {
        this.toasterService.pop('success', $localize`:@@UserInfoUpdated:`);
        this.sessionService
          .refreshUserData(language)
          .catch((error) => console.debug(error));
      })
      .catch(() =>
        this.toasterService.pop('danger', $localize`:@@UserInfoUpdateFailed:`)
      );
  }
}
