// @ts-nocheck
import { Component, OnInit, Input, Host } from '@angular/core';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-messages-detail-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
})
export class AppointmentComponent implements OnInit {
  @Input() message: object;
  date_formats: any;
  constructor(private utilsService: UtilsService) {
    this.date_formats = this.utilsService.getDateFormats();
  }

  ngOnInit() {}
}
