// @ts-nocheck
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasPermissionsDirective } from './has-permissions.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [HasPermissionsDirective],
  exports: [HasPermissionsDirective, CommonModule],
})
export class PermissionsModule {}

export enum Permissions {
  MESSAGE_VIEW = 'message.view',
  MESSAGE_VIEW_PERSONAL_INFO = 'message.view_personal_info',
  MESSAGE_VIEW_FREE_TEXT = 'message.view_free_text',
  MESSAGE_VIEW_MEDICAL = 'message.view_medical',
  MESSAGE_VIEW_ATTACHMENTS = 'message.view_attachments',
  MESSAGE_CHANGE_STATE = 'message.change_state',
  MESSAGE_TRANSFER = 'message.transfer',
  MESSAGE_CREATE_COMMENT = 'message.create_comment',
  MESSAGE_VIEW_COMMENT = 'message.view_comment',
  MESSAGE_VIEW_SMS = 'message.view_sms',
  MESSAGE_SEND_SMS = 'message.send_sms',
  MESSAGE_REQUEST_ATTACHMENTS = 'message.request_attachments',
  MESSAGE_VIEW_FULL_AUDIT_LOG = 'message.view_full_audit_log',

  USERS_VIEW = 'users.view',
  USERS_CREATE = 'users.create',
  USERS_EDIT = 'users.edit',
  USERS_EDIT_USER_SERVICES_FULL = 'users.edit_user_services_full',
  USERS_EDIT_USER_GROUPS = 'users.edit_user_groups',
  USERS_EDIT_JOB_ROLE = 'users.edit_job_role',
  USERS_VIEW_JOB_ROLE = 'users.view_job_role',
  USERS_VIEW_USER_GROUPS = 'users.view_user_groups',
  USERS_DELETE = 'users.delete',

  DASHBOARD_VIEW = 'dashboard.view',
  DASHBOARD_VIEW_DAILY = 'dashboard.view_daily',
  DASHBOARD_VIEW_USAGE = 'dashboard.view_usage',
  DASHBOARD_VIEW_USER_DATA = 'dashboard.view_user_data',
  DASHBOARD_VIEW_TECHNICAL = 'dashboard.view_technical',
  DASHBOARD_VIEW_MEDICAL = 'dashboard.view_medical',

  SETTINGS_VIEW = 'settings.view',
  SETTINGS_PATIENT_FORM_VIEW = 'settings.patient_form.view',
  SETTINGS_PATIENT_FORM_EDIT = 'settings.patient_form.edit',
  SETTINGS_SMS_TEMPLATES_VIEW = 'settings.sms_templates.view',
  SETTINGS_SMS_TEMPLATES_EDIT = 'settings.sms_templates.edit',
  SETTINGS_DATA = 'settings.data',
  PRO_FORM_VIEW = 'pro_form.view',

  NO_PERMISSION = 'NO_PERMISSION',
}
