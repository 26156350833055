// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { SessionService } from 'services/session.service';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent implements OnInit {
  public currentUrl = this.router.routerState.snapshot.url;
  public languages: DropdownItem[] = [];
  public baseURL: string[] = window.location.toString().split('#');
  private languageList = {
    fi: $localize`:@@fi:`,
    sv: $localize`:@@sv:`,
    en: $localize`:@@en:`,
    pt: $localize`:@@pt:`,
    nl: $localize`:@@nl:`,
  };

  private selectedLanguage = '';
  private cookie: RegExpMatchArray | string = document.cookie.match(
    `(^|;) ?cookie-language=([^;]*)(;|$)`
  );
  private URLParts = this.baseURL[0].split('/');

  constructor(
    protected router: Router,
    private sessionService: SessionService
  ) {}

  ngOnInit() {
    const cookie = this.cookie ? this.cookie[2] : null;
    this.languages = environment.availableLanguages.map((language) => {
      if (this.URLParts.indexOf(language) !== -1) {
        this.baseURL[0] = this.baseURL[0].replace(`${language}/`, '');
        this.selectedLanguage = language;
      }
      return { value: language, name: this.languageList[language] };
    });

    if (!this.selectedLanguage) {
      this.selectedLanguage = environment.defaultLocale;
    }
    if (!cookie || this.selectedLanguage === cookie || cookie === '') {
      return;
    } else if (environment.availableLanguages.indexOf(cookie) !== -1) {
      this.changeLanguage(cookie);
    }
  }

  changeLanguage(language) {
    const hundredYears = 365 * 100;
    this.sessionService.setCookie('cookie-language', language, hundredYears);
    location.href = `${this.baseURL[0]}${language}/#${this.baseURL[1]}`;
  }
}
