// @ts-nocheck
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { FeedbackComponent } from './feedback.component';

export const feedbackRoutes: Routes = [
  {
    path: '',
    component: FeedbackComponent,
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(feedbackRoutes);
