// @ts-nocheck
import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ErrorTypes } from 'app/utils/http-response.enums';
import { SessionService } from 'services/session.service';
import { EmailValidator } from 'validators';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-auth-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss'],
})
export class ResetPasswordComponent {
  public form: FormGroup;
  public username: AbstractControl;
  public password: AbstractControl;
  public repeatPassword: AbstractControl;
  public passwords: FormGroup;
  public state: string;
  public showHint = false;
  public errorType: ErrorTypes = null;
  public emailError = false;
  public validate = false;
  public currentLanguage = '';
  errorTypes = ErrorTypes;

  model: any = {};

  constructor(
    fb: FormBuilder,
    protected sessionService: SessionService,
    private utilsService: UtilsService
  ) {
    this.state = 'form-unsent';

    this.form = fb.group({
      username: [
        '',
        Validators.compose([Validators.required, EmailValidator.validate]),
      ],
    });

    this.username = this.form.controls['username'];
  }

  public resetPassword(): void {
    this.validateEmail();
    if (this.errorType) return;

    this.currentLanguage = this.utilsService.getCurrentLocale();

    this.sessionService
      .resetPassword(this.model.username, this.currentLanguage)
      .then(() => (this.state = 'form-success'))
      .catch((error) => {
        if (!error.status) {
          this.errorType = ErrorTypes.unknown;
        }
        this.errorType = error.status;
        this.state = 'form-error';
      });
  }

  public validateEmail(): void {
    if (this.validate) {
      const EMAIL_REGEXP =
        /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])+$/i;
      const isValidEmail = EMAIL_REGEXP.test(this.model.username);
      this.errorType = isValidEmail ? null : ErrorTypes.invalidEmail;
    }
  }
}
