<div class="top-three">
  <div class="top-three-container">
    <div class="item" *ngIf="items[0]">
      <h1 class="title">{{ items[0].title }}</h1>
      <h1 class="number">{{ items[0].value }}</h1>
    </div>
  </div>
  <div class="top-three-container">
    <div class="item" *ngIf="items[1]">
      <h1 class="title">{{ items[1].title }}</h1>
      <h1 class="number">{{ items[1].value }}</h1>
    </div>
    <div class="item" *ngIf="items[2]">
      <h1 class="title">{{ items[2].title }}</h1>
      <h1 class="number">{{ items[2].value }}</h1>
    </div>
  </div>
</div>
