// @ts-nocheck
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DashboardService } from 'services/dashboard.service';

export type DropdownTranslations = {
  deselectAll: string;
  selectAll: string;
  noUnitsAvailable: string;
  allUnitsSelected: string;
  noUnitsSelected: string;
  nUnitsSelected: string;
};

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnDestroy {
  @Input()
  public translations: DropdownTranslations;
  @Input()
  public options: DropdownItem[] = [];
  @Output()
  public selectedOptions: EventEmitter<DropdownItem[]> = new EventEmitter<
    DropdownItem[]
  >();

  public disabled = false;
  public open = false;
  public loading = false;

  public get isAllSelected(): boolean {
    return this.currentSelection.length === this.options.length;
  }
  public get headline(): string {
    if (this.disabled && this.currentSelection.length === 0)
      return this.translations.noUnitsAvailable;

    if (this.isAllSelected) return this.translations.allUnitsSelected;

    if (this.currentSelection.length > 1)
      return `${this.currentSelection.length} ${this.translations.nUnitsSelected}`;

    if (this.currentSelection.length === 1)
      return this.currentSelection[0].name;

    return this.translations.noUnitsAvailable;
  }

  public get currentSelection() {
    return this.options.filter((option) => option.checked);
  }

  constructor(private dashboardService: DashboardService) {
    this.dashboardService.loadingState$.subscribe(
      (state) => (this.loading = state)
    );
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  toggleDropdown(): void {
    if (!this.disabled) {
      this.open = !this.open;
    }
    this.updateDisabledState();
  }
  toggleOption(index: number) {
    this.options[index].checked = !this.options[index]?.checked || false;
    this.updateDisabledState();
  }
  toggleAllOptions() {
    // if all selected, deselect all
    if (this.isAllSelected) {
      this.options.forEach((option) => (option.checked = false));
    } else {
      this.options.forEach((option) => (option.checked = true));
    }

    this.updateDisabledState();
  }
  applySelectedOptions() {
    this.selectedOptions.emit(this.currentSelection);
    this.updateDisabledState();
    this.toggleDropdown();
  }

  private updateDisabledState() {
    this.disabled = this.loading;
    this.disabled = this.options.length === 0;
    this.disabled = this.currentSelection.length === 0;
  }
}
