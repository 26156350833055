// @ts-nocheck
export class PersonalInfoItem {
  /**
   * Base class for showing personal information for a patient case. If using the base
   * class becomes too complicated, you can always extend it with a subclass.
   *
   * @param key - unique key for the item.
   * @param header - translated header.
   * @param value - value to show.
   * @param show_copy - Whether to show copy icon or not.
   * @param show_exclamation  - Whether to show exclamation icon or not.
   * @param show_valid_icon  - Whether to show valid icon or not.
   */
  constructor(
    public readonly key: string,
    public header: string,
    public value: string | Array<string>,
    public show_copy: boolean = false,
    public show_exclamation: boolean = false,
    public show_valid_icon: boolean = false
  ) {}
}
