// @ts-nocheck
import {
  customOptionsBarPlugin,
  customOptionsPie,
  defaultOptions,
} from '../configs/chartJSDefaultConfigs';
import { statusColors, urgencyColors } from '../configs/colors';

const translations = [
  $localize`:@@sidebar_daily:`,
  $localize`:@@sidebar_dashboard:`,
];

export const daily: View = {
  daily: {
    id: 'dashboard',
    charts: [
      {
        chart_config: {
          id: 'status',
          legend: $localize`:@@dailyViewStats:`,
          type: ChartType.PIE,
          options: customOptionsPie,
        },
        group_by: GroupBy.XLABEL,
        datasets: ['status'],
        custom_colors: statusColors,
        match_custom_colors_to_dataset_label: true,
        translations: {
          labels: {
            overdue: $localize`:@@overdue:`,
            new: $localize`:@@new:`,
            processing: $localize`:@@processing:`,
            archived: $localize`:@@archived:`,
          },
        },
      },
      {
        chart_config: {
          id: 'avg_time',
          legend: $localize`:@@dailyViewProcessing:`,
          type: ChartType.BAR,
          options: defaultOptions,
        },
        group_by: GroupBy.NONE,
        datasets: ['median_time'],
        translations: {
          labels: {
            med_processing_to_ready: $localize`:@@avg_processing_to_ready:`,
            med_creation_to_first_opened: $localize`:@@avg_creation_to_first_opened:`,
          },
        },
      },
      {
        chart_config: {
          id: 'diagnoses',
          legend: $localize`:@@dailyViewConditions:`,
          type: ChartType.BAR,
          options: defaultOptions,
          linked_chart: {
            view: 'medical',
            chartgroup: 'conditions',
            chart: 'conditions',
          },
        },
        group_by: GroupBy.NONE,
        sort_by: SortBy.DESC,
        datasets: ['diagnoses'],
        translations: {
          labels: { diagnoses: 'diagnoses' },
        },
      },
      {
        chart_config: {
          id: 'appointment_types',
          legend: $localize`:@@dailyViewAppointmentTypes:`,
          type: ChartType.BAR,
          options: defaultOptions,
          linked_chart: {
            view: 'usage',
            chartgroup: 'contacts-by-type',
            chart: 'contacts-by-type',
          },
        },
        group_by: GroupBy.NONE,
        sort_by: SortBy.DESC,
        datasets: ['appointment_types'],
        translations: {
          labels: { appointment_types: 'appointment_types' },
        },
      },
      {
        chart_config: {
          id: 'priorities',
          legend: $localize`:@@dailyViewStatus:`,
          type: ChartType.HORIZONTAL_BAR,
          options: defaultOptions,
        },
        custom_colors: urgencyColors,
        match_custom_colors_to_dataset_label: true,
        group_by: GroupBy.TOTAL,
        datasets: ['self_care', 'non_urgent', 'urgent', 'emergency'],
        custom_groupings: [
          {
            datasets: ['self_care_female', 'self_care_male'],
            label: 'self_care',
          },
          {
            datasets: ['non_urgent_female', 'non_urgent_male'],
            label: 'non_urgent',
          },
          {
            datasets: ['urgent_female', 'urgent_male'],
            label: 'urgent',
          },
          {
            datasets: ['emergency_female', 'emergency_male'],
            label: 'emergency',
          },
        ],
        translations: {
          labels: {
            priorities: $localize`:@@dailyViewStatus:`,
            emergency: $localize`:@@emergency:`,
            self_care: $localize`:@@self_care:`,
            urgent: $localize`:@@urgent:`,
            non_urgent: $localize`:@@non_urgent:`,
          },
        },
      },
      {
        chart_config: {
          id: 'contacts-by-day',
          legend: $localize`:@@contactsByDay:`,
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.NONE,
        datasets: ['appointments_per_day'],
        translations: {
          labels: {
            appointments_per_day: $localize`:@@contactsByDay:`,
          },
        },
      },
      {
        chart_config: {
          id: 'contacts-by-hour',
          legend: $localize`:@@contactsByHour:`,
          type: ChartType.LINE,
          options: defaultOptions,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.HOUR,
        datasets: ['appointments_per_day'],
        translations: {
          labels: {
            appointments_per_day: $localize`:@@contactsByHour:`,
          },
        },
      },
    ],
  },
};
