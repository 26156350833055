// @ts-nocheck
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionsModule } from 'app/shared/permissions/permissions.module';
import { WelcomeModule } from 'app/welcome/welcome.module';
import { DetailModule } from './detail/detail.module';
import { FollowupModule } from './followup/followup.module';
import { ListModule } from './list/list.module';
import { UtilsModule } from 'app/utils/utils.module';

import { MessagesComponent } from './messages.component';
import { MovingToRecordComponent } from './moving-to-record/moving-to-record.component';
import { ConnectWithPRSComponent } from './connect-with-prs/connect-with-prs.component';
import { SymptomsService } from '../shared/services/symptoms.service';
import { PracticeCodeSelection } from './practice-code-selection/practice-code-selection.component';
import { SelectCaseDetailsComponent } from './select-case-details/select-case-details.component';
import { AppMaterialModule } from 'app/app-material/app-material.module';
import { IntegrationTransactionLogComponent } from './integration-transaction-log/integration-transaction-log.component';
import { RequestStatusComponent } from './select-case-details/request-status/request-status.component';
import { IdentityLinkComponent } from './identity-link/identity-link.component';

@NgModule({
  imports: [
    CommonModule,
    DetailModule,
    ListModule,
    FollowupModule,
    WelcomeModule,
    PermissionsModule,
    UtilsModule,
    AppMaterialModule,
  ],
  exports: [],
  declarations: [
    MessagesComponent,
    MovingToRecordComponent,
    ConnectWithPRSComponent,
    PracticeCodeSelection,
    SelectCaseDetailsComponent,
    IntegrationTransactionLogComponent,
    RequestStatusComponent,
    IdentityLinkComponent,
  ],
  providers: [SymptomsService],
})
export class MessagesModule {}
