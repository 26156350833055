// @ts-nocheck
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { Permissions } from 'permissions/permissions.module';
import { Subscription } from 'rxjs';
import {
  AttachmentApiResponse,
  AttachmentService,
  AttachmentImage,
} from 'services/attachment.service';
import { MessageService } from 'services/message.service';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';
import { UtilsService } from 'services/utils.service';
import { LoadingStatus } from 'enums';

type safeResourceUrlImage = {
  id: number;
  file: {
    changingThisBreaksApplicationSecurity: string;
  };
  fileType: string;
};

@Component({
  selector: 'app-messages-detail-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public message: object;

  public hasPicturePermission: boolean;
  public session: object = null;
  public pictures: AttachmentImage[] = [];
  public processed_pictures: safeResourceUrlImage[] = [];
  public hidden_pictures = 0;
  public slideIndex = 0;
  public modalRef: BsModalRef;
  public modalRef2: BsModalRef;
  public attachmentState = LoadingStatus.NONE;
  public showInstructions = false;

  /**
   * A bucket to store all subscriptions.
   * All subscriptions from the bucket will be unsubscribed when the component is destroyed.
   */
  private _subBucket = Array<Subscription>();
  private currentImage: object = null;

  public isRequestAttachmentsEnabled: boolean =
    this.sessionService.isRequestAttachmentsEnabled;

  public readonly permissions = Permissions;

  constructor(
    protected messageService: MessageService,
    private modalService: BsModalService,
    protected utilsService: UtilsService,
    protected sessionService: SessionService,
    protected stateService: StateService,
    private attachmentService: AttachmentService
  ) {
    this.session = sessionService.getSession();
  }

  ngOnInit() {
    this.hasPicturePermission = this.sessionService.checkPermissions(
      this.permissions.MESSAGE_VIEW_ATTACHMENTS
    );

    this._subBucket.push(
      this.stateService.state$.subscribe((state) => {
        switch (state['key']) {
          case StateKeys.loadedServiceGroup:
            this.isRequestAttachmentsEnabled =
              this.sessionService.isRequestAttachmentsEnabled;
            break;
        }
      })
    );
  }

  ngOnChanges(): void {
    if (this.message === null) return;
    this.loadAttachments();
  }

  /**
   * @deprecated use the attachmentService.getImages instead
   * Unfortunately this method does some other things as well, so leaving it here for now
   */
  processAttachments(attachments: AttachmentApiResponse) {
    if (attachments.id !== this.message['id']) return; // Redundant check?

    this.pictures = attachments.images; // AttachmentService.getImages returns the same thing

    this.processed_pictures = this.pictures
      .filter((picture) => picture.file)
      .map((picture) => {
        return {
          id: picture.id,
          // The type casting to any is a workaround the strange typing for safeResourceUrlImage.file.
          file: this.utilsService.createImgDataURL(picture.file) as any,
          fileType: picture.file_extension,
        } as safeResourceUrlImage;
      }); // This mapping can be removed when using attachmentService.getImages
    this.hidden_pictures = this.pictures.filter(
      (picture) => !picture.file
    ).length;
  }

  loadAttachments() {
    this.attachmentState = LoadingStatus.LOADING;
    this.pictures = null;
    this.hidden_pictures = 0;
    this.slideIndex = 0;

    this._subBucket.push(
      this.attachmentService.getAttachments(this.message['id']).subscribe(
        (data) => {
          this.processAttachments(data);
          this.attachmentState = LoadingStatus.SUCCESS;
        },
        (error) => {
          this.attachmentState = LoadingStatus.FAILED;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this._subBucket.forEach((sub) => sub?.unsubscribe());
  }

  openModal(template: TemplateRef<any>, index: number) {
    // In the future we'll fetch the images when the button is pressed, for now just log the action in the backend
    this.messageService.openAttachments(this.message['id']);

    const options: ModalOptions = {
      class: 'modal-lg black-modal',
    };
    this.showSlides(index);
    this.modalRef = this.modalService.show(template, options);
  }

  openConfirmationModal(template: TemplateRef<any>, image: object) {
    this.modalRef2 = this.modalService.show(template, { class: 'second' });
    this.currentImage = image;
  }

  plusSlides(n) {
    this.showSlides(this.slideIndex + n);
  }

  currentSlide(n) {
    this.showSlides(n);
  }

  showSlides(n) {
    if (n >= 0 && n < this.processed_pictures.length) {
      this.slideIndex = n;
    }
  }

  hideImage() {
    const index = this.slideIndex;
    if (this.slideIndex === this.processed_pictures.length - 1) {
      this.slideIndex = this.processed_pictures.length - 2;
    }
    this.processed_pictures.splice(index, 1);
    this.hidden_pictures += 1;

    const data = {
      id: this.currentImage['id'],
      hidden: true,
    };
    this.messageService.hideAttachment(this.currentImage['id'], data);

    this.currentImage = null;
    this.modalRef2.hide();
  }

  downloadImage(image: safeResourceUrlImage, idx: number) {
    const extension = image.fileType;
    const downloadName = `attachment-${idx + 1}`;
    const link = document.createElement('a');
    link.href = image.file.changingThisBreaksApplicationSecurity;
    link.download = extension ? `${downloadName}${extension}` : downloadName;
    link.click();
  }
}
