// @ts-nocheck
import { DropdownItem } from 'components/atoms/simple-dropdown/simple-dropdown.component';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { SessionService } from 'services/session.service';
import { UserService } from 'services/user.service';
import { getNestedValue } from 'app/shared/methods/getNestedValue';

@Component({
  selector: 'app-user-authentication-settings',
  templateUrl: './user-authentication-settings.component.html',
  styleUrls: ['./user-authentication-settings.component.scss'],
})
export class UserAuthenticationComponent implements OnInit {
  @Input()
  public userDetails: object;

  @ViewChild('mfaVerificationModal', { static: true }) mfaVerificationModal;
  public mfaVerificationModalVisible: boolean = false;

  public translations = {
    selectMFAMethod: $localize`:@@userSelectedAuthenticationMethod:`,
    selectedMFAMethod: $localize`:@@selectUserAuthenticationMethod:`,
    backButtonText: $localize`:@@return:`,
  };

  public authenticationMethods: Array<DropdownItem> = [];

  public userId: number = null;
  public mfaEnabled: boolean = false;

  public formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private sessionService: SessionService,
    private userService: UserService
  ) {
    this.formGroup = this.formBuilder.group({
      mfaMethod: new FormControl(),
    });
  }

  ngOnInit() {
    const session = this.sessionService.getSession();

    if (!session) {
      this.toasterService.pop('danger', 'Failed to get session');
      return;
    }

    // Set the user's current method as the form value
    const currentMFAMethod = getNestedValue(this.userDetails, 'mfa_method');
    this.updateValue('mfaMethod', currentMFAMethod);
    this.mfaEnabled = currentMFAMethod !== null;

    // Populate the available mfa methods list
    this.authenticationMethods = this.generateAvailableMFAMethods();
  }

  generateAvailableMFAMethods(): Array<DropdownItem> {
    const available_mfa_methods = getNestedValue(
      this.userDetails,
      'available_mfa_methods'
    );

    if (!available_mfa_methods) {
      return [];
    }

    return available_mfa_methods.map((method: object) => {
      return new DropdownItem(method['name'], method['name']);
    });
  }

  updateValue(key, value) {
    this.formGroup.controls[key].setValue(value);
  }

  toggleMfaVerificationModal(show: boolean) {
    this.mfaVerificationModalVisible = show;
    if (show) {
      this.mfaVerificationModal.show();
    } else {
      this.mfaVerificationModal.hide();
    }
  }

  saveChanges() {
    this.toggleMfaVerificationModal(false);

    // Update user's mfa settings
    const userID = getNestedValue(this.userDetails, 'id');
    const newMethod = this.mfaEnabled
      ? null
      : this.formGroup.controls['mfaMethod'].value;

    this.userService
      .updateUserMfaSettings(newMethod, userID)
      .then(() => {
        this.toasterService.pop(
          'success',
          $localize`:@@userMFASettingsUpdatedSuccessToasterText:`
        );
        this.mfaEnabled = newMethod !== null;
        if (this.mfaEnabled === false) {
          this.updateValue('mfaMethod', null);
        }
      })
      .catch(() =>
        this.toasterService.pop(
          'danger',
          $localize`:@@userMFASettingsUpdatedFailedToasterText:`
        )
      );
  }
}
