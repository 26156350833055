<div class="btn-group" *ngIf="session">
  <div class="btn-group" dropdown id="header-dropdown">
    <div class="user-info-container">
      <p class="service-group-display">{{ session.service_group.name }}</p>
      <p class="user-first-name">
        {{ session.first_name }} {{ session.last_name }}
      </p>
      <p class="job">{{ session.job_role }}</p>
    </div>

    <button
      id="header-dropdown-toggle"
      dropdownToggle
      type="button"
      class="btn dropdown-toggle">
      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
    </button>
    <ul class="dropdown-menu" role="menu" id="lock-dropdown">
      <div class="sidebar-section">
        <li role="menuitem" class="menu-own-data">
          <a class="dropdown-item" routerLink="/settings/my-account">
            <div class="icon-container">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            <div class="dropdown-choice" i18n="@@userDropdownMyAccount">
              Omat Tiedot
            </div>
          </a>
        </li>
        <li role="menuitem" class="menu-own-data">
          <a class="dropdown-item" [routerLink]="['feedback']">
            <div class="icon-container">
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </div>
            <div class="dropdown-choice" i18n="@@sendFeedback">
              Lähetä palautetta
            </div>
          </a>
        </li>
        <li
          *ngIf="session.is_staff || session.user_service_groups?.length > 1"
          role="menuitem"
          class="menu-own-data">
          <a class="dropdown-item" [routerLink]="['superuser']">
            <div class="icon-container">
              <i class="fa fa-users" aria-hidden="true"></i>
            </div>
            <div class="dropdown-choice" i18n="@@userDropdownChooseClient">
              Valitse Asiakas
            </div>
          </a>
        </li>
        <li *ngIf="hasSettingsPermission" role="menuitem" class="menu-own-data">
          <a class="dropdown-item" [routerLink]="['settings']">
            <div class="icon-container">
              <i class="fa fa-sliders" aria-hidden="true"></i>
            </div>
            <div class="dropdown-choice" i18n="@@settingsHeader">Settings</div>
          </a>
        </li>
        <li role="menuitem" class="menu-own-data">
          <a
            class="dropdown-item"
            href="https://help.klinikhealthcaresolutions.com/knowledge/klinik-access-changelog"
            target="_blank"
            rel="noopener">
            <div class="dropdown-choice" i18n="@@changelogHeader">
              Changelog
            </div>
            <div class="icon-container">
              <i class="fa fa-external-link" aria-hidden="true"></i>
            </div>
          </a>
        </li>

        <li role="menuitem" class="menu-own-data" *ngIf="hasKipPermission">
          <a class="dropdown-item" [routerLink]="['kip-installer']">
            <div class="icon-container">
              <i class="fa fa-sliders" aria-hidden="true"></i>
            </div>
            <div class="dropdown-choice" i18n="@@settingsHeader">
              KIP Settings
            </div>
          </a>
        </li>

        <li id="version-display" *ngIf="version">
          <span i18n="@@version">Version:</span> {{ version }}
        </li>

        <li
          id="header-dropdown-toggle-logout"
          role="menuitem"
          class="menu-logout">
          <a class="dropdown-item poweroff" (click)="logout()">
            <div class="icon-container">
              <i class="fa fa-power-off" aria-hidden="true"></i>
            </div>
            <div class="dropdown-choice" i18n="@@userDropdownLogOut">
              KIRJAUDU ULOS
            </div>
          </a>
        </li>
      </div>
    </ul>
  </div>
</div>
