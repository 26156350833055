// @ts-nocheck
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DatepickerService {
  private readonly today = moment();
  private datePickerState = {
    startDate: this.today.format('YYYY-MM-DD'),
    endDate: this.today.format('YYYY-MM-DD'),
    selectedQuickFilter: 0,
  };
  private _datePickerState = new BehaviorSubject<DatePickerObject>(
    this.datePickerState
  );

  public state$: Observable<DatePickerObject> =
    this._datePickerState.asObservable();

  update(datePickerObject: DatePickerObject): void {
    this.datePickerState = datePickerObject;
    this._datePickerState.next(this.datePickerState);
  }
}
