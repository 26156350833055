// @ts-nocheck
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { UtilsService } from 'services/utils.service';

/**
 * This pipe is used to format a simple short date based on the current locale, eg. '22.11.2023'.
 */
@Pipe({
  name: 'KlinikShortDate',
})
export class KlinikShortDatePipe implements PipeTransform {
  constructor(public utilsService: UtilsService) {}

  /**
   * @param value The date expression: a `Date` object,  a number
   * (milliseconds since UTC epoch), or an ISO string (https://www.w3.org/TR/NOTE-datetime).
   * @returns A date string in the desired format.
   */
  transform(value: any): string | null {
    let utilsLocale = this.utilsService.currentLocale;

    return formatLocaleShortDate(value, utilsLocale);
  }
}

export function formatLocaleShortDate(value: any, locale: string) {
  if (locale === 'en') {
    locale = 'en-gb';
  }
  return moment(value).locale(locale).format('L');
}
