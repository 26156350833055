<form>
  <h1 i18n="@@changePassword">Change password</h1>

  <p class="work-in-progress">
    Feature is available internally for testing.<br />Will be released for users
    once error handling is included and feature has been deemed to work.
  </p>

  <p i18n="@@passwordRules">Password rules</p>
  <ul>
    <li i18n="@@passwordMinLength">Password has at least 8 characters.</li>
    <li i18n="@@passwordCharacterCasing">
      Password has both uppercase and lower case characters.
    </li>
    <li i18n="@@passwordNumber">Password has at least one number.</li>
    <li i18n="@@passwordNoCommon">
      Do not use common or easy to quess passwords such as Passw0rd.
    </li>
  </ul>

  <text-input
    (updatedEvent)="updateControlValue(passwordLabels.CURRENT_PASSWORD, $event)"
    [autocomplete]="'current-password'"
    [value]="currentPasswordControl.value"
    [label]="currentPasswordTranslation"
    [type]="'password'">
  </text-input>
  <text-input
    (updatedEvent)="updateControlValue(passwordLabels.NEW_PASSWORD, $event)"
    [autocomplete]="'password'"
    [value]="newPasswordControl.value"
    [label]="newPasswordTranslation"
    [type]="'password'">
  </text-input>
  <text-input
    (updatedEvent)="
      updateControlValue(passwordLabels.RETYPED_NEW_PASSWORD, $event)
    "
    [autocomplete]="'password'"
    [value]="retypedNewPasswordControl.value"
    [label]="retypeNewPasswordTranslation"
    [type]="'password'">
  </text-input>

  <button class="action" i18n="@@changePassword" (click)="changePassword()">
    Change password
  </button>
</form>
