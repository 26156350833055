<div
  class="dropdown"
  [ngClass]="{ unclickable: loading }"
  [tabindex]="1"
  [attachOutsideOnClick]="true"
  [clickOutsideEnabled]="open"
  (clickOutside)="toggleDropdown()"
  (keyup.enter)="applySelectedOptions()"
  (keyup.esc)="toggleDropdown()">
  <div
    class="dropdown-headline"
    [ngClass]="{
      'dropdown-headline--open': open,
      'dropdown-headline--disabled': disabled
    }"
    (click)="toggleDropdown()">
    <p>{{ headline }}</p>
    <i class="arrow fa fa-angle-down" [ngClass]="{ 'arrow--open': open }"></i>
  </div>
  <div class="dropdown-body" *ngIf="open">
    <div class="dropdown-item" (click)="toggleAllOptions()">
      <input
        type="checkbox"
        name="select-all"
        [value]="null"
        [(checked)]="isAllSelected" />
      <label for="select-all">{{
        isAllSelected ? translations.deselectAll : translations.selectAll
      }}</label>
    </div>
    <div class="dropdown-divider"></div>
    <div
      *ngFor="let option of options; let index = index"
      class="dropdown-item"
      (click)="toggleOption(index)">
      <input
        type="checkbox"
        [name]="option.value"
        [value]="option.value"
        [ngClass]="{ secondary: option.isSecondary }"
        [(checked)]="option.checked" />
      <label [for]="option.value">{{ option.name }}</label>
    </div>
    <div class="dropdown-divider"></div>
    <div class="dropdown-footer">
      <button
        class="btn btn-apply"
        (click)="applySelectedOptions()"
        [disabled]="disabled"
        i18n="@@DropdownApply">
        Apply
      </button>
    </div>
  </div>
</div>
