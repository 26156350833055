// @ts-nocheck
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { EXTERNAL_FORWARD_TYPES } from 'services/integration.service';
import { SessionService } from 'services/session.service';

@Component({
  selector: 'app-practice-code-selection',
  templateUrl: './practice-code-selection.component.html',
  styleUrls: ['./practice-code-selection.component.scss'],
})
export class PracticeCodeSelection {
  @Output()
  private integrationActionEmitter: EventEmitter<EXTERNAL_FORWARD_TYPES> = new EventEmitter();

  @Output()
  private cancelEmitter: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public readonly currentIntegrationAction: EXTERNAL_FORWARD_TYPES = null;

  public integrationAction = (): void =>
    this.integrationActionEmitter.emit(this.currentIntegrationAction);
  public cancel = (): void => this.cancelEmitter.emit(false);
  public practiceCodes: PracticeCode[] =
    this.sessionService.serviceGroup.practice_codes;
  public practiceCodeSelected: false | string = false;

  public readonly integrationActions = EXTERNAL_FORWARD_TYPES;

  constructor(protected sessionService: SessionService) {}

  private SELECTED_PRACTICE_CODE_KEY: string = 'selectedPracticeCode';
  private REMEMBER_SELECTED_PRACTICE_CODE_KEY: string =
    'rememberSelectedPracticeCode';
  public rememberSelectedPracticeCode: boolean = false;

  ngOnInit() {
    if (this.sessionService.getData(this.REMEMBER_SELECTED_PRACTICE_CODE_KEY)) {
      this.rememberSelectedPracticeCode = true;
      const selectedPracticeCode = this.sessionService.getData(
        this.SELECTED_PRACTICE_CODE_KEY
      );
      this.selectPracticeCode(selectedPracticeCode);
    }
  }

  toggleRememberSelectedPracticeCode() {
    this.rememberSelectedPracticeCode = !this.rememberSelectedPracticeCode;
  }

  selectPracticeCode(practiceCode: PracticeCode) {
    this.practiceCodeSelected = practiceCode.code;
    this.sessionService.setData(this.SELECTED_PRACTICE_CODE_KEY, practiceCode);
  }

  rememberSelectionAndContinue() {
    this.sessionService.setData(
      this.REMEMBER_SELECTED_PRACTICE_CODE_KEY,
      this.rememberSelectedPracticeCode
    );
    this.integrationAction();
  }
}
