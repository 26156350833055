// @ts-nocheck
import { Component } from '@angular/core';

@Component({
  selector: 'app-kip-installer',
  templateUrl: './kip-installer.component.html',
  styleUrls: ['./kip-installer.component.scss'],
})
export class KipInstallerComponent {
  public activeCard = 0;
  public cards = [
    {
      action: 'downloadKip',
      state: 1,
      translations: {
        header: $localize`:@@download:`,
        text: $localize`:@@kipInstallerDownloadText:`,
        button: $localize`:@@kipInstallerDownloadButton:`,
      },
    },
    {
      action: 'continue',
      state: 0,
      translations: {
        header: $localize`:@@kipInstallerInstallHeader:`,
        text: $localize`:@@kipInstallerInstallText:`,
        button: $localize`:@@kipInstallerInstallButton:`,
      },
    },
    {
      action: 'testKipConnection',
      state: 0,
      translations: {
        header: $localize`:@@kipInstallerTestKIPHeader:`,
        text: $localize`:@@kipInstallerTestKIPText:`,
        button: $localize`:@@kipInstallerTestKIPButton:`,
      },
    },
    {
      action: 'addPhrName',
      state: 0,
      translations: {
        header: $localize`:@@kipInstallerUsernameHeader:`,
        text: $localize`:@@kipInstallerUsernameText:`,
        button: $localize`:@@kipInstallerUsernameButton:`,
      },
    },
    {
      action: 'testPhrConnection',
      state: 0,
      translations: {
        header: $localize`:@@kipInstallerTestPHRHeader:`,
        text: $localize`:@@kipInstallerTestPHRText:`,
        button: $localize`:@@kipInstallerTestPHRButton:`,
      },
    },
  ];

  constructor() {}

  updateCards(index: number): void {
    if (this.cards[index + 1]) {
      this.cards[index + 1].state = 1;
      this.cards = [...this.cards];
    }
  }
}
