// @ts-nocheck
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { ErrorTypes, SuccessTypes } from 'app/utils/http-response.enums';
import { SessionService } from 'services/session.service';
import { getNestedValue } from '../methods/getNestedValue';

@Injectable()
export class SessionGuard implements CanActivate {
  constructor(private sessionService: SessionService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const response = await this.sessionService.isSessionValid();

      if (getNestedValue(response, 'session') !== SuccessTypes.valid) {
        this.sessionService.logout();
        return false;
      }
    } catch (error) {
      const errorCode = getNestedValue(error, 'error', 'code');
      if (errorCode === ErrorTypes.unauthorized_session_code) {
        // If user does not have a session, SessionInterceptor will logout the user
        return false;
      }
    }

    // Passed all checks
    return true;
  }
}
