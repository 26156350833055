// @ts-nocheck
import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface PromptTranslations {
  header: string;
  prompt: string;
  yes: string;
  no: string;
}

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent {
  @Input()
  public translations: {
    header: string;
    prompt: string;
    yes: string;
    no: string;
  };

  @Output()
  public clickEmitter: EventEmitter<boolean> = new EventEmitter();

  emitClick(value: boolean): void {
    this.clickEmitter.emit(value);
  }
}
