// @ts-nocheck
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';

interface ILogTableItem {
  status: IntegrationTransactionStatus;
  time: string; // IsoString
  actor: string;
}

@Component({
  selector: 'app-integration-transaction-log',
  templateUrl: './integration-transaction-log.component.html',
  styleUrls: ['./integration-transaction-log.component.scss'],
})
export class IntegrationTransactionLogComponent implements OnInit, OnChanges {
  @Input() transactions$: Observable<IntegrationTransaction[]>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource: MatTableDataSource<ILogTableItem>;
  public logTableColumns: string[] = [
    'status',
    'time',
    'actor',
    'http_status_code',
    'destination',
  ];
  private _subBucket: Subscription[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._subBucket.forEach((sub) => sub?.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.transactions$?.currentValue) return;
    this._subBucket.push(
      this.transactions$.subscribe((transactions) =>
        this.updateDataSource(transactions)
      )
    );
  }

  private updateDataSource(transactions: IntegrationTransaction[]): void {
    this.dataSource = new MatTableDataSource<ILogTableItem>();
    this.dataSource.data = this.formatToLogTableData(transactions);
    this.dataSource.paginator = this.paginator;
  }

  private formatToLogTableData(
    transactions: IntegrationTransaction[]
  ): ILogTableItem[] {
    return transactions.map((transaction) => {
      return {
        status: transaction.status,
        time: transaction.created_at,
        actor: this.getActorName(transaction.actor),
        destination: transaction.destination,
        httpStatusCode: transaction.http_status_code,
      };
    });
  }

  private getActorName(actor: IntegrationActor | null): string {
    if (!actor) return $localize`:@@system:System`;
    return `${actor.first_name} ${actor.last_name}`;
  }

  public getStatusIcon(status: IntegrationTransactionStatus): string {
    switch (status) {
      case IntegrationTransactionStatus.PENDING:
        return 'cached';
      case IntegrationTransactionStatus.SUCCESS:
        return 'check_circle';
      case IntegrationTransactionStatus.FAILURE:
        return 'warning';
      default:
        return 'warning';
    }
  }

  public getStatusColor(status: IntegrationTransactionStatus): string {
    switch (status) {
      case IntegrationTransactionStatus.PENDING:
        return '#005493';
      case IntegrationTransactionStatus.SUCCESS:
        return '#67A734';
      case IntegrationTransactionStatus.FAILURE:
        return '#E93A3A';
      default:
        return 'red';
    }
  }

  public getStatusText(status: IntegrationTransactionStatus): string {
    switch (status) {
      case IntegrationTransactionStatus.PENDING:
        return 'Pending';
      case IntegrationTransactionStatus.SUCCESS:
        return 'Success';
      case IntegrationTransactionStatus.FAILURE:
        return 'Failure';
      default:
        return 'Unknown';
    }
  }
}
