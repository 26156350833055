// @ts-nocheck
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AuthComponent } from './auth.component';
import { CurrentUserComponent } from './currentuser/currentuser.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
import { SetPasswordComponent } from './setpassword/setpassword.component';
import { LanguageDropdownComponent } from './language-dropdown/language-dropdown.component';
import { MFAVerificationComponent } from './mfa-verification/mfa-verification.component';
import { UtilsModule } from 'app/utils/utils.module';
import { AppMaterialModule } from 'app/app-material/app-material.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    UtilsModule,
    AppMaterialModule,
  ],
  exports: [CurrentUserComponent, MFAVerificationComponent],
  declarations: [
    CurrentUserComponent,
    LoginComponent,
    SetPasswordComponent,
    ResetPasswordComponent,
    LogoutComponent,
    AuthComponent,
    LanguageDropdownComponent,
    MFAVerificationComponent,
  ],
})
export class AuthModule {}
