// @ts-nocheck
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { Subscription } from 'rxjs';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';
import { UserService } from 'services/user.service';
import { UtilsService } from 'services/utils.service';
import { EmailValidator } from 'validators';
import { PhoneNumberValidator } from 'validators';
import { environment } from 'environments/environment';
import { UserStatus } from 'services/user.service';
import { getNestedValue } from 'app/shared/methods/getNestedValue';
import { DropdownItem } from 'components/atoms/simple-dropdown/simple-dropdown.component';
import { addInactiveToServiceName } from 'app/utils/serviceUtils';

export enum UserDetailsModalType {
  MY_ACCOUNT = 0,
  EDIT_USER = 1,
  CREATE_USER = 2,
}

@Component({
  selector: 'app-users-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public modalType: UserDetailsModalType;

  @Input()
  public openedInPopup: boolean = false;

  @Input()
  public userDetails: User;

  @Output()
  private userDetailsUpdated: EventEmitter<any> = new EventEmitter();

  @Output()
  private cancelEvent: EventEmitter<any> = new EventEmitter();

  public readonly permissions = Permissions;

  /**
   * Returns true if the currently logged-in user is a staff user.
   */
  public get loggedInUserIsStaff(): boolean {
    return this.sessionService.userIsStaff;
  }

  /**
   * Returns true if the selected user is a staff user.
   *
   * Note: Using the is_staff field for this purpose is not completely accurate,
   * as the user may still be missing the required system_admin group. */
  public get selectedUserIsStaff(): boolean {
    return this.userDetails.is_staff;
  }

  /**
   * Indicates that the staff buttons should be disabled.
   * This is a workaround to prevent multiple clicks on the staff buttons, because
   * the userDetails input is not updated after the staff status is changed.
   */
  isStaffButtonDisabled: boolean;

  /** @deprecated Use the userDetails input instead.  */
  public selectedDetailedUser: any = {}; // soon to be obsolete
  public validationFromServer = false;
  public currentUrl = this.router.routerState.snapshot.url;
  public ssnHidden = false;
  public userCanEdit = true;
  public inputDisabled: boolean = false;

  public readonly session = this.sessionService.getSession();
  public readonly emisIntegrationEnabled: boolean =
    this.sessionService.isEmisIntegrationEnabled;

  userForm: FormGroup;
  // basic data
  public first_name: AbstractControl;
  public last_name: AbstractControl;
  public username: AbstractControl;
  public ssn: AbstractControl;
  public phone_number: AbstractControl;
  public services_ids: AbstractControl;
  public groups_ids: AbstractControl;
  public job_role: AbstractControl;
  // only available to admin
  public status: AbstractControl; // obsolete

  public groups: Array<object> = [];
  public services: Array<object> = [];
  public jobRoles: Array<DropdownItem> = [];

  public delete_user: Boolean = false;
  public objectKeys = Object.keys; // obsolete
  public showDescription = false;
  public userstatus = UserStatus;
  public user_status: AbstractControl;

  public viewingOwnDetails: boolean = false;

  public roleTranslations = {
    service_personnel: {
      name: $localize`:@@servicePersonnelRole:`,
      description: $localize`:@@servicePersonnelDescription:`,
    },
    service_admin: {
      name: $localize`:@@serviceAdminRole:`,
      description: $localize`:@@serviceAdminDescription:`,
    },
    service_group_admin: {
      name: $localize`:@@serviceGroupAdminRole:`,
      description: $localize`:@@serviceGroupAdminDescription:`,
    },
    restricted_user: {
      name: $localize`:@@restrictedUserRole:`,
      description: $localize`:@@restrictedUserDescription:`,
    },
    management: {
      name: $localize`:@@managementRole:`,
      description: $localize`:@@managementDescription:`,
    },
    system_admin: {
      name: $localize`:@@systemAdminRole:`,
      description: $localize`:@@systemAdminRoleDescription:`,
    },
    medical_admin: {
      name: $localize`:@@medicalAdminRole:`,
      description: $localize`:@@medicalAdminRoleDescription:`,
    },
    ACTIVE: {
      name: $localize`:@@userStatusActive:`,
      description: $localize`:@@userStatusActiveDescription:`,
    },
    DISABLED: {
      name: $localize`:@@UserStatusNotActive:`,
      description: $localize`:@@UserStatusNotActiveDescription:`,
    },
    EXPIRED: {
      name: $localize`:@@UserStatusExpired:`,
      description: $localize`:@@UserStatusExpiredDescription:`,
    },
  };

  public readonly inputLabelTranslations = {
    first_name: $localize`:@@kayttajanTiedotEtunimi:`,
    last_name: $localize`:@@kayttajanTiedotSukunimi:`,
    ssn: $localize`:@@kayttajanTiedotHenkilotunnus:`,
    username: $localize`:@@kayttajanTiedotKayttajatunnus:`,
    emis_username: $localize`:@@userDetailsEmisUsername:`,
    job_role: $localize`:@@Tyonimike:`,
    phone_number: $localize`:@@kayttajanTiedotPuhelinnumero:`,
    delete_user: $localize`:@@kayttajanTiedotPoista:`,
  };

  public readonly inputErrorTranslations = {
    first_name: $localize`:@@kayttajanTiedotVirheEtunimi:`,
    last_name: $localize`:@@kayttajanTiedotVirheSukunimi:`,
    ssn_required: $localize`:@@kayttajanTiedotVirheHenkilotunnus:`,
    ssn_invalid: $localize`:@@kayttajanTiedotVirheVaaraHenkilotunnus:`,
    username: $localize`:@@kayttajanTiedotVirheSahkoposti:`,
    emis_username: $localize`:@@kayttajanTiedotVirheSahkoposti:`,
    job_role: $localize`:@@kayttajanTiedotVirheTyonimike:`,
    phone_number_required: $localize`:@@kayttajanTiedotVirhePuhelinnumero:`,
    phone_number_invalid: $localize`:@@kayttajanTiedotVirhePuhelinnumeroVaara:`,
  };

  private stateChangeSubscription: Subscription;
  private readonly ADMIN_GROUP_RANK: number = 1;

  constructor(
    private fb: FormBuilder,
    protected userService: UserService,
    protected stateService: StateService,
    private sessionService: SessionService,
    protected toasterService: ToasterService,
    private utilsService: UtilsService,
    protected router: Router
  ) {
    // init with empty user so angular doesn't break
    this.selectedDetailedUser = {
      service_group: { name: '' },
      services: [],
      groups: [],
    };

    if (this.userService.selectedDetailedUser) {
      this.userCanEdit =
        this.userService.selectedDetailedUser.can_edit_user_details;
    }

    this.inputDisabled =
      !this.sessionService.checkPermissions(Permissions.USERS_EDIT) ||
      !this.userCanEdit;

    this.userForm = this.fb.group({
      username: new FormControl({ value: '', disabled: this.inputDisabled }, [
        Validators.required,
        EmailValidator.validate,
      ]),
      first_name: new FormControl(
        { value: '', disabled: this.inputDisabled },
        Validators.required
      ),
      last_name: new FormControl(
        { value: '', disabled: this.inputDisabled },
        Validators.required
      ),
      phone_number: new FormControl(
        { value: '', disabled: this.inputDisabled },
        [PhoneNumberValidator.validate]
      ),
      services_ids: new FormControl(
        { value: [], disabled: this.inputDisabled },
        this.serviceIdValidator()
      ),
      job_role: new FormControl(
        { value: '', disabled: this.inputDisabled },
        Validators.required
      ),
      groups_ids: new FormControl(
        { value: [], disabled: this.inputDisabled },
        Validators.required
      ),
      ssn: new FormControl({ value: '', disabled: this.inputDisabled }),
      user_status: new FormControl({ value: '', disabled: this.inputDisabled }),
    });

    if (this.emisIntegrationEnabled) {
      this.userForm.addControl(
        'emis_username',
        new FormControl({ value: '', disabled: this.inputDisabled })
      );
    }

    // name the controls for easy access from template
    this.first_name = this.userForm.controls['first_name'];
    this.last_name = this.userForm.controls['last_name'];
    this.username = this.userForm.controls['username'];
    this.services_ids = this.userForm.controls['services_ids'];
    this.groups_ids = this.userForm.controls['groups_ids'];
    this.ssn = this.userForm.controls['ssn'];
    this.phone_number = this.userForm.controls['phone_number'];
    this.job_role = this.userForm.controls['job_role'];
    this.user_status = this.userForm.controls['user_status'];

    this.stateService.setState(StateKeys.userDetailsInitComplete, null);
  }

  ngOnInit() {
    this.selectedDetailedUser = this.userDetails;

    this.viewingOwnDetails =
      this.sessionService.getUsername() == this.userDetails.username ||
      this.modalType === UserDetailsModalType.MY_ACCOUNT;

    // add groups
    this.buildGroupsArray();

    // add services
    this.buildServicesArray();

    // add job roles
    this.buildJobRolesArray();

    this.populateFormWithUserData();

    // Hide SSN field in all production environments, except FI
    this.ssnHidden = !this.sessionService.isFinlandProduction;

    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        const informationUpdated = $localize`:@@InformationUpdated:`;
        const NewUserCreated = $localize`:@@NewUserCreated:`;
        const CreatingNewUserFailed = $localize`:@@CreatingNewUserFailed:`;
        const UpdatingInformationFailed = $localize`:@@UpdatingInformationFailed:`;

        switch (state['key']) {
          case StateKeys.updateSuccessful:
            this.toasterService.pop(
              'success',
              `${this.first_name.value} ${this.last_name.value} ${informationUpdated}`
            );
            this.userDetailsUpdated.emit();
            break;

          case StateKeys.createSuccessful:
            this.toasterService.pop(
              'success',
              `${NewUserCreated} ${this.first_name.value} ${this.last_name.value}`
            );
            this.userDetailsUpdated.emit();
            break;

          case StateKeys.linkSuccessful:
            this.toasterService.pop(
              'success',
              `${NewUserCreated} ${state['value']}`
            );
            break;

          case StateKeys.createFailed:
            if (state['value'].status === 409) {
              return;
            }

            this.toasterService.pop('danger', CreatingNewUserFailed);
            this.getErrors(state['value']);
            break;

          case StateKeys.userDetailsUpdateFailed:
            this.toasterService.pop('danger', UpdatingInformationFailed);
            this.getErrors(state['value']);
            break;

          case StateKeys.linkUser:
            const formValue = this.userForm.value;
            formValue.username = formValue.username.trim();
            this.userService.linkUser(formValue);
            this.userDetailsUpdated.emit();
            break;
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userDetails) {
      this.isStaffButtonDisabled = false;
    }
  }

  // Validate based on services length
  serviceIdValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.services.length == 0) return null; // Valid

      const value = control.value;
      if (value && value.length > 0) return null; // Valid

      return { required: true }; // Invalid
    };
  }

  populateFormWithUserData() {
    Object.keys(this.selectedDetailedUser).forEach((k) => {
      if (this.userForm.controls[k]) {
        this.userForm.controls[k].setValue(this.selectedDetailedUser[k]);
      } else if (k === 'services') {
        const serviceIds: Array<number> =
          this.selectedDetailedUser.services.map(
            (service: object) => service['id']
          );
        this.userForm.controls['services_ids'].setValue(serviceIds);
      } else if (k === 'groups') {
        const groupIds: Array<number> = this.selectedDetailedUser.groups
          .filter((group) => group['rank'] !== this.ADMIN_GROUP_RANK) // Filter out admin groups since they cannot be edited in Pro UI
          .map((group: object) => group['id']);

        this.userForm.controls['groups_ids'].setValue(groupIds);
      }
    });

    // Mark all fields as untouched on init
    Object.keys(this.userForm.controls).forEach((control) => {
      this.userForm.controls[control].markAsUntouched();
    });
  }

  ngOnDestroy() {
    this.stateChangeSubscription.unsubscribe();
  }

  toggleAllServicesSelected() {
    let usersServiceIds: Array<number> =
      this.userForm.controls['services_ids'].value;
    const availableServiceIds: Array<number> = this.services.map(
      (service: object) => service['id']
    );

    // Check if every available service is already included in the user's services
    const allServicesSelected: boolean = availableServiceIds.every((id) => {
      return usersServiceIds.indexOf(id) !== -1;
    });

    if (allServicesSelected) {
      //remove all available services from user's services
      usersServiceIds = usersServiceIds.filter((id) => {
        availableServiceIds.indexOf(id) !== -1;
      });
    } else {
      //add all available services to user's services
      usersServiceIds = Array.from(
        new Set(usersServiceIds.concat(availableServiceIds))
      );
    }

    this.userForm.controls['services_ids'].setValue(usersServiceIds);
  }

  userInAny(controlName: string, refListKey: string): boolean {
    let usersItemIds: Array<number> = this.userForm.controls[controlName].value;
    if (!usersItemIds) return false;

    const availableIds: Array<number> = this[refListKey].map(
      (item: object) => item['id']
    );

    // Check if any of the available services is included in the user's services
    const userInAny: boolean = usersItemIds.some(
      (id) => availableIds.indexOf(id) !== -1
    );
    return userInAny;
  }

  buildGroupsArray(): void {
    const sessionGroups = this.session['groups'];
    if (this.modalType === UserDetailsModalType.MY_ACCOUNT) {
      this.groups = sessionGroups;
      return;
    }

    // Display all available user groups when viewing user edit view
    const ranks = Object.keys(sessionGroups).map(
      (groupKey) => sessionGroups[groupKey].rank
    );
    const highest_rank = Math.min.apply(Math, ranks);

    const availableGroups: {}[] = this.session['available_groups'];
    this.groups = availableGroups.filter(
      (group) => group['rank'] >= highest_rank
    );
  }

  buildServicesArray(): void {
    if (this.modalType === UserDetailsModalType.MY_ACCOUNT) {
      // When viewing the details from the My account page -> always show the services the user is in
      this.services = this.session['services'];
      return;
    }

    const serviceGroup: object = this.session['service_group'];
    const serviceGroupServices: Array<object> = serviceGroup['services'];

    if (
      this.sessionService.checkPermissions([
        Permissions.USERS_EDIT_USER_SERVICES_FULL,
      ])
    ) {
      // If user has full access to edit services -> show all services in the service group
      this.services = serviceGroupServices;
    } else if (
      this.sessionService.checkPermissions([
        Permissions.USERS_CREATE,
        Permissions.USERS_EDIT,
      ])
    ) {
      // User has only edit or and create rights -> show all the services the user is part of
      this.services = this.session['services'];
    } else {
      // If user doesn't have rights to edit services -> don't show any services
      this.services = [];
    }

    this.services = this.services.map((service) => {
      return {
        ...service,
        name: addInactiveToServiceName(service as Service),
      };
    }) as Object[];
  }

  buildJobRolesArray(): void {
    const availableRoles = getNestedValue(this.session, 'available_roles');

    const dropdownItems: Array<DropdownItem> = [];
    for (const i in availableRoles) {
      const jobRole = availableRoles[i];
      dropdownItems.push(
        new DropdownItem(this.getTranslation(jobRole), jobRole.id)
      );
    }
    this.jobRoles = dropdownItems;
  }

  getErrors(data: any) {
    // display errors even if fields not touched
    this.validationFromServer = true;

    // grab the response status code and the errors from the payload
    const status = data.status;
    const formErrors = data.formErrors;

    // grab the field names if we got any back from the server
    const fields = Object.keys(formErrors || {});
    fields.forEach((field) => {
      const control = this.userForm.get(field);
      if (!control) {
        // Can't set errors on a control that doesn't exist
        console.warn("Can't find control for field: " + field);
        return;
      }

      const errors_and_keys = formErrors[field] as Array<string>;
      let errors = {};
      if (typeof errors_and_keys === 'object') {
        errors = Object.keys(errors_and_keys || {});
        // server side errors are string, each error will be its own key
        errors_and_keys.forEach((error) => {
          errors[error] = error;
        });
      } else {
        errors[<string>errors_and_keys] = true;
      }

      // set the errors on the controller
      control.setErrors(errors);
    });
  }

  async deleteUser() {
    await this.userService
      .deleteUser(this.userDetails.id)
      .then(() => {
        this.stateService.setState(StateKeys.selectedUser, null);
        this.toasterService.pop('success', $localize`:@@UserDeleted:`);
      })
      .catch(() => {
        this.toasterService.pop('danger', $localize`:@@DeletingUserFailed:`);
      });
    this.userDetailsUpdated.emit();
  }

  checkboxTicked(key: string, id: number) {
    if (this.userForm.controls[key].touched === false) {
      this.userForm.controls[key].markAsTouched();
    }

    // Get currently selected
    let userItems: Array<number> = this.userForm.controls[key].value;

    const i: number = userItems.indexOf(id);
    if (i !== -1) {
      // Remove the selected service id from users service id list and update the FormControl value
      userItems = userItems.filter((itemId) => itemId !== id);
    } else {
      userItems.push(id);
    }

    this.userForm.controls[key].setValue(userItems);
  }

  displayDescription(event: MouseEvent, descriptionTranslationKey: string) {
    this.showDescription = true;
    // request animation frame in order to make sure element has been rendered before attempting to update it's contents
    requestAnimationFrame(() => {
      const descriptionElement = document.getElementById('description');
      if (!descriptionElement) {
        // Sometimes the descriptionElement is null, and an unnecessary
        // error would be thrown.
        return;
      }

      const hoverElement = <HTMLElement>event.target;
      descriptionElement.style.top = `${
        hoverElement.offsetTop + hoverElement.offsetHeight
      }px`;
      descriptionElement.style.left = `${
        hoverElement.offsetLeft + hoverElement.offsetWidth
      }px`;
      descriptionElement.getElementsByTagName('h3')[0].innerHTML =
        this.roleTranslations[descriptionTranslationKey].name;
      descriptionElement.getElementsByTagName('p')[0].innerHTML =
        this.roleTranslations[descriptionTranslationKey].description;
    });
  }

  getTranslation(obj: object) {
    const translation = getNestedValue(
      obj,
      'translations',
      this.utilsService.currentLocale,
      'name'
    );
    if (translation) {
      return translation;
    }

    const name = getNestedValue(obj, 'name');
    if (name) {
      return name;
    }

    return 'TRANSLATION_MISSING';
  }

  get isDisabledInputDisabled() {
    // Toggles disabled attribute for user status not active input
    if (this.userDetails.user_status === this.userstatus.EXPIRED) {
      // Disables for expired users
      return true;
    }
    if (!this.userDetails.user_status) {
      // Disables for new users
      return true;
    }
    return null;
  }

  updateValue(key, value) {
    this.userForm.controls[key].setValue(value);
    this.userForm.controls[key].markAsTouched();
  }

  onMakeStaffClicked(event) {
    // Prevent the form from being submitted
    event.preventDefault();

    const username = this.userDetails.username;
    const confirmMessage = $localize`:@@MakeUserStaffConfirmation:This will make ${username} a staff user with all permissions to all service groups. Are you sure?`;

    if (!confirm(confirmMessage)) {
      return;
    }

    this.userService
      .makeStaffUser(this.userDetails.id)
      .then(() => {
        this.isStaffButtonDisabled = true;
        this.toasterService.pop(
          'success',
          $localize`:@@UserMadeStaffSuccess:Successfully made the user a staff user.`
        );
      })
      .catch((error) => {
        this.toasterService.pop(
          'danger',
          $localize`:@@MakingUserStaffFailed:Failed to make a staff user.`
        );
      });
  }

  onremoveStaffUserStatusClicked(event) {
    // Prevent the form from being submitted
    event.preventDefault();

    const username = this.userDetails.username;
    const confirmMessage = $localize`:@@RemoveStaffUserStatusConfirmation:This will remove the staff user status from ${username}. Are you sure?`;

    if (!confirm(confirmMessage)) {
      return;
    }

    this.userService
      .removeStaffUserStatus(this.userDetails.id)
      .then(() => {
        this.isStaffButtonDisabled = true;
        this.toasterService.pop(
          'success',
          $localize`:@@removeStaffUserStatusSuccess:Successfully removed staff user status.`
        );
      })
      .catch((error) => {
        this.toasterService.pop(
          'danger',
          $localize`:@@removeStaffUserStatusFailed:Failed to remove staff user status.`
        );
      });
  }

  onSubmit() {
    // Mark the form controls as touched to display errors
    Object.keys(this.userForm.controls).forEach((control) => {
      this.userForm.controls[control].markAsTouched();
    });

    if (this.userForm.status === 'VALID') {
      const formValue = this.userForm.value;
      formValue.username = formValue.username.trim();

      if (this.modalType === UserDetailsModalType.CREATE_USER) {
        this.userService.createUser(formValue);
      } else {
        this.userService
          .updateUserDetails(this.userDetails.id, formValue, false)
          .then((res) => {
            if (res) {
              this.stateService.setState(StateKeys.userDetailLoaded, res);
              this.stateService.setState(StateKeys.updateSuccessful, '');
              this.userService.getUsers();
            }
          })
          .catch((error) => {
            this.parseErrorReponse(error);
          });
      }
    }
  }

  parseErrorReponse(error): void {
    let formErrors = {};
    const errors: Array<object> = getNestedValue(error, 'error');
    for (const i in errors) {
      // We can receive errors in 2 formats from the backend:
      // either a list of objects [{...}, {...}]
      // or a dictionary ["username": {...}, "ssn": {...}]
      // so we need to be able to distinguish in what format are we receiving those errors,
      // so we can populate the formErrors correctly

      if (Number.isNaN(Number(i))) {
        // Here we have the errors in a dictionary :')
        for (const ii in errors[i]) {
          const errs: Array<string> = errors[i] as Array<string>;
          errs.forEach((errCode) => {
            // Ugly fix for the front end since our backend doesn't return meaningful error codes/messages...
            if (i === 'ssn') {
              errCode = 'invalid_ssn';
            }

            if (formErrors[i]) {
              formErrors[i].add(errCode);
            } else {
              formErrors[i] = new Set([errCode]);
            }
          });
        }
      } else {
        // Here we have the errors in an array :')
        const err = errors[i];
        Object.keys(err).forEach((k) => {
          if (formErrors[k]) {
            formErrors[k].add(err[k]);
          } else {
            formErrors[k] = new Set([err[k]]);
          }
        });
      }
    }

    this.stateService.setState(StateKeys.userDetailsUpdateFailed, {
      formErrors: formErrors,
      status: 400,
    });

    if (!environment.production) {
      console.log(error);
    }
  }
}
