import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LoadingStatus } from 'enums';
import { DataReportsService, ReportType } from 'services/data-reports.service';
import { TilesService } from 'services/tiles.service';
import { UtilsService } from 'services/utils.service';

type ReportOption = {
  value: ReportType;
  viewValue: string;
  disabled?: boolean;
};

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [DatePipe],
})
export class ReportsComponent implements OnInit {
  constructor(
    protected dataReportsService: DataReportsService,
    private datePipe: DatePipe,
    private utilsService: UtilsService
  ) {}

  loadingStatus = LoadingStatus; // Import the LoadingStatus enum to use in the template
  reportType = ReportType; // Import the ReportType enum to use in the template
  startDate: string = this.getOneMonthAgoDate();
  endDate: string = this.getYesterdaysDate();

  enqueueStatus: LoadingStatus = LoadingStatus.NONE;
  pollingStatus: LoadingStatus = LoadingStatus.NONE;

  selectedReportType: ReportType | null = null;

  reportOptions: ReportOption[] = [
    {
      value: ReportType.CASE_COUNTS,
      viewValue: $localize`:@@caseCountsReport:`,
    },
    {
      value: ReportType.PRODUCTIVITY,
      viewValue: $localize`:@@patientFeedbackReport:`,
      disabled: true,
    },
  ];

  ngOnInit(): void {
    this.dataReportsService.enqueueStatus$.subscribe((status) => {
      this.enqueueStatus = status;
    });

    this.dataReportsService.pollingStatus$.subscribe((status) => {
      this.pollingStatus = status;
    });
  }

  /** Triggers the generation of a report and starts polling for the results */
  queueReport(reportType: ReportType | null) {
    if (!reportType) return;
    this.dataReportsService
      .queueReport(reportType, this.startDate, this.endDate)
      .subscribe(({ task_id }) => {
        this.resetSelections();
        this.dataReportsService.startPolling(task_id);
      });
  }

  /** Returns true if the generate report request is still pending */
  get generationRequestPending() {
    return this.enqueueStatus === LoadingStatus.LOADING;
  }

  get pollingInProgress() {
    return this.pollingStatus === LoadingStatus.POLLING;
  }

  /** Returns true if the generate report trigger shuold not be usable */
  get triggerDisabled() {
    return (
      !this.selectedReportType ||
      this.generationRequestPending ||
      this.pollingInProgress ||
      this.dateRangeOverTwelveMonths
    );
  }

  /** Returns true if the report type and date range selectors should be disabled */
  get selectorsDisabled() {
    return this.generationRequestPending || this.pollingInProgress;
  }

  /** Returns true if an error has occurred during the report generation process */
  get hasErrored() {
    return (
      this.pollingStatus === LoadingStatus.FAILED ||
      this.enqueueStatus === LoadingStatus.FAILED
    );
  }

  get dateRangeOverTwelveMonths() {
    const startDate = new Date(this.startDate);
    const endDate = new Date(this.endDate);
    const dateDiff = endDate.getTime() - startDate.getTime();
    const twelveMonths = 1000 * 60 * 60 * 24 * 30 * 12;
    return dateDiff > twelveMonths;
  }

  /** Resets the selected report type and date range */
  private resetSelections() {
    this.selectedReportType = null;
    // Reset date ranges if deemed necessary?
  }

  private getYesterdaysDate() {
    const today = new Date();
    const yesterday = new Date(today).setDate(today.getDate() - 1);
    return this.datePipe.transform(
      yesterday,
      'yyyy-MM-dd',
      undefined,
      this.utilsService.currentLocale
    )!;
  }

  private getOneMonthAgoDate() {
    const today = new Date();
    const oneMonthAgo = new Date(today).setMonth(today.getMonth() - 1);
    return this.datePipe.transform(
      oneMonthAgo,
      'yyyy-MM-dd',
      undefined,
      this.utilsService.currentLocale
    )!;
  }
}
