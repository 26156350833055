// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { Configuration } from 'app/app.constants';
import { Observable, Subscription, of } from 'rxjs';
import {
  MessageCounts,
  MessageService,
  MESSAGE_STATES,
  MessageListParameters,
} from 'services/message.service';
import { SessionService } from 'services/session.service';
import {
  StateService,
  StateKeys,
  updatedMessageStateEvent,
  MessageListEvent,
} from 'services/state.service';
import type { messageCountEvent } from 'services/state.service';
import { UtilsService, shortUuid } from 'services/utils.service';
import { EnquiryHeader } from 'app/shared/methods/enquiry/enquiry';
import { DatePipe } from '@angular/common';
import { UserService } from 'services/user.service';
import {
  AppointmentTypeFieldName,
  AppointmentTypesService,
} from 'services/appointment-types.service';
import { LoadingStatus } from 'enums';

export enum OrderTypes {
  most_recent_first = '-created_at',
  oldest_first = 'created_at',
  most_urgent_first = '-priority',
  least_urgent_first = 'priority',
  services_a_to_z = 'service',
  services_z_to_a = '-service',
  lastname_a_to_z = 'lastname',
  lastname_z_to_a = '-lastname',
  new_state_first = 'state',
  in_progress_first = '-state',
  emergencies_and_urgent_first = 'emergencies_and_urgent_first',
}

/**
 * Default sort order for the message list.
 * We want to show emergencies first, then the oldest messages.
 */
const DEFAULT_SORT_ORDER = [
  OrderTypes.emergencies_and_urgent_first,
  OrderTypes.oldest_first,
];

@Component({
  selector: 'app-messages-list-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy {
  private stateChangeSubscription: Subscription | undefined;
  private messageId: number | null = null;
  private priorityIds: Array<number> = [];
  private hasNewFollowups: number | null = null;
  private hasNewAttachments: number | null = null;
  private navigationRequest: string | null = null;
  private messageRefreshIntervalFunction: NodeJS.Timeout | undefined;

  /** List of services that the user has explicitly selected. */
  private selectedServiceIds: Array<number> = [];
  private states = { prioritiy: null, services: null };

  public newHover = $localize`:@@newHover:`;
  public newEmergencyHover = $localize`:@@emergency:`;
  public newUrgentHover = $localize`:@@urgent:`;
  public transferHover = $localize`:@@transferHover:`;
  public urgentHover = $localize`:@@urgentHover:`;
  public emergencyHover = $localize`:@@emergencyHover:`;
  public priorityHover = $localize`:@@priorityHover:`;
  public attachmentHover = $localize`:@@attachmentHover:`;
  public activeTab: string | null = null;
  public newFollowupsCount = 0;
  public newMessageCount = 0;
  public urgentMessageCount = 0;
  public emergencyMessageCount = 0;
  public totalMessageCount = 0;
  public messageList: Array<EnquiryHeader> | null = [];
  public serviceId: number | null = null;
  public stateIds: Array<number> = [];
  public assignedToUserIds: Array<number> = [];
  public searchTerm = '';
  public searchSsn = '';
  public searchAppointmentId = '';
  public orderBy: string[] = [''];
  public page = 1;
  public pageSize = 10;
  public maxSize = 10;
  public previousText = '<i class="fa fa-arrow-left" aria-hidden="true">';
  public nextText = '<i class="fa fa-arrow-right" aria-hidden="true">';
  public highlightedTabs: Array<string> = [];
  public date_formats: any;
  public values: DropdownItem[] = [];
  public selectedAppointmentTypes: DropdownItem[] = [];
  public selectedSubAppointmentTypes: DropdownItem[] = [];
  public lastUpdated: Date | undefined = undefined;

  public orderTypes = OrderTypes;

  /**
   * Returns true if the message list is updating.
   */
  public get isMessageListUpdating(): boolean {
    return this.stateService.isLoading(StateKeys.loadingMessages);
  }

  public get showPendingTab() {
    return this.sessionService.isGPConnectIntegrationEnabled;
  }

  public get showPriority() {
    return this.sessionService.isRestricted !== true;
  }

  constructor(
    protected messageService: MessageService,
    protected sessionService: SessionService,
    protected userService: UserService,
    protected configuration: Configuration,
    protected stateService: StateService,
    protected route: ActivatedRoute,
    private toasterService: ToasterService,
    protected utilsService: UtilsService,
    private appointmentTypesService: AppointmentTypesService
  ) {
    this.resetState();
  }

  async ngOnInit() {
    this.messageService.getUserServices();

    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.filteredByservices:
            const items = state['value'] as DropdownItem[];
            this.selectedServiceIds = items
              .filter((item) => item.checked)
              .map((item) => parseInt(item.value.toString()));
            this.updateMessages();
            break;
          case StateKeys.filteredByAppointmentTypes:
            this.selectedAppointmentTypes = this.getPrimaryAppointmentTypes(
              state['value']
            );
            this.selectedSubAppointmentTypes =
              this.getSecondaryAppointmentTypes(state['value']);

            this.updateMessages();
            break;
          case StateKeys.selectedService:
            this.messageId = null;
            this.serviceId = state['value'] ? state['value']['id'] : null;
            this.updateMessages();
            break;
          case StateKeys.selectedPriorities:
            this.priorityIds = state['value'];
            this.updateMessages();
            break;
          case StateKeys.selectedStates:
            this.stateIds = state['value'];
            this.updateMessages();
            break;
          case StateKeys.enteredSearchTerm:
            this.searchTerm = state['value'];
            this.updateMessages();
            break;
          case StateKeys.enteredSsnSearch:
            this.searchSsn = state['value'];
            this.updateMessages();
            break;
          case StateKeys.enteredAppointmentIdSearch:
            this.searchAppointmentId = state['value'];
            this.updateMessages();
            break;
          case StateKeys.selectedMessage:
            this.messageId = state['value'] ? state['value']['id'] : null;
            break;
          case StateKeys.loadingMessages:
            this.handleMessageListEvent(state['value'] as MessageListEvent);
            break;
          case StateKeys.messageCounts:
            const messageCountEvent = state['value'] as messageCountEvent;
            if (messageCountEvent.status !== LoadingStatus.SUCCESS) return;
            this.handleMessageCounts(messageCountEvent.data);
            break;
          case StateKeys.redirectedMessage:
            this.messageId = null;
            this.updateMessages({}, false);
            break;
          case StateKeys.resetFilter:
            this.page = 1;
            switch (state['value']['filter']) {
              case 'stateIds':
                this.stateIds = [0, 1];
                break;
              case 'searchTerm':
                this.searchTerm = null;
                break;
              case 'priorityIds':
                this.priorityIds = [];
                break;
            }

            if (state['value']['update']) {
              this.updateMessages();
            }
            break;
          case StateKeys.closedMessage:
            this.messageId = null;
            break;
          case StateKeys.receivedNewFollowups:
            this.highlightTab('followups');
            break;
          case StateKeys.deleteSession:
            this.resetState();
            break;
          case StateKeys.updatedMessageState:
            const event = state['value'] as updatedMessageStateEvent;
            this.handleUpdatedMessageState(event);
          case StateKeys.requestedNavigation:
            this.handleNavigationRequest(state['value']['request']);
            break;
        }
      }
    );
    this.date_formats = this.utilsService.getDateFormats();

    this.getServices().then((services) => {
      // Initialize selected services with all available services for the user
      this.selectedServiceIds = services.map((service) => service.id);
      this.updateBundledCounts();
      this.activateTab('messages');
    });

    this.keepMessageListUpdated();
  }

  /** Returns all services for the user. */
  async getServices(): Promise<Array<Service>> {
    try {
      const userId = this.sessionService.userID;
      const user = await this.userService.getUser(userId);
      return user.services;
    } catch (error) {
      const errorMsg = $localize`:@@somethingWentWrong:`;
      this.toasterService.pop('error', errorMsg);
      return [];
    }
  }

  getPrimaryAppointmentTypes(appointmentTypes: DropdownItem[]): DropdownItem[] {
    return appointmentTypes.filter((appointmentType) => {
      return !appointmentType.isSecondary;
    });
  }

  getSecondaryAppointmentTypes(
    appointmentTypes: DropdownItem[]
  ): DropdownItem[] {
    return appointmentTypes.filter((appointmentType) => {
      return appointmentType.isSecondary;
    });
  }

  resetState() {
    this.messageList = [];
    this.serviceId = this.configuration.defaultState['selectedService'];
    this.stateIds = this.configuration.defaultState['selectedStates'];
    this.priorityIds = this.configuration.defaultState['selectedPriorities'];
    this.searchTerm = '';
    this.searchSsn = '';
    this.orderBy = DEFAULT_SORT_ORDER;
    this.messageId = null;
    this.page = 1;
    this.pageSize = this.configuration.pageSize;
    this.totalMessageCount = 0;
    this.newMessageCount = 0;
    this.newFollowupsCount = 0;
    this.assignedToUserIds = [];
    this.route.queryParams.subscribe((params) => {
      this.pageSize =
        typeof params['pageSize'] !== 'undefined'
          ? params['pageSize']
          : this.configuration.pageSize;
    });
  }

  activateTab(tab: string) {
    this.orderBy = DEFAULT_SORT_ORDER;
    this.assignedToUserIds = [];

    switch (tab) {
      case 'messages':
        this.hasNewFollowups = 0;
        this.hasNewAttachments = 0;
        this.stateIds = [0, 1];
        break;
      case 'followups':
        this.hasNewFollowups = 1;
        this.hasNewAttachments = 1;
        this.stateIds = [0, 1];
        break;
      case 'ready':
        this.hasNewFollowups = 0;
        this.hasNewAttachments = 0;
        this.stateIds = [2];
        this.orderBy = [OrderTypes.most_recent_first]; // Archived messages are better to be shown in most recent order
        break;
      case 'own-messages':
        this.hasNewFollowups = 0;
        this.hasNewAttachments = 0;
        this.assignedToUserIds = [this.sessionService.userID]; // Show only messages assigned to the current user
        this.stateIds = [1];
        break;
      case 'pending-messages':
        this.hasNewFollowups = 0;
        this.hasNewAttachments = 0;
        this.stateIds = [3];
        break;
    }
    this.activeTab = tab;
    this.stateService.setState(StateKeys.activatedTab, tab);
    this.updateMessages();
  }

  highlightTab(tab: string) {
    this.highlightedTabs.push(tab);

    window.setTimeout(() => {
      const index = this.highlightedTabs.indexOf(tab, 0);
      if (index > -1) {
        this.highlightedTabs.splice(index, 1);
      }
    }, 2000);
  }

  selectMessage(message: object) {
    this.stateService.setState(StateKeys.selectedMessage, message);
  }

  selectStates(stateIds: Array<number>) {
    this.stateIds = stateIds;
    this.stateService.setState(StateKeys.selectedStates, stateIds);
  }

  /**
   * Sets the order by field for the message list api call.
   * @param orderBy - The order by key or an array of order by keys.
   */
  setOrdering(orderBy: string | string[]) {
    const orderByArray = Array.isArray(orderBy) ? orderBy : [orderBy]; // Convert to array if not already

    if (!orderByArray.includes(OrderTypes.most_recent_first)) {
      orderByArray.push(OrderTypes.oldest_first); // Always show the oldest of each priority first
    }
    this.orderBy = orderByArray;
    this.updateMessages();
  }

  /**
   * Toggles the order of the message list based on priority.
   * If the list is currently ordered by priority, it will be reversed.
   */
  togglePriorityOrder() {
    const toggled = this.orderBy.includes(OrderTypes.least_urgent_first)
      ? OrderTypes.most_urgent_first
      : OrderTypes.least_urgent_first;
    this.setOrdering(toggled);
  }

  pageChanged(event: any): void {
    this.page = event.page;
    this.updateMessages({}, false);
  }

  resetFilter(filter: string, update: boolean = false) {
    this.stateService.setState(StateKeys.resetFilter, {
      filter: filter,
      update: update,
    });
  }

  /**
   * Returns selected service ids as a sorted array.
   */
  getSelectedServiceIds(): Array<number> {
    let serviceIds = [...this.selectedServiceIds];

    // Patient cases which have a value of null in DB
    if (serviceIds.length > 0) serviceIds.push(0);

    // Sort serviceIds so that they are always in the same order.
    // This will help the backend API(s) with caching.
    serviceIds.sort((a, b) => a - b);

    return serviceIds;
  }
  getSelectedAppointmentTypeIds(): Array<number> {
    return this.selectedAppointmentTypes.map((s) =>
      parseInt(s.value as string)
    );
  }
  getSelectedSubAppointmentTypeIds(): Array<number> {
    return this.selectedSubAppointmentTypes.map((s) =>
      parseInt(s.value as string)
    );
  }

  getParameters(overrides: any = null): MessageListParameters {
    let parameters = {} as MessageListParameters;

    // Add serviceIds if any are selected.
    const serviceIds = this.getSelectedServiceIds();
    if (serviceIds.length > 0) {
      parameters.service_id = [...serviceIds].join(',');
    }

    // Add appointment_type_id's if selected
    const appointmentTypeIds = this.getSelectedAppointmentTypeIds();
    if (appointmentTypeIds.length > 0) {
      parameters.appointment_type_id = appointmentTypeIds.join(',');
    }

    // Add sub_appointment_type_ids's if selected
    const appointmentSubTypeIds = this.getSelectedSubAppointmentTypeIds();
    if (appointmentSubTypeIds.length > 0) {
      parameters.sub_appointment_type_id = appointmentSubTypeIds.join(',');
    }

    // Add search parameters.
    if (this.searchTerm) {
      parameters.search_term = this.searchTerm;
    }

    // Add search SSN parameter.
    if (this.searchSsn) {
      parameters.search_ssn = this.searchSsn;
    }

    // Add state_id parameter.
    if (this.stateIds.length > 0) {
      parameters.state_id = this.stateIds.join(',');
    }

    // Add appointment_id parameter.
    if (this.searchAppointmentId) {
      parameters.appointment_id = this.searchAppointmentId;
    }

    // Add priority_id parameter.
    if (this.priorityIds.length > 0) {
      parameters.priority_id = this.priorityIds.join(',');
    }

    // Add has_new_followups parameter.
    if (this.hasNewFollowups) {
      parameters.has_new_followups = this.hasNewFollowups;
    }

    // Add has_new_attachments parameter.
    if (this.hasNewAttachments) {
      parameters.has_new_attachments = this.hasNewAttachments;
    }

    // Add assigned_to_user_id parameter.
    if (this.assignedToUserIds.length > 0) {
      parameters.assigned_to_user_id = this.assignedToUserIds.join(',');
    }

    // Add the rest of the parameters that should always be included.
    parameters = {
      ...parameters,
      order_by: this.orderBy,
      page: this.page,
      page_size: this.pageSize,
    };

    // Add any overrides.
    for (const key in overrides) {
      if (overrides.hasOwnProperty(key)) {
        parameters[key] = overrides[key];
      }
    }

    return parameters;
  }

  /**
   * Updates the message list.
   *
   * @param overrides - Who knows? Is this useful?
   * @param resetPage - Should the page number be reset to 1? Defaults to true.
   */
  updateMessages(overrides: any = {}, resetPage: boolean = true) {
    if (resetPage) {
      this.page = 1;
    }

    try {
      this.messageService.getMessages(this.getParameters(overrides));
    } catch (err) {
      console.error('Error updating message list', err);
    }

    this.updateBundledCounts();
  }

  /** Updates the bundled message counts based on the current filters. */
  updateBundledCounts() {
    if (!this.selectedServiceIds || this.selectedServiceIds.length === 0) {
      console.debug('Waiting for services to be loaded');
      return;
    }

    try {
      this.messageService.getBundledMessageCounts(this.getParameters());
    } catch (err) {
      console.error('Error updating message counts', err);
    }
  }

  handleMessageCounts(counts: MessageCounts) {
    this.totalMessageCount = counts.totalMessages.count;

    // Only if the user is on messages list -> update the message type specific counts
    if (this.activeTab === 'messages') {
      this.newMessageCount = counts.newMessages.count;
      this.emergencyMessageCount = counts.emergencyMessages.count;
      this.urgentMessageCount = counts.urgentMessages.count;
    }

    // Followup count indicators should always be up to date
    const oldFollowupsCount: number = this.newFollowupsCount;
    this.newFollowupsCount = counts.newFollowups.count;

    if (this.newFollowupsCount > oldFollowupsCount) {
      this.stateService.setState(StateKeys.receivedNewFollowups, true);
    }
  }

  public handleUpdatedMessageState(event: updatedMessageStateEvent) {
    const messageId = event.messageId;
    const newState = event.currentState;

    const message = this.messageList.find((m) => m.id === messageId);

    if (!message) {
      // Since the message was not found from the list, update the whole list.
      // This probably happens because the message was moved from the Archive
      // back into Progress or as a New case.
      this.updateMessages({}, false);
      return;
    }

    // If we are viewing the Archived tab and the message is unarchived...
    if (this.activeTab == 'ready' && newState != MESSAGE_STATES.ARCHIVED) {
      // Remove the message from the list
      this.messageList = this.messageList.filter((m) => m.id !== messageId);
      return;
    }

    // If we are archiving a message...
    if (newState == MESSAGE_STATES.ARCHIVED) {
      // Remove the message from the list
      this.messageList = this.messageList.filter((m) => m.id !== messageId);
      return;
    }

    // Update the message state locally, without needing backend calls.
    message.state = newState;
  }

  // FIXME state handling needs refactoring, especially uniform way of setting and resetting filters
  handleNavigationRequest(request) {
    this.navigationRequest = request;

    switch (request) {
      case 'messages':
        this.activateTab('messages');
        break;
      case 'all-messages':
        this.resetFilter('priorityIds');
        this.resetFilter('searchTerm');
        this.resetFilter('searchSsn');
        this.activateTab('messages');
        break;
      case 'new-messages':
        this.stateIds = [0];
        this.activeTab = 'messages';
        this.hasNewFollowups = 0;
        this.hasNewAttachments = 0;
        this.stateService.setState(StateKeys.selectedPriorities, []);
        this.stateService.setState(StateKeys.activatedTab, 'messages');
        this.updateMessages();
        break;
      case 'latest-new-message':
        this.stateIds = [0, 1];
        this.orderBy = [OrderTypes.most_recent_first];
        this.stateService.setState(StateKeys.selectedPriorities, []);
        this.stateService.setState(StateKeys.toggledFollowup, false);
        this.activateTab('messages');
        break;
      case 'priority-messages':
        this.stateIds = [0, 1];
        this.page = 1;
        this.activeTab = 'messages';
        this.hasNewFollowups = 0;
        this.hasNewAttachments = 0;
        this.stateService.setState(StateKeys.selectedPriorities, [1]);
        this.stateService.setState(StateKeys.activatedTab, 'messages');
        break;
      case 'latest-priority-message':
        this.stateIds = [0, 1];
        this.orderBy = [OrderTypes.most_recent_first];
        this.stateService.setState(StateKeys.selectedPriorities, []);
        this.stateService.setState(StateKeys.toggledFollowup, false);
        this.activateTab('messages');
        break;
      case 'followupmessages':
        this.stateIds = [0, 1, 2];
        this.stateService.setState(StateKeys.selectedPriorities, []);
        this.activateTab('followups');
        break;
      case 'latest-followupmessage':
        this.stateIds = [0, 1, 2];
        this.orderBy = [OrderTypes.most_recent_first];
        this.hasNewFollowups = 1;
        this.hasNewAttachments = 1;
        this.stateService.setState(StateKeys.selectedPriorities, []);
        this.activateTab('followups');
        break;
    }
  }

  checkMessageSelection() {
    if (this.navigationRequest) {
      if (this.navigationRequest === 'latest-followupmessage') {
        this.stateService.setState(
          StateKeys.selectedMessage,
          this.messageList[0]
        );
        this.stateService.setState(StateKeys.toggledFollowup, true);
      } else {
        for (const m of this.messageList) {
          if (
            (this.navigationRequest === 'latest-new-message' &&
              m.state === 0) ||
            (this.navigationRequest === 'latest-priority-message' &&
              m.priority === 1)
          ) {
            this.stateService.setState(StateKeys.selectedMessage, m);
            break;
          }
        }
      }

      this.navigationRequest = null;
    }
  }

  keepMessageListUpdated() {
    this.messageRefreshIntervalFunction = setInterval(() => {
      this.pollMessageList();
    }, 1000);
  }

  pollMessageList() {
    // Don't update if the page is not visible
    if (this.documentHidden) return;

    if (typeof this.lastUpdated === 'undefined') return;
    const previousUpdateTime = this.lastUpdated.getTime();
    const nowTime = new Date().getTime();

    const recentlyUpdated =
      (nowTime - previousUpdateTime) / 1000 <=
      this.configuration.updateInterval;
    if (recentlyUpdated) return;

    window.setTimeout(() => {
      this.updateMessages({ automatic: true }, false);
    }, 500);
  }

  getAppointmentTypeShortName(message: EnquiryHeader): Observable<string> {
    if (message.appointment_sub_type) {
      return of(
        this.utilsService.getFieldTranslation(
          message.appointment_sub_type,
          'name'
        )
      );
    } else {
      return this.appointmentTypesService.getTranslatedNameById(
        message?.appointment_duration_type_id,
        AppointmentTypeFieldName.SHORT_NAME,
        message.appointment_duration_type
      );
    }
  }

  ngOnDestroy() {
    this.stateChangeSubscription?.unsubscribe();
    clearInterval(this.messageRefreshIntervalFunction);
  }

  getServiceList() {
    const services = this.sessionService.getData('userServices');
    return services;
  }

  getLastUpdatedLocalizedPipe(format: string): string {
    const lastUpdated = this.lastUpdated;
    const pipe = new DatePipe(this.utilsService.currentLocale);
    return pipe.transform(lastUpdated, format);
  }

  get documentHidden(): boolean {
    return document.hidden;
  }

  handleMessageListEvent(event: MessageListEvent) {
    if (event.status === LoadingStatus.LOADING) {
      return;
    }

    if (event.status !== LoadingStatus.SUCCESS) {
      this.toasterService.pop('danger', $localize`:@@LoadingMessagesFailed:`);
      return;
    }

    this.lastUpdated = new Date();
    this.messageList = event.data;
    this.checkMessageSelection();
  }

  shortUuid(uuid: string) {
    return shortUuid(uuid);
  }
}
