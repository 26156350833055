// @ts-nocheck
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

interface PasswordValidationResponse {
  is_valid: boolean;
  error?: string;
}

interface VideoConsultationResponse {
  consultation_room_url: string;
}

@Injectable()
export class PublicService {
  constructor(private http: HttpClient) {}

  prepareHeaders(token: string, method: string = null) {
    let headers = new HttpHeaders();
    headers = headers.set('document-token', token);

    if (method === 'post') {
      headers = headers.set('Content-Type', 'application/json');
    }

    return headers;
  }

  async validatePassword(
    password: string
  ): Promise<PasswordValidationResponse> {
    try {
      const request = this.http.post(
        `${environment.backendURL}/public/validate/password`,
        { password: password }
      );
      const response = await request.toPromise();
      return <PasswordValidationResponse>response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getVideoConsultationRoom(
    appointmentRequestUuid: string
  ): Promise<VideoConsultationResponse> {
    try {
      const response = this.http.get(
        `${environment.backendURL}/public/video-consultation/${appointmentRequestUuid}`
      );
      const videoConsultationRoomUrl = await response.toPromise();
      return <VideoConsultationResponse>videoConsultationRoomUrl;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPublicData(
    token: string
  ): Promise<{ valid: boolean; response: {}; case_handled: boolean }> {
    const result = {
      valid: false,
      response: null,
      case_handled: false,
    };

    try {
      result.response = await this.http
        .get(`${environment.backendURL}/public/token`, {
          headers: this.prepareHeaders(token),
        })
        .toPromise();

      result.valid = true;
    } catch (error) {
      result.case_handled = error['error']['message'] === 'Case handled';
      result.valid = false;
    }

    return result;
  }
}
