<div *ngIf="message">
  <section class="detail-progress">
    <div class="message-progress">
      <div class="progress-segment progress-left">
        <button
          [ngClass]="{
            'progress-info': message.state === messageStates.NEW,
            'info-new': message.state === messageStates.NEW,
            active: message.state === messageStates.NEW,
            'progress-action': message.state !== messageStates.NEW,
            disabled:
              !userPermissions.has(permissions.MESSAGE_CHANGE_STATE) ||
              message.state === messageStates.ARCHIVED ||
              message.state === messageStates.PENDING
          }"
          (click)="updateMessageState(false, messageStates.NEW)">
          <i
            class="fa fa-circle"
            *ngIf="message.state === messageStates.NEW"></i>
          <ng-container i18n="@@viestinVaiheetUusi"
            >Uusi yhteydenotto</ng-container
          >
        </button>
      </div>

      <div class="progress-segment progress-center">
        <button
          [ngClass]="{
            'progress-info': message.state === messageStates.IN_PROGRESS,
            'info-open': message.state === messageStates.IN_PROGRESS,
            active: message.state === messageStates.IN_PROGRESS,
            'progress-action': message.state !== messageStates.IN_PROGRESS,
            disabled:
              !userPermissions.has(permissions.MESSAGE_CHANGE_STATE) ||
              message.state === messageStates.PENDING
          }"
          (click)="updateMessageState(false, messageStates.IN_PROGRESS)">
          <i
            class="fa fa-circle"
            *ngIf="message.state === messageStates.IN_PROGRESS"></i>
          <ng-container i18n="@@viestinVaiheetKasittelyssa"
            >Käsittelyssä</ng-container
          >
        </button>
      </div>

      <div class="progress-segment progress-right">
        <button
          [ngClass]="{
            'progress-info': message.state === messageStates.ARCHIVED,
            'info-ready': message.state === messageStates.ARCHIVED,
            active: message.state === messageStates.ARCHIVED,
            'progress-action': message.state !== messageStates.ARCHIVED,
            disabled:
              !userPermissions.has(permissions.MESSAGE_CHANGE_STATE) ||
              message.state === messageStates.PENDING ||
              message.state === messageStates.NEW
          }"
          (click)="updateMessageState(false, messageStates.ARCHIVED)">
          <i
            class="fa fa-circle"
            *ngIf="message.state === messageStates.ARCHIVED"></i>
          <ng-container i18n="@@viestinVaiheetValmis">Valmis</ng-container>
        </button>
      </div>
    </div>
  </section>
</div>

<app-prompt
  *ngIf="showPromptEnquiryAlreadyInHandling"
  [translations]="promptTranslations"
  (clickEmitter)="handlePrompt($event)">
</app-prompt>
