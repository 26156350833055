// @ts-nocheck
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownComponent } from './dropdowns/dropdown/dropdown.component';
import { NotificationComponent } from '../../components/atoms/notification/notification.component';
import { NgModule } from '@angular/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { TextInputComponent } from 'components/atoms/text-input/text-input.component';
import { ToggleSwitchComponent } from '../../components/atoms/toggle-switch/toggle-switch.component';
import { TextareaInputComponent } from '../../components/atoms/textarea-input/textarea-input.component';
import { SimpleDropdownComponent } from '../../components/atoms/simple-dropdown/simple-dropdown.component';
import { LoadingComponent } from '../../components/atoms/loading/loading.component';
import { ErrorNotificationComponent } from './error-notification/error-notification.component';
import { ToggleSwitchListComponent } from '../../components/molecules/toggle-switch-list/toggle-switch-list.component';
import { ScrollAreaComponent } from '../../components/atoms/scroll-area/scroll-area.component';
import { LoadingPipe } from 'app/shared/pipes/loading.pipe';
import { KlinikShortDatePipe } from 'app/shared/pipes/klinik-short-date.pipe';
import { KlinikOverlayComponent } from 'components/atoms/klinik-overlay/klinik-overlay.component';

@NgModule({
  imports: [CommonModule, ClickOutsideModule, ReactiveFormsModule, FormsModule],
  exports: [
    DropdownComponent,
    NotificationComponent,
    TextInputComponent,
    ToggleSwitchComponent,
    ToggleSwitchListComponent,
    TextareaInputComponent,
    SimpleDropdownComponent,
    LoadingComponent,
    ErrorNotificationComponent,
    ScrollAreaComponent,
    LoadingPipe,
    KlinikShortDatePipe,
    KlinikOverlayComponent,
  ],
  declarations: [
    DropdownComponent,
    NotificationComponent,
    TextInputComponent,
    ToggleSwitchComponent,
    TextareaInputComponent,
    SimpleDropdownComponent,
    LoadingComponent,
    ErrorNotificationComponent,
    ToggleSwitchListComponent,
    ScrollAreaComponent,
    LoadingPipe,
    KlinikShortDatePipe,
    KlinikOverlayComponent,
  ],
})
export class UtilsModule {}
