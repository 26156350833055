// @ts-nocheck
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DropdownTranslations } from 'app/utils/dropdowns/dropdown/dropdown.component';
import { Subscription } from 'rxjs';
import { DashboardService } from 'services/dashboard.service';
import { SessionService } from 'services/session.service';
import { addInactiveToServiceName } from 'app/utils/serviceUtils';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input()
  public activeView: string;

  @Input()
  public activeChartgroup: object;

  @Input()
  public views: object;

  @Output()
  private emitChangeChartGroup: EventEmitter<string> = new EventEmitter();

  @Output()
  private emitChangeView: EventEmitter<any> = new EventEmitter();

  @Output()
  private emitFullscreen: EventEmitter<boolean> = new EventEmitter();

  @Output()
  private emitDateUpdate: EventEmitter<any> = new EventEmitter();

  @Output()
  private emitContactTypeUpdate: EventEmitter<DropdownItem[]> =
    new EventEmitter();

  @Output()
  private emitServiceFilterUpdate: EventEmitter<DropdownItem[]> =
    new EventEmitter();

  public allowedViews: object[] = [];
  public tabItems = {
    views: [],
  };
  public onlyTopLevelNavigation: string[] = [Views.DAILY];
  public isFullScreen = false;
  public loading = true;
  public contactTypeOptions: DropdownItem[] = [];
  public serviceFilterOptions: DropdownItem[] = [];

  public get serviceDropdownTranslations(): DropdownTranslations {
    return {
      deselectAll: $localize`:@@dropdownDeselectAll:`,
      selectAll: $localize`:@@dropdownSelectAll:`,
      noUnitsAvailable: $localize`:@@servicesDropdownNoAvailable:`,
      allUnitsSelected: $localize`:@@servicesDropdownAllSelected:`,
      noUnitsSelected: $localize`:@@servicesDropdownNoSelected:`,
      nUnitsSelected: $localize`:@@servicesDropdownNSelected:`,
    };
  }
  public get contactTypeDropdownTranslations(): DropdownTranslations {
    return {
      deselectAll: $localize`:@@dropdownDeselectAll:`,
      selectAll: $localize`:@@dropdownSelectAll:`,
      noUnitsAvailable: $localize`:@@contact_typesDropdownNoAvailable:`,
      allUnitsSelected: $localize`:@@contact_typesDropdownAllSelected:`,
      noUnitsSelected: $localize`:@@contact_typesDropdownNoSelected:`,
      nUnitsSelected: $localize`:@@contact_typesDropdownNSelected:`,
    };
  }

  private loadingStateSubscription: Subscription;
  private sidebarTranslations: object = {
    sidebar_age: $localize`:@@sidebar_age:`,
    'sidebar_all-contacts-length': $localize`:@@sidebar_all-contacts-length:`,
    sidebar_conditions: $localize`:@@sidebar_conditions:`,
    'sidebar_connect-vs-access': $localize`:@@sidebar_connect-vs-access:`,
    'sidebar_contacts-by-municipality': $localize`:@@sidebar_contacts-by-municipality:`,
    'sidebar_contacts-by-time': $localize`:@@sidebar_contacts-by-time:`,
    'sidebar_contacts-by-type': $localize`:@@sidebar_contacts-by-type:`,
    'sidebar_contacts-by-unit': $localize`:@@sidebar_contacts-by-unit:`,
    'sidebar_contacts-by-urgency': $localize`:@@sidebar_contacts-by-urgency:`,
    sidebar_daily: $localize`:@@sidebar_daily:`,
    sidebar_dashboard: $localize`:@@sidebar_dashboard:`,
    'sidebar_devices-and-operating-systems': $localize`:@@sidebar_devices-and-operating-systems:`,
    'sidebar_enquiry-outcome': $localize`:@@sidebar_enquiry-outcome:`,
    sidebar_feedback: $localize`:@@sidebar_feedback:`,
    sidebar_gender: $localize`:@@sidebar_gender:`,
    sidebar_locations: $localize`:@@sidebar_locations:`,
    sidebar_medical: $localize`:@@sidebar_medical:`,
    sidebar_outcomes: $localize`:@@sidebar_outcomes:`,
    sidebar_patient_feedback: $localize`:@@sidebar_patient_feedback:`,
    sidebar_professional_feedback: $localize`:@@sidebar_professional_feedback:`,
    'sidebar_resource-outcome': $localize`:@@sidebar_resource-outcome:`,
    'sidebar_sms-statistics': $localize`:@@sidebar_sms-statistics:`,
    'sidebar_sms-status': $localize`:@@sidebar_sms-status:`,
    sidebar_symptoms: $localize`:@@sidebar_symptoms:`,
    sidebar_technical: $localize`:@@sidebar_technical:`,
    sidebar_usage: $localize`:@@sidebar_usage:`,
    // todo: sidebar_user_feedback translation does not exist. Is this necessary?
    sidebar_user_feedback: $localize`:@@sidebar_user_feedback:`,
    sidebar_user: $localize`:@@users:`,
  };

  constructor(
    private dashboardService: DashboardService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.getSidebarConfig();
    this.contactTypeOptions = this.createContactTypeOptions();
    this.serviceFilterOptions = this.createServiceFilterOptions();
    this.loadingStateSubscription =
      this.dashboardService.loadingState$.subscribe(
        (state) => (this.loading = state)
      );
  }

  ngOnDestroy(): void {
    this.loadingStateSubscription.unsubscribe();
  }

  getSidebarTranslation(key: string): string {
    try {
      return this.sidebarTranslations[key];
    } catch (error) {
      return 'No translation found';
    }
  }

  getSidebarConfig(): void {
    const viewTypes = Object.keys(this.views);
    this.tabItems.views = viewTypes.map((viewType: string) => {
      return {
        name: this.getSidebarTranslation(`sidebar_${viewType}`),
        key: viewType,
        charts: Object.keys(this.views[viewType]).map((chart: string) => {
          return {
            name: this.getSidebarTranslation(`sidebar_${chart}`),
            key: chart,
          };
        }),
      };
    });
  }

  @HostListener('window:fullscreenchange', ['$event'])
  @HostListener('window:webkitfullscreenchange', ['$event'])
  @HostListener('window:mozfullscreenchange', ['$event'])
  @HostListener('window:MSFullscreenChange', ['$event'])
  screenChange(): void {
    if (this.isFullScreen === true) {
      this.toggleFullScreen();
    }
    this.isFullScreen = !this.isFullScreen;
    const header = document.getElementsByTagName('header')[0];
    header.classList.toggle('header');
    const sidebar = document.getElementsByClassName('sidebar-container')[1];
    sidebar.classList.toggle('sidebar-container-full');
  }

  changeChartgroup(event: string): void {
    this.emitChangeChartGroup.emit(event);
  }

  changeView(event: string): void {
    this.emitChangeView.emit(event);
  }

  changeDate(event: any): void {
    this.emitDateUpdate.emit(event);
  }

  serviceCompareWith(service1: any, service2: any) {
    return service1.slug === service2.slug && service1.id === service2.id;
  }

  toggleFullScreen(): void {
    this.isFullScreen ? this.closeFullscreen() : this.openFullscreen();
    if (this.isFullScreen === false) {
      this.emitFullscreen.emit(this.isFullScreen);
    }
  }

  openFullscreen(): void {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement['mozRequestFullScreen']) {
      /* Firefox */
      document.documentElement['mozRequestFullScreen']();
    } else if (document.documentElement['webkitRequestFullscreen']) {
      /* Chrome, Safari and Opera */
      document.documentElement['webkitRequestFullscreen']();
    } else if (document.documentElement['msRequestFullscreen']) {
      /* IE/Edge */
      document.documentElement['msRequestFullscreen']();
    }
  }

  closeFullscreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['mozCancelFullScreen']) {
      /* Firefox */
      document['mozCancelFullScreen']();
    } else if (document['webkitExitFullscreen']) {
      /* Chrome, Safari and Opera */
      document['webkitExitFullscreen']();
    } else if (document['msExitFullscreen']) {
      /* IE/Edge */
      document['msExitFullscreen']();
    }
  }

  createContactTypeOptions(): DropdownItem[] {
    return this.sessionService.serviceGroup.appointment_types.map((at) => {
      return {
        name: at.name,
        value: at.id,
        checked: true,
      };
    });
  }

  createServiceFilterOptions(): DropdownItem[] {
    const options = this.sessionService.serviceGroup.services.map((s) => {
      const serviceName = addInactiveToServiceName(s);

      return {
        name: serviceName,
        value: s.slug,
        checked: true,
      };
    });

    options.push({
      name: $localize`:@@unknownUnit:`,
      value: 'NO_SELECTED_SERVICE',
      checked: true,
    });

    return options;
  }

  handleContactTypeUpdate(event: DropdownItem[]): void {
    this.emitContactTypeUpdate.emit(event);
  }

  handleServiceFilterUpdate(event: DropdownItem[]): void {
    this.emitServiceFilterUpdate.emit(event);
  }
}
