// @ts-nocheck
import { ErrorTypes } from './http-response.enums';

export const getErrorType = (errorStatus: ErrorTypes) => {
  for (const type in ErrorTypes) {
    if (ErrorTypes[type] == errorStatus) {
      return errorStatus;
    }
  }

  return ErrorTypes.unknown;
};
