// @ts-nocheck
import {
  customOptionsBarNoAutoskip,
  customOptionsBarPlugin,
  defaultOptions,
  defaultOptionsPie,
} from '../configs/chartJSDefaultConfigs';

const translations = [
  $localize`:@@sidebar_usage:`,
  $localize`:@@sidebar_contacts-by-time:`,
  $localize`:@@sidebar_contacts-by-type:`,
  $localize`:@@sidebar_contacts-by-unit:`,
  $localize`:@@sidebar_all-contacts-length:`,
  $localize`:@@sidebar_sms-statistics:`,
  $localize`:@@sidebar_sms-status:`,
  $localize`:@@sidebar_connect-vs-access:`,
  $localize`:@@sidebar_enquiry-outcome:`,
];

export const usage: View = {
  'contacts-by-time': {
    id: 'contacts-by-time',
    charts: [
      {
        chart_config: {
          id: 'contacts-by-day',
          legend: $localize`:@@contactsByDay:`,
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.NONE,
        datasets: ['appointments_per_day'],
        translations: {
          labels: {
            appointments_per_day: $localize`:@@contactsByDay:`,
          },
        },
      },
      {
        chart_config: {
          id: 'contacts-by-hour',
          legend: $localize`:@@contactsByHour:`,
          type: ChartType.LINE,
          options: defaultOptions,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.HOUR,
        datasets: ['appointments_per_day'],
        translations: {
          labels: {
            appointments_per_day: $localize`:@@contactsByHour:`,
          },
        },
      },
      {
        chart_config: {
          id: 'contacts-by-weekday',
          legend: $localize`:@@contactsByWeekday:`,
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.WEEKDAY,
        datasets: ['appointments_per_day'],
        translations: {
          labels: {
            appointments_per_day: $localize`:@@contactsByWeekday:`,
          },
        },
      },
      {
        chart_config: {
          id: 'contacts-by-month',
          legend: $localize`:@@contactsByMonth:`,
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.MONTH,
        datasets: ['appointments_per_day'],
        translations: {
          labels: {
            appointments_per_day: $localize`:@@contactsByMonth:`,
          },
        },
      },
    ],
  },
  'contacts-by-type': {
    id: 'contacts-by-type',
    charts: [
      {
        chart_config: {
          id: 'contacts-by-type',
          legend: $localize`:@@contactsByType:`,
          top_items: {
            max_items: 30,
            show_numbers: true,
          },
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.NONE,
        datasets: ['appointment_types'],
        translations: {
          labels: {
            appointment_types: $localize`:@@contactsByType:`,
          },
        },
      },
    ],
  },
  'all-contacts-length': {
    id: 'all-contacts-length',
    charts: [
      {
        chart_config: {
          id: 'all-contacts-length',
          legend: $localize`:@@allContactsLength:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.TOTAL,
        datasets: ['all-contacts-short', 'all-contacts-long'],
        custom_groupings: [
          {
            datasets: [
              'short_contacts_female',
              'short_contacts_male',
              'short_contacts_unknown',
            ],
            label: 'all-contacts-short',
          },
          {
            datasets: ['contacts_female', 'contacts_male'],
            label: 'all-contacts-long',
          },
        ],
        translations: {
          labels: {
            'all-contacts-short': $localize`:@@allContactsShort:`,
            'all-contacts-long': $localize`:@@allContactsLong:`,
          },
        },
      },
    ],
  },
  'contacts-by-unit': {
    id: 'contacts-by-unit',
    charts: [
      {
        chart_config: {
          id: 'contacts-by-unit',
          legend: $localize`:@@contactsByUnit:`,
          type: ChartType.BAR,
          options: customOptionsBarNoAutoskip,
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.NONE,
        datasets: ['services'],
        translations: {
          labels: {
            services: $localize`:@@contactsByUnit:`,
          },
        },
      },
    ],
  },
  'sms-status': {
    id: 'sms-status',
    charts: [
      {
        chart_config: {
          id: 'SMS',
          legend: $localize`:@@sms:`,
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.TOTAL,
        datasets: ['sms_outbound', 'sms_inbound'],
        translations: {
          labels: {
            sms_inbound: $localize`:@@smsInboundCount:`,
            sms_outbound: $localize`:@@smsOutboundCount:`,
          },
        },
      },
      {
        chart_config: {
          id: 'sms-status-by-day',
          legend: $localize`:@@smsByDay:`,
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.NONE,
        datasets: ['sms_outbound', 'sms_inbound'],
        translations: {
          labels: {
            sms_inbound: $localize`:@@smsInboundCount:`,
            sms_outbound: $localize`:@@smsOutboundCount:`,
          },
        },
      },
      {
        chart_config: {
          id: 'sms-status-by-hour',
          legend: $localize`:@@smsByHour:`,
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.HOUR,
        datasets: ['sms_outbound', 'sms_inbound'],
        translations: {
          labels: {
            sms_inbound: $localize`:@@smsInboundCount:`,
            sms_outbound: $localize`:@@smsOutboundCount:`,
          },
        },
      },
      {
        chart_config: {
          id: 'sms-status-by-weekday',
          legend: $localize`:@@smsByWeekday:`,
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.WEEKDAY,
        datasets: ['sms_outbound', 'sms_inbound'],
        translations: {
          labels: {
            sms_inbound: $localize`:@@smsInboundCount:`,
            sms_outbound: $localize`:@@smsOutboundCount:`,
          },
        },
      },
      {
        chart_config: {
          id: 'sms-status-by-month',
          legend: $localize`:@@smsByMonth:`,
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.MONTH,
        datasets: ['sms_outbound', 'sms_inbound'],
        translations: {
          labels: {
            sms_inbound: $localize`:@@smsInboundCount:`,
            sms_outbound: $localize`:@@smsOutboundCount:`,
          },
        },
      },
    ],
  },
  'connect-vs-access': {
    id: 'connect-vs-access',
    charts: [
      {
        chart_config: {
          id: 'connect-vs-access',
          legend: $localize`:@@connectVsAccess:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.XLABEL,
        datasets: ['source_form'],
        translations: {
          labels: {
            connect: $localize`:@@connectVsAccessConnect:`,
            access: $localize`:@@connectVsAccessAccess:`,
          },
        },
      },
    ],
  },
};
