// @ts-nocheck
import { Injectable } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { Subject } from 'rxjs';
import {
  MessageCounts,
  MESSAGE_STATES,
  AppointmentType,
} from './message.service';
import { Enquiry, EnquiryHeader } from '../methods/enquiry/enquiry';
import { AttachmentApiResponse } from './attachment.service';
import { LoadingStatus } from 'enums';

export enum StateKeys {
  closedMessage,
  selectedMessage,
  selectedStates,
  selectedPriorities,
  resetFilter,
  closedWelcome,
  loggedIn,
  deleteSession,
  userDetailLoaded,
  selectedUser,
  loadedServiceGroup,
  userListLoaded,
  selectedService,
  redirectedMessage,
  updatedMessageState,
  toggledDrawer,
  enteredSearchTerm,
  enteredSsnSearch,
  enteredAppointmentIdSearch,
  userDetailsUpdateFailed,
  requestErrors,
  updateSuccessful,
  userDeleted,
  closeUserModal,
  deleteFailed,
  createFailed,
  loadedUserServices,
  userListLoadingFailed,
  userCountUpdated,
  userDetailsLoadFailed,
  createSuccessful,
  loadedFollowupMessages,
  updatedFollowupMessage,
  requestedNavigation,
  activatedTab,
  receivedNewFollowups,
  toggledFollowup,
  userDetailsInitComplete,
  user_order_by,
  userPageChanged,
  userSearchTerm,
  cancelledUserEditing,
  selectedDetailedUser,
  sendingFeedbackComplete,
  sendingFeedbackFailed,
  feedbackLoopDataSent,
  feedbackLoopDataSendingFailed,
  caseFeedbackDataSent,
  caseFeedbackDataSendingFailed,
  GdprInstructions,
  messageCommented,
  messageCommentEdited,
  messageCommentEditFailed,
  linkUser,
  linkSuccessful,
  documentHidden,
  documentHiddenFailed,
  emisIntegrationAvailable,
  emisPatientSearchResult,
  emisFileRecordPostStatus,
  emisAttachmentsPostStatus,
  emisPatientConnectedToRecordStatus,
  findingPatientsFromPrs,
  snoozeTimers,
  snoozeTimerFailed,
  snoozeActiveTimer,
  attachmentsFetchSuccessful,
  attachmentFetchFailed,
  time_zone,
  newKIPVersionAvailable,
  gpConnectSendDocumentStatus,
  loadedExternalForwardStatuses,
  professionalEnquiryAssessmentModalVisible,
  enquiryAlreadyInHandling,
  loadingMessages,
  messageCounts,
  dataCounts,
  dataSetRequests,
  createDataSetRequest,
  refreshUserData,
  singleMessage,
  filteredByservices,
  filteredByAppointmentTypes,
  appointmentTypes,
  attachments,

  // SMS Templates
  updateSMSTemplates,
  SMSTemplateDeleted,
}

@Injectable()
export class StateService {
  private state: object = {};
  private log: object = {};

  // public keys = new keys();
  public keys;
  stateChange: Subject<object> = new Subject<object>();
  state$ = this.stateChange.asObservable();

  constructor(protected configuration: Configuration) {
    this.keys = StateKeys;
  }

  public setState(key, value) {
    this.state[key] = value;
    this.log[key] = new Date();

    this.notify({
      key: key,
      value: value,
    });
  }

  /**
   * Returns true if the specific state key is loading.
   * @param key - One of the state keys.
   */
  public isLoading(key: StateKeys): boolean {
    return this.getState(key)?.status === LoadingStatus.LOADING;
  }

  /**
   * Sets a loading status for the specific key.
   * @param key - One of the state keys.
   */
  public setLoading(key: StateKeys) {
    this.setState(key, {
      status: LoadingStatus.LOADING,
    });
  }
  /**
   * Sets a failed status for the specific key.
   * @param key - One of the state keys.
   */
  public setFailed(key: StateKeys) {
    this.setState(key, {
      status: LoadingStatus.FAILED,
    });
  }

  /**
   * Sets a success status for the specific key.
   * @param key - One of the state keys.
   * @param data - Data received upon success.
   */
  public setSuccess(key: StateKeys, data: any) {
    this.setState(key, {
      status: LoadingStatus.SUCCESS,
      data: data,
    });
  }

  public getState(key) {
    return this.state[key];
  }

  public getLog(key) {
    return this.log[key];
  }

  private notify(state) {
    this.stateChange.next(state);
  }
}

/** An event that indicates that the state of a message was changed. */
export interface updatedMessageStateEvent {
  /** Id of the patient case. */
  messageId: number;

  /** The current, updated state of the patient case. */
  currentState: MESSAGE_STATES;
}

/** An event for fetching the messages ie. patient cases list. */
export interface MessageListEvent {
  status: LoadingStatus;
  data: Array<EnquiryHeader> | null;
}

export interface messageCountEvent {
  status: LoadingStatus;
  data: MessageCounts | null;
}

export interface singleMessageEvent {
  status: LoadingStatus;
  data: Enquiry | null;
}

export interface appointmentTypesEvent {
  status: LoadingStatus;
  data: AppointmentType[] | null;
}

export interface attachmentsEvent {
  status: LoadingStatus;
  data: AttachmentApiResponse | null;
}

export interface IntegrationTransactionEvent {
  status: LoadingStatus;
  data: IntegrationTransaction | null;
}
