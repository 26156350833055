// @ts-nocheck
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UtilsService } from 'services/utils.service';
import { SessionService } from 'services/session.service';

@Component({
  selector: 'app-messages-detail-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent implements OnInit, OnChanges {
  @Input() message: object;

  diagnoses = [];
  other_suggestions = [];
  symptom_articles = [];
  showScores = false;

  constructor(
    protected utilsService: UtilsService,
    protected sessionService: SessionService
  ) {}

  ngOnInit() {
    const session = this.sessionService.getSession();
    this.showScores = session['service_group']['show_diagnosis_scores'];
  }

  ngOnChanges(changes: SimpleChanges) {
    const unorderedDiagnoses = this.utilsService.getProperty(
      this.message,
      'data.data_v2.diagnosis'
    );
    if (unorderedDiagnoses !== null && unorderedDiagnoses !== undefined) {
      const sortedKeys = Object.keys(unorderedDiagnoses).sort(function (a, b) {
        return unorderedDiagnoses[b]['score'] - unorderedDiagnoses[a]['score'];
      });

      // this.diagnoses = sortedKeys.map(key => unorderedDiagnoses[key])

      if (this.utilsService.currentLocale === 'fi') {
        this.symptom_articles = sortedKeys
          .map((key) => unorderedDiagnoses[key])
          .filter((x) => {
            return x['symptom_article'] === true;
          });

        this.diagnoses = sortedKeys
          .map((key) => unorderedDiagnoses[key])
          .filter((x) => {
            return x['symptom_article'] !== true;
          })
          .splice(0, 2);

        this.other_suggestions = sortedKeys
          .map((key) => unorderedDiagnoses[key])
          .filter((x) => {
            return x['symptom_article'] !== true;
          })
          .splice(2);
      } else {
        // Don't use symptom diagnoses if locale is not "fi"
        this.diagnoses = sortedKeys
          .map((key) => unorderedDiagnoses[key])
          .splice(0, 2);

        this.other_suggestions = sortedKeys
          .map((key) => unorderedDiagnoses[key])
          .splice(2);
      }
    }
  }
}
