<table mat-table [dataSource]="dataSource" class="mat-elevation-z4">
  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon
        aria-hidden="false"
        [ngStyle]="{ color: getStatusColor(element.status) }"
        [aria-label]="getStatusText(element.status)"
        [title]="getStatusText(element.status)"
        >{{ getStatusIcon(element.status) }}</mat-icon
      >
    </td>
  </ng-container>

  <!-- Time Column -->
  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef>Time</th>
    <td mat-cell *matCellDef="let element">
      {{ element.time | date: 'short' }}
    </td>
  </ng-container>

  <!-- Actor Column -->
  <ng-container matColumnDef="actor">
    <th mat-header-cell *matHeaderCellDef>Actor</th>
    <td mat-cell *matCellDef="let element">{{ element.actor }}</td>
  </ng-container>

  <!-- Destination Column -->
  <ng-container matColumnDef="destination">
    <th mat-header-cell *matHeaderCellDef>Destination</th>
    <td mat-cell *matCellDef="let element">{{ element.destination }}</td>
  </ng-container>

  <!-- Http-status Column -->
  <ng-container matColumnDef="http_status_code">
    <th mat-header-cell *matHeaderCellDef>HTTP Status</th>
    <td mat-cell *matCellDef="let element">{{ element.httpStatusCode }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="logTableColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: logTableColumns"></tr>
</table>
<mat-paginator
  [pageSizeOptions]="[5, 10, 15, 20]"
  showFirstLastButtons
  aria-label="Select page of transaction logs">
</mat-paginator>
