// @ts-nocheck
import { Component, Input } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { SMSTemplatesService } from 'app/smstemplates.service';
import { SMS_TEMPLATE_KEYS } from '../../sms-messaging.component';

@Component({
  selector: 'app-new-template',
  templateUrl: './new-template.component.html',
  styleUrls: ['./new-template.component.scss'],
})
export class NewTemplateComponent {
  public readonly SMSTemplateKeys = SMS_TEMPLATE_KEYS;

  @Input() hasEditPermission: boolean;

  public sending: boolean = false;

  public newTemplate: SMSTemplate;
  protected newTemplateIsValid: object;

  constructor(
    private smsTemplatesService: SMSTemplatesService,
    private toasterService: ToasterService
  ) {
    this.initNewTemplate();
  }

  updateControl(key: string, event: InputUpdateEvent): void {
    console.debug(
      `${key}, old: ${this.newTemplate[key]}, new: ${event.value}, isValid: ${event.isValid}`
    );
    this.newTemplate[key] = event.value;
    this.newTemplateIsValid[key] = event.isValid;
  }

  async addNewTemplate(): Promise<void> {
    try {
      // Sending button is disabled while sending
      this.sending = true;

      await this.smsTemplatesService.newSMSTemplate(this.newTemplate);
      this.initNewTemplate();

      this.toasterService.pop(
        'success',
        $localize`:@@smsTemplateAddNewSuccess:`
      );
    } catch (error) {
      this.toasterService.pop('error', $localize`:@@smsTemplateAddNewError:`);
    } finally {
      this.sending = false;
    }
  }

  initNewTemplate(): void {
    this.newTemplate = <SMSTemplate>{};

    this.newTemplateIsValid = {};

    for (const key of Object.keys(SMS_TEMPLATE_KEYS)) {
      const real_key = SMS_TEMPLATE_KEYS[key];
      this.newTemplateIsValid[real_key] = false;
    }
  }

  get isNewTemplateButtonDisabled(): boolean {
    if (this.sending) return true;

    for (let key of Object.keys(this.newTemplateIsValid)) {
      const value = this.newTemplateIsValid[key];
      if (!value) {
        return true;
      }
    }

    return false;
  }
}
