<div class="status-sheet" [ngClass]="{ fail: status === 'failure' }">
  <!-- Stylised title with icon if present -->
  <h3 *ngIf="title">
    {{ title }} <mat-icon *ngIf="icon" fontIcon="icon"></mat-icon>
  </h3>

  <!-- Main content -->
  <div class="main-content">
    <ng-content></ng-content>
  </div>

  <!-- Footer when signature is present -->
  <ng-container *ngIf="signature">
    <mat-divider></mat-divider>
    <p class="signature">
      {{ signature }}
    </p>
  </ng-container>
</div>
