// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { SessionService } from 'services/session.service';
import { UtilsService } from 'services/utils.service';

export enum IsSelecting {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

interface CalendarItem {
  value: number;
  enabled: boolean;
  date: moment.Moment;
}

@Component({
  selector: 'app-common-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
  public open = false;
  public loading = false;
  public startDate: moment.Moment;
  public endDate: moment.Moment;
  public month1: moment.Moment;
  public month2: moment.Moment;
  public month1Days: CalendarItem[] = [];
  public month2Days: CalendarItem[] = [];
  public canAdvanceMonth = true;
  public canRetreatMonth = true;
  public dateInvalid: any = {
    start: {
      date: false,
      time: false,
    },
    end: {
      date: false,
      time: false,
    },
  };
  public user: object;

  // The default is defined in
  // src/app/dashboard/dashboard.component.ts:67
  public isSelecting: IsSelecting = IsSelecting.END_DATE;

  private today;
  private user_tz;

  public readonly weekDays = [
    ...moment.weekdaysShort().slice(1),
    moment.weekdaysShort(0),
  ];

  constructor(
    private utilsService: UtilsService,
    protected sessionService: SessionService
  ) {
    // Default en locale to en-UK.
    // moment doesn't apply locale settings to weekdays etc. so we specify those here.
    if (this.utilsService.currentLocale === 'en') {
      moment.locale('en-gb', {
        week: {
          dow: 1, // First day of the week is monday
          doy: 4, // First week of the year is the one with majority of it's days (>=4) in January
        },
      });
    } else {
      moment().locale(this.utilsService.currentLocale);
    }
  }

  ngOnInit() {
    this.user = this.sessionService.getSession();
    this.user_tz = this.user['service_group']['time_zone'];
    this.today = moment_tz().tz(this.user_tz);
    this.startDate = moment(this.today).add(10, 'minutes');
    this.endDate = moment(this.startDate).add(1, 'hour');
    this.month1 = moment(this.startDate).startOf('month');
    this.month2 = moment(this.month1).add(1, 'months').startOf('month');
    this.updateMonthDays();
  }

  isDateEnabled(date: moment.Moment): boolean {
    return date.isSameOrAfter(this.today, 'day');
  }

  getMonthDays(month: moment.Moment): any[] {
    const start = month.clone().startOf('month');
    const initalEmpty = start.isoWeekday() - 1;
    const end = month.clone().endOf('month');
    const finalEmpty = 7 - end.isoWeekday();
    const daysInMonth = month.daysInMonth();

    const values = [];
    for (let i = 0; i < initalEmpty; i++) {
      values.push({ value: 0, enable: false, date: null });
    }
    const day = moment(start);
    for (let i = 1; i <= daysInMonth; i++) {
      values.push({
        value: i,
        enabled: this.isDateEnabled(day),
        date: moment(day),
      });
      day.add(1, 'day');
    }
    for (let i = 0; i < finalEmpty; i++) {
      values.push({ value: 0, enable: false, date: null });
    }
    return values;
  }

  get headline() {
    if (this.startDate && this.endDate) {
      return `${this.startDate.format('L')} - ${this.endDate.format('L')}`;
    }
    return '';
  }

  getMonth1Name(): string {
    return this.month1.format('MMMM YYYY');
  }

  getMonth2Name(): string {
    return this.month2.format('MMMM YYYY');
  }

  updateMonthDays(): void {
    this.month1Days = this.getMonthDays(this.month1);
    this.month2Days = this.getMonthDays(this.month2);
    this.canAdvanceMonth = this.month2.isAfter(this.today, 'month');
    this.canRetreatMonth = this.month1.isAfter(this.today, 'month');
  }

  nextMonth(): void {
    this.month1.add(1, 'month');
    this.month2.add(1, 'month');
    this.updateMonthDays();
  }

  previousMonth(): void {
    this.month1.subtract(1, 'month');
    this.month2.subtract(1, 'month');
    this.updateMonthDays();
  }

  resetToDate(date: moment.Moment): void {
    this.startDate = moment({
      year: moment(date).year(),
      month: moment(date).month(),
      day: moment(date).date(),
      hour: this.startDate.hour(),
      minute: this.startDate.minute(),
    });
    this.endDate = moment({
      year: moment(date).year(),
      month: moment(date).month(),
      day: moment(date).date(),
      hour: this.endDate.hour(),
      minute: this.endDate.minute(),
    });

    this.isSelecting = IsSelecting.END_DATE; // Next pick will be the end date...
  }
  expandRange(date: moment.Moment): void {
    this.endDate = moment({
      year: moment(date).year(),
      month: moment(date).month(),
      day: moment(date).date(),
      hour: this.endDate.hour(),
      minute: this.endDate.minute(),
    });

    this.isSelecting = IsSelecting.START_DATE; // Next pick will be the start date...
  }
  expandReverseRange(date: moment.Moment): void {
    this.startDate = moment({
      year: moment(date).year(),
      month: moment(date).month(),
      day: moment(date).date(),
      hour: this.startDate.hour(),
      minute: this.startDate.minute(),
    });

    this.isSelecting = IsSelecting.START_DATE; // Next pick will be the end date...
  }

  selectDate(date: moment.Moment): void {
    // User picks new start date
    if (this.isSelecting === IsSelecting.START_DATE)
      return this.resetToDate(date);

    // User sets the end date to earlier than the start date
    if (date.isBefore(this.endDate, 'date'))
      return this.expandReverseRange(date);

    // User sets the end date to later than the start date
    if (date.isAfter(this.endDate, 'date')) return this.expandRange(date);

    // User sets the end date to start date
    if (date.isSame(this.startDate, 'date')) return this.resetToDate(date);
  }

  manualInputDate(event: any, input: string, unit: string): void {
    let newDate: moment.Moment;
    const dates = {
      start: this.startDate,
      end: this.endDate,
    };

    switch (unit) {
      case 'date':
        const inputDate = moment(event.target.value, 'L');
        newDate = moment({
          year: inputDate.year(),
          month: inputDate.month(),
          day: inputDate.date(),
          hour: dates[input].hour(),
          minute: dates[input].minute(),
        });
        break;
      case 'time':
        const inputTime = moment(event.target.value, 'HH:mm');
        newDate = moment({
          year: dates[input].year(),
          month: dates[input].month(),
          day: dates[input].date(),
          hour: inputTime.hour(),
          minute: inputTime.minute(),
        });
        break;
    }

    if (newDate.isValid()) {
      switch (input) {
        case 'start':
          this.startDate = newDate;
          break;
        case 'end':
          this.endDate = newDate;
          break;
      }
    } else {
      this.dateInvalid[input][unit] = true;
    }
  }
}
