<form [formGroup]="formGroup">
  <label for="textareaControl">{{ label }}</label>
  <textarea
    rows="{{ rows }}"
    name="textareaControl"
    maxlength="{{ maxlength }}"
    formControlName="textareaControl"
    [ngClass]="{
      small: smallTextarea,
      large: largeTextarea
    }"></textarea>
  <p
    class="char-count"
    *ngIf="
      textareaControl.value && textareaControl.value.length > maxlength * 0.9
    ">
    {{ textareaControl.value.length }} / {{ maxlength }}
  </p>
</form>
