<div class="modal-close-wrapper" (click)="cancel()"></div>
<div class="practice-code-selector-container">
  <p id="modal-action-header">
    <ng-container
      *ngIf="currentIntegrationAction === integrationActions.FOR_INFORMATION"
      i18n="@@integrationActionHeaderForInformation">
      Archive case and transfer to patient record
    </ng-container>
    <ng-container
      *ngIf="currentIntegrationAction === integrationActions.FOR_ACTION"
      i18n="@@integrationActionHeaderForAction">
      Archive case and transfer as an action
    </ng-container>
  </p>

  <h2 i18n="@@practiceCodeSelectionHeader">
    Which practice are you handling enquiries from?
  </h2>

  <section class="practice-code-selections">
    <div
      class="practice-code-selector-card"
      *ngFor="let pCode of practiceCodes"
      (click)="selectPracticeCode(pCode)">
      <label>
        <input
          type="radio"
          name="practice_code"
          [value]="pCode.code"
          id="pCode.code"
          [checked]="practiceCodeSelected === pCode.code" />
        <div class="practice-code-text-container">
          <p>{{ pCode.name }}</p>
          <p>{{ pCode.code }}</p>
        </div>
      </label>
    </div>
  </section>

  <section class="remember-selected-practice-code">
    <label class="input-label">
      <input
        type="checkbox"
        (click)="toggleRememberSelectedPracticeCode()"
        [checked]="rememberSelectedPracticeCode" />
      <span i18n="@@rememberMyPracticeCodeSelection">Remember my choice</span>
    </label>
  </section>

  <div class="practice-code-selector-footer">
    <button class="cancel" i18n="@@cancelButton" (click)="cancel()">
      Cancel
    </button>
    <button
      class="continue"
      [disabled]="this.practiceCodeSelected === false"
      (click)="rememberSelectionAndContinue()">
      Continue
    </button>
  </div>
</div>
