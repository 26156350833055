// @ts-nocheck
import { Injectable } from '@angular/core';
import { defaultColors } from 'app/dashboard/viewdata/configs/colors';
import { DashboardViews } from 'app/dashboard/viewdata/dashboardViews';
import * as cloneDeep from 'lodash.clonedeep';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HttpWrapperService } from './http-wrapper.service';
import { SessionService } from './session.service';
import { UtilsService } from './utils.service';
import {
  AppointmentTypesService,
  AppointmentTypesResponse,
} from './appointment-types.service';

@Injectable()
export class DashboardService {
  private chartModel: Chart;
  private total: number;
  private session = this.sessionService.getSession();
  private response: DatasetResponse;
  private sortAsc = (a: any, b: any) =>
    a['value']['total'] - b['value']['total'];
  private sortDesc = (a: any, b: any) =>
    b['value']['total'] - a['value']['total'];
  private appointmentTypes: AppointmentTypesResponse = undefined;

  public _loadingState = new BehaviorSubject<boolean>(false);
  public loadingState$: Observable<boolean> = this._loadingState.asObservable();

  constructor(
    private httpWrapper: HttpWrapperService,
    private sessionService: SessionService,
    private utilsService: UtilsService,
    private appointmentTypesService: AppointmentTypesService
  ) {
    // Default en locale to en-UK.
    // moment doesn't apply locale settings to weekdays etc. so we specify those here.
    if (this.utilsService.currentLocale === 'en') {
      moment.locale('en-gb', {
        week: {
          dow: 1, // First day of the week is monday
          doy: 4, // First week of the year is the one with majority of it's days (>=4) in January
        },
      });
    } else {
      moment().locale(this.utilsService.currentLocale);
    }
  }

  fetchViews(): object {
    const views = {};
    const data_sets = {};
    const smsChartGroups = ['sms-statistics', 'sms-status'];
    Object.keys(DashboardViews).forEach((view: string) => {
      views[view] = {};
      data_sets[view] = new Set();
      Object.keys(DashboardViews[view]).forEach((chartGroup: string) => {
        if (
          smsChartGroups.indexOf(chartGroup) === -1 ||
          this.session['data'].serviceGroup.sms_enabled === true
        ) {
          views[view][DashboardViews[view][chartGroup].id] = DashboardViews[
            view
          ][chartGroup]['charts'].map((chart: ChartDefinition) => {
            if (this.utilsService.ObjectHasKey(chart, 'custom_groupings')) {
              const customDataSets: String[] = chart.custom_groupings.reduce(
                (acc, curr) => [...acc, ...curr.datasets],
                []
              );
              customDataSets.forEach((dataset) => data_sets[view].add(dataset));
            } else {
              chart.datasets.forEach((dataset) => data_sets[view].add(dataset));
            }
            return { key: chart['chart_config']['id'] };
          });
        }
      });
    });
    return { views: views, data_sets: data_sets };
  }

  async getData(fetchData: object, view: string): Promise<object> {
    this._loadingState.next(true);

    try {
      const appointmentTypesPromise = this.appointmentTypesService
        .getAppointmentTypes()
        .toPromise();

      const getDataPromise = this.httpWrapper.deprecated_post(
        '/dashboard/get-data',
        fetchData
      );

      // Allow both promises to run in parallel
      const [appointmentTypesResponse, getDataResponse] = await Promise.all([
        appointmentTypesPromise,
        getDataPromise,
      ]);

      this.appointmentTypes =
        appointmentTypesResponse as AppointmentTypesResponse;
      this.response = getDataResponse['data'];

      return this.generateData(view);
    } catch (error) {
      throw error;
    } finally {
      this._loadingState.next(false);
    }
  }

  generateData(view: string): object {
    const charts = {};
    Object.keys(DashboardViews[view]).forEach((chartGroup: string) => {
      const relatedCharts = {
        related_charts: DashboardViews[view][chartGroup]['charts'].map(
          (chart: ChartDefinition) => {
            return {
              id: chart.chart_config.id,
              translation: chart.chart_config.legend,
            };
          }
        ),
      };
      DashboardViews[view][chartGroup]['charts'].forEach(
        (chart: ChartDefinition) => {
          // chartModel does not need to have all the ChartJS required fields, but follows the typing since it's used to create the base for charts. Thus errors ignored.
          // @ts-ignore
          this.chartModel = {
            ...chart['chart_config'],
            ...relatedCharts,
            data: { labels: [], datasets: [] },
          };
          let matched_colors: MatchedColor[] = null;

          // If the chart has Custom Groupings, aggregate the datasets
          // and add the generated ones to this.response
          if (chart['custom_groupings']) {
            chart['custom_groupings'].forEach((grouping: CustomGrouping) => {
              const groupingTotal = grouping.datasets
                .map((datasetKey: string) => {
                  const dataset: DatasetValue[] = this.response[datasetKey];
                  let total = 0;
                  // Check if the dataset already contains a "total" xlabel
                  const totalDataset = dataset.filter(
                    (value: DatasetValue) => value.xlabel === 'total'
                  );
                  // If it does, takes that one
                  if (totalDataset.length === 1) {
                    total = totalDataset[0].value.total;
                    // Else, calculate it
                  } else {
                    total = dataset.reduce(
                      (acc: number, value: DatasetValue) =>
                        acc + value.value.total,
                      0
                    );
                  }
                  return total;
                })
                // Finally, get the total of all the datasets
                .reduce((acc: number, value: number) => acc + value, 0);
              const groupingValue: DatasetValue = {
                xlabel: { no_translations: 'total' },
                value: { total: groupingTotal },
              };
              // Add the grouping to the datasets
              this.response[grouping.label] = [groupingValue];
            });
          }

          if (this.checkForDatasets(chart.datasets)) {
            chart.datasets.forEach((datasetKey: string, index: number) => {
              let times: object;
              const colors = this.utilsService.ObjectHasKey(
                chart,
                'custom_colors'
              )
                ? chart['custom_colors']
                : null;
              const dataset = cloneDeep(this.response[datasetKey]);
              const data = [];
              this.total = 0;

              if (
                this.utilsService.ObjectHasKey(
                  chart,
                  'match_custom_colors_to_dataset_label'
                )
              ) {
                matched_colors = dataset.map(
                  (value: DatasetValue, datasetIndex: number) => {
                    return {
                      label: this.createTranslation(value, datasetKey, chart),
                      color: chart.custom_colors[datasetIndex],
                    };
                  }
                );
              }

              if (this.utilsService.ObjectHasKey(chart, 'sort_by')) {
                chart.sort_by === SortBy.ASC
                  ? dataset.sort(this.sortAsc)
                  : dataset.sort(this.sortDesc);
              }

              if (matched_colors) {
                this.chartModel['backgroundColor'] = [];
                matched_colors.forEach(
                  (value: MatchedColor) =>
                    (this.chartModel['backgroundColor'][
                      this.chartModel.data.labels.indexOf(value.label)
                    ] = value.color)
                );
              }

              switch (chart.group_by) {
                case GroupBy.HOUR:
                case GroupBy.WEEKDAY:
                case GroupBy.MONTH:
                  times = this.datasetTime(dataset, chart);
                  Object.keys(times).forEach((key) => data.push(times[key]));
                  if (
                    this.utilsService.ObjectHasKey(
                      chart,
                      'chart_config.include_total'
                    )
                  ) {
                    data.push(this.total);
                  }

                  break;
                case GroupBy.TOTAL:
                case GroupBy.CUSTOM:
                  const totalData = this.datasetTotal(
                    dataset,
                    chart,
                    chart['translations']['labels'][datasetKey],
                    index
                  );
                  this.squashDatasets(
                    $localize`:@@total:`,
                    chart['datasets'],
                    totalData,
                    colors
                  );
                  break;
                case GroupBy.XLABEL:
                  const xLabelData = this.datasetDefault(
                    datasetKey,
                    dataset,
                    chart
                  );
                  this.squashDatasets(
                    $localize`:@@total:`,
                    <string[]>this.chartModel['data']['labels'],
                    xLabelData,
                    colors
                  );
                  break;
                case GroupBy.NONE:
                default:
                  data.push(...this.datasetDefault(datasetKey, dataset, chart));
                  break;
              }

              if (
                chart['group_by'] !== GroupBy.XLABEL &&
                chart['group_by'] !== GroupBy.TOTAL
              ) {
                this.chartModel['data']['datasets'].push({
                  label: chart.translations.labels[datasetKey],
                  data: data,
                  hoverBackgroundColor: `rgba(${this.getColor(index)}, 0.8)`,
                  backgroundColor: `rgba(${this.getColor(index)}, 0.55)`,
                  borderColor: `rgba(${this.getColor(index)}, 1)`,
                  borderWidth: 1,
                });
              }
            });
          }

          if (matched_colors) {
            this.chartModel['backgroundColor'] = [];
            matched_colors.forEach(
              (value: MatchedColor) =>
                (this.chartModel['backgroundColor'][
                  this.chartModel.data.labels.indexOf(value.label)
                ] = value.color)
            );
          }

          // Every other week is different color
          if (this.chartModel.id === 'contacts-by-day') {
            const datasetLength = this.chartModel.data.datasets[0].data.length;
            // If only one day there is no need to add more colors
            if (datasetLength) {
              let total = 0;
              // Check which weekday the first day is
              const firstDay = new Date(
                <string>this.chartModel.data.labels[0]
              ).getDay();

              while (total < datasetLength) {
                let i = 0;

                if (total === 0) {
                  // Initilize the color values as strig[]
                  this.chartModel.data.datasets[0].backgroundColor = [
                    `rgba(${this.getColor(total % 2)}, 0.55)`,
                  ];
                  this.chartModel.data.datasets[0].hoverBackgroundColor = [
                    `rgba(${this.getColor(total % 2)}, 0.8)`,
                  ];
                  this.chartModel.data.datasets[0].borderColor = [
                    `rgba(${this.getColor(total % 2)}, 1)`,
                  ];
                  i = firstDay;
                }

                for (i = i; i < 7; i++) {
                  (<string[]>(
                    this.chartModel.data.datasets[0].backgroundColor
                  )).push(`rgba(${this.getColor(total % 2)}, 0.55)`);
                  (<string[]>(
                    this.chartModel.data.datasets[0].hoverBackgroundColor
                  )).push(`rgba(${this.getColor(total % 2)}, 0.8)`);
                  (<string[]>this.chartModel.data.datasets[0].borderColor).push(
                    `rgba(${this.getColor(total % 2)}, 1)`
                  );
                }

                total += 7;
              }
            }
          }
          charts[chart.chart_config.id] = this.chartModel;
        }
      );
    });
    return charts;
  }

  checkForDatasets(datasets: string[]): boolean {
    for (let i = 0; i < datasets.length; i++) {
      if (!this.utilsService.ObjectHasKey(this.response, datasets[i])) {
        return false;
      }
    }
    return true;
  }

  getColor(index: number): string {
    if (index >= defaultColors.length) {
      do {
        index = index - defaultColors.length;
      } while (index >= defaultColors.length);
    }
    return defaultColors[index];
  }

  checkForDataset(array: object[], label: string): boolean {
    for (let i = 0; i < array.length; i++) {
      if (array[i]['label'] === label) {
        return true;
      }
    }
    return false;
  }

  createTranslation(
    value: DatasetValue,
    datasetKey: string,
    chart: ChartDefinition = null
  ): string {
    if (this.utilsService.ObjectHasKey(value, 'xlabel.no_translations')) {
      const noTranslation = <string>value.xlabel.no_translations;

      if (datasetKey === 'services') {
        for (let i = 0; i < this.session['services'].length; i++) {
          if (this.session['services'][i].slug === noTranslation) {
            return this.firstLetterUpperCase(
              this.session['services'][i]['name']
            );
          } else if (noTranslation === 'NO_SELECTED_SERVICE') {
            return this.firstLetterUpperCase($localize`:@@unknownUnit:`);
          } else if (i === this.session['services'].length - 1) {
            return this.firstLetterUpperCase(noTranslation);
          }
        }
      }

      if (datasetKey === 'appointment_types') {
        const translations =
          this.appointmentTypes?.[noTranslation]?.translations[
            this.utilsService.currentLocale
          ];
        const translation = translations?.name ?? translations?.short_name;
        if (!translation) {
          return noTranslation;
        }
        return translation;
      }

      if (datasetKey === 'resources') {
        const resources = this.session['available_case_resources'];
        for (let i = 0; i < resources.length; i++) {
          if (resources[i].id === noTranslation) {
            return this.utilsService.getNameTranslation(resources[i]);
          }
        }
      }

      if (datasetKey === 'procedures') {
        const procedures = this.session['available_case_procedures'];
        for (let i = 0; i < procedures.length; i++) {
          if (procedures[i].id === noTranslation) {
            return this.utilsService.getNameTranslation(procedures[i]);
          }
        }
      }

      if (
        chart &&
        this.utilsService.ObjectHasKey(
          chart,
          `translations.labels.${noTranslation}`
        )
      ) {
        return chart.translations.labels[noTranslation];
      }
    }

    const translation = this.utilsService.labelTranslation(value);

    if (translation === 'NO_MUNICIPALITY_SELECTED') {
      return $localize`:@@noMunicipality:`;
    }

    if (datasetKey === 'municipalities') {
      return this.firstLetterUpperCase(translation);
    }

    return translation;
  }

  firstLetterUpperCase(string: string): string {
    string = string
      .split(' ')
      .map(
        (word: string) =>
          `${word.substr(0, 1).toUpperCase()}${word.substr(1, word.length)}`
      )
      .join(' ');
    string = string
      .split('-')
      .map(
        (word: string) =>
          `${word.substr(0, 1).toUpperCase()}${word.substr(1, word.length)}`
      )
      .join('-');
    string = string
      .split('(')
      .map(
        (word: string) =>
          `${word.substr(0, 1).toUpperCase()}${word.substr(1, word.length)}`
      )
      .join('(');
    return string;
  }

  datasetDefault(
    datasetKey: string,
    dataset: DatasetValue[],
    chart: ChartDefinition
  ): number[] {
    return dataset.map((value: DatasetValue) => {
      this.pushLabelTranslation(
        this.createTranslation(value, datasetKey, chart)
      );
      return value.value.total ? value.value.total : 0;
    });
  }

  datasetTotal(
    dataset: DatasetValue[],
    chart: ChartDefinition,
    translation: string,
    index: number
  ): number[] {
    dataset.forEach((value: DatasetValue) => {
      this.pushLabelTranslation(translation);
      this.total += value.value.total;
    });

    return Object.keys(chart['datasets']).map((value, valueIndex) => {
      if (valueIndex === index) {
        return this.total;
      }
      return 0;
    });
  }

  datasetTime(dataset: DatasetValue[], chart: ChartDefinition): object {
    const times = {};

    switch (chart['group_by']) {
      case GroupBy.HOUR:
        // Initialize the times
        for (let i = 0; i < 24; i++) {
          times[i] = 0;
        }
        // Add the values
        dataset.forEach((value: DatasetValue) => {
          value.value.hours.forEach(
            (amount: number, hour: number) => (times[hour] += amount)
          );
          this.total += value.value.total;
        });
        break;

      case GroupBy.WEEKDAY:
        // Get the weekdays from Moment
        const weekdays: string[] = moment.weekdays(true);

        // Initialize the times
        weekdays.forEach((day: string) => (times[day] = 0));
        // Add the values
        dataset.forEach((value: DatasetValue) => {
          if (this.utilsService.ObjectHasKey(value, 'xlabel.no_translations')) {
            const day =
              weekdays[moment(value.xlabel.no_translations).weekday()];
            times[day] += value.value.total;
            this.total += value.value.total;
          }
        });
        break;

      case GroupBy.MONTH:
        // Add the values
        dataset.forEach((value: DatasetValue) => {
          if (this.utilsService.ObjectHasKey(value, 'xlabel.no_translations')) {
            const month = `${moment.months(
              moment(value.xlabel.no_translations).month()
            )} ${moment(value.xlabel.no_translations).year()}`;

            if (Object.keys(times).indexOf(month) === -1) {
              times[month] = value.value.total;
            } else {
              times[month] += value.value.total;
            }

            this.total += value.value.total;
          }
        });

        break;
    }

    Object.keys(times).forEach((label) => {
      if (this.chartModel['data']['labels'].indexOf(label) === -1) {
        this.chartModel['data']['labels'].push(label);
      }
    });

    if (
      this.utilsService.ObjectHasKey(chart, 'chart_config.include_total') &&
      this.chartModel['data']['labels'].indexOf($localize`:@@total:`) === -1
    ) {
      this.chartModel['data']['labels'].push($localize`:@@total:`);
    }

    return times;
  }

  squashDatasets(
    labelTranslation: string,
    labels: string[],
    data: number[],
    customColors: string[] = null
  ) {
    if (this.chartModel['data']['datasets'].length > 0) {
      data.forEach(
        (value: number, index: number) =>
          ((<number[]>this.chartModel['data']['datasets'][0]['data'])[index] +=
            value)
      );
    } else {
      this.chartModel['data']['datasets'] = [
        {
          label: labelTranslation,
          data: data,
          hoverBackgroundColor: labels.map(
            (label: string, index: number) =>
              `rgba(${
                customColors ? customColors[index] : this.getColor(index)
              }, 0.8)`
          ),
          backgroundColor: labels.map(
            (label: string, index: number) =>
              `rgba(${
                customColors ? customColors[index] : this.getColor(index)
              }, 0.55)`
          ),
          borderColor: labels.map(
            (label: string, index: number) =>
              `rgba(${
                customColors ? customColors[index] : this.getColor(index)
              }, 1)`
          ),
          borderWidth: 1,
        },
      ];
    }
  }

  pushLabelTranslation(translation: string) {
    if (this.chartModel['data']['labels'].indexOf(translation) === -1) {
      this.chartModel['data']['labels'].push(translation);
    }
  }
}
