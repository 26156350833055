import { Component, OnInit } from '@angular/core';
import { UserService } from 'services/user.service';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss'],
})
export class StaffComponent implements OnInit {
  /**
   * Indicates whether the users list is currently being downloaded.
   */
  public downloadingUsersList: boolean = false;

  constructor(
    protected userService: UserService,
    protected utilsService: UtilsService
  ) {}

  ngOnInit(): void {}

  async downloadActiveUsersList() {
    try {
      this.downloadingUsersList = true;
      const data: Blob = await this.userService.downloadUsersList();
      this.utilsService.downloadFile(data, 'text/csv', 'active_users.csv');
    } finally {
      this.downloadingUsersList = false;
    }
  }
}
