<h1 i18n="@@tiles">Tiles</h1>

<p i18n="@@tilesDesc">
  Disable or enable tiles from the patient or connect forms. Disabled tiles will
  be removed from the forms within a few minutes.
</p>

<table class="table">
  <thead>
    <th i18n="@@name">Name</th>
    <th>Id</th>
    <th i18n="@@patientForm">Patient form</th>
    <th i18n="@@connectForm">Connect form</th>
  </thead>
  <tbody>
    <tr *ngFor="let tile of tiles">
      <td>{{ getTileName(tile) }}</td>
      <td>{{ tile.id }}</td>
      <td>
        <app-toggle-switch
          [value]="tile.base_form"
          [askConfirm]="true"
          [askConfirmText]="askConfirmText(tile)"
          (updatedEvent)="updateTileBaseForm(tile, $event)">
        </app-toggle-switch>
      </td>
      <td>
        <app-toggle-switch
          [value]="tile.pro_form"
          [askConfirm]="true"
          [askConfirmText]="askConfirmText(tile)"
          (updatedEvent)="updateTileProForm(tile, $event)">
        </app-toggle-switch>
      </td>
    </tr>
  </tbody>
</table>

<button class="btn action" (click)="commitChanges()" i18n="@@save">Save</button>
