// @ts-nocheck
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-and-chart',
  templateUrl: './table-and-chart.component.html',
  styleUrls: ['./table-and-chart.component.scss'],
})
export class TableAndChartComponent {
  @Input()
  public chart: Chart = null;
}
