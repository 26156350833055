// @ts-nocheck
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';
import { DetailModule } from './detail/detail.module';

import { ListComponent } from './list/list.component';
import { SuperuserComponent } from './superuser.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule.forRoot(),
    DetailModule,
  ],
  declarations: [SuperuserComponent, ListComponent],
})
export class SuperuserModule {}
