<form [formGroup]="internalFormGroup">
  <label for="internalInput">
    {{ label }}<span style="color: red">{{ isRequired ? ' *' : '' }}</span>
  </label>

  <input
    class="form-control"
    [id]="inputId"
    [autocomplete]="autocomplete"
    [type]="type"
    formControlName="internalFormControl"
    name="internalInput" />
  <p class="error" *ngIf="showErrors">{{ errorText }}</p>
</form>
