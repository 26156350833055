// @ts-nocheck
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpWrapperService } from 'services/http-wrapper.service';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';
import { environment } from '../../../environments/environment';

/**
 * Service to manage service groups.
 */
@Injectable()
export class ServiceGroupService {
  constructor(
    private httpWrapper: HttpWrapperService,
    private http: HttpClient,
    private sessionService: SessionService,
    private stateService: StateService
  ) {}

  catchError(error) {
    if (environment.production !== true) {
      console.error(error);
    }
  }

  getParameterString(params) {
    const parameters: Array<any> = [];
    for (const key in params) {
      if (
        params.hasOwnProperty(key) &&
        typeof params[key] !== 'undefined' &&
        params[key] !== null
      ) {
        parameters.push(key + '=' + params[key]);
      }
    }
    return parameters.join('&');
  }

  /**
   * Returns all service groups from Backend that the user has access to.
   */
  async getAllServiceGroups(): Promise<ServiceGroupListSerializerData[]> {
    const headers = this.httpWrapper.prepareHeaders('get');

    try {
      const url = `${environment.backendURL}/service_groups`;
      const serviceGroups = await this.http
        .get(url, { headers: headers })
        .toPromise();

      return serviceGroups as Array<ServiceGroup>;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Get the service group details from Backend.
   */
  async getServiceGroup(id: number): Promise<ServiceGroup> {
    const headers = this.httpWrapper.prepareHeaders('get');

    try {
      const url = `${environment.backendURL}/service_group/${id}`;
      const serviceGroup = (await this.http
        .get(url, { headers: headers })
        .toPromise()) as ServiceGroup;

      this.sessionService.setServiceGroup(serviceGroup);
      this.stateService.setState(StateKeys.loadedServiceGroup, serviceGroup);

      return serviceGroup;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Get the current user's active service group details from Backend.
   */
  async getActiveServiceGroup(): Promise<ServiceGroup> {
    return this.getServiceGroup(this.sessionService.serviceGroupId);
  }

  /**
   * Updates the active service group's fields.
   * @param service_group - The fields to update for the active service group.
   */
  async updateServiceGroup(service_group: object): Promise<ServiceGroup> {
    const headers = this.httpWrapper.prepareHeaders('put');

    try {
      const id = this.sessionService.serviceGroupId;
      const url = `${environment.backendURL}/service_group/${id}`;

      const serviceGroup = (await this.http
        .put(url, service_group, { headers: headers })
        .toPromise()) as ServiceGroup;

      this.sessionService.setServiceGroup(serviceGroup);
      this.stateService.setState(StateKeys.loadedServiceGroup, serviceGroup);

      return serviceGroup;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getAuditEvents(params): Promise<QuerySetResponse> {
    try {
      const headers = this.httpWrapper.prepareHeaders();

      const url: string = `${environment.backendURL}/service_group/${
        this.sessionService.serviceGroupId
      }/audit_events/?${this.getParameterString(params)}`;

      return (await this.http
        .get(url, { headers: headers })
        .toPromise()) as QuerySetResponse;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Get all services for the active service group.
   *
   * @param includeDeleted boolean - Whether to include (soft) deleted services or not.
   */
  getServices(
    includeDeleted: boolean = false
  ): Service[] | PromiseLike<Service[]> {
    try {
      const headers = this.httpWrapper.prepareHeaders();
      let url = `${environment.backendURL}/service_group/${this.sessionService.serviceGroupId}/services/`;

      if (includeDeleted) {
        url += '?include_deleted=true';
      }

      return this.http.get(url, { headers: headers }).toPromise() as Promise<
        Service[]
      >;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Updates a service.
   * @param serviceId - Id of the service.
   * @param active - Whether the service is active or inactive.
   * @param deleted - Date when the service was deleted. Null if you want to undelete the service.
   */
  async updateService(
    serviceId: number,
    active: boolean,
    deleted: Date | null
  ): Promise<{}> {
    try {
      const headers = this.httpWrapper.prepareHeaders('put');
      const url = `${environment.backendURL}/service_group/${this.sessionService.serviceGroupId}/services/${serviceId}/`;
      const response = await this.http
        .put(
          url,
          {
            active: active,
            deleted: deleted,
          },
          { headers: headers }
        )
        .toPromise();

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Deletes a service. This is a soft delete, meaning that the service is not actually deleted from the database.
   * @param serviceId - Service id.
   */
  async deleteService(serviceId: number): Promise<void> {
    try {
      const headers = this.httpWrapper.prepareHeaders();
      const url = `${environment.backendURL}/service_group/${this.sessionService.serviceGroupId}/services/${serviceId}/`;
      const response = await this.http
        .delete(url, { headers: headers })
        .toPromise();
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
