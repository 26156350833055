// @ts-nocheck
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from 'services/session.service';
import { UtilsService } from 'services/utils.service';

@Injectable()
export class NoSessionGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
    private utilsService: UtilsService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const session = this.sessionService.getSession();
    // User should not have a session when navigating to this page
    if (!session) {
      return true;
    }

    try {
      // If they do have a session, check it's validity. If it's valid forward user back to Pro UI.
      // I.e. when user is logged in and somehow is trying to access login page, they should not be able to unless they are logged out.
      const language = this.utilsService.getCurrentLocale();
      await this.sessionService.refreshUserData(language);
      return false;
    } catch (error) {
      // If session did exist, but it was not valid, destroy it and allow user to continue navigation.
      this.sessionService.deleteSession();
      return true;
    }
  }
}
