// @ts-nocheck
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardModule } from '../dashboard/dashboard.module';
import { UtilsModule } from '../utils/utils.module';
import { SettingsComponent } from './settings.component';
import { SnoozeTimersComponent } from './snooze-timers/snooze-timers.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { SmsMessagingComponent } from './sms-messaging/sms-messaging.component';
import { PermissionsModule } from 'app/shared/permissions/permissions.module';
import { DeleteConfirmationPopupComponent } from './components/delete-confirmation-popup/delete-confirmation-popup.component';
import { NewTemplateComponent } from './sms-messaging/sms-templates/new-template/new-template.component';
import { EditTemplatesComponent } from './sms-messaging/sms-templates/edit-templates/edit-templates.component';
import { SmsTemplatesComponent } from './sms-messaging/sms-templates/sms-templates.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { FeaturesComponent } from './features/features.component';
import { RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { DetailModule } from '../users/detail/detail.module';
import { UserAuthenticationComponent } from './my-account/user-authentication-settings/user-authentication-settings.component';
import { AuthModule } from 'app/auth/auth.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UnitsComponent } from './units/units.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DataComponent } from './data/data.component';
import { StaffComponent } from './staff/staff.component';
import { AuthenticationDataComponent } from './authentication-data/authentication-data.component';
import { AppMaterialModule } from 'app/app-material/app-material.module';
import { FeatureOverviewComponent } from './feature-overview/feature-overview.component';
import { ReportsComponent } from './reports/reports.component';
import { TilesComponent } from './tiles/tiles.component';

@NgModule({
  imports: [
    CommonModule,
    DashboardModule,
    UtilsModule,
    FormsModule,
    ReactiveFormsModule,
    PermissionsModule,
    RouterModule,
    DetailModule,
    AuthModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    AppMaterialModule,
  ],
  declarations: [
    SettingsComponent,
    SnoozeTimersComponent,
    DatepickerComponent,
    SmsMessagingComponent,
    DeleteConfirmationPopupComponent,
    NewTemplateComponent,
    EditTemplatesComponent,
    SmsTemplatesComponent,
    AuthenticationComponent,
    FeaturesComponent,
    ChangePasswordComponent,
    MyAccountComponent,
    UserAuthenticationComponent,
    UnitsComponent,
    DataComponent,
    StaffComponent,
    AuthenticationDataComponent,
    FeatureOverviewComponent,
    ReportsComponent,
    TilesComponent,
  ],
})
export class SettingsModule {}
