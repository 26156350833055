<section class="auth-container">
  <h1 i18n="@@resetPasswordPageTitle">Renew password</h1>
  <form
    name="form"
    (ngSubmit)="f.form.valid && resetPassword()"
    #f="ngForm"
    novalidate>
    <div [ngClass]="{ 'has-error': f.submitted && !username.valid }">
      <input
        id="username-reset-password"
        type="text"
        class="form-control"
        name="username"
        [(ngModel)]="model.username"
        #username="ngModel"
        required
        placeholder="Sähköpostiosoite"
        i18n-placeholder="@@resetPasswordPageEmailPlaceholder"
        (keyup)="validateEmail()"
        (blur)="validate = true; validateEmail()" />
      <div *ngIf="f.submitted && !username.valid" class="error">
        <i class="fa fa-exclamation-circle"></i>
        <p i18n="@@resetPasswordPageErrorEmailMissing">Fill in email</p>
      </div>
      <div>
        <span
          *ngIf="state === 'form-success'"
          class="success"
          i18n="@@resetPasswordResetLinkSent">
          A password reset link has been sent to the provided email address. If
          you do not receive the reset link within 10 minutes, make sure the
          email address is written correctly and try again.
        </span>

        <ng-container [ngSwitch]="errorType">
          <div class="error" *ngSwitchCase="errorTypes.tooManyRequests">
            <i class="fa fa-exclamation-circle"></i>
            <p i18n="@@errorTooManyRequests">Too many requests!</p>
          </div>

          <div class="error" *ngSwitchCase="errorTypes.unknown">
            <i class="fa fa-exclamation-circle"></i>
            <p i18n="@@resetPasswordPageErrorFailed">Password reset failed.</p>
          </div>

          <div class="error" *ngSwitchCase="errorTypes.invalidEmail">
            <i class="fa fa-exclamation-circle"></i>
            <p i18n="@@invalidEmailError">
              Please check the provided email is correct.
            </p>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="flexbox">
      <button
        class="btn btn-auth"
        [disabled]="errorType === errorTypes.invalidEmail"
        i18n="@@resetPasswordFormSubmitButton">
        Send
      </button>
      <a routerLink="/auth/login" i18n="@@resetPasswordLoginLink"
        >Back to log in screen</a
      >
    </div>
  </form>
</section>
