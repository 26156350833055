<div
  class="modal fade"
  bsModal
  #redirectModal="bs-modal"
  [config]="{ backdrop: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true">
  <div
    class="modal-close-wrapper"
    (click)="selectRedirectTarget(null); redirectModal.hide()"></div>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <form
        [formGroup]="redirectForm"
        (ngSubmit)="OnSubmit(redirectTarget, redirectForm.value)">
        <div class="modal-header">
          <h4
            class="modal-title pull-left"
            i18n="@@viestitoiminnotOhjausOtsikko">
            Vahvista viestin ohjaus
          </h4>
        </div>

        <div class="modal-body" *ngIf="redirectTarget">
          <p>
            <span i18n="@@viestitoiminnotOhjausKysymys"
              >Oletko varma, että haluat siirtää viestin käsittelyn yksikköön:
            </span>
            <strong>{{ redirectTarget.name }}</strong
            >?
          </p>

          <p *ngIf="!userHasServicePermission(redirectTarget)">
            <i class="fa fa-exclamation-circle"></i>
            <ng-container i18n="@@viestitoiminnotOhjausHuomautus">
              Huom! Et pysty enää näkemään viestiä tai tekemään siihen muutoksia
              kun se on siirretty kyseisen yksikön vastuulle.
            </ng-container>
          </p>

          <div class="redirect-form-row">
            <div class="form-group redirect-form-group">
              <label for="redirect-content" i18n="@@KommenttiLabel"
                >Redirecting comment</label
              >
              <textarea
                rows="10"
                resizeable="false"
                class="form-control"
                id="redirect-content"
                formControlName="content"></textarea>

              <div
                class="professional-flag-input"
                *ngIf="!this.message.flagged_by_professional">
                <input
                  type="checkbox"
                  id="redirect-flag"
                  formControlName="flag" />
                <label for="redirect-flag" i18n="@@professionalFlagLabel">
                  Requires extra attention from a healthcare professional
                </label>
              </div>
            </div>
            <div
              class="error"
              i18n="@@KommenttiError"
              *ngIf="
                redirectForm.controls.content.hasError('required') &&
                redirectForm.controls.content.touched
              ">
              Input transfer comment
            </div>
          </div>

          <div class="modal-buttons">
            <button
              class="btn cancel"
              (click)="selectRedirectTarget(null); redirectModal.hide()"
              i18n="@@viestitoiminnotOhjausPeruuta">
              Peruuta
            </button>
            <button
              class="btn action btn-submit"
              (click)="redirectModal.hide()"
              type="submit"
              [disabled]="redirectForm.invalid"
              i18n="@@viestitoiminnotOhjausVahvista">
              Vahvista
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-prompt
  *ngIf="showPrompt"
  [translations]="promptTranslations"
  (clickEmitter)="handlePromptResponse($event)">
</app-prompt>

<section class="detail-actions" [class.visible]="message">
  <div
    class="process-actions"
    *ngIf="redirectionServices !== null && redirectionServices.length > 0">
    <div dropdown class="btn-group">
      <button
        dropdownToggle
        id="unit-transfer"
        type="button"
        class="btn action dropdown-toggle"
        [disabled]="isMessageLoading">
        <span class="longtext" i18n="@@viestitoiminnotOhjausPitkä"
          >Ohjaa yksikköön</span
        >
        <span class="shorttext" i18n="@@viestitoiminnotOhjausLyhyt">Ohjaa</span>
        <span class="caret"></span>
      </button>

      <div *dropdownMenu class="dropdown-menu" role="menu">
        <div *ngFor="let service of redirectionServices">
          <div role="menuitem" *ngIf="service?.id !== serviceId">
            <div
              class="dropdown-item"
              click-prevent-default
              (click)="selectRedirectTarget(service); redirectModal.show()">
              {{ service.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="display-actions">
    <button
      type="button"
      class="btn action"
      (click)="downloadPdf()"
      [disabled]="isMessageLoading">
      <span>
        <i class="fa fa-print" aria-hidden="true"></i>
        <span class="label" i18n="@@print">Print</span>
      </span>
    </button>

    <button
      class="btn action"
      *ngIf="isVideoConsultationEnabled"
      (click)="openVideoConsultation()"
      [disabled]="isMessageLoading">
      <span>
        <i class="fa fa-video-camera"></i>
        <span class="label" i18n="@@videoConsultation">Video consultation</span>
      </span>
    </button>

    <button
      type="button"
      class="btn"
      (click)="closeMessage()"
      [disabled]="isMessageLoading">
      <i class="fa fa-times-circle" aria-hidden="true"></i>
      <span class="label" i18n="@@close"> Sulje </span>
    </button>
  </div>
</section>
