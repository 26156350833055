<section id="users-page" class="users">
  <section class="users-list" *ngIf="currentUrl !== '/users/own-details'">
    <div class="content">
      <app-users-list (userSelected)="userSelected($event)"></app-users-list>
    </div>
  </section>

  <div
    #userModal="bs-modal"
    class="modal fade"
    bsModal
    tabindex="-1"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
    [config]="{ backdrop: false, keyboard: false }">
    <div class="modal-close-wrapper" (click)="closeUserModal()"></div>
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            <span i18n="@@kayttajanTiedotOtsikko" *ngIf="modalType === 1"
              >Muokkaa käyttäjän tietoja</span
            >
            <span i18n="@@kayttajanTiedotUusiOtsikko" *ngIf="modalType === 2"
              >Luo uusi käyttäjä</span
            >
            <span i18n="@@userDropdownMyAccount" *ngIf="modalType === 0"
              >Omat tiedot</span
            >
          </h4>

          <button
            type="button"
            class="btn btn-close"
            (click)="closeUserModal()">
            <i class="fa fa-times-circle" aria-hidden="true"></i>
            <span class="label" i18n="@@close">Sulje</span>
          </button>
        </div>
        <div class="modal-body">
          <app-users-detail
            *ngIf="selectedUser"
            [modalType]="modalType"
            [userDetails]="selectedUser"
            (userDetailsUpdated)="closeUserModal(true)"
            (cancelEvent)="closeUserModal()"
            [openedInPopup]="true"></app-users-detail>
        </div>
      </div>
    </div>
  </div>
</section>

<app-link-service-groups
  *ngIf="existingUser"
  [username]="existingUser"
  (closePopUpEmitter)="existingUser = $event"></app-link-service-groups>
