<div class="dropdown">
  <div class="dropdown-button">
    <app-dropdown
      [translations]="serviceDropdownTranslations"
      [options]="serviceFilterOptions"
      (selectedOptions)="handleServiceChange($event)">
    </app-dropdown>
  </div>

  <div class="dropdown-button">
    <app-dropdown
      [translations]="priorityDropdownTranslations"
      [options]="priorityFilterOptions"
      (selectedOptions)="handlePriorityChange($event)">
    </app-dropdown>
  </div>

  <div class="dropdown-button">
    <app-dropdown
      [translations]="appointmentTypeDropdownTranslations"
      [options]="appointmentTypeFilterOptions"
      (selectedOptions)="handleAppointmentTypeChange($event)">
    </app-dropdown>
  </div>
</div>

<div
  class="drawer-opener btn-group"
  [class.open]="drawerOpen"
  [class.active]="searchTerm || appointmentType">
  <button class="btn" (click)="toggleDrawer()">
    <i class="fa fa-filter"></i>
    <span i18n="@@viestilistanSuodatusNaytaHaku">Haku...</span>
  </button>
</div>

<div class="drawer-wrapper" [class.open]="drawerOpen">
  <div class="drawer">
    <div class="drawer-content">
      <div
        class="form-input search-input"
        [class.active]="ssnSearch"
        *ngIf="ssnSearchVisible">
        <div class="input-group">
          <div class="input-group-addon" i18n="@@viestlistanSuodatusHakuHetu">
            SSN
          </div>
          <input
            type="text"
            i18n-placeholder="@@viestilistanSuodatusHakuHetuAputeksti"
            placeholder="Syötä henkilötunnus"
            name="ssnSearch"
            [(ngModel)]="ssnSearch" />
          <button
            [disabled]="
              !ssnSearch ||
              ssnSearch.length <= 2 ||
              ssnSearch === lastFiltered['enteredSsnSearch']
            "
            (click)="filterMessageList('enteredSsnSearch', ssnSearch)"
            i18n="@@filterSearch">
            Hae
          </button>
          <span
            class="reset-link"
            (click)="
              ssnSearch = null; filterMessageList('enteredSsnSearch', ssnSearch)
            "
            *ngIf="lastFiltered['enteredSsnSearch']">
            <i class="fa fa-times"></i>
            <span i18n="@@viestilistanSuodatusHakuHenkilotunnusPoista"
              >Remove filter</span
            >
          </span>
        </div>
      </div>

      <div class="form-input search-input" [class.active]="searchAppointmentId">
        <div class="input-group">
          <div class="input-group-addon" i18n="@@viestinAsiakasAsiointitunnus">
            Case identifier
          </div>
          <input
            type="text"
            i18n-placeholder="@@viestilistanSuodatusHakuAsioinittunnusAputeksti"
            placeholder="Syötä asiointitunnus"
            name="appointmetIdSearch"
            [(ngModel)]="searchAppointmentId" />
          <button
            [disabled]="
              searchAppointmentId === lastFiltered['enteredAppointmentIdSearch']
            "
            (click)="
              filterMessageList(
                'enteredAppointmentIdSearch',
                searchAppointmentId
              )
            "
            i18n="@@filterSearch">
            Hae
          </button>
          <span
            class="reset-link"
            (click)="
              searchAppointmentId = null;
              filterMessageList(
                'enteredAppointmentIdSearch',
                searchAppointmentId
              )
            "
            *ngIf="
              searchAppointmentId && lastFiltered['enteredAppointmentIdSearch']
            ">
            <i class="fa fa-times"></i>
            <span i18n="@@viestilistanSuodatusHakuAsioinittunnusPoista"
              >Remove filter</span
            >
          </span>
        </div>
      </div>

      <div class="form-input search-input" [class.active]="searchTerm">
        <div class="input-group">
          <div
            class="input-group-addon"
            i18n="@@viestlistanSuodatusHakuLahettaja">
            Lähettäjä
          </div>
          <input
            type="text"
            i18n-placeholder="@@viestilistanSuodatusHakuLahettajaAputeksti"
            placeholder="Syötä nimi"
            name="searchTerm"
            [(ngModel)]="searchTerm" />
          <button
            [disabled]="searchTerm === lastFiltered['enteredSearchTerm']"
            (click)="filterMessageList('enteredSearchTerm', searchTerm)"
            i18n="@@filterSearch">
            Hae
          </button>
          <span
            class="reset-link"
            (click)="
              searchTerm = null;
              filterMessageList('enteredSearchTerm', searchTerm)
            "
            *ngIf="searchTerm && lastFiltered['enteredSearchTerm']">
            <i class="fa fa-times"></i>
            <span i18n="@@viestilistanSuodatusHakuLahettajaPoista"
              >Poista rajaus</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
