// @ts-nocheck
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from 'services/session.service';
import { getNestedValue } from '../methods/getNestedValue';

@Injectable({
  providedIn: 'root',
})
export class MultipleServiceGroupsGuard implements CanActivate {
  constructor(private sessionService: SessionService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // Allow staff user to navigate regardless of how many service groups they are explicitly
    if (this.sessionService.userIsStaff) {
      return true;
    }

    const session = this.sessionService.getSession();
    const serviceGroups = getNestedValue(session, 'user_service_groups');

    if (!serviceGroups) {
      return false;
    }

    if (serviceGroups.length <= 1) {
      return false;
    }

    return true;
  }
}
