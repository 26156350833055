// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import {
  DataCounts,
  DataCountsEvent,
  DataSetRequest,
  DataSetRequestsEvent,
  DatasetRequestService,
} from 'services/dataset-request.service';
import { SessionService } from 'services/session.service';
import { StateService } from 'services/state.service';
import { shortUuid } from 'services/utils.service';
import { ServiceGroupService } from 'services/service-group.service';
import { LoadingStatus } from 'enums';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss'],
})
export class DataComponent implements OnInit {
  public serviceGroup: ServiceGroup;

  public hasPendingDataSetRequests: boolean = false;
  public dataSetRequestsStatus: LoadingStatus = LoadingStatus.NONE;
  public dataSetRequests: Array<DataSetRequest> = [];

  public dataCounts: DataCounts;
  public dataCountsStatus: LoadingStatus = LoadingStatus.NONE;

  public sendingDataSetRequest: LoadingStatus = LoadingStatus.NONE;

  public translations = {
    somethingWentWrong: $localize`:@@somethingWentWrong:`,
    serviceGroupHasPendingDataSetRequests: $localize`:@@serviceGroupHasPendingDataSetRequests:`,
    failedToLoadDataSetRequests: $localize`:@@failedToLoadDataSetRequests:`,
    failedToLoadDataCounts: $localize`:@@failedToLoadDataCounts:`,
  };

  constructor(
    private datasetRequestService: DatasetRequestService,
    private serviceGroupService: ServiceGroupService,
    private sessionService: SessionService,
    private toaster: ToasterService,
    private stateService: StateService
  ) {}

  async ngOnInit() {
    this.serviceGroup = this.sessionService.serviceGroup;

    if (!this.isDataSetRequestsEnabled) {
      // Prevent making any API requests if the feature is disabled, to avoid unnecessary errors.
      return;
    }

    this.stateService.state$.subscribe((state) => {
      switch (state['key']) {
        case this.stateService.keys.dataCounts:
          const dataCountsEvent = state['value'] as DataCountsEvent;
          this.handleDataCountsEvent(dataCountsEvent);
          break;
        case this.stateService.keys.dataSetRequests:
          const dataSetRequestsEvent = state['value'] as DataSetRequestsEvent;
          this.handleDataSetRequestsEvent(dataSetRequestsEvent);
          break;
        case this.stateService.keys.createDataSetRequest:
          const createDataSetRequestEvent = state[
            'value'
          ] as DataSetRequestsEvent;
          this.handleCreateDataSetRequestEvent(createDataSetRequestEvent);
          break;
      }
    });

    // Make the requests AFTER the state service is subscribed to,
    // so that the subscriptions can handle the responses.
    this.datasetRequestService
      .getServiceGroupDataCounts(this.serviceGroup.id)
      .catch((error) => {
        // todo: handle the errors in a better way, eg. where the data is actually used in the HTML.
        this.toaster.pop('error', 'Unable to get data counts');
      });

    this.datasetRequestService
      .getDataSetRequests(this.serviceGroup.id)
      .catch((error) => {
        // todo: handle the errors in a better way, eg. where the data is actually used in the HTML.
        this.toaster.pop('error', 'Unable to get data set requests');
      });
  }

  private handleDataCountsEvent(event: DataCountsEvent) {
    this.dataCountsStatus = event.status;
    this.dataCounts = event.data;
  }

  private handleDataSetRequestsEvent(event: DataSetRequestsEvent) {
    this.dataSetRequestsStatus = event.status;
    this.dataSetRequests = event.data;

    // Set download statuses as NONE for each data set request after they've been fetched
    this.dataSetRequests?.map((d) => {
      d.downloadState = LoadingStatus.NONE;
    });

    this.setPendingDataSetRequestsState();
  }

  private handleCreateDataSetRequestEvent(event: DataSetRequestsEvent) {
    this.sendingDataSetRequest = event.status;

    if (event.status === LoadingStatus.SUCCESS) {
      this.toaster.pop('success', 'Data set request created');
    }

    if (event.status === LoadingStatus.FAILED) {
      this.handleCreateDataSetRequestError(event.error);
    }

    // Refresh the data set requests
    this.datasetRequestService.getDataSetRequests(this.serviceGroup.id);
  }

  private handleCreateDataSetRequestError(error) {
    if (error.status === 500) {
      this.toaster.pop('error', 'Failed to create a new data set request');
    }

    if (error.status === 403) {
      this.toaster.pop(
        'error',
        `You don't have permissions to request ${this.serviceGroup.name}'s data`
      );
    }

    if (
      error.status === 400 &&
      error?.error?.code === 'PENDING_DATA_SET_REQUEST_EXISTS'
    ) {
      this.toaster.pop(
        'error',
        `A pending data set request already exists for ${this.serviceGroup.name}`
      );
    }
  }

  public get isDataSetRequestsEnabled(): boolean {
    return this.serviceGroup?.data_set_requests_enabled;
  }

  public get userIsStaff(): boolean {
    return this.sessionService.userIsStaff;
  }

  public isDownloadButtonDisabled(dataSetRequest: DataSetRequest): boolean {
    const isCompleted: boolean =
      dataSetRequest.status === DataSetRequestStatus.COMPLETE;
    const isLoading: boolean =
      dataSetRequest.downloadState === LoadingStatus.LOADING;
    return isCompleted === false || isLoading;
  }

  public toggleDataSetRequestsEnabled(enabled: boolean): void {
    this.serviceGroupService
      .updateServiceGroup({
        data_set_requests_enabled: enabled,
      })
      .then((serviceGroup) => {
        this.serviceGroup = serviceGroup;

        // Run ngOnInit to update all the data, subscriptions, etc.
        this.ngOnInit();
        this.toaster.pop('success', $localize`:@@savedSuccesfully:`);
      })
      .catch(() => {
        this.toaster.pop('error', $localize`:@@errorPleaseTryAgain:`);
      });
  }

  private setPendingDataSetRequestsState() {
    this.hasPendingDataSetRequests = this.dataSetRequests?.find(
      (d) => d.data_set_created == null
    )
      ? true
      : false;
  }

  requestServiceGroupData() {
    this.datasetRequestService.createNewDataSetRequest(
      this.sessionService.serviceGroup.id
    );
  }

  setDataSetDownloadStatus(dataSetId: number, status: LoadingStatus): void {
    this.dataSetRequests.find((x) => x.id == dataSetId).downloadState = status;
  }

  async downloadDataSet(dataSetId: number) {
    this.setDataSetDownloadStatus(dataSetId, LoadingStatus.LOADING);
    try {
      const response = await this.datasetRequestService.getSignedDatasetUrl(
        dataSetId
      );
      this.openUrlInNewTab(response.download_url);
      this.setDataSetDownloadStatus(dataSetId, LoadingStatus.SUCCESS);
    } catch (error) {
      this.setDataSetDownloadStatus(dataSetId, LoadingStatus.FAILED);
    }
  }

  openUrlInNewTab(url: string) {
    window.open(url, '_blank');
  }

  get dataSetDescription() {
    return `${$localize`:@@leavingCustomerDataSetDescription:`} ${
      this.serviceGroup.name
    }`;
  }

  get dataCountsDescription() {
    return `${$localize`:@@dataCountsDescription:`} ${this.serviceGroup.name}`;
  }

  get serviceGroupHasPendingDataSetRequestsDescription() {
    return `${
      this.serviceGroup.name
    } ${$localize`:@@serviceGroupHasPendingDataSetRequests:`}`;
  }

  shortUuid(uuid: string) {
    return shortUuid(uuid);
  }

  getTranslatedDatasetStatus(status: DataSetRequestStatus) {
    switch (status) {
      case DataSetRequestStatus.PENDING:
        return $localize`:@@pending:`;
      case DataSetRequestStatus.COMPLETE:
        return $localize`:@@complete:`;
      case DataSetRequestStatus.EXPIRED:
        return $localize`:@@expired:`;

      default:
        return '';
    }
  }
}
