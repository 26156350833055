import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpMethod, HttpTools } from 'tools/httptools';
import { SessionService } from './session.service';

export type TileNameVariant = 'default' | 'en' | 'fi' | 'nl' | 'sv' | 'pt';

type TileName = Record<TileNameVariant, string>;

export type Tile = {
  id: number;
  name: TileName;
  base_form: boolean;
  pro_form: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class TilesService {
  constructor(
    private httpTools: HttpTools,
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  getUrl() {
    return `${environment.backendURL}/tiles/service-group/${this.sessionService.serviceGroupId}/`;
  }

  getTiles() {
    const headers = this.httpTools.prepareHeaders(HttpMethod.GET);
    return this.http.get<Tile[]>(this.getUrl(), { headers });
  }

  updateTiles(tiles: Tile[]) {
    const headers = this.httpTools.prepareHeaders(HttpMethod.PUT);
    return this.http.put<Tile[]>(this.getUrl(), tiles, { headers });
  }
}
