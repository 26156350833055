<div class="comment_warpper" *ngIf="message !== null">
  <div class="comment-form" *ngIf="isCommentingEnabled && message.state == 1">
    <button
      id="add-comment"
      class="btn action"
      (click)="toggleCommentArea()"
      [ngClass]="{
        visible: !showCommentArea
      }"
      i18n="@@WriteMemo">
      Add a comment
    </button>
    <form
      [formGroup]="commentForm"
      (ngSubmit)="OnSubmit(commentForm.value)"
      [ngClass]="{ visible: showCommentArea, 'new-comment-container': true }">
      <textarea
        class="form-control"
        i18n-placeholder="@@WriteMemo"
        placeholder="Add a comment"
        formControlName="content">
      </textarea>
      <div class="comment-button-wrapper">
        <button
          type="button"
          (click)="toggleCommentArea()"
          i18n="@@kayttajanTiedotProfiiliSulje"
          class="btn cancel">
          Cancel
        </button>
        <button
          type="submit"
          class="btn submit-comment-button action"
          i18n="@@save"
          [disabled]="commentForm.invalid">
          Save
        </button>
      </div>
    </form>
  </div>
  <!-- Comment list -->
  <div class="comment-container" *ngFor="let comment of message['comments']">
    <!-- user and date information -->
    <h5 class="comment-user">
      {{ comment.user_name }}
      - {{ comment.created_at | date: 'medium' }}
      <span *ngIf="comment['updated']" i18n="@@edited" class="comment-edited">
        <i id="edited-comment"
          >(edited {{ comment.updated_at | date: 'medium' }})</i
        >
      </span>
    </h5>
    <!-- Edit button / link -->
    <div
      class="edit-wrapper"
      *ngIf="comment['canEdit']"
      (click)="changeEditComment(comment)">
      <p class="edit-comment" i18n="@@edit">Edit</p>
      <i class="fa fa-pencil-square-o"></i>
    </div>

    <!-- Transfer information -->
    <div
      class="comment-transfer"
      *ngIf="
        comment.service_from !== comment.service_to && service_to !== null
      ">
      <span class="transfer-comment-notification" i18n="@@SiirtoNotifikaatio">
        Redirection
      </span>
      <p>
        {{ comment.service_from }} <i class="fa fa-long-arrow-right"></i>
        {{ comment.service_to }}
      </p>
    </div>

    <!-- Comment -->
    <div class="comment-content" *ngIf="editing_comment !== comment['id']">
      <p>{{ comment.content }}</p>
      <p></p>
    </div>

    <!-- Edit form -->
    <form
      [formGroup]="commentEditForm"
      *ngIf="editing_comment == comment['id']"
      (ngSubmit)="OnEditSubmit(commentEditForm.value)"
      [ngClass]="{ visible: true, 'new-comment-container': true }">
      <textarea class="form-control" formControlName="content"> </textarea>
      <div class="comment-button-wrapper">
        <button
          type="button"
          (click)="changeEditComment(null)"
          i18n="@@kayttajanTiedotProfiiliSulje"
          class="btn cancel">
          Cancel
        </button>
        <button
          type="submit"
          class="btn action"
          i18n="@@save"
          [disabled]="commentEditForm.invalid">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
