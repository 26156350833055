// @ts-nocheck
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DropdownItem } from '../../../components/atoms/simple-dropdown/simple-dropdown.component';
import { ServiceGroupService } from 'services/service-group.service';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  // Placeholder values until we have the mfa settings configured in messages backend
  public authenticationMethods: Array<DropdownItem> = [
    new DropdownItem('SMS verification', 'sms_verification'),
    new DropdownItem('Email verification', 'email_verification'),
    new DropdownItem('YubiKey', 'yubikey'),
    new DropdownItem('Google Authenticator', 'google_authenticator'),
  ];

  public translations = {
    mfaMethod: $localize`:@@authSettingsAuthenticationMethods:`,
    mfaRequired: $localize`:@@authSettingsMFARequired:`,
    mfaEnabled: $localize`:@@authSettingsMFAEnabled:`,
  };

  public formGroup: FormGroup;
  public selectedAuthenticationMethod: String;

  constructor(
    private formBuilder: FormBuilder,
    private serviceGroupService: ServiceGroupService,
    private toasterService: ToasterService
  ) {
    this.formGroup = formBuilder.group({
      mfaRequired: new FormControl(),
      mfaEnabled: new FormControl(),
      mfaMethod: new FormControl(),
    });
  }

  ngOnInit() {
    this.serviceGroupService
      .getActiveServiceGroup()
      .then((serviceGroup: ServiceGroup) => {
        this.updateValue('mfaRequired', serviceGroup.mfa_required);
        this.updateValue('mfaEnabled', serviceGroup.mfa_enabled);
      });
  }

  updateValue(key, value) {
    this.formGroup.controls[key].setValue(value);
  }

  saveChanges() {
    this.serviceGroupService
      .updateServiceGroup({
        mfa_required: this.formGroup.controls['mfaRequired'].value,
        mfa_enabled: this.formGroup.controls['mfaEnabled'].value,
      })
      .then((serviceGroup: ServiceGroup) => {
        this.updateValue('mfaRequired', serviceGroup.mfa_required);
        this.updateValue('mfaEnabled', serviceGroup.mfa_enabled);
        this.toasterService.pop('success', 'Authentication settings updated');
      })
      .catch(() => {
        this.toasterService.pop(
          'danger',
          'Failed to update authentication settings'
        );
      });
  }
}
