// @ts-nocheck
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-side-by-side',
  templateUrl: './side-by-side.component.html',
  styleUrls: ['./side-by-side.component.scss'],
})
export class SideBySideComponent implements OnChanges {
  @Input()
  private chart: Chart;

  public hours: number[] = [];
  public minutes: number[] = [];
  public labels: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chart']) {
      this.labels = [...(<string[]>this.chart.data.labels)];

      for (let i = 0; i < 2; i++) {
        const hours = <number>this.chart.data.datasets[0].data[i] / 60 / 60;
        this.hours[i] = Math.floor(hours);
        this.minutes[i] = Math.round((hours - this.hours[i]) * 60);
      }
    }
  }
}
