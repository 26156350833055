<section class="service-groups">
  <section class="service-groups-inner">
    <div class="content">
      <app-superuser-list></app-superuser-list>
    </div>
  </section>
  <!--<section class="superuser detail">-->
  <!--<app-superuser-detail></app-superuser-detail>-->
  <!--</section>-->
  <section class="service-groups-inner">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </section>
</section>
