// @ts-nocheck
import {
  APP_BASE_HREF,
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Injector, NgModule, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ToasterModule } from 'angular2-toaster';
import { PermissionsModule } from 'app/shared/permissions/permissions.module';
import { SessionGuard } from 'guards/session.guard';
import { PermissionGuard } from 'guards/permission.guard';
import { DashboardService } from 'services/dashboard.service';
import { DatepickerService } from 'services/datepicker.service';
import { FeedbackService } from 'services/feedback.service';
import { HttpWrapperService } from 'services/http-wrapper.service';
import { MessageService } from 'services/message.service';
import { IntegrationService } from 'services/integration.service';
import { PrintService } from 'services/print.service';
import * as Raven from 'raven-js';
import { SessionService } from 'services/session.service';
import { ServiceGroupService } from 'services/service-group.service';
import { DatasetRequestService } from 'services/dataset-request.service';
import { StateService } from 'services/state.service';
import { UserService } from 'services/user.service';
import { UtilsService } from 'services/utils.service';
import { HttpTools } from 'tools/httptools';
import { AppComponent } from './app.component';
import { Configuration } from './app.constants';
import { routes } from './app.routing';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { FeedbackComponent } from './feedback/feedback.component';
import { GdprInstructionsComponent } from './gdpr-instructions/gdpr-instructions.component';
import { HeaderComponent } from './header/header.component';
import { LandingComponent } from './landing/landing.component';
import { MessagesModule } from './messages/messages.module';
import { SettingsModule } from './settings/settings.module';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { SuperuserModule } from './superuser/superuser.module';
import { UsersModule } from './users/users.module';
import { WelcomeModule } from './welcome/welcome.module';
import { UserSatisfactionComponent } from './user-satisfaction/user-satisfaction.component';
import { KIPIntegrationPopupComponent } from './kip-integration-popup/kip-integration-popup.component';
import { KipInstallerComponent } from './kip-instructions/kip-installer.component';
import { KipCardComponent } from './kip-instructions/kip-card/kip-card.component';
import { PublicModule } from './public/public.module';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { sessionInterceptorFactory } from './app.session-interceptor';
import { NoSessionGuard } from 'guards/no-session.guard';

import localeFi from '@angular/common/locales/fi';
import localeSv from '@angular/common/locales/sv';
import localeEn from '@angular/common/locales/en';
import localeNl from '@angular/common/locales/nl';
import localePt from '@angular/common/locales/pt';
import { SleepModalComponent } from './sleep-modal/sleep-modal.component';
import { UtilsModule } from './utils/utils.module';

Raven.config(environment.ravenUrl, {
  environment: environment.name,
  release: environment.version,
}).install();

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  public errorPresent = new BehaviorSubject<boolean>(false);

  handleError(err: any): void {
    this.errorPresent.next(true);
    console.error(err);
    Raven.captureException(err);
  }
}

registerLocaleData(localeFi);
registerLocaleData(localeSv);
registerLocaleData(localeEn);
registerLocaleData(localeNl);
registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FeedbackComponent,
    GdprInstructionsComponent,
    SafeHtmlPipe,
    LandingComponent,
    KipInstallerComponent,
    KipCardComponent,
    KIPIntegrationPopupComponent,
    UserSatisfactionComponent,
    SleepModalComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ToasterModule.forRoot(),
    BrowserAnimationsModule,
    MessagesModule,
    AuthModule,
    UsersModule,
    SuperuserModule,
    WelcomeModule,
    DashboardModule,
    PermissionsModule,
    SettingsModule,
    PublicModule,
    UtilsModule,
  ],
  providers: [
    SessionGuard,
    NoSessionGuard,
    PermissionGuard,
    SessionService,
    MessageService,
    IntegrationService,
    StateService,
    Configuration,
    HttpTools,
    UserService,
    HttpWrapperService,
    FeedbackService,
    UtilsService,
    DashboardService,
    PrintService,
    DatepickerService,
    ServiceGroupService,
    DatasetRequestService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: ErrorHandler,
      useClass: RavenErrorHandler,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: sessionInterceptorFactory,
      multi: true,
      deps: [Injector],
    },
  ],
  exports: [SafeHtmlPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
