// @ts-nocheck
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StateService, StateKeys } from 'services/state.service';
import { SessionService } from 'services/session.service';

@Component({
  selector: 'app-link-service-groups',
  templateUrl: './link-service-groups.component.html',
  styleUrls: ['./link-service-groups.component.scss'],
})
export class LinkServiceGroupsComponent {
  @Output()
  private closePopUpEmitter: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public username: string;

  public user: any;

  constructor(
    protected stateService: StateService,
    private sessionService: SessionService
  ) {}

  ngOnInit() {
    this.user = this.sessionService.getSession();
  }

  closePopUp() {
    this.closePopUpEmitter.emit(false);
  }

  linkUser() {
    this.stateService.setState(StateKeys.linkUser, true);
    this.closePopUp();
  }
}
