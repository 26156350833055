// @ts-nocheck
import {
  customOptionsBarNoAutoskip,
  customOptionsBarPlugin,
  defaultOptionsPie,
} from '../configs/chartJSDefaultConfigs';
import { urgencyColors } from '../configs/colors';

const translations = [
  $localize`:@@sidebar_medical:`,
  $localize`:@@sidebar_contacts-by-urgency:`,
  $localize`:@@sidebar_locations:`,
  $localize`:@@sidebar_symptoms:`,
  $localize`:@@sidebar_conditions:`,
];

export const medical: View = {
  'contacts-by-urgency': {
    id: 'contacts-by-urgency',
    charts: [
      {
        chart_config: {
          id: 'contacts-by-urgency',
          legend: $localize`:@@contactsByUrgency:`,
          type: ChartType.BAR,
          options: customOptionsBarNoAutoskip,
          card_config: {
            show_related_charts: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.TOTAL,
        datasets: ['self_care', 'non_urgent', 'urgent', 'emergency'],
        custom_colors: urgencyColors,
        match_custom_colors_to_dataset_label: true,
        custom_groupings: [
          {
            datasets: ['self_care_female', 'self_care_male'],
            label: 'self_care',
          },
          {
            datasets: ['non_urgent_female', 'non_urgent_male'],
            label: 'non_urgent',
          },
          {
            datasets: ['urgent_female', 'urgent_male'],
            label: 'urgent',
          },
          {
            datasets: ['emergency_female', 'emergency_male'],
            label: 'emergency',
          },
        ],
        translations: {
          labels: {
            priorities: $localize`:@@contactsByUrgency:`,
            self_care: $localize`:@@self_care:`,
            non_urgent: $localize`:@@non_urgent:`,
            urgent: $localize`:@@urgent:`,
            emergency: $localize`:@@emergency:`,
          },
        },
      },
      {
        chart_config: {
          id: 'self-care',
          legend: $localize`:@@contactsByUrgencySelfCare:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
          card_config: {
            show_related_charts: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.TOTAL,
        datasets: ['self_care_female', 'self_care_male'],
        translations: {
          labels: {
            priorities: $localize`:@@contactsByUrgency:`,
            self_care_female: $localize`:@@self_care_female:`,
            self_care_male: $localize`:@@self_care_male:`,
          },
        },
      },
      {
        chart_config: {
          id: 'non-urgent',
          legend: $localize`:@@contactsByUrgencyNonUrgent:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
          card_config: {
            show_related_charts: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.TOTAL,
        datasets: ['non_urgent_female', 'non_urgent_male'],
        translations: {
          labels: {
            priorities: $localize`:@@contactsByUrgency:`,
            non_urgent_female: $localize`:@@non_urgent_female:`,
            non_urgent_male: $localize`:@@non_urgent_male:`,
          },
        },
      },
      {
        chart_config: {
          id: 'urgent',
          legend: $localize`:@@contactsByUrgencyUrgent:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
          card_config: {
            show_related_charts: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.TOTAL,
        datasets: ['urgent_female', 'urgent_male'],
        translations: {
          labels: {
            priorities: $localize`:@@contactsByUrgency:`,
            urgent_female: $localize`:@@urgent_female:`,
            urgent_male: $localize`:@@urgent_male:`,
          },
        },
      },
      {
        chart_config: {
          id: 'emergency',
          legend: $localize`:@@contactsByUrgencyEmergency:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
          card_config: {
            show_related_charts: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.TOTAL,
        datasets: ['emergency_female', 'emergency_male'],
        translations: {
          labels: {
            priorities: $localize`:@@contactsByUrgency:`,
            emergency_female: $localize`:@@emergency_female:`,
            emergency_male: $localize`:@@emergency_male:`,
          },
        },
      },
    ],
  },
  locations: {
    id: 'locations',
    charts: [
      {
        chart_config: {
          id: 'locations',
          legend: $localize`:@@Locations:`,
          type: ChartType.BAR,
          options: customOptionsBarNoAutoskip,
          top_items: {
            max_items: 30,
            show_numbers: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.NONE,
        datasets: ['body_parts'],
        translations: {
          labels: {
            locations: $localize`:@@Locations:`,
          },
        },
      },
    ],
  },
  symptoms: {
    id: 'symptoms',
    charts: [
      {
        chart_config: {
          id: 'symptoms',
          legend: $localize`:@@Symptoms:`,
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
          top_items: {
            max_items: 30,
            show_numbers: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.NONE,
        datasets: ['symptoms'],
        translations: {
          labels: {
            symptoms: $localize`:@@Symptoms:`,
          },
        },
      },
    ],
  },
  conditions: {
    id: 'conditions',
    charts: [
      {
        chart_config: {
          id: 'conditions',
          legend: $localize`:@@Conditions:`,
          type: ChartType.BAR,
          options: customOptionsBarPlugin,
          top_items: {
            max_items: 30,
            show_numbers: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.NONE,
        datasets: ['diagnoses'],
        translations: {
          labels: {
            diagnoses: $localize`:@@Conditions:`,
          },
        },
      },
    ],
  },
};
