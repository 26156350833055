// @ts-nocheck
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SessionService } from 'services/session.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilsService } from 'services/utils.service';
import { ErrorTypes } from 'app/utils/http-response.enums';
import { getErrorType } from 'app/utils/errorUtils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mfa-verification',
  templateUrl: './mfa-verification.component.html',
  styleUrls: ['./mfa-verification.component.scss'],
})
export class MFAVerificationComponent implements OnInit, OnDestroy {
  @Input()
  public sessionId: string;

  @Input()
  public maskedUsername: string;

  @Input()
  public backButtonText: string;

  @Output()
  private backToPreviousPageEmitter: EventEmitter<void> = new EventEmitter();

  @Output()
  private onVerified: EventEmitter<object> = new EventEmitter();

  public retryCodeSent: boolean = false;
  public errorType: ErrorTypes = null;
  public validationSubscription: Subscription = null;
  public validatingTranslation = $localize`:@@validatingMFACode:`;

  public mfaCodeItMayTakeFewMinutes = $localize`:@@mfaCodeItMayTakeFewMinutes:`;
  public mfaCodeTypeVerificationCodeAgain = $localize`:@@mfaCodeTypeVerificationCodeAgain:`;

  public codeLength: number = 6;

  errorTypes = ErrorTypes;

  codeForm = new FormGroup({
    code: new FormControl('', {
      validators: [
        Validators.required,
        Validators.maxLength(this.codeLength),
        Validators.minLength(this.codeLength),
      ],
    }),
  });

  constructor(
    private sessionService: SessionService,
    private utilsService: UtilsService,
    protected router: Router
  ) {}

  ngOnInit() {
    this.requestVerificationCode();
  }

  requestVerificationCode() {
    this.stopValidation(true);
    this.codeForm.controls['code'].setValue(null);

    this.sessionService
      .requestMFAVerficationCode(
        this.sessionId,
        this.utilsService.getCurrentLocale()
      )
      .catch((error) => {
        this.errorType = getErrorType(error.status);
      });
  }

  backToLogin(): void {
    this.backToPreviousPageEmitter.emit();
  }

  checkCode(): Promise<void> {
    if (this.validationSubscription) return;

    this.errorType = null;
    this.validationSubscription = this.sessionService
      .verifyMFAVerficationCode(
        this.codeForm.controls['code'].value,
        this.sessionId
      )
      .subscribe(
        async (response) => {
          // Refresh user details after succesful MFA
          const { id, sessionid, pro_submit_token } = response;
          const language = this.utilsService.getCurrentLocale();
          const userResult = await this.sessionService.getUserData(
            id,
            sessionid,
            language
          );

          // Yes, this is extremely clumsy... :/
          userResult['pro_submit_token'] = pro_submit_token;
          this.onVerified.emit(userResult);
        },
        (error) => (this.errorType = getErrorType(error.status))
      );

    this.validationSubscription.add(() => this.stopValidation());
  }

  /**
   * Unsubscribes from validation request, can cancel ongoing request.
   * Clears loading spinner if clearValidationAnimation = true is provided as a parameter.
   */
  stopValidation(clearValidationAnimation: boolean = false): void {
    if (this.validationSubscription) {
      this.validationSubscription.unsubscribe();

      if (clearValidationAnimation) {
        this.validationSubscription = null;
      }
    }
  }

  ngOnDestroy(): void {
    this.stopValidation(true);
  }
}
