// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { IntegrationService } from 'services/integration.service';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';
import { UtilsService } from 'services/utils.service';
import { Configuration } from '../app.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public instructionsUrl =
    'https://storage.googleapis.com/klinik-shared-media/instructions.pdf';
  public integrationEnabled = false;
  public emisIntegrationAvailable = false;
  public newKIPVersionAvailable = false;
  public kipInstallerDownloadUrl = '';
  public session: object;
  public service_group: object;
  public customSettings: object;
  public userPermissions: Set<string> = new Set();
  public navigationSidebar = true;

  public readonly permissions = Permissions;

  private stateChangeSubscription: Subscription;

  constructor(
    protected sessionService: SessionService,
    protected stateService: StateService,
    private router: Router,
    private utilsService: UtilsService,
    private config: Configuration,
    private integrationService: IntegrationService
  ) {}

  async ngOnInit() {
    this.session = this.sessionService.getSession();

    if (this.session) {
      this.service_group = this.session['service_group'];
      this.setUserCustomSettings();
      this.setUserPermissions();
    }

    this.instructionsUrl = this.utilsService.getInstructionsUrl();

    // Check integration status also when refreshing the page while logged in
    if (this.session) {
      await this.checkIntegration();
    }

    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.refreshUserData:
            this.session = state['value'];
            this.service_group = this.session['service_group'];
            this.setUserCustomSettings();
            this.setUserPermissions();
            this.checkIntegration();
            break;

          case StateKeys.deleteSession:
            this.session = null;
            this.userPermissions = new Set();
            break;

          case StateKeys.loadedServiceGroup:
            this.service_group = state['value'];
            break;

          case StateKeys.emisIntegrationAvailable:
            this.emisIntegrationAvailable = state['value'];
            break;

          case StateKeys.newKIPVersionAvailable:
            this.newKIPVersionAvailable = state['value'];
            if (this.newKIPVersionAvailable) {
              this.kipInstallerDownloadUrl =
                this.integrationService.kipInstallerDownloadUrl;
            }
            break;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.stateChangeSubscription) {
      this.stateChangeSubscription.unsubscribe();
    }
  }

  async checkIntegration() {
    if (!this.session) {
      return;
    }

    this.integrationEnabled = this.sessionService.isEmisIntegrationEnabled;
    if (this.integrationEnabled) {
      await this.integrationService.checkIntegrationAvailable();
    }
  }

  requestNavigation(request: string) {
    this.stateService.setState(StateKeys.requestedNavigation, {
      request: request,
    });
  }

  naviSection(section) {
    // workaround for apparent activeroute bug
    return this.router.routerState.snapshot.url.split('/')[1] === section;
  }

  setUserPermissions(): void {
    this.userPermissions = this.utilsService.filterUserPemsissions([
      this.permissions.MESSAGE_VIEW,
      this.permissions.DASHBOARD_VIEW,
      this.permissions.USERS_VIEW,
      this.permissions.PRO_FORM_VIEW,
    ]);
  }

  toggleSidebar(): void {
    this.navigationSidebar = !this.navigationSidebar;
  }

  setUserCustomSettings() {
    if (
      this.session &&
      this.config &&
      this.session['service_group']['slug'] in this.config.customSettings
    ) {
      this.customSettings =
        this.config.customSettings[this.session['service_group']['slug']];
    } else {
      this.customSettings = null;
    }
  }

  openProForm(): void {
    if (this.userPermissions.has(this.permissions.PRO_FORM_VIEW)) {
      this.utilsService.openProForm();
    }
  }
}
