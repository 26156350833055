<section>
  <form
    [formGroup]="userForm"
    class="user-form"
    (ngSubmit)="onSubmit()"
    [class.delete]="delete_user">
    <div class="form-content">
      <div class="inputs">
        <text-input
          [inputId]="'first_name'"
          [label]="inputLabelTranslations.first_name"
          [isRequired]="true"
          [isDisabled]="inputDisabled"
          [isTouched]="userForm.controls['first_name'].touched"
          [errorText]="inputErrorTranslations.first_name"
          [value]="userForm.controls['first_name'].value"
          (updatedEvent)="updateValue('first_name', $event)">
        </text-input>

        <text-input
          [inputId]="'last_name'"
          [label]="inputLabelTranslations.last_name"
          [isRequired]="true"
          [isDisabled]="inputDisabled"
          [isTouched]="userForm.controls['last_name'].touched"
          [errorText]="inputErrorTranslations.last_name"
          [value]="userForm.controls['last_name'].value"
          (updatedEvent)="updateValue('last_name', $event)">
        </text-input>

        <!-- SSN -->
        <div *ngIf="!ssnHidden">
          <text-input
            *ngIf="!ssnHidden"
            [inputId]="'ssn'"
            [label]="inputLabelTranslations.ssn"
            [isDisabled]="inputDisabled"
            [isTouched]="userForm.controls['ssn'].touched"
            [errorText]="inputErrorTranslations.ssn_required"
            [value]="userForm.controls['ssn'].value"
            (updatedEvent)="updateValue('ssn', $event)">
          </text-input>

          <ng-container
            *ngIf="
              !userForm.controls.ssn.valid &&
              (userForm.controls.ssn.touched || validationFromServer)
            ">
            <p
              class="error"
              *ngIf="userForm.controls.ssn.hasError('invalid_ssn')"
              i18n="@@kayttajanTiedotVirheVaaraHenkilotunnus">
              Annettu henkilötunnus ei ole validi.
            </p>
          </ng-container>
        </div>

        <!-- create a separate username component (?) -->
        <div>
          <text-input
            [inputId]="'username'"
            [label]="inputLabelTranslations.username"
            [isRequired]="true"
            [isDisabled]="inputDisabled"
            [isTouched]="userForm.controls['username'].touched"
            [errorText]="inputErrorTranslations.username"
            [value]="userForm.controls['username'].value"
            (updatedEvent)="updateValue('username', $event)">
          </text-input>

          <div
            *ngIf="modalType === 0 && inputDisabled"
            class="small"
            i18n="@@kayttajanTiedotSahkopostiOhje">
            Sähköpostiosoitetta käytetään käyttäjätunnuksenasi ja siksi sen
            muuttaminen vaatii yhteydenoton organisaatiosi pääkäyttäjään
          </div>

          <ng-container *ngIf="userForm.controls.username.touched">
            <ng-container
              *ngIf="
                userForm.controls.username.errors &&
                (userForm.controls.username.touched || validationFromServer)
              ">
              <p
                class="error"
                *ngIf="userForm.controls.username.hasError('username_taken')"
                i18n="@@kayttajanTiedotVirheSahkopostiVarattu">
                Käyttäjätunnus on varattu!
              </p>
            </ng-container>
            <p
              class="error"
              *ngIf="
                userForm.controls.username.hasError('validateEmail') &&
                !userForm.controls.username.hasError('required')
              "
              i18n="@@kayttajanTiedotVirheSahkopostiVaara">
              Sähköpostiosoite ei ole oikeassa muodossa
            </p>
          </ng-container>
        </div>

        <!-- obsolete? -->
        <text-input
          *ngIf="emisIntegrationEnabled"
          [inputId]="'emis_username'"
          [label]="inputLabelTranslations.emis_username"
          [isRequired]="emisIntegrationEnabled"
          [isDisabled]="inputDisabled"
          [isTouched]="userForm.controls['emis_username'].touched"
          [errorText]="inputErrorTranslations.emis_username"
          [value]="userForm.controls['emis_username'].value"
          (updatedEvent)="updateValue('emis_username', $event)">
        </text-input>

        <div>
          <text-input
            [inputId]="'phone_number'"
            [label]="inputLabelTranslations.phone_number"
            [isDisabled]="inputDisabled"
            [isTouched]="userForm.controls['phone_number'].touched"
            [errorText]="inputErrorTranslations.phone_number_required"
            [value]="userForm.controls['phone_number'].value"
            (updatedEvent)="updateValue('phone_number', $event)">
          </text-input>
          <ng-container *ngIf="userForm.controls.phone_number.touched">
            <p
              class="error"
              *ngIf="
                userForm.controls.phone_number.hasError('validatePhoneNumber')
              "
              i18n="@@kayttajanTiedotVirhePuhelinnumeroVaara">
              Puhelinnumero ei ole validi.
            </p>
          </ng-container>
          <ng-container
            *ngIf="
              userForm.controls.phone_number.errors &&
              (userForm.controls.phone_number.touched || validationFromServer)
            ">
            <p
              class="error"
              *ngIf="
                userForm.controls.phone_number.hasError('invalid_phone_number')
              "
              i18n="@@kayttajanTiedotVirhePuhelinnumeroVaara2">
              Puhelinnumero ei ole validi.
            </p>
          </ng-container>
        </div>

        <app-simple-dropdown
          [items]="jobRoles"
          [preSetValue]="userForm.controls['job_role'].value"
          [dropDownName]="'job_role'"
          [isRequired]="true"
          [isDisabled]="inputDisabled"
          [isTouched]="userForm.controls['job_role'].touched"
          [errorText]="inputErrorTranslations.job_role"
          [label]="inputLabelTranslations.job_role"
          (onChangeEmitter)="updateValue('job_role', $event)">
        </app-simple-dropdown>
      </div>

      <div class="inputs">
        <!-- SERVICES -->
        <div
          *ngIf="services.length > 0"
          class="users-form-group form-group-services">
          <label>
            <span i18n="@@kayttajanTiedotYksikot2">Yksiköt</span>
            <span class="error-asterisk">*</span>
          </label>
          <div class="input-entry" *ngFor="let service of services">
            <label class="input-label">
              <input
                type="checkbox"
                [checked]="
                  userForm.controls['services_ids'].value.indexOf(
                    service.id
                  ) !== -1
                "
                (change)="checkboxTicked('services_ids', service.id)"
                [disabled]="!userCanEdit"
                *ngIf="modalType !== 0" />
              <span>{{ service.name }} </span>
            </label>
          </div>
          <ng-container
            *ngIf="
              !userInAny('services_ids', 'services') &&
              userForm.controls.services_ids.touched
            ">
            <p class="error" i18n="@@ValitseKäyttäjälleVähintäänYksiYksikkö">
              Valitse käyttäjälle vähintään yksi yksikkö
            </p>
          </ng-container>
          <button
            class="btn btn-select-all"
            type="button"
            i18n="@@Selectallunits"
            (click)="toggleAllServicesSelected()"
            *ngIf="modalType !== 0 && userCanEdit">
            Valitse kaikki yksiköt
          </button>
        </div>

        <!-- USER GROUPS -->
        <div
          class="users-form-group form-group-user-groups"
          *hasPermissions="[permissions.USERS_VIEW_USER_GROUPS]">
          <label i18n="@@kayttajanTiedotRooli">Käyttäjän rooli</label>

          <div class="input-entry" *ngFor="let group of groups">
            <label class="input-label">
              <input
                type="checkbox"
                id="{{ group.name }}"
                [checked]="
                  userForm.controls['groups_ids'].value.indexOf(group.id) !== -1
                "
                (change)="checkboxTicked('groups_ids', group.id)"
                [disabled]="!userCanEdit"
                *ngIf="modalType !== 0" />
              <span>{{ roleTranslations[group.name].name }}</span>
              <i
                class="fa fa-info-circle"
                (mouseover)="displayDescription($event, group.name)"
                (mouseout)="showDescription = false"></i>
            </label>
          </div>

          <ng-container
            *ngIf="
              !userInAny('groups_ids', 'groups') &&
              userForm.controls['groups_ids'].touched
            ">
            <p class="error" i18n="@@noRolesSelectedError">
              Please select a role for the user
            </p>
          </ng-container>

          <div *ngIf="loggedInUserIsStaff && modalType === 1">
            <!-- Add staff user status -->
            <button
              *ngIf="!selectedUserIsStaff"
              [disabled]="isStaffButtonDisabled"
              class="btn btn-warning make-staff-btn"
              (click)="onMakeStaffClicked($event)"
              i18n="@@addStaffUserStatusButton">
              Add staff user status
            </button>

            <!-- Remove staff user status button -->
            <button
              *ngIf="selectedUserIsStaff"
              [disabled]="isStaffButtonDisabled"
              class="btn btn-info make-staff-btn"
              (click)="onremoveStaffUserStatusClicked($event)"
              i18n="@@removeStaffUserStatusButton">
              Remove staff user status
            </button>
          </div>
        </div>

        <!-- STATUS -->
        <div
          class="users-form-group form-group-user-status"
          *ngIf="modalType !== 0">
          <label i18n="@@kayttajanTiedotTila">Tila</label>
          <div class="input-entry">
            <label class="input-label">
              <input
                type="radio"
                name="user_status"
                [checked]="true"
                [value]="userstatus.ACTIVE"
                formControlName="user_status" />
              <span i18n="@@kayttajanTiedotTilaAktiivinen">Aktiivinen</span>
              <i
                class="fa fa-info-circle"
                (mouseover)="displayDescription($event, 'ACTIVE')"
                (mouseout)="showDescription = false"></i>
            </label>
          </div>
          <div class="input-entry">
            <label class="input-label">
              <input
                type="radio"
                name="user_status"
                [checked]="
                  selectedDetailedUser['user_status'] === userstatus.DISABLED
                "
                [value]="userstatus.DISABLED"
                [attr.disabled]="isDisabledInputDisabled"
                formControlName="user_status" />
              <span i18n="@@kayttajanTiedotTilaEiAktiivinen"
                >Ei aktiivinen</span
              >
              <i
                class="fa fa-info-circle"
                (mouseover)="displayDescription($event, 'DISABLED')"
                (mouseout)="showDescription = false"></i>
            </label>
          </div>
          <div class="input-entry">
            <label class="input-label">
              <input
                type="radio"
                name="user_status"
                [checked]="
                  selectedDetailedUser['user_status'] === userstatus.EXPIRED
                "
                disabled
                [value]="userstatus.EXPIRED"
                formControlName="user_status" />
              <span i18n="@@UserStatusExpired">Expired</span>
              <i
                class="fa fa-info-circle"
                (mouseover)="displayDescription($event, 'EXPIRED')"
                (mouseout)="showDescription = false"></i>
            </label>
          </div>
        </div>
      </div>

      <div id="description" *ngIf="showDescription">
        <h3></h3>
        <p></p>
      </div>
    </div>

    <div class="actions">
      <div *hasPermissions="[permissions.USERS_DELETE]">
        <ng-container *ngIf="modalType === 1 && viewingOwnDetails === false">
          <app-toggle-switch
            [label]="inputLabelTranslations.delete_user"
            [value]="delete_user"
            (updatedEvent)="delete_user = !delete_user">
          </app-toggle-switch>
        </ng-container>
      </div>

      <div class="button-group">
        <button
          class="btn btn-delete"
          type="button"
          i18n="@@kayttajanTiedotTallenna"
          *ngIf="delete_user"
          (click)="deleteUser()">
          Poista käyttäjä
        </button>

        <button
          class="btn btn-submit"
          *ngIf="userCanEdit && !inputDisabled && !delete_user"
          [disabled]="userForm.invalid"
          type="submit">
          <span *ngIf="modalType !== 2" i18n="@@kayttajanTiedotTallenna2"
            >Tallenna</span
          >
          <span *ngIf="modalType === 2" i18n="@@kayttajanTiedotLuo"
            >Luo käyttäjä</span
          >
        </button>
      </div>
    </div>
  </form>
</section>
