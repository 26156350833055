<div class="dropdown-container">
  <div class="icon-container">
    <i class="fa fa-globe"></i>
    <span i18n="@@Language">Language</span>
  </div>

  <select
    class="language"
    id="language"
    #language
    name="language"
    (change)="changeLanguage(language.value)"
    placeholder="Syötä asiointitunnus">
    <option
      *ngFor="let item of languages"
      [value]="item.value"
      [selected]="item.value === selectedLanguage">
      {{ item.name }}
    </option>
  </select>
</div>
