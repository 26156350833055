<div class="modal-backdrop" (click)="closeModal()"></div>

<section id="connect-with-prs">
  <h1 i18n="@@connectEnquiryToRecordModalTitle">Connect enquiry to record</h1>

  <section class="wrapper">
    <div class="loading-container" *ngIf="findingPatientsFromPrs">
      <i class="fa fa-spinner spin"></i>
    </div>

    <div
      class="matches"
      id="emis-matches"
      *ngIf="
        source_prs === 'emis' && !findingPatientsFromPrs && matches.length > 0
      ">
      <div
        *ngFor="let item of matches; let index = index"
        class="match-container">
        <div class="content">
          <h4>{{ item.FirstNames }} {{ item.FamilyName }}</h4>
          <p class="patient-data">
            {{ item.Address.Street }}, {{ item.Address.Town }}
          </p>
          <p *ngIf="item.DateOfBirth" class="patient-data">
            <span class="patient-data-title" i18n="@@dateOfBirth"
              >Date Of Birth</span
            >: {{ item.DateOfBirth }}
          </p>
          <p *ngIf="item.NhsNumber" class="patient-data">
            <span class="patient-data-title" i18n="@@nhsNumber">Nhs Number</span
            >: {{ item.NhsNumber }}
          </p>
        </div>
        <button
          [ngClass]="{
            success: indexOfSelected === index,
            unselected: indexOfFailed !== index && indexOfSelected !== index,
            fail: indexOfFailed === index
          }"
          [disabled]="connectingPatientToRecord !== null"
          (click)="
            connectingPatientToRecord = index;
            connectToRecord(item.RefID, item.NhsNumber)
          ">
          <ng-container
            i18n="@@connectEnquiryToRecordModalConnect"
            *ngIf="
              indexOfFailed !== index &&
              indexOfSelected !== index &&
              connectingPatientToRecord !== index
            ">
            Connect to record
          </ng-container>
          <ng-container
            i18n="@@connectEnquiryToRecordModalConnecting"
            *ngIf="connectingPatientToRecord === index">
            Connecting to record
          </ng-container>
          <ng-container
            i18n="@@connectEnquiryToRecordModalConnected"
            *ngIf="indexOfSelected === index">
            Connected to record
          </ng-container>
          <ng-container
            i18n="@@connectEnquiryToRecordModalConnectFailed"
            *ngIf="indexOfFailed === index">
            Connecting to record failed
          </ng-container>
          <i
            class="fa fa-spinner spin"
            *ngIf="connectingPatientToRecord === index"></i>
        </button>
      </div>
    </div>

    <div
      class="no-matches"
      *ngIf="
        !findingPatientsFromPrs && (matches.length === 0 || matches === null)
      ">
      <h4>No records found.</h4>
    </div>
  </section>

  <button class="close-btn" (click)="closeModal()" i18n="@@close">Close</button>
</section>
