// @ts-nocheck
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input()
  public title: string = null;

  @Input()
  public content: string = null;
}
