// @ts-nocheck
import { forwardRef, Inject, Injectable } from '@angular/core';
import { SessionService } from 'services/session.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

export type CallBackOptions = {
  onError?: (error: any) => void;
  onSuccess?: (response: any) => void;
};

/**
 * HTTP methods.
 */
export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

/**
 * Provides common tools for HTTP requests.
 */
@Injectable()
export class HttpTools {
  constructor(
    @Inject(forwardRef(() => SessionService))
    public sessionService: SessionService
  ) {}

  /**
   * Prepares the default headers for an HTTP request.
   * @param method
   */
  public prepareHeaders(method: HttpMethod = null) {
    let headers = new HttpHeaders();
    headers = this.addAuthenticationHeaders(headers);

    if (environment.production) {
      headers = this.addCachePreventionHeaders(headers);
    }

    if (
      method === HttpMethod.POST ||
      method === HttpMethod.PUT ||
      method === HttpMethod.PATCH
    ) {
      headers = this.addContentTypeJsonHeader(headers);
    }

    return headers;
  }

  /**
   * Adds the necessary authentication headers.
   */
  public addAuthenticationHeaders(headers: HttpHeaders): HttpHeaders {
    const session = this.sessionService.getSession();
    if (session) {
      headers = headers.set('X-sessionid', this.sessionService.getSessionId());
    }

    return headers;
  }

  /**
   * Adds the necessary headers to prevent caching of the response.
   */
  public addCachePreventionHeaders(headers: HttpHeaders): HttpHeaders {
    return headers
      .set('Cache-Control', 'no-cache, must-revalidate')
      .set('Pragma', 'no-cache')
      .set('If-Modified-Since', 'Mon, 26 Jul 1997 05:00:00 GMT');
  }

  /**
   * Adds the necessary headers to indicate that the request body is JSON.
   * @param headers
   */
  public addContentTypeJsonHeader(headers: HttpHeaders): HttpHeaders {
    return headers.set('Content-Type', 'application/json');
  }

  /**
   * Wraps an observable with callbacks.
   *
   * Used mostly for HTTP requests. (Handy e.g. for toaster notifications after a request succeeds or fails.)
   * @param observable The observable to wrap.
   * @param callBacks The callbacks to execute.
   * @returns The original observable wrapped with callbacks.
   */
  public wrapWithCallBacks<T>(
    observable: Observable<T>,
    callBacks: CallBackOptions = {}
  ): Observable<T> {
    return observable.pipe(
      catchError((error) => {
        callBacks.onError && callBacks.onError(error);
        return throwError(error);
      }),
      map((data) => {
        callBacks.onSuccess && callBacks.onSuccess(data);
        return data;
      })
    );
  }
}
