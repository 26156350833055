// @ts-nocheck
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpWrapperService } from './http-wrapper.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PrintService {
  constructor(
    private http: HttpClient,
    private httpWrapper: HttpWrapperService
  ) {}

  /**
   * Downloads a PDF file of a patient case from Messages backend and opens it in a new tab.
   * @param data - The data for the PDF. This is a very propriatery format. :/ Your best bet is to look at how this method is called.
   */
  async downloadPdf(data) {
    let headers = this.httpWrapper.prepareHeaders('post');
    headers.set('Accept', 'application/pdf');

    const response = await this.http
      .post(`${environment.backendURL}/print`, JSON.stringify(data), {
        headers: headers,
        responseType: 'blob',
      })
      .toPromise();

    const blob = new Blob([response], { type: 'application/pdf' });

    window.open(URL.createObjectURL(blob));
  }
}
