// @ts-nocheck
import { Component, Input, OnInit } from '@angular/core';

export enum RequestStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

@Component({
  selector: 'app-request-status',
  templateUrl: './request-status.component.html',
  styleUrls: ['./request-status.component.scss'],
})
export class RequestStatusComponent implements OnInit {
  @Input() title?: string;
  @Input() icon?: string;
  @Input() signature?: string;
  @Input() status?: RequestStatus = RequestStatus.SUCCESS;

  constructor() {}

  ngOnInit(): void {}
}
