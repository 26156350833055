// @ts-nocheck
import { Injectable } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { environment } from 'environments/environment';
import { getNestedValue } from '../methods/getNestedValue';
import { SessionService } from './session.service';
import { ServiceGroupService } from './service-group.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Injectable()
export class UtilsService {
  private languageChoices = new Set();
  public currentLocale: string;
  public foundLocale: string;
  private _subBucket: Subscription[] = [];

  private default_date_format = {
    date: 'D.M.YYYY',
    time: 'H:mm',
    date_and_time: 'D.M.YYYY H:mm',
  };

  private date_formats = {
    FI: this.default_date_format,
    SV: this.default_date_format,
    EN: {
      date: 'YYYY-M-D',
      time: 'H:mm a',
      date_and_time: 'YYYY-M-D H:mm A',
    },
  };

  public instructionsUrls = {
    en: 'https://help.klinikhealthcaresolutions.com/knowledge',
    fi: 'https://storage.googleapis.com/klinik-shared-media/K%C3%A4ytt%C3%B6ohje.pdf',
    nl: 'https://huisartsenvannederland.nl/wp-content/uploads/2022/12/Helppagina-Document-Klinik-Platform-versie-1.1.pdf',
  };

  constructor(
    private sessionService: SessionService,
    private serviceGroupService: ServiceGroupService,
    private toaster: ToasterService,
    private sanitizer: DomSanitizer
  ) {
    environment.availableLanguages.forEach((language) =>
      this.languageChoices.add(language)
    );
    this.currentLocale = this.getCurrentLocale();
  }

  ngOnDestroy() {
    this._subBucket.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Helper function for retrieving nested properties without blowing angular silently to pieces
   * @param object
   * @param propertyName
   * @param fallbackValue
   * @returns {any}
   */
  public getProperty(
    object: object,
    propertyName: string,
    fallbackValue = null
  ): any {
    try {
      const parts = propertyName.split('.');
      const length = parts.length;
      let property = object || this;

      for (let i = 0; i < length; i++) {
        property = property[parts[i]];
      }

      if (property !== undefined && property !== null) {
        return property;
      } else {
        return fallbackValue;
      }
    } catch (e) {
      return fallbackValue;
    }
  }

  /**
   * Get locale string or return the default locale string
   * @returns {string}
   */
  public getCurrentLocale(): string {
    try {
      const currentUrl = window.location.href;
      const matches = currentUrl.match('\\/((\\w+)(-\\w+)?)\\/#\\/');

      if (matches != null) {
        this.foundLocale = matches[1];
      } else {
        const cookie_regex = document.cookie.match(
          `(^|;) ?cookie-language=([^;]*)(;|$)`
        );
        this.foundLocale = cookie_regex != null ? cookie_regex[2] : null;
      }
      if (
        this.foundLocale != null &&
        this.languageChoices.has(this.foundLocale)
      ) {
        return this.foundLocale;
      }

      if (this.sessionService.serviceGroup.country_code != null) {
        return this.sessionService.serviceGroup.country_code;
      }
    } catch (e) {
      return environment.defaultLocale;
    }
  }

  public getInstructionsUrl(): string {
    const key = this.currentLocale.toLowerCase();
    const url = getNestedValue(this.instructionsUrls, key);
    return url ? url : this.instructionsUrls['en'];
  }

  /**
   * Get translation for name or display translation missing error message
   * @returns {string}
   */
  public getNameTranslation(object?: object): string {
    const translated = this.getProperty(
      object ?? {},
      `translations.${this.currentLocale}.name`
    );

    if (translated !== null) {
      return translated;
    } else {
      return environment.translationMissingMessage[this.currentLocale];
    }
  }

  getContactTypeTranslation(contact_type: {}) {
    if (this.ObjectHasKey(contact_type, `short_name_${this.currentLocale}`)) {
      return contact_type[`short_name_${this.currentLocale}`];
    } else if (this.ObjectHasKey(contact_type, `name_${this.currentLocale}`)) {
      return contact_type[`name_${this.currentLocale}`];
    } else if (this.ObjectHasKey(contact_type, 'name')) {
      return contact_type['name'];
    } else {
      return environment.translationMissingMessage[this.currentLocale];
    }
  }

  /**
   * Get translation for name or display translation missing error message
   * @returns {string}
   */
  public getFieldTranslation(object: any, fieldName: string): string {
    const translated = this.getProperty(
      object,
      `translations.${this.currentLocale}.${fieldName}`
    );

    if (translated !== null) {
      return translated;
    } else {
      return environment.translationMissingMessage[this.currentLocale];
    }
  }

  getDateFormats() {
    const locale = this.sessionService.serviceGroup?.country_code;

    if (this.date_formats[locale]) {
      return this.date_formats[locale];
    }
    return this.default_date_format;
  }

  ObjectHasKey(object: object, keys: string): boolean {
    if (!object) {
      return false;
    }

    const keysArray = keys.split('.');

    for (let i = 0; i < keysArray.length; i++) {
      if (keysArray[i] in object === false) {
        return false;
      }

      if (!object[keysArray[i]]) {
        return false;
      }

      if (object[keysArray[i]].length === 0) {
        return false;
      }

      object = object[keysArray[i]];
    }

    return true;
  }

  labelTranslation(label: object) {
    if (!label) {
      return null;
    }

    if (this.ObjectHasKey(label, 'xlabel.no_translations')) {
      return label['xlabel']['no_translations'];
    } else {
      return this.getNameTranslation(label['xlabel']);
    }
  }

  serviceGroupConfiguration(user: object, key: string): any {
    if (this.ObjectHasKey(user, `service_group.configuration.${key}`)) {
      return user['service_group']['configuration'][key];
    }

    return false;
  }

  userExtraData(key: string): any {
    const session = this.sessionService.getSession();

    if (this.ObjectHasKey(session, `extra_data.${key}`)) {
      return session['extra_data'][key];
    }

    return false;
  }

  getCircularReplacer(): any {
    const seen = new Set();
    return (key: string, value: any) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  filterUserPemsissions(permissions: string[]): Set<string> {
    const userPermissions: Set<string> = new Set();
    permissions.map((permission) => {
      if (this.sessionService.checkPermissions(permission)) {
        userPermissions.add(permission);
      }
    });

    return userPermissions;
  }

  /**
   * Opens the patient form for the active service group in a new window.
   */
  async openPatientForm(): Promise<void> {
    try {
      const sg: ServiceGroup =
        await this.serviceGroupService.getActiveServiceGroup();
      const url = `${environment.formUrl}/contact/${sg.slug}/`;
      window.open(url, '_blank');
    } catch (error) {
      this.toaster.pop('error', $localize`:@@errorPleaseTryAgain:`);
    }
  }

  /**
   * Opens the Connect / Pro Form for the active service group in a new window.
   */
  async openProForm(): Promise<void> {
    // Check that connect is enabled for service group before forwarding
    try {
      const sg = await this.serviceGroupService.getActiveServiceGroup();
      if (!sg || !sg.pro_form_active) {
        return;
      }
      const form_url =
        sg.connect_url !== null ? sg.connect_url : environment.formUrl;
      window.open(`${form_url}/contact/${sg['slug']}/pro`, '_blank');
    } catch (error) {
      this.toaster.pop('error', $localize`:@@errorPleaseTryAgain:`);
    }
  }

  getPatientNameFromMessage(message, defaultEmptyValue = null) {
    const firstName: string = getNestedValue(
      message,
      'data',
      'data_v2',
      'personal_info',
      'firstname'
    );
    const lastName: string = getNestedValue(
      message,
      'data',
      'data_v2',
      'personal_info',
      'lastname'
    );
    if (firstName || lastName) {
      return `${firstName} ${lastName}`;
    }

    const fullName: string = getNestedValue(
      message,
      'data',
      'data_v2',
      'personal_info',
      'full_name'
    );
    if (fullName) {
      return fullName;
    }

    return defaultEmptyValue;
  }

  /**
   * Initiates a download in the users browser for a given file.
   * @param data - The file data in a blob.
   * @param dataType - The data type of the file.
   * @param fileName - The default file name.
   */
  downloadFile = (
    data: Blob,
    dataType: string,
    fileName: string = 'download_from_klinik'
  ) => {
    const blob = new Blob([data], { type: dataType });
    const bloblUrl = window.URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.href = bloblUrl;
    anchor.download = fileName;
    anchor.style.display = 'none';

    document.body.appendChild(anchor);
    anchor.click();

    document.body.removeChild(anchor);
    URL.revokeObjectURL(bloblUrl);
  };

  createImgDataURL(file: string): string {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'data:Image/*;base64,' + file
    ) as string;
  }
}

/**
 * Returns the first part of an UUID, ie. the first 8 characters.
 * @param uuid - An UUID, for example "4bf7081f-6758-4414-92c7-a4ccaf7319b1"
 */
export function shortUuid(uuid: string) {
  if (!uuid) {
    return '';
  }

  return uuid.split('-')[0];
}
