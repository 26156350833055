// @ts-nocheck
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { SessionService } from 'services/session.service';
import { getNestedValue } from '../methods/getNestedValue';

@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(private sessionService: SessionService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Allow staff user to navigate to any url
    const session = this.sessionService.getSession();
    if (getNestedValue(session, 'is_staff') === true) {
      return true;
    }

    // Do not allow access to route if user is missing permission(s)
    const permissions: Permissions[] = getNestedValue(
      route,
      'data',
      'permissions'
    );

    return this.sessionService.checkPermissions(permissions);
  }
}
