import { Injectable } from '@angular/core';
import { EnquiryParser, ParsedItem } from '../methods/enquiry/enquiryParser';
import { SessionService } from './session.service';
import {
  AppointmentTypeFieldName,
  AppointmentTypesService,
} from './appointment-types.service';
import { Enquiry } from '../methods/enquiry/enquiry';

@Injectable({
  providedIn: 'root',
})
export class CopyContentService {
  public enquiryParser!: EnquiryParser;
  constructor(
    protected sessionService: SessionService,
    private appointmentTypesService: AppointmentTypesService
  ) {}

  public async generateCopyContent(
    enquiryParser: EnquiryParser,
    reducedCopy: boolean
  ): Promise<string> {
    if (reducedCopy) {
      return await this.generateReducedCopyContent(enquiryParser);
    } else {
      return this.generateFullCopyContent(enquiryParser);
    }
  }
  async getAppointmentTypeNameTranslated(enquiry: Enquiry): Promise<string> {
    if (!enquiry) {
      return '';
    }

    const translatedName = await this.appointmentTypesService
      .getTranslatedNameById(
        enquiry.appointment_type,
        AppointmentTypeFieldName.NAME,
        enquiry.data.appointment_request.appointment_duration_type
      )
      .toPromise();
    return translatedName;
  }

  async formatAppointmentTypeAndSubtype(enquiry: Enquiry): Promise<string> {
    const appointmentName = await this.getAppointmentTypeNameTranslated(
      enquiry
    );
    const appointmentSubtypeName =
      this.appointmentTypesService.getTranslatedSubtype(enquiry) || '';

    let result = '';
    if (appointmentName && appointmentSubtypeName) {
      result = appointmentName + ', ' + appointmentSubtypeName + '\n';
    } else if (!appointmentName && appointmentSubtypeName) {
      result = appointmentSubtypeName + '\n';
    } else if (appointmentName && !appointmentSubtypeName) {
      result = appointmentName + '\n';
    }
    return result;
  }
  async generateReducedCopyContent(
    enquiryParser: EnquiryParser
  ): Promise<string> {
    // Gather items to be added to the copy data
    const basicItems = new Array<ParsedItem>();

    basicItems.push(enquiryParser.nhsNumberItem);

    // Gather different sets of data that might or might not be present
    const enquiryDataSets: Array<Array<ParsedItem>> = [
      basicItems,
      enquiryParser.symptomDetailItems,
      enquiryParser.symptomItems,
      enquiryParser.severitySymptomsItem
        ? [enquiryParser.severitySymptomsItem]
        : [],

      enquiryParser.previousTreatmentItems,
      enquiryParser.medicalQuestionItems,
      enquiryParser.freeTextItem ? [enquiryParser.freeTextItem] : [],
    ];
    let copyData = '';
    copyData += await this.formatAppointmentTypeAndSubtype(
      enquiryParser.enquiry
    );
    for (const dataSet of enquiryDataSets) {
      // If no data, skip it.
      if (!dataSet) {
        continue;
      }
      // Loop through each item and append to copyData
      for (const item of dataSet) {
        if (item.key && item.value !== null) {
          copyData += item.toCopyItem(true);
        }
      }
    }
    return copyData;
  }
  generateFullCopyContent(enquiryParser: EnquiryParser): string {
    // Gather items to be added to the copy data
    const basicItems = new Array<ParsedItem>();

    basicItems.push(enquiryParser.nhsNumberItem);

    basicItems.push(
      enquiryParser.dateOfBirthItem,
      enquiryParser.caseIdentifierItem,
      enquiryParser.formSubmittedAtItem
    );
    // Gather different sets of data that might or might not be present
    const enquiryDataSets: Array<Array<ParsedItem>> = [
      basicItems,
      enquiryParser.dynamicFieldItems,
      enquiryParser.symptomDetailItems,
      enquiryParser.symptomItems,
      enquiryParser.severitySymptomsItem
        ? [enquiryParser.severitySymptomsItem]
        : [],
      enquiryParser.notSelectedSeveritySymptomsItem
        ? [enquiryParser.notSelectedSeveritySymptomsItem]
        : [],
      enquiryParser.previousTreatmentItems,
      enquiryParser.medicalQuestionItems,
      enquiryParser.freeTextItem ? [enquiryParser.freeTextItem] : [],
    ];
    let copyData = '';
    for (const dataSet of enquiryDataSets) {
      // If no data, skip it.
      if (!dataSet) {
        continue;
      }
      // Loop through each item and append to copyData
      for (const item of dataSet) {
        if (item.key && item.value !== null) {
          copyData += item.toCopyItem(false);
        }
      }
    }
    // Finally append "copied from..." to end of copied content
    copyData += `${$localize`:@@copiedFromKlinikPro:Copied from Klinik Access`}`;
    return copyData;
  }
}
