// @ts-nocheck
/**
 * Some useful functions related to units/services.
 * They are here for lack of a better place, and because they are used in multiple places.
 */

/**
 * Adds "(inactive)" to the name of an inactive service.
 * @param service - A service.
 * @returns The name of the service, appended with "(inactive)" if the service is inactive.
 */
export const addInactiveToServiceName = (service: Service) => {
  if (service.active === false) {
    const inactive = $localize`:@@inactive:inactive`;
    return `${service.name} (${inactive})`;
  }

  return service.name;
};
