// @ts-nocheck
import {
  defaultOptionsPie,
  optionsGroupedBar,
} from '../configs/chartJSDefaultConfigs';
import { feedbackColors } from '../configs/colors';

const translations = [
  $localize`:@@sidebar_feedback:`,
  $localize`:@@sidebar_professional_feedback:`,
];

export const feedback: View = {
  sidebar_feedback: {
    id: 'patient_feedback',
    charts: [
      {
        chart_config: {
          id: 'sidebar_patient_feedback',
          legend: $localize`:@@sidebar_patient_feedback:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.XLABEL,
        datasets: ['customer_satisfaction'],
        custom_colors: feedbackColors,
        match_custom_colors_to_dataset_label: true,
        translations: {
          labels: {
            5: $localize`:@@FeedbackAmazing:`,
            4: $localize`:@@FeedbackGood:`,
            3: $localize`:@@FeedbackOk:`,
            2: $localize`:@@FeedbackBad:`,
            1: $localize`:@@FeedbackTerrible:`,
          },
        },
      },
      {
        chart_config: {
          id: 'feedback-by-age',
          legend: $localize`:@@FeedbackByAge:`,
          type: ChartType.BAR,
          options: optionsGroupedBar,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.NONE,
        datasets: [
          'customer_satisfaction_0_9',
          'customer_satisfaction_10_19',
          'customer_satisfaction_20_29',
          'customer_satisfaction_30_39',
          'customer_satisfaction_40_49',
          'customer_satisfaction_50_59',
          'customer_satisfaction_60_69',
          'customer_satisfaction_70_79',
          'customer_satisfaction_80_89',
          'customer_satisfaction_90',
        ],
        custom_colors: feedbackColors,
        match_custom_colors_to_dataset_label: true,
        translations: {
          labels: {
            customer_satisfaction_0_9: $localize`:@@customer_satisfaction_0_9:`,
            customer_satisfaction_10_19: $localize`:@@customer_satisfaction_10_19:`,
            customer_satisfaction_20_29: $localize`:@@customer_satisfaction_20_29:`,
            customer_satisfaction_30_39: $localize`:@@customer_satisfaction_30_39:`,
            customer_satisfaction_40_49: $localize`:@@customer_satisfaction_40_49:`,
            customer_satisfaction_50_59: $localize`:@@customer_satisfaction_50_59:`,
            customer_satisfaction_60_69: $localize`:@@customer_satisfaction_60_69:`,
            customer_satisfaction_70_79: $localize`:@@customer_satisfaction_70_79:`,
            customer_satisfaction_80_89: $localize`:@@customer_satisfaction_80_89:`,
            customer_satisfaction_90: $localize`:@@customer_satisfaction_90:`,
            5: $localize`:@@FeedbackAmazing:`,
            4: $localize`:@@FeedbackGood:`,
            3: $localize`:@@FeedbackOk:`,
            2: $localize`:@@FeedbackBad:`,
            1: $localize`:@@FeedbackTerrible:`,
          },
        },
      },
      {
        chart_config: {
          id: 'feedback-by-gender',
          legend: $localize`:@@FeedbackByGender:`,
          type: ChartType.BAR,
          options: optionsGroupedBar,
          card_config: {
            show_related_charts: true,
          },
        },
        group_by: GroupBy.NONE,
        datasets: [
          'customer_satisfaction_female',
          'customer_satisfaction_male',
        ],
        custom_colors: feedbackColors,
        match_custom_colors_to_dataset_label: true,
        translations: {
          labels: {
            customer_satisfaction_female: $localize`:@@allContactsFemale:`,
            customer_satisfaction_male: $localize`:@@allContactsMale:`,
            5: $localize`:@@FeedbackAmazing:`,
            4: $localize`:@@FeedbackGood:`,
            3: $localize`:@@FeedbackOk:`,
            2: $localize`:@@FeedbackBad:`,
            1: $localize`:@@FeedbackTerrible:`,
          },
        },
      },
    ],
  },
  professional_feedback: {
    id: 'professional_feedback',
    charts: [
      {
        chart_config: {
          id: 'sidebar_professional_feedback',
          legend: $localize`:@@sidebar_professional_feedback:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
        },
        group_by: GroupBy.XLABEL,
        datasets: ['professional_satisfaction'],
        custom_colors: feedbackColors,
        match_custom_colors_to_dataset_label: true,
        translations: {
          labels: {
            5: $localize`:@@FeedbackAmazing:`,
            4: $localize`:@@FeedbackGood:`,
            3: $localize`:@@FeedbackOk:`,
            2: $localize`:@@FeedbackBad:`,
            1: $localize`:@@FeedbackTerrible:`,
          },
        },
      },
    ],
  },
};
