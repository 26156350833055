<section
  id="login-view"
  class="auth-container"
  *ngIf="showMFAVerificationPage === false">
  <h1 i18n="@@loginPageTitle">Please log in</h1>

  <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
    <div [ngClass]="{ 'has-error': f.submitted && !username.valid }">
      <input
        id="username-login-input"
        type="text"
        class="form-control"
        name="username"
        [(ngModel)]="model.username"
        #username="ngModel"
        required
        placeholder="Sähköpostiosoite"
        i18n-placeholder="@@loginPageEmailPlaceholder" />
      <div *ngIf="f.submitted && !username.valid" class="error">
        <i class="fa fa-exclamation-circle"></i>
        <ng-container i18n="@@loginPageErrorEmailMissing"
          >Fill in email</ng-container
        >
      </div>
    </div>

    <div [ngClass]="{ 'has-error': f.submitted && !password.valid }">
      <input
        id="password-login-input"
        type="password"
        class="form-control"
        name="password"
        [(ngModel)]="model.password"
        #password="ngModel"
        required
        placeholder="Salasana"
        i18n-placeholder="@@loginPagePasswordPlaceholder" />
      <div *ngIf="f.submitted && !password.valid" class="error">
        <i class="fa fa-exclamation-circle"></i>
        <ng-container i18n="@@loginPageErrorPasswordMissing"
          >Fill in password</ng-container
        >
      </div>
      <div *ngIf="errorMessage" class="error">
        <div class="error-holder">
          <i class="fa fa-exclamation-circle login-error"></i>
        </div>

        <span id="login-error-messages" *ngIf="!!errorMessage">{{
          errorMessage
        }}</span>
      </div>
    </div>

    <button
      mat-flat-button
      color="primary"
      id="log-in-button"
      i18n="@@loginPageLoginButton">
      Log in
    </button>
    <a
      id="forgot-your-password"
      routerLink="/auth/resetpassword"
      i18n="@@loginPageForgotPassword">
      Forgot your password?
    </a>
  </form>
</section>

<mfa-verification
  *ngIf="showMFAVerificationPage === true"
  [maskedUsername]="maskedUsername"
  [backButtonText]="translations.backToLogin"
  [sessionId]="sessionId"
  (backToPreviousPageEmitter)="showMFAVerificationPage = false"
  (onVerified)="sessionService.createSession($event)"></mfa-verification>
