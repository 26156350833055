<ng-container>
  <div
    class="notification-bar"
    [ngClass]="{
      success: type === 'success',
      error: type === 'error',
      info: type === 'info',
      warning: type === 'warning'
    }">
    <p>
      <i class="fa fa-{{ icon }}" *ngIf="icon"></i> <ng-content></ng-content>
    </p>
  </div>
</ng-container>
