// @ts-nocheck
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { PermissionsModule } from 'app/shared/permissions/permissions.module';
import { PromptComponent } from 'app/utils/prompt/prompt.component';
import { WelcomeModule } from 'app/welcome/welcome.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ClipboardModule } from 'ngx-clipboard';
import { ActionsComponent } from './actions/actions.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { CommentComponent } from './comment/comment.component';
import { CustomerComponent } from './customer/customer.component';
import { DescriptionComponent } from './description/description.component';
import { DetailComponent } from './detail.component';
import { ProgressComponent } from './progress/progress.component';
import { StatusComponent } from './status/status.component';
import { UtilsModule } from 'app/utils/utils.module';
import { ProfessionalEnquiryAssessmentModalComponent } from './professional-enquiry-assessment-modal/professional-enquiry-assessment-modal.component';
import { PatientIdentifierDisplayComponent } from './patient-identifier-display/patient-identifier-display.component';
import { PatientDetailsNotificationSlotComponent } from './patient-details-notification-slot/patient-details-notification-slot.component';
import { CaretakerInfoDisplayComponent } from './caretaker-info-display/caretaker-info-display.component';
import { AppMaterialModule } from 'app/app-material/app-material.module';

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ClipboardModule,
    MomentModule,
    WelcomeModule,
    FormsModule,
    ReactiveFormsModule,
    PermissionsModule,
    UtilsModule,
    AppMaterialModule,
  ],
  exports: [DetailComponent],
  declarations: [
    DetailComponent,
    ActionsComponent,
    CustomerComponent,
    DescriptionComponent,
    StatusComponent,
    AppointmentComponent,
    AnalysisComponent,
    ChangelogComponent,
    ProgressComponent,
    CommentComponent,
    PromptComponent,
    AttachmentsComponent,
    ProfessionalEnquiryAssessmentModalComponent,
    PatientIdentifierDisplayComponent,
    PatientDetailsNotificationSlotComponent,
    CaretakerInfoDisplayComponent,
  ],
})
export class DetailModule {}
