<app-delete-confirmation-popup
  *ngIf="deletingAllSMSTemplates"
  [disabled]="false"
  [translations]="'smsTemplateDeleteAll'"
  (deleteConfirmationEmitter)="deleteAllSMSTemplates($event)">
</app-delete-confirmation-popup>

<section id="sms-messaging">
  <h1 i18n="@@smsMessaging">SMS messaging</h1>

  <div *ngIf="userIsStaff" class="staff-settings">
    <p><b>All of these settings are visible only to staff members.</b></p>

    <p>Service group: {{ serviceGroup['name'] }}</p>

    <!-- SMS Settings -->

    <app-toggle-switch
      [label]="'smsEnabled'"
      [value]="serviceGroup.sms_enabled"
      (updatedEvent)="updateLocalValue('sms_enabled', $event)">
    </app-toggle-switch>

    <text-input
      [label]="'smsPhoneNumber'"
      [value]="getLocalValue('sms_phone_number')"
      (updatedEvent)="updateLocalValue('sms_phone_number', $event)">
    </text-input>

    <text-input
      [label]="'smsRejectionMessage'"
      [value]="serviceGroup.sms_rejection_message"
      (updatedEvent)="updateLocalValue('sms_rejection_message', $event)">
    </text-input>

    <button class="btn btn-submit" (click)="saveSMSSettings()">Save</button>

    <hr />

    <app-simple-dropdown
      [items]="smsTemplateMarkets"
      [dropDownName]="'smsTemplateMarkets'"
      [label]="translations.smsTemplateMarkets"
      (onChangeEmitter)="updateSMSTemplateMarket($event)">
    </app-simple-dropdown>

    <button
      class="btn btn-submit"
      [disabled]="!selectedSMSTemplateMarket"
      (click)="addDefaultSMSTemplates()"
      i18n="@@addDefaultSMSTemplates">
      Add default SMS templates
    </button>

    <button
      class="btn btn-warning"
      (click)="deletingAllSMSTemplates = true"
      i18n="@@deleteAllSMSTemplates">
      Delete all SMS templates
    </button>
  </div>

  <app-sms-templates></app-sms-templates>
</section>
