// @ts-nocheck
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StateService } from 'services/state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gdpr-instructions',
  templateUrl: './gdpr-instructions.component.html',
  styleUrls: ['./gdpr-instructions.component.scss'],
})
export class GdprInstructionsComponent implements OnInit {
  private stateChangeSubscription: Subscription;

  constructor(private stateService: StateService) {}

  ngOnInit() {
    const self = this;
  }
}
