// @ts-nocheck
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss'],
})
export class DailyComponent implements OnChanges {
  @Input()
  public dailyData: any = null;

  @Input()
  public activeChart: Chart;

  @Input()
  public activeView: string;

  @Input()
  public sidebarOpen = true;

  @Output()
  private linkedChartEmitter: EventEmitter<LinkedChart> = new EventEmitter();

  public typeTableAndChart = CardTypes.TABLE_AND_CHART;
  public typeSideBySide = CardTypes.SIDE_BY_SIDE;
  public typeTopThree = CardTypes.TOP_THREE;
  public typeChart = CardTypes.CHART;

  constructor(private utilsService: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.utilsService.ObjectHasKey(changes, 'sidebarOpen')) {
      setTimeout(() => {
        this.activeChart = { ...this.activeChart };
        this.dailyData = { ...this.dailyData };
      }, 300);
    }
  }

  openLinkedChart(linkedChart: LinkedChart): void {
    this.linkedChartEmitter.emit(<LinkedChart>linkedChart);
  }
}
