<div class="integration-modal">
  <mat-tab-group mat-stretch-tabs [(selectedIndex)]="currentTab">
    <mat-tab label="Preview">
      <div class="view-headings">
        <h2 i18n="@@saveToPatientRecord">Save to patient record</h2>
      </div>
      <div class="view-contents">
        <!-- Selection toggles -->
        <div>
          <h3>
            <span i18n="@@checkItemsToInclude">Check items to be included</span
            >:
          </h3>
          <mat-slide-toggle
            [color]="'primary'"
            [(ngModel)]="includePersonalInfo"
            i18n="@@personalInformation"
            [title]="personalInfoToggleTitle"
            >Personal Information</mat-slide-toggle
          >
          <mat-slide-toggle
            [color]="'primary'"
            [(ngModel)]="includeCaseSummary"
            i18n="@@caseSummary"
            [title]="caseSummaryToggleTitle"
            >Case Summary</mat-slide-toggle
          >
          <mat-slide-toggle
            [color]="'primary'"
            [(ngModel)]="includeComments"
            i18n="@@comments"
            [title]="commentsToggleTitle"
            [disabled]="!hasComments"
            >Comments</mat-slide-toggle
          >
          <mat-slide-toggle
            [color]="'primary'"
            [(ngModel)]="includeSMS"
            i18n="@@smsMessages"
            [title]="smsToggleTitle"
            [disabled]="isSmsToggleDisabled"
            >SMS Messages</mat-slide-toggle
          >
          <mat-slide-toggle
            [color]="'primary'"
            [(ngModel)]="includeImages"
            i18n="@@attachments"
            [title]="imagesToggleTitle"
            [disabled]="isImagesToggleDisabled"
            >Images</mat-slide-toggle
          >

          <app-notification
            *ngIf="hasAnyTransactions"
            [type]="statusNotificationType | async"
            [icon]="statusNotificationIcon | async"
            >{{ statusnotificationText | async }}</app-notification
          >
        </div>
        <!-- Preview -->
        <div>
          <mat-accordion>
            <!-- Personal information preview -->
            <mat-expansion-panel [disabled]="!includePersonalInfo">
              <mat-expansion-panel-header
                [collapsedHeight]="'80px'"
                [expandedHeight]="'80px'"
                ><mat-panel-title
                  >Personal information</mat-panel-title
                ></mat-expansion-panel-header
              >
              <p *ngFor="let item of personalInformationItems">
                {{ item.key }}: <span>{{ item.value }}</span>
              </p>
            </mat-expansion-panel>
            <!-- Case summary preview  -->
            <mat-expansion-panel [disabled]="!includeCaseSummary">
              <mat-expansion-panel-header
                [collapsedHeight]="'80px'"
                [expandedHeight]="'80px'"
                ><mat-panel-title
                  >Case summary</mat-panel-title
                ></mat-expansion-panel-header
              >
              <p *ngFor="let item of caseSummaryItems">
                {{ item.key }}: <span>{{ item.value }}</span>
              </p>
            </mat-expansion-panel>
            <!-- Comments preview  -->
            <mat-expansion-panel [disabled]="!includeComments">
              <mat-expansion-panel-header
                [collapsedHeight]="'80px'"
                [expandedHeight]="'80px'"
                ><mat-panel-title
                  >Comments</mat-panel-title
                ></mat-expansion-panel-header
              >
              <p *ngFor="let comment of commentsItems">
                {{ comment.key }}:
                <span>{{ comment.value }}</span>
              </p>
            </mat-expansion-panel>
            <!-- SMS message preview  -->
            <mat-expansion-panel [disabled]="!includeSMS">
              <mat-expansion-panel-header
                [collapsedHeight]="'80px'"
                [expandedHeight]="'80px'"
                ><mat-panel-title
                  >SMS messages</mat-panel-title
                ></mat-expansion-panel-header
              >
              <p *ngFor="let followup of followupMessages">
                {{ getSMSActorName(followup) }}:
                <span>{{ followup.body }}</span>
                <i> ({{ followup.created_at | date: 'medium' }})</i>
              </p>
            </mat-expansion-panel>
            <!-- Image attachment preview  -->
            <mat-expansion-panel [disabled]="!includeImages">
              <mat-expansion-panel-header
                [collapsedHeight]="'80px'"
                [expandedHeight]="'80px'"
                ><mat-panel-title>
                  <span i18n="@@attachments"
                    >Case attachments</span
                  ></mat-panel-title
                ></mat-expansion-panel-header
              >

              <div *ngIf="images$ | loading | async as images">
                <!-- Loading -->
                <ng-template [ngIf]="images.loading">
                  <app-loading></app-loading>
                </ng-template>

                <!-- Success-->
                <ng-template [ngIf]="images.data">
                  <img [src]="img.url" *ngFor="let img of images.data" />
                </ng-template>

                <!-- Error -->
                <ng-template [ngIf]="images.error">
                  <app-notification
                    [icon]="'exclamation-triangle'"
                    [type]="'error'">
                    <span i18n="@@attachmentLoadFailed">
                      Failed to load attachments.
                    </span>
                  </app-notification>
                </ng-template>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Logs">
      <div class="view-headings">
        <h2>Previous patient record actions</h2>
      </div>
      <div class="view-contents">
        <app-integration-transaction-log
          [transactions$]="transactions$"></app-integration-transaction-log>
      </div>
    </mat-tab>
  </mat-tab-group>

  <mat-card-footer>
    <p>
      {{ patientSignature }}
    </p>
    <mat-divider></mat-divider>
    <mat-card-actions>
      <button mat-stroked-button (click)="closeModal()" i18n="@@closeWindow">
        Close window
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="saveCaseToEhr()"
        [disabled]="isSendButtonDisabled"
        [title]="sendButtonTitle"
        i18n="@@saveToPatientRecord">
        Save to patient record
      </button>
    </mat-card-actions>
  </mat-card-footer>
</div>

<!-- Response status indicators for the send request -->
<app-klinik-overlay *ngIf="createResponse$ !== null">
  <ng-container *ngIf="createResponse$ | loading | async as response">
    <!-- Loading -->
    <ng-template [ngIf]="response.loading">
      <div class="center"><mat-spinner></mat-spinner></div>
    </ng-template>
    <!-- Success -->
    <ng-template [ngIf]="response.data">
      <div class="bottom">
        <app-request-status
          [status]="'success'"
          [title]="successHeading"
          [icon]="'check_circle'"
          [signature]="patientSignature">
          <p i18n="@@ehrSuccessDescription">
            Case data has been successfully saved to the patient record system.
            You can see previous actions from the logs.
          </p>
          <mat-card-actions>
            <button
              mat-flat-button
              color="primary"
              (click)="closeModal()"
              i18n="@@closeWindow"
              style="text-transform: uppercase">
              Close window
            </button>
          </mat-card-actions>
        </app-request-status>
      </div>
    </ng-template>
    <!-- Error -->
    <ng-template [ngIf]="response.error">
      <div class="bottom">
        <app-request-status
          [status]="'failure'"
          [title]="failureHeading"
          [icon]="'warning'"
          [signature]="patientSignature">
          <p>
            {{ getErrorDescription(response.error) }}
          </p>
          <mat-card-actions>
            <button
              mat-flat-button
              (click)="acknowledgeStatus()"
              i18n="@@tryAgain"
              style="text-transform: uppercase">
              Try again
            </button>

            <button
              mat-flat-button
              color="warn"
              (click)="closeModal()"
              i18n="@@closeWindowAndCopyManually"
              style="text-transform: uppercase">
              Close window and copy manually
            </button>
          </mat-card-actions>
        </app-request-status>
      </div>
    </ng-template>
  </ng-container>
</app-klinik-overlay>
