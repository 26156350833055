// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { SessionService } from 'services/session.service';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public instructionsUrl = '';
  public userPermissions: Set<string> = new Set();
  public session = {};
  public activatedSurvey = true;
  public kipOnboarding = false;
  public enableInfotext = false;

  public readonly permissions = Permissions;

  constructor(
    private utilsService: UtilsService,
    public sessionService: SessionService
  ) {}

  ngOnInit() {
    this.session = this.sessionService.getSession();
    this.instructionsUrl = this.utilsService.getInstructionsUrl();
    this.userPermissions = this.utilsService.filterUserPemsissions([
      this.permissions.MESSAGE_VIEW,
      this.permissions.DASHBOARD_VIEW,
      this.permissions.USERS_VIEW,
      this.permissions.PRO_FORM_VIEW,
    ]);

    this.kipOnboarding =
      this.sessionService.isEmisIntegrationEnabled &&
      this.session['kip_onboarded'] === null;
    this.toggleInfotext();
  }

  toggleSurvey(): void {
    this.activatedSurvey = !this.activatedSurvey;
  }

  toggleInfotext() {
    if (this.sessionService.isUKProduction) this.enableInfotext = true;
  }

  openProForm(): void {
    if (this.userPermissions.has(this.permissions.PRO_FORM_VIEW)) {
      this.utilsService.openProForm();
    }
  }

  toggleKIPOnboarding(event: boolean): void {
    this.kipOnboarding = event;
  }
}
