// @ts-nocheck
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilsModule } from 'app/utils/utils.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PermissionsModule } from 'permissions/permissions.module';
import { DetailComponent } from './detail.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule,
    UtilsModule,
    PermissionsModule,
  ],
  exports: [DetailComponent],
  declarations: [DetailComponent],
})
export class DetailModule {}
