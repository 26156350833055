import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { EmailValidator } from 'validators';
import { SessionService } from 'services/session.service';
import { StateService } from 'services/state.service';
import { UtilsService } from 'services/utils.service';
import { getNestedValue } from 'app/shared/methods/getNestedValue';
import { ErrorTypes } from 'app/utils/http-response.enums';

@Component({
  selector: 'app-auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public form: FormGroup;
  public username: AbstractControl;
  public password: AbstractControl;
  public submitted = false;
  public loggedIn = false;
  public errorMessage: string | null = null;
  public model: any = {};
  public returnUrl: string | null;

  public maskedUsername: string | undefined = undefined;
  public sessionId: string | undefined = undefined;

  public showMFAVerificationPage: boolean = false;

  public translations = {
    backToLogin: $localize`:@@mfaCodeBackToLogin:`,
  };

  constructor(
    fb: FormBuilder,
    protected sessionService: SessionService,
    protected stateService: StateService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.form = fb.group({
      username: [
        '',
        Validators.compose([Validators.required, EmailValidator.validate]),
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
    });

    this.username = this.form.controls['username'];
    this.password = this.form.controls['password'];

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {
    const language = this.utilsService.getCurrentLocale();
    this.sessionService
      .login(this.model.username, this.model.password, language)
      .then((response) => {
        this.errorMessage = null;
        const mfa_required = getNestedValue(response, 'mfa_required');

        if (!mfa_required) {
          this.sessionService.createSession(response);
        } else {
          this.sessionId = getNestedValue(response, 'sessionid');
          this.maskedUsername = getNestedValue(response, 'username');
          this.showMFAVerificationPage = true;
        }
      })
      .catch((error) => {
        if (error?.status >= ErrorTypes.internalServerError) {
          this.errorMessage = $localize`:@@loginPageErrorSystemFailure:`;
          return;
        }

        if (error?.status === ErrorTypes.tooManyRequests) {
          this.errorMessage = $localize`:@@errorTooManyRequests:`;
          return;
        }

        switch (error?.error?.code) {
          case 'user_expired':
            this.errorMessage = $localize`:@@loginPageErrorUserExpired:`;
            break;
          case 'authentication_failed':
            this.errorMessage = $localize`:@@loginFailed:`;
            break;
          default:
            // Unknown error code
            this.errorMessage = $localize`:@@loginPageErrorSystemFailure:`;
            break;
        }
      });
  }
}
