<section class="detail-attachment" *ngIf="isRequestAttachmentsEnabled">
  <h2>
    <b><i class="fa fa-paperclip fa-flip-vertical fa-lg"></i></b>
    <span i18n="@@attachments">Case attachments</span>
  </h2>

  <ng-container [ngSwitch]="attachmentState">
    <ng-container *ngSwitchCase="'LOADING'">
      <div class="loading-container">
        <i class="fa fa-spinner spin"></i>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'FAILED'">
      <span class="load-failed"
        ><i class="fa fa-times"></i>&nbsp;<span i18n="@@attachmentLoadFailed"
          >Failed to load attachments</span
        ></span
      >
      <button i18n="@@attachmentLoadRetry" (click)="loadAttachments()">
        Retry
      </button>
    </ng-container>

    <div *ngSwitchDefault>
      <ng-container *ngIf="hasPicturePermission && hidden_pictures > 0">
        <span class="preview"
          ><i class="fa fa-eye-slash"></i> {{ hidden_pictures }}
          <span i18n="@@attachmentsRemoved">attachment(s) removed</span></span
        >
      </ng-container>

      <div
        class="image-row preview"
        *ngIf="pictures && pictures.length > 0; else no_pictures">
        <ng-container
          *ngIf="hasPicturePermission && processed_pictures.length > 0">
          <div
            class="image-preview-column"
            *ngFor="let image of processed_pictures; let i = index">
            <img (click)="openModal(template, i)" [src]="image.file" />
          </div>
        </ng-container>

        <ng-container *ngIf="!hasPicturePermission">
          <span
            title="Insufficient permissions"
            class="image-preview-column not-allowed"
            *ngFor="let blankImage of pictures; let i = index">
            <i class="fa fa-file-image-o fa-lg"></i>
          </span>
        </ng-container>
      </div>

      <ng-template #no_pictures>
        <span class="no-images" i18n="@@noAttachments"
          >This case has no attachments...</span
        >
      </ng-template>
    </div>
  </ng-container>
</section>

<ng-template #template>
  <div class="modal-header image-row">
    <div
      class="image-column"
      *ngFor="let image of processed_pictures; let i = index">
      <img
        class="images"
        [ngClass]="{ active: i === this.slideIndex }"
        (click)="currentSlide(i)"
        [src]="image.file" />
    </div>
    <button type="button" class="close close-gallery" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body slide-modal-content">
    <div
      *ngFor="let image of processed_pictures; let i = index"
      [ngClass]="i === this.slideIndex ? 'active-img' : 'hidden-img'">
      <img [src]="image.file" alt="" />
      <div
        class="remove-image"
        (click)="openConfirmationModal(confirmation, image)">
        <span class="fa-stack fa-lg alert-icon">
          <i class="fa fa-circle fa-stack-2x"></i>
          <i class="fa fa-exclamation fa-stack-1x fa-inverse"></i>
        </span>
        <span i18n="@@removeAttachment">Remove this image</span>
      </div>
      <button
        class="downloadBtn btn"
        (click)="downloadImage(image, i)"
        i18n="@@download">
        Download
      </button>
    </div>

    <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
    <a class="next" (click)="plusSlides(1)">&#10095;</a>
  </div>
</ng-template>

<ng-template #confirmation>
  <div class="modal-header confirmation-header">
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef2.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body confirmation-body">
    <p i18n="@@confirmAttachmentRemoval">
      This action is permanent, are you sure you want to remove this image?
    </p>
    <div class="modal-buttons btn-group">
      <button
        type="button"
        class="btn btn-link"
        (click)="modalRef2.hide()"
        i18n="@@cancelButton">
        Cancel
      </button>
      <button
        class="btn btn-submit btn-single"
        type="button"
        (click)="hideImage()"
        i18n="@@confirmButton">
        Confirm
      </button>
    </div>
  </div>
</ng-template>
