// @ts-nocheck
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'services/session.service';
import { EmailValidator, EqualPasswordsValidator } from 'validators';

@Component({
  selector: 'app-auth-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  private passwords: FormGroup;
  private resetToken: string;
  private email: string;

  public submitted = false;
  public tokenInvalid = false;
  public showHint = false;
  public errors: string | string[] = null;
  public state: string = null;
  public form: FormGroup;
  public username: AbstractControl;
  public password: AbstractControl;
  public repeatPassword: AbstractControl;

  constructor(
    fb: FormBuilder,
    protected sessionService: SessionService,
    private router: Router,
    private selectedRoute: ActivatedRoute
  ) {
    this.state = 'form-unsent';

    this.form = fb.group({
      username: [
        '',
        Validators.compose([Validators.required, EmailValidator.validate]),
      ],
      passwords: fb.group(
        {
          password: ['', Validators.compose([Validators.required])],
          repeatPassword: ['', Validators.compose([Validators.required])],
        },
        {
          validator: EqualPasswordsValidator.validate(
            'password',
            'repeatPassword'
          ),
        }
      ),
    });

    this.username = this.form.controls['username'];
    this.passwords = <FormGroup>this.form.controls['passwords'];
    this.password = this.passwords.controls['password'];
    this.repeatPassword = this.passwords.controls['repeatPassword'];
  }

  public setNewPassword(form: object): void {
    this.submitted = true;

    if (this.form.valid) {
      this.sessionService
        .setPassword(
          this.form.controls['username'].value,
          this.resetToken,
          this.form.controls['passwords'].get('password').value
        )
        .then((success) => {
          this.state = 'form-success';
        })
        .catch((response) => {
          const error = response.error;
          this.state = 'form-error';
          const message = JSON.parse(error.message);
          this.errors = message.constructor === Array ? message : [message];
        });
    } else {
      this.state = 'form-error';
    }
  }

  ngOnInit() {
    this.selectedRoute.queryParams.subscribe((data) => {
      this.email = data['email'];
      this.resetToken = data['token'];
    });

    this.username.setValue(this.email, { onlySelf: true });

    if (this.resetToken == null) {
      this.router.navigate(['login']);
    } else {
      this.sessionService
        .validateToken(this.email, this.resetToken)
        .then((success: HttpResponse<object>) => {
          this.tokenInvalid = success.status > 400;
        })
        .catch((error: HttpResponse<object>) => {
          this.tokenInvalid = true;
          const body = error.body;
          const message = JSON.parse(body['message']);
          this.errors = message.constructor === Array ? message : [message];
        });
    }
  }
}
