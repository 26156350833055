// @ts-nocheck
import { defaultOptionsPie } from '../configs/chartJSDefaultConfigs';

const translations = [
  $localize`:@@sidebar_technical:`,
  $localize`:@@sidebar_devices-and-operating-systems:`,
];

export const technical: View = {
  'devices-and-operating-systems': {
    id: 'devices-and-operating-systems',
    charts: [
      {
        chart_config: {
          id: 'devices',
          legend: $localize`:@@contactsByDevice:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
          card_config: {
            show_related_charts: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.TOTAL,
        datasets: ['desktop', 'tablet', 'mobile'],
        translations: {
          labels: {
            devices: $localize`:@@devices:`,
            desktop: $localize`:@@desktop:`,
            mobile: $localize`:@@mobile:`,
            tablet: $localize`:@@tablet:`,
          },
        },
      },
      {
        chart_config: {
          id: 'desktop',
          legend: $localize`:@@contactsByDeviceDesktop:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
          card_config: {
            show_related_charts: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.XLABEL,
        datasets: ['desktop'],
      },
      {
        chart_config: {
          id: 'tablet',
          legend: $localize`:@@contactsByDeviceTablet:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
          card_config: {
            show_related_charts: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.XLABEL,
        datasets: ['tablet'],
      },
      {
        chart_config: {
          id: 'mobile',
          legend: $localize`:@@contactsByDeviceMobile:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
          card_config: {
            show_related_charts: true,
          },
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.XLABEL,
        datasets: ['mobile'],
      },
    ],
  },
};
