<main>
  <header>
    <h1 i18n="@@dataRequests">Data requests</h1>
  </header>
  <section class="admin-section" *ngIf="userIsStaff">
    <p class="warning">
      THE DATA SETS CONTAIN HIGHLY SENSITIVE PATIENT INFORMATION AND ARE MEANT
      TO SERVE OUR LEAVING CUSTOMERS ONLY!
    </p>

    <app-toggle-switch
      [label]="'Enable data set requests for ' + serviceGroup.name + ''"
      [value]="isDataSetRequestsEnabled"
      [askConfirm]="true"
      (updatedEvent)="toggleDataSetRequestsEnabled($event)">
    </app-toggle-switch>
  </section>

  <div class="testing" *ngIf="isDataSetRequestsEnabled"></div>

  <ng-container *ngIf="isDataSetRequestsEnabled">
    <section class="data-counts">
      <section>
        <h2 i18n="@@dataAvailable">Data available</h2>
        <p>{{ dataCountsDescription }}</p>
      </section>
      <table class="table data-counts-table">
        <tr>
          <th i18n="@@data">Data</th>
          <th i18n="@@count">Count</th>
        </tr>
        <ng-container *ngIf="dataCountsStatus === 'LOADING'">
          <tr>
            <app-loading></app-loading>
          </tr>
        </ng-container>

        <ng-container *ngIf="dataCountsStatus === 'SUCCESS'">
          <tr>
            <td i18n="@@auditEvents">Audit events</td>
            <td>{{ dataCounts.audit_events }}</td>
          </tr>
          <tr>
            <td i18n="@@enquiries">Enquiries</td>
            <td>{{ dataCounts.enquiries }}</td>
          </tr>
          <tr>
            <td i18n="@@sms">SMS</td>
            <td>{{ dataCounts.followups }}</td>
          </tr>
          <tr>
            <td i18n="@@users">Users</td>
            <td>{{ dataCounts.users }}</td>
          </tr>
        </ng-container>

        <ng-container *ngIf="dataCountsStatus === 'FAILED'">
          <tr>
            <app-error-notification
              id="failedToLoadDataCountsError"
              [text]="
                translations.failedToLoadDataCounts
              "></app-error-notification>
          </tr>
        </ng-container>
      </table>
    </section>
    <section class="data-set-requests">
      <section>
        <h2 i18n="@@requests">Requests</h2>
        <p>
          {{ dataSetDescription }}
        </p>
      </section>

      <table class="table data-set-requests-table">
        <tr>
          <th i18n="@@requested">Requested</th>
          <th i18n="@@identifier">Identifier</th>
          <th i18n="@@status">Status</th>
          <th><!-- Download --></th>
        </tr>
        <ng-container *ngIf="dataSetRequestsStatus === 'SUCCESS'">
          <tr *ngFor="let item of dataSetRequests" class="data-set-request-row">
            <td>{{ item.created_at | date: 'medium' }}</td>
            <td>{{ shortUuid(item.uuid) }}</td>
            <td>{{ getTranslatedDatasetStatus(item.status) }}</td>
            <td class="download-button-column">
              <button
                [disabled]="isDownloadButtonDisabled(item)"
                class="btn submit data-set-download-button"
                (click)="downloadDataSet(item.id)"
                i18n="@@download">
                Download
              </button>
              <app-loading
                *ngIf="item.downloadState === 'LOADING'"></app-loading>
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="dataSetRequestsStatus === 'LOADING'">
          <tr>
            <app-loading></app-loading>
          </tr>
        </ng-container>

        <ng-container *ngIf="dataSetRequestsStatus === 'FAILED'">
          <tr>
            <app-error-notification
              id="failedToLoadDataSetRequestsError"
              [text]="translations.failedToLoadDataSetRequests">
              ></app-error-notification
            >
          </tr>
        </ng-container>
      </table>

      <section class="data-set-request-actions">
        <button
          *ngIf="sendingDataSetRequest !== 'LOADING'"
          class="btn btn-submit"
          [disabled]="
            hasPendingDataSetRequests || dataSetRequestsStatus !== 'SUCCESS'
          "
          (click)="requestServiceGroupData()"
          i18n="@@requestDataset">
          Request dataset
        </button>

        <section class="disclaimers">
          <div class="disclaimer" *ngIf="sendingDataSetRequest === 'LOADING'">
            <app-loading></app-loading>
          </div>

          <div *ngIf="sendingDataSetRequest === 'FAILED'">
            <app-error-notification
              id="somethingWentWrongError"
              [text]="translations.somethingWentWrong">
            </app-error-notification>
          </div>

          <div class="disclaimer" *ngIf="hasPendingDataSetRequests">
            <app-notification [icon]="'history'">
              {{
                serviceGroupHasPendingDataSetRequestsDescription
              }}</app-notification
            >
          </div>
        </section>
      </section>
    </section>
  </ng-container>
</main>
