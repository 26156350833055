// @ts-nocheck
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FilterComponent } from './filter/filter.component';
import { ListComponent } from './list.component';
import { TableComponent } from './table/table.component';
import { UtilsModule } from 'app/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    MomentModule,
    UtilsModule,
  ],
  exports: [ListComponent],
  declarations: [ListComponent, FilterComponent, TableComponent],
})
export class ListModule {}
