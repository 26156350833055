<section>
  <header>
    <img src="assets/images/access-logo.png" alt="Klinik Access logo" />
  </header>

  <div id="mfa-content">
    <app-loading
      *ngIf="validationSubscription"
      [title]="validatingTranslation"></app-loading>

    <form [formGroup]="codeForm" *ngIf="validationSubscription === null">
      <h2 i18n="@@mfaCodePageEnterVerificationCodeTitle">
        Enter verification code
      </h2>

      <p i18n="@@mfaCodePageTheCodeHasBeenSentTo">
        The code has been sent to: {{ this.maskedUsername }}
      </p>

      <input
        formControlName="code"
        type="text"
        [maxlength]="codeLength"
        spellcheck="false"
        required />

      <button
        class="btn action"
        [disabled]="!codeForm.valid"
        (click)="checkCode()"
        i18n="@@mfaCodeVerify">
        Verify
      </button>

      <ng-container *ngIf="errorType" [ngSwitch]="errorType">
        <ng-container *ngSwitchCase="errorTypes.unauthorized">
          <p class="error" i18n="@@mfaCodeInvalidVerificationCode">
            Invalid verification code!
          </p>
          <p [innerHTML]="mfaCodeTypeVerificationCodeAgain">
            Type verification code again
            <br />or
          </p>
        </ng-container>

        <ng-container *ngSwitchCase="errorTypes.tooManyRequests">
          <p class="error" i18n="@@errorTooManyRequests">Too many requests!</p>
          <p i18n="@@errorPleaseWait">Please wait a moment before retrying</p>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <p class="error" i18n="@@errorPleaseTryAgain">
            An error occurred. Please try again.
          </p>
        </ng-container>
      </ng-container>

      <p
        *ngIf="
          (!errorType || errorType !== errorTypes.unauthorized) &&
          errorType !== errorTypes.internalServerError
        "
        [innerHTML]="mfaCodeItMayTakeFewMinutes">
        It may take a few minutes for the code to arrive.<br />
        Please also check your spam folder.
      </p>
    </form>

    <button
      class="blue plain-text"
      (click)="requestVerificationCode()"
      i18n="@@mfaCodeResendCode">
      Resend code
    </button>
    <p *ngIf="retryCodeSent" i18n="@@mfaCodeNewCodeHasBeenSent">
      New code has been sent!
    </p>
  </div>

  <button class="small plain-text" (click)="backToLogin()">
    <i class="fa fa-undo"></i>
    <span>{{ backButtonText }}</span>
  </button>
</section>
