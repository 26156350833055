<section class="kip-instructions">
  <div class="kip-instructions-sidebar">
    <button class="kip-enable">Enable integration services</button>
    <button class="kip-list">List of available intergation services</button>
    <button class="kip-intro">Intro to integration</button>
  </div>
  <div class="kip-instructions-container">
    <div class="kip-instructions-header-container">
      <h2>Enable integration services</h2>
      <p>
        KIP installer is used to install <span>Klinik Integration Pack</span> on
        your local machine.
        <br />
        KIP is required for integration services to fucntion properly, it is the
        intermediate program that handles communication between PHR and Klinik
        services.
      </p>
    </div>
    <ng-container *ngFor="let card of cards; let i = index">
      <app-kip-card
        [config]="card"
        (cardEmitter)="updateCards(i)"></app-kip-card>
    </ng-container>
  </div>
</section>
