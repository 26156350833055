// @ts-nocheck
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { Enquiry } from 'app/shared/methods/enquiry/enquiry';
import { getNestedValue } from 'app/shared/methods/getNestedValue';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { PromptTranslations } from 'app/utils/prompt/prompt.component';
import { Subscription } from 'rxjs';
import { IntegrationService } from 'services/integration.service';
import { MessageService } from 'services/message.service';
import { PrintService } from 'services/print.service';
import { PublicService } from 'services/public.service';
import { SessionService } from 'services/session.service';
import {
  StateService,
  StateKeys,
  singleMessageEvent,
} from 'services/state.service';
import { UtilsService } from 'services/utils.service';
import { LoadingStatus } from 'enums';

export interface ServiceIdAndName {
  name: string;
  id: number;
}

@Component({
  selector: 'app-messages-detail-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit, OnDestroy {
  @Input() public message: Enquiry;

  public serviceId: number = null;
  public serviceList: Array<ServiceIdAndName> = [];
  showErrors: boolean;
  public readonly permissions = Permissions;
  public redirectForm: FormGroup;
  public redirectTarget: object | null = null;

  // Prompt
  public showPrompt: boolean = false;
  public promptTranslations: PromptTranslations = {
    header: '',
    prompt: '',
    yes: '',
    no: '',
  };

  private stateChangeSubscription: Subscription;
  private userServiceList: Array<object> = [];

  public isVideoConsultationEnabled: boolean =
    this.sessionService.isVideoConsultationEnabled;

  get redirectionServices(): Array<ServiceIdAndName> {
    return this.serviceList.filter((x) => x.id != this.message.service);
  }

  public get isMessageLoading() {
    return this.stateService.isLoading(StateKeys.singleMessage);
  }

  constructor(
    protected messageService: MessageService,
    private fb: FormBuilder,
    protected sessionService: SessionService,
    protected stateService: StateService,
    private utilsService: UtilsService,
    protected integrationService: IntegrationService,
    private printService: PrintService,
    private publicService: PublicService,
    private toasterService: ToasterService
  ) {
    this.redirectForm = this.fb.group({
      content: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.maxLength(600),
      ]),
      flag: new FormControl({ value: false, disabled: false }),
    });
  }

  ngOnInit() {
    this.serviceList = this.getServiceList();

    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.loadedServiceGroup:
            this.isVideoConsultationEnabled =
              this.sessionService.isVideoConsultationEnabled;
            break;
          case StateKeys.selectedMessage:
            // refresh this here so will get valid values from the session service
            this.serviceList = this.getServiceList();
            this.serviceId = state['value'] ? state['value']['id'] : null;

            // dropdownToggle tag in button element doesn't accept disabled attribute in the template
            if (
              this.sessionService.checkPermissions(
                this.permissions.MESSAGE_TRANSFER
              ) === false &&
              this.serviceList.length > 0
            ) {
              document
                .getElementById('unit-transfer')
                .setAttribute('disabled', 'true');
            }
            break;
          case StateKeys.singleMessage:
            const event = state['value'] as singleMessageEvent;
            if (event.status !== LoadingStatus.SUCCESS) return;
            this.serviceId = event.data['service'];
            break;
          case StateKeys.loadedUserServices:
            this.serviceId = state['value'] ? state['value']['service'] : null;
            break;
        }
      }
    );
  }

  ngOnDestroy() {
    this.stateChangeSubscription.unsubscribe();
  }

  togglePrompt(toggle: boolean, translations: PromptTranslations | null): void {
    const t = {
      header: '',
      prompt: '',
      yes: '',
      no: '',
    };
    if (translations && toggle) {
      t.header = translations.header;
      t.prompt = translations.prompt;
      t.yes = translations.yes;
      t.no = translations.no;
    }

    this.showPrompt = toggle;
    this.promptTranslations = t;
  }

  handlePromptResponse(response: boolean): void {
    // User has cancelled action
    if (!response) return this.togglePrompt(false, null);
    // User updates message unit with force
    this.OnSubmit(this.redirectTarget, this.redirectForm.value, true);
  }

  userHasServicePermission(service: object): boolean {
    this.userServiceList.forEach(function (s: object) {
      if (s['slug'] === service['slug']) {
        return true;
      }
    });
    return false;
  }

  selectRedirectTarget(service: object | null) {
    this.redirectTarget = service;
    this.resetRedirectForm();
  }

  async OnSubmit(service: object | null, formValue, useForceUpdate = false) {
    try {
      await this.messageService.redirectMessage(
        this.message['id'],
        service?.id,
        formValue.content,
        formValue.flag,
        useForceUpdate
      );
    } catch (error) {
      if (error.error.code === 'MESSAGE_STATE_CONFLICT') {
        // Case it in handling by another user (requires force update)
        this.togglePrompt(true, {
          header: $localize`:@@promptUpdateMessageStatusHeader:`,
          prompt: error.error.message,
          yes: $localize`:@@continue:`,
          no: $localize`:@@promptUpdatemesageSatustNo:`,
        });
        return;
      }
    }
    // Transfer successful
    this.stateService.setState(StateKeys.redirectedMessage, this.message['id']);
    this.message = null;
    this.resetRedirectForm();
    this.closeMessage();
  }

  updateMessageState(state) {
    this.messageService.updateMessage(this.message['id'], state);
  }

  resetRedirectForm() {
    this.redirectForm.controls.content.setValue(null);
    this.redirectForm.controls.flag.setValue(null);
    this.redirectForm.markAsUntouched();
  }

  closeMessage() {
    this.stateService.setState(StateKeys.closedMessage, this.message);
    this.stateService.setState(StateKeys.toggledFollowup, false); // this is here to stop the polling of followup messages
    this.message = null;
  }

  async downloadPdf(): Promise<void> {
    const locale = this.utilsService.currentLocale;

    // Page heading
    const [appointmentType] = [this.printItembyId('appointment-type')];

    // Header information
    const [name, documentID, dateOfBirth, nhsNumber] = [
      this.printItemByClassAndTag('item-name', 'p'),
      this.printItemByClassAndTag('item-id', 'p'),
      this.printItemByClassAndTag('item-date-of-birth', 'span'),
      this.printItemByClassAndTag('item-nhs-number', 'span'),
    ];

    // Personal information
    const [urgency, statusAssignee, personalItems, formFiller] = [
      this.printItemByClass('status-priority'),
      this.printItemByClass('status-assignee'),
      this.printItemByClass('items'),
      this.printItemByClass('form-filler-information'),
    ];

    // Additional information
    const [additionalInfo] = [this.printItemByClass('detail-description')];

    const data = {
      filename: documentID,
      header: `
        <header class="left">
          ${name} - ${dateOfBirth}
          <br>
          ${nhsNumber}<br>
        </header>

        <header class="right">
          Klinik Access, ${documentID}<br>
          ${new Date().toLocaleDateString(locale, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
          
        </header>
      `,
      body: `
        <div class="print-wrapper personal-info">
          <h1>${appointmentType}</h1>
          <p>${
            urgency === undefined
              ? statusAssignee
              : `${urgency}<br>${statusAssignee}`
          }</p>
          ${personalItems}
          ${formFiller}
        </div>

        <div class="print-wrapper additional-info">
          ${additionalInfo}
        </div>
      `,
    };

    try {
      await this.printService.downloadPdf(data);
    } catch (error) {
      this.toasterService.pop(
        'error',
        $localize`:@@printFailed:Printing failed`
      );
    }
  }

  printItemByClassAndTag(key: string, tag: string) {
    if (document.getElementsByClassName(key)[0]) {
      if (
        document.getElementsByClassName(key)[0].getElementsByTagName(tag)[0]
      ) {
        const element = <HTMLElement>(
          document.getElementsByClassName(key)[0].getElementsByTagName(tag)[0]
        );
        return element.innerText;
      }
    }

    return '';
  }

  printItemByClass(key: string): string {
    if (document.getElementsByClassName(key)[0]) {
      return document.getElementsByClassName(key)[0].innerHTML;
    }

    return '';
  }

  printItembyId(key: string): string {
    if (document.getElementById(key)) {
      return document.getElementById(key).innerHTML;
    }

    return '';
  }

  getServiceList(): any[] {
    const modifiedServices = [];
    const sister_service_groups = this.utilsService.getProperty(
      this.sessionService.getSession(),
      'sister_service_groups'
    );

    if (sister_service_groups !== null && sister_service_groups.length > 0) {
      for (const sisterServiceGroup of sister_service_groups) {
        for (const service of sisterServiceGroup.services) {
          if (service.active === true) {
            const ms = {
              name: `${sisterServiceGroup.name} -- ${service.name}`,
              id: service.id,
            };
            modifiedServices.push(ms);
          }
        }
      }

      return modifiedServices;
    }

    return this.utilsService
      .getProperty(this.sessionService.serviceGroup, 'services', [])
      .filter((value) => value.active === true);
  }

  openVideoConsultation(): void {
    const appointmentRequestUuid: string = getNestedValue(
      this.message,
      'data',
      'appointment_request_uuid'
    );

    this.publicService
      .getVideoConsultationRoom(appointmentRequestUuid)
      .then((getVideoConsultationRoom) => {
        const consultationRoomUrl =
          getVideoConsultationRoom.consultation_room_url;
        window.open(consultationRoomUrl, '', 'width=800, height=800');
      })
      .catch(() =>
        this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`)
      );
  }
}
