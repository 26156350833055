<div id="my-account-view">
  <h1 i18n="@@userDropdownMyAccount">My Account</h1>
  <div class="tabs">
    <button
      class="settings-page-nav-button"
      [ngClass]="{ active: activeTab === 'my-account' }"
      (click)="switchTab('my-account')"
      i18n="@@userDropdownMyAccount">
      My Account
    </button>

    <button
      class="settings-page-nav-button"
      [ngClass]="{ active: activeTab === 'authentication' }"
      (click)="switchTab('authentication')"
      i18n="@@authSettingsNavTitle">
      Authentication
    </button>
  </div>

  <div *ngIf="activeTab == 'my-account'">
    <app-users-detail
      *ngIf="userDetails"
      [modalType]="userDetailsModalType"
      [userDetails]="userDetails"></app-users-detail>
  </div>

  <div *ngIf="activeTab == 'authentication'">
    <app-user-authentication-settings
      *ngIf="userDetails"
      [userDetails]="userDetails"></app-user-authentication-settings>
  </div>
</div>
