// @ts-nocheck
const colors = {
  urgency_red: '255, 0, 0',
  urgency_yellow: '248, 154, 1',
  urgency_green: '0, 128, 0',
  urgency_lilac: '0, 84, 147',
  klinik_lilac: '70, 51, 92',
  klinik_red: '219, 62, 36',
  klinik_blue: '37, 55, 70',
  klinik_green: '0, 172, 96',
  klinik_orange: '255, 133, 94',
  klinik_grey: '137, 137, 137',
};

export const defaultColors = [
  colors.klinik_lilac,
  colors.klinik_green,
  colors.klinik_blue,
  colors.klinik_orange,
  colors.klinik_grey,
];
export const urgencyColors = [
  colors.urgency_lilac,
  colors.urgency_green,
  colors.urgency_yellow,
  colors.urgency_red,
];
export const statusColors = [
  colors.klinik_lilac,
  colors.klinik_red,
  colors.klinik_blue,
  colors.klinik_green,
];
export const feedbackColors = [
  colors.klinik_red,
  colors.klinik_blue,
  colors.klinik_lilac,
  colors.klinik_green,
];
