// @ts-nocheck
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpTools } from 'tools/httptools';
import { CacheService } from './cache.service';
import { map, shareReplay } from 'rxjs/operators';

const TRANSACTION_CACHE_TTL = 1000 * 30; // 30 seconds

/**
 * Provides methods for fetching creating, and reading integration transactions.
 * Integration transactions are used to track the progress of patient record integrations and provide a way to view
 * the status and history of integration-related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class IntegrationTransactionService {
  constructor(
    private http: HttpClient,
    private httpTools: HttpTools,
    private cache: CacheService
  ) {}

  private _getURL(messageId: number): string {
    return `${environment.backendURL}/messages/${messageId}/integration-transactions`;
  }

  /**
   * Returns integration transactions for a message.
   * Results will be cached based on the messageId.
   * @param messageId The ID of the message to fetch transactions for.
   * @returns An observable that resolves to the response data.
   */
  public fetch(
    messageId: number,
    clearCache: boolean = false
  ): Observable<IntegrationTransaction[]> {
    const cache_key = `integration_transactions_${messageId}`;
    const url = this._getURL(messageId);
    const headers = this.httpTools.prepareHeaders();

    if (clearCache) this.cache.remove(cache_key);

    const cached = this.cache.get(cache_key);
    if (cached) return cached;

    const response = this.http
      .get<IntegrationTransaction[]>(url, {
        headers,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        shareReplay(1) // This allows multiple subscriptions to the same observable to share the same response
      );

    this.cache.set(cache_key, response, TRANSACTION_CACHE_TTL);
    return response;
  }
  /**
   * Creates a new integration transaction, and triggers a request to forward the case details to an external patient record system.
   * @param messageId The ID of the message to create a transaction for.
   * @param odsCode The ODS code of the practice to create a transaction for.
   * @param includeComments Whether to include comments in the transaction.
   * @param caseSummary A case summary to add to the transaction.
   * @param followupMessageIds An array of message IDs to add to the transaction as followups.
   * @param imageAttachmentIds An array of attachment IDs to add to the transaction.
   * @param integrationDestination The destination patient record system to forward the case details to.
   * @returns An observable that resolves to the response data.
   */
  public create(
    messageId: number,
    odsCode: string,
    caseSummary: string,
    includeComments: boolean,
    followupMessageIds: number[],
    imageAttachmentIds: number[],
    integrationDestination: IntegrationDestination
  ): Observable<{}> {
    // TODO: improve typing when api return something smarter
    const url = this._getURL(messageId);
    const headers = this.httpTools.prepareHeaders();
    const data = {
      ods_code: odsCode,
      case_summary: caseSummary,
      include_comments: includeComments,
      followup_ids: followupMessageIds,
      attachment_ids: imageAttachmentIds,
      integration_destination: integrationDestination,
    };

    return this.http.post<{}>(url, data, { headers });
  }

  /** Returns the latest created integration transaction from the fetchOutput$ feed. */
  getLatestCreated(messageId: number): Observable<IntegrationTransaction> {
    const transactions = this.fetch(messageId);
    return transactions.pipe(
      map((transactions) => {
        return transactions?.sort((a, b) =>
          b.created_at.localeCompare(a.created_at)
        )?.[0];
      })
    );
  }

  /** Returns true if the latest transaction in the fetchOutput$ feed has a status of pending */
  latestIsPending(messageId: number): Observable<boolean> {
    const latest = this.getLatestCreated(messageId);
    return latest.pipe(
      map((latest) => {
        return latest?.status === IntegrationTransactionStatus.PENDING;
      })
    );
  }

  /** Returns true if the fetchOutput$ feed has at least one item */
  hasAny(messageId: number): Observable<boolean> {
    const transactions = this.fetch(messageId);
    return transactions.pipe(
      map((transactions) => {
        return transactions?.length > 0;
      })
    );
  }
}
