// @ts-nocheck
import {
  customOptionsBarNoAutoskip,
  defaultOptionsPie,
  optionsGroupedBar,
} from '../configs/chartJSDefaultConfigs';

const translations = [
  $localize`:@@sidebar_user:`,
  $localize`:@@sidebar_gender:`,
  $localize`:@@sidebar_age:`,
  $localize`:@@sidebar_contacts-by-municipality:`,
];

export const user: View = {
  gender: {
    id: 'gender',
    charts: [
      {
        chart_config: {
          id: 'all-contacts-gender',
          legend: $localize`:@@allContactsGender:`,
          type: ChartType.PIE,
          options: defaultOptionsPie,
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.TOTAL,
        datasets: [
          'all_contact_requests_female',
          'all_contact_requests_male',
          'short_contacts_unknown',
        ],
        translations: {
          labels: {
            all_contact_requests_female: $localize`:@@allContactsFemale:`,
            all_contact_requests_male: $localize`:@@allContactsMale:`,
            short_contacts_unknown: $localize`:@@allContactsUnknown:`,
          },
        },
      },
    ],
  },
  age: {
    id: 'age',
    charts: [
      {
        chart_config: {
          id: 'contacts-by-age',
          legend: $localize`:@@ContactsByAge:`,
          type: ChartType.BAR,
          options: customOptionsBarNoAutoskip,
        },
        group_by: GroupBy.XLABEL,
        datasets: ['all_contact_requests_female', 'all_contact_requests_male'],
        translations: {
          labels: {
            all_contact_requests_female: $localize`:@@contactRequestsFemale:`,
            all_contact_requests_male: $localize`:@@contactRequestsMale:`,
          },
        },
      },
    ],
  },
  'contacts-by-municipality': {
    id: 'contacts-by-municipality',
    charts: [
      {
        chart_config: {
          id: 'contacts-by-municipality',
          legend: $localize`:@@ContactsByMunicipality:`,
          type: ChartType.BAR,
          options: customOptionsBarNoAutoskip,
        },
        sort_by: SortBy.DESC,
        group_by: GroupBy.NONE,
        datasets: ['municipalities'],
        translations: {
          labels: {
            municipalities: $localize`:@@ContactsByMunicipality:`,
          },
        },
      },
    ],
  },
};
