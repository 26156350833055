import { Component } from '@angular/core';
import { AccessGroupName } from 'enums';
import { Permissions } from 'permissions/permissions.module';
import { FeatureFlagService } from 'services/feature-flag.service';
import { SessionService } from 'services/session.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  public readonly permissions = Permissions;
  public readonly snoozeEnabled: boolean =
    this.sessionService.isSnoozeFeatureEnabled;

  public showSettingsIntro: boolean = true;

  public get isDataRequestViewEnabled(): boolean {
    const featureEnabled =
      this.sessionService?.serviceGroup?.data_set_requests_enabled;
    const hasPermission = this.sessionService.checkPermissions(
      Permissions.SETTINGS_DATA
    );

    return (featureEnabled && hasPermission) || this.userIsStaff;
  }

  /**Returns true if user is staff or service group admin */
  public get isDataReportsEnabled(): boolean {
    return (
      this.featureFlagService.isDataReportsEnabled &&
      (this.sessionService.userIsStaff ||
        this.sessionService.hasGroupAccess(
          AccessGroupName.SERVICE_GROUP_ADMIN_ROLE
        ))
    );
  }

  public get userIsStaff(): boolean {
    return this.sessionService.userIsStaff;
  }

  constructor(
    private sessionService: SessionService,
    private featureFlagService: FeatureFlagService
  ) {}
}
