<div class="modal-backdrop" (click)="closeModal()"></div>

<section id="moving-to-record" [ngClass]="{ status: sending.size > 0 }">
  <p id="modal-action-header">
    <ng-container
      *ngIf="currentIntegrationAction === integrationAction.FOR_INFORMATION"
      i18n="@@integrationActionHeaderForInformation">
      Archive case and transfer to patient record
    </ng-container>
    <ng-container
      *ngIf="currentIntegrationAction === integrationAction.FOR_ACTION"
      i18n="@@integrationActionHeaderForAction">
      Archive case and transfer as an action
    </ng-container>
  </p>

  <ng-container *ngIf="sending.size > 0">
    <ng-container [ngSwitch]="firstOfSending.type">
      <ng-container *ngSwitchCase="'senddocument'">
        <div class="gpconnect-senddocument loading-container">
          <i
            class="fa fa-spinner spin"
            *ngIf="firstOfSending.status === 'sending'"></i>

          <ng-container
            *ngIf="firstOfSending.status === integrationResponse.SUCCESS">
            <i class="fa fa-check-circle"></i>
            <ul>
              <li i18n="@@sendDocumentSuccess">
                Transfer initiated successfully
              </li>
              <li i18n="@@sendDocumentSuccessArchive">
                Once required acknowledgments have been received the case will
                be automatically archived
              </li>
              <li i18n="@@sendDocumentSuccessStatus">
                Status of the transfer will be updated and stay visible at the
                top of the case details
              </li>
            </ul>
          </ng-container>

          <ng-container
            *ngIf="firstOfSending.status === integrationResponse.FAIL">
            <i class="fa fa-times"></i>
            <ul>
              <li i18n="@@sendDocumentFail">Transfer not initiated</li>
              <li i18n="@@sendDocumentFailArchive">
                Case will not be automatically archived
              </li>
              <li i18n="@@sendDocumentFailTryAgain">
                You may try to initiate transfer again
              </li>
            </ul>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container *ngFor="let data of sending">
          <button
            class="selection-btn"
            (click)="moveToRecord(true, data)"
            [ngClass]="[data.status]">
            <h2 class="thin" [ngSwitch]="data.status">
              <ng-container
                *ngSwitchCase="'sending'"
                i18n="@@movingToRecordStatusSending">
                Forwarding {{ data.type }} to PRS...
              </ng-container>
              <ng-container
                *ngSwitchCase="integrationResponse.FAIL"
                i18n="@@movingToRecordStatusRetry">
                Forwarding data to PRS failed
              </ng-container>
              <ng-container
                *ngSwitchCase="integrationResponse.SUCCESS"
                i18n="@@movingToRecordStatusSuccess">
                Forwarded {{ data.type }}
              </ng-container>
            </h2>

            <p [ngSwitch]="data.status">
              <ng-container *ngSwitchCase="'sending'">
                <i class="fa fa-spinner"></i>
              </ng-container>
              <ng-container *ngSwitchCase="integrationResponse.FAIL">
                <p>
                  <i class="fa fa-repeat"></i>
                  <span i18n="@@movingToRecordResultFailed">Retry</span>
                </p>
              </ng-container>
              <ng-container *ngSwitchCase="integrationResponse.SUCCESS">
                <p i18n="@@movingToRecordResultSuccess">Success</p>
              </ng-container>
            </p>
          </button>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="flex-wrapper" *ngIf="sending.size === 0">
    <section class="selections">
      <h1 class="modal-header" i18n="@@moveToRecordModalSelectionsTitle">
        Selections
      </h1>
      <button
        *ngFor="let item of visibleSelectionItems"
        class="selection-btn"
        (click)="toggleSelections(item.key)"
        [ngClass]="{
          selected: selections.has(item.key),
          unselected: selections.has(item.key) === false
        }">
        <h2 class="thin">{{ item.header }}</h2>
        <p>{{ item.content }}</p>

        <i *ngIf="isPresetSelection(item.key) == true" class="fa fa-lock"></i>
        <i *ngIf="isPresetSelection(item.key) == false" class="fa fa-check"></i>
      </button>
    </section>

    <section class="preview">
      <h1 class="modal-header" i18n="@@moveToRecordModalPreviewTitle">
        Preview
      </h1>
      <div class="preview-sections-text" *ngIf="textPreviewVisible()">
        <div class="preview-section" *ngIf="selections.has('personal_info')">
          <p *ngFor="let item of enquiry.personalInfoItems">
            <span class="thin">{{ item.key }}:</span> {{ item.value }}
          </p>
        </div>

        <div
          class="preview-section"
          *ngIf="selections.has('medical_information')">
          <p *ngFor="let item of enquiry.symptomDetailItems">
            <span class="thin">{{ item.key }}:</span> {{ item.value }}
          </p>

          <p *ngFor="let item of enquiry.symptomItems">
            <span class="thin">{{ item.key }}:</span> {{ item.value }}
          </p>

          <p *ngIf="enquiry.severitySymptomsItem">
            <span class="thin"> {{ enquiry.severitySymptomsItem.key }}:</span>
            {{ enquiry.severitySymptomsItem.value }}
          </p>

          <p *ngFor="let item of enquiry.previousTreatmentItems">
            <span class="thin">{{ item.key }}:</span> {{ item.value }}
          </p>

          <p *ngFor="let item of enquiry.medicalQuestionItems">
            <span class="thin">{{ item.key }}:</span> {{ item.value }}
          </p>
        </div>

        <div
          class="preview-section"
          *ngIf="selections.has('system_recommendations')">
          <p *ngFor="let item of systemRecommendationsParsed">
            <span class="thin">{{ item.key }}:</span> {{ item.value }}
          </p>
        </div>

        <div class="preview-section" *ngIf="selections.has('followups')">
          <p *ngFor="let item of followupMessagesParsed">
            <span class="thin"
              >({{ item.createdAt }} SMS) {{ item.sender }}: </span
            >{{ item.body }}
          </p>
        </div>

        <div class="preview-section" *ngIf="selections.has('comments')">
          <p *ngFor="let comment of enquiry.commentItems">
            <span class="thin">{{ comment.key }}:</span> {{ comment.value }}
          </p>
        </div>
      </div>
      <div id="attachments" *ngIf="selections.has('attachments')">
        <p>Attachments:</p>
        <div class="loading-container" *ngIf="!attachmentsLoaded">
          <i class="fa fa-spinner spin"></i>
        </div>

        <div
          class="image-row"
          *ngIf="pictures && pictures.length > 0; else no_pictures">
          <ng-container *ngIf="processedPictures.length > 0">
            <div *ngFor="let image of processedPictures; let i = index">
              <div
                class="image-preview-column"
                [ngClass]="{ selected: selectedAttachmentIds.has(image.id) }"
                (click)="toggleAttachmentSelections(image.id)">
                <img class="selected" [src]="image.value" />
                <div class="attachment-checkbox">
                  <i
                    class="fa fa-check"
                    *ngIf="selectedAttachmentIds.has(image.id)"></i>
                </div>
              </div>
              <div class="sent-to-emis-indicator" *ngIf="image.sentToEmis">
                <p style="font-weight: 100" i18n="@@movedToRecord">
                  moved to record
                </p>
                <i class="fa fa-check"></i>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!hasPicturePermission">
            <span
              title="Insufficient permissions"
              class="image-preview-column not-allowed"
              *ngFor="let blankImage of pictures; let i = index">
              <i class="fa fa-file-image-o fa-lg"></i>
            </span>
          </ng-container>
        </div>
      </div>
      <ng-template #no_pictures>
        <span *ngIf="attachmentsLoaded" class="no-images" i18n="@@noAttachments"
          >This case has no attachments...</span
        >
      </ng-template>
    </section>
  </div>

  <section class="modal-actions">
    <button class="cancel" (click)="closeModal()" [disabled]="isBeingSent()">
      <ng-container i18n="@@cancelButton" *ngIf="sending.size === 0"
        >Cancel</ng-container
      >
      <ng-container i18n="@@OkButton" *ngIf="sending.size > 0">OK</ng-container>
    </button>
    <button
      class="continue"
      *ngIf="sending.size === 0"
      (click)="moveToRecord()"
      [disabled]="
        this.selections.size == 0 ||
        !attachmentsLoaded ||
        !followupMessagesLoaded
      "
      i18n="@@moveSelectionsToRecord">
      Submit
    </button>
  </section>
</section>
