// @ts-nocheck
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpWrapperService } from './http-wrapper.service';
import { StateService, StateKeys } from './state.service';

@Injectable()
export class FeedbackService {
  constructor(
    private httpWrapper: HttpWrapperService,
    private stateService: StateService
  ) {}

  sendFeedBack(data: any) {
    return this.httpWrapper
      .deprecated_post('/feedback/send', data)
      .then(() =>
        this.stateService.setState(StateKeys.sendingFeedbackComplete, '')
      )
      .catch((errors) =>
        this.stateService.setState(StateKeys.sendingFeedbackFailed, {
          formErrors: errors,
          status: errors.status,
        })
      );
  }

  async postFeedbackSurvey(data: any) {
    try {
      const respones = await this.httpWrapper.deprecated_post(
        '/feedback_survey/send',
        data
      );
      return respones;
    } catch (errors) {
      if (environment.production === false) {
        console.error(errors);
      }
      return false;
    }
  }

  async patchUserOnboarded() {
    this.httpWrapper.patch('/kip_user_onboarded/patch', null).subscribe();
  }
}
