// @ts-nocheck
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { environment } from 'environments/environment';
import { StateService, StateKeys } from './state.service';
import { getNestedValue } from '../methods/getNestedValue';
import { Router } from '@angular/router';
import {
  ErrorTypes,
  ValidMFACodeResponse,
  ValidSessionResponse,
} from 'app/utils/http-response.enums';
import { Observable } from 'rxjs';
import { AccessGroupName } from 'enums';

export enum PasswordLabels {
  CURRENT_PASSWORD = 'current-password',
  NEW_PASSWORD = 'new-password',
  RETYPED_NEW_PASSWORD = 'retyped-new-password',
}

export interface changePasswordPayload {
  new_password: string;
  old_password: string;
}

@Injectable()
export class SessionService {
  private session: object = null;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    protected stateService: StateService,
    private router: Router
  ) {
    this.session = this.getSession();
  }

  prepareHeaders(method: string = null, sessionId: string = null): HttpHeaders {
    let headers = new HttpHeaders();

    if (sessionId === null) {
      // If sessionid was not provided as argument, try to get it rom the active session instead.
      sessionId = this.getSessionId();
    }

    if (sessionId) {
      // If we have a sessionid, set it as an authentication header.
      headers = headers.set('X-sessionid', sessionId);
    }

    if (environment.production) {
      headers = headers.set('Cache-Control', 'no-cache, must-revalidate');
      headers = headers.set('Pragma', 'no-cache');
      headers = headers.set(
        'If-Modified-Since',
        'Mon, 26 Jul 1997 05:00:00 GMT'
      );
    }

    if (method === 'post') {
      headers = headers.set('Content-Type', 'application/json');
    }

    return headers;
  }

  async login(
    username: string,
    password: string,
    language: string
  ): Promise<object> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    try {
      const request = this.http.post(
        `${environment.backendURL}/authenticate`,
        { username: username, password: password, language: language },
        { headers: headers }
      );

      return await request.toPromise();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async requestMFAVerficationCode(
    sessionId: string,
    currentLanguage: string
  ): Promise<object> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    try {
      const request = this.http.post(
        `${environment.backendURL}/authenticate/mfa/request`,
        { session_id: sessionId, language: currentLanguage },
        { headers: headers }
      );

      return await request.toPromise();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  verifyMFAVerficationCode(
    mfaCode: string,
    sessionId: string
  ): Observable<ValidMFACodeResponse> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.post(
      `${environment.backendURL}/authenticate/mfa/verify`,
      { mfa_code: mfaCode, session_id: sessionId },
      { headers: headers }
    ) as Observable<ValidMFACodeResponse>;
  }

  /**
   * Get user data.
   * @param userId - Id of the user.
   * @param sessionId - Session Id for authentication.
   * @param language - Current language of the UI.
   * @returns - The whole user data, including the sessionId.
   */
  async getUserData(
    userId: number,
    sessionId: string,
    language: string
  ): Promise<object> {
    if (!userId || !sessionId) {
      return Promise.reject({ error: 'Invalid session' });
    }

    try {
      const response = await this.http
        .get(`${environment.backendURL}/users/${userId}?language=${language}`, {
          headers: this.prepareHeaders('get', sessionId),
        })
        .toPromise();
      response['sessionid'] = sessionId;
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Refreshes the currently logged-in user's data.
   * As stated, this method assumes that the user is already logged-in.
   * It will also save the whole session etc...
   *
   * @param language - Current language of the UI.
   * @returns - nothing.
   */
  async refreshUserData(language: string): Promise<void> {
    const userId = this.userID;
    const sessionId = this.getSessionId();

    try {
      const response = await this.getUserData(userId, sessionId, language);
      this.saveSession(response);
      this.stateService.setState(StateKeys.refreshUserData, response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async logout(): Promise<void> {
    // Make the logout request only if we have a session Id.
    if (this.getSessionId()) {
      const url = `${environment.backendURL}/logout`;
      const headers = this.prepareHeaders('post');

      try {
        await this.http.post(url, {}, { headers: headers }).toPromise();
      } catch (error) {
        /* 
        It is of not much consequence even if the logout request fails.
        We could still log these errors to Sentry though.
        */
      }
    }

    // whatever happens, just delete the session from Pro UI
    this.deleteSession();

    // Navigate the user to the logout page
    this.router.navigate(['auth', 'logout']);
  }

  saveSession(session: object): void {
    this.session = session;
    localStorage.setItem(
      this.configuration.sessionName,
      JSON.stringify(this.session)
    );
  }

  deleteSession(): void {
    this.session = null;
    localStorage.removeItem(this.configuration.sessionName);
    this.stateService.setState(StateKeys.deleteSession, null);
  }

  async resetPassword(email: string, language: string): Promise<Object> {
    try {
      const response = await this.http
        .post(`${environment.backendURL}/users/get_password_reset_token`, {
          email: email,
          language: language,
        })
        .toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async validateToken(
    email: string,
    token: string
  ): Promise<HttpResponse<Object>> {
    try {
      const response = await this.http
        .post(
          `${environment.backendURL}/users/validate_password_reset_token`,
          { email: email, token: token },
          { observe: 'response' }
        )
        .toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async changePassword(payload: changePasswordPayload): Promise<Object> {
    try {
      const request = this.http.post(
        `${environment.backendURL}/users/${this.userID}/password`,
        payload,
        { headers: this.prepareHeaders('post') }
      );
      const response = request.toPromise();
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Set a new password when user authentivatin is done with a token.
   */
  async setPassword(
    email: string,
    token: string,
    newPassword: string
  ): Promise<HttpResponse<Object>> {
    try {
      const response = await this.http
        .post(
          `${environment.backendURL}/users/set_password`,
          {
            email: email,
            token: token,
            new_password: newPassword,
          },
          { observe: 'response' }
        )
        .toPromise();
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  setData(key: string, value: any): void {
    const session = this.getSession();
    if (session === null) {
      // The session has expired
      return;
    }

    if (!session.hasOwnProperty('data')) {
      session['data'] = {};
    }

    session['data'][key] = value;
    this.saveSession(session);
  }

  setServiceGroup(serviceGroup: ServiceGroup): void {
    // Update both of these keys since both of them are used throughout Pro UI.
    // Refactoring should be done to get rid of the situation.
    this.session['service_group'] = serviceGroup;
    this.setData('serviceGroup', serviceGroup);
  }

  /**
   * Get value of a key in session.data.
   * @return undefined when no data found.
   */
  getData(key: string): any | undefined {
    return getNestedValue(this.getSession(), 'data', key);
  }

  getSession(): object | null {
    const storedSession = localStorage.getItem(this.configuration.sessionName);

    if (storedSession) {
      return JSON.parse(storedSession);
    }

    return null;
  }

  getSessionId(): string | null {
    return this.session ? this.session['sessionid'] : null;
  }

  getUsername(): string {
    return this.session ? this.session['username'] : null;
  }

  /** Returns true if user has group level access that was requested  */
  hasGroupAccess(groupName: AccessGroupName): boolean {
    const groups = this.session ? this.session?.groups : ([] as AccessGroup[]);
    return groups.some((g) => g.name === groupName);
  }

  /**
   * Returns the current user's ID.
   */
  get userID(): number {
    return getNestedValue(this.session, 'id');
  }

  /**
   * Returns true if the user is a Klinik staff member.
   */
  get userIsStaff(): boolean {
    return this.session ? this.session['is_staff'] : null;
  }

  get isRestricted(): boolean {
    /**
     * Returns true if the user/session is restricted. Whatever that means.
     */
    const isRestricted = getNestedValue(this.session, 'is_restricted');
    return isRestricted ? true : false;
  }

  /** Returns the active service group's details from cache. */
  get serviceGroup(): ServiceGroup | undefined {
    return this.getData('serviceGroup');
  }

  /** Returns the active service group's time zone. */
  get timeZone(): string {
    return getNestedValue(this.serviceGroup, 'time_zone');
  }

  /**
   * Returns true if the service group's dashboard feature is enabled.
   */
  get isDashboardEnabled(): boolean {
    return this.serviceGroup ? this.serviceGroup.dashboard_enabled : false;
  }

  /**
   * Returns true if the service group's Connect (aka. Pro Form) feature is enabled.
   */
  get isConnectEnabled(): boolean {
    return this.serviceGroup ? this.serviceGroup.pro_form_active : false;
  }

  /**
   * Returns true if the service group's snooze feature is enabled and
   * it's settings page should be accessible.
   */
  get isSnoozeFeatureEnabled(): boolean {
    return this.serviceGroup ? this.serviceGroup.snooze_enabled : false;
  }

  /** Returns true if the service group has SMS feature enabled. */
  get isSMSEnabled(): boolean {
    return this.serviceGroup ? this.serviceGroup.sms_enabled : false;
  }

  /** Returns true if the service group has commenting feature enabled. */
  get isCommentingEnabled(): boolean {
    return this.serviceGroup ? this.serviceGroup.allow_commenting : false;
  }

  /** Returns true if the service group has requests attachments feature enabled. */
  get isRequestAttachmentsEnabled(): boolean {
    return this.serviceGroup
      ? this.serviceGroup.request_attachments_enabled
      : false;
  }

  /** Returns true if the service group has video consultation feature enabled. */
  get isVideoConsultationEnabled(): boolean {
    return this.serviceGroup
      ? this.serviceGroup.video_consultation_enabled
      : false;
  }

  /**
   * Returns true if the EMIS integration is enabled.
   */
  get isEmisIntegrationEnabled(): boolean {
    return this.serviceGroup?.integration_settings?.emis_enabled;
  }

  /**
   * Returns true if the GP Connect integration is enabled.
   * @deprecated  use FeatureFlagService.isGPConnectIntegrationEnabled instead.
   */
  get isGPConnectIntegrationEnabled(): boolean {
    return this.serviceGroup?.integration_settings?.gp_connect_enabled;
  }

  /**
   * Returns the active service group's id.
   */
  get serviceGroupId(): number {
    return this.session && this.session['service_group']
      ? this.session['service_group']['id']
      : null;
  }

  /** Returns true if this is one of our development environments. */
  get isDevelopment(): boolean {
    return (
      this.isEnvironment('local') ||
      this.isEnvironment('testing') ||
      this.isEnvironment('staging')
    );
  }

  /**
   * Returns true if we are running in the Finland production environment.
   */
  get isFinlandProduction(): boolean {
    return environment.production && this.isEnvironment('fi');
  }

  /**
   * Returns true if we are running in the UK production environment.
   */
  get isUKProduction(): boolean {
    return environment.production && this.isEnvironment('uk');
  }

  get isNLProduction(): boolean {
    return environment.production && this.isEnvironment('nl');
  }

  get market(): string | null {
    if (!this.serviceGroup) {
      return null;
    }

    return this.serviceGroup.market_variables.code;
  }

  public isEnvironment(environmentName: string): boolean {
    const code = getNestedValue(this.serviceGroup, 'market_variables', 'code');
    if (code === environmentName) {
      return true;
    }

    return false;
  }

  /** Returns true if the service group has feedback resources feature enabled. */
  get isFeedbackResourcesEnabled(): boolean {
    return this.serviceGroup
      ? this.serviceGroup.feedback_resources_enabled
      : false;
  }

  /** Returns true if the service group has feedback procedures feature enabled. */
  get isFeedbackProceduresEnabled(): boolean {
    return this.serviceGroup
      ? this.serviceGroup.feedback_procedures_enabled
      : false;
  }

  /** Returns true if the service group has feedback loop feature enabled. */
  get isFeedbackLoopEnabled(): boolean {
    return this.serviceGroup ? this.serviceGroup.feedback_loop_enabled : false;
  }

  getProfessionalEnquiryAssesmentStatuses(
    message: object
  ): ProfessionalEnquiryAssesmentStatus {
    // Ask professionals to asses wether need for care prediction was accurate for AI enquiries.
    const isLongAppointment =
      getNestedValue(message, 'is_short_appointment') === false ||
      !!getNestedValue(message, 'data', 'diagnosis');

    // Procedure = ask professional to provide the method of how enquiry was closed.
    // I.e. via telephone, face to face, etc...
    const feedbackProcedures = getNestedValue(
      this.session,
      'available_case_procedures'
    );
    const feedbackProceduresExist =
      Array.isArray(feedbackProcedures) && feedbackProcedures.length > 0;

    // Resource = ask professional to provide which resource was utilized to close enquiry.
    // I.e. Dentist, GP, etc...
    const feedbackResources = getNestedValue(
      this.session,
      'available_case_resources'
    );
    const feedbackResourcesExist =
      Array.isArray(feedbackResources) && feedbackResources.length > 0;

    return {
      need_for_care_accuracy_assesment:
        this.isFeedbackLoopEnabled && isLongAppointment,
      method_assesment:
        feedbackProceduresExist && this.isFeedbackProceduresEnabled,
      methods: feedbackProcedures,
      resource_assesment:
        feedbackResourcesExist && this.isFeedbackResourcesEnabled,
      resources: feedbackResources,
    };
  }

  checkPermissions(permissions: string | string[]): boolean {
    const user_permissions = this.session
      ? this.session['user_permissions']
      : [];
    if (Array.isArray(permissions)) {
      for (const permission of permissions) {
        if (user_permissions.indexOf(permission) === -1) {
          return false;
        }
      }
      return true;
    } else {
      return user_permissions.indexOf(permissions) !== -1;
    }
  }

  setCookie(name: string, value: any, days: number): void {
    const maxAge = 60 * 60 * 24 * days;
    document.cookie = `${name}=${value}; max-age=${maxAge}; path=/; domain=${environment.baseCookieDomain}; secure=${environment.secureCookies}; sameSite=None`;
  }

  createSession(response): void {
    if (getNestedValue(response, 'pro_submit_token')) {
      // have cookie expire in two weeks (could be longer). We will store expired cookies for a awhile
      // and redirect user to sign in if session has expired
      this.setCookie('pro_submit_token', response['pro_submit_token'], 14);
    }

    this.saveSession(response);
    this.stateService.setState(StateKeys.refreshUserData, response);
    this.stateService.setState(StateKeys.loggedIn, response);
  }

  /**
   * Checks if session is valid.
   *
   * @response { valid: boolean}
   */
  async isSessionValid(): Promise<ValidSessionResponse> {
    const sessionID = this.getSessionId();
    if (!sessionID) return { session: ErrorTypes.invalid };

    try {
      const request = this.http.get(
        `${environment.backendURL}/validate/session`,
        {
          headers: this.prepareHeaders('GET', sessionID),
        }
      );
      const response = (await request.toPromise()) as ValidSessionResponse;
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  get isLoggedIn(): boolean {
    return !!this.session;
  }
}
