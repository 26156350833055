// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { UserDetailsModalType } from 'app/users/detail/detail.component';
import { SessionService } from 'services/session.service';
import { UserService } from 'services/user.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  public userDetails: object;
  public userDetailsModalType: UserDetailsModalType =
    UserDetailsModalType.MY_ACCOUNT;

  public activeTab: string = 'my-account';
  public userIsStaff: boolean = this.sessionService.userIsStaff;

  constructor(
    private sessionService: SessionService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getUserDetails();
  }

  async getUserDetails() {
    const userId = this.sessionService.userID;
    this.userDetails = await this.userService.getUser(userId);
  }

  switchTab(tabName: string) {
    this.activeTab = tabName;
    this.getUserDetails();
  }
}
