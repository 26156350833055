// @ts-nocheck
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { IntegrationService } from 'services/integration.service';
import { StateService, StateKeys } from 'services/state.service';

@Component({
  selector: 'app-connect-with-prs',
  templateUrl: './connect-with-prs.component.html',
  styleUrls: ['./connect-with-prs.component.scss'],
})
export class ConnectWithPRSComponent implements OnInit, OnDestroy {
  @Input()
  public message: object = null;

  @Output()
  private connectWithPRSEmitter: EventEmitter<boolean> = new EventEmitter();

  public findingPatientsFromPrs = true;
  public connectingPatientToRecord: number = null;
  public matches: {}[] = null;
  public source_prs: string = null;
  public indexOfSelected: number = null;
  public indexOfFailed: number = null;

  private stateChangeSubscription: Subscription;

  constructor(
    private integrationService: IntegrationService,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state) => {
        switch (state['key']) {
          case StateKeys.emisPatientSearchResult:
            if (state['value']) {
              this.findingPatientsFromPrs = false;
              this.source_prs = state['value']['source'];
              this.matches = state['value']['items'];
            }
            break;
          case StateKeys.findingPatientsFromPrs:
            if (state['value']) {
              this.findingPatientsFromPrs = state['value'];
            }
            break;

          case StateKeys.emisPatientConnectedToRecordStatus:
            if (state['value'] === 'success') {
              this.indexOfFailed = null;
              this.indexOfSelected = this.connectingPatientToRecord;
            } else if (state['value'] === 'fail') {
              this.indexOfSelected = null;
              this.indexOfFailed = this.connectingPatientToRecord;
            }

            this.connectingPatientToRecord = null;
            break;
        }
      }
    );

    this.integrationService.getEMISSearchRequestXML(this.message['id']);
  }

  ngOnDestroy(): void {
    this.stateChangeSubscription.unsubscribe();
  }

  connectToRecord(emisId, nhsNumber: number): void {
    if (this.message) {
      this.integrationService.setEmisPatientId(this.message, emisId, nhsNumber);
    }
  }

  closeModal(): void {
    this.connectWithPRSEmitter.emit(false);
  }
}
