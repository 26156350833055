import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { Configuration } from 'app/app.constants';
import { Permissions } from 'app/shared/permissions/permissions.module';
import { Subscription } from 'rxjs';
import { SessionService } from 'services/session.service';
import { StateService, StateKeys } from 'services/state.service';
import { UserService, UserStatus } from 'services/user.service';

@Component({
  selector: 'app-users-list-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy {
  private stateChangeSubscription: Subscription | undefined;
  public userList: Array<object> = [];
  public orderBy = 'first_name';
  public totalUsers = 0;
  public pageSize = 20;
  public page = 1;
  public previousText = '<i class="fa fa-arrow-left" aria-hidden="true">';
  public nextText = '<i class="fa fa-arrow-right" aria-hidden="true">';
  public readonly permissions = Permissions;
  public readonly userstatus = UserStatus;

  @Output()
  private userSelected: EventEmitter<object> = new EventEmitter();

  constructor(
    protected sessionService: SessionService,
    protected stateService: StateService,
    protected configuration: Configuration,
    protected toasterService: ToasterService,
    private userService: UserService
  ) {
    this.pageSize = this.configuration.usersPageSize;
  }

  async selectUser(user: any) {
    await this.userService
      .getUser(user.id)
      .then((userDetails) => {
        this.userSelected.emit(userDetails);
      })
      .catch(() => {
        this.toasterService.pop('danger', $localize`:@@userDetailsLoadFailed:`);
      });
  }

  setOrdering(orderBy: string) {
    this.orderBy = orderBy;
    this.stateService.setState(StateKeys.user_order_by, this.orderBy);
  }

  pageChanged(event: any): void {
    this.stateService.setState(StateKeys.userPageChanged, event.page);
  }

  ngOnInit() {
    this.stateChangeSubscription = this.stateService.state$.subscribe(
      (state: any) => {
        switch (state['key']) {
          case StateKeys.userListLoaded:
            this.userList = state['value'];
            break;
          case StateKeys.userCountUpdated:
            this.totalUsers = state['value'];
            break;
        }
      }
    );

    // Reset the user search term when entering the user list
    this.stateService.setState(StateKeys.userSearchTerm, null);
  }

  ngOnDestroy() {
    this.stateChangeSubscription?.unsubscribe();
  }
}
