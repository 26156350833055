<div class="simple-dropdown" [formGroup]="internalFormGroup">
  <label [for]="dropDownName"
    >{{ label
    }}<span style="color: red">{{ isRequired ? ' *' : '' }}</span></label
  >
  <select
    formControlName="internalFormControl"
    id="{{ dropDownName }}"
    class="custom-select"
    name="{{ dropDownName }}">
    <option disabled>-</option>
    <option *ngFor="let item of items" [ngValue]="item.value">
      {{ item.name }}
    </option>
  </select>
  <p style="color: red" *ngIf="showErrors">{{ errorText }}</p>
</div>
