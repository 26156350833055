<table *ngFor="let dataset of data">
  <thead *ngIf="!hideHead">
    <tr class="top-table-row header">
      <th *ngFor="let header of dataset['headers']">{{ header }}</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let row of dataset['values']; let i = index"
      class="top-table-row"
      [ngClass]="{ odd: i % 2 }">
      <td *ngFor="let value of row">{{ value }}</td>
    </tr>
    <tr *ngIf="!hideTotal" class="top-table-row header">
      <th *ngIf="maxItems !== false"></th>
      <th i18n="@@total">Yhteensä</th>
      <th>{{ dataset.total }}</th>
      <th>{{ percentage }}</th>
    </tr>
  </tbody>
</table>
