// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { ServiceGroupService } from 'services/service-group.service';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss'],
})
export class UnitsComponent implements OnInit {
  private _services: Array<Service>;

  public get services(): Array<Service> {
    return this._services;
  }

  public askConfirmText(service: Service): string {
    return `Are you sure that you want to ${
      !service.active ? 'activate' : 'deactivate'
    } ${service.name} (${service.slug})?`;
  }

  public askConfirmDeleteText(service: Service): string {
    return `Are you sure that you want to ${
      !service.deleted ? 'delete' : 'undelete'
    } ${service.name} (${service.slug})?`;
  }

  constructor(
    private serviceGroupService: ServiceGroupService,
    private toasterService: ToasterService
  ) {}

  async ngOnInit() {
    // Load services from the backend to make sure that they are up to date.
    await this.loadServices();
  }

  async loadServices() {
    this._services = await this.serviceGroupService.getServices(true);
  }

  public async updateActive(service: Service, active: boolean) {
    try {
      await this.serviceGroupService.updateService(
        service.id,
        active,
        service.deleted
      );
      this.toasterService.pop('success', $localize`:@@savedSuccesfully:`);
    } catch (error) {
      this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`);
    }

    this.loadServices();
  }

  public async updateDeleted(service: Service, deleted: boolean) {
    if (deleted) {
      await this.deleteService(service);
    } else {
      await this.undeleteService(service);
    }
    this.loadServices();
  }

  public async deleteService(service: Service) {
    try {
      await this.serviceGroupService.deleteService(service.id);
      // todo: translate
      this.toasterService.pop('success', 'Deleted successfully');
    } catch (error) {
      this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`);
    }
  }

  public async undeleteService(service: Service) {
    try {
      await this.serviceGroupService.updateService(
        service.id,
        service.active,
        null
      );
      // todo: translate
      this.toasterService.pop('success', 'Undeleted successfully');
    } catch (error) {
      this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`);
    }
  }
}
