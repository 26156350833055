// @ts-nocheck
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { PasswordValidator } from 'app/shared/validators/password.validator';
import { PublicService } from 'services/public.service';
import {
  changePasswordPayload,
  PasswordLabels,
  SessionService,
} from 'services/session.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  public readonly passwordLabels = PasswordLabels;

  // Input translations
  public readonly currentPasswordTranslation = $localize`:@@currentPassword:`;
  public readonly newPasswordTranslation = $localize`:@@newPassword:`;
  public readonly retypeNewPasswordTranslation = $localize`:@@retypeNewPassword:`;

  // Form controls
  public readonly currentPasswordControl = new FormControl('', {
    validators: [Validators.required],
  });
  public readonly newPasswordControl = new FormControl('', {
    validators: [Validators.required],
    asyncValidators: [PasswordValidator(this.publicService)],
  });
  public readonly retypedNewPasswordControl = new FormControl('', {
    validators: [Validators.required],
    asyncValidators: [PasswordValidator(this.publicService)],
  });

  // Init form group with form controls
  public passwordFormGroup: FormGroup = new FormGroup({
    'current-password': this.currentPasswordControl,
    'new-password': this.newPasswordControl,
    'retyped-new-password': this.retypedNewPasswordControl,
  });

  constructor(
    private publicService: PublicService,
    private sessionService: SessionService,
    private toasterService: ToasterService
  ) {}

  updateControlValue(label: string, value: string): void {
    const passwordControl = this.passwordFormGroup.get(label);
    passwordControl.setValue(value);
  }

  changePassword(): void {
    if (!this.isNewPasswordValid || !this.passwordFormGroup.valid) {
      return;
    }

    const payload: changePasswordPayload = {
      old_password: this.passwordFormGroup.get(PasswordLabels.CURRENT_PASSWORD)
        .value,
      new_password: this.passwordFormGroup.get(PasswordLabels.NEW_PASSWORD)
        .value,
    };

    this.sessionService
      .changePassword(payload)
      .then(() => {
        this.toasterService.pop('success', $localize`:@@savedSuccesfully:`);
        this.clearControls();
      })
      .catch((error) =>
        this.toasterService.pop('error', $localize`:@@errorPleaseTryAgain:`)
      );
  }

  clearControls(): void {
    this.currentPasswordControl.setValue('');
    this.newPasswordControl.setValue('');
    this.retypedNewPasswordControl.setValue('');
  }

  get isNewPasswordValid(): boolean {
    const currentPassword = this.currentPasswordControl.value;
    const newPassword = this.newPasswordControl.value;
    const retypedNewPassword = this.retypedNewPasswordControl.value;

    // New password should be typed twice to prevent typos etc.
    if (newPassword !== retypedNewPassword) {
      return false;
    }

    // New password cannot be same as current password
    if (currentPassword === newPassword) {
      return false;
    }

    return true;
  }
}
