import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { Tile, TilesService, TileNameVariant } from 'services/tiles.service';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss'],
})
export class TilesComponent implements OnInit {
  constructor(
    private tilesService: TilesService,
    private utilsService: UtilsService,
    private toastService: ToasterService
  ) {}

  ngOnInit(): void {
    this.fetchTiles();
  }

  tiles: Tile[] = [];

  private async fetchTiles() {
    const respo = await this.tilesService.getTiles().toPromise();
    this.tiles = respo;
  }

  getTileName(tile: Tile) {
    const locale = this.utilsService.currentLocale as TileNameVariant; // This is defined for the service group and does not depend on the i18n locale
    const translation = tile.name[locale];
    if (translation) return translation; // Yes, hit!
    return tile.name.default; // Fallback
  }

  askConfirmText(tile: Tile) {
    const question = $localize`:@@changeVisibilityTileAreYouSure:`;
    return `${question}: ${this.getTileName(tile)}?`;
  }

  updateTileBaseForm(tile: Tile, event: boolean) {
    const updatedTile = { ...tile, base_form: event };
    this.tiles = this.tiles.map((t) => (t.id === tile.id ? updatedTile : t));
  }
  updateTileProForm(tile: Tile, event: boolean) {
    const updatedTile = { ...tile, pro_form: event };
    this.tiles = this.tiles.map((t) => (t.id === tile.id ? updatedTile : t));
  }

  async commitChanges() {
    try {
      const result = await this.tilesService
        .updateTiles(this.tiles)
        .toPromise();
      this.tiles = result;
      this.toastService.pop(
        'success',
        $localize`:@@tilesUpdated:`,
        'Tiles updated successfully'
      );
    } catch (e) {
      await this.fetchTiles();
      this.toastService.pop(
        'error',
        $localize`:@@somethingWentWrong:`,
        'Something went wrong'
      );
    }
  }
}
