// @ts-nocheck
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
})
export class TextareaInputComponent implements OnInit {
  @Input() public validators: ValidatorFn[] = [Validators.required];

  @Input() public readonly maxlength: number = 300;
  @Input() public readonly rows: number = 1;
  @Input() public smallTextarea: boolean = false;
  @Input() public largeTextarea: boolean = false;
  @Input() set translations(prefix: string) {
    this.label = this.translationStore[`${prefix}`].label;
  }
  @Input() set prefill(prefill: string) {
    this.textareaControl.setValue(prefill);
  }
  @Input() public set disabled(value: boolean) {
    if (value) {
      this.textareaControl.disable();
    } else {
      this.textareaControl.enable();
    }
  }

  @Output() public readonly controlEmitter: EventEmitter<InputUpdateEvent> =
    new EventEmitter();

  private readonly translationStore = {
    name: {
      label: $localize`:@@smsTemplateNameLabel:`,
    },
    body: {
      label: $localize`:@@smsTemplateBodyLabel:`,
    },
  };

  public label: string;

  public readonly formGroup: FormGroup = new FormGroup({});
  public readonly textareaControl = new FormControl('');

  ngOnInit() {
    this.textareaControl.setValidators(this.validators);
    this.formGroup.addControl('textareaControl', this.textareaControl);
    this.textareaControl.valueChanges.subscribe(() => {
      this.controlEmitter.emit(<InputUpdateEvent>{
        value: this.textareaControl.value,
        isValid: this.textareaControl.valid,
      });
    });
  }
}
