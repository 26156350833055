<div class="side-by-side">
  <div class="side-by-side-container">
    <h1 class="title">{{ labels[0] }}</h1>
    <h1 class="number">{{ hours[0] }} h&emsp;{{ minutes[0] }} min</h1>
  </div>
  <div class="divider"></div>
  <div class="side-by-side-container">
    <h1 class="title">{{ labels[1] }}</h1>
    <h1 class="number">{{ hours[1] }} h&emsp;{{ minutes[1] }} min</h1>
  </div>
</div>
