// @ts-nocheck
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { PublicService } from 'services/public.service';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class RedirectionComponent implements OnInit {
  public submitting = false;
  public validToken = false;
  public otherReason = false;
  public caseHandled = false;
  public uploadSuccess = false;
  public validatingToken = true;
  public practice = '';
  public pharmacies: {
    id: number;
    name: string;
    address: string;
    opening_hours: string[];
  }[] = [];
  public redirectionForm: FormGroup = new FormGroup({
    referral_decision: new FormControl('', Validators.required),
  });

  public readonly max_decline_len = 4000;
  public readonly declinationReasons = [
    'My preferred pharmacy is not in the list',
    'The pharmacies are too far away',
    'I have already seen a pharmacist, who was unable to help',
    'My symptoms are too serious to be treated by a pharmacist',
    'I need a specific prescription that the pharmacist is unable to write',
  ];

  private token = '';

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private location: Location,
    private publicService: PublicService
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.auth;
    this.location.replaceState('#/redirection');

    // DELETE ONCE NHS USER TESTING HAS FINISHED
    this.validToken = true;
    this.practice = 'Tollgate Medical Centre';
    this.validatingToken = false;

    // UNCOMMENT ONCE NHS USER TESTING HAS FINISHED
    // if (this.token) {
    //   this.publicService.getPublicData(this.token).then(result => {
    //     this.validToken = result.valid;

    //     if (this.validToken) {
    //       this.practice = result.response['service_group_name'];
    //     } else {
    //       this.caseHandled = result.case_handled;
    //     }

    //     this.validatingToken = false;
    //   });
    // } else {
    //   this.validToken = false;
    //   this.validatingToken = false;
    // }
  }

  async onSubmit(form: NgForm): Promise<void> {
    this.submitting = true;

    // DELETE ONCE NHS USER TESTING HAS FINISHED
    setTimeout(() => {
      this.uploadSuccess = true;
      this.submitting = false;
    }, 500);

    // UNCOMMENT ONCE NHS USER TESTING HAS FINISHED
    // try {
    //   await this.http.post(
    //     `${environment.backendURL}/public/redirection`,
    //     form.value,
    //     { headers: this.publicService.prepareHeaders(this.token, 'post') }
    //   ).toPromise();

    //   this.uploadSuccess = true;
    // } catch (error) {
    //   console.log(error);
    // }

    // this.submitting = false;
  }

  getPharmacies(): void {
    // TODO: Handle getting list of pharmacies
    setTimeout(() => {
      this.pharmacies = [
        {
          id: 0,
          name: 'Day Lewis Pharmacy (Beckton)',
          address: '220 Tollgate Rd, London E6 5JS',
          opening_hours: [
            'Mon - Fri 09:00 - 13:00, 14:00 - 18:30',
            'Sat Closed',
            'Sun Closed',
          ],
        },
        {
          id: 1,
          name: 'Asda Pharmacy',
          address: 'Tollgate Rd, London E6 5JP',
          opening_hours: [
            'Mon 08:00 - 23:00',
            'Tue - Fri 07:00 - 23:00',
            'Sat 07:00 - 22:00',
            'Sun 11:00 - 17:00',
          ],
        },
      ];
    }, 2500);
  }

  referralDecision(answer: boolean): void {
    this.redirectionForm.controls['referral_decision'].patchValue(answer);

    if (answer) {
      this.getPharmacies();
      this.redirectionForm.addControl(
        'preferred_pharmacy',
        new FormControl('', [Validators.required, Validators.maxLength(256)])
      );
      this.redirectionForm.removeControl('reason_for_decline');
    } else {
      this.redirectionForm.addControl(
        'reason_for_decline',
        new FormControl('', [
          Validators.required,
          Validators.maxLength(this.max_decline_len),
        ])
      );
      this.redirectionForm.removeControl('preferred_pharmacy');
    }
  }

  redirectionAnswer(question: string, answer: string | number): void {
    this.redirectionForm.controls[question].patchValue(answer);

    if (question === 'reason_for_decline') {
      this.otherReason = answer === '';
    }
  }
}
