<header>
  <div
    class="img-container"
    [routerLink]="['landing']"
    (click)="requestNavigation('landing')">
    <img
      *ngIf="!customSettings"
      src="assets/images/access-logo.png"
      [routerLink]="['messages']"
      alt="Klinik"
      (click)="requestNavigation('welcome')" />
    <img
      *ngIf="customSettings"
      src="assets/images/{{ customSettings.logo }}"
      alt="{{ customSettings.alt }}" />
  </div>

  <ng-container *ngIf="session">
    <nav>
      <button
        class="nav-button"
        [routerLink]="['messages']"
        [ngClass]="{ active: naviSection('messages') }"
        [disabled]="!userPermissions.has(permissions.MESSAGE_VIEW)">
        <i class="fa fa-envelope"></i>
        <span i18n="@@navigationMessages">Contact requests</span>
      </button>

      <button
        class="nav-button"
        *ngIf="sessionService.isDashboardEnabled"
        [routerLink]="['dashboard']"
        [class.active]="naviSection('dashboard')"
        [disabled]="!userPermissions.has(permissions.DASHBOARD_VIEW)">
        <i class="fa fa-bar-chart"></i>
        <span class="dropdown-choice" i18n="@@dashboardMenuTitle"
          >Dashboard</span
        >
      </button>

      <button
        class="nav-button"
        [routerLink]="['users']"
        [class.active]="naviSection('users')"
        [disabled]="!userPermissions.has(permissions.USERS_VIEW)">
        <i class="fa fa-users"></i>
        <span i18n="@@navigationUserManagement">User administration</span>
      </button>

      <!-- Open Connect / Pro Form. -->
      <button
        class="nav-button"
        *ngIf="sessionService.isConnectEnabled"
        [disabled]="!userPermissions.has(permissions.PRO_FORM_VIEW)"
        (click)="openProForm()">
        <i class="fa fa-wpforms"></i>
        <span i18n="@@proForm">HTA-lomake</span>
      </button>

      <!-- Open Patient form. Visible only to staff users for now. -->
      <button
        *ngIf="sessionService.userIsStaff"
        class="nav-button"
        (click)="utilsService.openPatientForm()">
        <i class="fa fa-wpforms"></i>
        <span i18n="@@patientFormSettingsTitle">Potilaslomake</span>
      </button>

      <a href="{{ instructionsUrl }}" target="_blank" rel="noopener">
        <button
          class="nav-button"
          [class.active]="naviSection('gdpr-instructions')">
          <i class="fa fa-question"></i>
          <span i18n="@@header_gdpr_instructions">Instructions</span>
        </button>
      </a>
    </nav>

    <div
      class="kip-installer-download"
      *ngIf="newKIPVersionAvailable && integrationEnabled">
      <a
        class="header-integration-element"
        href="{{ kipInstallerDownloadUrl }}"
        target="_blank"
        rel="noopener"
        [ngClass]="{ active: newKIPVersionAvailable }">
        <i class="fa fa-download"></i>
        <p id="download-text" i18n="@@kipDownloadButtonText">Download</p>
      </a>
      <div class="kip-download-help-text">
        <p i18n="@@newKIPVersionAvailableInfoText">
          New Klinik integration pack version available.<br />
        </p>
      </div>
    </div>

    <div class="integration-status" *ngIf="integrationEnabled">
      <i
        class="fa fa-bolt header-integration-element"
        [ngClass]="{ active: emisIntegrationAvailable }"></i>

      <div
        class="integration-help-text"
        [ngClass]="{ active: emisIntegrationAvailable }">
        <p
          *ngIf="emisIntegrationAvailable"
          i18n="@@integrationHelpTextEmisActive">
          Connection to EMIS established.<br />
          KIP running without errors.
        </p>
        <p
          *ngIf="!emisIntegrationAvailable"
          i18n="@@integrationHelpTextEmisInactive">
          Connection to EMIS lost.<br />
          Try restarting KIP.
        </p>
      </div>
    </div>
    <app-auth-currentuser></app-auth-currentuser>
  </ng-container>
</header>
