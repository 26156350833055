// @ts-nocheck
import * as moment_tz from 'moment-timezone';

/**
 * Format date to service groups timezone.
 *
 * Examples of different foramts for service group with timezone: Europe/London
 *
 * LL = 17 March 2022
 *
 * LLLL = Thursday, 17 March 2022 13:31
 *
 * LT = 13:31
 */
export const formatDateToLocale = (
  date: string,
  format: string,
  timezone: string,
  simpleDate: boolean = false
): string => {
  let dateToFormat = date;

  if (simpleDate) {
    return moment_tz(dateToFormat).format(format);
  }

  // remove timezone from date if it has it
  [' +', ' -'].forEach((timezoneChar) => {
    const timezoneCharIndex = dateToFormat.indexOf(timezoneChar);
    if (timezoneCharIndex !== -1) {
      dateToFormat = dateToFormat.substring(0, timezoneCharIndex);
    }
  });

  return moment_tz(dateToFormat).tz(timezone).format(format);
};
