<section class="feedback">
  <div class="feedback-container">
    <form [formGroup]="feedbackForm" (ngSubmit)="OnSubmit(feedbackForm.value)">
      <div class="title-holder">
        <div>
          <h1 class="feedback-title" i18n="@@sendFeedback">
            Lähetä palautetta
          </h1>
          <button
            type="button"
            class="btn btn-close"
            (click)="backToMessages()">
            <i class="fa fa-times-circle" aria-hidden="true"></i>
            <span class="label" i18n="@@kayttajanTiedotProfiiliSulje"
              >Sulje</span
            >
          </button>
        </div>
      </div>
      <div class="form-content">
        <div class="feedback-form-row">
          <div class="form-group feedback-form-group">
            <label for="feedback-username" i18n="@@feedback_user"
              >Käyttäjä</label
            >
            <input
              type="text"
              class="form-control"
              id="feedback-username"
              formControlName="full_name" />
          </div>
          <div class="form-group feedback-form-group">
            <label
              for="feedback-service-group"
              i18n="@@viestilistaOtsikkoYksikko"
              >Yksikkö</label
            >
            <input
              class="form-control"
              type="text"
              id="feedback-service-group"
              formControlName="service_group" />
          </div>
        </div>

        <div class="feedback-message-wrapper">
          <div class="feedback-form-row">
            <div class="form-group feedback-form-group">
              <div class="feedback-form-block feedback-type-wrapper">
                <label for="subject" i18n="@@feedback_type">Tyyppi</label>
                <select class="form-control" id="type" formControlName="type">
                  <option value="1" selected>
                    <ng-container i18n="@@feedback_feedback">
                      Palaute
                    </ng-container>
                  </option>
                  <option value="2">
                    <ng-container i18n="@@feedback_support_request"
                      >Tukipyyntö</ng-container
                    >
                  </option>
                  <option value="3">
                    <ng-container i18n="@@feedback_development_proposal"
                      >Kehitysehdotus</ng-container
                    >
                  </option>
                  <option value="4">
                    <ng-container i18n="@@feedback_personal_data_management"
                      >Henkilötietojen käsittely</ng-container
                    >
                  </option>
                </select>
              </div>
              <div class="feedback-form-block feedback-subject-wrapper">
                <label for="subject" i18n="@@feedback_subject">Otsikko</label>
                <input
                  class="form-control"
                  type="text"
                  id="subject"
                  formControlName="subject" />
              </div>
              <div
                class="error"
                i18n="@@feedback_subject_error"
                *ngIf="
                  feedbackForm.controls.subject.hasError('required') &&
                  feedbackForm.controls.subject.touched
                ">
                Syötä otsikko
              </div>
            </div>
          </div>
          <div class="feedback-form-row">
            <div class="form-group feedback-form-group">
              <label for="content" i18n="@@feedback_feedback_message"
                >Palauteviesti</label
              >
              <textarea
                rows="15"
                class="form-control"
                id="content"
                formControlName="content"></textarea>
              <div
                class="error"
                i18n="@@feedback_feedback_message_error"
                *ngIf="
                  feedbackForm.controls.content.hasError('required') &&
                  feedbackForm.controls.content.touched
                ">
                Syötä palauteviesti
              </div>
              <br />
              <br />
              <ng-container *ngIf="currentType() != '4'; else gdpr_hint">
                <label i18n="@@feedback_assurance"
                  >Vakuutan, että palauteviesti tai otsikko ei sisällä
                  henkilötietoja:</label
                >
                <input
                  type="checkbox"
                  id="no_sensitive_data"
                  name="no_sensitive_data"
                  [(ngModel)]="sensitive"
                  [ngModelOptions]="{ standalone: true }" />
              </ng-container>
              <ng-template #gdpr_hint>
                <label i18n="@@feedback_gdpr_hint"
                  >GDPR:n mukaisesti potilaalla on oikeus saada nähdä
                  henkilötiedot jotka olemme keränneet heistä, pyytää niihin
                  oikaisua tai pyytää niitä poistettavaksi. Kertokaa
                  viestissänne, mistä toimenpiteestä on kyse. Lisäksi liittäkää
                  mukaan potilaan henkilötunnus ja nimi.</label
                >
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="actions">
        <button
          class="btn btn-submit"
          type="submit"
          i18n="@@feedback_submit"
          [disabled]="
            feedbackForm.invalid ||
            (sensitive != true && currentType() != '4') ||
            disableButton
          ">
          Lähetä
        </button>
        <button
          class="btn btn-cancel"
          type="button"
          i18n="@@feedback_return"
          (click)="backToMessages()">
          Paluu
        </button>
      </div>
    </form>
  </div>
</section>
