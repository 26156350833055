// @ts-nocheck
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { PublicService } from 'services/public.service';
import { getNestedValue } from '../methods/getNestedValue';

export function PasswordValidator(
  publicService: PublicService
): AsyncValidatorFn {
  const invalidPassword = { password_invalid: true };
  const validPassword = null;

  return async (c: AbstractControl): Promise<ValidationErrors | null> => {
    return publicService
      .validatePassword(c.value)
      .then((response: object) => {
        if (getNestedValue(response, 'is_valid') !== true) {
          return invalidPassword;
        }

        return validPassword;
      })
      .catch(() => invalidPassword);
  };
}
