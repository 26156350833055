// @ts-nocheck
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-klinik-overlay',
  templateUrl: './klinik-overlay.component.html',
  styleUrls: ['./klinik-overlay.component.scss'],
})
export class KlinikOverlayComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
