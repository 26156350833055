// @ts-nocheck
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authRoutes } from './auth/auth.routing';
import { dashboardRoutes } from './dashboard/dashboard.routing';
import { feedbackRoutes } from './feedback/feedback.routing';
import { superuserRoutes } from './superuser/superuser.routing';
import { userRoutes } from './users/users.routing';
import { settingRoutes } from './settings/settings.routing';

import { AuthComponent } from './auth/auth.component';
import { GdprInstructionsComponent } from './gdpr-instructions/gdpr-instructions.component';
import { LandingComponent } from './landing/landing.component';
import { MessagesComponent } from './messages/messages.component';
import { DocumentUploadComponent } from './public/document-upload/document-upload.component';
import { RedirectionComponent } from './public/redirection/redirection.component';
import { DetailComponent } from './users/detail/detail.component';
import { KipInstallerComponent } from './kip-instructions/kip-installer.component';

import { Permissions } from 'permissions/permissions.module';
import { SessionGuard } from 'guards/session.guard';
import { MultipleServiceGroupsGuard } from 'guards/multiple-service-groups.guard';
import { NoSessionGuard } from 'guards/no-session.guard';
import { PermissionGuard } from 'guards/permission.guard';

export const routes: Routes = [
  {
    path: 'auth',
    children: authRoutes,
    canActivate: [NoSessionGuard],
    component: AuthComponent,
  },
  {
    path: 'patient',
    component: DocumentUploadComponent,
  },
  {
    path: 'redirection',
    component: RedirectionComponent,
  },
  // Below routes that require session
  {
    path: 'users',
    children: userRoutes,
    canActivate: [SessionGuard, PermissionGuard],
    data: {
      permissions: [Permissions.USERS_VIEW],
    },
  },
  {
    path: 'superuser',
    children: superuserRoutes,
    canActivate: [SessionGuard, MultipleServiceGroupsGuard],
  },
  {
    path: 'profile',
    component: DetailComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'feedback',
    children: feedbackRoutes,
    canActivate: [SessionGuard],
  },
  {
    path: 'gdpr-instructions',
    component: GdprInstructionsComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'dashboard',
    children: dashboardRoutes,
    canActivate: [SessionGuard, PermissionGuard],
    data: {
      permissions: [Permissions.DASHBOARD_VIEW],
    },
  },
  {
    path: 'messages',
    component: MessagesComponent,
    canActivate: [SessionGuard, PermissionGuard],
    data: {
      permissions: [Permissions.MESSAGE_VIEW],
    },
  },
  {
    path: 'landing',
    component: LandingComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'settings',
    children: settingRoutes,
    canActivate: [SessionGuard, PermissionGuard],
    data: {
      permissions: [Permissions.SETTINGS_VIEW],
    },
  },
  {
    path: 'kip-installer',
    component: KipInstallerComponent,
    canActivate: [SessionGuard],
  },
  // Routing is hierarchial! New routes only above '' and '**'!
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
  routes,
  {
    useHash: true,
    relativeLinkResolution: 'legacy',
  }
);
