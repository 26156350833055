// @ts-nocheck
import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { DetailComponent } from './detail.component';

export const detailRoutes: Routes = [
  {
    path: 'service-group/:id',
    component: DetailComponent,
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(detailRoutes);
