<div class="datepicker" [ngClass]="{ unclickable: loading }" [tabindex]="1">
  <div class="datepicker-body">
    <div class="month1">
      <div class="month">
        <div class="month-header">
          <i
            class="calendar-arrow fa fa-chevron-left"
            [ngClass]="{ 'calendar-arrow--disabled': !canRetreatMonth }"
            (click)="canRetreatMonth && previousMonth()"></i>
          <p class="header-text">
            <strong>{{ getMonth1Name() }}</strong>
          </p>
        </div>
        <div class="month-days">
          <div class="month-days-header" *ngFor="let day of weekDays">
            <p>{{ day }}</p>
          </div>
          <div
            [ngClass]="{
              'month-days-item': item.value > 0,
              'month-days-item--disabled': !item.enabled
            }"
            *ngFor="let item of month1Days">
            <p
              *ngIf="item.value > 0"
              [ngClass]="{
                'month-days-item--selected':
                  (startDate && item.date.isSame(startDate, 'day')) ||
                  (endDate && item.date.isSame(endDate, 'day')),
                'month-days-item--in-range':
                  startDate &&
                  endDate &&
                  item.date.isBetween(startDate, endDate, 'day')
              }"
              (click)="item.enabled && selectDate(item.date)">
              {{ item.value }}
            </p>
            <p *ngIf="item.value === 0"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="month2">
      <div class="month">
        <div class="month-header">
          <p class="header-text">
            <strong>{{ getMonth2Name() }}</strong>
          </p>
          <i
            class="calendar-arrow fa fa-chevron-right"
            [ngClass]="{ 'calendar-arrow--disabled': !canAdvanceMonth }"
            (click)="canAdvanceMonth && nextMonth()"></i>
        </div>
        <div class="month-days">
          <div class="month-days-header" *ngFor="let day of weekDays">
            <p>{{ day }}</p>
          </div>
          <div
            [ngClass]="{
              'month-days-item': item.value > 0,
              'month-days-item--disabled': !item.enabled
            }"
            *ngFor="let item of month2Days">
            <p
              *ngIf="item.value > 0"
              [ngClass]="{
                'month-days-item--selected':
                  (startDate && item.date.isSame(startDate, 'day')) ||
                  (endDate && item.date.isSame(endDate, 'day')),
                'month-days-item--in-range':
                  startDate &&
                  endDate &&
                  item.date.isBetween(startDate, endDate, 'day')
              }"
              (click)="item.enabled && selectDate(item.date)">
              {{ item.value }}
            </p>
            <p *ngIf="item.value === 0"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <span i18n="@@snoozeTimerStart">Start</span>
    <input
      type="text"
      class="date-input"
      [ngClass]="{ 'footer-input--invalid': dateInvalid['start']['date'] }"
      (blur)="manualInputDate($event, 'start', 'date')"
      [ngModel]="startDate.format('L')" />
    <input
      type="text"
      class="time-input"
      [ngClass]="{ 'footer-input--invalid': dateInvalid['start']['time'] }"
      (blur)="manualInputDate($event, 'start', 'time')"
      [ngModel]="startDate.format('HH:mm')" />
    <span i18n="@@snoozeTimerEnd">End</span>
    <input
      type="text"
      class="date-input"
      [ngClass]="{ 'footer-input--invalid': dateInvalid['end']['date'] }"
      (blur)="manualInputDate($event, 'end', 'date')"
      [ngModel]="endDate.format('L')" />
    <input
      type="text"
      class="time-input"
      [ngClass]="{ 'footer-input--invalid': dateInvalid['end']['time'] }"
      (blur)="manualInputDate($event, 'end', 'time')"
      [ngModel]="endDate.format('HH:mm')" />
  </div>
</div>
